import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState, useContext } from 'react';
import useAsync from '../../../asyncNet';
import { Backdrop, CircularProgress, Typography } from '@material-ui/core';
import publicIp from 'public-ip';
import { DormancyTable, DormancyToolbar } from './components';
import { searchDormantUser } from '../APIs/tokapis';
import { userInfoContext } from '../../../App';
import {
  NO_PERMISSION,
  NO_PERMISSION_WARNING,
  NO_WHITELIST_INSIDE_WARNING,
  READ_PERMISSION
} from '../../Common/Config/naviwatchConfig';
import { naviwatchCheckWhiteListValidation } from '../../Common/APIs/naviwatchApis';
import AlertPopup from './components/DormancyDialog/AlertPopup';
import {
  STRING_NO_DORMANT_USER,
  STRING_NO_SEARCH_DATA
} from '../StringTable/StringTable';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));


const DormancyManage = ({ history }) => {
  const classes = useStyles();
  const [query, setQuery] = useState({
    userId: '',
    from: '',
    to: ''
  });


  const [dormancyData, tryRefetch] = useAsync(getDormantUser, [], true);
  const { loading: dormancyLoading, data: dormancy } = dormancyData;
  const [ipCheckState, tryCheckWhitelist] = useAsync(checkIpValidation, [], true);
  const { loading: ipCheckLoading, data: ipValid } = ipCheckState;
  const [ipAddress, setIpAddress] = useState(undefined);
  const authInfo = useContext(userInfoContext);
  const [noPermissionMsg, setNoPermissionMsg] = useState("");
  const [permission, setPermission] = useState(NO_PERMISSION);
  const [search, setSearch] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const [dormantPopupOpen, setDormantPopupOpen] = useState(false);
  const openPopup = () => setPopupOpen(true);
  const closePopup = () => setPopupOpen(false);
  const openDormantPopup = () => setDormantPopupOpen(true);
  const closeDormantPopup = () => setDormantPopupOpen(false);

  useEffect(() => {
    var pageInfo = sessionStorage.getItem('pages');
    if (pageInfo !== "undefined") {
      var pageInfoObj = JSON.parse(pageInfo);
      var pageArray = pageInfoObj.filter(page => page.name.toLowerCase().includes("inactive"));
      if (pageArray !== undefined && pageArray.length > 0) {
        var page = pageArray[0];
        if (page.permission === undefined) {
          setNoPermissionMsg(NO_PERMISSION_WARNING);
        }
        setPermission(page.permission);
      }
    }
  }, [authInfo.pages]);

  async function getDormantUser() {
    return searchDormantUser(query.userId, query.from, query.to, ipAddress);
  }

  async function checkIpValidation() {
    return naviwatchCheckWhiteListValidation(ipAddress);
  }

  const searchDormacy = (searchdata) => {
    var _from = '';
    var _to = '';
    if (searchdata.from !== '') {
      _from = new Date(searchdata.from).setHours(0, 0, 0, 0) + 9 * 60 * 60 * 1000
    }
    if (searchdata.to !== '') {
      _to = new Date(searchdata.to).setHours(23, 59, 59, 0) + 9 * 60 * 60 * 1000
    }
    setQuery(

      {
        userId: searchdata.userId,
        from: _from,
        to: _to
      });
    setSearch(true);
  };

  useEffect(() => {
    if (search === true) {
      tryRefetch();
      setSearch(false);
    }
  }, [search]);

  useEffect(() => {
    if (ipCheckState.data === false) {
      setNoPermissionMsg(NO_WHITELIST_INSIDE_WARNING + `(${ipAddress})`);
    }
  }, [ipCheckState]);

  useEffect(() => {
    if (ipAddress !== undefined) {
      tryCheckWhitelist();
    }
  }, [ipAddress]);

  useEffect(() => {
    if (sessionStorage.getItem('naviwatch_permission') !== 'true')
      history.push('/');
    (async () => {
      setIpAddress(await publicIp.v4());
    })();
  }, []);

  useEffect(() => {
    if (dormancy !== null && dormancy !== undefined && (dormancy.dormantUsers).length === 0 && query.userId === '') {
      openDormantPopup()
    }
  }, [dormancy, query]);

  useEffect(() => {
    if (dormancy !== null && dormancy !== undefined && (dormancy.dormantUsers).length === 0 && query.userId !== '') {
      openPopup()
    }
  }, [dormancy, query]);

  return (
    <div>
      {permission >= READ_PERMISSION && ipValid === true ?
        <div className={classes.root}>
          <Backdrop className={classes.backdrop} open={dormancyLoading || ipCheckLoading}>
            <CircularProgress color={'primary'} />
          </Backdrop>
          <DormancyToolbar callbackFunc={searchDormacy} />
          <div className={classes.content}>
            {
              dormancyLoading === false && dormancy !== null && dormancy !== undefined &&
              <DormancyTable dormantUser={dormancy.dormantUsers} />
            }
          </div>
        </div> :
        <Typography className={classes.root}>{noPermissionMsg}</Typography>
      }
      <AlertPopup open={popupOpen} handleClose={closePopup} comment={STRING_NO_DORMANT_USER} color={'black'} severity={'info'} />
      <AlertPopup open={dormantPopupOpen} handleClose={closeDormantPopup} comment={STRING_NO_SEARCH_DATA} color={'black'} severity={'info'} />
    </div>
  );
};

export default DormancyManage;
