/* eslint-disable linebreak-style */
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AWS from 'aws-sdk';

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  Input,
  FormGroup,
  FormControlLabel,
  Switch,
  Select,
  MenuItem,
  InputLabel,
  FormControl
} from '@material-ui/core';
import { NO_PERMISSION, WRITE_PERMISSION } from '../../../../Common/Config/naviwatchConfig';
import { getSts } from 'views/Smart/APIs/smartApis';
import { deploy } from 'config';

const useStyles = makeStyles(() => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  },
  input:{
    width: '90%'
  }
}));

const STRING_NOTI_SEND = '등록';
const STRING_TITLE = '공지사항 등록';
const STRING_SUBTITLE = '파일선택 후 업로드버튼을 눌러주세요';
const STRING_NOTI_TITLE = '제목';
const STRING_NOTI_CONTENTS = '내용';
const STRING_UPLOAD = '업로드'
const STRING_LINK = '링크';
const STRING_PUSH_CHECKBOX = '푸시전송';
const STRING_CONDITION_CLEAN = '초기화';
const STRING_NOTI_TYPE = '공지 타입';

const NOTI_OPTIONS = [
  { value: "3100", label: "서버점검사전안내" },
  { value: "3200", label: "APP업데이트안내" },
  { value: "3300", label: "개인정보변경동의" },
  { value: "3400", label: "설문조사 이벤트/광고" },
];

const REGION ='ap-northeast-2';
const S3_BUCKET = {
  dev: 'nskr-dev-naviwatch',
  stage: 'nskr-stage-naviwatch',
  prod: 'nskr-prod-naviwatch',
}

const PushToolbar = props => {
  const { className, callbackFunc, ...rest } = props;
  const classes = useStyles();
  
  const [noticode, setNoticode] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [pushSwitch, setPushSwitch] = useState(false);
  const [values, setValues] = useState({
    title : '',
    content : '',
    link : ''
  });
  const [permission, setPermission] = useState(NO_PERMISSION);

  useEffect(()=>{
    var pageInfo = sessionStorage.getItem('pages');
    var pageInfoObj = JSON.parse(pageInfo);
    var pageArray = pageInfoObj.filter(page=>page.name.toLowerCase().includes('push'));
    if(pageArray!==undefined && pageArray.length > 0) {
      var page = pageArray[0];
      setPermission(page.permission);
    }
    loadCredentials();
  }, []);

  const loadCredentials = async() => {
    try {
      const data = await getSts();
      AWS.config.update(data);
    } catch (error) {
      console.log(`PushToolbar::loadCredentials error = ${JSON.stringify(error)}`);
    }
  }

  const cleanInput = () =>{
    setValues({
      title: '',
      content: '',
      link : '',
    });
    setNoticode("");
    setSelectedFile(null);
    setImageUrl(null);
    document.getElementById('uploadInput').value = '';
    setPushSwitch(false);
  };

  const handleFileInput = (e) => {
    setSelectedFile(e.target.files[0]);
  }

  const uploadFile = (file) => {
    if (file == null) {
      alert(`파일을 선택해주세요`);
      return
    }

    const fileName = 'notice/' + new Date().getTime() + '_' + file.name;
    const myBucket = new AWS.S3({
      params: { Bucket: S3_BUCKET[deploy]},
      region: REGION
    });
    const params = {
      Body: file,
      Bucket: S3_BUCKET[deploy],
      Key: fileName
    };
    myBucket.putObject(params, (err, data) => {
      if (err) {
        alert(`업로드가 실패하였습니다. \n ${err}`);
      } else {
        alert('업로드 되었습니다.');
      }
      console.log(`data: ${JSON.stringify(data)}, err: ${err}`);
    });
    setImageUrl(`https://${S3_BUCKET[deploy]}.s3.ap-northeast-2.amazonaws.com/${fileName}`)
  }

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const sendPush = () =>{
    let output = {
      notificationCode: noticode,
      message: values.content,
      parameters : {
        notiCode: noticode,
        template : {
          title: values.title,
          description: values.content,
          link: (values.link != '')? values.link : null,
          imageUrl: (imageUrl != null)? imageUrl : null,
          buttons:[
            {
              title: "상세보기"
            }
          ]
        }
      },
      push: pushSwitch
    }
    cleanInput();
    callbackFunc(output);
  };

  const handleChangeNoticode = (event) => {
    setNoticode(event.target.value);
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        subheader={<div>
          <span style={{ color: 'red' }}>{STRING_SUBTITLE}</span></div>}
        title={STRING_TITLE}
      />
      <Divider/>
      <CardContent>
        <Grid
          container
          spacing={3}
        >
          <Grid item md={12} xs={12} >
            <FormControl  fullWidth>
              <InputLabel id="select-helper-label">{STRING_NOTI_TYPE}</InputLabel>
              <Select labelId="select-helper-label" value={noticode} onChange={handleChangeNoticode}>
                {NOTI_OPTIONS.map((option) => (
                  <MenuItem  key={option.value} value={option.value}> {option.label} </MenuItem >
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={12} xs={12} >
            <TextField
              fullWidth
              label={STRING_NOTI_TITLE}
              margin="dense"
              name="title"
              onChange={handleChange}
              value={values.title}
              variant="outlined"
            />
          </Grid>
          <Grid item md={12} xs={12} >
            <TextField
              fullWidth
              label={STRING_NOTI_CONTENTS}
              margin="dense"
              multiline
              name="content"
              onChange={handleChange}
              minRows={10}
              value={values.content}
              variant="outlined"
            />
          </Grid>
          <Grid item md={12} xs={12} >
            <TextField 
              fullWidth 
              label={STRING_LINK} 
              name="link"
              onChange={handleChange} 
              size="medium" 
              value={values.link} 
            />
          </Grid>
          <Grid item md={11} xs={11} >
            <Input
              className={classes.input}
              id="uploadInput"
              onChange={handleFileInput}
              type="file"
            />
          </Grid>
          <Grid item md={1} xs={1} container justifyContent="flex-end">
            <Button
              color="secondary"
              component="span"
              onClick={() => uploadFile(selectedFile)}
              size="small"
              variant="outlined"
            > {STRING_UPLOAD} </Button>
          </Grid>
          <Grid item md={12} xs={12} >
            <FormGroup>
              <FormControlLabel 
                control={
                  <Switch 
                    checked={pushSwitch}
                    onClick={() => {
                      setPushSwitch(!pushSwitch);
                    }}
                  />
                } 
                label={STRING_PUSH_CHECKBOX} 
                labelPlacement="start"
              />
            </FormGroup>
          </Grid>
        </Grid>
      </CardContent>
      <Divider/>
      <CardActions className={classes.actions}>
        <Button
          color="primary"
          disabled={noticode===''||values.model==='' && values.title===''&& values.content===''}
          onClick={cleanInput}
          variant="contained"
        >
          {STRING_CONDITION_CLEAN}
        </Button>
        <Button
          color="primary"
          disabled={noticode===''||values.title===''||permission<WRITE_PERMISSION}
          onClick={sendPush}
          variant="contained"
        >
          {STRING_NOTI_SEND}
        </Button>
      </CardActions>
    </Card>
  );
};

PushToolbar.propTypes = {
  className: PropTypes.string
};

export default PushToolbar;
