import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import OperatingModeCharts from './components/OperatingModeChart/OperatingModeCharts';
import TemperatureChart from './components/TemperatureChart/TemperatureChart';
import ConnectionCounts from './components/ConnectionCount/ConnectionCounts';
import ConnectionTrends from './components/ConnectionTrend/ConnectionTrends';
import UserArea from './components/UserArea/UserArea';
import UserAreaDialog from './components/UserAreaDialog/UserAreaDialog';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));


const Statistics = ({ history }) => {
  const classes = useStyles();
  const [userAreaDialogOpen, setUserAreaDialogOpen] = useState(false);
  const [userAreaData, setUserAreaData] = useState({});
  const openUserArea = (areaData) =>{
    setUserAreaData(areaData);
    setUserAreaDialogOpen(true);
  };
  const closeUserArea = () =>{
    setUserAreaDialogOpen(false);
  };
  useEffect(() => {
    if (sessionStorage.getItem('naviwatch_permission') !== 'true')
      history.push('/');
  }, []);

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={4}
      >
      <Grid
      container
      item
          lg={6}
          md={6}
          xl={6}
          xs={12}
      > 
      <Grid
      container
      spacing={2}
      >
        <Grid
        item
        lg={4}
        md={4}
        xl={4}
        xs={4}
        >
        <ConnectionCounts type="roomcontroller" />
        </Grid>
        <Grid
          item
          lg={4}
          md={4}
          xl={4}
          xs={4}
        >
          <ConnectionCounts type="user" />
        </Grid>
        <Grid
          item
          lg={4}
          md={4}
          xl={4}
          xs={4}
        >
          <ConnectionCounts type="control" />
        </Grid>
      </Grid>
        <Grid
          item
          lg={12}
          md={12}
          xl={12}
          xs={12}
        >
          <UserArea dialogCallback={openUserArea}/>
       </Grid>
      </Grid> 

        <Grid
          item
          lg={6}
          md={6}
          xl={6}
          xs={12}
        >
          <OperatingModeCharts/>
        </Grid>

        <Grid
          item
          lg={12}
          md={12}
          xl={12}
          xs={12}
        >
          <ConnectionTrends metricName="User" day="14"/>
        </Grid>

        
        <Grid
          item
          lg={12}
          md={12}
          xl={12}
          xs={12}
        >
          <TemperatureChart metricName="InsideHeat"/>
        </Grid>

        <Grid
          item
          lg={12}
          md={12}
          xl={12}
          xs={12}
        >
          <TemperatureChart metricName="OndolHeat"/>
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          xl={12}
          xs={12}
        >
          <TemperatureChart metricName="WaterHeat"/>
        </Grid>
        <UserAreaDialog openStatus={userAreaDialogOpen} closeFunc={closeUserArea} data={userAreaData}/>
      </Grid>
    </div>
  );
};

export default Statistics;
