import React, { useState } from 'react';
import {
  Dialog, DialogActions, DialogContent, Button,
  DialogTitle, Divider, Typography, makeStyles,
  Backdrop, CircularProgress
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import { deleteAnnouncement } from 'views/Smart/APIs/smartApis';


const STRING_TITLE = '공지사항 삭제';
const STRING_ANNOUNCEMNET_TITLE = '제목 :';
const STRING_ANNOUNCEMENT_CONTENTS = '내용 :';
const STRING_ANNOUNCEMENT_LINK = '링크 :';
const STRING_ANNOUNCEMENT_TYPE = '공지사항 종류 :';
const STRING_ANNOUNCEMENT_REGIST_AT = '공지사항 등록시간 :';
const STRING_ALERT_ANNOUNCEMENT_DELETE = '공지사항 삭제 후 복구가 불가능합니다. 유의해주세요';
const STRING_CLOSE = '취소';
const STRING_DELETE = '삭제';


const useStyles = makeStyles(theme => ({
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  linear:{
    display: 'flex',
    alignItems: 'flex-end'
  },
  datas:{
    marginLeft: theme.spacing(2)
  },
  icons:{
    marginLeft: theme.spacing(2),
    padding: theme.spacing(0)
  },
  fwDescription:{
    marginLeft: theme.spacing(2),
    width: '75%'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));

const DeleteAnnouncementDialog  = props => {
  const { open, handleClose, refetch, content, registerAt } = props;
  const [loading, setLoading] = useState(false);

  console.log('content' + content)
  console.log('registerAt' + registerAt)

  const classes = useStyles();

  const handleCloseClick = () =>{
    handleClose();
  };

  const refetchList = () => {
    setLoading(false);
    refetch();
  };

  const handleDelete = async () => {
    setLoading(true);

    console.log('handle delete')
    deleteAnnouncement(content, registerAt, refetchList)
    
    console.log('handle delete off ')
    handleCloseClick()
  };

  return (
    <div>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color={'primary'}/>
      </Backdrop>
      <Dialog
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{STRING_TITLE}</DialogTitle>
        <DialogContent>
          <Alert severity={'error'}><span style={{ color: 'red' }}>{STRING_ALERT_ANNOUNCEMENT_DELETE}</span></Alert>
          <Divider className={classes.divider}/>
          
          <Divider className={classes.divider}/>
          <div className={classes.linear}>
            <Typography variant="h6">{STRING_ANNOUNCEMENT_CONTENTS}</Typography>
            <Typography className={classes.datas} variant="h6">{content}</Typography>
          </div>
          <Divider className={classes.divider}/>
          <div className={classes.linear}>
            <Typography variant="h6">{STRING_ANNOUNCEMENT_REGIST_AT}</Typography>
            <Typography className={classes.datas} variant="h6">{registerAt}</Typography>
          </div>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseClick} color="primary" autoFocus>
            {STRING_CLOSE}
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            {STRING_DELETE}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

DeleteAnnouncementDialog.propTypes = {
  className: PropTypes.string
};

export default DeleteAnnouncementDialog;
