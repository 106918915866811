import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  DialogTitle,
  Divider,
  Typography,
  makeStyles,
  Backdrop,
  CircularProgress,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination
} from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import {firmwareThingsOfJob, firmwareGetJobFile } from '../../../APIs/mateApis';
import Box from '@material-ui/core/Box';
import {formatter} from '../../../APIs/mateApis'
import useAsync from '../../../../../asyncNet';
import TextField from '@material-ui/core/TextField';
const STRING_CLOSE = '확인';
const useStyles = makeStyles(theme => ({
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  button:{
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex'
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  overview_status_container:{
    display: 'flex',
    justifyContent: 'space-between'
  },
  overview_box_margin:{
    marginTop: theme.spacing(1),
  },
  jobFileTextEdit:{
    width: '700px'
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

const getFWStateCount = (firmware, state) =>{
  if(firmware===undefined || firmware===null || firmware.jobProcessDetails===undefined)
    return 0;
  else{
    switch (state) {
      case "Queued":
        return firmware.jobProcessDetails.numberOfQueuedThings;
      case "In progress":
        return firmware.jobProcessDetails.numberOfInProgressThings;
      case "Timed out":
        return firmware.jobProcessDetails.numberOfTimedOutThings;
      case "Failed":
        return firmware.jobProcessDetails.numberOfFailedThings;
      case "Succeeded":
        return firmware.jobProcessDetails.numberOfSucceededThings;
      case "Rejected":
        return firmware.jobProcessDetails.numberOfRejectedThings;
      case "Canceled":
        return firmware.jobProcessDetails.numberOfCanceledThings;
      case "Removed":
        return firmware.jobProcessDetails.numberOfRemovedThings;
    }
  }
};

const getThingStatus = (resource) =>{
  if(resource.status === "FAILED"){
    return resource.status+"("+resource.reason+")"
  }else{
    return resource.status
  }

}

const getJobId = (firmware) => {
  if (firmware === undefined || firmware === null)
    return 0;
  return firmware.jobId
};

const getJobStatus = (firmware) => {
  if (firmware === undefined || firmware === null)
    return 0;
  return firmware.status
};

const getColorOfJob = (status) =>{
  if (status === "IN_PROGRESS" || status === "QUEUED")
    return "primary";
  else if (status === "FAILED" || status === "TIMED_OUT" ||
    status === "REJECTED" || status === "REMOVED" || status === "DELETION_IN_PROGRESS"){
    return "error";
  } else if (status === "SUCCESS"){
    return "secondary";
  } else {
    return "initial";
  }
};

const getJobDescription = (firmware) => {
  if (firmware === undefined || firmware === null)
    return 0;
  return firmware.description
};

const getJobExeRolloutConf = (firmware) => {
  if (firmware === undefined || firmware === null)
    return 0;
  return firmware.jobExeRolloutConf
};

const getJobTargets = (firmware) => {
  if (firmware === undefined || firmware === null )
    return 0;
  return firmware.targets
};

const getJobCreateTime = (firmware) => {
  if (firmware === undefined || firmware === null)
    return 0;
  return formatter(firmware.createTime)
};

const getJobLastUpdatedAt = (firmware) => {
  if (firmware === undefined || firmware === null)
    return 0;
  return formatter(firmware.lastUpdatedAt)
};

const DetailInfoDialog  = props => {
  const { open, handleClose, refetch, selectedFW } = props;
  const [queryString, setQueryString] = useState({
    majorVersion: 0,
    minorVersion: 0,
    modelCode: 0,
    buildNumber: 0,
    fwType : 0,
    state: "",
    token: ""
  });
  const getResourcesOfJobs = () => {
    return firmwareThingsOfJob(queryString.majorVersion, queryString.minorVersion,
      queryString.modelCode, queryString.buildNumber, queryString.fwType,
      queryString.state, queryString.token);
  };
  const [resourcesOfJob, getResourcesOfJob] = useAsync(getResourcesOfJobs, [queryString]);
  const { loading, data: resource } = resourcesOfJob;

  const [value, setValue] = React.useState(0);
  const [jobDesc, setJobDesc] = React.useState({});
  const [resources, setResources] = React.useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const classes = useStyles();

  useEffect(()=>{
    if(open===true){
      console.log("selectedFW: "+JSON.stringify(selectedFW))
      setResources([]);
      setJobDesc({});
      setQueryString({
        majorVersion: selectedFW.majorVersion,
        minorVersion: selectedFW.minorVersion,
        modelCode: selectedFW.modelCode,
        buildNumber: selectedFW.buildNumber,
        fwType : selectedFW.fwType,
        state: "",
        token: ""
      });
    }
  },[open]);

  useEffect(()=>{
    if(open===true && resource!==null && resource.resources !== undefined){
      setResources(resources.concat(resource.resources))
    }
    if(open===true && resource!==null && resource.jobDesc !== undefined){
      setJobDesc(resource.jobDesc)
    }
  },[resource]);

  useEffect(()=>{
    console.log("change resources : "+resources)
  }, [resources]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCloseClick = () =>{
    handleClose();
  };

  const a11yProps =index => {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  };

  const handlePageChange = (event, page) => {
    setPage(page);
  };
  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };
  const resourceTable = (styleClass, resources) =>{
    return(
      <div>
        <Divider className={styleClass.divider}/>
        <TableContainer component={Paper}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Resource</TableCell>
                <TableCell align="center">Last updated</TableCell>
                <TableCell align="center">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {resources!==undefined && resources.slice((page * rowsPerPage), ((page + 1) * rowsPerPage)).map(resource => (
                <TableRow key={resource.thingArn}>
                  <TableCell align="center">{resource.thingArn}</TableCell>
                  <TableCell align="center">{formatter(resource.lastUpdatedAt)}</TableCell>
                  <TableCell align="center">{getThingStatus(resource)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  count={resources !== undefined?resources.length:0}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handleRowsPerPageChange}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={[5, 10, 25]}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    );
  };

  const statusBtnClicked = (state) =>{
    setResources([])
    setQueryString({
      ...queryString,
      ['state']: state
    }
    )
  };

  const OverviewPanel =(classes) =>{
    return(
      <div>
        <Backdrop className={classes.backdrop} open={ loading }>
          <CircularProgress color={'primary'}/>
        </Backdrop>
        <div className={classes.overview_status_container}>
          <Typography className={classes.overview_box_margin} component={'span'} variant="h6" col>Last updated : {getJobLastUpdatedAt(jobDesc)}</Typography>
          <div>
            <Typography className={classes.overview_box_margin} component={'span'} variant="h6" >Status : </Typography>
            <Typography className={classes.overview_box_margin} component={'span'} variant="h6" color={getColorOfJob(getJobStatus(jobDesc))}>{getJobStatus(jobDesc)}</Typography>
          </div>
          <Button
            variant="outlined"
            onClick={()=>statusBtnClicked("")}>
            <Typography>
              All status
            </Typography>
          </Button>
        </div>
        <br/>
        <div className={classes.button}>
          <Button
            variant="outlined"
            onClick={()=>statusBtnClicked("QUEUED")}>
            <Typography variant={"caption"}>
              {getFWStateCount(jobDesc, "Queued")}<br/>
              Queued
            </Typography>
          </Button>
          <Button
            variant="outlined"
            onClick={()=>statusBtnClicked("IN_PROGRESS")}>
            <Typography variant={"caption"}>
              {getFWStateCount(jobDesc, "In progress")}<br/>
              In progress
            </Typography>
          </Button>
          <Button
            variant="outlined"
            onClick={()=>statusBtnClicked("TIMED_OUT")}>
            <Typography variant={"caption"}>
              {getFWStateCount(jobDesc, "Timed out")}<br/>
              Timed out
            </Typography>
          </Button>
          <Button
            variant="outlined"
            onClick={()=>statusBtnClicked("FAILED")}>
            <Typography variant={"caption"}>
              {getFWStateCount(jobDesc, "Failed")}<br/>
              Failed
            </Typography>
          </Button>
          <Button
            variant="outlined"
            onClick={()=>statusBtnClicked("SUCCEEDED")}>
            <Typography variant={"caption"}>
              {getFWStateCount(jobDesc, "Succeeded")}<br/>
              Succeeded
            </Typography>
          </Button>
          <Button
            variant="outlined"
            onClick={()=>statusBtnClicked("REJECTED")}>
            <Typography variant={"caption"}>
              {getFWStateCount(jobDesc, "Rejected")}<br/>
              Rejected
            </Typography>
          </Button>
          <Button
            variant="outlined"
            onClick={()=>statusBtnClicked("CANCELED")}>
            <Typography variant={"caption"}>
              {getFWStateCount(jobDesc, "Canceled")}<br/>
              Canceled
            </Typography>
          </Button>
          <Button
            variant="outlined"
            onClick={()=>statusBtnClicked("REMOVED")}>
            <Typography variant={"caption"}>
              {getFWStateCount(jobDesc, "Removed")}<br/>
              Removed
            </Typography>
          </Button>
        </div>
        {resourceTable(classes, resources)}
      </div>
    )
  };

  const listToStringWithNewLine = (list) =>{
    var i;
    var temp=[];
    var splitedString;
    if (list === undefined || list === null){
      return "";
    }
    for(i=0;i<list.length;++i){
      splitedString = list[i].split('/');
      temp.push(splitedString[1]);
    }
    return temp.join(", ")
  };

  const DetailPanel=(classes, firmware) => {
    const getJobfile = (firmware) => {
      if(firmware===undefined||firmware===null){
        return "";
      }
      else {
        return firmwareGetJobFile(firmware.jobId);
      }
    };
    const [jobFile, getJobFile] = useAsync(()=>getJobfile(jobDesc), [jobDesc]);
    const { loading, data: jobDoc } = jobFile;

    return (
      <div>
        <Typography variant="h4">Details</Typography>
        <Divider className={classes.divider}/>
        <Typography variant="h5">Job ID</Typography>
        <Typography variant="body1">{getJobId(jobDesc)}</Typography>
        <br/>
        <Typography variant="h5">Description</Typography>
        <Typography variant="body1">{getJobDescription(jobDesc)}</Typography>
        <br/>
        <Typography variant="h5">Job executions rollout configuration</Typography>
        <Typography variant="body1">{getJobExeRolloutConf(jobDesc)}</Typography>
        <br/>
        <Typography variant="h5">Job executions timeout configuration</Typography>
        <Typography variant="body1">{""}</Typography>
        <br/>
        <Typography variant="h5">Devices to update</Typography>
        <Typography variant="body1">{getJobTargets(jobDesc)}</Typography>
        <br/>
        <Typography variant="h5">Created time</Typography>
        <Typography variant="body1">{getJobCreateTime(jobDesc)}</Typography>
        <br/>
        <Typography variant="h5">Last updated time</Typography>
        <Typography variant="body1">{getJobLastUpdatedAt(jobDesc)}</Typography>
        <br/>
        <Typography variant="h5">View stored job file</Typography>
        <TextField className={classes.jobFileTextEdit}
          id="jobFile"
          multiline
          disabled
          rows={10}
          defaultValue={jobDoc!==null&&jobDoc!==undefined&&jobDoc.jobDoc!==undefined&&jobDoc.jobDoc['document']}
          variant="outlined"
        />
      </div>
    )
  };

  const TagsPanel=(classes, firmware) => {
    return (
      <div>
        <Typography variant="h4">Tags</Typography>
        <Divider className={classes.divider}/>
      </div>
    )
  };

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <div><Typography component={'span'} variant="h6">JOB</Typography><br/>
            <Typography component={'span'} variant="h4">{selectedFW.jobId}</Typography><br/>
          </div>
        </DialogTitle>
        <DialogContent className={classes.root}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            className={classes.tabs}
          >
            <Tab label="Overview" {...a11yProps(0)} />
            <Tab label="Details" {...a11yProps(1)} />
            <Tab label="Resource Tags" {...a11yProps(2)} />
          </Tabs>
          <TabPanel value={value} index={0}>
            {OverviewPanel(classes)}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {DetailPanel(classes,selectedFW)}
          </TabPanel>
          <TabPanel value={value} index={2}>
            {TagsPanel(classes,selectedFW)}
          </TabPanel>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseClick} color="primary" autoFocus>
            {STRING_CLOSE}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

DetailInfoDialog.propTypes = {
  className: PropTypes.string
};

export default DetailInfoDialog;
