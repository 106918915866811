import React, { useEffect, useState, useRef } from 'react';
import {
  Dialog, DialogActions, DialogContent, Button,
  DialogTitle, Divider, Typography, makeStyles,
  Backdrop, CircularProgress,
  Accordion, AccordionSummary, AccordionDetails,
} from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import { firmwareGetGroupUsers, firmwareSetGroupUsers, getS3Path } from '../../../APIs/mateApis';
import useAsync from '../../../../../asyncNet';
import TextField from '@material-ui/core/TextField';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const STRING_TITLE = 'GROUP OTA 대상 설정';

const STRING_CURRENT_GROUP = '현재 등록된 그룹';
const STRING_MODIFY_GROUP = '그룹 수정';

const STRING_ALERT_GROUP_OTA_ENABLED = 'GROUP OTA를 수행할 MAC 이나 User를 선택하세요.';
const STRING_CLOSE = '취소';
const STRING_GROUP_CHANGE = '변경';
const GROUP_EMPTY_WARNING = "수정할 그룹을 지정해주세요.";
const GROUP_NON_JSON_WARNING = "JSON 값이 비정상입니다.";

const useStyles = makeStyles(theme => ({
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  linear:{
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1)

  },
  datas:{
    marginLeft: theme.spacing(2)
  },
  icons:{
    marginLeft: theme.spacing(2),
    padding: theme.spacing(0)
  },
  fwDescription:{
    marginLeft: theme.spacing(2),
    width: '75%'
  },
  button:{
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const GroupOTADialog  = props => {
  const { open, ipAddress, handleClose, refetch, selectedFW } = props;
  const getUsersAtGroup = () => {
    return firmwareGetGroupUsers(selectedFW.majorVersion,
      selectedFW.minorVersion, selectedFW.modelCode, selectedFW.buildNumber);
  };
  const [state, tryRefetch] = useAsync(getUsersAtGroup, [], true);
  const { loading, data: groupUsers } = state;
  const [ updateLoading, setUpdateLoading ] = useState(false);
  const [ updatedUserList, setUpdatedUserList ] = useState("");
  const [ warningMessage, setWarningMessage ] = useState("");
  const textInput = useRef();

  useEffect(()=>{
    tryRefetch();
    }, [open]);


  const placeHolderValue = [
    {
      "USERNAME": "Sample1"
    },
    {
      "USERNAME": "Sample2"
    }
  ];

  const classes = useStyles();

  const handleCloseClick = () =>{
    handleClose();
  };

  const refetchList = (warningMessage) => {
    setUpdateLoading(false);
    refetch(warningMessage);
  };


  const changeGroupList = () =>{
    var ret;
    var arrayValue = updatedUserList.replace(/(\r\n|\n|\r)/gm, "");
    console.log("changed value : "+arrayValue)
    ret = firmwareSetGroupUsers(sessionStorage.getItem('email'), ipAddress, selectedFW.majorVersion,
      selectedFW.minorVersion, selectedFW.modelCode, selectedFW.buildNumber,
      selectedFW.fwType, arrayValue, getS3Path(selectedFW.modelCode));
    setUpdateLoading(true);
    handleCloseClick()
    return ret;
  };

  const [groupChangeState, callGroupChange] = useAsync(changeGroupList, [], true);
  const { groupChangeLoading, data: changeResponse } = groupChangeState;

  const handleChange = () => {
    callGroupChange();
  };

  const editUpdateUser = event =>{
    setUpdatedUserList(event.target.value);
  };

  useEffect(()=> {
    if (changeResponse !== null && changeResponse !== undefined){
      if (changeResponse.code === 200) {
        refetchList("그룹 수정이 성공하였습니다.")
      } else {
        refetchList("예상치 못한 에러가 발생하였습니다.")
      }
    }
  }, [changeResponse]);

  useEffect(()=>{
    if(updatedUserList.length===0){
      setWarningMessage(GROUP_EMPTY_WARNING)
    }
    else if(updatedUserList.length>0) {
      var arrayValue = updatedUserList.replace(/(\r\n|\n|\r)/gm, "");
      try {
        var parsedJson = JSON.parse(arrayValue);
        var count = parsedJson.length;
        if( count >= 1){
          if(Object.keys(parsedJson[0]).includes("USERNAME")===true){
            setWarningMessage("")
          }else{
            setWarningMessage(GROUP_NON_JSON_WARNING)
          }
        }
      } catch (e) {
        setWarningMessage(GROUP_NON_JSON_WARNING)
      }
    }else {
      setWarningMessage(GROUP_EMPTY_WARNING)
    }

  } ,[updatedUserList]);


  return (
    <div>
      <Backdrop className={classes.backdrop} open={ updateLoading }>
        <CircularProgress color={'primary'}/>
      </Backdrop>
      <Dialog
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{STRING_TITLE}</DialogTitle>
        <DialogContent>
          <Alert severity={'error'}><span style={{ color: 'red' }}>{STRING_ALERT_GROUP_OTA_ENABLED}</span></Alert>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography className={classes.heading}>{STRING_CURRENT_GROUP}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <TextField
                  id="current-user-id"
                  label="Current UserID"
                  multiline
                  disabled
                  fullWidth
                  rows={15}
                  defaultValue={groupUsers!=null && JSON.stringify(groupUsers['userList'], null, 2)}
                  variant="outlined"
                  ref={textInput}
                />
            </AccordionDetails>
            <div className={classes.linear}>
              <CopyToClipboard text={groupUsers!=null?JSON.stringify(groupUsers['userList'],null, 2):""}>
                <Button
                  variant="outlined"
                >
                  Copy to clipboard
                </Button>
            </CopyToClipboard>
            </div>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <Typography className={classes.heading}>{STRING_MODIFY_GROUP}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <TextField
                  id="update-user-id"
                  multiline
                  fullWidth
                  rows={15}
                  placeholder={JSON.stringify(placeHolderValue, null, 2)}
                  onChange={editUpdateUser}
                  value={updatedUserList}
                  variant="outlined"
                />
            </AccordionDetails>
          </Accordion>
          <Divider className={classes.divider}/>
          <Typography>{warningMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseClick} color="primary" autoFocus>
            {STRING_CLOSE}
          </Button>
          <Button onClick={handleChange} color="primary"
                  disabled={warningMessage.length>0} autoFocus>
            {STRING_GROUP_CHANGE}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

GroupOTADialog.propTypes = {
  className: PropTypes.string
};

export default GroupOTADialog;
