import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import palette from 'theme/palette';

import {options} from './chart';
import useAsync from '../../../../../asyncNet';
import {
  dashboardRegionStatistics
} from '../../../APIs/aironeApis';

const useStyles = makeStyles(() => ({
  root: {},
  chartContainer: {
    height: 400,
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));


const STRING_ALLREGION='모든 지역 보기';
const STRING_REGION_COUNT='지역별 룸콘 분포';

const DeviceArea = props => {

  const { className, dialogCallback, ...rest } = props;
  const [state] = useAsync(() => dashboardRegionStatistics(), []);
  const { data: counts } = state;
  const classes = useStyles();
  const [areaData, setAreaData] = useState({
    labels:[],
    datasets:[]
  });


  useEffect( ()=>{
    try {
      if(counts !== null && counts !== undefined && counts['countryArray']!==undefined) {
        setAreaData(areaData => ({
          ...areaData,
          labels: counts['countryArray'].slice(0,7),
          datasets: [
            {
              label: '1 세대',
              backgroundColor: palette.primary.main,
              data: counts['modelNtr10pwArray'].slice(0,7)
            },
            {
              label: '2 세대',
              backgroundColor: palette.info.main,
              data: counts['modelNtr10pw2ndArray'].slice(0,7)
            },
            {
              label: '3 세대',
              backgroundColor: palette.error.main,
              data: counts['modelNrt20dArray'].slice(0,7)
            }
          ]
        }))
      }
    }catch (e) {
      console.log('areaCount update failed :'+JSON.stringify(e));
    }
  }, [counts])

  return (
    <Card
    {...rest}
    className={clsx(classes.root, className)}
  >
    <CardHeader
      title={STRING_REGION_COUNT}
    />

     <Divider />
      <CardContent>
        <div className={classes.chartContainer}>
          <Bar data={areaData} options={options}/>
        </div>
      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>
        <Button
          color="primary"
          size="small"
          variant="text"
          onClick={()=>{
            dialogCallback(counts)
          }}
        >
          {STRING_ALLREGION} <ArrowRightIcon />
        </Button>
      </CardActions>
    </Card>
  );
};

DeviceArea.propTypes = {
  className: PropTypes.string
};

export default DeviceArea;
