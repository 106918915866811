import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import ZoomInTwoToneIcon from '@material-ui/icons/ZoomInTwoTone';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Checkbox,
  Typography
} from '@material-ui/core';
import DetailDialog from './DetailDialog';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'space-between'
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  jobHistoryTextEdit: {
    width: '700px',
    whiteSpace: 'pre-line'
  },
  tableRow: {
    height: 30
  },
  tableCell: {
    padding: '0px 16px'
  }
}));

const StyledTableRow = withStyles(theme => ({
  root: {
    height: 30
  }
}))(TableRow);

const StyledTableCell = withStyles(theme => ({
  root: {
    padding: '0px 16px'
  }
}))(TableCell);

const TableType = {
  CONNECTION: 'Connection',
  DEVICE: 'Device',
  CONTROL: 'Control'
};

const DeviceHistoryTable = props => {
  const { historyData } = props;

  const classes = useStyles();
  const [tableData, setTableData] = useState([]);

  const [selectedDevices] = useState([]);
  const [currentPayload, setCurrentPayload] = useState();

  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);

  const [detailDialogOpen, setDetailDialogOpen] = React.useState(false);
  const [connectionShowState, setConnectionShowState] = useState(true);
  const [deviceShowState, setDeviceShowState] = useState(true);
  const [controlShowState, setControlShowState] = useState(true);

  useEffect(() => {
    setConnectionShowState(true);
    setDeviceShowState(true);
    setControlShowState(true);
    setTableData(historyData);
  }, [historyData]);

  useEffect(() => {
    const newFilterData = historyData.filter(
      data =>
        (data.type === TableType.CONNECTION && connectionShowState) ||
        (data.type === TableType.DEVICE && deviceShowState) ||
        (data.type === TableType.CONTROL && controlShowState)
    );
    setPage(0);
    setTableData(newFilterData);
  }, [connectionShowState, deviceShowState, controlShowState]);

  const handleClickOpen = current => {
    setCurrentPayload(current);
    setDetailDialogOpen(true);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setPage(0);
    setRowsPerPage(event.target.value);
  };

  return (
    <div>
      <Card>
        <CardContent>
          <PerfectScrollbar>
            <div>
              <Table>
                <TableHead>
                  <TableRow className={classes.tableRow}>
                    <TableCell align={'center'} className={classes.tableCell}>
                      <span>
                        <b>{'Type'}</b>
                      </span>
                    </TableCell>
                    <TableCell align={'center'} className={classes.tableCell}>
                      <span>
                        <b>{'Topic'}</b>
                      </span>
                    </TableCell>
                    <TableCell align={'center'} className={classes.tableCell}>
                      <span>
                        <b>{'Date'}</b>
                      </span>
                    </TableCell>
                    <TableCell align={'center'} className={classes.tableCell}>
                      <span>
                        <b>{'Detail'}</b>
                      </span>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData !== null &&
                    tableData !== undefined &&
                    tableData
                      .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                      .map(dev => (
                        <StyledTableRow
                          hover
                          key={dev.id}
                          selected={selectedDevices.indexOf(dev.id) !== -1}>
                          {dev.type === 'Connection' ? (
                            <StyledTableCell
                              style={{ backgroundColor: '#FF6666' }}
                              align={'center'}>
                              {dev.type}
                            </StyledTableCell>
                          ) : dev.type === 'Device' ? (
                            <StyledTableCell
                              style={{ backgroundColor: '#C0C0C0' }}
                              align={'center'}>
                              {dev.type}
                            </StyledTableCell>
                          ) : (
                            <StyledTableCell
                              style={{ backgroundColor: '#99CCFF' }}
                              align={'center'}>
                              {dev.type}
                            </StyledTableCell>
                          )}
                          <StyledTableCell align={'left'}>
                            {dev.topic}
                          </StyledTableCell>
                          <StyledTableCell align={'center'}>
                            {moment(dev.date).format('YYYY-MM-DDTHH:mm:ss.SSS+09:00')}
                          </StyledTableCell>
                          <StyledTableCell align={'center'}>
                            <Tooltip title={'상세보기'}>
                              <IconButton
                                aria-label={'상세보기'}
                                onClick={() => handleClickOpen(dev.detail)}>
                                <ZoomInTwoToneIcon />
                              </IconButton>
                            </Tooltip>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <Typography style={{ marginLeft: 15, marginRight: 0 }} variant="h6">
              {'Connection'}
            </Typography>
            <Checkbox
              checked={connectionShowState}
              onChange={() => setConnectionShowState(!connectionShowState)}
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
            <Typography style={{ marginLeft: 20, marginRight: 0 }} variant="h6">
              {'Device'}
            </Typography>
            <Checkbox
              checked={deviceShowState}
              onChange={() => setDeviceShowState(!deviceShowState)}
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
            <Typography style={{ marginLeft: 20, marginRight: 0 }} variant="h6">
              {'Control'}
            </Typography>
            <Checkbox
              checked={controlShowState}
              onChange={() => setControlShowState(!controlShowState)}
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </div>
          {tableData !== null && tableData !== undefined && (
            <TablePagination
              component="div"
              count={
                tableData !== null &&
                tableData !== undefined &&
                tableData.length
              }
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleRowsPerPageChange}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[25, 50, 100]}
            />
          )}
        </CardActions>
      </Card>
      <DetailDialog
        open={detailDialogOpen}
        handleClose={() => setDetailDialogOpen(false)}
        data={currentPayload}
      />
    </div>
  );
};

DeviceHistoryTable.propTypes = {
  className: PropTypes.string,
  historyData: PropTypes.array.isRequired
};

export default DeviceHistoryTable;
