import React, { useState, useEffect } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Backdrop,
  CircularProgress
} from '@material-ui/core';
import {
  Grid,
  Button,
  IconButton,
  TextField,
  Link,
  FormHelperText,
  Checkbox,
  Typography
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Auth } from 'aws-amplify';

const STR_RESET_PASSWORD_WELCOME = '비밀번호 재설정';
const STR_RESET_PASSWORD_DESCRIPTION =
  '비밀번호 재설정 위해 사용하신 이메일을 입력 후 인증 코드를 발송하세요.';
const STR_RESET_PASSWORD_INSERT_TITLE = '신규 비밀번호 입력';
const STR_RESET_PASSWORD_INSERT_DESCRIPTION =
  '새로 설정할 비밀번호와 이메일로 수신된 인증 코드를 입력하세요.';
const STR_NEW_PASSWORD = '새 비밀번호';
const STR_NEW_PASSWORD_CHECK = '새 비밀번호 확인';
const STR_READ_LEGALDOC = '을 읽었습니다.';
const STR_ALREADY_SIGNUP = '계정이 있으신가요?';
const STR_REGISTER_USER = '비밀번호 변경';
const STR_SIGN_IN = '로그인';
const STR_EMAIL_SCHEME_ERROR_TITLE = '가입 실패';
const STR_EMAIL_SCHEME_ERROR_CONTENT1 = '죄송합니다. ';
const STR_EMAIL_SCHEME_ERROR_CONTENT2 =
  '@kdiwin.com 메일 서버 가입자만 가입이 가능합니다.';
const STR_PASSWORD_ERROR_TITLE = '가입 실패';
const STR_PASSWORD_SHORT_CONTENT1 = '죄송합니다. ';
const STR_PASSWORD_SHORT_CONTENT2 = '비밀번호는 최소 8자 이상 기입해주세요.';
const STR_SEND_VERIFICATION_CODE_TITLE = '인증코드를 확인해주세요.';
const STR_SEND_VERIFICATION_CODE_CONTENT1 = '인증코드가 발송되었습니다.';
const STR_SEND_VERIFICATION_CODE_CONTENT2 =
  '메일을 확인한 뒤, 인증코드를 기입해주세요';
const STR_ERR_VERIFICATION_CODE_TITLE = '인증코드를 확인 실패.';
const STR_ERR_VERIFICATION_CODE_CONTENT1 = '인증코드가 유효하지 않습니다.';
const STR_ERR_VERIFICATION_CODE_CONTENT2 =
  '인증코드를 재전송한 뒤, 다시 시도하세요.';
const STR_ERR_VERIFICATION_LIMIT_EXCEED_TITLE = '인증코드를 확인 실패.';
const STR_ERR_VERIFICATION_LIMIT_EXCEED_CONTENT1 =
  '인증코드 검증 제한이 초과하였습니다.';
const STR_ERR_VERIFICATION_LIMIT_EXCEED_CONTENT2 = '나중에 다시 시도하세요.';
const STR_SIGNUP_FAIL_ALREADY_REGISTERED_TITLE = '가입 실패';
const STR_SIGNUP_FAIL_ALREADY_REGISTERED_CONTENT =
  '죄송합니다. 이미 가입된 사용자입니다.';
const STR_SIGNUP_FAIL_TITLE = '가입 실패';
const STR_SIGNUP_FAIL_CONTENT =
  '죄송합니다. 내부적인 문제로 가입되지 않습니다.';
const STR_ASSIGN_USER = '회원 이메일';
const STR_CONFIRM_NUMBER = '인증번호';
const STR_SEND_CODE = '인증 코드 발송';
const STR_RESEND_VEFIRICATION_CODE = '재전송';
const STR_KDNAVIEN_EMAILFORM = '@kdiwin.com';
const STR_OK_VERIFICATION_TITLE = '비밀번호 재설정 완료';
const STR_OK_VERIFICATION_CONTENT1 = '비밀번호 재설정이 완료되었습니다.';
const STR_OK_VERIFICATION_CONTENT2 = '로그인 페이지로 이동합니다.';

const schema = {
  newPassword: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      min: 8,
      maximum: 128
    }
  },
  newPasswordCheck:{
    equality: "newPassword"
  },
  verifyNumber : {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      is:6
    }
  }
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/intro.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 20,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  policy: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center'
  },
  policyCheckbox: {
    marginLeft: '-14px'
  },
  signUpButton: {
    margin: theme.spacing(2, 0)
  },
  verifyField: {
    display: 'flex',
    alignItems: 'flexCenter',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2)
  },
  doneVerifyBtn: {
    marginTop: theme.spacing(2, 0)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));

const PasswordReset = props => {
  const { history } = props;

  const classes = useStyles();

  const [dialogOpen, setDialog] = useState(false);
  const [dialogTxt, setDialogTxt] = useState({
    dialogTitle: '',
    dialogContent1: '',
    dialogContent2: ''
  });

  const setDialogOpen = () => {
    setBackdrop(false);
    setDialog(true);
  };

  const setDialogClose = () => {
    setDialog(false);
    if (formState.isFinishSignup === true) {
      console.log('go back to login page');
      history.push('/');
    }
  };

  const [backdropStatus, setBackdrop] = useState(false);
  const [formState, setFormState] = useState({
    isPasswordValid: false,
    isVerifyValid: false,
    isFinishSignup: false,
    isSignupValid:false,
    isSendVerifyNumber: false,
    verifyUserEmail: '',
    verifyNumber: '',
    values: {
      newPassword: '',
      newPasswordCheck:'',
      verifyNumber:''
    },
    touched: {},
    errors: {}
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);
    console.log(errors)
    console.log(JSON.stringify(formState.values))

    setFormState(formState => ({
      ...formState,
      isSignupValid: errors,
      errors: errors || {}
    }));

    if (formState.verifyUserEmail.includes(STR_KDNAVIEN_EMAILFORM) === true) {
      setFormState(formState => ({
        ...formState,
        isVerifyValid: true
      }));
    }
  }, [formState.values, formState.values.newPassword, formState.values.newPasswordCheck, formState.values.verifyNumber, formState.verifyUserEmail]);

  useEffect(() => {
    if (formState.isFinishSignup) {
      dialogOn(
        STR_OK_VERIFICATION_TITLE,
        STR_OK_VERIFICATION_CONTENT1,
        STR_OK_VERIFICATION_CONTENT2
      );
    } else if (formState.isSendVerifyNumber) {
      dialogOn(
        STR_SEND_VERIFICATION_CODE_TITLE,
        STR_SEND_VERIFICATION_CODE_CONTENT1,
        STR_SEND_VERIFICATION_CODE_CONTENT2
      );
    }
  }, [formState.isFinishSignup, formState.isSendVerifyNumber]);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const onChangeUserEmail = event => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      [event.target.name]: event.target.value
    }));
  };

  const handleBack = () => {
    history.goBack();
  };

  const dialogOn = (title, content1, content2) => {
    setDialogTxt({
      ...dialogTxt,
      dialogTitle: title,
      dialogContent1: content1,
      dialogContent2: content2
    });
    setDialogOpen();
  };

  const confirmPasswordReset = event => {
    event.preventDefault();
    setBackdrop(true);
    forgotPassword(formState.verifyUserEmail)
      .then(() => {
        console.log('Success verify code Signup');
        setFormState(formState => ({
          ...formState,
          isSendVerifyNumber: true
        }));
        setBackdrop(false);
      })
      .catch(e => {
        console.log('fail send code : ' + e.code);
      });
  };

  const handleResetPassword = event => {
    event.preventDefault();
    setBackdrop(true);
    forgotPasswordSubmit(
      formState.verifyUserEmail,
      formState.values.verifyNumber,
      formState.values.newPassword
    )
      .then(() => {
        setFormState(formState => ({
          ...formState,
          isFinishSignup: true
        }));
        console.log('success password change');
      })
      .catch(e => {
        if (
          e.code === 'ExpiredCodeException' ||
          e.code === 'CodeMismatchException'
        ) {
          dialogOn(
            STR_ERR_VERIFICATION_CODE_TITLE,
            STR_ERR_VERIFICATION_CODE_CONTENT1,
            ''
          );
          console.log('fail signup : ' + e.code);
        } else if (e.code === 'LimitExceededException') {
          dialogOn(
            STR_ERR_VERIFICATION_LIMIT_EXCEED_TITLE,
            STR_ERR_VERIFICATION_LIMIT_EXCEED_CONTENT1,
            STR_ERR_VERIFICATION_LIMIT_EXCEED_CONTENT2
          );
          console.log('Fail signup');
        } else {
          dialogOn(STR_SIGNUP_FAIL_TITLE, STR_SIGNUP_FAIL_CONTENT, '');
          console.log('fail signup : ' + e.code);
        }
        console.log('Exception : ' + JSON.stringify(e));
      });
  };

  // Send confirmation code to user's email or phone number
  async function forgotPassword(username) {
    try {
      const data = await Auth.forgotPassword(username);
      console.log(data);
    } catch (err) {
      console.log(err);
    }
  }

  // Collect confirmation code and new password
  async function forgotPasswordSubmit(username, code, newPassword) {
    try {
      const data = await Auth.forgotPasswordSubmit(username, code, newPassword);
      console.log(data);
    } catch (err) {
      console.log(err);
    }
  }

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={backdropStatus}>
        <CircularProgress color={'primary'} />
      </Backdrop>

      <Dialog
        open={dialogOpen}
        onClose={setDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {dialogTxt.dialogTitle}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogTxt.dialogContent1}
            <br />
            {dialogTxt.dialogContent2}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={setDialogClose} color="primary">
            네
          </Button>
        </DialogActions>
      </Dialog>
      <Grid className={classes.grid} container>
        <Grid className={classes.quoteContainer} item lg={5}>
          <div className={classes.quote}>
            <div className={classes.quoteInner}>
              <Typography className={classes.quoteText} variant="h1">
                <p>Smarter Living Environment Partner,</p>
                <p>KD Navien</p>
              </Typography>
              <div className={classes.person}></div>
            </div>
          </div>
        </Grid>
        <Grid className={classes.content} item lg={7} xs={12}>
          <div className={classes.content}>
            <div className={classes.contentHeader}>
              <IconButton onClick={handleBack}>
                <ArrowBackIcon />
              </IconButton>
            </div>
            <div className={classes.contentBody}>
              <div>
                <form className={classes.form} onSubmit={confirmPasswordReset}>
                  <Typography className={classes.title} variant="h2">
                    {STR_RESET_PASSWORD_WELCOME}
                  </Typography>
                  <Typography
                    className={classes.textField}
                    color="textSecondary"
                    gutterBottom>
                    {STR_RESET_PASSWORD_DESCRIPTION}
                  </Typography>
                  <TextField
                    className={classes.textField}
                    fullWidth
                    label={STR_ASSIGN_USER}
                    name="verifyUserEmail"
                    onChange={onChangeUserEmail}
                    type="text"
                    disabled={formState.isSendVerifyNumber}
                    value={formState.verifyUserEmail || ''}
                    variant="outlined"
                  />
                  <div className={classes.verifyField}>
                    <Button
                      className={classes.doneVerifyBtn}
                      color="primary"
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      disabled={!formState.isVerifyValid}>
                      {formState.isSendVerifyNumber ? STR_RESEND_VEFIRICATION_CODE : STR_SEND_CODE}
                    </Button>
                  </div>
                  <Typography
                    className={classes.textField}
                    color="textSecondary"
                    variant="body1">
                    {STR_ALREADY_SIGNUP}{' '}
                    <Link component={RouterLink} to="/sign-in" variant="h6">
                      {STR_SIGN_IN}
                    </Link>
                  </Typography>
                </form>
                {formState.isSendVerifyNumber && (
                  <form className={classes.form} onSubmit={handleResetPassword}>
                    <Typography className={classes.title} variant="h3">
                      {STR_RESET_PASSWORD_INSERT_TITLE}
                    </Typography>
                    <Typography
                      className={classes.textField}
                      color="textSecondary"
                      gutterBottom>
                      {STR_RESET_PASSWORD_INSERT_DESCRIPTION}
                    </Typography>
                    <TextField
                      className={classes.textField}
                      error={hasError('newPassword')}
                      fullWidth
                      helperText={
                        hasError('newPassword')
                          ? formState.errors.newPassword[0]
                          : null
                      }
                      label={STR_NEW_PASSWORD}
                      name="newPassword"
                      onChange={handleChange}
                      type="password"
                      value={formState.values.newPassword || ''}
                      variant="outlined"
                    />
                    <TextField
                      className={classes.textField}
                      error={hasError('newPasswordCheck')}
                      fullWidth
                      helperText={
                        hasError('emanewPasswordCheckil')
                          ? formState.errors.email[0]
                          : null
                      }
                      label={STR_NEW_PASSWORD_CHECK}
                      name="newPasswordCheck"
                      onChange={handleChange}
                      type="password"
                      value={formState.values.newPasswordCheck || ''}
                      variant="outlined"
                    />
                    <TextField
                      className={classes.textField}
                      error={hasError('verifyNumber')}
                      fullWidth
                      label={STR_CONFIRM_NUMBER}
                      name="verifyNumber"
                      onChange={handleChange}
                      type="text"
                      value={formState.values.verifyNumber || ''}
                      inputProps={{ maxLength: 6 }}
                      variant="outlined"
                    />
                    <Button
                      className={classes.signUpButton}
                      color="primary"
                      disabled={formState.isSignupValid}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained">
                      {STR_REGISTER_USER}
                    </Button>
                  </form>
                )}
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

PasswordReset.propTypes = {
  history: PropTypes.object
};

export default withRouter(PasswordReset);
