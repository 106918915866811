import React, { useState } from 'react';
import {
  Dialog, DialogActions, DialogContent, Button,
  DialogTitle, Divider, Typography, makeStyles,
  Checkbox, Input, Backdrop, CircularProgress
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import { firmwareUpload } from '../../../APIs/aironeApis';


const STRING_TITLE = '펌웨어 추가';
const STRING_MAJOR_VERSION = 'MAJOR 버전 :';
const STRING_MINOR_VERSION = 'MINOR 버전 :';
const STRING_MODEL_CODE = '모델 코드 :';
const STRING_FW_URL = '펌웨어 업로드 :';
const STRING_ACTIVE = 'ACTIVE 유무 :';
const STRING_FORCE = 'FORCE 유무 :';
const STRING_GROUP_OTA = 'GROUP OTA 유무 :';
const STRING_FW_DESCRIPTION = '펌웨어 설명 :';
const STRING_FILE_NAME = '파일 :';
const STRING_FILE_SIZE = '파일 크기 :';
const STRING_FILE_SELECT = '파일 선택';
const STRING_ALERT_FW_UPLOAD = '업로드시 사전 검증 완료된 펌웨어만 등록해주세요.';
const STRING_CLOSE = '취소';
const STRING_UPLOAD = '업로드';


const useStyles = makeStyles(theme => ({
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  linear:{
    display: 'flex',
    alignItems: 'flex-end'
  },
  datas:{
    marginLeft: theme.spacing(2)
  },
  icons:{
    marginLeft: theme.spacing(2),
    padding: theme.spacing(0)
  },
  fwDescription:{
    marginLeft: theme.spacing(2),
    width: '65%'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));

const AddFirmwareDialog  = props => {
  const { open, ipAddress, handleClose, refetch, modelCode } = props;
  const [forcedflag, setForcedFlag] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeflag, setActiveFlag] = useState(false);
  const [groupOtaflag, setGroupOtaFlag] = useState(false);
  const [fileBuffer, setFileBuffer] = useState("");
  const [selectedFileName, setSelectedFileName] = useState("");
  const [selectedFileSize, setSelectedFileSize] = useState(0);
  const [majorVersion, setMajorVersion] = useState(0);
  const [minorVersion, setMinorVersion] = useState(0);
  const [fwDescription, setFwDescription] = useState("");

  const classes = useStyles();

  const handleForcedFlag = () =>{
    if(forcedflag){
      setForcedFlag(false);
    }else{
      setForcedFlag(true);
    }
  };

  const handleActiveFlag = () =>{
    if(activeflag){
      setActiveFlag(false);
    }else{
      setActiveFlag(true);
    }
  };

  const handleGroupOtaFlag = () =>{
    if(groupOtaflag){
      setGroupOtaFlag(false);
    }else{
      setGroupOtaFlag(true);
    }
  };

  function _arrayBufferToBase64( buffer ) {
    var binary = '';
    var bytes = new Uint8Array( buffer );
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
  }

  const handleUploadClick = event => {
    console.log();
    var file = event.target.files[0];
    const reader = new FileReader();
    var url =    reader.readAsArrayBuffer(file);
    reader.onloadend = function(e) {
      var data = reader.result;
      var base64String = _arrayBufferToBase64(data);
      setFileBuffer(base64String);
      setSelectedFileName(file.name);
      setSelectedFileSize(file.size);
    }.bind(this);
  };

  const handleCloseClick = () =>{
    setForcedFlag(false);
    setActiveFlag(false);
    setGroupOtaFlag(false);
    setFileBuffer("");
    setSelectedFileName("");
    setSelectedFileSize(0);
    setMajorVersion(0);
    setMinorVersion(0);
    setFwDescription("");
    handleClose();
  };

  const refetchList = () => {
    setLoading(false);
    refetch();
  };

  const handleUpload = () => {
    if(fileBuffer.length!==0) {
      firmwareUpload(sessionStorage.getItem('email'), ipAddress,
        fileBuffer, majorVersion, minorVersion,
        selectedFileSize, modelCode, fwDescription, activeflag, forcedflag,
        groupOtaflag, refetchList);
      setLoading(true);
      handleCloseClick()
    }
  };

  const changeMajorNumber = event => {
    event.persist();
    setMajorVersion(event.target.value);
  };

  const changeMinorNumber = event => {
    event.persist();
    setMinorVersion(event.target.value);
  };

  const changeFWDescription = event => {
    event.persist();
    setFwDescription(event.target.value);
  };

  return (
    <div>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color={'primary'}/>
      </Backdrop>
      <Dialog
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{STRING_TITLE}</DialogTitle>
        <DialogContent>
          <Alert severity={'error'}><span style={{ color: 'red' }}>{STRING_ALERT_FW_UPLOAD}</span></Alert>
          <Divider className={classes.divider}/>
          <div className={classes.linear}>
            <Typography variant="h6">{STRING_MAJOR_VERSION}</Typography>
            <Input className={classes.datas}
                   type={"number"}
                   placeholder={STRING_MAJOR_VERSION}
                   inputProps={{ 'aria-label': 'description' }}
                   onChange={changeMajorNumber}
            />
          </div>
          <Divider className={classes.divider}/>
          <div className={classes.linear}>
            <Typography variant="h6">{STRING_MINOR_VERSION}</Typography>
            <Input className={classes.datas}
                   type={"number"}
                   placeholder={STRING_MINOR_VERSION}
                   inputProps={{ 'aria-label': 'description' }}
                   onChange={changeMinorNumber}
            />
          </div>
          <Divider className={classes.divider}/>
          <div className={classes.linear}>
            <Typography variant="h6">{STRING_MODEL_CODE}</Typography>
            <Typography className={classes.datas} variant="h6">{modelCode}</Typography>
          </div>
          <Divider className={classes.divider}/>
          <div className={classes.linear}>
            <Typography variant="h6">{STRING_ACTIVE}</Typography>
            <Checkbox className={classes.icons}
                      checked={activeflag}
                      onChange={handleActiveFlag}
                      color="primary"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </div>
          <Divider className={classes.divider}/>
          <div className={classes.linear}>
            <Typography variant="h6">{STRING_FORCE}</Typography>
            <Checkbox className={classes.icons}
                      checked={forcedflag}
                      onChange={handleForcedFlag}
                      color="primary"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}/>
          </div>
          <Divider className={classes.divider}/>
          <div className={classes.linear}>
            <Typography variant="h6">{STRING_GROUP_OTA}</Typography>
            <Checkbox className={classes.icons}
                      checked={groupOtaflag}
                      onChange={handleGroupOtaFlag}
                      color="primary"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}/>
          </div>
          <Divider className={classes.divider}/>
          <div className={classes.linear}>
            <Typography variant="h6">{STRING_FW_URL}</Typography>
            <Button className={classes.icons} component="label"
            onChange={handleUploadClick}>
              {STRING_FILE_SELECT}
              <input
                type="file"
                hidden
              />
            </Button>
          </div>
          <Divider className={classes.divider}/>
          <div className={classes.linear}>
            <Typography variant="h6">{STRING_FW_DESCRIPTION}</Typography>
            <Input className={classes.fwDescription}
                   inputProps={{ 'aria-label': 'description' }}
                   onChange={changeFWDescription}
            />
          </div>
          <Divider className={classes.divider}/>
            <div className={classes.linear}>
              <Typography variant="h6">{STRING_FILE_NAME}</Typography>
              <Typography className={classes.datas} variant="h6">{selectedFileName}</Typography>
            </div>
          <Divider className={classes.divider}/>
            <div className={classes.linear}>
              <Typography variant="h6">{STRING_FILE_SIZE}</Typography>
              <Typography className={classes.datas} variant="h6">{selectedFileSize===0?0:selectedFileSize+" bytes"}</Typography>
            </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseClick} color="primary" autoFocus>
            {STRING_CLOSE}
          </Button>
          <Button onClick={handleUpload} color="primary" autoFocus>
            {STRING_UPLOAD}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

AddFirmwareDialog.propTypes = {
  className: PropTypes.string
};

export default AddFirmwareDialog;
