import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import DeviceToolbar from './components/DeviceToolbar';
import DeviceTable from './components/DeviceTable';
import useAsync from '../../../asyncNet';
import { Backdrop, CircularProgress, Typography } from '@material-ui/core';
import timezoneCode from '../../../common/TimezoneCode';
import { deviceSearch } from '../APIs/mateApis';
import { userInfoContext } from '../../../App';
import { NO_PERMISSION, NO_PERMISSION_WARNING, READ_PERMISSION } from '../../Common/Config/naviwatchConfig';



const STRING_MODEL_572 = 'EQM-572';
const STRING_MODEL_580 = 'EQM-580';
const STRING_MODEL_581 = 'EQM-581';
const STRING_MODEL_582 = 'EQM-582';
const STRING_MODEL_EMF_500 = 'EMF-500';
const STRING_MODEL_EME_501 = 'EME-501';
const STRING_MODEL_EME_500 = 'EME-500';

const STRING_CONNECTION_ON = '연결 상태';
const STRING_CONNECTION_OFF = '비연결 상태';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));


const Device = ({ history }) => {
  const classes = useStyles();
  const [query, setQuery] = useState({
    deviceid: '',
    userid: '',
    modelcode: '',
    connection: '',
    timezoneCode: ''
  });
  const [state, tryRefetch] = useAsync(getDeviceSearch, [], true);
  const { loading, data: devices } = state;

  const authInfo =  useContext(userInfoContext);
  const [noPermissionMsg, setNoPermissionMsg] = useState("");
  const [permission, setPermission] = useState(NO_PERMISSION);
  useEffect(()=>{
    var pageInfo = sessionStorage.getItem('pages');
    if(pageInfo!=="undefined") {
      var pageInfoObj = JSON.parse(pageInfo);
      var pageArray = pageInfoObj.filter(page => page.name.toLowerCase().includes("device"));
      if (pageArray !== undefined && pageArray.length > 0) {
        var page = pageArray[0];
        if(page.permission===undefined){
          setNoPermissionMsg(NO_PERMISSION_WARNING);
        }
        setPermission(page.permission);
      }
    }
  }, [authInfo.pages]);

  async function getDeviceSearch() {
    return deviceSearch(query.deviceid, query.userid, query.modelcode, query.connection,
      query.timezoneCode);
  }

  useEffect(() => {
    console.log('useEffect -- modelcode' + query.modelcode);
    if (query.deviceid !== '' || query.userid !== '' || query.modelcode !== '' || query.connection !== ''
      || query.timezoneCode !== '') {
      tryRefetch();
    }
  }, [query]);

  const searchDevice = (search) => {
    var modelNumber = '';
    if (search.model === STRING_MODEL_572) {
      modelNumber = 1;
    } else if (search.model === STRING_MODEL_580) {
      modelNumber = 2;
    } else if (search.model === STRING_MODEL_581) {
      modelNumber = 3;
    } else if (search.model === STRING_MODEL_582) {
      modelNumber = 4;
    } else if (search.model === STRING_MODEL_EME_501) {
      modelNumber = 257;
    } else if (search.model === STRING_MODEL_EME_500){
      modelNumber = 257;
    } else if (search.model === STRING_MODEL_EMF_500){
      modelNumber = 513;
    }
    var connection = '';
    if (search.connection === STRING_CONNECTION_ON) {
      connection = 1;
    } else if (search.connection === STRING_CONNECTION_OFF) {
      connection = 0;
    }
    var gmtStr = '';
    console.log('searchDevice call, region : '+search.region);

    if(search.region !==''){
      for ( var index=0 ; index< timezoneCode.length ; ++index){
        console.log('stringfy : '+JSON.stringify(timezoneCode[index]));

        console.log('loop try region.name : '+timezoneCode[index]['name']);
        if (timezoneCode[index]['name'] === search.region){
          gmtStr = timezoneCode[index]['gmtStr'];
          break;
        }
      }

    }

    setQuery({
      deviceid: search.deviceid,
      userid: search.userid,
      modelcode: modelNumber,
      connection: connection,
      timezoneCode : gmtStr
    });
  };


  useEffect(() => {
    if (sessionStorage.getItem('naviwatch_permission') !== 'true')
      history.push('/');
  }, []);


  return (
    <div>
      { permission >= READ_PERMISSION ?
        <div className={classes.root}>
          <Backdrop className={classes.backdrop} open={loading}>
            <CircularProgress color={'primary'}/>
          </Backdrop>
          <DeviceToolbar callbackFunc={searchDevice}/>
          <div className={classes.content}>
            {
              loading === false && devices !== null && devices !== undefined && <DeviceTable devices={devices.devices}/>
            }
          </div>
        </div> :
        <Typography className={classes.root}>{noPermissionMsg}</Typography>
      }
    </div>
  );
};

export default Device;
