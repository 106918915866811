import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core/styles';
import palette from 'theme/palette';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  IconButton,
  Divider,
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import { options } from './options';
import useAsync from '../../../../asyncNet';
import { serverLBMetric } from '../../APIs/aironeApis';
import ListView from '../../Components/ListView/ListView';

const INTERVAL_1MIN = '1분';
const INTERVAL_10MIN = '10분';
const INTERVAL_1HOUR = '1시간';
const INTERVAL_1DAY = '1일';
const INTERVAL_30DAY = '30일';


const useStyles = makeStyles(() => ({
  root: {},
  card:{
    backgroundColor : '#f4fce3'
  },
  chartContainer: {
    height: 200,
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));


const interval = [
  {
    id: 0,
    value: INTERVAL_1MIN,
    timelineSec: 60*60/2,
    timePeriodSec: 60
  },
  {
    id: 1,
    value: INTERVAL_10MIN,
    timelineSec: 5*60*60,
    timePeriodSec: 600
  },
  {
    id: 2,
    value: INTERVAL_1HOUR,
    timelineSec: 30*60*60,
    timePeriodSec: 3600
  },
  {
    id: 3,
    value: INTERVAL_1DAY,
    timelineSec: 2880*60*60,
    timePeriodSec: 86400// (3600*24)
  },
  {
    id: 4,
    value: INTERVAL_30DAY,
    timelineSec: 86400*60*60,
    timePeriodSec: 2592000// (86,400 * 30)
  }
];

const getLBChart = (metricName, selectedIndex, backbtnclickCnt)=>{
  var timelineSec = interval[selectedIndex].timelineSec;
  var timePeriodSec = interval[selectedIndex].timePeriodSec;
  var currentTime = Math.floor(new Date() /1000 - (backbtnclickCnt * timelineSec)) ;
  return serverLBMetric(metricName, timelineSec, timePeriodSec, currentTime);
};

const LoadBalancerCharts = props => {
  const { className, metricName, ...rest } = props;
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [backbtnclickCnt, clickBackbtn] = useState(0);
  const refreshBtn = useRef();
  const [state, tryRefetch] = useAsync(() => getLBChart(metricName, selectedIndex, backbtnclickCnt),
    [selectedIndex, backbtnclickCnt]);
  const { data: metric } = state;
  const classes = useStyles();
  const [metricData, setMetricData] = useState({});

  const [chartdata, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Server',
        fill: false,
        backgroundColor: palette.primary.main,
        data: []
      }
    ]
  });

  const backbtnClick = ()=>{
    clickBackbtn( backbtnclickCnt => backbtnclickCnt+1)
  };

  const forwardbtnClick = ()=>{
    if(backbtnclickCnt>0) {
      clickBackbtn(backbtnclickCnt => backbtnclickCnt - 1)
    }
  };

  const clickRefresh = () =>{
    if(backbtnclickCnt===0) {
      tryRefetch();
    }
  };

  const forceRefresh = () =>{
      clickBackbtn(0)
  };

  useEffect(() => {
    try {
      if (metricData !== null) {

        const formatter = n => {
          var tempval = new Date((n + 60 * 60 * 9) * 1000);
          var timeStr;
          if (selectedIndex === 0 || selectedIndex === 1) {
            timeStr = tempval.toISOString().slice(11, 16);
          } else if (selectedIndex === 2) {
            timeStr = tempval.toISOString().slice(8, 13).replace('T', '.');
          } else {
            timeStr = tempval.toISOString().slice(5, 10).replace('-', '/');
          }

          return timeStr;
        };
        console.log('metric updated :' + JSON.stringify(metricData));

        if (metricData['data']['p99'] !== null) {
          console.log('LB timestamp : ' + metricData['data']['average']['timestamp']);
          console.log('LB value : ' + metricData['data']['average']['value']);

          const formattedTime = metricData['data']['average']['timestamp'].map(formatter);
          setChartData(chardata => ({
              ...chartdata,
              labels: formattedTime,
              datasets: [{
                label: 'sum',
                lineTension: 0,
                fill: true,
                backgroundColor: palette.primary.main,
                data: metricData['data']['average']['value']
              }]
            })
          );
        }
      }
    } catch (e) {
      console.log('metric update failed :' + JSON.stringify(metricData));
    }
  }, [metricData]);

  useEffect(()=>{
    if (metric !== null) {
      setMetricData(metric);
    }
  },[metric]);

  useEffect(() => {
    const timer = setInterval(function() {
      refreshBtn.current.click();
    }, 60 * 1000);
    console.log('create server board timer:'+timer)
    return () => {
      console.log('destroy server timer :'+timer);
      clearInterval(timer);
    };
  }, []);

  return (
    <div>
      <Card
        {...rest}
        className={classes.root}>
        <CardHeader className={classes.card}
          action={
            <IconButton size="small" onClick={clickRefresh} ref={refreshBtn} style={{color: 'white'}}>
            </IconButton>
          }
          title={metricName === 'ActiveFlowCount_TCP' ? 'TCP Connection (Count)' : 'TCP Traffic (Bytes)'}
        />

        <Divider/>
        <CardContent>
          <div className={classes.chartContainer}>
            <Bar
              data={chartdata}
              options={options}
            />
          </div>
        </CardContent>
        <Divider/>
        <CardActions className={classes.actions}>

          <IconButton size="small" onClick={backbtnClick}>
            <ArrowLeftIcon color={'primary'}/>
          </IconButton>

          <IconButton size="small" onClick={forwardbtnClick}>
            <ArrowRightIcon color={'primary'}/>
          </IconButton>

          <ListView
            items={interval}
            selectedIdx={selectedIndex}
            setSelectedIdx={setSelectedIndex}
            choiceCallback={forceRefresh}
          />
        </CardActions>
      </Card>
    </div>
  );
};

LoadBalancerCharts.propTypes = {
  className: PropTypes.string
};

export default React.memo(LoadBalancerCharts);
