import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography
} from '@material-ui/core';
import HotTubIcon from '@material-ui/icons/HotTub';
import useAsync from '../../../../../asyncNet';
import { dashboardGetModelCount } from '../../../APIs/mateApis';

const STRING_ALL_NAME='전체 기기 분포';
const STRING_ONLINE_NAME='온라인 기기 분포';


const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  chartContainer: {
    position: 'relative',
    height: '300px'
  },
  stats: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center'
  },
  device: {
    textAlign: 'center',
    padding: theme.spacing(1)
  },
  deviceIcon: {
    color: theme.palette.icon
  }
}));

const ModelCounter = props => {
  const { className, status, ...rest } = props;

  const classes = useStyles();
  const theme = useTheme();
  const [state] = useAsync(()=>dashboardGetModelCount(status), []);
  const { data: models } = state;
  const [modelsChart, setModelsChart] = useState({});
  const [modelData, setModelData] = useState( []);

  useEffect(()=>{
    try{
      if(models!==null){
        console.log('models : ', JSON.stringify(models))

        // update model data
        if(models['modelName'].length>=1) {
          // update chart
          setModelsChart(modelsChart=>({
            ...modelsChart,
            datasets: [
              {
                data: models['modelCount'],
                backgroundColor: [
                  theme.palette.primary.main,
                  theme.palette.info.main,
                  theme.palette.error.main
                ],
                borderWidth: 8,
                borderColor: theme.palette.white,
                hoverBorderColor: theme.palette.white
              }
            ],
            labels: models['modelName']
          }));

          var sum = models['modelCount'][0] + models['modelCount'][1] + models['modelCount'][2];
          var firstValue = 100;//models['modelCount'][0];
          //var firstValue = Math.floor(models['modelCount'][0] * (100 / sum));
          //var secondValud = Math.floor(models['modelCount'][1] * (100 / sum));
          //var thirdValud = Math.floor(models['modelCount'][2] * (100 / sum));

          setModelData(
            [
              {
                title: models['modelName'][0],
                value: firstValue,
                icon: <HotTubIcon style={{ color: theme.palette.primary.main }}/>,
                color: theme.palette.primary.main
              }/*,
              {
                title: models['modelName'][1],
                value: secondValud,
                icon: <HotTubIcon style={{ color: theme.palette.info.main }}/>,
                color: theme.palette.info.main
              },
              {
                title: models['modelName'][2],
                value: 100-(firstValue+secondValud),
                icon: <HotTubIcon style={{ color: theme.palette.error.main }}/>,
                color: theme.palette.error.main
              }

              {
                title: models['modelName'][2],
                value: thirdValud,
                icon: <HotTubIcon style={{ color: theme.palette.error.main }}/>,
                color: theme.palette.error.main
              },
              {
                title: models['modelName'][3],
                value: 100-(firstValue+secondValud+thirdValud),
                icon: <HotTubIcon style={{ color: theme.palette.success.main }}/>,
                color: theme.palette.success.main
              }*/
            ]
          )
        }
      }
    }catch (e) {
      console.log('get models count failed: '+JSON.stringify(e));
    }

  }, [models]);

  const options = {
    legend: {
      display: false
    },
    responsive: true,
    maintainAspectRatio: false,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      borderWidth: 1,
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.white,
      titleFontColor: theme.palette.text.primary,
      bodyFontColor: theme.palette.text.secondary,
      footerFontColor: theme.palette.text.secondary
    }
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        title={status==="online"?STRING_ONLINE_NAME:STRING_ALL_NAME}
      />
      <Divider />
      <CardContent>
        <div className={classes.chartContainer}>
          {modelsChart!==null &&
            <Doughnut
              data={modelsChart}
              options={options}
            />
          }
        </div>
        <div className={classes.stats}>
          {modelData!==null && modelData.map(device => (
            <div
              className={classes.device}
              key={device.title}
            >
              <span className={classes.deviceIcon}>{device.icon}</span>
              <Typography variant="body1">{device.title}</Typography>
              <Typography
                style={{ color: device.color }}
                variant="h2"
              >
                {device.value}%
              </Typography>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};

ModelCounter.propTypes = {
  className: PropTypes.string
};

export default ModelCounter;
