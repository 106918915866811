import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import useAsync from '../../../../../asyncNet';
import dateformat from 'dateformat';
import { getUsers } from 'views/Smart/APIs/tokapis';
import ExcelDownload from './ExcelDownload';

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  Typography,

} from '@material-ui/core';
import {
  STRING_ALERT_USER_INFO,
  STRING_SEARCH,
  STRING_EXCEL,
  STRING_CLEAN,
  STRING_ALL,
  STRING_ID,
  STRING_NAME,
  STRING_PHONE,
  STRING_COLLECTION,
  STRING_MARKETING,
  STRING_OFFER,
  STRING_DATE_FORMAT_LOWER_CASE,
  STRING_SEARCH_DIVISION,
  STRING_AGREE_DIVISION,
  STRING_AGREE_PRIVACY_DATE
} from '../../../StringTable/StringTable';


const useStyles = makeStyles((theme) => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  },
  title: {
    fontWeight: 700
  },
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  textField: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: 200,
  },
  containerItems: {
    display: 'flex',
    alignItems: 'ceneter',
  },
  box1: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(13),
  },
  box2: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(5.5),
  },
  box3: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(2),
  }
}));

const searchTypes = [
  STRING_ALL,
  STRING_ID,
  STRING_NAME,
  STRING_PHONE,
];

const legalTypes = [
  STRING_ALL,
  STRING_COLLECTION,
  STRING_MARKETING,
  STRING_OFFER,
];


const UsersToolbar = props => {
  const { className, callbackFunc, callbackExcelFunc, users, ...rest } = props;
  const classes = useStyles();
  const [usersData, setusersData] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const [values, setValues] = useState({
    input: '',
    searchType: STRING_ALL,
    legalType: STRING_ALL,
    from: dateformat(new Date().setMonth(new Date().getMonth() - 3), STRING_DATE_FORMAT_LOWER_CASE),
    to: dateformat(new Date(), STRING_DATE_FORMAT_LOWER_CASE),
    isDownload: false,
    offset: 0,
    limit: 10,
  });

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const cleanSearchInput = () => {
    setValues({
      input: '',
      searchType: STRING_ALL,
      legalType: STRING_ALL,
      from: dateformat(new Date().setMonth(new Date().getMonth() - 3), STRING_DATE_FORMAT_LOWER_CASE),
      to: dateformat(new Date(), STRING_DATE_FORMAT_LOWER_CASE),
      offset: 0,
      limit: 10,
    });
  };

  const excelDownload = async () => {
    let userExcelList = await getUsers(values.input, values.searchType, values.legalType, values.from, values.to, '', true);
    setExcelData(userExcelList.data.users);
  }

  useEffect(() => {
    if (users !== null && users !== false) {
      setusersData(users);
    }
  }, [users]);

  useEffect(() => {

  }, [callbackFunc]);

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        title={'회원 관리'}
        subheader={<div>
          <span style={{ color: 'red' }}>{STRING_ALERT_USER_INFO}</span></div>}
      />
      <Divider />
      <CardContent>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            md={12}
            xs={12}
            lg={12}
            xl={12}
            className={classes.containerItems}
          >
            <Typography
              align='center'
              variant="body1" className={classes.box1} noValidate
            >
              <b>{STRING_SEARCH_DIVISION}</b>
            </Typography>

            <TextField
              label={STRING_SEARCH_DIVISION}
              margin="dense"
              name="searchType"
              onChange={handleChange}
              select
              SelectProps={{ native: true }}
              value={values.searchType}
              variant="outlined"
              style={{ width: 120 }}
            >
              {
                searchTypes.map(searchType => (
                  <option
                    key={searchType}
                    value={searchType}
                  >
                    {searchType}
                  </option>
                ))
              }
            </TextField>

            <Grid
              item
              md={3}
              xs={3}
              lg={3}
              xl={3}
              className={classes.containerItems}
            >
              <TextField
                fullWidth
                className={classes.box3}
                label={values.searchType === STRING_PHONE && '- 제외 입력'}
                margin="dense"
                name="input"
                onChange={handleChange}
                value={values.input}
                variant="outlined"
                disabled={values.searchType === STRING_ALL}
              />
            </Grid>
          </Grid>

        </Grid>
      </CardContent>

      <CardContent>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            md={12}
            xs={12}
            lg={12}
            xl={12}
            className={classes.containerItems}
          >
            <Typography
              align='center'
              variant="body1" className={classes.box2} noValidate
            >
              <b>{STRING_AGREE_PRIVACY_DATE}</b>
            </Typography>

            <Grid
              item
            >
              <TextField
                label={STRING_AGREE_DIVISION}
                margin="dense"
                name="legalType"
                onChange={handleChange}
                select
                SelectProps={{ native: true }}
                value={values.legalType}
                variant="outlined"
                disabled={values.handlerType === ''}
                style={{ width: 120 }}
              >
                {
                  legalTypes.map(legalType => (
                    <option
                      key={legalType}
                      value={legalType}
                    >
                      {legalType}
                    </option>
                  ))
                }
              </TextField>
            </Grid>

            <TextField
              id="from"
              name="from"
              label="From"
              type="date"
              className={classes.textField}
              style={{ borderTopWidth: 5 }}
              value={values.from}
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              disabled={values.legalType === STRING_ALL}
            />

            <Typography align="center"
              variant="body1" className={classes.container} noValidate>
              ~
            </Typography>
            <TextField
              id="to"
              name="to"
              label="To"
              type="date"
              className={classes.textField}
              value={values.to}
              onChange={handleChange}
              inputProps={{
                max: dateformat(new Date(values.from).setMonth(new Date(values.from).getMonth() + 3), STRING_DATE_FORMAT_LOWER_CASE)
              }}
              InputLabelProps={{
                shrink: true,
              }}
              disabled={values.legalType === STRING_ALL}
            />
          </Grid>
        </Grid>
      </CardContent>
      <Divider />

      <CardActions className={classes.actions}>
        <Typography
          inline variant="body1" align="left" className={classes.container} noValidate
        >
        </Typography>
        <Button
          color="primary"
          variant="contained"
          onClick={cleanSearchInput}
          disabled={values.input === "" && values.from === dateformat(new Date(), STRING_DATE_FORMAT_LOWER_CASE) && values.to === dateformat(new Date(), STRING_DATE_FORMAT_LOWER_CASE) && values.feature === ''}
        >
          {STRING_CLEAN}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => callbackFunc(values, false)}
        >
          {STRING_SEARCH}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => excelDownload()}
          disabled={users == false || users == null}
        >
          {STRING_EXCEL}
        </Button>
        {
          excelData !== undefined && excelData !== null && excelData.length > 0 &&
          <ExcelDownload excelDownloadData={excelData} />
        }
      </CardActions>

      <Typography
        align='center'
        variant="body1" className={classes.container} noValidate
      >
      </Typography>

    </Card>


  );
};

UsersToolbar.propTypes = {
  className: PropTypes.string
};

export default UsersToolbar;
