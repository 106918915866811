import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import useAsync from '../../../../../asyncNet';
import {
  STRING_DAY_ROOMCON_COUNT,
  STRING_DAY_USER_COUNT,
  STRING_DAY_CONTROL_COUNT
} from '../../../StringTable/StringTable';


const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    width: '100%',
    height: '100%'
  },
  title: {
    fontWeight: 700
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  connDevCnt: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    marginRight: theme.spacing(1),
    width: "100%"
  }
}));

const ConnectionCounts = props => {
  const { className, onRequestSearchUser, type, ...rest } = props;
  const classes = useStyles();

  const getCountFunction = () => {
    console.log('call getCountFunction');
    if (type === 'roomcontroller') {
      return /*getConnectedDeviceNumber()*/;
    } else if (type === 'user') {
      return /*getConnectedDeviceNumber()*/;
    } else if (type === 'control') {
      return /*getConnectedDeviceNumber()*/;
    }
  };

  var counterTitle;
  var classesIcon;
  var classesAvatar;
  var classesRoot;
  var data;

  if (type === 'roomcontroller') {
    counterTitle = STRING_DAY_ROOMCON_COUNT;
    data = "1350";
    //   classesRoot = classes.mobileRoot;
    //   classesIcon = classes.mobileDifferenceIcon;
    //   classesAvatar = classes.mobileAvatar;
  } else if (type === 'user') {
    counterTitle = STRING_DAY_USER_COUNT;
    data = "7380";
    //   classesRoot = classes.deviceRoot;
    //   classesIcon = classes.deviceDifferenceIcon;
    //   classesAvatar = classes.deviceAvatar;
  } else if (type === 'control') {
    counterTitle = STRING_DAY_CONTROL_COUNT;
    data = "31257";
    //   classesRoot = classes.userRoot;
    //   classesIcon = classes.userDifferenceIcon;
    //   classesAvatar = classes.userAvatar;
  }

  /* Response data from the SmartTok server */
  const [connectedDeviceNumber, getConnectedDeviceCount] = useAsync(getCountFunction, []);
  const { loading, data: connDevCnt, error } = connectedDeviceNumber;

  /* Text for user search */
  const [searchText, searchTextSet] = useState('');

  const onClickSearchUser = () => {
    onRequestSearchUser(searchText);
  };

  const onKeyupSearchInput = (event) => {
    if (event.key === 'Enter') {
      onRequestSearchUser(searchText);
    }
  }

  const onChangeSearchInput = (event) => {
    searchTextSet(event.target.value);
  }

  return (
    <Card style={{ flex: 1, backgroundColor: 'lightgray' }}>
      <CardContent>
        <Grid
          container
          justifyContent="space-around"
        >
          <Grid item>
            <div className={classes.content}>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
                variant="body2"
              >
                {counterTitle}
              </Typography>
              <Grid className={classes.connDevCnt}>
                <Typography
                  className={classes.title}
                  color="textSecondary"
                  gutterBottom
                  variant="h2"
                >
                  {/* {connDevCnt ? connDevCnt.count : '-'} */}
                  {data}
                </Typography>
              </Grid>
            </div>
          </Grid>
        </Grid>
        <div {...rest} className={clsx(classes.root, className)} >
        </div>
      </CardContent>
    </Card>

  );
};

ConnectionCounts.propTypes = {
  className: PropTypes.string
};

export default ConnectionCounts;
