import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Line, Bar } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core/styles';
import palette from 'theme/palette';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import { getEC2Metric, getRdsMetric, getPrivacyEC2Metric } from '../../../APIs/tokapis';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  IconButton,
  Divider
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { options } from './options';
import useAsync from '../../../../../asyncNet';
import ListView from './ListView/ListView';
import ValueView from './ValueView/ValueView';
import {
  STRING_1MIN,
  STRING_5MIN,
  STRING_10MIN,
  STRING_1HOUR,
  STRING_1DAY,
  STRING_30DAY,
  STRING_MAX,
  STRING_MIN,
  STRING_AVG,
  STRING_EC2_PRIVACY
} from '../../../StringTable/StringTable';

const choice = [
  {
    id: 'avg',
    value: STRING_AVG
  },
  {
    id: 'max',
    value: STRING_MAX
  },
  {
    id: 'min',
    value: STRING_MIN
  }
];

const interval = [
  {
    id: 0,
    value: STRING_1MIN,
    timelineSec: 60 * 12,
    timePeriodSec: 60
  },
  {
    id: 1,
    value: STRING_5MIN,
    timelineSec: 300 * 12,
    timePeriodSec: 300
  },
  {
    id: 2,
    value: STRING_10MIN,
    timelineSec: 600 * 12,
    timePeriodSec: 600
  },
  {
    id: 3,
    value: STRING_1HOUR,
    timelineSec: 3600 * 12,
    timePeriodSec: 3600
  },
  {
    id: 4,
    value: STRING_1DAY,
    timelineSec: 86400 * 12,
    timePeriodSec: 86400// (3600*24)
  },
  {
    id: 5,
    value: STRING_30DAY,
    timelineSec: 86400 * 60 * 60,
    timePeriodSec: 2592000// (86,400 * 30)
  }
];

async function getMetric(ec2Name, metricName, intervalIndex, backCnt) {
  const timelineSec = interval[intervalIndex].timelineSec;
  const timePeriodSec = interval[intervalIndex].timePeriodSec;
  //endtimeSec is current time
  const endtimeSec = Math.floor(new Date() / 1000 - (backCnt * timelineSec));
  if (ec2Name === STRING_EC2_PRIVACY) {
    return getPrivacyEC2Metric(ec2Name, metricName, endtimeSec, timelineSec, timePeriodSec);
  } else {
    return getEC2Metric(ec2Name, metricName, endtimeSec, timelineSec, timePeriodSec);
  }

}

async function getrdsMetric(rdsName, metricName, unit, intervalIndex, backCnt) {
  const timelineSec = interval[intervalIndex].timelineSec;
  const timePeriodSec = interval[intervalIndex].timePeriodSec;
  //endtimeSec is current time
  const endtimeSec = Math.floor(new Date() / 1000 - (backCnt * timelineSec));

  return getRdsMetric(rdsName, metricName, endtimeSec, timelineSec, timePeriodSec, unit);
}

const Chart = props => {
  const { className, ec2Name, ec21Name, ec22Name, ec23Name, metricName, color, button, width, height, rdsName, unit, ...rest } = props;
  const useStyles = makeStyles(() => ({
    root: {},
    card: {
      backgroundColor: color,
    },
    chartContainer: {
      height: height,
      width: width,
      position: 'relative'
    },
    actions: {
      justifyContent: 'flex-end'
    }
  }));
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [selectedState, setSelectedState] = useState(0);
  const [backbtnclickCnt, clickBackbtn] = useState(0);
  const refreshBtn = useRef();

  //EC2
  const [state, tryRefetch] = useAsync(() => getMetric(ec2Name, metricName, selectedIndex, backbtnclickCnt),
    [selectedIndex, backbtnclickCnt]);
  const { data: metric } = state;

  // DB
  const [rdsstate, rdstryRefetch] = useAsync(() => getrdsMetric(rdsName, metricName, unit, selectedIndex, backbtnclickCnt),
    [selectedIndex, backbtnclickCnt]);
  const { rdsloading, data: rdsmetric, rdserror } = rdsstate;

  // Converter
  const [state1, tryRefetch1] = useAsync(() => getMetric(ec21Name, metricName, selectedIndex, backbtnclickCnt),
    [selectedIndex, backbtnclickCnt]);
  const { data: metric1 } = state1;
  const [state2, tryRefetch2] = useAsync(() => getMetric(ec22Name, metricName, selectedIndex, backbtnclickCnt),
    [selectedIndex, backbtnclickCnt]);
  const { data: metric2 } = state2;
  const [state3, tryRefetch3] = useAsync(() => getMetric(ec23Name, metricName, selectedIndex, backbtnclickCnt),
    [selectedIndex, backbtnclickCnt]);
  const { data: metric3 } = state3;


  const classes = useStyles();
  const [chartdata, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: 'chart',
        fill: false,
        backgroundColor: palette.primary.main,
        data: []
      }
    ]
  });

  const backbtnClick = () => {
    clickBackbtn(backbtnclickCnt => backbtnclickCnt + 1)
  };

  const forwardbtnClick = () => {
    if (backbtnclickCnt > 0) {
      clickBackbtn(backbtnclickCnt => backbtnclickCnt - 1)
    }
  };

  const clickRefresh = () => {
    if (backbtnclickCnt === 0) {
      tryRefetch();
      tryRefetch1();
      tryRefetch2();
      tryRefetch3();
      rdstryRefetch();
    }
  };

  const forceRefreshList = () => {
    clickBackbtn(0)
  };
  const forceRefreshState = () => {
    clickBackbtn(0)
  };

  const stateValue = choice[selectedState]['id'];

  let title = '';
  if (metricName === 'CPUUtilization') {
    title = 'CPU (%)';
  }
  else if (metricName === 'MemoryUtilization') {
    title = 'MEM (%)';
  }
  else if (metricName === 'DatabaseConnections' || metricName === 'Connections') {
    title = 'Connections (Count)';
  }
  else if (metricName === 'Queries') {
    title = 'Queries (Count/Second)';
  }
  else if (metricName === 'CPUUtilization') {
    title = 'CPU (%)';
  }

  useEffect(() => {
    try {
      if (metric !== null) {
        const formatter = n => {
          var tempval = new Date((n + 60 * 60 * 9) * 1000);
          var timeStr;
          if (selectedIndex === 0 || selectedIndex === 1) {
            timeStr = tempval.toISOString().slice(11, 16);
          } else if (selectedIndex === 2) {
            timeStr = tempval.toISOString().slice(8, 13).replace('T', '.');
          }
          else {
            timeStr = tempval.toISOString().slice(5, 10).replace('-', '/');
          }

          return timeStr;
        };
        // console.log('timestamp : ' + metric['data']['average']['timestamp']);
        const formattedTime = metric['data']['average']['timestamp'].map(formatter);
        setChartData(chardata => ({
          ...chartdata,
          labels: formattedTime,
          datasets: [
            {
              label: 'MAX',
              lineTension: 0,
              fill: false,
              backgroundColor: palette.error.main,
              data: metric['data']['maximum']['value'],
              barThickness: 12,
              maxBarThickness: 10,
              barPercentage: 0.5,
              categoryPercentage: 0.5,
            }, {
              label: 'AVG',
              lineTension: 0,
              fill: false,
              backgroundColor: palette.info.main,
              data: metric['data']['average']['value'],
              barThickness: 12,
              maxBarThickness: 10,
              barPercentage: 0.5,
              categoryPercentage: 0.5,
            }, {
              label: 'MIN',
              lineTension: 0,
              fill: false,
              backgroundColor: palette.success.main,
              data: metric['data']['minimum']['value'],
              barThickness: 12,
              maxBarThickness: 10,
              barPercentage: 0.5,
              categoryPercentage: 0.5,
            }]
        })
        );
      }
    } catch (e) {
      // console.log('metric update failed :' + JSON.stringify(e));
    }
  }, [metric]);


  useEffect(() => {
    try {
      if (metric1 !== null && metric2 !== null && metric3 !== null) {
        const formatter = n => {
          var tempval = new Date((n + 60 * 60 * 9) * 1000);
          var timeStr;
          if (selectedIndex === 0 || selectedIndex === 1 || selectedIndex === 2) {
            timeStr = tempval.toISOString().slice(11, 16);
          } else if (selectedIndex === 3) {
            timeStr = tempval.toISOString().slice(8, 13).replace('T', '.');
          }
          else {
            timeStr = tempval.toISOString().slice(5, 10).replace('-', '/');
          }

          return timeStr;
        };
        if (stateValue === "avg") {
          const formattedTime = metric1['data']['average']['timestamp'].map(formatter);
          setChartData(chardata => ({
            ...chartdata,
            labels: formattedTime,
            datasets: [
              {
                label: 'az1 AVG',
                lineTension: 0,
                fill: false,
                backgroundColor: palette.info.main,
                data: metric1['data']['average']['value'],
                barThickness: 12,
                maxBarThickness: 10,
                barPercentage: 0.5,
                categoryPercentage: 0.5,
              },
              {
                label: 'az2 AVG',
                lineTension: 0,
                fill: false,
                backgroundColor: palette.error.main,
                data: metric2['data']['average']['value'],
                barThickness: 12,
                maxBarThickness: 10,
                barPercentage: 0.5,
                categoryPercentage: 0.5,
              }, {
                label: 'az3 AVG',
                lineTension: 0,
                fill: false,
                backgroundColor: palette.success.main,
                data: metric3['data']['average']['value'],
                barThickness: 12,
                maxBarThickness: 10,
                barPercentage: 0.5,
                categoryPercentage: 0.5,
              }
            ]
          })
          );
        } else if (stateValue === "max") {
          const formattedTime = metric1['data']['maximum']['timestamp'].map(formatter);
          setChartData(chardata => ({
            ...chartdata,
            labels: formattedTime,
            datasets: [
              {
                label: 'az1 MAX',
                lineTension: 0,
                fill: false,
                backgroundColor: palette.info.main,
                data: metric1['data']['maximum']['value'],
                barThickness: 12,
                maxBarThickness: 10,
                barPercentage: 0.5,
                categoryPercentage: 0.5,
              },
              {
                label: 'az2 MAX',
                lineTension: 0,
                fill: false,
                backgroundColor: palette.error.main,
                data: metric2['data']['maximum']['value'],
                barThickness: 12,
                maxBarThickness: 10,
                barPercentage: 0.5,
                categoryPercentage: 0.5,
              },
              {
                label: 'az3 MAX',
                lineTension: 0,
                fill: false,
                backgroundColor: palette.success.main,
                data: metric3['data']['maximum']['value'],
                barThickness: 12,
                maxBarThickness: 10,
                barPercentage: 0.5,
                categoryPercentage: 0.5,
              }
            ]
          })
          );
        } else {
          const formattedTime = metric1['data']['minimum']['timestamp'].map(formatter);
          setChartData(chardata => ({
            ...chartdata,
            labels: formattedTime,
            datasets: [
              {
                label: 'az1 MIN',
                lineTension: 0,
                fill: false,
                backgroundColor: palette.info.main,
                data: metric1['data']['minimum']['value'],
                barThickness: 12,
                maxBarThickness: 10,
                barPercentage: 0.5,
                categoryPercentage: 0.5,
              },
              {
                label: 'az2 MIN',
                lineTension: 0,
                fill: false,
                backgroundColor: palette.error.main,
                data: metric2['data']['minimum']['value'],
                barThickness: 12,
                maxBarThickness: 10,
                barPercentage: 0.5,
                categoryPercentage: 0.5,
              }, {
                label: 'az3 MIN',
                lineTension: 0,
                fill: false,
                backgroundColor: palette.success.main,
                data: metric3['data']['minimum']['value'],
                barThickness: 12,
                maxBarThickness: 10,
                barPercentage: 0.5,
                categoryPercentage: 0.5,
              }
            ]
          })
          );
        }
      }
    } catch (e) {
      // console.log('metric update failed :' + JSON.stringify(e));
    }
  }, [metric1, metric2, metric3, stateValue]);


  useEffect(() => {
    try {
      if (rdsmetric !== null) {

        const formatter = n => {
          var tempval = new Date((n + 60 * 60 * 9) * 1000);
          var timeStr;
          if (selectedIndex === 0 || selectedIndex === 1) {
            timeStr = tempval.toISOString().slice(11, 16);
          } else if (selectedIndex === 2) {
            timeStr = tempval.toISOString().slice(8, 13).replace('T', '.');
          }
          else {
            timeStr = tempval.toISOString().slice(5, 10).replace('-', '/');
          }

          return timeStr;
        };
        if (rdsmetric['data']['sum'] !== null) {
          const formattedTime = rdsmetric['data']['sum']['timestamp'].map(formatter);
          setChartData(chardata => ({
            ...chartdata,
            labels: formattedTime,
            datasets: [{
              label: 'SUM',
              lineTension: 0,
              fill: true,
              backgroundColor: palette.primary.main,
              data: rdsmetric['data']['sum']['value'],
              barThickness: 12,
              maxBarThickness: 10,
              barPercentage: 0.5,
              categoryPercentage: 0.5,
            }]
          })
          );
        }
        else {
          const formattedTime = rdsmetric['data']['average']['timestamp'].map(formatter);
          setChartData(chardata => ({
            ...chartdata,
            labels: formattedTime,
            datasets: [
              {
                label: 'MAX',
                lineTension: 0,
                fill: false,
                backgroundColor: palette.error.main,
                data: rdsmetric['data']['maximum']['value'],
                barThickness: 12,
                maxBarThickness: 10,
                barPercentage: 0.5,
                categoryPercentage: 0.5,
              },
              {
                label: 'AVG',
                lineTension: 0,
                fill: false,
                backgroundColor: palette.info.main,
                data: rdsmetric['data']['average']['value'],
                barThickness: 12,
                maxBarThickness: 10,
                barPercentage: 0.5,
                categoryPercentage: 0.5,
              }
            ]
          })
          );
        }
      }
    } catch (e) {
      // console.log('metric update failed :' + JSON.stringify(e));
    }
  }, [rdsmetric]);


  useEffect(() => {
    const timer = setInterval(function () {
      refreshBtn.current.click();
    }, 60 * 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className={classes.chartContainer}>
      <Card
        {...rest}
      >
        <CardHeader className={classes.card}
          action={
            <IconButton size="small" onClick={clickRefresh} ref={refreshBtn} style={{ color: 'white' }}>
            </IconButton>
          }
          title={title}
        />

        <Divider />
        <CardContent>
          <div>
            {unit === 'Count' || unit === 'Count/Second' ?
              <Bar
                data={chartdata}
                options={options}
              /> :
              <Line
                data={chartdata}
                options={options}
              />
            }
          </div>
        </CardContent>
        <Divider />
        <CardActions className={classes.actions}>

          {button === true &&
            <ValueView
              items={choice}
              selectedState={selectedState}
              setSelectedState={setSelectedState}
              choiceCallback={forceRefreshState}
            />
          }

          <IconButton size="small" onClick={backbtnClick}>
            <ArrowLeftIcon color={'primary'} />
          </IconButton>

          <IconButton size="small" onClick={forwardbtnClick}>
            <ArrowRightIcon color={'primary'} />
          </IconButton>

          <ListView
            items={interval}
            selectedIdx={selectedIndex}
            setSelectedIdx={setSelectedIndex}
            choiceCallback={forceRefreshList}
          />
        </CardActions>
      </Card>
    </div>
  );
};

Chart.propTypes = {
  className: PropTypes.string
};

export default React.memo(Chart);
