import React, { useState, useEffect, useContext } from 'react';
import { Link as RouterLink, withRouter, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import secureLocalStarage from 'react-secure-storage';
import { makeStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

import {
  Grid,
  Button,
  IconButton,
  TextField,
  Link,
  Typography,
  CircularProgress
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Backdrop } from '@material-ui/core';
import { Auth } from 'aws-amplify';
import { dispatchContext, userInfoContext } from '../../../App';
import useAsync from '../../../asyncNet';
import { statisticsAMBrightnessCount } from '../../Airone/APIs/aironeApis';
import { naviwatchSignIn } from '../APIs/naviwatchApis';
import { useSetRecoilState } from 'recoil';
import { userAccount, idToken, accessToken } from '../../../common/mainStore';

const schema = {
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64
    }
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128
    }
  }
};

const STR_WARNING_ACCOUNT_PW_TITLE = '계정 & 비번 확인';
const STR_WARNING_ACCOUNT_PW_CONTENT =
  '계정 혹은 비밀번호가 유효하지 않습니다.';
const STR_WARNING_SIGNIN_FAIL_TITLE = '삭제된 사용자';
const STR_WARNING_SIGNIN_FAIL_CONTENT =
  '계정 사용이 거부된 사용자로 확인됩니다.';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/intro.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  socialButtons: {
    marginTop: theme.spacing(3)
  },
  socialIcon: {
    marginRight: theme.spacing(1)
  },
  sugestion: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    backgroundColor: '#ffffff'
  },
  backdropSignin: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  backdropContent: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  symbolimage: {
    width: '30%'
  },
  mark: {
    flexBasis: 'auto',
    width: '400px'
  },
  avatar: {
    width: '10%',
    height: '10%',
    marginRight: theme.spacing(2)
  }
}));

const SignIn = props => {
  const setUserAccount = useSetRecoilState(userAccount);
  const setIdToken = useSetRecoilState(idToken);
  const setAccessToken = useSetRecoilState(accessToken);

  const authInfo = useContext(userInfoContext);
  const dispatch = useContext(dispatchContext);

  const { history } = props;

  const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      email: '',
      password: ''
    },
    touched: {},
    errors: {}
  });
  const [backdropdisappear, setBackdropState] = useState(false);
  const [loginBackdrop, setLoginBackdrop] = useState(false);
  const [dgstate, dgset] = useState(false);
  const [cognitoCallbackData, setCognitoCallbackData] = useState(undefined);
  const [state, trySignIn] = useAsync(() => signIn(), [], true);
  const { data: userInfo } = state;
  const [dialogTxt, setDialogTxt] = useState({
    dialogTitle: '',
    dialogContent1: ''
  });

  const setDialogOpen = () => {
    setLoginBackdrop(false);
    dgset(true);
  };

  const setDialogClose = () => {
    dgset(false);
  };

  const dialogOn = (title, content1) => {
    setDialogTxt({
      ...dialogTxt,
      dialogTitle: title,
      dialogContent1: content1
    });
    setDialogOpen();
  };

  useEffect(() => {
    if (cognitoCallbackData !== undefined) {
      trySignIn().then(responseData => {
        if (responseData.code === 200) {
          var group = JSON.stringify(
            cognitoCallbackData.signInUserSession.idToken.payload[
              'cognito:groups'
            ]
          );
          console.log('group : ' + group);
          console.log('responseData : ' + JSON.stringify(responseData));
          console.log(
            'access token : ' +
              JSON.stringify(
                cognitoCallbackData.signInUserSession.accessToken
              ) +
              '\n'
          );

          /* Set userAccount & IdToken, AccessToken to Recoil */
          setUserAccount(JSON.stringify(responseData.data));
          secureLocalStarage.setItem('account', responseData.data)
          setIdToken(cognitoCallbackData.signInUserSession.idToken['jwtToken']);
          setAccessToken(
            cognitoCallbackData.signInUserSession.accessToken['jwtToken']
          );
          dispatch({
            type: 'LOGIN',
            name: cognitoCallbackData.attributes['name'],
            email: cognitoCallbackData.attributes['email'],
            team: cognitoCallbackData.attributes['custom:team'],
            group:
              group === undefined
                ? undefined
                : cognitoCallbackData.signInUserSession.idToken.payload[
                    'cognito:groups'
                  ][0],
            idToken: cognitoCallbackData.signInUserSession.idToken['jwtToken'],
            accessToken:
              cognitoCallbackData.signInUserSession.accessToken['jwtToken'],
            account: responseData.data
          });
          console.log(
            'signin name data : ' + cognitoCallbackData.attributes['name']
          );
          console.log(
            'signin email data : ' + cognitoCallbackData.attributes['email']
          );
          console.log(
            'signin team data : ' +
              cognitoCallbackData.attributes['custom:team']
          );
          setLoginBackdrop(false);
          history.push('/IntroProduct');
        } else {
          console.log('response code is not 200');
          dialogOn(
            STR_WARNING_SIGNIN_FAIL_TITLE,
            STR_WARNING_SIGNIN_FAIL_CONTENT
          );
        }
      });
    }
  }, [cognitoCallbackData]);

  useEffect(()=>{
    if(userInfo) history.push('/IntroProduct');
  },[userInfo])

  const signIn = () => {
    return naviwatchSignIn(
      formState.values.email,
      cognitoCallbackData.signInUserSession.idToken['jwtToken']
    );
  };

  useEffect(() => {
    if (authInfo.isSignin === true) {
      setLoginBackdrop(false);
      history.push('/IntroProduct');
    }

    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleBack = () => {
    setLoginBackdrop(false);
    history.goBack();
  };

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  async function awsSignIn() {
    return await Auth.signIn(formState.values.email, formState.values.password);
  }

  const handleSignIn = event => {
    event.preventDefault();
    setLoginBackdrop(true);
    awsSignIn()
      .then(callbackData => {
        setCognitoCallbackData(callbackData);
      })
      .catch(e => {
        console.log('Error login:' + JSON.stringify(e));
        setLoginBackdrop(false);
        dgset(true);
        dialogOn(STR_WARNING_ACCOUNT_PW_TITLE, STR_WARNING_ACCOUNT_PW_CONTENT);
      });
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  useEffect(() => {
    setTimeout(
      function() {
        setBackdropState(true);
      }.bind(this),
      2000
    );
  }, []);

  useEffect(()=>{
    if(authInfo.isSignin === true) history.push('/IntroProduct')

  },[backdropdisappear, authInfo])

  return (
    <div className={classes.root}>
      <Backdrop
        className={classes.backdrop}
        open={!backdropdisappear}
        transitionDuration={{ enter: 1500, exit: 500 }}>
        <div className={classes.backdropContent}>
          <img
            className={classes.mark}
            src="/images/naviwatch_logo_2020_09_25.jpg"
          />
        </div>
      </Backdrop>
      <Backdrop className={classes.backdropSignin} open={loginBackdrop}>
        <CircularProgress color={'primary'} />
      </Backdrop>
      {backdropdisappear &&
        authInfo.isSignin === true &&
        history.push('/IntroProduct')}
      {backdropdisappear && (
        <Grid className={classes.grid} container>
          <Dialog
            onClose={setDialogClose}
            aria-labelledby="form-dialog-title"
            open={dgstate}>
            <DialogTitle id="form-dialog-title">
              {dialogTxt.dialogTitle}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>{dialogTxt.dialogContent1}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={setDialogClose} color="primary">
                Retry
              </Button>
            </DialogActions>
          </Dialog>

          <Grid className={classes.quoteContainer} item lg={5}>
            <div className={classes.quote}>
              <div className={classes.quoteInner}>
                <Typography className={classes.quoteText} variant="h1">
                  <p>Smarter Living Environment Partner,</p>
                  <p>KD Navien</p>
                </Typography>
                <div className={classes.person}>
                  <p>&nbsp;</p>
                </div>
              </div>
            </div>
          </Grid>
          <Grid className={classes.content} item lg={7} xs={12}>
            <div className={classes.content}>
              <div className={classes.contentHeader}>
                <IconButton onClick={handleBack}>
                  <ArrowBackIcon />
                </IconButton>
              </div>
              <div className={classes.contentBody}>
                <form className={classes.form} onSubmit={handleSignIn}>
                  <Typography className={classes.title} variant="h2">
                    Sign in
                  </Typography>
                  <Typography color="textSecondary" gutterBottom>
                    signin with email address
                  </Typography>
                  <Typography
                    align="center"
                    className={classes.sugestion}
                    color="textSecondary"
                    variant="body1"></Typography>
                  <TextField
                    className={classes.textField}
                    error={hasError('email')}
                    fullWidth
                    helperText={
                      hasError('email') ? formState.errors.email[0] : null
                    }
                    label="Email address"
                    name="email"
                    onChange={handleChange}
                    type="text"
                    value={formState.values.email || ''}
                    variant="outlined"
                  />
                  <TextField
                    className={classes.textField}
                    error={hasError('password')}
                    fullWidth
                    helperText={
                      hasError('password') ? formState.errors.password[0] : null
                    }
                    label="Password"
                    name="password"
                    onChange={handleChange}
                    type="password"
                    value={formState.values.password || ''}
                    variant="outlined"
                  />
                  <Button
                    className={classes.signInButton}
                    color="primary"
                    disabled={!formState.isValid}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained">
                    Sign in now
                  </Button>

                  <Typography color="textSecondary" variant="body1">
                    Don't have an account?{' '}
                    <Link component={RouterLink} to="/sign-up" variant="h6">
                      Sign up
                    </Link>
                  </Typography>
                  <Typography color="textSecondary" variant="body1">
                    Forget password?{' '}
                    <Link component={RouterLink} to="/reset-password" variant="h6">
                      Reset Password
                    </Link>
                  </Typography>
                </form>
              </div>
            </div>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

SignIn.propTypes = {
  history: PropTypes.object
};

export default withRouter(SignIn);
