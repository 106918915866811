import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core/styles';
import {
  CardContent,
  Divider,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide
} from '@material-ui/core';
import palette from 'theme/palette';
import { options } from './chart';
import {
  STRING_CONNECT_USER,
  STRING_ALL_REGION
} from '../../../StringTable/StringTable';

const useStyles = makeStyles(() => ({
  chartContainer: {
    height: 400,
    position: 'relative'
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const UserAreaDialog = props => {

  const { className, openStatus, closeFunc, data, ...rest } = props;
  const classes = useStyles();
  const [areaData, setAreaData] = useState({
    labels: [],
    datasets: []
  });

  useEffect(() => {
    try {
      if (data !== null && data !== undefined && data['region'] !== undefined) {
        setAreaData(areaData => ({
          ...areaData,
          labels: data['region'],
          datasets: [
            {
              label: STRING_CONNECT_USER,
              backgroundColor: palette.primary.main,
              data: data['userCount']
            }
          ]
        }));
      }
    } catch (e) {
      console.log('areaCount update failed :' + JSON.stringify(e));
    }
  }, [data]);

  return (
    <Dialog
      open={openStatus}
      TransitionComponent={Transition}
      fullWidth={true}
      maxWidth={'xl'}
      keepMounted
      onClose={closeFunc}
    >
      <DialogTitle>{STRING_ALL_REGION}</DialogTitle>
      <DialogContent>
        <CardContent>
          <div className={classes.chartContainer}>
            <Bar data={areaData} options={options} />
          </div>
        </CardContent>
        <Divider />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeFunc} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

UserAreaDialog.propTypes = {
  className: PropTypes.string
};

export default UserAreaDialog;
