import React, { useContext, useEffect, useState } from 'react';
import {
  makeStyles, CardContent, TableHead, TableRow, TableCell, CardActions,
  Card, Table, TableBody, TablePagination, Box, Button, Tooltip,
  IconButton, Typography, Divider, TableContainer, TableFooter
} from '@material-ui/core';

import SearchIcon from '@material-ui/icons/Search';
import Alert from '@material-ui/lab/Alert';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';

import {
  DetailInfoDialog
} from './components';
import PhonelinkRingOutlinedIcon from '@material-ui/icons/PhonelinkRingOutlined';
import {
  NO_PERMISSION,
  NO_PERMISSION_WARNING,
  READ_PERMISSION,
  WRITE_PERMISSION
} from '../../Common/Config/naviwatchConfig';
import { userInfoContext } from '../../../App';
import { firmwareSearchThingGroups, firmwareSearchThings } from '../APIs/mateApis';
import useAsync from '../../../asyncNet';
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  alert:{
    marginBottom: theme.spacing(2)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  BtnAppend: {
    marginRight: theme.spacing(1)
  },
  title:{
    marginBottom:  theme.spacing(5)
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  searchBox:{
    width: "38%",
    marginRight: theme.spacing(1)
  },
  thingsTable:{
    width: "40%",
  }
}));

const STRING_FW_NAME = 'FW(JOB) Name';
const STRING_STATUS = 'Status';
const STRING_TARGET_SELECTION = 'Target Selection';
const STRING_TARGETS = 'Targets Count';
const STRING_CREATED_AT = '생성일';
const STRING_LAST_UPDATED_AT = '마지막 업데이트';
const STRING_DETAIL_SEARCH = '상세보기';
const STRING_ALERT = '펌웨어 수정, 업로드시 각별한 주의를 부탁드립니다.';


function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

const FirmwareCreate = ({history, match}) => {
  const classes = useStyles();
  const { modelName } = match.params;
  const [noPermissionMsg, setNoPermissionMsg] = useState("");
  const authInfo =  useContext(userInfoContext);
  const [permission, setPermission] = useState(NO_PERMISSION);
  const [value, setValue] = React.useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchString, setSearchString] = React.useState("");
  const [checkedThingList, setCheckedThingList] = React.useState([]);

  const getThingOrThingGroups = () => {
    if(value === 0)
      return firmwareSearchThings("deviceId", searchString, "");
    else if(value === 1)
      return firmwareSearchThingGroups(searchString, "");
    else
      return undefined;
  };
  const [searchResult, querySearch] = useAsync(getThingOrThingGroups, [searchString, value]);
  const { loading, data: resources } = searchResult;

  const handleSearchStringChange = event => {
    setSearchString(event.target.value);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(()=>{
    var pageInfo = sessionStorage.getItem('pages');
    if(pageInfo!=="undefined") {
      var pageInfoObj = JSON.parse(pageInfo);
      var pageArray = pageInfoObj.filter(page => page.name.toLowerCase().includes("firmware"));
      if (pageArray !== undefined && pageArray.length > 0) {
        var page = pageArray[0];
        if(page.permission===undefined){
          setNoPermissionMsg(NO_PERMISSION_WARNING);
        }
        setPermission(page.permission);
      }
    }
  }, [authInfo.pages]);



  const clickBack = (modelName) => {
    history.push(`/mate-kr/firmware/${modelName}`);
  };
  const [checked, setChecked] = React.useState(true);

  const checkboxClicked = (event) => {
    setChecked(event.target.checked);
  };
  const handlePageChange = (event, page) => {
    setPage(page);
  };
  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  const getResourceCheckedValue = (thingName) =>{
    return checkedThingList.findIndex(thing=>thing.thingName===thingName)!==-1;
  };

  const setResourceCheckedValue = (thingName) =>{
    if(checkedThingList.findIndex( thing=>thing.thingName===thingName)===-1){
      setCheckedThingList([...checkedThingList, {thingName: thingName}]);
    }else(
      setCheckedThingList(checkedThingList.filter(item=>item.thingName!==thingName))
    )
  };

  const getCount = (responses) =>{
    if(resources!==undefined && resources!==null &&
    resources.things!==undefined && resources.things!==null)
      return resources.things.length
  };
  const resourceTable = (styleClass, resources) =>{
    return(
      <div className={styleClass.thingsTable}>
        <Divider className={styleClass.divider}/>
        <TableContainer component={Paper}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell width="10%" align="left">Check</TableCell>
                <TableCell width="90%" align="center">Resource</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {resources!==undefined && resources!==null&&
                resources.slice((page * rowsPerPage), ((page + 1) * rowsPerPage)).map(resource => (
                <TableRow key={resource.thingName}>
                  <TableCell align="left">
                    <Checkbox
                      checked={getResourceCheckedValue(resource.thingName)}
                      onChange={()=>setResourceCheckedValue(resource.thingName)}
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                  </TableCell>
                  <TableCell align="center">{resource.thingName}</TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell/>
                <TablePagination
                  count={getCount(resources)}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handleRowsPerPageChange}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={[5, 10, 25]}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    );
  };
  const searchView = (classes) =>{
    return (
      <div>
        <Grid container alignItems="flex-end">
          <Grid item>
            <SearchIcon />
          </Grid>
          <Grid item className={classes.searchBox}>
            <TextField id="input-with-search" label="Search" fullWidth={true}
                       onChange={handleSearchStringChange}
                       value={searchString}
            />
          </Grid>
        </Grid>
      </div>
    )
  };

  return (
    <div>
      {permission>=READ_PERMISSION?
        <div className={classes.root}>
          <Alert className={classes.alert} severity={'error'}><span style={{ color: 'red' }}>{STRING_ALERT}</span></Alert>
          <Card >
            <CardContent className={classes.content}>
              <Typography className={classes.title} variant="h2" id="tableTitle" component="div">
                {modelName}
              </Typography>
              <Typography variant="h4">Select devices to update</Typography>
              <Typography variant="body1">Browse and select the devices you want to include in this job.</Typography>
              <Paper className={classes.content}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                  centered
                >
                  <Tab label="Things" {...a11yProps(0)}/>
                  <Tab label="Thing groups" {...a11yProps(0)}/>
                  <Tab label="Summary" {...a11yProps(0)}/>
                </Tabs>
                <TabPanel value={value} index={0}>
                  {searchView(classes)}
                  {resources!==undefined&& resources!==null&&resourceTable(classes, resources.things)}
                </TabPanel>
                <TabPanel value={value} index={1}>
                  {searchView(classes)}
                  {resources!==undefined&& resources!==null&&resourceTable(classes, resources.things)}
                </TabPanel>
                <TabPanel value={value} index={2}>
                  {resourceTable(classes, checkedThingList)}
                </TabPanel>
              </Paper>

            <Divider className={classes.divider}/>

            </CardContent>
            <CardActions className={classes.actions}>
              <Box m={3}
                   display="flex"
                   justifyContent="flex-end"
              >
                <Button className={classes.BtnAppend}
                        onClick={()=>clickBack(modelName)}>
                  Back
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={()=>clickBack(modelName)}
                  disabled={permission<WRITE_PERMISSION}
                >
                  Confirm
                </Button>
              </Box>
            </CardActions>
          </Card>
        </div>:
        <Typography className={classes.root}>{noPermissionMsg}</Typography>
      }
    </div>
  );
};

export default FirmwareCreate;
