/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  DialogTitle,
  Divider,
  Typography,
  makeStyles,
  Input
} from '@material-ui/core';

import PropTypes from 'prop-types';
import useAsync from '../../../../../asyncNet';

import { eoneSetSleepStatusOperationSetting } from '../../../APIs/mateApis';

import acceptPng from 'assets/image/accept.png';

const useStyles = makeStyles(theme => ({
  divider: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5)
  },
  button: {
    marginTop: theme.spacing(2)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex'
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`
  },
  overview_status_container: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  overview_box_margin: {
    marginTop: theme.spacing(1)
  },
  jobFileTextEdit: {
    width: '700px'
  },
  linear: {
    display: 'flex',
    alignItems: 'flex-end'
  },
  datas: {
    marginLeft: theme.spacing(2)
  },
  inputBox: {
    marginLeft: theme.spacing(2),
    padding: theme.spacing(0)
  },
  dataSelection: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    width: '40%'
  },
  tabPannel: {
    width: '100%'
  }
}));

const STRING_DIALOG_NAME = 'Sleep Status Operation Setting';
const STRING_CLOSE = '종료';
const STRING_SAVE = '설정 저장';

const singlePayloadForm = {
  single: {
    sensorId: '',
    set: 0,
    asleep: 0,
    rem: 0,
    awake: 0,
    hour: 0,
    minute: 0,
    standby: 0
  }
};

const doublePayloadForm = {
  left: {
    sensorId: '',
    set: 0,
    asleep: 0,
    rem: 0,
    awake: 0,
    hour: 0,
    minute: 0,
    standby: 0
  },
  right: {
    sensorId: '',
    set: 0,
    asleep: 0,
    rem: 0,
    awake: 0,
    hour: 0,
    minute: 0,
    standby: 0
  }
};

const SleepStatusControlDialog = props => {
  const { open, handleClose, deviceInfo, sleepData } = props;
  const [singlePayloadFormState, setSinglePayloadFormState] = useState(
    singlePayloadForm
  );
  const [doublePayloadFormState, setDoublePayloadFormState] = useState(
    doublePayloadForm
  );
  const [settingState, setSettingState] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    if (sleepData && sleepData.payload) {
      const sleepCtrl = sleepData.payload;
      if (typeof sleepCtrl === 'object') {
        if (Object.keys(sleepCtrl).includes('single')) {
          const singleObj = {
            single: {
              sensorId: sleepCtrl.single.sensorId,
              set: sleepCtrl.single.temperature.set,
              asleep: sleepCtrl.single.temperature.asleep,
              rem: sleepCtrl.single.temperature.rem,
              awake: sleepCtrl.single.temperature.awake,
              hour: sleepCtrl.single.time.awake.hour,
              minute: sleepCtrl.single.time.awake.minute,
              standby: sleepCtrl.single.time.awake.standby
            }
          };
          setSinglePayloadFormState(singleObj);
        } else {
          const doubleObj = {
            left: {
              sensorId: sleepCtrl.left.sensorId,
              set: sleepCtrl.left.temperature.set,
              asleep: sleepCtrl.left.temperature.asleep,
              rem: sleepCtrl.left.temperature.rem,
              awake: sleepCtrl.left.temperature.awake,
              hour: sleepCtrl.left.time.awake.hour,
              minute: sleepCtrl.left.time.awake.minute,
              standby: sleepCtrl.left.time.awake.standby
            },
            right: {
              sensorId: sleepCtrl.right.sensorId,
              set: sleepCtrl.right.temperature.set,
              asleep: sleepCtrl.right.temperature.asleep,
              rem: sleepCtrl.right.temperature.rem,
              awake: sleepCtrl.right.temperature.awake,
              hour: sleepCtrl.right.time.awake.hour,
              minute: sleepCtrl.right.time.awake.minute,
              standby: sleepCtrl.right.time.awake.standby
            }
          };
          setDoublePayloadFormState(doubleObj);
        }
        setSettingState(true);
      } else {
        setSinglePayloadFormState(singlePayloadForm);
        setDoublePayloadFormState(doublePayloadForm);
        setSettingState(false);
      }
    }
  }, [sleepData]);

  const setPayloadValue = (type, e, key) => {
    type === 'single'
      ? setSinglePayloadValue(e, key)
      : setDoublePayloadValue(type, e, key);
  };

  const setSinglePayloadValue = (e, key) => {
    const newSingleItem = { ...singlePayloadFormState.single };
    newSingleItem[key] =
      key === 'sensorId' ? e.target.value : Number(e.target.value);
    const newPayload = { ...singlePayloadFormState, single: newSingleItem };
    setSinglePayloadFormState(newPayload);
  };

  const setDoublePayloadValue = (side, e, key) => {
    let newSubItem = {};
    let newPayload = {};

    if (side === 'left') {
      newSubItem = { ...doublePayloadFormState.left };
      newSubItem[key] =
        key === 'sensorId' ? e.target.value : Number(e.target.value);
      newPayload = { ...doublePayloadFormState, left: newSubItem };
    } else {
      newSubItem = { ...doublePayloadFormState.right };
      newSubItem[key] =
        key === 'sensorId' ? e.target.value : Number(e.target.value);
      newPayload = { ...doublePayloadFormState, right: newSubItem };
    }
    setDoublePayloadFormState(newPayload);
  };

  const handleSaveClick = async () => {
    const deviceId =
      deviceInfo.data.macAddress + deviceInfo.data.additionalValue;

    if (deviceInfo.data.capacityInfo === '1') {
      const result = await eoneSetSleepStatusOperationSetting(
        deviceId,
        'single',
        singlePayloadFormState.single
      );
      if (result && result.payload && typeof result.payload === 'object') {
        setSettingState(true);
      } else {
        setSettingState(false);
        alert('설정에 실패 했습니다. 잠시 후  다시 시도해 주세요.');
        return;
      }
    } else {
      let result = await eoneSetSleepStatusOperationSetting(
        deviceId,
        'left',
        doublePayloadFormState.left
      );

      if (result && result.payload && typeof result.payload === 'object') {
        result = await eoneSetSleepStatusOperationSetting(
          deviceId,
          'right',
          doublePayloadFormState.right
        );

        if (result && result.payload && typeof result.payload === 'object') {
          setSettingState(true);
        } else {
          setSettingState(false);
          alert('설정에 실패 했습니다. 잠시 후  다시 시도해 주세요.');
          return;
        }
      } else {
        setSettingState(false);
        alert('설정에 실패 했습니다. 잠시 후  다시 시도해 주세요.');
        return;
      }
    }
    handleCloseClick();
  };

  const handleCloseClick = () => {
    handleClose();
  };

  const renderContentUnit = type => {
    const defaultSensorId =
      type === 'single'
        ? singlePayloadFormState.single.sensorId
        : type === 'left'
        ? doublePayloadFormState.left.sensorId
        : doublePayloadFormState.right.sensorId;

    const defaultSet =
      type === 'single'
        ? singlePayloadFormState.single.set
        : type === 'left'
        ? doublePayloadFormState.left.set
        : doublePayloadFormState.right.set;

    const defaultAsleep =
      type === 'single'
        ? singlePayloadFormState.single.asleep
        : type === 'left'
        ? doublePayloadFormState.left.asleep
        : doublePayloadFormState.right.asleep;

    const defaultRem =
      type === 'single'
        ? singlePayloadFormState.single.rem
        : type === 'left'
        ? doublePayloadFormState.left.rem
        : doublePayloadFormState.right.rem;

    const defaultAwake =
      type === 'single'
        ? singlePayloadFormState.single.awake
        : type === 'left'
        ? doublePayloadFormState.left.awake
        : doublePayloadFormState.right.awake;

    const defaultHour =
      type === 'single'
        ? singlePayloadFormState.single.hour
        : type === 'left'
        ? doublePayloadFormState.left.hour
        : doublePayloadFormState.right.hour;

    const defaultMinite =
      type === 'single'
        ? singlePayloadFormState.single.minute
        : type === 'left'
        ? doublePayloadFormState.left.minute
        : doublePayloadFormState.right.minute;

    const defaultStandby =
      type === 'single'
        ? singlePayloadFormState.single.standby
        : type === 'left'
        ? doublePayloadFormState.left.standby
        : doublePayloadFormState.right.standby;

    return (
      <div>
        <div className={classes.linear}>
          <Typography>센서 아이디 </Typography>:{' '}
          <Input
            className={classes.datas}
            inputProps={{ 'aria-label': 'description' }}
            onChange={e => setPayloadValue(type, e, 'sensorId')}
            value={defaultSensorId}
          />
        </div>
        <div
          className={classes.linear}
          style={{ marginTop: '30px', fontSize: '16px' }}>
          <p>{'온도 설정'}</p>
        </div>
        <div className={classes.linear}>
          <Typography style={{ marginBottom: '5px' }}>{'set: '}</Typography>
          <Input
            className={classes.datas}
            inputProps={{ 'aria-label': 'description' }}
            margin={'none'}
            onChange={e => setPayloadValue(type, e, 'set')}
            type={'number'}
            value={defaultSet}
          />
          <Typography
            style={{
              marginBottom: '5px',
              marginLeft: '15px'
            }}>
            {'asleep: '}
          </Typography>
          <Input
            className={classes.datas}
            inputProps={{ 'aria-label': 'description' }}
            onChange={e => setPayloadValue(type, e, 'asleep')}
            type={'number'}
            value={defaultAsleep}
          />
          <Typography
            style={{
              marginBottom: '5px',
              marginLeft: '15px'
            }}>
            {'rem: '}
          </Typography>
          <Input
            className={classes.datas}
            inputProps={{ 'aria-label': 'description' }}
            onChange={e => setPayloadValue(type, e, 'rem')}
            type={'number'}
            value={defaultRem}
          />
          <Typography
            style={{
              marginBottom: '5px',
              marginLeft: '15px'
            }}>
            {'awake: '}
          </Typography>
          <Input
            className={classes.datas}
            inputProps={{ 'aria-label': 'description' }}
            onChange={e => setPayloadValue(type, e, 'awake')}
            type={'number'}
            value={defaultAwake}
          />
        </div>
        <div
          className={classes.linear}
          style={{ marginTop: '30px', fontSize: '16px' }}>
          <p>{'시간 설정'}</p>
        </div>
        <div className={classes.linear}>
          <Typography style={{ marginBottom: '5px' }}>{'hour: '}</Typography>
          <Input
            className={classes.datas}
            inputProps={{ 'aria-label': 'description' }}
            onChange={e => setPayloadValue(type, e, 'hour')}
            type={'number'}
            value={defaultHour}
          />
          <Typography
            style={{
              marginBottom: '5px',
              marginLeft: '15px'
            }}>
            {'minute: '}
          </Typography>
          <Input
            className={classes.datas}
            inputProps={{ 'aria-label': 'description' }}
            onChange={e => setPayloadValue(type, e, 'minute')}
            type={'number'}
            value={defaultMinite}
          />
          <Typography
            style={{
              marginBottom: '5px',
              marginLeft: '15px'
            }}>
            {'standby: '}
          </Typography>
          <Input
            className={classes.datas}
            inputProps={{ 'aria-label': 'description' }}
            onChange={e => setPayloadValue(type, e, 'standby')}
            type={'number'}
            value={defaultStandby}
          />
        </div>
      </div>
    );
  };

  const renderDoubleType = () => {
    return (
      <div>
        <div className={classes.linear}>
          <Typography style={{ fontSize: '18px', fontWeight: 'bold' }}>
            좌측 설정
          </Typography>
        </div>
        <Divider className={classes.divider} />
        {renderContentUnit('left')}
        <div className={classes.linear} style={{ marginTop: '60px' }}>
          <Typography style={{ fontSize: '18px', fontWeight: 'bold' }}>
            우측 설정
          </Typography>
        </div>
        <Divider className={classes.divider} />
        {renderContentUnit('right')}
      </div>
    );
  };

  const opeartionPannel = classes => {
    const capacityType =
      deviceInfo && deviceInfo.data && deviceInfo.data.capacityInfo !== '1'
        ? 'double'
        : 'single';
    return capacityType === 'double'
      ? renderDoubleType()
      : renderContentUnit('single');
  };

  return (
    <div>
      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        fullWidth={true}
        maxWidth={'md'}
        onClose={handleClose}
        open={open}>
        <DialogTitle id="alert-dialog-title">
          <div style={{ display: 'flex' }}>
            <Typography component={'span'} variant="h6">
              {STRING_DIALOG_NAME}
            </Typography>
            {settingState ? (
              <div style={{ display: 'flex' }}>
                <Typography
                  style={{ marginLeft: 15, color: 'green' }}
                  component={'span'}
                  variant="h6">
                  {'설정됨'}
                </Typography>
                <img
                  style={{ marginLeft: 5 }}
                  alt="acceptPng"
                  src={acceptPng}
                  width="18"
                  height="18"
                />
              </div>
            ) : (
              <Typography
                style={{ marginLeft: 15, color: 'red' }}
                component={'span'}
                variant="h6">
                {'미설정'}
              </Typography>
            )}
            <br />
          </div>
        </DialogTitle>
        <DialogContent className={classes.root}>
          {opeartionPannel(classes)}
        </DialogContent>
        <DialogActions>
          <Button autoFocus color="primary" onClick={handleSaveClick}>
            {STRING_SAVE}
          </Button>
          <Button autoFocus color="primary" onClick={handleCloseClick}>
            {STRING_CLOSE}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

SleepStatusControlDialog.prototype = {
  className: PropTypes.string
};

export default SleepStatusControlDialog;
