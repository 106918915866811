import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Card, CardContent, CardActions, Button } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '80%'
  },
  item: {
    height: '50%'
  },
  action: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  image: {
    width: '40%'
  },
  button: {
    marginBottom: theme.spacing(3)
  }
}));

const getProductName = (prodName, regionName) => {
  if (regionName !== '') {
    return prodName + '-' + regionName;
  } else {
    return prodName;
  }
};

const storeLocalValue = (prd)=>{
  if(prd !== undefined){
    localStorage.productID = prd.PRODUCT_ID;
    localStorage.productName = prd.PRODUCT_NAME;
    localStorage.regionName = prd.REGION_NAME;
    localStorage.productIdx = prd.PRODUCT_IDX;
  }
};

const getLinkPage = (prd)=>{
  if(prd.PRODUCT_ENABLE!=true){
    return '/IntroProduct';
  }else{
    if(prd.REGION_NAME!==""){
      return `/${prd.PRODUCT_NAME}-${prd.REGION_NAME}/dashboard`;
    }else{
      return `/${prd.PRODUCT_NAME}/dashboard`;
    }
  }
}

const ProductCard = props => {
  const { product, disable } = props;
  const [mouseOver, setMouseOver] = useState(-1);

  const classes = useStyles();

  return (
    <Grid
      className={classes.item}
      item
      key={product.PRODUCT_ID + product.REGION_NAME}
      lg={4}
      md={4}
      xs={12}
      onMouseOver={() => setMouseOver(product.PRODUCT_ID + product.REGION_NAME)}
      onMouseOut={() => setMouseOver(-1)}>
      <Link
        to={getLinkPage(product)}
        onClick={() => {
          storeLocalValue(product);
        }}>
        <Card className={classes.root}>
          <CardContent className={classes.card}>
            <img
              alt="Product"
              height={
                disable
                  ? '70%'
                  : mouseOver === product.PRODUCT_ID + product.REGION_NAME
                  ? '90%'
                  : '70%'
              }
              src={product.PRODUCT_IMAGE}
            />
          </CardContent>
          <CardActions className={classes.action}>
            <Button
              className={classes.button}
              color="primary"
              size="large"
              type="button"
              disabled={disable}
              variant={
                mouseOver === product.PRODUCT_ID + product.REGION_NAME
                  ? 'contained'
                  : 'outlined'
              }>
              {getProductName(product.PRODUCT_NAME, product.REGION_NAME)}
            </Button>
          </CardActions>
        </Card>
      </Link>
    </Grid>
  );
};

ProductCard.propTypes = {
  className: PropTypes.string,
  product: PropTypes.object.isRequired
};

export default ProductCard;
