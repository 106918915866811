import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, Typography } from '@material-ui/core';

import {
  ServerCharts
} from './ServerChart';
import { DBCharts } from './DbChart';
import { LoadBalancerCharts } from './LoadBalancerChart';
import { NO_PERMISSION, NO_PERMISSION_WARNING, READ_PERMISSION } from '../../Common/Config/naviwatchConfig';
import { userInfoContext } from '../../../App';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    flexGrow: 1,
  }
}));

const redirectAwsDashboard = ()=>{
    const url = "https://naver.com";
    window.open(url, '_blank');
}

const STRING_AWS_DASHBOARD_PAGE = "Click for more information (GO TO AWS CLOUDWATCH DASHBOARD)";

const Server = ({match, history}) => {
  const classes = useStyles();
  const authInfo =  useContext(userInfoContext);
  const [noPermissionMsg, setNoPermissionMsg] = useState("");
  const [permission, setPermission] = useState(NO_PERMISSION);
  useEffect(()=>{
    var pageInfo = sessionStorage.getItem('pages');
    if(pageInfo!=="undefined") {
      var pageInfoObj = JSON.parse(pageInfo);
      var pageArray = pageInfoObj.filter(page => page.name.toLowerCase().includes("server"));
      if (pageArray !== undefined && pageArray.length > 0) {
        var page = pageArray[0];
        if(page.permission===undefined){
          setNoPermissionMsg(NO_PERMISSION_WARNING);
        }
        setPermission(page.permission);
      }
    }
  }, [authInfo.pages]);

  useEffect(()=>{
    if(sessionStorage.getItem('naviwatch_permission')!=="true")
      history.push('/')
  },[]);
  return (
    <div>
      {permission>=READ_PERMISSION?
        <div className={classes.root}>
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              sm={6}
              xs={12}
            >
              <ServerCharts metricName="CPUUtilization"/>
            </Grid>
            <Grid
              item
              sm={6}
              xs={12}
            >
              <ServerCharts metricName="MemoryUtilization"/>
            </Grid>
            <Grid
              item
              sm={6}
              xs={12}
            >
              <DBCharts metricName="CPUUtilization" unitName="Percent" stat=""/>
            </Grid>
            <Grid
              item
              sm={6}
              xs={12}
            >
              <DBCharts metricName="Queries" unitName="Count/Second" stat="Sum"/>
            </Grid>

            <Grid
              item
              sm={6}
              xs={12}
            >
              <LoadBalancerCharts metricName="ActiveFlowCount_TCP"/>
            </Grid>
            <Grid
              item
              sm={6}
              xs={12}
            >
              <LoadBalancerCharts metricName="ProcessedBytes_TCP"/>
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
            >{/*
              <Button
                color="primary"
                variant="contained"
                fullWidth={true}
                onClick={()=>redirectAwsDashboard()}
              >
                {STRING_AWS_DASHBOARD_PAGE}
              </Button>*/}
            </Grid>
          </Grid>
        </div>:
        <Typography className={classes.root}>{noPermissionMsg}</Typography>
      }
    </div>
  );
};

export default Server;
