import axios from 'axios';
const ApiInstance = axios.create({ baseURL : 'https://4edlhjpvk8.execute-api.ap-northeast-2.amazonaws.com/' });

export async function naviwatchSignIn(userId, token) {
  var response = undefined;
  let reqUrl = 'naviwatch-api-v1/naviwatch/api/v1/user/sign-in';
  let data = JSON.stringify({
    "userId": userId
  });

  let config = {headers:{
    "authorizer":  token
  }};
  console.log("Token:"+token);

  response = await ApiInstance.post(reqUrl, data, config);
  console.log("Signin Response:"+JSON.stringify(response));
  return response.data;
}

export async function naviwatchSignUp(userName, userEmail, password, team) {
  var response = undefined;
  let reqUrl = 'naviwatch-api-v1/naviwatch/api/v1/user/sign-up';
  let data = JSON.stringify({
    "userName": userName,
    "userEmail": userEmail,
    "password": password,
    "team": team
  });

  response = await ApiInstance.post(reqUrl, data);
  console.log("SignUp Response:"+JSON.stringify(response));
  return response.data;
}

export async function naviwatchWithdraw(userEmail) {
  var response = undefined;
  let reqUrl = 'naviwatch-api-v1/naviwatch/api/v1/user';

  let data = JSON.stringify({
    "userId": userEmail
  });

  let config = {
    data: {
      "userId": userEmail
    },
    headers:{
        "authorizer":  sessionStorage.getItem('idToken')
      }
  };

  response = await ApiInstance.delete(reqUrl, config);
  console.log("Withdraw Response:"+JSON.stringify(response));
  return response.data;
}


export async function naviwatchGetUserCount() {
  var response = undefined;
  let reqUrl = `naviwatch-api-v1/naviwatch/api/v1/user/count`;
  let config = {headers:{
      "authorizer":  sessionStorage.getItem('idToken')
    }};

  response = await ApiInstance.get(reqUrl, config);
  console.log("user count Response:"+JSON.stringify(response));
  return response.data;
}

export async function naviwatchGetFullPolicies() {
  var response = undefined;
  let reqUrl = 'naviwatch-api-v1/naviwatch/api/v1/policy';
  let config = {headers:{
      "authorizer":  sessionStorage.getItem('idToken')
    }};

  response = await ApiInstance.get(reqUrl, config);
  console.log("fullPolicies Response:"+JSON.stringify(response));
  return response.data;
}

export async function naviwatchGetPolicies(productId) {
  var response = undefined;
  let reqUrl = `naviwatch-api-v1/naviwatch/api/v1/policy/product?productSeq=${productId}`;
  let config = {headers:{
      "authorizer":  sessionStorage.getItem('idToken')
    }};

  response = await ApiInstance.get(reqUrl, config);
  console.log("product policy Response:"+JSON.stringify(response));
  return response.data;
}

export async function naviwatchGetFullRoles() {
  var response = undefined;
  let reqUrl = 'naviwatch-api-v1/naviwatch/api/v1/role';
  let config = {headers:{
      "authorizer":  sessionStorage.getItem('idToken')
    }};

  response = await ApiInstance.get(reqUrl, config);
  console.log("fullPolicies Response:"+JSON.stringify(response));
  return response.data;
}

export async function naviwatchGetRoles(productId) {
  var response = undefined;
  let reqUrl = `naviwatch-api-v1/naviwatch/api/v1/role/product?productSeq=${productId}`;
  let config = {headers:{
      "authorizer":  sessionStorage.getItem('idToken')
    }};

  response = await ApiInstance.get(reqUrl, config);
  console.log("product roles Response:"+JSON.stringify(response));
  return response.data;
}


export async function naviwatchUserSearch(productId, userEmail, userName, roleId) {
  var response = undefined;
  console.log("userEmail:"+userEmail);
  console.log("userName:"+userName);
  console.log("roleId:"+roleId);
  let reqUrl = `naviwatch-api-v1/naviwatch/api/v1/user?productSeq=${productId}&`
  +`userEmail=${userEmail}&userName=${userName}&roleSeq=${roleId}`;
  let config = {headers:{
      "authorizer":  sessionStorage.getItem('idToken')
    }};

  response = await ApiInstance.get(reqUrl, config);
  console.log("search users Response:"+JSON.stringify(response));
  return response.data;
}

export async function naviwatchDeletedUserSearch(userEmail) {
  var response = undefined;
  console.log("userEmail:"+userEmail);
  let reqUrl = `naviwatch-api-v1/naviwatch/api/v1/user/withdrawal?`+
    `userEmail=${userEmail}`;
  let config = {headers:{
      "authorizer":  sessionStorage.getItem('idToken')
    }};

  response = await ApiInstance.get(reqUrl, config);
  console.log("search users Response:"+JSON.stringify(response));
  return response.data;
}

export async function naviwatchSetPolicy(userId, productId, roleId,
                                         setterEmail, callback) {
  var response = undefined;
  let reqUrl = 'naviwatch-api-v1/naviwatch/api/v1/user/role';
  let data = JSON.stringify({
    "productSeq": productId,
    "userId": userId,
    "roleSeq": roleId,
    "managerUserId": setterEmail
  });

  let config = {headers:{
      "authorizer":  sessionStorage.getItem('idToken')
    }};

  response = await ApiInstance.post(reqUrl, data, config);
  console.log("set policy:"+JSON.stringify(response));
  callback();
  return response.data;
}

export async function naviwatchPolicyHistory(userEmail) {
  var response = undefined;

  let reqUrl = `naviwatch-api-v1/naviwatch/api/v1/user/role/history?userId=${userEmail}`;
  let config = {headers:{
      "authorizer":  sessionStorage.getItem('idToken')
    }};

  response = await ApiInstance.get(reqUrl, config);
  console.log("search users Response:"+JSON.stringify(response));
  return response.data;
}

export async function naviwatchGetWhiteList(ip, description) {
  var response = undefined;
  let reqUrl = `naviwatch-api-v1/naviwatch/api/v1/whitelist?ip=${ip}&`+
    `description=${description}`;
  let config = {headers:{
      "authorizer":  sessionStorage.getItem('idToken')
    }};

  response = await ApiInstance.get(reqUrl, config);
  console.log("whitelist get Response:"+JSON.stringify(response));
  return response.data;
}

export async function naviwatchAddWhiteList(ip, description) {
  var response = undefined;
  let reqUrl = `naviwatch-api-v1/naviwatch/api/v1/whitelist`;
  let config = {headers:{
      "authorizer":  sessionStorage.getItem('idToken')
    }};
  let data = JSON.stringify({
    "ip": ip,
    "description": description
  });
  response = await ApiInstance.post(reqUrl, data, config);
  console.log("whitelist post Response:"+JSON.stringify(response));
  return response.data;
}

export async function naviwatchDeleteWhiteList(ip) {
  var response = undefined;
  let reqUrl = `naviwatch-api-v1/naviwatch/api/v1/whitelist`;

  let config = {
    data: {
      "ip": ip
    },
    headers:{
      "authorizer":  sessionStorage.getItem('idToken')
    }
  };

  response = await ApiInstance.delete(reqUrl, config);
  console.log("whitelist delete Response:"+JSON.stringify(response));
  return response.data;
}

export async function naviwatchCheckWhiteListValidation(myIp) {
  var response = undefined;
  var retValue = false;
  let reqUrl = `naviwatch-api-v1/naviwatch/api/v1/whitelist?ip=&description=`;
  let config = {headers:{
      "authorizer":  sessionStorage.getItem('idToken')
    }};

  response = await ApiInstance.get(reqUrl, config);
  console.log(`check whitelist response: ${JSON.stringify(response)}, myIp: ${myIp}`);

  if(response!==undefined && response.data!==undefined){
    var ipLists = response.data.data;
    if (ipLists !== undefined){
      console.log(`ipLists: ${JSON.stringify(ipLists)}`);
      var matchedIp = ipLists.filter(ipData=>(ipData.ip.includes(myIp)===true));
      console.log(`matchedIp: ${JSON.stringify(matchedIp)}`);
      if(matchedIp.length>0){
        retValue = true;
      }
    }
  }
  console.log(`whitelist detection: ${retValue}, myIp: ${myIp}`);

  return retValue;
}
