import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
} from '@material-ui/core';
import regionCode from '../../../../../common/regionCode';
import axios from 'axios';
import useAsync from '../../../../../asyncNet';
import { getDeviceCount } from "../../../APIs/tokapis";
import {
  STRING_SEARCH,
  STRING_CLEAN,
  STRING_DEVICE_SEARCH,
  STRING_TOTAL_DEVICE_IS_1,
  STRING_TOTAL_DEVICE_IS_2,
  STRING_DEVICE_ID
} from '../../../StringTable/StringTable';

const useStyles = makeStyles(theme => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  },
  title: {
    fontWeight: 700
  }
}));



const DeviceToolbar = props => {
  const { className, callbackFunc, ...rest } = props;

  const classes = useStyles();
  const [state, tryRefetch] = useAsync(getDeviceCount, []);
  const { loading, data: deviceCount, error } = state;

  const [values, setValues] = useState({
    deviceId: ''
  });

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const cleanSearchInput = () => {
    setValues({
      deviceId: ''
    });
  };


  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >

      <CardHeader
        title={STRING_DEVICE_SEARCH}
        subheader={STRING_TOTAL_DEVICE_IS_1 + (deviceCount ? deviceCount.totalCount : '-') + STRING_TOTAL_DEVICE_IS_2}
      />
      <Divider />
      <CardContent>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            md={4}
            xs={12}
          >
            <TextField
              fullWidth
              label={STRING_DEVICE_ID}
              margin="dense"
              name="deviceId"
              onChange={handleChange}
              value={values.deviceId}
              variant="outlined"

            />
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>
        <Button
          color="primary"
          variant="contained"
          onClick={cleanSearchInput}
          disabled={values.deviceId === ''}
        >
          {STRING_CLEAN}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => callbackFunc(values)}
          disabled={values.deviceId === ''}
        >
          {STRING_SEARCH}
        </Button>
      </CardActions>
    </Card>
  );
};

DeviceToolbar.propTypes = {
  className: PropTypes.string
};

export default DeviceToolbar;
