import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

import { SearchInput } from 'components';
import useAsync from '../../../../../asyncNet';
import { getConnectedDeviceNumber } from '../../../APIs/tokapis';
import { STRING_RC_CONNECT_COUNT } from '../../../StringTable/StringTable';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  connDevCnt: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    marginRight: theme.spacing(1),
    width: "100%"
  }
}));

const UsersToolbar = props => {
  const { className, onRequestSearchUser, ...rest } = props;
  const classes = useStyles();
  /* Response data from the SmartTok server */
  const [connectedDeviceNumber, getConnectedDeviceCount] = useAsync(getConnectedDeviceNumber, []);
  const { loading, data: connDevCnt, error } = connectedDeviceNumber;
  /* Text for user search */
  const [searchText, searchTextSet] = useState('');
  const onClickSearchUser = () => {
    onRequestSearchUser(searchText);
  };
  const onKeyupSearchInput = (event) => {
    if (event.key === 'Enter') {
      onRequestSearchUser(searchText);
    }
  }
  const onChangeSearchInput = (event) => {
    searchTextSet(event.target.value);
  }

  return (
    <div {...rest} className={clsx(classes.root, className)} >
      <div className={classes.row}>
        <Button className={classes.connDevCnt}>{STRING_RC_CONNECT_COUNT}{connDevCnt ? connDevCnt.count : '-'}</Button>
        <span className={classes.spacer} />
        <div className={classes.row}>
          <SearchInput className={classes.searchInput} placeholder="Search user"
            onChange={onChangeSearchInput} onKeyPress={onKeyupSearchInput} />
          <Button color="primary" variant="contained" onClick={onClickSearchUser}>Search</Button>
        </div>
      </div>
    </div>
  );
};

UsersToolbar.propTypes = {
  className: PropTypes.string
};

export default UsersToolbar;
