import React, { useState } from 'react';
import {
  Dialog, DialogActions, DialogContent, Button,
  DialogTitle, Divider, Typography, makeStyles,
  Backdrop, CircularProgress
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import { deleteFirmware } from '../../../../SmartTok-CN/APIs/tokapis';
import AlertPopup from './AlertPopup';
import {
  STRING_FW_VERSION,
  STRING_SW_CODE,
  STRING_FW_DESCRIPTION,
  STRING_CANCEL,
  STRING_DELETE,
  STRING_FIRMWARE_DELETE,
  STRING_CHECK_DELETE_FW,
  STRING_CLICK_DELETE_BUTTON,
  STRING_FAIL_DELETE_FIRMWARE,
  STIRNG_SUCCESS_DELETE_FIRMWARE
} from '../../../StringTable/StringTable';


const useStyles = makeStyles(theme => ({
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  linear: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(2)
  },
  datas: {
    marginLeft: theme.spacing(2)
  },
  icons: {
    marginLeft: theme.spacing(2),
    padding: theme.spacing(0)
  },
  fwDescription: {
    marginLeft: theme.spacing(2),
    width: 400
  },
  uploadTime: {
    marginLeft: theme.spacing(2),
    width: '20%'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: theme.spacing(2)
  }
}));

const DeleteFirmwareDialog = props => {
  const { open, handleClose, refetch, selectedFW, detailClose } = props;
  const [loading, setLoading] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [failOpen, setFailOpen] = useState(false);
  const classes = useStyles();

  const handleCloseClick = () => {
    handleClose();
    detailClose();
  };

  const handleDelete = async () => {
    setLoading(true);
    handleCloseClick();
    let deleteFW = await deleteFirmware(
      parseInt(selectedFW.version, 16),
      parseInt(selectedFW.swCode, 16),
      refetchList);
    setLoading(false);
    if (deleteFW.code === 200) {
      openAlertSuccess();
    } else {
      openAlertFail();
    }
  };

  const refetchList = () => refetch();
  const openAlertSuccess = () => setSuccessOpen(true);
  const closeAlertSuccess = () => setSuccessOpen(false);
  const openAlertFail = () => setFailOpen(true);
  const closeAlertFail = () => setFailOpen(false);

  return (
    <div>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color={'primary'} />
      </Backdrop>
      <Dialog
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{STRING_FIRMWARE_DELETE}</DialogTitle>
        <DialogContent>
          <Alert severity={'error'}>
            <span style={{ color: 'red' }}>{STRING_CHECK_DELETE_FW}</span>
            <div style={{ color: 'red' }}>{STRING_CLICK_DELETE_BUTTON}</div>
          </Alert>
          <Divider className={classes.divider} />
          <div className={classes.linear}>
            <Typography variant="h6">{STRING_FW_VERSION}{' :'}</Typography>
            <Typography className={classes.datas} variant="h6">{selectedFW.version ? 'V' + parseInt(selectedFW.version.substr(0, 2), 16) + '-' + parseInt(selectedFW.version.substr(2, 2), 16) : '-'}</Typography>
          </div>
          <Divider className={classes.divider} />
          <div className={classes.linear}>
            <Typography variant="h6">{STRING_SW_CODE}{' :'}</Typography>
            <Typography className={classes.datas} variant="h6">{selectedFW.swCode ? '0x' + selectedFW.swCode : '-'}</Typography>
          </div>
          <Divider className={classes.divider} />
          <div className={classes.linear}>
            <Typography variant="h6">{STRING_FW_DESCRIPTION}{' :'}</Typography>
            <Typography className={classes.datas} variant="h6">{selectedFW.description ? selectedFW.description : '-'}</Typography>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseClick} color="primary" autoFocus>
            {STRING_CANCEL}
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            {STRING_DELETE}
          </Button>
        </DialogActions>
      </Dialog>
      <AlertPopup open={successOpen} handleClose={closeAlertSuccess} comment={STIRNG_SUCCESS_DELETE_FIRMWARE} color={'green'} severity={'success'} />
      <AlertPopup open={failOpen} handleClose={closeAlertFail} comment={STRING_FAIL_DELETE_FIRMWARE} color={'red'} severity={'error'} />
    </div>
  );
};

DeleteFirmwareDialog.propTypes = {
  className: PropTypes.string
};

export default DeleteFirmwareDialog;
