import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/core/styles';
import { userDetailInfo } from '../../../APIs/tokapis';

import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination
} from '@material-ui/core';

import axios from 'axios';
import useAsync from '../../../../../asyncNet';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import ZoomInTwoToneIcon from '@material-ui/icons/ZoomInTwoTone';
import DetailDialog from './DetailDialog';
import PushDialog from './PushDialog';
import PhonelinkRingOutlinedIcon from '@material-ui/icons/PhonelinkRingOutlined';
import {
  STRING_USER_ID,
  STRING_USER_EMAIL,
  STRING_USER_NAME,
  STRING_USER_PHONE,
  STRING_PUSH_SEND,
  STRING_SHOW_DETAIL
} from '../../../StringTable/StringTable';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  deviceView: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  deviceDescriptView: {
    display: 'flex',
    flexDirection: 'column'
  },
  deviceDescriptDivider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

const UsersTable = props => {
  const { className, users, ip, ...rest } = props;

  const classes = useStyles();

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [userId, setUserChoice] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [open, setOpen] = React.useState(false);
  const [pushOpen, setPushOpen] = React.useState(false);
  const [state, tryRefetch] = useAsync(() => userDetailInfo(userId, ip), [], true);
  const { loading, data: userInfo, error } = state;


  useEffect(() => {
    if (userId !== '') {
      tryRefetch();
    }

  }, [userId]);

  const handleClickOpen = (user) => {
    setUserChoice(user);
    setOpen(true);
  };

  const handleClose = () => {
    setUserChoice('');
    setOpen(false);
  };


  const handlePushClickOpen = (user) => {
    setUserChoice(user);
    setPushOpen(true);
  };

  const handlePushClose = () => {
    setUserChoice('');
    setPushOpen(false);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  return (
    <div>
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align={'center'}><span><b>{STRING_USER_ID}</b></span></TableCell>
                    <TableCell align={'center'}><span><b>{STRING_USER_NAME}</b></span></TableCell>
                    <TableCell align={'center'}><span><b>{STRING_USER_EMAIL}</b></span></TableCell>
                    <TableCell align={'center'}><span><b>{STRING_USER_PHONE}</b></span></TableCell>
                    <TableCell align={'center'}><span><b>{STRING_SHOW_DETAIL}</b></span></TableCell>
                    <TableCell align={'center'}><span><b>{STRING_PUSH_SEND}</b></span></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users !== null && users !== undefined && users.slice((page * rowsPerPage), ((page + 1) * rowsPerPage)).map(user => (
                    <TableRow
                      className={classes.tableRow}
                      hover
                      key={user.userEmail}
                      selected={selectedUsers.indexOf(user.userId) !== -1}
                    >
                      <TableCell align={'center'}>
                        {user.userId ? user.userId : '-'}
                      </TableCell>
                      <TableCell align={'center'}>
                        {user.userName ? user.userName : '-'}
                      </TableCell>
                      <TableCell align={'center'}>
                        {user.userEmail ? user.userEmail : '-'}
                      </TableCell>
                      <TableCell align={'center'}>
                        {user.userPhone ? user.userPhone : '-'}
                      </TableCell>
                      <TableCell align={'center'}>
                        <Tooltip title={STRING_SHOW_DETAIL}>
                          <IconButton aria-label={STRING_SHOW_DETAIL}
                            onClick={() => handleClickOpen(user.userId)}>
                            <ZoomInTwoToneIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                      <TableCell align={'center'}>
                        <Tooltip title={STRING_PUSH_SEND}>
                          <IconButton aria-label={STRING_PUSH_SEND}
                            disabled={(sessionStorage.getItem('group') !== 'master')}
                            onClick={() => handlePushClickOpen(user.userId)}>
                            <PhonelinkRingOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={users !== undefined ? users.length : 0}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </CardActions>
      </Card>
      <DetailDialog open={open} handleClose={handleClose} userInfo={userInfo} rendering={tryRefetch} />
      <PushDialog open={pushOpen} handleClose={handlePushClose} userId={userInfo !== null && userInfo !== undefined && userInfo.userdetail.userId} />
    </div>
  );
};

UsersTable.propTypes = {
  className: PropTypes.string,
  users: PropTypes.array.isRequired
};

export default UsersTable;

