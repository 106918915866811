import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField, Typography, Paper
} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles(() => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  },
  items:{
    textAlign: 'center',
    textSize: 110
  },
  mouseOver:{
    cursor: 'pointer'
  },
}));

const ModelBox = props => {
  const { className, modelName, imageSrc, firmwareCount, lastUpdated, ...rest } = props;
  const classes = useStyles();

  return (
    <Card>
      <CardHeader
        avatar={
          <Avatar alt={modelName} src={imageSrc}>
          </Avatar>
        }
        title={modelName}
      />
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          펌웨어 등록 수 : {firmwareCount}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          마지막 펌웨어 업데이트 일 : {lastUpdated}
        </Typography>
      </CardContent>

    </Card>
  );
};

ModelBox.propTypes = {
  className: PropTypes.string
};

export default ModelBox;
