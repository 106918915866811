import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import palette from 'theme/palette';

import { data, options } from './chart';
import useAsync from '../../../../../asyncNet';
import axios from 'axios';
import { getDeviceRegionCount } from '../../../APIs/tokapis';
import { STRING_SHOW_ALL_REGION, STRING_REGION_COUNT } from '../../../StringTable/StringTable';

const useStyles = makeStyles(() => ({
  root: {},
  chartContainer: {
    height: 400,
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const DeviceArea = props => {

  const { className, dialogCallback, ...rest } = props;
  const [state, tryRefetch] = useAsync(() => getDeviceRegionCount(), []);
  const { loading, data: counts, error } = state;
  const classes = useStyles();
  const [areaData, setAreaData] = useState({
    labels: [],
    datasets: []
  });

  useEffect(() => {
    try {
      if (counts !== null && counts !== undefined && counts['countryArray'] !== undefined) {
        setAreaData(areaData => ({
          ...areaData,
          labels: counts['countryArray'].slice(0, 7),
          datasets: [
            {
              label: 'NR-30D',
              backgroundColor: palette.error.main,
              data: counts['nr30dArray'],
              barThickness: 5,
              maxBarThickness: 10,
              barPercentage: 0.5,
              categoryPercentage: 0.5,
            },
            {
              label: 'NR-35D',
              backgroundColor: palette.info.main,
              data: counts['nr35dArray'],
              barThickness: 5,
              maxBarThickness: 10,
              barPercentage: 0.5,
              categoryPercentage: 0.5,
            },
            {
              label: 'NR-40D',
              backgroundColor: palette.success.main,
              data: counts['nr40dArray'],
              barThickness: 5,
              maxBarThickness: 10,
              barPercentage: 0.5,
              categoryPercentage: 0.5,
            },
            {
              label: 'NRM-35D',
              backgroundColor: palette.success.main,
              data: counts['nrm35dArray'],
              barThickness: 5,
              maxBarThickness: 10,
              barPercentage: 0.5,
              categoryPercentage: 0.5,
            },
            {
              label: 'NR-6XD',
              backgroundColor: palette.primary.main,
              data: counts['nr6xdArray'],
              barThickness: 5,
              maxBarThickness: 10,
              barPercentage: 0.5,
              categoryPercentage: 0.5,
            },
            {
              label: 'NRM-6XD',
              backgroundColor: palette.secondary.main,
              data: counts['nrm6xdArray'],
              barThickness: 5,
              maxBarThickness: 10,
              barPercentage: 0.5,
              categoryPercentage: 0.5,
            }
          ]
        }))
      }
    } catch (e) {
      console.log('areaCount update failed :' + JSON.stringify(e));
    }
  }, [counts])

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        title={STRING_REGION_COUNT}
      />

      <Divider />
      <CardContent>
        <div className={classes.chartContainer}>
          <Bar data={areaData} options={options} />
        </div>
      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>
        <Button
          color="primary"
          size="small"
          variant="text"
          onClick={() => {
            dialogCallback(counts)
          }}
        >
          {STRING_SHOW_ALL_REGION} <ArrowRightIcon />
        </Button>
      </CardActions>
    </Card>
  );
};

DeviceArea.propTypes = {
  className: PropTypes.string
};

export default DeviceArea;
