import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Backdrop, CircularProgress } from '@material-ui/core';
import { RecordToolbar, RecordTable } from './components';
import useAsync from '../../../asyncNet';
import publicIp from 'public-ip';
import { getRecords } from '../APIs/tokapis';
import { userInfoContext } from '../../../App';
import { NO_PERMISSION, NO_PERMISSION_WARNING, READ_PERMISSION, NO_WHITELIST_INSIDE_WARNING } from '../../Common/Config/naviwatchConfig';
import { naviwatchCheckWhiteListValidation } from '../../Common/APIs/naviwatchApis';
import { values } from 'underscore';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));


const Record = ({ history }) => {
  const classes = useStyles();
  const [query, setQuery] = useState({
    handlerType: '',
    featureType: '',
    from: '',
    to: '',
    isDownload: false,
    offset: 0,
    limit: 10,
  });
  const [userState, trySearchUser] = useAsync(getRecordSearch, [], true);
  const { loading: userSearchLoading, data: record } = userState;
  const [ipCheckState, tryCheckWhitelist] = useAsync(checkIpValidation, [], true);
  const { loading: ipCheckLoading, data: ipValid } = ipCheckState;
  const [ipAddress, setIpAddress] = useState(undefined);
  const authInfo = useContext(userInfoContext);
  const [noPermissionMsg, setNoPermissionMsg] = useState("");
  const [permission, setPermission] = useState(NO_PERMISSION);

  useEffect(() => {
    var pageInfo = sessionStorage.getItem('pages');
    if (pageInfo !== "undefined") {
      var pageInfoObj = JSON.parse(pageInfo);
      var pageArray = pageInfoObj.filter(page => page.name.toLowerCase().includes("search log"));
      if (pageArray !== undefined && pageArray.length > 0) {
        var page = pageArray[0];
        if (page.permission === undefined) {
          setNoPermissionMsg(NO_PERMISSION_WARNING);
        }
        setPermission(page.permission);
      }
    }
  }, [authInfo.pages]);

  async function getRecordSearch() {
    return getRecords(query.handlerType, query.featureType, query.from, query.to, query.isDownload, query.offset, query.limit);
  }

  async function checkIpValidation() {
    return naviwatchCheckWhiteListValidation(ipAddress);
  }

  const searchUsers = (searchdata, down) => {
    if (down) {
      setQuery(
        {
          handlerType: searchdata.handlerType,
          featureType: searchdata.featureType,
          from: searchdata.from,
          to: searchdata.to,
          isDownload: down,
        });
    }
    else if (down === false) {
      setQuery(
        {
          handlerType: searchdata.handlerType,
          featureType: searchdata.featureType,
          from: searchdata.from,
          to: searchdata.to,
          isDownload: down,
          offset: searchdata.offset,
          limit: searchdata.limit
        });
    }

  };

  const searchRecordsInfo = (offsetPage, rowPerPage) => {
    setQuery(
      {
        handlerType: query.handlerType,
        featureType: query.featureType,
        from: query.from,
        to: query.to,
        isDownload: query.isDownload,
        offset: offsetPage,
        limit: rowPerPage
      });
  };

  useEffect(() => {
    if (query.to !== "" && query.from !== "") {
      trySearchUser();
    }
  }, [query]);

  useEffect(() => {
    if (ipCheckState.data === false) {
      setNoPermissionMsg(NO_WHITELIST_INSIDE_WARNING + `(${ipAddress})`);
    }
  }, [ipCheckState]);

  useEffect(() => {
    if (ipAddress !== undefined) {
      tryCheckWhitelist();
    }
  }, [ipAddress]);


  useEffect(() => {
    if (sessionStorage.getItem('naviwatch_permission') !== 'true')
      history.push('/');
    (async () => {
      setIpAddress(await publicIp.v4());
    })();
  }, []);

  return (
    <div>
      {permission >= READ_PERMISSION && ipValid === true ?
        <div className={classes.root}>
          <Backdrop className={classes.backdrop} open={userSearchLoading || ipCheckLoading}>
            <CircularProgress color={'primary'} />
          </Backdrop>
          <RecordToolbar callbackFunc={searchUsers} records={userSearchLoading === false && record !== null && record !== undefined && record} />
          <div className={classes.content}>
            {
              userSearchLoading === false && record !== null && record !== undefined &&
              <RecordTable callbackFunc={searchUsers} records={record} sendPageInfo={searchRecordsInfo} initRowsPerPage={query.limit} initPage={query.offset / query.limit} />
            }
          </div>
        </div> :
        <Typography className={classes.root}>{noPermissionMsg}</Typography>
      }
    </div>
  );
};

export default Record;