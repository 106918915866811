import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { LinearProgress } from '@material-ui/core';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
  } from '@material-ui/core';
import CellWifiTwoToneIcon from '@material-ui/icons/CellWifiTwoTone';
import PortableWifiOffTwoToneIcon from '@material-ui/icons/PortableWifiOffTwoTone';

const useStyles = makeStyles(theme => ({
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  deviceView: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  deviceDescriptView: {
    display: 'flex',
    flexDirection: 'column'
  },
  deviceDescriptDivider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

const STRING_SERVICE_NAME = '서비스 타입';
const STRING_MODEL_NAME = '모델';
const STRING_DEVICE_ID = '디바이스 ID';
const STRING_CONNECTION = '연결 여부';

const DeviceDialog = props => {
  const { deviceOpen, handleDeviceClose, deviceInfo } = props;
  const classes = useStyles();

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={deviceOpen}
        onClose={handleDeviceClose}
        aria-labelledby="max-width-dialog-title"
      >
        { deviceInfo === null ? <LinearProgress /> : null }
        <DialogContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align={'center'}><span><b>{STRING_SERVICE_NAME}</b></span></TableCell>
                <TableCell align={'center'}><span><b>{STRING_MODEL_NAME}</b></span></TableCell>
                <TableCell align={'center'}><span><b>{STRING_DEVICE_ID}</b></span></TableCell>
                <TableCell align={'center'}><span><b>{STRING_CONNECTION}</b></span></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {deviceInfo !== null && deviceInfo !== undefined && deviceInfo.code === 200 &&  deviceInfo.data.map(device => (
                <TableRow>
                  <TableCell align={'center'}>
                    {device.serviceName}
                  </TableCell>
                  <TableCell align={'center'}>
                    {device.modelName}
                  </TableCell>
                  <TableCell align={'center'}>
                    {device.deviceId}
                  </TableCell>
                  <TableCell align={'center'}>
                    {device.connected === 1 ? <CellWifiTwoToneIcon color={'secondary'}/> : <PortableWifiOffTwoToneIcon color={'error'}/>}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeviceClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

DeviceDialog.propTypes = {
  className: PropTypes.string
};

export default DeviceDialog;
