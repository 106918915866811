import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core/styles';
import {
  CardContent,
  Divider,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide
} from '@material-ui/core';
import palette from 'theme/palette';
import { options } from './chart';

const STRING_ALLREGION = '지역별 룸콘 분포';
const useStyles = makeStyles(() => ({
  chartContainer: {
    height: 400,
    position: 'relative'
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DeviceAreaDialog = props => {

  const { openStatus, closeFunc, data } = props;
  const classes = useStyles();
  const [areaData, setAreaData] = useState({
    labels: [],
    datasets: []
  });


  console.log('data:',data['countryArray'])

  useEffect(() => {
    try {
      if (data !== null && data !== undefined && data['countryArray'] !== undefined) {
        setAreaData(areaData => ({
          ...areaData,
          labels: data['countryArray'],
          datasets: [
            {
              label: '1 세대',
              backgroundColor: palette.primary.main,
              data: data['modelNtr10pwArray']
            },
            {
              label: '2 세대',
              backgroundColor: palette.info.main,
              data: data['modelNtr10pw2ndArray']
            },
            {
              label: '3 세대',
              backgroundColor: palette.error.main,
              data: data['modelNrt20dArray'] 
            }
          ]
        }));
      }
    } catch (e) {
      console.log('areaCount update failed :' + JSON.stringify(e));
    }
  }, [data]);

  return (
    <Dialog
      open={openStatus}
      TransitionComponent={Transition}
      fullWidth={true}
      maxWidth={'xl'}
      keepMounted
      onClose={closeFunc}
    >
      <DialogTitle>{STRING_ALLREGION}</DialogTitle>
      <DialogContent>
        <CardContent>
          <div className={classes.chartContainer}>
            <Bar data={areaData} options={options}/>
          </div>
        </CardContent>
        <Divider/>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeFunc} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DeviceAreaDialog.propTypes = {
  className: PropTypes.string
};

export default DeviceAreaDialog;
