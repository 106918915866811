import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { getFirmwareModelInfo } from '../../../APIs/tokapis';
import useAsync from '../../../../../asyncNet';
import {
  Card, CardHeader,
  CardContent, Backdrop, CircularProgress,
  Divider, Grid, Button
} from '@material-ui/core';
import ModelBox from './ModelBox';
import { STRING_SELECT_MODEL } from '../../../StringTable/StringTable';

const useStyles = makeStyles((theme) => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  },
  items: {
    textAlign: 'center',
    textSize: 110
  },
  mouseOver: {
    width: 350,
    height: 300,
    cursor: 'pointer',
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  backButton: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));



const FirmwareToolbar = (props) => {
  const { className, productName, firmwareType, ...rest } = props;
  const [state, tryRefetch] = useAsync(() => getFirmwareModelInfo(productName, firmwareType), []);
  const { loading, data: model } = state;
  const classes = useStyles();
  const STRING_TITLE = productName;

  return (
    <div>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color={'primary'} />
      </Backdrop>
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardHeader
          title={STRING_TITLE}
          subheader={STRING_SELECT_MODEL}
          action={
            <Link to={`/hydrofurnace/firmware/${firmwareType}`}>
              <Button
                className={classes.backButton}
                variant="outlined">
                BACK
              </Button>
            </Link>
          }
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            {model !== null && model.models !== undefined && model.models.map(info => (
              <div key={info['name']}>
                <Grid
                  className={classes.mouseOver}
                  item
                >
                  <ModelBox modelName={info['name']} imageSrc={`/images/hydrofurnace/${productName}.png`} productName={productName}
                    count={info['count']} lastRegisterAt={info['lastRegisterAt']} lastModifyAt={info['lastRegisterAt']} swCodeMasking={'0x' + info['swCodeMasking']} firmwareType={firmwareType} />
                </Grid>
              </div>
            ))}
          </Grid>
        </CardContent>
        <Divider />
      </Card>
    </div>
  );
};

FirmwareToolbar.propTypes = {
  className: PropTypes.string
};

export default FirmwareToolbar;
