import React from 'react';
import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import PushToolbar from '../PushToolbar';
import { STRING_CONFIRM } from '../../../StringTable/StringTable';


const PushDialog = props => {
  const { className, open, handleClose, dialogString, ...rest } = props;

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dialogString.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogString.content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            {STRING_CONFIRM}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

PushDialog.propTypes = {
  className: PropTypes.string
};

export default PushDialog;
