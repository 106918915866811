export default [
  {
    PRODUCT_ID: 0,
    PRODUCT_NAME: "controller",
    PRODUCT_IMAGE: "/images/smartplus/controller.png",
    PRODUCT_ENABLE: true
  },
  {
    PRODUCT_ID: 1,
    PRODUCT_NAME: "frontpanel",
    PRODUCT_IMAGE: "/images/smartplus/frontpanel.png",
    PRODUCT_ENABLE: true
  },
  {
    PRODUCT_ID: 2,
    PRODUCT_NAME: "servicetoolkit",
    PRODUCT_IMAGE: "/images/smartplus/servicetoolkit.png",
    PRODUCT_ENABLE: true
  },
  {
    PRODUCT_ID: 3,
    PRODUCT_NAME: "valvecontroller",
    PRODUCT_IMAGE: "/images/smartplus/valvecontroller.png",
    PRODUCT_ENABLE: true
  },
  {
    PRODUCT_ID: 4,
    PRODUCT_NAME: "communicationmodule",
    PRODUCT_IMAGE: "/images/smartplus/communicationmodule.png",
    PRODUCT_ENABLE: true
  },
  {
    PRODUCT_ID: 5,
    PRODUCT_NAME: "wifimodule",
    PRODUCT_IMAGE: "/images/smartplus/wifimodule.png",
    PRODUCT_ENABLE: true
  }
];
