import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  },
  title: {
    fontWeight: 700
  }
}));
const STRING_SEARCH = '검색';
const STRING_TITLE = '휴면 전환 안내';
const STRING_ID_LABEL = '사용자 ID';
const STRING_SEARCH_CLEAN = '초기화';
const STRING_SEARCH_COND = '검색 조건';
const STRING_SEARCH_ALL = '전체';
const STRING_SEARCH_SEPERATE = '개별';

const states = [
  {
    value: ''
  },
  {
    value: STRING_SEARCH_ALL
  },
  {
    value: STRING_SEARCH_SEPERATE
  }
];

const DormancyHistoryToolbar = props => {
  const { className, callbackFunc, ...rest } = props;

  const classes = useStyles();

  const [values, setValues] = useState({
    condition: '',
    userid: ''
  });

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };


  const cleanSearchInput = () =>{
    setValues({
      condition: '',
      userid: ''
    });
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        title={STRING_TITLE}
      />
      <Divider/>
      <CardContent>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            md={4}
            xs={12}
          >
            <TextField
              fullWidth
              label={STRING_SEARCH_COND}
              margin="dense"
              name="condition"
              onChange={handleChange}
              select
              // eslint-disable-next-line react/jsx-sort-props
              SelectProps={{ native: true }}
              value={values.condition}
              variant="outlined"
            >
              {states.map(option => (
                <option
                  key={option.value}
                  value={option.value}
                >
                  {option.value}
                </option>
              ))}
            </TextField>
          </Grid>
          <Grid
            item
            md={4}
            xs={12}
          >
            <TextField
              fullWidth
              label={STRING_ID_LABEL}
              margin="dense"
              name="userid"
              disabled={values.condition!==STRING_SEARCH_SEPERATE}
              onChange={handleChange}
              value={values.userid}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </CardContent>
      <Divider/>
      <CardActions className={classes.actions}>
        <Button
          color="primary"
          variant="contained"
          onClick={cleanSearchInput}
          disabled={values.userid==="" && values.condition===""}
        >
          {STRING_SEARCH_CLEAN}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={()=>callbackFunc(values)}
          disabled={values.userid==="" && values.condition===""}
        >
          {STRING_SEARCH}
        </Button>
      </CardActions>
    </Card>
  );
};

DormancyHistoryToolbar.propTypes = {
  className: PropTypes.string
};

export default DormancyHistoryToolbar;
