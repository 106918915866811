import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import JSONPretty from 'react-json-pretty';

import {
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Grid,
  DialogTitle,
  Button,
  FormControl
} from '@material-ui/core';

const theme = {
  main: 'line-height:1.3;color:#66d9ef;background:transparent;overflow:auto;',
  error: 'line-height:1.3;color:#66d9ef;background:transparent;overflow:auto;',
  key: 'color:green;',
  string: 'color:#fd971f;',
  value: 'color:#777777;',
  boolean: 'color:#ac81fe;'
};

const DetailDialog = props => {
  const { open, handleClose, data } = props;

  useEffect(() => {
    console.log(data);
  }, [data]);

  return (
    <div>
      <Dialog
        fullWidth={false}
        maxWidth={'md'}
        open={open}
        onClose={handleClose}
        aria-labelledby="dialog-report">
        <DialogContent>
          <DialogTitle id="dialog-report"> Payload </DialogTitle>
          <div
            style={{
              borderStyle: 'solid',
              borderWidth: '2px',
              borderColor: 'gray',
              borderRadius: '10px',
              overflow: 'auto',
              padding: '20px',
              maxHeight: '60vh'
            }}>
            <JSONPretty
              style={{ paddingRight: '80px' }}
              className="json-display"
              id="json-pretty"
              data={data}
              theme={theme}></JSONPretty>
          </div>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained" onClick={handleClose}>
            {' '}
            Close{' '}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

DetailDialog.propTypes = {
  className: PropTypes.string
};

export default DetailDialog;
