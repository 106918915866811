import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Grid, Typography, Avatar } from '@material-ui/core';
import RemoveIcon from '@material-ui/icons/Remove';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import DevicedsIcon from '@material-ui/icons/Devices';
import axios from 'axios';
import useAsync from '../../../../../asyncNet';
import HotTubIcon from '@material-ui/icons/HotTub';
import Skeleton from '@material-ui/lab/Skeleton';
import PhonelinkRingIcon from '@material-ui/icons/PhonelinkRing';
import PersonIcon from '@material-ui/icons/Person';
import MemoryIcon from '@material-ui/icons/Memory';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import MobileFriendlyIcon from '@material-ui/icons/MobileFriendly';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getDeviceCount, getUserCount } from '../../../APIs/tokapis';
import {
  STRING_REALTIME_MOBILE_CONNECT,
  STRING_DEVICE,
  STRING_ERROR_STATUS,
  STRING_NOW,
  STRING_24H,
  STRING_WEEK,
  STRING_USER,
  STRING_ALL,
  STRING_ONLINE,
  STRING_OFFLINE,
  STRING_STATUS_CON1,
  STRING_STATUS_CON2,
  STRING_STATUS_DB,
  STRING_STATUS_AGS,
  STRING_MOBILE_IOS,
  STRING_MOBILE_ANDROID
} from '../../../StringTable/StringTable';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    width: '100%',
    height: '100%'
  },
  title: {
    fontWeight: 700
  },
  mobileAvatar: {
    backgroundColor: theme.palette.success.main,
    height: 56,
    width: 56
  },
  deviceAvatar: {
    backgroundColor: theme.palette.warning.main,
    height: 56,
    width: 56
  },
  userAvatar: {
    backgroundColor: theme.palette.info.main,
    height: 56,
    width: 56
  },
  errorAvatar: {
    backgroundColor: theme.palette.error.main,
    height: 56,
    width: 56
  },
  stableAvatar: {
    backgroundColor: theme.palette.success.main,
    height: 56,
    width: 56
  },
  unstableAvatar: {
    backgroundColor: theme.palette.error.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  fabProgress: {
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  mobileDifferenceIcon: {
    color: theme.palette.success.dark
  },
  mobileDifferenceValue: {
    color: theme.palette.success.dark,
    marginRight: theme.spacing(1)
  },
  mobileRoot: {
    color: theme.palette.success.light
  },
  deviceDifferenceIcon: {
    color: theme.palette.warning.dark
  },
  deviceDifferenceValue: {
    color: theme.palette.warning.dark,
    marginRight: theme.spacing(1)
  },
  deviceRoot: {
    color: theme.palette.warning.light
  },
  userDifferenceIcon: {
    color: theme.palette.info.dark
  },
  userDifferenceValue: {
    color: theme.palette.info.dark,
    marginRight: theme.spacing(1)
  },
  userRoot: {
    color: theme.palette.info.light
  },
  errorDifferenceIcon: {
    color: theme.palette.error.dark
  },
  errorDifferenceValue: {
    color: theme.palette.error.dark,
    marginRight: theme.spacing(1)
  },
  errorRoot: {
    color: theme.palette.error.light
  },
  stableDifferenceIcon: {
    color: theme.palette.success.dark
  },
  stableDifferenceValue: {
    color: theme.palette.success.dark,
    marginRight: theme.spacing(1)
  },
  stableRoot: {
    color: theme.palette.success.light
  },
  unstableDifferenceIcon: {
    color: theme.palette.error.dark
  },
  unstableDifferenceValue: {
    color: theme.palette.error.dark,
    marginRight: theme.spacing(1)
  },
  unstableRoot: {
    color: theme.palette.error.light
  },
}));


const RealtimeIcon = props => {
  //console.log('type : '+JSON.stringify(type));
  if (props.type === 'appUser')
    return (<MobileFriendlyIcon className={props.classname} />);
  else if (props.type === 'device')
    return (<MemoryIcon className={props.classname} />);
  else if (props.type === 'user')
    return (<PersonIcon className={props.classname} />);
  else if (props.type === 'error')
    return (<NotificationImportantIcon className={props.classname} />);
  else if (props.type === 'status')
    return (<CheckCircleOutlineIcon className={props.classname} />);
  else
    return null;
};

const RealtimeCounterTODO = props => {
  const { className, type, ...rest } = props;

  const getCountFunction = () => {
    // console.log('call getCountFunction');
    if (type === 'appUser') {
      //TODO : Add api
      return getDeviceCount();
    } else if (type === 'device') {
      return getDeviceCount();
    } else if (type === 'user') {
      return getUserCount();
    } else if (type === 'error') {
      //TODO : Add api
      return getDeviceCount();
    }
  };
  const [previousCount, setPreviousCount] = useState(0);
  const [state, tryRefetch] = useAsync(getCountFunction, []);
  const { loading, data: count, error } = state;
  const [firstFieldCountValue, setFirstFieldCountValue] = useState(0);
  const [secondFieldCountValue, setSecondFieldCountValue] = useState(0);
  const [thirdFieldCountValue, setThirdFieldCountValue] = useState(0);
  const classes = useStyles();

  var counterTitle;
  var firstTitle;
  var secondTitle;
  var thirdTitle;
  var classesIcon;
  var classesAvatar;
  var classesRoot;

  if (type === 'appUser') {
    counterTitle = STRING_REALTIME_MOBILE_CONNECT;
    firstTitle = STRING_ALL;
    secondTitle = STRING_MOBILE_IOS;
    thirdTitle = STRING_MOBILE_ANDROID;
    classesRoot = classes.mobileRoot;
    classesIcon = classes.mobileDifferenceIcon;
    classesAvatar = classes.mobileAvatar;
  } else if (type === 'device') {
    counterTitle = STRING_DEVICE;
    firstTitle = STRING_ALL;
    secondTitle = STRING_ONLINE;
    thirdTitle = STRING_OFFLINE;
    classesRoot = classes.deviceRoot;
    classesIcon = classes.deviceDifferenceIcon;
    classesAvatar = classes.deviceAvatar;
  } else if (type === 'user') {
    counterTitle = STRING_USER;
    firstTitle = STRING_NOW;
    secondTitle = STRING_24H;
    thirdTitle = STRING_WEEK;
    classesRoot = classes.userRoot;
    classesIcon = classes.userDifferenceIcon;
    classesAvatar = classes.userAvatar;
  } else if (type === 'error') {
    counterTitle = STRING_ERROR_STATUS;
    firstTitle = STRING_ALL;
    secondTitle = STRING_24H;
    thirdTitle = STRING_WEEK;
    classesRoot = classes.errorRoot;
    classesIcon = classes.errorDifferenceIcon;
    classesAvatar = classes.errorAvatar;
  } else if (type === 'status') {
    counterTitle = STRING_STATUS_CON1;
    firstTitle = STRING_STATUS_CON2;
    secondTitle = STRING_STATUS_DB;
    thirdTitle = STRING_STATUS_AGS;
  }


  useEffect(() => {
    try {
      if (count !== null) {
        if (type === 'appUser' && count['totalCount'] !== undefined) {
          setPreviousCount(secondFieldCountValue);
          setFirstFieldCountValue(count['totalCount'] = "268");
          setSecondFieldCountValue(count['iOSCount'] = "122");
          setThirdFieldCountValue(count['androidCount'] = "146");
        } else if (type === 'device' && count['totalCount'] !== undefined) {
          setPreviousCount(secondFieldCountValue);
          setFirstFieldCountValue(count['totalCount']);
          setSecondFieldCountValue(count['onlineCount']);
          setThirdFieldCountValue(count['offlineCount']);
        } else if (type === 'user' && count['totalCount'] !== undefined) {
          setPreviousCount(secondFieldCountValue);
          setFirstFieldCountValue(count['totalCount']);
          setSecondFieldCountValue(count['connectDayCount'] = "1453");
          setThirdFieldCountValue(count['connectWeekCount'] = "6534");
        } else if (type === 'error' && count['totalCount'] !== undefined) {
          setPreviousCount(secondFieldCountValue);
          setFirstFieldCountValue(count['totalCount'] = "5");
          setSecondFieldCountValue(count['connectDayCount'] = "7");
          setThirdFieldCountValue(count['connectWeekCount'] = "13");
        }
      }
    } catch (e) {
      console.log('get count failed: ' + JSON.stringify(e));
    }
  }, [count]);

  useEffect(() => {
    const timer = setInterval(function () {
      tryRefetch();
    }, 60 * 1000);
    // console.log('setInterval intervalidx:' + timer);
    return () => {
      // console.log('clearinterval: ' + timer);
      clearInterval(timer);
    };
  }, []);


  return (
    <Card style={{ flex: 1, backgroundColor: 'lightgray' }}>
      <CardContent>
        <Grid
          container
          justifyContent="space-around"
        >
          <Grid item>
            <div className={classes.content}>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
                variant="body2"
              >
                {counterTitle}
              </Typography>
              <Avatar className={classesAvatar}>
                <RealtimeIcon type={type} classname={classes.icon} />
              </Avatar>
            </div>
          </Grid>
          <Grid item>
            <div className={classes.content}>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
                variant="body2"
              >
                {firstTitle}
              </Typography>
              <Typography
                className={classes.caption}
                variant="h3"
              >
                {firstFieldCountValue}
              </Typography>
            </div>
          </Grid>
          <Grid item>
            <div className={classes.content}>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
                variant="body2"
              >
                {secondTitle}
              </Typography>
              <Typography
                className={classes.caption}
                variant="h3"
              >
                {secondFieldCountValue}
              </Typography>
            </div>
          </Grid>
          <Grid item>
            <div className={classes.content}>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
                variant="body2"
              >
                {thirdTitle}
              </Typography>
              <Typography
                className={classes.caption}
                variant="h3"
              >
                {thirdFieldCountValue}
              </Typography>
            </div>
          </Grid>
        </Grid>

      </CardContent>
    </Card>
  );
};

RealtimeCounterTODO.propTypes = {
  className: PropTypes.string
};

export default RealtimeCounterTODO;
