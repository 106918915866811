import React, { useEffect, useState } from 'react';
import {
  Dialog, DialogActions, DialogContent, Button,
  DialogTitle, Divider, Typography, makeStyles,
  Checkbox, Backdrop, CircularProgress, TextField,
  OutlinedInput, InputAdornment
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { addFirmware } from '../../../APIs/tokapis';
import AlertPopup from './AlertPopup';
import {
  STRING_FW_ADD,
  STRING_FOTA_FW,
  STRING_SERVICE_TOOLKIT_FW,
  STRING_BS_FW,
  STRING_FW_UPLOAD,
  STRING_ACTIVE,
  STRING_FORCE,
  STRING_GROUP,
  STRING_FW_DESCRIPTION,
  STRING_FILE_NAME,
  STRING_FILE_SIZE,
  STRING_FILE_SELECT,
  STRING_ALERT_FW_UPLOAD,
  STRING_COMPULSORY_INPUT_DESCRIPTION,
  STRING_CANCEL,
  STRING_UPLOAD,
  STRING_FILE_CRC,
  STRING_DATA_CRC,
  STRING_PRODUCT,
  STRING_MODEL_NUMBER,
  STRING_TYPE,
  STRING_FW_VERSION,
  STRING_SW_CODE,
  STRING_UPLOAD_TIME,
  STRING_LINE_LIMIT,
  STRING_MINUTE,
  STRING_EXIST_ACTIVE_FIRMWARE,
  STIRNG_SUCCESS_ADD_FIRMWARE,
  STRING_FAIL_ADD_FIRMWARE,
  STRING_WRONG_FW_INFO,
  STRING_ALERT_CHECKBOX,
  STRING_BS_CHECK
} from '../../../StringTable/StringTable';

const useStyles = makeStyles(theme => ({
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  linear: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(2)
  },
  file: {
    marginLeft: theme.spacing(2)
  },
  icons: {
    marginLeft: theme.spacing(2),
    padding: theme.spacing(0)
  },
  fwDescription: {
    display: 'flex',
    marginLeft: theme.spacing(2),
    width: '65%'
  },
  uploadTime: {
    marginLeft: theme.spacing(2),
    width: '20%'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: theme.spacing(2)
  },
}));

const AddFirmwareDialog = props => {
  const { open, handleClose, firmwareType, refetch, swCodeMasking, product, modelName, activeCheck } = props;
  const [loading, setLoading] = useState(false);
  const [forceFlag, setForceFlag] = useState(false);
  const [activeFlag, setActiveFlag] = useState(false);
  const [groupFlag, setGroupFlag] = useState(false);
  const [bsCheckFlag, setBSCheckFlag] = useState(false);
  const [sendVersion, setSendVersion] = useState(0);
  const [sendSWCode, setSendSWCode] = useState(0);
  const [sendVersionST, setSendVersionST] = useState(0);
  const [sendSWCodeST, setSendSWCodeST] = useState(0);
  const [description, setDescription] = useState("");
  const [updateTime, setUpdateTime] = useState("3");
  const [successOpen, setSuccessOpen] = useState(false);
  const [failOpen, setFailOpen] = useState(false);
  const [fileAlertOpen, setFileAlertOpen] = useState(false);
  const [activeOpen, setActiveOpen] = useState(false);
  const [forceOpen, setForceOpen] = useState(false);
  const [groupOpen, setGroupOpen] = useState(false);
  const [bsCheckOpen, setBSCheckOpen] = useState(false);
  const [firmwares, setFirmwares] = useState([]);
  const [comment, setComment] = useState(null);
  const crc16xmodem = require('crc16-xmodem');
  const classes = useStyles();
  const STRING_ALERT_ACTIVE_CHECK = '연결된 ' + modelName + '의 모든 ' + product + '을 업데이트를 활성화하게 됩니다 !';
  const STRING_ALERT_FORCE_CHECK = '연결된 ' + modelName + '의 모든 ' + product + '을 강제로 업데이트하게 됩니다 !';
  const STRING_ALERT_GROUP_CHECK = modelName + '에 선택된 사용자 그룹의 ' + product + '만 업데이트 하게 됩니다 !';
  const STRING_ALERT_BS_CHECK = '해당 펌웨어의 OS 펌웨어를 BS 펌웨어 리스트에 추가합니다 !';

  const [values, setValues] = useState({
    fileBuffer: null,
    fileName: null,
    fileSize: null,
    product: null,
    modelNumber: null,
    type: null,
    version: null,
    swCode: null,
    dataCrc: null,

  });

  const [valuesST, setValuesST] = useState({
    fileBufferST: null,
    fileNameST: null,
    fileSizeST: null,
    productST: null,
    modelNumberST: null,
    typeST: null,
    versionST: null,
    swCodeST: null,
    dataCrcST: null,

  });

  const handleFile = (fileBuffer, fileName, fileSize, product,
    modelNumber, type, version, swCode, dataCrc) => {
    setValues({
      ...values,
      fileBuffer: fileBuffer,
      fileName: fileName,
      fileSize: fileSize,
      product: product,
      modelNumber: modelNumber,
      type: type,
      version: version,
      swCode: swCode,
      dataCrc: dataCrc
    });
  };

  const handleFileST = (fileBufferST, fileNameST, fileSizeST, productST,
    modelNumberST, typeST, versionST, swCodeST, dataCrcST) => {
    setValuesST({
      ...values,
      fileBufferST: fileBufferST,
      fileNameST: fileNameST,
      fileSizeST: fileSizeST,
      productST: productST,
      modelNumberST: modelNumberST,
      typeST: typeST,
      versionST: versionST,
      swCodeST: swCodeST,
      dataCrcST: dataCrcST
    });
  };

  const cleanFile = () => {
    setValues({
      fileBuffer: null,
      fileName: null,
      fileSize: null,
      product: null,
      modelNumber: null,
      type: null,
      version: null,
      swCode: null,
      dataCrc: null,
    });
    setValuesST({
      fileBufferST: null,
      fileNameST: null,
      fileSizeST: null,
      productST: null,
      modelNumberST: null,
      typeST: null,
      versionST: null,
      swCodeST: null,
      dataCrcST: null,
    });
  };

  const handleForceFlag = () => {
    if (forceFlag) {
      setForceFlag(false);
    } else {
      openAlertForce();
      setForceFlag(true);
    }
  };


  const handleActiveFlag = () => {
    if (activeFlag) {
      setActiveFlag(false);
    } else {
      openAlertActive();
      setActiveFlag(true);
    }
  };

  const handleGroupFlag = () => {
    if (groupFlag) {
      setGroupFlag(false);
    } else {
      openAlertGroup();
      setGroupFlag(true);
    }
  };

  const handleBSCheckFlag = () => {
    if (bsCheckFlag) {
      setBSCheckFlag(false);
    } else {
      openAlertBSCheck();
      setBSCheckFlag(true);
    }
  };

  const openAlertPopup = () => setFileAlertOpen(true);
  const closeAlertPopup = () => setFileAlertOpen(false);
  const openAlertSuccess = () => setSuccessOpen(true);
  const closeAlertSuccess = () => setSuccessOpen(false);
  const openAlertFail = () => setFailOpen(true);
  const closeAlertFail = () => setFailOpen(false);
  const openAlertActive = () => setActiveOpen(true);
  const closeAlertActive = () => setActiveOpen(false);
  const openAlertForce = () => setForceOpen(true);
  const closeAlertForce = () => setForceOpen(false);
  const openAlertGroup = () => setGroupOpen(true);
  const closeAlertGroup = () => setGroupOpen(false);
  const openAlertBSCheck = () => setBSCheckOpen(true);
  const closeAlertBSCheck = () => setBSCheckOpen(false);

  function stringToArray(str) {
    var bytes = [];
    for (var i = 0; i < str.length; i++) {
      var char = str.charCodeAt(i);
      bytes.push(char & 0xFF);
    }
    return bytes;
  }

  const handleUploadClick = event => {
    let file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onloadend = function (e) {
      let binaryString = reader.result;
      let headerBuf = new ArrayBuffer(20);
      let bufViewCheck = new Uint8Array(headerBuf);
      for (var i = 0; i < 20; i++) {
        bufViewCheck[i] = binaryString.charCodeAt(i);
      }
      let fwProduct = new DataView(headerBuf).getUint8(7, false).toString(16).padStart(2, '0');
      let fwModel = new DataView(headerBuf).getUint16(5, true).toString(16).padStart(4, '0');
      let fwType = new DataView(headerBuf).getUint8(4, false).toString(16).substr(0, 1);
      let fwDataCrc = new DataView(headerBuf).getUint16(16, true).toString(16);
      let fwVersion = "V" + new DataView(headerBuf).getUint8(8, false) + "-" + new DataView(headerBuf).getUint8(9, false);
      let fwSWCode = "0x" + new DataView(headerBuf).getUint32(4, true).toString(16).padStart(8, '0');
      let offset = null;
      if (firmwareType == 'firmwareOfficial' && fwProduct != '10') {
        offset = '0';
      }
      else if (firmwareType == 'firmwareOfficial' && fwProduct == '10') {
        offset = '1';
      }
      else if (firmwareType == 'firmwareBS' && fwProduct.substr(0, 1) == '8') {
        offset = '8';
      }
      if (fwProduct.substr(0, 1) == offset && ((file.name.includes('kdfb') && offset == '0' || offset == '1') || (file.name.includes('kdsb') && offset == '8')) &&
        fwProduct.substr(1, 2) == ((swCodeMasking & 0x7F000000) >> 24).toString(16).padStart(2, '0').substr(1, 2) &&
        fwModel == ((swCodeMasking & 0x00FFFF00) >> 8).toString(16).padStart(4, '0') &&
        fwType == ((swCodeMasking & 0x000000F0) >> 4).toString(16).substr(0, 1)) {
        handleFile(binaryString, file.name, file.size, fwProduct, fwModel, fwType, fwVersion, fwSWCode, fwDataCrc);
        // setDescription(product + " " + modelName + "모델 / " + fwVersion + " / " + file.name);
        setSendSWCode(new DataView(headerBuf).getUint32(4, true).toString(16));
        setSendVersion(new DataView(headerBuf).getUint32(8, false).toString(16));
      } else {
        openAlertPopup();
      }
    }.bind(this);
  };

  const handleSTUploadClick = event => {
    let file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onloadend = function (e) {
      let binaryString = reader.result;
      let headerBuf = new ArrayBuffer(20);
      let bufViewCheck = new Uint8Array(headerBuf);
      for (var i = 0; i < 20; i++) {
        bufViewCheck[i] = binaryString.charCodeAt(i);
      }
      let fwProduct = new DataView(headerBuf).getUint8(7, false).toString(16).padStart(2, '0');
      let fwModel = new DataView(headerBuf).getUint16(5, true).toString(16).padStart(4, '0');
      let fwType = new DataView(headerBuf).getUint8(4, false).toString(16).substr(0, 1);
      let fwDataCrc = new DataView(headerBuf).getUint16(16, true).toString(16);
      let fwVersion = "V" + new DataView(headerBuf).getUint8(8, false) + "-" + new DataView(headerBuf).getUint8(9, false);
      let fwSWCode = "0x" + new DataView(headerBuf).getUint32(4, true).toString(16).padStart(8, '0');
      let offset = null;
      if (firmwareType == 'firmwareOfficial' && fwProduct != '10') {
        offset = '0';
      }
      else if (firmwareType == 'firmwareOfficial' && fwProduct == '10') {
        offset = '1';
      }
      else if (firmwareType == 'firmwareBS' && fwProduct.substr(0, 1) == '8') {
        offset = '8';
      }
      if (fwProduct.substr(0, 1) == offset && file.name.includes('kdsb') &&
        fwProduct.substr(1, 2) == ((swCodeMasking & 0x7F000000) >> 24).toString(16).padStart(2, '0').substr(1, 2) &&
        fwModel == ((swCodeMasking & 0x00FFFF00) >> 8).toString(16).padStart(4, '0') &&
        fwType == ((swCodeMasking & 0x000000F0) >> 4).toString(16).substr(0, 1)) {
        handleFileST(binaryString, file.name, file.size, fwProduct, fwModel, fwType, fwVersion, fwSWCode, fwDataCrc);
        setSendSWCodeST(new DataView(headerBuf).getUint32(4, true).toString(16));
        setSendVersionST(new DataView(headerBuf).getUint32(8, false).toString(16));
      } else {
        openAlertPopup();
      }
    }.bind(this);
  };

  const handleCloseClick = () => {
    setForceFlag(false);
    setActiveFlag(false);
    setGroupFlag(false);
    setBSCheckFlag(false);
    setSendVersion(0);
    setSendSWCode(0);
    setDescription("");
    setUpdateTime("3");
    setSendVersionST(0);
    setSendSWCodeST(0);
    cleanFile();
    handleClose();
  };

  const refetchList = () => {
    refetch();
  };

  const handleFirmware = firmware => {
    setFirmwares(firmwares => [...firmwares, firmware]);
  };

  useEffect(() => {
    if (values.fileBuffer !== null && sendVersion !== 0 && sendSWCode !== 0) {
      let releaseType = 'OF'
      if (firmwareType == 'firmwareBS') {
        releaseType = 'BS'
      }
      handleFirmware({
        "fileBuffer": stringToArray(values.fileBuffer),
        "releaseType": releaseType,
        "fileName": values.fileName,
        "fileSize": values.fileSize,
        "version": sendVersion,
        "swCode": sendSWCode,
        "description": description,
        "activeFlag": activeFlag,
        "forceFlag": forceFlag,
        "groupFlag": groupFlag,
        "bsCheckFlag": bsCheckFlag,
        "fileCrc": crc16xmodem.crc16(values.fileBuffer, 0x0).toString(16),
        "naviWatchId": sessionStorage.getItem('email'),
        "updateTime": updateTime,
        "dataCrc": values.dataCrc
      });
    }
  }, [values, sendVersion, sendSWCode, description, updateTime])

  useEffect(() => {
    if (valuesST.fileBufferST !== null && sendVersionST !== 0 && sendSWCodeST !== 0) {
      handleFirmware({
        "fileBuffer": stringToArray(valuesST.fileBufferST),
        "releaseType": 'OS',
        "fileName": valuesST.fileNameST,
        "fileSize": valuesST.fileSizeST,
        "version": sendVersionST,
        "swCode": sendSWCodeST,
        "description": description,
        "activeFlag": activeFlag,
        "forceFlag": forceFlag,
        "groupFlag": groupFlag,
        "bsCheckFlag": bsCheckFlag,
        "fileCrc": crc16xmodem.crc16(valuesST.fileBufferST, 0x0).toString(16),
        "naviWatchId": sessionStorage.getItem('email'),
        "updateTime": updateTime,
        "dataCrc": valuesST.dataCrcST
      });
    }
  }, [valuesST, sendVersionST, sendSWCodeST, description, updateTime])

  const handleUpload = async () => {
    setLoading(true);
    handleCloseClick();

    if ((firmwares.length === 2 && sendSWCode === sendSWCodeST && sendVersion === sendVersionST) || firmwares.length === 1) {
      let result = await addFirmware(firmwares, refetchList);
      setLoading(false);
      if (result.code === 200) {
        openAlertSuccess();
      } else {
        setComment(result.data.errorMsg);
        openAlertFail();
      }
    }
    else {
      setLoading(false);
      setComment(STRING_FAIL_ADD_FIRMWARE);
      openAlertFail();
    }
    setFirmwares([]);
  };

  const changeFWDescription = event => {
    event.persist();
    setFirmwares([]);
    setDescription(event.target.value);
  };

  const changeUploadTime = event => {
    event.persist();
    setFirmwares([]);
    setUpdateTime(event.target.value);
  };

  return (
    <div>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color={'primary'} />
      </Backdrop>
      <Dialog
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >

        <DialogTitle id="alert-dialog-title">{STRING_FW_ADD}</DialogTitle>
        <DialogContent>
          <Alert severity={'error'}>
            <span style={{ color: 'red' }}>{STRING_ALERT_FW_UPLOAD}</span>
            <div style={{ color: 'red' }}>{STRING_COMPULSORY_INPUT_DESCRIPTION}</div>
          </Alert>
          {firmwareType === "firmwareOfficial" &&
            <div>
              <Divider className={classes.divider} />
              <div className={classes.linear}>
                <Typography variant="h6">{STRING_ACTIVE}</Typography>
                <Checkbox className={classes.icons}
                  checked={activeFlag}
                  onChange={handleActiveFlag}
                  color="primary"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                  disabled={activeCheck == true}
                />
                <div>　　　</div>
                <Typography variant="h6">{STRING_FORCE}</Typography>
                <Checkbox className={classes.icons}
                  checked={forceFlag}
                  onChange={handleForceFlag}
                  color="primary"
                  inputProps={{ 'aria-label': 'secondary checkbox' }} />
                <div>　　　</div>
                <Typography variant="h6">{STRING_GROUP}</Typography>
                <Checkbox className={classes.icons}
                  checked={groupFlag}
                  onChange={handleGroupFlag}
                  color="primary"
                  inputProps={{ 'aria-label': 'secondary checkbox' }} />
                <div>　　　</div>
                <Typography variant="h6">{STRING_BS_CHECK}</Typography>
                <Checkbox className={classes.icons}
                  checked={bsCheckFlag}
                  onChange={handleBSCheckFlag}
                  color="primary"
                  inputProps={{ 'aria-label': 'secondary checkbox' }} />
              </div>
              {activeCheck == true && <Typography variant="caption" color="error" className={classes.linear}>{STRING_EXIST_ACTIVE_FIRMWARE}</Typography>}
            </div>
          }
          <Divider className={classes.divider} />
          <div className={classes.title}>
            {firmwareType === "firmwareOfficial" && <Typography variant="h5">{STRING_FOTA_FW}</Typography>}
            {firmwareType === "firmwareBS" && <Typography variant="h5">{STRING_BS_FW}</Typography>}
          </div>
          <Divider className={classes.divider} />
          <div className={classes.linear}>
            <Typography variant="h6">{STRING_FW_UPLOAD}{' :'}</Typography>
            <Button className={classes.icons} component="label"
              onChange={handleUploadClick}
              color="secondary"
              startIcon={<CloudUploadIcon />}>
              {STRING_FILE_SELECT}
              <input
                type="file"
                hidden
              />
            </Button>
          </div>
          {
            values.fileBuffer !== null &&
            <div>
              <Divider className={classes.divider} />
              <div className={classes.linear}>
                <Typography variant="h6">{STRING_FW_DESCRIPTION}{' :'}</Typography>
                <TextField
                  id="description"
                  className={classes.fwDescription}
                  multiline
                  maxRows={4}
                  onChange={changeFWDescription}
                  label={STRING_LINE_LIMIT}
                  required
                />
              </div>
              <Divider className={classes.divider} />
              <div className={classes.linear}>
                <Typography variant="h6">{STRING_UPLOAD_TIME}{' :'}</Typography>
                <OutlinedInput
                  className={classes.uploadTime}
                  id="upload-time"
                  size="small"
                  margin="dense"
                  defaultValue={'3'}
                  onChange={changeUploadTime}
                  endAdornment={<InputAdornment position="end">{STRING_MINUTE}</InputAdornment>}
                />
              </div>
              <Divider className={classes.divider} />
              <div className={classes.linear}>
                <Typography variant="h6">{STRING_FILE_NAME}{' :'}</Typography>
                <Typography className={classes.file} variant="h6">{values.fileName ? values.fileName : '-'}</Typography>
              </div>
              <Divider className={classes.divider} />
              <div className={classes.linear}>
                <Typography variant="h6">{STRING_FILE_SIZE}{' :'}</Typography>
                <Typography className={classes.file} variant="h6">{values.fileSize ? values.fileSize + " bytes" : '-'}</Typography>
              </div>
              <Divider className={classes.divider} />
              <div className={classes.linear}>
                <Typography variant="h6">{STRING_PRODUCT}{' :'}</Typography>
                <Typography className={classes.file} variant="h6">{values.product ? '0x' + values.product : '-'}</Typography>
              </div>
              <Divider className={classes.divider} />
              <div className={classes.linear}>
                <Typography variant="h6">{STRING_MODEL_NUMBER}{' :'}</Typography>
                <Typography className={classes.file} variant="h6">{values.modelNumber ? '0x' + values.modelNumber : '-'}</Typography>
              </div>
              <Divider className={classes.divider} />
              <div className={classes.linear}>
                <Typography variant="h6">{STRING_TYPE}{' :'}</Typography>
                <Typography className={classes.file} variant="h6">{values.type ? '0x' + values.type : '-'}</Typography>
              </div>
              <Divider className={classes.divider} />
              <div className={classes.linear}>
                <Typography variant="h6">{STRING_FW_VERSION}{' :'}</Typography>
                <Typography className={classes.file} variant="h6">{values.version ? values.version : '-'}</Typography>
              </div>
              <Divider className={classes.divider} />
              <div className={classes.linear}>
                <Typography variant="h6">{STRING_SW_CODE}{' :'}</Typography>
                <Typography className={classes.file} variant="h6">{values.swCode ? values.swCode : '-'}</Typography>
              </div>
              <Divider className={classes.divider} />
              <div className={classes.linear}>
                <Typography variant="h6">{STRING_FILE_CRC}{' :'}</Typography>
                <Typography className={classes.file} variant="h6">{crc16xmodem.crc16(values.fileBuffer, 0x0).toString(16) ? crc16xmodem.crc16(values.fileBuffer, 0x0).toString(16) : '-'}</Typography>
              </div>
              <Divider className={classes.divider} />
              <div className={classes.linear}>
                <Typography variant="h6">{STRING_DATA_CRC}{' :'}</Typography>
                <Typography className={classes.file} variant="h6">{values.dataCrc ? values.dataCrc : '-'}</Typography>
              </div>
            </div>
          }
          {firmwareType === 'firmwareOfficial' && swCodeMasking.substr(2, 2) !== '10' &&
            <div>
              <Divider className={classes.divider} />
              <div className={classes.title}>
                <Typography variant="h5">{STRING_SERVICE_TOOLKIT_FW}</Typography>
              </div>
              <Divider className={classes.divider} />
              <div className={classes.linear}>
                <Typography variant="h6">{STRING_FW_UPLOAD}{' :'}</Typography>
                <Button className={classes.icons} component="label"
                  onChange={handleSTUploadClick}
                  color="secondary"
                  startIcon={<CloudUploadIcon />}>
                  {STRING_FILE_SELECT}
                  <input
                    type="file"
                    hidden
                  />
                </Button>
              </div>
              {
                valuesST.fileBufferST !== null &&
                <div>
                  <Divider className={classes.divider} />
                  <div className={classes.linear}>
                    <Typography variant="h6">{STRING_FILE_NAME}{' :'}</Typography>
                    <Typography className={classes.file} variant="h6">{valuesST.fileNameST ? valuesST.fileNameST : '-'}</Typography>
                  </div>
                  <Divider className={classes.divider} />
                  <div className={classes.linear}>
                    <Typography variant="h6">{STRING_FILE_SIZE}{' :'}</Typography>
                    <Typography className={classes.file} variant="h6">{valuesST.fileSizeST ? valuesST.fileSizeST + " bytes" : 0}</Typography>
                  </div>
                  <Divider className={classes.divider} />
                  <div className={classes.linear}>
                    <Typography variant="h6">{STRING_PRODUCT}{' :'}</Typography>
                    <Typography className={classes.file} variant="h6">{valuesST.productST ? '0x' + valuesST.productST : '-'}</Typography>
                  </div>
                  <Divider className={classes.divider} />
                  <div className={classes.linear}>
                    <Typography variant="h6">{STRING_MODEL_NUMBER}{' :'}</Typography>
                    <Typography className={classes.file} variant="h6">{valuesST.modelNumberST ? '0x' + valuesST.modelNumberST : '-'}</Typography>
                  </div>
                  <Divider className={classes.divider} />
                  <div className={classes.linear}>
                    <Typography variant="h6">{STRING_TYPE}{' :'}</Typography>
                    <Typography className={classes.file} variant="h6">{valuesST.typeST ? '0x' + valuesST.typeST : '-'}</Typography>
                  </div>
                  <Divider className={classes.divider} />
                  <div className={classes.linear}>
                    <Typography variant="h6">{STRING_FW_VERSION}{' :'}</Typography>
                    <Typography className={classes.file} variant="h6">{valuesST.versionST ? valuesST.versionST : '-'}</Typography>
                  </div>
                  <Divider className={classes.divider} />
                  <div className={classes.linear}>
                    <Typography variant="h6">{STRING_SW_CODE}{' :'}</Typography>
                    <Typography className={classes.file} variant="h6">{valuesST.swCodeST ? valuesST.swCodeST : '-'}</Typography>
                  </div>
                  <Divider className={classes.divider} />
                  <div className={classes.linear}>
                    <Typography variant="h6">{STRING_FILE_CRC}{' :'}</Typography>
                    <Typography className={classes.file} variant="h6">{crc16xmodem.crc16(valuesST.fileBufferST, 0x0).toString(16) ? crc16xmodem.crc16(valuesST.fileBufferST, 0x0).toString(16) : '-'}</Typography>
                  </div>
                  <Divider className={classes.divider} />
                  <div className={classes.linear}>
                    <Typography variant="h6">{STRING_DATA_CRC}{' :'}</Typography>
                    <Typography className={classes.file} variant="h6">{valuesST.dataCrcST ? valuesST.dataCrcST : '-'}</Typography>
                  </div>
                </div>
              }
            </div>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseClick} color="primary" autoFocus>
            {STRING_CANCEL}
          </Button>
          {firmwareType == 'firmwareOfficial' && values.product != '10' &&
            <Button onClick={handleUpload} color="primary" disabled={values.fileBuffer === null || valuesST.fileBufferST === null || description.length == 0} autoFocus>
              {STRING_UPLOAD}
            </Button>
          }
          {firmwareType == 'firmwareOfficial' && values.product == '10' &&
            <Button onClick={handleUpload} color="primary" disabled={values.fileBuffer === null || description.length == 0} autoFocus>
              {STRING_UPLOAD}
            </Button>
          }
          {firmwareType == 'firmwareBS' &&
            <Button onClick={handleUpload} color="primary" disabled={values.fileBuffer === null || description.length == 0} autoFocus>
              {STRING_UPLOAD}
            </Button>
          }
        </DialogActions>
      </Dialog>
      <AlertPopup open={successOpen} handleClose={closeAlertSuccess} comment={STIRNG_SUCCESS_ADD_FIRMWARE} color={'green'} severity={'success'} />
      <AlertPopup open={failOpen} handleClose={closeAlertFail} comment={comment} color={'red'} severity={'error'} />
      <AlertPopup open={fileAlertOpen} handleClose={closeAlertPopup} comment={STRING_WRONG_FW_INFO} color={'red'} severity={'error'} />
      <AlertPopup open={activeOpen} handleClose={closeAlertActive} comment={STRING_ALERT_ACTIVE_CHECK}
        comment2={STRING_ALERT_CHECKBOX} color={'red'} severity={'error'} flag={true} />
      <AlertPopup open={forceOpen} handleClose={closeAlertForce} comment={STRING_ALERT_FORCE_CHECK}
        comment2={STRING_ALERT_CHECKBOX} color={'red'} severity={'error'} flag={true} />
      <AlertPopup open={groupOpen} handleClose={closeAlertGroup} comment={STRING_ALERT_GROUP_CHECK}
        comment2={STRING_ALERT_CHECKBOX} color={'red'} severity={'error'} flag={true} />
      <AlertPopup open={bsCheckOpen} handleClose={closeAlertBSCheck} comment={STRING_ALERT_BS_CHECK}
        comment2={STRING_ALERT_CHECKBOX} color={'red'} severity={'error'} flag={true} />

    </div>
  );
};

AddFirmwareDialog.propTypes = {
  className: PropTypes.string
};

export default AddFirmwareDialog;
