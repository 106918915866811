import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Bar  } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core/styles';
import palette from 'theme/palette';

import {
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Divider,
  CardActions,
  Button
} from '@material-ui/core';

import useAsync from '../../../../asyncNet';
import { statisticsGetTemperatureCount } from '../../APIs/mateApis';

const STRING_TEMPERATURE = '사용자 설정 온도 분포';
const STRING_BASIS_FAHRENHEIT = "화씨";
const STRING_BASIS_CELSIUS = "섭씨";

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
  card: {
    backgroundColor: '#fff9db'
  },
  chartContainer: {
    height: '40vh'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));




const TemperatureChart = props => {
  const { className, ...rest } = props;
  const [state, tryRefetch] = useAsync(() => statisticsGetTemperatureCount());
  const [chartBasis, setChartBasis] = useState(STRING_BASIS_FAHRENHEIT);
  const { data: metric } = state;
  const classes = useStyles();
  const getTitle = () =>{
    var title = STRING_TEMPERATURE+" - "+chartBasis;

    return title;
  };
  const [chartdata, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: '',
        fill: false,
        backgroundColor: palette.info.main,
        data: []
      }
    ]
  });

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: { display: false },
    cornerRadius: 5,
    scales: {
      xAxes: [
        {
          barPercentage: 0.5,
          categoryPercentage: 0.5,
          ticks: {
            fontColor: palette.text.secondary
          },
          gridLines: {
            display: false,
            drawBorder: false
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            fontColor: palette.text.secondary,
          },
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: palette.divider
          }
        }
      ]
    },
  };
  const xAxesStringMaker = n => {
    var xAxes = n+"도";
    return xAxes;
  };

  const changeBasis = (basis) =>{
    setChartBasis(basis);
  }

  const changeToCelsius = n =>{
    return ((n-32)*5/9).toFixed(2);
  }

  useEffect(() => {
    try {
      if (metric !== null) {
        var formattedXAxes = null;
        var yAxes = null;
        if(chartBasis===STRING_BASIS_FAHRENHEIT){
          formattedXAxes = metric['temperature'].map(xAxesStringMaker);
          yAxes = metric['count'];
        }else{
          formattedXAxes = metric['temperature'].map(changeToCelsius).map(xAxesStringMaker);
          yAxes = metric['count'];
        }

        setChartData(chardata => ({
            ...chartdata,
            labels: formattedXAxes,
            datasets: [
              {
                label: '설정한 사용자 수',
                borderWidth: 3,
                backgroundColor: 'rgba(54, 162, 235, 0.5)',
                barThickness: 15,
                data: yAxes,
              }]
          })
        );
      }
    } catch (e) {
      console.log('metric update failed :' + JSON.stringify(e));
    }
  }, [metric, chartBasis]);

  useEffect(() => {
    tryRefetch();
  }, []);

  return (
    <div>
      <Card
        {...rest}
        className={classes.root}>
        <CardHeader
          action={
            <IconButton size="small" style={{ color: 'white' }}>
            </IconButton>
          }
          title={getTitle()}
        />

        <Divider/>
        <CardContent>
          <div className={classes.chartContainer}>
            <Bar
              data={chartdata}
              options={options}
            />
          </div>
        </CardContent>
        <Divider/>
        <CardActions className={classes.actions}>
          <Button
            color="primary"
            size="small"
            variant="text"
            onClick={()=>{
              changeBasis(STRING_BASIS_CELSIUS);
            }}
          >
            {STRING_BASIS_CELSIUS}
          </Button>
          <Button
            color="primary"
            size="small"
            variant="text"
            onClick={()=>{
              changeBasis(STRING_BASIS_FAHRENHEIT);
            }}
          >
            {STRING_BASIS_FAHRENHEIT}
          </Button>
        </CardActions>
      </Card>
    </div>
  );
};

TemperatureChart.propTypes = {
  className: PropTypes.string
};

export default React.memo(TemperatureChart);
