import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { HorizontalBar } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core/styles';

import {
  Card,
  CardHeader,
  CardContent,
  Divider,
} from '@material-ui/core';

import useAsync from '../../../../../asyncNet';
import {
  statisticsVentilationCount
} from '../../../APIs/aironeApis';

const STRING_FREE_VENTILATION_TITLE='환기 / 공청 모델별 누적수';
const STRING_VENT_COUNT = '환기 누적수';
const STRING_AIR_COUNT = '공청 누적수';
const STRING_MODEL_COUNT = '모델 수';

const useStyles = makeStyles(() => ({
  height: '100%',
  card: {
    backgroundColor: '#fff9db'
  },
  chartContainer: {
    height: '100%',
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const options = {
  legend: {
    display: false
  }
};

const VentilationCountChart = props => {

  const { className, ...rest } = props;
  const [state, tryRefetch] = useAsync(statisticsVentilationCount, [], false);
  const [chartdata, setChartData] = useState({
    labels: [],
    datasets: []
  });
  const [ventValue, setVentValue] = useState([]);
  const [airValue, setAirValue] = useState([]);
  const [modelValue, setModelValue] = useState([]);
  const [chartColor, setChartColor] = useState([]);
  const chartLabel = ["TAC 510/550", "TAC 511/551", "중대형"];

  const { data: metric } = state;
  const classes = useStyles();

  const makeChartValue = (numeric, ventCount, airOperationCount, modelCount) =>{
    let ventValue = [0, 0, 0];
    let airValue = [0, 0, 0];
    let modelValue = [0, 0, 0];
    let tempColor = [];

    for (var i = 0; i < numeric.length; i++) {
      switch (parseInt(numeric[i])) {
        case 2:
          ventValue[0]+=ventCount[i];
          airValue[0]+=airOperationCount[i];
          modelValue[0]+=modelCount[i];
          tempColor.push('#FFFAFD');
          break;
        case 3:
          ventValue[0]+=ventCount[i];
          airValue[0]+=airOperationCount[i];
          modelValue[0]+=modelCount[i];
          tempColor.push('#FFFFCD');
          break;
        case 4:
          ventValue[1]+=ventCount[i];
          airValue[1]+=airOperationCount[i];
          modelValue[1]+=modelCount[i];
          tempColor.push('#FF730F');
          break;
        case 5:
          break;
        case 6:
          ventValue[2]+=ventCount[i];
          airValue[2]+=airOperationCount[i];
          modelValue[2]+=modelCount[i];
          tempColor.push('#FDDACD');
          break;
      }
    }
    setVentValue(ventValue);
    setAirValue(airValue);
    setModelValue(modelValue);
    setChartColor(tempColor);

  };

  useEffect(() => {
    try {
      setChartData({
          ...chartdata,
          labels: chartLabel,
          datasets: [
            {
              label: STRING_VENT_COUNT,
              borderWidth: 1,
              backgroundColor: '#FFFFCD',
              data: ventValue,
            },
            {
              label: STRING_AIR_COUNT,
              borderWidth: 1,
              backgroundColor: '#FDDACD',
              data: airValue,
            },
            {
              label: STRING_MODEL_COUNT,
              borderWidth: 1,
              backgroundColor: '#FF730F',
              data: modelValue//metric['count'],
            }
          ]
        }
      );
    } catch (e) {
      console.log('metric update failed :' + JSON.stringify(e));
    }
  }, [ventValue]);

  useEffect(() => {
    if (metric !== null && metric !== undefined) {
      console.log('update freefilter: '+JSON.stringify(metric));
      makeChartValue(metric['modelCode'], metric['ventCount'], metric['airOperationCount'], metric['modelCount']);
    }  }, [metric]);


  return (
    <div>
      <Card
        {...rest}
        className={clsx(classes.root, className)}>
        <CardHeader
          title={STRING_FREE_VENTILATION_TITLE}
        />
        <Divider/>
        <CardContent>
          {chartdata !== undefined  &&
          <div className={classes.chartContainer}>
            <HorizontalBar
              data={chartdata}
              options={options}
            />
          </div>
          }
        </CardContent>
        <Divider/>
      </Card>
    </div>
  );
};

VentilationCountChart.propTypes = {
  className: PropTypes.string
};

export default React.memo(VentilationCountChart);
