import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/core/styles';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from 'moment';
import {
  STRING_HANDLER_TYPE,
  STRING_ID_HANDLER,
  STRING_CONNECTION_DATE,
  STRING_CONNECTION_IP,
  STRING_ID_MEMBER,
  STRING_MENU,
  STRING_FEATURE,
  STRING_FEATURE_DETAIL,
  STRING_MANAGER,
  STRING_DORMANT_USER,
  STRING_APP,
  STRING_CHANGE_PASSWORD,
  STRING_UPDATE_USER_PRIVATE_INFO,
  STRING_DEREGISTER_USER,
  STRING_EMAIL,
  STRING_PHONE_NUMBER,
  STRING_NAME,
  STRING_USER,
  STRING_MEMBER,
  STRING_DEVICE,
  STRING_DATE_FORMAT_INCLUDE_SEC,
  STRING_DORMANCY_INFO,
  STRING_DORMANCY_MANAGE,
  STRING_NAVIEN_SMART_MIGRATE
} from '../../../StringTable/StringTable';



import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination
} from '@material-ui/core';

import Tooltip from '@material-ui/core/Tooltip';
const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  MinInner: {
    minWidth: '10vw',
    maxWidth: '15vw'
  },
  TableHeader: {
    minWidth: '10vw',
    maxWidth: '40vw',
    borderWidth: 0.1,
    borderColor: theme.palette.action.selected,
    borderStyle: "solid"
  },
  smallInner: {
    width: '8vw',
    maxWidth: '50vw',
    minWidth: '8vw'
  },
  mouseOver: {
    cursor: 'pointer'
  },
  tableRow: {
    width: '100vw'
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  deviceView: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  deviceDescriptView: {
    display: 'flex',
    flexDirection: 'column'
  },
  deviceDescriptDivider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

const RecordTable = props => {
  const { className, records, ...rest } = props;
  const classes = useStyles();
  const [selectedUsers] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  return (
    <div>
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table >
                <TableHead>
                  <TableRow>
                    <TableCell align={'center'} className={classes.TableHeader} ><span><b>{STRING_HANDLER_TYPE}</b></span></TableCell>
                    <TableCell align={'center'} className={classes.TableHeader} ><span><b>{STRING_ID_HANDLER}</b></span></TableCell>
                    <TableCell align={'center'} className={classes.TableHeader} ><span><b>{STRING_CONNECTION_DATE}</b></span></TableCell>
                    <TableCell align={'center'} className={classes.TableHeader} ><span><b>{STRING_CONNECTION_IP}</b></span></TableCell>
                    <TableCell align={'center'} className={classes.TableHeader} ><span><b>{STRING_ID_MEMBER}</b></span></TableCell>
                    <TableCell align={'center'} className={classes.TableHeader} ><span><b>{STRING_MENU}</b></span></TableCell>
                    <TableCell align={'center'} className={classes.TableHeader} ><span><b>{STRING_FEATURE}</b></span></TableCell>
                    <TableCell align={'center'} className={classes.TableHeader} ><span><b>{STRING_FEATURE_DETAIL}</b></span></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody >
                  {records !== null && records !== undefined && records.slice((page * rowsPerPage), ((page + 1) * rowsPerPage)).map(record => (
                    <TableRow
                      className={classes.tableRow}
                      hover
                      key={record.date}
                      selected={selectedUsers.indexOf(record.userId) !== -1}

                    >
                      <TableCell align={'center'} className={classes.MinInner}>
                        {record.handlerType === 'MANAGER' && STRING_MANAGER}
                        {record.handlerType === 'USER' && STRING_MEMBER}
                      </TableCell>
                      <TableCell align={'center'} className={classes.MinInner}>
                        {record.handlerId ? record.handlerId : '-'}
                        {/* <ul>{record.PARAMETER.split(',').map(str=><li key={str}>{str}</li>)}</ul> */}
                      </TableCell>
                      <TableCell align={'center'} className={classes.MinInner}>
                        {record !== null && record.date ? moment((record.date - 9 * 60 * 60) * 1000).format(STRING_DATE_FORMAT_INCLUDE_SEC) : "-"}
                      </TableCell>
                      <TableCell align={'center'} className={classes.MinInner}>
                        {record.ip ? record.ip : '-'}
                      </TableCell>
                      <TableCell align={'center'} className={classes.MinInner}>
                        {record.userId ? record.userId : '-'}
                      </TableCell>
                      <TableCell align={'center'} className={classes.MinInner}>
                        {record.menu === 'USER' && STRING_USER}
                        {record.menu === 'DEVICE' && STRING_DEVICE}
                        {record.menu === 'DORMANT' && STRING_DORMANT_USER}
                        {record.menu === 'APP' && STRING_APP}
                      </TableCell>
                      <TableCell align={'center'} className={classes.MinInner}>
                        {record.feature === 'LOGIN' && 'Login'}
                        {record.feature === 'CHANGE_PASSWORD' && STRING_CHANGE_PASSWORD}
                        {record.feature === 'UPDATE_USER' && STRING_UPDATE_USER_PRIVATE_INFO}
                        {record.feature === 'DEREGISTER_USER' && STRING_DEREGISTER_USER}
                        {record.feature === 'SEARCH' && 'SEARCH'}
                        {record.feature === 'DETAIL SEARCH' && 'DETAIL SEARCH'}
                      </TableCell>
                      <TableCell align={'center'} className={classes.MinInner}>
                        {record.featureSub === 'NICKNAME' && STRING_NAME}
                        {record.featureSub === 'EMAIL' && STRING_EMAIL}
                        {record.featureSub === 'PHONE_NUMBER' && STRING_PHONE_NUMBER}
                        {record.featureSub === 'DORMANT NOTIFICATION' && STRING_DORMANCY_INFO}
                        {record.featureSub === 'MANAGE DORMANT USER' && STRING_DORMANCY_MANAGE}
                        {record.featureSub === 'NAVIEN SMART MIGRATE' && STRING_NAVIEN_SMART_MIGRATE}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={records !== undefined ? records.length : 0}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25, 100]}
          />
        </CardActions>
      </Card>
    </div>
  );
};

RecordTable.propTypes = {
  className: PropTypes.string,
  records: PropTypes.array.isRequired
};

export default RecordTable;
