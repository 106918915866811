import React, { useEffect, useState } from 'react';
import {
  Dialog, DialogActions, DialogContent, Button,
  DialogTitle, Divider, Typography, makeStyles,
  Checkbox, Input, Backdrop, CircularProgress, Accordion, AccordionSummary, AccordionDetails
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import { rcAddFirmware, getS3Path, getSts } from '../../../APIs/aironeApis';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import useAsync from '../../../../../asyncNet';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import AWS from 'aws-sdk';
import {deploy} from 'config';

const STRING_TITLE = '펌웨어 추가';
const STRING_MAJOR_VERSION = 'MAJOR 버전 :';
const STRING_MINOR_VERSION = 'MINOR 버전 :';
const STRING_MODEL_CODE = '모델 코드 :';
const STRING_BUILD_NUMBER = 'BUILD 넘버 :';
const STRING_FW_URL = '펌웨어 업로드 :';
const STRING_FW_TYPE = '펌웨어 Type :';
const STRING_FW_TYPE_1 = '1 (MCU)';
const STRING_FW_TYPE_2 = '2 (Wifi)';
const STRING_FW_TYPE_3 = '3 (Certificate)';
const STRING_ACTIVE = 'ACTIVE 유무 :';
const STRING_FORCE = 'FORCE 유무 :';
const STRING_GROUP_OTA = 'GROUP OTA 유무 :';
const STRING_FW_DESCRIPTION = '펌웨어 설명 :';
const STRING_FILE_NAME = '파일 :';
const STRING_FILE_SIZE = '파일 크기 :';
const STRING_FILE_SELECT = '파일 선택';
const STRING_ALERT_FW_UPLOAD = '업로드시 사전 검증 완료된 펌웨어만 등록해주세요.';
const STRING_CLOSE = '취소';
const STRING_UPLOAD = '업로드';
const STRING_ADD_GROUP = '그룹 추가';

const REGION ='ap-northeast-2';
var i =0 ; // add var
var S3_BUCKET =""

if(deploy === "dev"){
  S3_BUCKET = "airone-dev-s3-bucket-firmware";
}else if(deploy === "stage"){
  S3_BUCKET = "airone-stage-s3-bucket-firmware";
}else if(deploy === "prod"){
  S3_BUCKET = "airone-prod-s3-bucket-firmware";
}

console.log('S3_BUCKET:'+ S3_BUCKET);

const useStyles = makeStyles(theme => ({
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  linear:{
    display: 'flex',
    alignItems: 'flex-end'
  },
  datas:{
    marginLeft: theme.spacing(2)
  },
  dataSelection:{
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0)
  },
  icons:{
    marginLeft: theme.spacing(2),
    padding: theme.spacing(0)
  },
  fwDescription:{
    marginLeft: theme.spacing(2),
    width: '65%'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));

const fwFileType = [
  {
    value: STRING_FW_TYPE_1
  },
  {
    value: STRING_FW_TYPE_2
  },
  {
    value: STRING_FW_TYPE_3
  }
];

const AddFirmwareDialogMqtt  = props => {
  const { open, ipAddress, handleClose, refetch, modelCode } = props;
  const [forcedflag, setForcedFlag] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeflag, setActiveFlag] = useState(false);
  const [groupOtaflag, setGroupOtaFlag] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [selectedFileSize, setSelectedFileSize] = useState(0);
  const [majorVersion, setMajorVersion] = useState(0);
  const [minorVersion, setMinorVersion] = useState(0);
  const [buildNumber, setBuildNumber] = useState(0);
  const [fwDescription, setFwDescription] = useState("");
  const [ updatedDeviceList, setUpdatedDeviceList ] = useState("");
  const classes = useStyles();
  const [selectedFile, setSelectedFile] = useState(null);
  const [fwTypeName, setFwTypeName] = useState(fwFileType[0].value);
  const reqFiles = [];
  const reqFileName=[];
  const reqFileSize=[];
  var rcInitCount=0;
  

  const handleForcedFlag = () =>{
    if(forcedflag){
      setForcedFlag(false);
    }else{
      setForcedFlag(true);
    }
  };

  const fwTypeChange = (event) => {
    event.persist();
    setFwTypeName(event.target.value)
  };

  const handleActiveFlag = () =>{
    if(activeflag){
      setActiveFlag(false);
    }else{
      setActiveFlag(true);
    }
  };

  const handleGroupOtaFlag = () =>{
    if(groupOtaflag){
      setGroupOtaFlag(false);
    }else{
      setGroupOtaFlag(true);
    }
  };

  const placeHolderValue = [
    {
      "DEVICEID": "Sample1"
    },
    {
      "DEVICEID": "Sample2"
    }
  ];

  const handleUploadClick = event => {
   
    let uploadFileSize = 0;
    console.log('event.target.files.length: '+ event.target.files.length)

    for(let i =0; i < event.target.files.length; i++){
      reqFiles.push(event.target.files[i])
      reqFileName.push(event.target.files[i].name)
      reqFileSize.push(event.target.files[i].size)
      uploadFileSize += event.target.files[i].size
    }
    // setSelectedFile(event.target.files);
    // var file = event.target.files;
    console.log('reqFiles: ' + reqFiles)
    console.log('reqFileName: ' + reqFileName)
    console.log('uploadFileSize:' + uploadFileSize)
    
    setSelectedFile(reqFiles);
    setSelectedFileName(reqFileName);
    setSelectedFileSize(reqFileSize);

    console.log('selectedFile is ' + selectedFile)
    console.log('SelectedFileName is ' + selectedFileName)
    console.log('SelectedFileSize is ' + selectedFileSize)

  };

  const handleCloseClick = () =>{
    setForcedFlag(false);
    setActiveFlag(false);
    setGroupOtaFlag(false);
    setSelectedFileName("");
    setSelectedFileSize(0);
    setMajorVersion(0);
    setMinorVersion(0);
    setBuildNumber(0);
    setFwDescription("");
    handleClose();
    console.log("handle_close"+selectedFileSize);
  };

  const refetchList = () => {
    setLoading(false);
    refetch();
  };


  async function uploadPutObject(){
    const myBucket = new AWS.S3({
      params: { Bucket: S3_BUCKET},
      region: REGION
    });
    
    for(let j =0; j<selectedFile.length; j++){
      if ((selectedFileSize[0] < selectedFileSize[j])) {
        var changeSelectedFile = selectedFile[j]
        selectedFile[j] = selectedFile[0]
        selectedFile[0] = changeSelectedFile
        var changeSelectedFileName = selectedFileName[j]
        selectedFileName[j] = selectedFileName[0]
        selectedFileName[0] = changeSelectedFileName
      }
      console.log('selectedFile[j] is', selectedFile[j])
    }
    
    for(let i=0; i<selectedFile.length; i++){
      const params = {
        Body: selectedFile[i],
        Bucket: S3_BUCKET,
        Key: modelCode +'/'+ selectedFileName[i]
      };
      await new Promise(function(resolve, reject) {
        myBucket.putObject(params, (err, selectedFile) => {

          err ? reject(err): resolve(selectedFile);
          console.log('err' + JSON.stringify(err))
          console.log('selectedFile' + JSON.stringify(selectedFile))

          console.log('putObject');
          console.log('putObject_selectedFile['+i+']' + JSON.stringify(selectedFileName[i]))
          console.log('putObject_selectedFile_json: '+ JSON.stringify(selectedFile));
          resolve();
        });
      })

    } // forloop
    console.log('uploadPutObject end');
    uploadGetObject();
    callAddfirmware()
  }

  function uploadGetObject(){
    let once = 0;
    const myBucket = new AWS.S3({
      params: { Bucket: S3_BUCKET},
      region: REGION
    });
    console.log('GetObject Start')
    console.log('S3_bucket st: ' + S3_BUCKET )
    console.log('modelCode st: ' + modelCode )
    console.log('selectedFileName: ' + selectedFileName)
    

    for(let j=0; j<selectedFile.length; j++){
      once += 1;
      console.log('for loop start in upload GetObject');
      console.log('S3_bucket: ' + S3_BUCKET )
      console.log('modelCode: ' + modelCode )
      console.log('selectedFileName: ' + selectedFileName[j])
      console.log('s3 path:' +'s3://'+S3_BUCKET+'/'+modelCode+'/'+selectedFileName[j] )

      myBucket.getObject('s3://'+S3_BUCKET+'/'+modelCode+'/'+selectedFileName[j], (err, selectedFile) =>{
        if (err) {
          console.log("getObject missing")
          console.log("getObject_modelCode(parseS3Url): "+ modelCode)
          console.log("getObject_getFileName(parseS3Url): " + selectedFileName[j])
        } else {
          console.log("getObject_Loaded " + selectedFile.ContentLength + " bytes");
          console.log('getObject_selectedFile'+selectedFile[j])
        }
      });
    }
    
    if(once>=1){
      alert('업로드 되었습니다.', JSON.stringify(selectedFileName[i]));
    }
    console.log('getObject')
    refetchList();
  }

  const handleUpload = async() => {
    handleClose();
    setLoading(true);
    await getSts();
    console.log('selectedFile: '+ selectedFile)
    await uploadPutObject();
    // uploadGetObject();
  } 
  const addFirmwareFunction = async () =>{
    var ret;
      var deviceArrayValue = null;
      if(updatedDeviceList.length>0) {
        deviceArrayValue = updatedDeviceList.replace(/(\r\n|\n|\r)/gm, "");
      }
      console.log('selectedFileName:'+selectedFileName)
      console.log('befor selectedFileName is ' + selectedFileName[rcInitCount])
      var splitedFileName = selectedFileName[rcInitCount].split(",");
      console.log('before , splitedFileName is ' + splitedFileName)
      splitedFileName = splitedFileName[0].split(".");
      console.log('after , splitedFileName is ' + splitedFileName[0])
      var fwId = splitedFileName[0];
      console.log('fwId is ' + fwId)
      var fwType = fwFileType.map(function (e){ return e.value}).indexOf(fwTypeName) + 1;
      console.log('fwType: ' + fwType);

      let s3Files = selectedFileName;
      console.log("s3Files is " + s3Files);
      
      // let s3Files = "";
      // fwId -> array[] & delete selectedFileSize
      // if (fwId.length < 40) { 
      //   ret = rcAddFirmware(sessionStorage.getItem('email'), ipAddress,
      //   fwId, fwDescription, userArrayValue, getS3Path(modelCode), s3Files, majorVersion,
      //   minorVersion, buildNumber, fwType, selectedFileSize, modelCode,
      //   activeflag, forcedflag, groupOtaflag);
      // }
      if (fwId.length < 40) { 
        ret = rcAddFirmware(sessionStorage.getItem('email'), ipAddress,
        fwId, fwDescription, deviceArrayValue, getS3Path(modelCode), s3Files, majorVersion,
        minorVersion, buildNumber, fwType, modelCode,
        activeflag, forcedflag, groupOtaflag);
      }
      rcInitCount +=1;
    return ret;
    
  }
  const [state, callAddfirmware] = useAsync(addFirmwareFunction, [], true);
  const { addFirmLoading, data: addResponse } = state;

  useEffect(()=> {
    if (addResponse !== null && addResponse !== undefined){
      if (addResponse.code === 200) {
        refetchList("펌웨어 등록이 성공하였습니다.")
      } else if (addResponse.code === 606) {
        refetchList("majorVersion, minorVersion이 동일한 펌웨어가 이미 존재합니다.")
      } else {
        refetchList("예상치 못한 에러가 발생하였습니다.")
      }
    }
  }, [addResponse]);

  const changeMajorNumber = event => {
    event.persist();
    setMajorVersion(event.target.value);
  };

  const changeMinorNumber = event => {
    event.persist();
    setMinorVersion(event.target.value);
  };

  const changebuildNumber = event =>{
    event.persist();
    setBuildNumber(event.target.value);
  };

  const changeFWDescription = event => {
    event.persist();
    setFwDescription(event.target.value);
  };

  const editUpdateDevice = event =>{
    setUpdatedDeviceList(event.target.value);
  };

  return (
    <div>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color={'primary'}/>
      </Backdrop>
      <Dialog
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{STRING_TITLE}</DialogTitle>
        <DialogContent>
          <Alert severity={'error'}><span style={{ color: 'red' }}>{STRING_ALERT_FW_UPLOAD}</span></Alert>
          <Divider className={classes.divider}/>
          <div className={classes.linear}>
            <Typography variant="h6">{STRING_MAJOR_VERSION}</Typography>
            <Input className={classes.datas}
                   type={"number"}
                   placeholder={STRING_MAJOR_VERSION}
                   inputProps={{ 'aria-label': 'description' }}
                   onChange={changeMajorNumber}
            />
          </div>
          <Divider className={classes.divider}/>
          <div className={classes.linear}>
            <Typography variant="h6">{STRING_MINOR_VERSION}</Typography>
            <Input className={classes.datas}
                   type={"number"}
                   placeholder={STRING_MINOR_VERSION}
                   inputProps={{ 'aria-label': 'description' }}
                   onChange={changeMinorNumber}
            />
          </div>
          <Divider className={classes.divider}/>
          <div className={classes.linear}>
            <Typography variant="h6">{STRING_BUILD_NUMBER}</Typography>
            <Input className={classes.datas}
                   type={"number"}
                   placeholder={STRING_BUILD_NUMBER}
                   inputProps={{ 'aria-label': 'description' }}
                   onChange={changebuildNumber}
            />
          </div>
          <Divider className={classes.divider}/>
          <div className={classes.linear}>
            <Typography variant="h6">{STRING_MODEL_CODE}</Typography>
            <Typography className={classes.datas} variant="h6">{modelCode}</Typography>
          </div>
          <Divider className={classes.divider}/>
          <div className={classes.linear}>
            <Typography variant="h6">{STRING_FW_TYPE}</Typography>
            <TextField
              className={classes.dataSelection}
              margin="dense"
              name="fileType"
              onChange={fwTypeChange}
              select
              // eslint-disable-next-line react/jsx-sort-props
              SelectProps={{ native: true }}
              value={fwTypeName}
              variant="outlined"
            >
              {fwFileType.map(option => (
                <option
                  key={option.value}
                  value={option.value}
                >
                  {option.value}
                </option>
              ))}
            </TextField>
          </div>
          <Divider className={classes.divider}/>
          <div className={classes.linear}>
            <Typography variant="h6">{STRING_ACTIVE}</Typography>
            <Checkbox className={classes.icons}
                      checked={activeflag}
                      onChange={handleActiveFlag}
                      color="primary"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </div>
          <Divider className={classes.divider}/>
          <div className={classes.linear}>
            <Typography variant="h6">{STRING_FORCE}</Typography>
            <Checkbox className={classes.icons}
                      checked={forcedflag}
                      onChange={handleForcedFlag}
                      color="primary"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}/>
          </div>
          <Divider className={classes.divider}/>
          <div className={classes.linear}>
            <Typography variant="h6">{STRING_GROUP_OTA}</Typography>
            <Checkbox className={classes.icons}
                      checked={groupOtaflag}
                      onChange={handleGroupOtaFlag}
                      color="primary"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}/>
          </div>

          {groupOtaflag===true&&
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <Typography className={classes.heading}>{STRING_ADD_GROUP}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                id="update-user-id"
                multiline
                fullWidth
                rows={15}
                placeholder={JSON.stringify(placeHolderValue, null, 2)}
                onChange={editUpdateDevice}
                value={updatedDeviceList}
                variant="outlined"
              />
            </AccordionDetails>
            <div className={classes.clipboardBtn}>
              <CopyToClipboard text={updatedDeviceList.length===0?JSON.stringify(placeHolderValue, null, 2):updatedDeviceList}>
                <Button
                  variant="outlined"
                >
                  Copy to clipboard
                </Button>
              </CopyToClipboard>
            </div>
          </Accordion>}
         
          <Divider className={classes.divider}/>
          <div className={classes.linear}>
            <Typography variant="h6">{STRING_FW_URL}</Typography>
            <Button className={classes.icons} component="label"
            onChange={handleUploadClick}>
              {STRING_FILE_SELECT}
              <input
                type="file"
                hidden
                multiple
              />
            </Button>
          </div>
          <Divider className={classes.divider}/>
          <div className={classes.linear}>
            <Typography variant="h6">{STRING_FW_DESCRIPTION}</Typography>
            <Input className={classes.fwDescription}
                   inputProps={{ 'aria-label': 'description' }}
                   onChange={changeFWDescription}
            />
          </div>
          <Divider className={classes.divider}/>
            <div className={classes.linear}>
              <Typography variant="h6">{STRING_FILE_NAME}</Typography>
              <Typography className={classes.datas} variant="h6">{selectedFileName}</Typography>
            </div>
          <Divider className={classes.divider}/>
            <div className={classes.linear}>
              <Typography variant="h6">{STRING_FILE_SIZE}</Typography>
              <Typography className={classes.datas} variant="h6">{selectedFileSize===0?0:selectedFileSize+" bytes"}</Typography>
            </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseClick} color="primary" autoFocus>
            {STRING_CLOSE}
          </Button>
          <Button onClick={handleUpload} color="primary" autoFocus>
            {STRING_UPLOAD}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

AddFirmwareDialogMqtt.propTypes = {
  className: PropTypes.string
};

export default AddFirmwareDialogMqtt;