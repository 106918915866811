import React from 'react';
import ReactDOM from 'react-dom';

import * as serviceWorker from './serviceWorker';
import App from './App';
import Amplify from "aws-amplify";
import {cognito, getGA4Code} from "./config";

import GA4React from "ga-4-react";

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: cognito.REGION,
    userPoolId: cognito.USER_POOL_ID,
    identityPoolId: cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: cognito.APP_CLIENT_ID
  },
});

ReactDOM.render(
  <App />, document.getElementById('root')
);

try {
  setTimeout(_ => {
    const ga4react = new GA4React(getGA4Code());
    ga4react.initialize();
  }, 4000);
} catch (err) {}

serviceWorker.unregister();
