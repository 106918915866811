export default [
  {
    PRODUCT_ID: 0,
    PRODUCT_NAME: "roomcontroller",
    PRODUCT_IMAGE: "/images/smarttok/roomcontroller.png",
    PRODUCT_ENABLE: true
  },
  {
    PRODUCT_ID: 1,
    PRODUCT_NAME: "subroomcontroller",
    PRODUCT_IMAGE: "/images/smarttok/subroomcontroller.png",
    PRODUCT_ENABLE: true
  },
  {
    PRODUCT_ID: 2,
    PRODUCT_NAME: "wifimodule",
    PRODUCT_IMAGE: "/images/smarttok/wifimodule.png",
    PRODUCT_ENABLE: true
  },
  {
    PRODUCT_ID: 3,
    PRODUCT_NAME: "controller",
    PRODUCT_IMAGE: "/images/smarttok/controller.png",
    PRODUCT_ENABLE: true
  },
  {
    PRODUCT_ID: 4,
    PRODUCT_NAME: "servicetoolkit",
    PRODUCT_IMAGE: "/images/smarttok/servicetoolkit.png",
    PRODUCT_ENABLE: true
  },
  {
    PRODUCT_ID: 5,
    PRODUCT_NAME: "valvecontroller",
    PRODUCT_IMAGE: "/images/smarttok/valvecontroller.png",
    PRODUCT_ENABLE: true
  },
  {
    PRODUCT_ID: 6,
    PRODUCT_NAME: "frontpanel",
    PRODUCT_IMAGE: "/images/smarttok/frontpanel.png",
    PRODUCT_ENABLE: true
  }
];
