const regionCode = [
  {
  'name': '',
  'regionCode': []
  },
  {
    'name': '서울',
    'regionCode': [
      {
        "code": [10000]
      },
      {
        "code": [20000, 20999]
      }
    ]
  },
  {
    'name' : '부산',
    'regionCode' : [
      {
        "code": [10026]
      },
      {
        "code": [21000, 21999]
      }
    ]
  },
  {
    'name' : '대구',
    'regionCode' : [
      {
        "code": [10043]
      },
      {
        "code": [22000, 22999]
      }
    ]
  },
  {
    'name' : '인천',
    'regionCode' : [
      {
        "code": [10052]
      },
      {
        "code": [23000, 23999]
      }
    ]
  },
  {
    'name' : '광주',
    'regionCode' : [
      {
        "code": [10063]
      }
    ]
  },
  {
    'name' : '대전',
    'regionCode' : [
      {
        "code": [10069]
      }
    ]
  },
  {
    'name' : '울산',
    'regionCode' : [
      {
        "code": [10075]
      }
    ]
  },
  {
    'name' : '경기도',
    'regionCode' : [
      {
        "code": [10081, 10131]
      }
    ]
  },
  {
    'name' : '강원도',
    'regionCode' : [
      {
        "code": [10132, 10149]
      }
    ]
  },
  {
    'name' : '충청북도',
    'regionCode' : [
      {
        "code": [10150, 10163]
      }
    ]
  },
  {
    'name' : '충청남도',
    'regionCode' : [
      {
        "code": [10164, 10181]
      }
    ]
  },
  {
    'name' : '전라북도',
    'regionCode' : [
      {
        "code": [10182, 10197]
      }
    ]
  },
  {
    'name' : '전라남도',
    'regionCode' : [
      {
        "code": [14639]
      },
      {
        "code": [10198, 10219]
      }
    ]
  },
  {
    'name' : '경상북도',
    'regionCode' : [
      {
        "code": [10220, 10242]
      }
    ]
  },
  {
    'name' : '경상남도',
    'regionCode' : [
      {
        "code": [10245, 10246]
      }
    ]
  },
  {
    'name' : '제주',
    'regionCode' : [
      {
        "code": [10266, 10267]
      }
    ]
  },
  {
    'name' : '세종',
    'regionCode' : [
      {
        "code": [10273]
      }
    ]
  }
];

export default regionCode;
