import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core/styles';
import {
  CardContent,
  Divider,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Typography
} from '@material-ui/core';
import palette from 'theme/palette';
import { options } from './chart';

const STRING_ALLREGION = 'USA 지역 리스트';
const useStyles = makeStyles(() => ({
  chartContainer: {
    height: 400,
    position: 'relative'
  },
  divider: {
    marginTop: 20,
    marginBottom: 20
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const DeviceAreaDialog = props => {

  const { openStatus, closeFunc, data } = props;
  const classes = useStyles();
  const [areaData, setAreaData] = useState({
    labels: [],
    datasets: []
  });

  useEffect(() => {
    try {
      if (data !== null && data !== undefined && data['countryArray'] !== undefined) {
        setAreaData(areaData => ({
          ...areaData,
          labels: data['countryArray'],
          datasets: [
            {
              label: 'EQM-580',
              backgroundColor: palette.info.main,
              data: data['model580Array']
            },
            // {
            //   label: 'EQM-582',
            //   backgroundColor: palette.info.main,
            //   data: data['model582Array']
            // },
            // {
            //   label: 'EME-501',
            //   backgroundColor: palette.info.main,
            //   data: data['model501Array']
            // }
          ]
        }));
      }
    } catch (e) {
      console.log('areaCount update failed :' + JSON.stringify(e));
    }
  }, [data]);

  const timezoneArea = (classes) =>{
    return <div>
      <Typography variant="h5">Atlantic: </Typography>
      <Typography variant="subtitle2">Puerto Rico / U.S. Virgin Islands</Typography>

      <Divider className={classes.divider}/>
      <Typography variant="h5">Eastern: </Typography>
      <Typography variant="subtitle2"> Connecticut / Delaware / District of Columbia / Georgia /
      Maine / Maryland /
       Massachusetts / New Hampshire / New Jersey / New York / North Carolina / Ohio /
       Pennsylvania / Rhode Island /South Carolina / Vermont / Virginia / West Virginia</Typography>
      <Typography variant="subtitle2"> (Partially: Florida / Indiana / Kentucky / Michigan /
      Tennessee)</Typography>

      <Divider className={classes.divider}/>
      <Typography variant="h5">Central: </Typography>
      <Typography variant="subtitle2">Alabama / Arkansas / Illinois / Iowa / Louisiana / Minnesota /
      Mississippi / Missouri / Oklahoma / Wisconsin</Typography>
      <Typography variant="subtitle2"> (Partially: Florida / Indiana / Kansas / Kentucky / Michigan /
      Nebraska / North Dakota / South Dakota / Tennessee / Texas)</Typography>

      <Divider className={classes.divider}/>
      <Typography variant="h5">Mountain: </Typography>
      <Typography variant="subtitle2">Arizona / Colorado / Montana / New Mexico / Utah /
      Wyoming </Typography>
      <Typography variant="subtitle2"> (Partially: Idaho / Kansas / Nebraska / Nevada /
      North Dakota / Oregon / South Dakota / Texas)</Typography>

      <Divider className={classes.divider}/>
      <Typography variant="h5">Pacific: </Typography>
      <Typography variant="subtitle2">California / Washington (state)</Typography>
      <Typography variant="subtitle2"> (Partially: Idaho / Nevada / Oregon) </Typography>

      <Divider className={classes.divider}/>
      <Typography variant="h5">Alaska: </Typography>
      <Typography variant="subtitle2"> (Partially: Alaska) </Typography>

      <Divider className={classes.divider}/>
      <Typography variant="h5">Hawaiian: </Typography>
      <Typography variant="subtitle2">Hawaii</Typography>
      <Typography variant="subtitle2"> (Partially: Alaska, Johnston Atoll) </Typography>
    </div>
  }

  return (
    <Dialog
      open={openStatus}
      TransitionComponent={Transition}
      fullWidth={true}
      maxWidth={'xl'}
      keepMounted
      onClose={closeFunc}
    >
      <DialogTitle>{STRING_ALLREGION}</DialogTitle>
      <DialogContent>
       {timezoneArea(classes)}
      </DialogContent>
      <DialogActions>
        <Button onClick={closeFunc} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DeviceAreaDialog.propTypes = {
  className: PropTypes.string
};

export default DeviceAreaDialog;
