import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@material-ui/core';

const STRING_USER = '회원 통계';
const TABLENAME = {
  totalCount: '전체회원(룸콘기준)',
  activity: {
    total: '활성회원(룸콘기준)',
    online: '온라인',
    offLine: '오프라인'
  },
  dormancy: {
    total: '휴먼회원(룸콘기준)',
    online: '온라인',
    offLine: '오프라인'
  }
};

const useStyles = makeStyles(() => ({
  chartContainer: {
    height: 400,
    width: 400,
    position: 'relative'
  },
  tableTitle: {
    justifyContent: 'space-between'
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const UserTableDialog = props => {
  const { openStatus, closeFunc, data } = props;
  const [userData, setUserData] = useState({});
  const classes = useStyles();

  useEffect(() => {
    try {
      if (
        data !== null &&
        data !== undefined &&
        data['detailCount'] !== undefined
      ) {
        setUserData(userStateData => {
          return {
            ...userStateData,
            ...data
          };
        });
      }
    } catch (e) {
      console.log('Count update failed :' + JSON.stringify(e));
    }
  }, [data]);

  const getDateByTimestamp = time => {
    return new Date(time * 1000 - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .slice(0, 19)
      .replace('T', ' ');
  };

  return (
    <Dialog
      open={openStatus}
      TransitionComponent={Transition}
      // fullWidth={true}
      maxWidth={'m'}
      keepMounted
      onClose={closeFunc}>
      <DialogTitle className={classes.tableTitle}>
        <Typography>{STRING_USER}</Typography>
        <Typography variant="body2">
          {userData &&
            Object.keys(userData).length > 0 &&
            userData.hasOwnProperty('detailCount') && (
              <>{getDateByTimestamp(userData['detailCount']['timestamp'])}</>
            )}{' '}
          기준
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Table size="small">
          {userData &&
            Object.keys(userData).length > 0 &&
            userData.hasOwnProperty('detailCount') && (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={3}>{TABLENAME['totalCount']}</TableCell>
                  <TableCell>{userData['totalCount']}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell rowSpan={6} />
                  <TableCell colSpan={2}>
                    {TABLENAME['activity']['total']}
                  </TableCell>
                  <TableCell>
                    {userData['detailCount']['activity']['total']}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell rowSpan={2} />
                  <TableCell>{TABLENAME['activity']['online']}</TableCell>
                  <TableCell>
                    {userData['detailCount']['activity']['online']}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{TABLENAME['activity']['offLine']}</TableCell>
                  <TableCell>
                    {userData['detailCount']['activity']['offline']}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2}>
                    {TABLENAME['dormancy']['total']}
                  </TableCell>
                  <TableCell>
                    {userData['detailCount']['dormancy']['total']}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell rowSpan={2} />
                  <TableCell>{TABLENAME['dormancy']['online']}</TableCell>
                  <TableCell>
                    {userData['detailCount']['dormancy']['online']}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{TABLENAME['dormancy']['offLine']}</TableCell>
                  <TableCell>
                    {userData['detailCount']['dormancy']['offline']}
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeFunc} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

UserTableDialog.propTypes = {
  className: PropTypes.string
};

export default React.memo(UserTableDialog);
