import React, { useEffect, useState } from 'react';
import {
  makeStyles, CardContent, TableHead, TableRow, TableCell, CardActions,
  Card, Table, TableBody, TablePagination, Box, Button, Tooltip,
  IconButton, Backdrop, CircularProgress
} from '@material-ui/core';
import useAsync from '../../../asyncNet';
import { getFirmwareList } from '../APIs/tokapis';
import BorderColorIcon from '@material-ui/icons/BorderColor'
import DeleteIcon from '@material-ui/icons/Delete';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import ZoomInTwoToneIcon from '@material-ui/icons/ZoomInTwoTone';
import Alert from '@material-ui/lab/Alert';
import {
  AddFirmwareDialog, GroupOTADialog, UpdateFirmwareDialog, DetailFirmwareDialog
} from './components';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { green, blueGrey } from '@material-ui/core/colors';
import moment from 'moment';
import {
  STRING_FILE_SIZE_BYTES,
  STRING_ALERT_FW_LIST,
  STRING_REGISTER_AT,
  STRING_LAST_MODIFY_AT,
  STRING_FIRMWARE_TYPE,
  STRING_ROOMCON,
  STRING_SUB_ROOMCON,
  STRING_VALVE_CONTROLLER,
  STRING_SERVICE_TOOLKIT,
  STRING_WIFI,
  STRING_CERTIFICATE,
  STRING_CONTROLLER,
  STRING_PANEL,
  STRING_CLICK_FILENAME,
  STRING_FW_VERSION,
  STRING_SW_CODE,
  STRING_ACTIVE,
  STRING_FORCE,
  STRING_GROUP,
  STRING_FILE_NAME,
  STRING_SHOW_DETAIL,
  STRING_DATE_FORMAT_INCLUDE_SEC,
  STRING_UPDATE,
  STRING_BS_CHECK
} from '../StringTable/StringTable';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  alert: {
    marginBottom: theme.spacing(2)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  backButton: {
    marginRight: theme.spacing(1)
  }
}));

const FirmwareList = ({ history, match }) => {
  const classes = useStyles();
  let swCodeMasking = match.params.swCodeMasking;
  let productName = match.params.productname;
  let firmwareType = match.params.firmwareType;
  let modelName = match.params.modelname;
  let product = '';
  if (productName == 'roomcontroller') {
    product = STRING_ROOMCON;
  } else if (productName == 'subroomcontroller') {
    product = STRING_SUB_ROOMCON;
  } else if (productName == 'valvecontroller') {
    product = STRING_VALVE_CONTROLLER;
  } else if (productName == 'wifimodule') {
    product = STRING_WIFI;
  } else if (productName == 'certificate') {
    product = STRING_CERTIFICATE;
  } else if (productName == 'controller') {
    product = STRING_CONTROLLER;
  } else if (productName == 'frontpanel') {
    product = STRING_PANEL;
  }
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [state, tryRefetch] = useAsync(() => getFirmwareList(swCodeMasking, firmwareType), [], false);
  const { loading, data: firmwares } = state;
  const [addFWDialogOpen, setAddFWDialogOpen] = useState(false);
  const [updateFWDialogOpen, setUpdateFWDialogOpen] = useState(false);
  const [detailFWDialogOpen, setDetailFWDialogOpen] = useState(false);
  const [deleteFWDialogOpen, setDeleteFWDialogOpen] = useState(false);
  const [groupOTADialogOpen, setGroupOTADialogOpen] = useState(false);
  const [active, setActive] = useState(false);
  const [bsCheck, setBSCheck] = useState(false);
  const [selectedFirmware, setSelectedFirmware] = useState({
    version: null,
    swCode: null,
    fileName: null,
    downloadUrl: null,
    fileSize: null,
    fileCrc: null,
    fwRegisterAt: null,
    activeFlag: false,
    forceFlag: false,
    groupFlag: false,
    bsCheckFlag: false,
    description: null,
    updateTime: null,
    releaseType: null
  });

  useEffect(() => {
    if (sessionStorage.getItem('naviwatch_permission') !== 'true')
      history.push('/');
  }, []);

  useEffect(() => {
    if (firmwares !== null) {
      for (let i = 0; i < firmwares.fwList.length; i++) {
        if (firmwares.fwList[i].activeFlag === true) {
          activeTrue();
          break;
        } else {
          activeFalse();
        }
      }
    }
  }, [firmwares]);

  const handlePageChange = (event, page) => setPage(page);
  const handleRowsPerPageChange = event => setRowsPerPage(event.target.value);
  const clickBack = () => history.push(`/smarttok/firmware/${firmwareType}/${productName}`);
  const openAddFirmware = () => setAddFWDialogOpen(true);
  const closeAddFirmware = () => setAddFWDialogOpen(false);
  const openUpdateFirmware = () => setUpdateFWDialogOpen(true);
  const closeUpdateFirmware = () => setUpdateFWDialogOpen(false);
  const openDetailFirmware = () => setDetailFWDialogOpen(true);
  const closeDetailFirmware = () => setDetailFWDialogOpen(false);
  const openGroupOTADialog = () => setGroupOTADialogOpen(true);
  const closeGroupOTADialog = () => setGroupOTADialogOpen(false);
  const activeTrue = () => setActive(true);
  const activeFalse = () => setActive(false);
  const openDownloadUrl = (url) => window.open(url, '_blank');


  const updateClick = (version, swCode, activeFlag, forceFlag,
    groupFlag, bsCheckFlag, description, updateTime) => {
    setSelectedFirmware({
      ...selectedFirmware,
      version: version,
      swCode: swCode,
      activeFlag: activeFlag,
      forceFlag: forceFlag,
      groupFlag: groupFlag,
      bsCheckFlag: bsCheckFlag,
      description: description,
      updateTime: updateTime
    });
    setBSCheck(bsCheckFlag);
    openUpdateFirmware();
  };


  const detailClick = (version, swCode, fileName,
    downloadUrl, fileSize, fileCrc, dataCrc, fwRegisterAt,
    activeFlag, forceFlag, groupFlag, bsCheckFlag, description, updateTime, lastModifyAt, releaseType) => {
    setSelectedFirmware({
      ...selectedFirmware,
      version: version,
      swCode: swCode,
      fileName: fileName,
      downloadUrl: downloadUrl,
      fileSize: fileSize,
      fileCrc: fileCrc,
      dataCrc: dataCrc,
      fwRegisterAt: fwRegisterAt,
      activeFlag: activeFlag,
      forceFlag: forceFlag,
      groupFlag: groupFlag,
      bsCheckFlag: bsCheckFlag,
      description: description,
      updateTime: updateTime,
      lastModifyAt: lastModifyAt,
      releaseType: releaseType
    });
    openDetailFirmware();
  };

  const groupClick = (version, swCode, releaseType, fileName,
    downloadUrl, fileSize, fileCrc, fwRegisterAt,
    activeFlag, forceFlag, groupFlag, bsCheckFlag, description) => {
    setSelectedFirmware({
      ...selectedFirmware,
      version: version,
      swCode: swCode,
      releaseType: releaseType,
      fileName: fileName,
      downloadUrl: downloadUrl,
      fileSize: fileSize,
      fileCrc: fileCrc,
      fwRegisterAt: fwRegisterAt,
      activeFlag: activeFlag,
      forceFlag: forceFlag,
      groupFlag: groupFlag,
      bsCheckFlag: bsCheckFlag,
      description: description
    });
    openGroupOTADialog()
  };

  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color={'primary'} />
      </Backdrop>
      <Alert className={classes.alert} severity={'error'}>
        <span style={{ color: 'red' }}>{STRING_ALERT_FW_LIST}</span>
        <div />
        <span style={{ color: 'blue' }}>{STRING_CLICK_FILENAME}</span>
      </Alert>
      <Card >
        <CardContent className={classes.content}>
          <div className={classes.inner} style={{ overflowX: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align={'center'}><span><b>{STRING_FW_VERSION}</b></span></TableCell>
                  <TableCell align={'center'}><span><b>{STRING_SW_CODE}</b></span></TableCell>
                  <TableCell align={'center'}><span><b>{STRING_FILE_SIZE_BYTES}</b></span></TableCell>
                  <TableCell align={'center'}><span><b>{STRING_FIRMWARE_TYPE}</b></span></TableCell>
                  <TableCell align={'center'}><span><b>{STRING_FILE_NAME}</b></span></TableCell>
                  <TableCell align={'center'}><span><b>{STRING_REGISTER_AT}</b></span></TableCell>
                  <TableCell align={'center'}><span><b>{STRING_LAST_MODIFY_AT}</b></span></TableCell>
                  {firmwareType === 'firmwareOfficial' && <TableCell align={'center'}><span><b>{STRING_ACTIVE}</b></span></TableCell>}
                  {firmwareType === 'firmwareOfficial' && <TableCell align={'center'}><span><b>{STRING_FORCE}</b></span></TableCell>}
                  {firmwareType === 'firmwareOfficial' && <TableCell align={'center'}><span><b>{STRING_GROUP}</b></span></TableCell>}
                  {firmwareType === 'firmwareOfficial' && <TableCell align={'center'}><span><b>{STRING_BS_CHECK}</b></span></TableCell>}
                  <TableCell align={'center'}><span><b>{STRING_UPDATE}</b></span></TableCell>
                  <TableCell align={'center'} style={{ width: '6%' }}><span><b>{STRING_SHOW_DETAIL}</b></span></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {firmwares !== null && firmwares.fwList !== undefined && firmwares.fwList.slice((page * rowsPerPage), ((page + 1) * rowsPerPage)).map(firmware => (
                  <TableRow
                    hover
                    key={firmware.downloadUrl}
                  >
                    <TableCell align={'center'}>
                      {firmware.version ? 'V' + parseInt(firmware.version.substr(0, 2), 16) + '-' + parseInt(firmware.version.substr(2, 2), 16) : '-'}
                    </TableCell>
                    <TableCell align={'center'}>
                      {firmware.swCode ? '0x' + firmware.swCode : '-'}
                    </TableCell>
                    <TableCell align={'center'}>
                      {firmware.fileSize ? firmware.fileSize : '-'}
                    </TableCell>
                    <TableCell align={'center'}>
                      {firmware.releaseType ? firmware.releaseType : '-'}
                    </TableCell>
                    <TableCell align={'center'}>
                      <Button style={{ textTransform: 'none', color: 'darkblue' }} onClick={() => openDownloadUrl(firmware.downloadUrl)}>
                        {firmware.fileName ? firmware.fileName : '-'}
                      </Button>
                    </TableCell>
                    <TableCell align={'center'}>
                      {firmware.registerAt ? moment((firmware.registerAt - 9 * 60 * 60) * 1000).format(STRING_DATE_FORMAT_INCLUDE_SEC) : '-'}
                    </TableCell>
                    <TableCell align={'center'}>
                      {firmware.lastModifyAt ? moment((firmware.lastModifyAt - 9 * 60 * 60) * 1000).format(STRING_DATE_FORMAT_INCLUDE_SEC) : '-'}
                    </TableCell>
                    {firmware.releaseType === "OF" &&
                      <TableCell align={'center'}>
                        {firmware.activeFlag === true ? <FiberManualRecordIcon style={{ color: green[500] }} /> : <FiberManualRecordIcon style={{ color: blueGrey[100] }} />}
                      </TableCell>
                    }
                    {(firmwareType === 'firmwareOfficial' && firmware.releaseType === "OS") &&
                      <TableCell align={'center'} />
                    }
                    {(firmwareType === 'firmwareOfficial' && firmware.releaseType === "OF") &&
                      <TableCell align={'center'}>
                        {firmware.forceFlag === true ? <FiberManualRecordIcon style={{ color: green[500] }} /> : <FiberManualRecordIcon style={{ color: blueGrey[100] }} />}
                      </TableCell>
                    }
                    {(firmwareType === 'firmwareOfficial' && firmware.releaseType === "OS") &&
                      <TableCell align={'center'} />
                    }
                    {(firmwareType === 'firmwareOfficial' && firmware.releaseType === "OF") &&
                      <TableCell align={'center'}>
                        <Tooltip title={STRING_GROUP}>
                          <div>
                            {(firmwareType === 'firmwareOfficial' && firmware.releaseType === "OF") && firmware.groupFlag === true ?
                              <IconButton aria-label={STRING_GROUP}
                                style={{ color: green[500] }}
                                onClick={() => groupClick(
                                  firmware.version,
                                  firmware.swCode,
                                  firmware.releaseType,
                                  firmware.fileName,
                                  firmware.downloadUrl,
                                  firmware.fileSize,
                                  firmware.fileCrc,
                                  firmware.registerAt,
                                  firmware.activeFlag,
                                  firmware.forceFlag,
                                  firmware.groupFlag,
                                  firmware.bsCheckFlag,
                                  firmware.description
                                )}
                              >
                                <GroupAddIcon />
                              </IconButton>
                              :
                              (firmwareType === 'firmwareOfficial' && firmware.releaseType === "OF") &&
                              <IconButton aria-label={STRING_GROUP}
                                style={{ color: blueGrey[100] }}
                                disabled
                              >
                                <GroupAddIcon />
                              </IconButton>
                            }
                          </div>
                        </Tooltip>
                      </TableCell>
                    }
                    {(firmwareType === 'firmwareOfficial' && firmware.releaseType === "OS") &&
                      <TableCell align={'center'} />
                    }
                    {/*BS CHECK */}
                    {(firmwareType === 'firmwareOfficial' && firmware.releaseType === "OF") &&
                      <TableCell align={'center'} />
                    }
                    {(firmwareType === 'firmwareOfficial' && firmware.releaseType === "OS") &&
                      <TableCell align={'center'}>
                        {firmware.bsCheckFlag === true ? <FiberManualRecordIcon style={{ color: green[500] }} /> : <FiberManualRecordIcon style={{ color: blueGrey[100] }} />}
                      </TableCell>
                    }
                    {((firmware.releaseType === "OF" && firmwareType === 'firmwareOfficial') || (firmware.releaseType !== "OF" && firmwareType === 'firmwareBS')) &&
                      <TableCell align={'center'}>
                        <Tooltip title={STRING_UPDATE}>
                          <div>
                            <IconButton aria-label={STRING_UPDATE}
                              onClick={() => updateClick(
                                firmware.version,
                                firmware.swCode,
                                firmware.activeFlag,
                                firmware.forceFlag,
                                firmware.groupFlag,
                                firmware.bsCheckFlag,
                                firmware.description,
                                firmware.updateTime
                              )}>
                              <BorderColorIcon />
                            </IconButton>
                          </div>
                        </Tooltip>
                      </TableCell>
                    }
                    {(firmwareType === 'firmwareOfficial' && firmware.releaseType === "OS") &&
                      <TableCell align={'center'} />
                    }
                    {((firmware.releaseType === "OF" && firmwareType === 'firmwareOfficial') || (firmware.releaseType !== "OF" && firmwareType === 'firmwareBS')) &&
                      <TableCell align={'center'}>
                        <Tooltip title={STRING_SHOW_DETAIL}>
                          <div>
                            <IconButton aria-label={STRING_SHOW_DETAIL}
                              onClick={() => detailClick(
                                firmware.version,
                                firmware.swCode,
                                firmware.fileName,
                                firmware.downloadUrl,
                                firmware.fileSize,
                                firmware.fileCrc,
                                firmware.dataCrc,
                                firmware.registerAt,
                                firmware.activeFlag,
                                firmware.forceFlag,
                                firmware.groupFlag,
                                firmware.bsCheckFlag,
                                firmware.description,
                                firmware.updateTime,
                                firmware.lastModifyAt,
                                firmware.releaseType
                              )}>
                              <ZoomInTwoToneIcon />
                            </IconButton>
                          </div>
                        </Tooltip>
                      </TableCell>
                    }
                    {(firmwareType === 'firmwareOfficial' && firmware.releaseType === "OS") &&
                      <TableCell align={'center'} />
                    }
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </CardContent>
        <CardActions className={classes.actions}>
          {firmwares !== null && firmwares.fwList !== undefined &&
            <TablePagination
              component="div"
              count={firmwares.fwList !== null && firmwares.fwList !== undefined && firmwares.fwList.length}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[5, 10, 25]}
            />}
          <Box m={3}
            display="flex"
            justifyContent="flex-end"
          >
            <Button className={classes.backButton}
              onClick={clickBack}>
              Back
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={openAddFirmware}
            >
              Add firmware
            </Button>
          </Box>
        </CardActions>
      </Card >
      <AddFirmwareDialog open={addFWDialogOpen} handleClose={closeAddFirmware} refetch={tryRefetch} firmwareType={firmwareType} swCodeMasking={swCodeMasking}
        product={product} modelName={modelName} activeCheck={active} />
      <UpdateFirmwareDialog open={updateFWDialogOpen} handleClose={closeUpdateFirmware} refetch={tryRefetch} selectedFW={selectedFirmware} firmwareType={firmwareType}
        product={product} modelName={modelName} activeCheck={active} bsCheck={bsCheck} />
      <DetailFirmwareDialog open={detailFWDialogOpen} handleClose={closeDetailFirmware} refetch={tryRefetch} selectedFW={selectedFirmware} firmwareType={firmwareType} />
      <GroupOTADialog open={groupOTADialogOpen} handleClose={closeGroupOTADialog} refetch={tryRefetch} selectedFW={selectedFirmware} firmwareType={firmwareType} />
    </div >
  );
};

export default FirmwareList;
