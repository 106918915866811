import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import useAsync from '../../../../../asyncNet';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
} from '@material-ui/core';
import { naviwatchGetRoles, naviwatchGetUserCount } from '../../../../Common/APIs/naviwatchApis';

const useStyles = makeStyles((theme) => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  },
  title: {
    fontWeight: 700
  },
  subtitle:{
    marginTop: theme.spacing(2),
  }
}));
const STRING_SEARCH = '검색';
const STRING_TITLE = 'Naviwatch 사용자 검색';
const STRING_EMAIL_LABEL = '사용자 EMAIL';
const STRING_NAME_LABEL = '사용자 이름';
const STRING_ROLE_LABEL = '역할';

const STRING_USER_COUNT = 'Naviwatch 사용자수는 ';
const STRING_USER_COUNT2 = '기준 ';

const STRING_USER_COUNT3 = '명 입니다.';
const STRING_SEARCH_CLEAN = '초기화';
const STRING_SEARCH_DELETE_USER = '삭제된 유저 조회';


const NaviUsersToolbar = props => {
  const { className, callbackFunc, ...rest } = props;

  const classes = useStyles();
  const [roles, tryRoleRefetch] = useAsync(getProductRoles, [], false);
  const [naviUserCnt, tryUserCntRefetch] = useAsync(getNaviwatchUserCount, [], false);

  const [roleMap, setRoleMap] = useState([]);
  const { data: productRoles } = roles;
  const { data: userCount } = naviUserCnt;


  async function getProductRoles() {
    return naviwatchGetRoles(localStorage.getItem("productID"));
  }

  async function getNaviwatchUserCount() {
    return naviwatchGetUserCount();
  }

  const today = new Date().toLocaleDateString();

  const [values, setValues] = useState({
    useremail: '',
    username: '',
    roleId: ''
  });

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  useEffect(()=>{
    if(productRoles!==null){
      var roles = productRoles.data;
      roles.unshift({
        roleId: '',
        roleName: ''
      });
      setRoleMap(roles)
    }
  }, [productRoles]);


  const cleanSearchInput = () =>{
    setValues({
      useremail: '',
      username: '',
      roleId: '',
    });
  };

  const searchDeletedUser = () =>{
    return true;
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        title={STRING_TITLE}
        subheader={
          <div className={classes.subtitle}>
          <span>{STRING_USER_COUNT +today+STRING_USER_COUNT2 + (userCount === null ? 0 : userCount.data.userCount) + STRING_USER_COUNT3}<br/></span>
          </div>
        }
      />
      <Divider/>
      <CardContent>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            md={4}
            xs={12}
          >
            <TextField
              fullWidth
              label={STRING_EMAIL_LABEL}
              margin="dense"
              name="useremail"
              type='email'
              onChange={handleChange}
              value={values.useremail}
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            md={4}
            xs={12}
          >
            <TextField
              fullWidth
              label={STRING_NAME_LABEL}
              margin="dense"
              name="username"
              onChange={handleChange}
              value={values.username}
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            md={4}
            xs={12}
          >
            <TextField
              fullWidth
              label={STRING_ROLE_LABEL}
              margin="dense"
              name="roleId"
              onChange={handleChange}
              select
              SelectProps={{ native: true }}
              value={values.roleId}
              variant="outlined"
            >
              {roleMap.length>0&&roleMap.map(role => (
                <option
                  key={role.roleId}
                  value={role.roleId}
                >
                  {role.roleName}
                </option>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </CardContent>
      <Divider/>
      <CardActions className={classes.actions}>
        <Button
          color="primary"
          variant="contained"
          onClick={cleanSearchInput}
          disabled={values.useremail===""&& values.username==="" && values.roleId===""}
        >
          {STRING_SEARCH_CLEAN}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={()=>callbackFunc(values, false)}
          disabled={values.username!==""||values.roleId!==""}
        >
          {STRING_SEARCH_DELETE_USER}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={()=>callbackFunc(values, true)}
        >
          {STRING_SEARCH}
        </Button>
      </CardActions>
    </Card>
  );
};

NaviUsersToolbar.propTypes = {
  className: PropTypes.string
};

export default NaviUsersToolbar;
