import { CSVLink } from 'react-csv';
import React, { useEffect, useRef, } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
}));

const headers = [
    { label: "사용자구분", key: "handlerType" },
    { label: "사용자ID", key: "handlerId" },
    { label: "접속시간", key: "registerAt" },
    { label: "접속 IP", key: "ip" },
    { label: "처리대상", key: "userId" },
    { label: "Naviwatch 메뉴", key: "menu" },
    { label: "수행내역", key: "feature" },
    { label: "변경필드값", key: "featureSub" },
];

const unixtimeToDatetime = async (excelData) => {
    for (var i = 0; i < excelData.length; i++) {
        var date = new Date(excelData[i].registerAt * 1000);
        excelData[i].registerAt = date.getFullYear() +
            "/" + (date.getMonth() + 1).toString().padStart(2, '0') +
            "/" + date.getDate().toString().padStart(2, '0') +
            " " + date.getHours().toString().padStart(2, '0') +
            ":" + date.getMinutes().toString().padStart(2, '0') +
            ":" + date.getSeconds().toString().padStart(2, '0');
    }
}

const ExcelDownload = props => {
    const { className, excelDownloadData, ...rest } = props;
    const classes = useStyles();

    const btnRef = useRef(null);
    useEffect(() =>
        btnRef.current?.click(),
        [btnRef, excelDownloadData]);

    unixtimeToDatetime(excelDownloadData);

    return (
        <div>
            <CSVLink data={excelDownloadData} filename="접속기록" headers={headers} target="_blank" >
                <span ref={btnRef} />
            </CSVLink>
        </div>
    );
};

ExcelDownload.propTypes = {
    className: PropTypes.string
};

export default ExcelDownload;
