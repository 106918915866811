import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/core/styles';

import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination
} from '@material-ui/core';

import useAsync from '../../../../../asyncNet';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import ZoomInTwoToneIcon from '@material-ui/icons/ZoomInTwoTone';
import DetailDialog from './DetailDialog';
import PushDialog from './PushDialog';
import PhonelinkRingOutlinedIcon from '@material-ui/icons/PhonelinkRingOutlined';
import { userDetailSearch } from '../../../APIs/aironeApis';
import { WRITE_PERMISSION } from '../../../../Common/Config/naviwatchConfig';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  deviceView: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  deviceDescriptView: {
    display: 'flex',
    flexDirection: 'column'
  },
  deviceDescriptDivider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));
const STRING_ID_LABEL = '사용자 ID';
const STRING_EMAIL_LABEL = '사용자 메일';
const STRING_NAME_LABEL = '사용자 이름';

const STRING_PUSH = '푸시 발송';
const STRING_DETAIL = '상세보기';



const UsersTable = props => {
  const { className, users, ip, ...rest } = props;

  const classes = useStyles();
  const [permission, setPermission] = useState(false);

  const [selectedUsers] = useState([]);
  const [choicedUser, setUserChoice] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [open, setOpen] = React.useState(false);
  const [pushOpen, setPushOpen] = React.useState(false);

  const getUserInfoDetail = () =>{
    return userDetailSearch(sessionStorage.getItem('email'), ip, choicedUser);
  };

  const [state, tryRefetch] = useAsync(getUserInfoDetail, [], true);
  const { data: userInfo } = state;

  useEffect(() => {
    console.log('useEffect');
    if (choicedUser !== '') {
      tryRefetch();
    }
  }, [choicedUser]);

  useEffect(() => {
    var pageInfo = sessionStorage.getItem('pages');
    var pageInfoObj = JSON.parse(pageInfo);
    var usersPageArray = pageInfoObj.filter(page=>page.name.toLowerCase().includes("user"));
    if(usersPageArray!==undefined && usersPageArray.length > 0) {
      var usersPage = usersPageArray[0];
      var usersPermission = usersPage.permission;
      if (usersPermission === WRITE_PERMISSION) {
        setPermission(true);
      }
    }
  }, []);

  const handleClickOpen = (user) => {
    console.log('handleClickOpen');
    setUserChoice(user);
    setOpen(true);
  };

  const handleClose = () => {
    setUserChoice('');
    setOpen(false);
  };


  const handlePushClickOpen = (user) => {
    console.log('handleClickOpen');
    setUserChoice(user);
    setPushOpen(true);
  };

  const handlePushClose = () => {
    setUserChoice('');
    setPushOpen(false);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  return (
    <div>
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align={'center'}><span><b>{STRING_ID_LABEL}</b></span></TableCell>
                    <TableCell align={'center'}><span><b>{STRING_NAME_LABEL}</b></span></TableCell>
                    <TableCell align={'center'}><span><b>{STRING_EMAIL_LABEL}</b></span></TableCell>
                    <TableCell align={'center'}><span><b>{STRING_DETAIL}</b></span></TableCell>
                    <TableCell align={'center'}><span><b>{STRING_PUSH}</b></span></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users !== null && users !== undefined && users.slice((page * rowsPerPage), ((page + 1) * rowsPerPage)).map(user => (
                    <TableRow
                      className={classes.tableRow}
                      hover
                      key={user.USERID}
                      selected={selectedUsers.indexOf(user.USERID) !== -1}
                    >
                      <TableCell align={'center'}>
                        {user.USERID}
                      </TableCell>
                      <TableCell align={'center'}>
                        {user.USERNAME}
                      </TableCell>
                      <TableCell align={'center'}>
                        {user.USEREMAIL}
                      </TableCell>
                      <TableCell align={'center'}>
                        <Tooltip title={STRING_DETAIL}>
                          <IconButton aria-label={STRING_DETAIL}
                                      onClick={() => handleClickOpen(user.USERID)}>
                            <ZoomInTwoToneIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                      <TableCell align={'center'}>
                        <Tooltip title={STRING_PUSH}>
                          <IconButton aria-label={STRING_PUSH}
                                      disabled={permission===false}
                                      onClick={() => handlePushClickOpen(user.USERID)}>
                            <PhonelinkRingOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={users !== undefined?users.length:0}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </CardActions>
      </Card>
      <DetailDialog open={open} handleClose={handleClose} userInfo={userInfo} rendering={tryRefetch}/>
      <PushDialog open={pushOpen} handleClose={handlePushClose} userId={userInfo!==null && userInfo!==undefined && userInfo.userdetail.USER_ID} />
    </div>
  );
};

UsersTable.propTypes = {
  className: PropTypes.string,
  users: PropTypes.array.isRequired
};

export default UsersTable;
