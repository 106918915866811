import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core/styles';
import palette from 'theme/palette';

import {
  Card,
  CardHeader,
  CardContent,
  Divider,
} from '@material-ui/core';

import useAsync from '../../../../../asyncNet';
import {
  statisticsOduOperationModeCount
} from '../../../APIs/aironeApis';

const STRING_WEEKLY_USER='사용자 설정 모드';
const STRING_MODE_STANDBY = '대기모드';
const STRING_MODE_AIR_CONDITIONING = '냉방모드';
const STRING_MODE_DEHUMIDIFY = '제습모드';
const STRING_MODE_GENERAL_VENTILATION = '일반환기모드';
const STRING_MODE_EXHAUST = '배기모드';
const STRING_MODE_COOKING = '요리모드';
const STRING_MODE_CLEANING = '청소모드';
const STRING_MODE_AIR_CLEANING = '공기청정';
const STRING_MODE_YELLOW_DUST_CLEANING = '황사청정';
const STRING_MODE_HEATING = '난방모드';
const STRING_MODE_HUMIDIFICATION = '가습모드';
const STRING_MODE_AUTOMATIC_OPERATION = '자동운전';
const STRING_MODE_BRONCHIAL_PROTECTION = '기관지보호운전';
const STRING_MODE_SKIN_PROTECTION = '피부보호운전';
const STRING_MODE_AIR_SUPPLY = '급기모드';
const STRING_COUNT = 'count';

const useStyles = makeStyles(() => ({
  height: '100%',
  card: {
    backgroundColor: '#fff9db'
  },
  chartContainer: {
    height: '100%',
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const options = {
  legend: {
    display: true,
    position: "right",
    align: "center",
    fontFamily: "Allianz-Neo",
    textDirection: 'ltr',
    labels: {
      usePointStyle: true,
      fontColor: "#006192",
    }
  }
};

const OduOperationModeChart = props => {

  const { className, ...rest } = props;
  const [state, tryRefetch] = useAsync(statisticsOduOperationModeCount, []);
  const [chartdata, setChartData] = useState({
    labels: [],
    datasets: []
  });
  const [chartLabel, setChartLabel] = useState([]);
  const [chartColor, setChartColor] = useState([]);
  const [chartValue, setChartValue] = useState([]);

  const { data: metric } = state;
  const classes = useStyles();

  const makeChartValue = (numeric, count) =>{
    let tempLabel = [];
    let tempValue = [];
    let tempColor = [];

    for (var i = 0; i < numeric.length; i++) {
      switch (parseInt(numeric[i])) {
        case 1:
          tempLabel.push(STRING_MODE_STANDBY);
          tempValue.push(count[i]);
          tempColor.push('#FFFACF');
          break;
        case 2:
          tempLabel.push(STRING_MODE_AIR_CONDITIONING);
          tempValue.push(count[i]);
          tempColor.push('#FFFAFD');
          break;
        case 3:
          tempLabel.push(STRING_MODE_DEHUMIDIFY);
          tempValue.push(count[i]);
          tempColor.push('#FFFFCD');
          break;
        case 4:
          tempLabel.push(STRING_MODE_GENERAL_VENTILATION);
          tempValue.push(count[i]);
          tempColor.push('#FF730F');
          break;
        case 5:
          tempLabel.push(STRING_MODE_EXHAUST);
          tempValue.push(count[i]);
          tempColor.push('#FFDACD');
          break;
        case 6:
/*
          tempLabel.push(STRING_MODE_COOKING);
          tempValue.push(count[i]);
          tempColor.push('#FDDACD');
 */
          break;
        case 7:
          tempLabel.push(STRING_MODE_CLEANING);
          tempValue.push(count[i]);
          tempColor.push('#FFFACD');
          break;
        case 8:
          tempLabel.push(STRING_MODE_AIR_CLEANING);
          tempValue.push(count[i]);
          tempColor.push('#FFDEAD');
          break;
        case 9:
          tempLabel.push(STRING_MODE_YELLOW_DUST_CLEANING);
          tempValue.push(count[i]);
          tempColor.push('#DFFACD');
          break;
        case 10:
          tempLabel.push(STRING_MODE_HEATING);
          tempValue.push(count[i]);
          tempColor.push('#FCCACD');
          break;
        case 11:
          tempLabel.push(STRING_MODE_HUMIDIFICATION);
          tempValue.push(count[i]);
          tempColor.push('#CFFACD');
          break;
        case 12:
          tempLabel.push(STRING_MODE_AUTOMATIC_OPERATION);
          tempValue.push(count[i]);
          tempColor.push('#FF8C00');
          break;
        case 13:
          tempLabel.push(STRING_MODE_BRONCHIAL_PROTECTION);
          tempValue.push(count[i]);
          tempColor.push('#AAAACD');
          break;
        case 14:
          tempLabel.push(STRING_MODE_SKIN_PROTECTION);
          tempValue.push(count[i]);
          tempColor.push('#BBBACD');
          break;
        case 15:
          tempLabel.push(STRING_MODE_AIR_SUPPLY);
          tempValue.push(count[i]);
          tempColor.push('#BBBBEB');
          break;
      }
    }
    setChartLabel(tempLabel);
    setChartValue(tempValue);
    setChartColor(tempColor);

  };

  useEffect(() => {
    try {
      setChartData({
          ...chartdata,
          labels: chartLabel,
          datasets: [
            {
              label: STRING_COUNT,
              borderWidth: 1,
              backgroundColor: chartColor,
              data: chartValue,
            }
          ]
        }
      );
    } catch (e) {
      console.log('metric update failed :' + JSON.stringify(e));
    }
  }, [chartLabel, chartValue]);

  useEffect(()=>{
    if (metric !== null && metric !== undefined) {
      console.log('update Odu Operation Mode: '+JSON.stringify(metric));
      makeChartValue(metric['operationMode'], metric['count']);
    }
  }, [metric]);


  return (
    <div>
      <Card
        {...rest}
        className={clsx(classes.root, className)}>
        <CardHeader
          title={STRING_WEEKLY_USER}
        />
        <Divider/>
        <CardContent>
          {chartdata !== undefined  &&
          <div className={classes.chartContainer}>
            <Doughnut
              data={chartdata}
              options={options}
            />
          </div>
          }
        </CardContent>
        <Divider/>
      </Card>
    </div>
  );
};

OduOperationModeChart.propTypes = {
  className: PropTypes.string
};

export default React.memo(OduOperationModeChart);
