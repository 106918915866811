import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination
} from '@material-ui/core';
import {
  STRING_ACCOUNT,
  STRING_DELETE_DATE
} from '../../../StringTable/StringTable';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  deviceView: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  deviceDescriptView: {
    display: 'flex',
    flexDirection: 'column'
  },
  deviceDescriptDivider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

const NaviDeletedUsersTable = props => {
  const { className, users, ip, ...rest } = props;
  const classes = useStyles();
  const [fullRoles, setFullRoles] = useState(undefined);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const getRoleName = (roleId) => {
    if (fullRoles !== undefined) {
      var role = fullRoles.filter(role => role.roleId === roleId);
      if (role.length > 0)
        return role[0].roleName;
    }
    return "-";
  };

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  useEffect(() => {
    var fullRoles = sessionStorage.getItem("fullRoles");
    var fullRolesObj = JSON.parse(fullRoles);
    setFullRoles(fullRolesObj);
  }, []);

  return (
    <div>
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align={'center'}><span><b>{STRING_ACCOUNT}</b></span></TableCell>
                    <TableCell align={'center'}><span><b>{STRING_DELETE_DATE}</b></span></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users !== null && users !== undefined && users.slice((page * rowsPerPage), ((page + 1) * rowsPerPage)).map(user => (
                    <TableRow
                      className={classes.tableRow}
                      hover
                      key={user.register}
                    >
                      <TableCell align={'center'}>
                        {user.userid}
                      </TableCell>
                      <TableCell align={'center'}>
                        {user.register}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={users !== undefined ? users.length : 0}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </CardActions>
      </Card>
    </div>
  );
};

NaviDeletedUsersTable.propTypes = {
  className: PropTypes.string,
  users: PropTypes.array.isRequired
};

export default NaviDeletedUsersTable;
