import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { RoomconToolbar, AirmonitorToolbar,MQTTRoomconToolbar, MQTTRoomconToolbar43 } from './components';
import useAsync from '../../../asyncNet';
import { firmwareModelInfo } from '../APIs/aironeApis';
import { firmwareModelInfoMqtt } from '../APIs/aironeApis';

import Grid from '@material-ui/core/Grid';
import { NO_PERMISSION, NO_PERMISSION_WARNING, READ_PERMISSION } from '../../Common/Config/naviwatchConfig';
import { Typography } from '@material-ui/core';
import { userInfoContext } from '../../../App';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  }
}));

const FirmwareModels = ({ history }) => {
  const classes = useStyles();

  const [state, tryRefetch] = useAsync(firmwareModelInfo, [], false);
  const { loading, data: model } = state;
  const [noPermissionMsg, setNoPermissionMsg] = useState("");
  const authInfo = useContext(userInfoContext);
  const [permission, setPermission] = useState(NO_PERMISSION);
  
  const [stateMqtt, tryRefetchMqtt] = useAsync(firmwareModelInfoMqtt, [], false);
  const { loadingMqtt, data: modelMqtt } = stateMqtt;
  const [noPermissionMsgMqtt, setNoPermissionMsgMqtt] = useState("");
  const authInfoMqtt = useContext(userInfoContext);
  const [permissionMqtt, setPermissionMqtt] = useState(NO_PERMISSION);

  useEffect(() => {
    var pageInfo = sessionStorage.getItem('pages');
    if (pageInfo !== "undefined") {
      var pageInfoObj = JSON.parse(pageInfo);
      var pageArray = pageInfoObj.filter(page => page.name.toLowerCase().includes("firmware"));
      if (pageArray !== undefined && pageArray.length > 0) {
        var page = pageArray[0];
        if (page.permission === undefined) {
          setNoPermissionMsg(NO_PERMISSION_WARNING);
          
        }
        setPermission(page.permission);
        
      }
    }
  }, [authInfo.pages]);

  
  useEffect(() => {
    if (sessionStorage.getItem('naviwatch_permission') !== 'true')
      history.push('/');
  }, []);

  return (
    <div>
      {permission >= READ_PERMISSION ?
        <div className={classes.root}>
          <Grid
            container
            spacing={4}
          >

            <Grid
              item
              lg={12}
              sm={12}
              xl={12}
              xs={12}
            >
              <MQTTRoomconToolbar roomcons={modelMqtt === null ? null : modelMqtt.mqttrc_model} />
            </Grid>

            <Grid
              item
              lg={12}
              sm={12}
              xl={12}
              xs={12}
            >
              <MQTTRoomconToolbar43 roomcons={modelMqtt === null ? null : modelMqtt.mqttrc_model43} />
            </Grid>

            <Grid
              item
              lg={12}
              sm={12}
              xl={12}
              xs={12}
            >
              <RoomconToolbar roomcons={model === null ? null : model.rc_model} />
            </Grid>
            <Grid
              item
              lg={12}
              sm={12}
              xl={12}
              xs={12}
            >
              <AirmonitorToolbar airmonitors={model === null ? null : model.am_model} />
            </Grid>
            
          </Grid>
        
        </div>:
        <Typography className={classes.root}>{noPermissionMsg}</Typography>
      }

    </div>
  );
};

export default FirmwareModels;
