import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { PushToolbar, PushDialog } from './components';
import useAsync from '../../../asyncNet';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { pushSendPush } from '../APIs/mateApis';
import { userInfoContext } from '../../../App';
import { NO_PERMISSION, NO_PERMISSION_WARNING, READ_PERMISSION } from '../../Common/Config/naviwatchConfig';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  }
}));


const STRING_MODEL_580 = 'EQM-580';
const STRING_MODEL_ALL = '전체모델';
const STRING_MODEL_EME_501 = 'EME-501';
const STRING_MODEL_582 = 'EQM-582';
const STRING_SUCCESS_PUSH_TITLE='푸시 발송 성공';
const STRING_SUCCESS_PUSH_CONTENT='푸시 발송이 성공적으로 완료되었습니다.';
const STRING_FAIL_PUSH_TITLE='푸시 발송 실패';
const STRING_FAIL_PUSH_CONTENT='푸시 발송이 실패하였습니다.';


const Push = ({history}) => {
  const classes = useStyles();
  const [state, tryRefetch] = useAsync(sendPush, [], true);
  const { loading } = state;
  const [pushCond, setPushCond] = useState({
    modelCode: '',
    title: '',
    content: ''
  });
  const [dialogString, setDialogString] = useState({
    title:'',
    content:''
  });

  const [open, setOpen] = React.useState(false);
  const authInfo =  useContext(userInfoContext);
  const [noPermissionMsg, setNoPermissionMsg] = useState("");
  const [permission, setPermission] = useState(NO_PERMISSION);
  useEffect(()=>{
    var pageInfo = sessionStorage.getItem('pages');
    if(pageInfo!=="undefined") {
      var pageInfoObj = JSON.parse(pageInfo);
      var pageArray = pageInfoObj.filter(page => page.name.toLowerCase().includes("push"));
      if (pageArray !== undefined && pageArray.length > 0) {
        var page = pageArray[0];
        if(page.permission===undefined){
          setNoPermissionMsg(NO_PERMISSION_WARNING);
        }
        setPermission(page.permission);
      }
    }
  }, [authInfo.pages]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  async function sendPush(){
    const response = await pushSendPush(pushCond.modelCode, pushCond.title, pushCond.content)
    console.log('response :'+JSON.stringify(response));
    if (response.data.code===200){
      setDialogString({
        title: STRING_SUCCESS_PUSH_TITLE,
        content: STRING_SUCCESS_PUSH_CONTENT
      });
    }else {
      setDialogString({
        title: STRING_FAIL_PUSH_TITLE,
        content: STRING_FAIL_PUSH_CONTENT
      });
    }
    handleClickOpen();
    setPushCond({
      modelCode: '',
      title: '',
      content: ''
    });
    return response.data;
  }

  const pushCondition = (pushInfo) =>{
    console.log("call push "+JSON.stringify(pushInfo));
    var modelNumber='';
    if (pushInfo.model === STRING_MODEL_580) {
      modelNumber = 2;
    } else if (pushInfo.model === STRING_MODEL_ALL) {
      modelNumber = 0;
    } else if (pushInfo.model === STRING_MODEL_EME_501) {
      modelNumber = 257  ;
    } else if (pushInfo.model === STRING_MODEL_582) {
      modelNumber = 4;
    } 
    setPushCond(
      {
        modelCode: modelNumber,
        title: pushInfo.title,
        content: pushInfo.content,
      });
  };

  useEffect(() => {
    console.log('useEffect -- modelcode' + pushCond.modelCode);
    if (pushCond.modelCode !== '' && pushCond.title !== '' && pushCond.content !== '') {
      tryRefetch();
    }
  }, [pushCond]);


  useEffect(() => {
    if (sessionStorage.getItem('naviwatch_permission') !== 'true')
      history.push('/');
  }, []);


  return (
    <div>
      { permission >= READ_PERMISSION ?
        <div className={classes.root}>
          <Backdrop className={classes.backdrop} open={loading} >
            <CircularProgress color={'primary'}/>
          </Backdrop>
          <PushToolbar callbackFunc={pushCondition}/>
          <PushDialog open={open} handleClose={handleClose} dialogString={dialogString}/>
        </div>:
        <Typography className={classes.root}>{noPermissionMsg}</Typography>
      }
    </div>
  );
};

export default Push;
