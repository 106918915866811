import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  Box,
  Typography
} from '@material-ui/core';

import ReactApexChart from 'react-apexcharts';
import moment from 'moment';
const STRING_TITLE = 'AI수면 통계';

const UpSleepStatisticsChart = props => {
  const { data } = props;

  const sessionTotalInform = useMemo(() => {
    return data.reduce(
      (result, current) => {
        result.totalSuccessCount +=
          current.statistics.sessionCount.aiSleep.success +
          current.statistics.sessionCount.sensing.success +
          current.statistics.sessionCount.aiSleep.neverSlept +
          current.statistics.sessionCount.sensing.neverSlept +
          current.statistics.sessionCount.aiSleep.tooLong +
          current.statistics.sessionCount.sensing.tooLong;
        result.sensingSuccessCount +=
          current.statistics.sessionCount.sensing.success +
          current.statistics.sessionCount.sensing.neverSlept +
          current.statistics.sessionCount.sensing.tooLong;
        result.aiSleepSuccessCount +=
          current.statistics.sessionCount.aiSleep.success +
          current.statistics.sessionCount.aiSleep.neverSlept +
          current.statistics.sessionCount.aiSleep.tooLong;
        result.totalFailCount +=
          current.statistics.sessionCount.aiSleep.tooMany +
          current.statistics.sessionCount.sensing.tooMany +
          current.statistics.sessionCount.aiSleep.tooShort +
          current.statistics.sessionCount.sensing.tooShort;
        result.aiSleepFailCount +=
          current.statistics.sessionCount.aiSleep.tooMany +
          current.statistics.sessionCount.aiSleep.tooShort;
        result.sensingFailCount +=
          current.statistics.sessionCount.sensing.tooMany +
          current.statistics.sessionCount.sensing.tooShort;
        result.totalTooManyCount +=
          current.statistics.sessionCount.aiSleep.tooMany +
          current.statistics.sessionCount.sensing.tooMany;
        result.totalTooShortCount +=
          current.statistics.sessionCount.aiSleep.tooShort +
          current.statistics.sessionCount.sensing.tooShort;
        result.totalNeverSleptCount +=
          current.statistics.sessionCount.aiSleep.neverSlept +
          current.statistics.sessionCount.sensing.neverSlept;
        result.totalTooLongCount +=
          current.statistics.sessionCount.aiSleep.tooLong +
          current.statistics.sessionCount.sensing.tooLong;
        result.aiSleepReportCount +=
          current.statistics.sessionCount.reportCount.aiSleep;
        result.sensingNormalReportCount +=
          current.statistics.sessionCount.reportCount.sensing.normal;
        result.sensingOffReportCount +=
          current.statistics.sessionCount.reportCount.sensing.off;
        result.sensingSleepReportCount +=
          current.statistics.sessionCount.reportCount.sensing.sleep;
        result.sensingCustomReportCount +=
          current.statistics.sessionCount.reportCount.sensing.custom;
        result.totalHourSuccessList.push({
          x: new Date(current.timestamp * 1000 + 32400000),
          y:
            current.statistics.sessionCount.aiSleep.success +
            current.statistics.sessionCount.sensing.success +
            current.statistics.sessionCount.aiSleep.neverSlept +
            current.statistics.sessionCount.sensing.neverSlept +
            current.statistics.sessionCount.aiSleep.tooLong +
            current.statistics.sessionCount.sensing.tooLong
        });
        result.sensingHourSuccessList.push({
          x: new Date(current.timestamp * 1000 + 32400000),
          y:
            current.statistics.sessionCount.sensing.success +
            current.statistics.sessionCount.sensing.neverSlept +
            current.statistics.sessionCount.sensing.tooLong
        });
        result.aiSleepHourSuccessList.push({
          x: new Date(current.timestamp * 1000 + 32400000),
          y:
            current.statistics.sessionCount.aiSleep.success +
            current.statistics.sessionCount.aiSleep.neverSlept +
            current.statistics.sessionCount.aiSleep.tooLong
        });

        return result;
      },
      {
        sensingSuccessCount: 0,
        aiSleepSuccessCount: 0,
        totalSuccessCount: 0,
        totalFailCount: 0,
        aiSleepFailCount: 0,
        sensingFailCount: 0,
        totalNeverSleptCount: 0,
        totalTooLongCount: 0,
        totalTooManyCount: 0,
        totalTooShortCount: 0,
        aiSleepReportCount: 0,
        sensingNormalReportCount: 0,
        sensingOffReportCount: 0,
        sensingSleepReportCount: 0,
        sensingCustomReportCount: 0,
        totalHourSuccessList: [],
        sensingHourSuccessList: [],
        aiSleepHourSuccessList: []
      }
    );
  }, [data, data.length]);

  const daySuccessInform = useMemo(() => {
    let x = '';
    return data.reduce(
      (result, current, index) => {
        if (x == 0) {
          x = new Date(current.timestamp * 1000)
            .toLocaleString('en-US', {
              timeZone: 'UTC'
            })
            .split(',')[0];
          result.dayTotalSuccessCount +=
            current.statistics.sessionCount.aiSleep.success +
            current.statistics.sessionCount.sensing.success +
            current.statistics.sessionCount.aiSleep.neverSlept +
            current.statistics.sessionCount.sensing.neverSlept +
            current.statistics.sessionCount.aiSleep.tooLong +
            current.statistics.sessionCount.sensing.tooLong;
          result.daySensingSuccessCount +=
            current.statistics.sessionCount.sensing.success +
            current.statistics.sessionCount.sensing.neverSlept +
            current.statistics.sessionCount.sensing.tooLong;
          result.dayAiSleepSuccessCount +=
            current.statistics.sessionCount.aiSleep.success +
            current.statistics.sessionCount.aiSleep.neverSlept +
            current.statistics.sessionCount.aiSleep.tooLong;
        } else if (
          x ==
          new Date(current.timestamp * 1000)
            .toLocaleString('en-US', {
              timeZone: 'UTC'
            })
            .split(',')[0]
        ) {
          result.dayTotalSuccessCount +=
            current.statistics.sessionCount.aiSleep.success +
            current.statistics.sessionCount.sensing.success +
            current.statistics.sessionCount.aiSleep.neverSlept +
            current.statistics.sessionCount.sensing.neverSlept +
            current.statistics.sessionCount.aiSleep.tooLong +
            current.statistics.sessionCount.sensing.tooLong;
          result.daySensingSuccessCount +=
            current.statistics.sessionCount.sensing.success +
            current.statistics.sessionCount.sensing.neverSlept +
            current.statistics.sessionCount.sensing.tooLong;
          result.dayAiSleepSuccessCount +=
            current.statistics.sessionCount.aiSleep.success +
            current.statistics.sessionCount.aiSleep.neverSlept +
            current.statistics.sessionCount.aiSleep.tooLong;
        } else if (
          x !=
          new Date(current.timestamp * 1000)
            .toLocaleString('en-US', {
              timeZone: 'UTC'
            })
            .split(',')[0]
        ) {
          result.totalDaySuccessList.push({
            x,
            y: result.dayTotalSuccessCount
          });
          result.sensingDaySuccessList.push({
            x,
            y: result.daySensingSuccessCount
          });
          result.aiSleepDaySuccessList.push({
            x,
            y: result.dayAiSleepSuccessCount
          });
          x = new Date(current.timestamp * 1000)
            .toLocaleString('en-US', {
              timeZone: 'UTC'
            })
            .split(',')[0];
          result.dayTotalSuccessCount =
            current.statistics.sessionCount.aiSleep.success +
            current.statistics.sessionCount.sensing.success +
            current.statistics.sessionCount.aiSleep.neverSlept +
            current.statistics.sessionCount.sensing.neverSlept +
            current.statistics.sessionCount.aiSleep.tooLong +
            current.statistics.sessionCount.sensing.tooLong;
          result.daySensingSuccessCount =
            current.statistics.sessionCount.sensing.success +
            current.statistics.sessionCount.sensing.neverSlept +
            current.statistics.sessionCount.sensing.tooLong;
          result.dayAiSleepSuccessCount =
            current.statistics.sessionCount.aiSleep.success +
            current.statistics.sessionCount.aiSleep.neverSlept +
            current.statistics.sessionCount.aiSleep.tooLong;
        }
        if (index == data.length - 1) {
          result.totalDaySuccessList.push({
            x,
            y: result.dayTotalSuccessCount
          });
          result.sensingDaySuccessList.push({
            x,
            y: result.daySensingSuccessCount
          });
          result.aiSleepDaySuccessList.push({
            x,
            y: result.dayAiSleepSuccessCount
          });
        }
        return result;
      },
      {
        dayTotalSuccessCount: 0,
        daySensingSuccessCount: 0,
        dayAiSleepSuccessCount: 0,
        totalDaySuccessList: [],
        sensingDaySuccessList: [],
        aiSleepDaySuccessList: []
      }
    );
  }, [data, data.length]);

  const averageInform = useMemo(() => {
    return data.reduce(
      (result, current, index) => {
        result.sleepIndex.aiSleep += current.statistics.sleepIndex.aiSleep;
        result.sleepIndex.sensing.total +=
          current.statistics.sleepIndex.sensing.total;
        result.sleepIndex.sensing.normal +=
          current.statistics.sleepIndex.sensing.normal;
        result.sleepIndex.sensing.off +=
          current.statistics.sleepIndex.sensing.off;
        result.sleepIndex.sensing.sleep +=
          current.statistics.sleepIndex.sensing.sleep;
        result.sleepIndex.sensing.custom +=
          current.statistics.sleepIndex.sensing.custom;
        result.setTemp.aiSleep += current.statistics.setTemp.aiSleep;
        result.setTemp.sensing.total +=
          current.statistics.setTemp.sensing.total;
        result.setTemp.sensing.normal +=
          current.statistics.setTemp.sensing.normal;
        result.setTemp.sensing.off += current.statistics.setTemp.sensing.off;
        result.setTemp.sensing.sleep +=
          current.statistics.setTemp.sensing.sleep;
        result.setTemp.sensing.custom +=
          current.statistics.setTemp.sensing.custom;
        result.sleepRatio.aiSleep.rem +=
          current.statistics.sleepRatio.aiSleep.rem;
        result.sleepRatio.aiSleep.wake +=
          current.statistics.sleepRatio.aiSleep.wake;
        result.sleepRatio.aiSleep.deep +=
          current.statistics.sleepRatio.aiSleep.deep;
        result.sleepRatio.aiSleep.light +=
          current.statistics.sleepRatio.aiSleep.light;
        result.sleepRatio.sensing.total.rem +=
          current.statistics.sleepRatio.sensing.total.rem;
        result.sleepRatio.sensing.total.wake +=
          current.statistics.sleepRatio.sensing.total.wake;
        result.sleepRatio.sensing.total.deep +=
          current.statistics.sleepRatio.sensing.total.deep;
        result.sleepRatio.sensing.total.light +=
          current.statistics.sleepRatio.sensing.total.light;
        result.sleepRatio.sensing.normal.rem +=
          current.statistics.sleepRatio.sensing.normal.rem;
        result.sleepRatio.sensing.normal.wake +=
          current.statistics.sleepRatio.sensing.normal.wake;
        result.sleepRatio.sensing.normal.deep +=
          current.statistics.sleepRatio.sensing.normal.deep;
        result.sleepRatio.sensing.normal.light +=
          current.statistics.sleepRatio.sensing.normal.light;
        result.sleepRatio.sensing.off.rem +=
          current.statistics.sleepRatio.sensing.off.rem;
        result.sleepRatio.sensing.off.wake +=
          current.statistics.sleepRatio.sensing.off.wake;
        result.sleepRatio.sensing.off.deep +=
          current.statistics.sleepRatio.sensing.off.deep;
        result.sleepRatio.sensing.off.light +=
          current.statistics.sleepRatio.sensing.off.light;
        result.sleepRatio.sensing.sleep.rem +=
          current.statistics.sleepRatio.sensing.sleep.rem;
        result.sleepRatio.sensing.sleep.wake +=
          current.statistics.sleepRatio.sensing.sleep.wake;
        result.sleepRatio.sensing.sleep.deep +=
          current.statistics.sleepRatio.sensing.sleep.deep;
        result.sleepRatio.sensing.sleep.light +=
          current.statistics.sleepRatio.sensing.sleep.light;
        result.sleepRatio.sensing.custom.rem +=
          current.statistics.sleepRatio.sensing.custom.rem;
        result.sleepRatio.sensing.custom.wake +=
          current.statistics.sleepRatio.sensing.custom.wake;
        result.sleepRatio.sensing.custom.deep +=
          current.statistics.sleepRatio.sensing.custom.deep;
        result.sleepRatio.sensing.custom.light +=
          current.statistics.sleepRatio.sensing.custom.light;
        return result;
      },
      {
        sleepIndex: {
          aiSleep: 0,
          sensing: {
            total: 0,
            normal: 0,
            off: 0,
            sleep: 0,
            custom: 0
          }
        },
        setTemp: {
          aiSleep: 0,
          sensing: {
            total: 0,
            normal: 0,
            off: 0,
            sleep: 0,
            custom: 0
          }
        },
        sleepRatio: {
          aiSleep: {
            rem: 0,
            wake: 0,
            deep: 0,
            light: 0
          },
          sensing: {
            total: {
              rem: 0,
              wake: 0,
              deep: 0,
              light: 0
            },
            normal: {
              rem: 0,
              wake: 0,
              deep: 0,
              light: 0
            },
            off: {
              rem: 0,
              wake: 0,
              deep: 0,
              light: 0
            },
            sleep: {
              rem: 0,
              wake: 0,
              deep: 0,
              light: 0
            },
            custom: {
              rem: 0,
              wake: 0,
              deep: 0,
              light: 0
            }
          }
        }
      }
    );
  }, [data, data.length]);

  const lineChartDayState = useMemo(() => {
    return {
      series: [
        {
          name: '총 과금 개수',
          data: daySuccessInform.totalDaySuccessList
        },
        {
          name: 'AI수면 과금 개수',
          data: daySuccessInform.aiSleepDaySuccessList
        },
        {
          name: '센싱 과금 개수',
          data: daySuccessInform.sensingDaySuccessList
        }
      ],
      options: {
        title: {
          text: '일자별 성공 세션 추이'
        },
        chart: {
          id: 'chart2',
          type: 'stepline',
          height: 500,
          toolbar: {
            autoSelected: 'pan',
            show: false
          }
        },
        colors: ['#008FFB', '#ff9b0f', '#0e3173'],
        stroke: {
          curve: 'stepline',
          width: [1, 4, 3, 1]
        },
        dataLabels: {
          enabled: false
        },
        fill: {
          opacity: 1
        },
        markers: {
          size: 0,
          hover: {
            sizeOffset: 3
          }
        },
        xaxis: {
          labels: {
            show: true
          },
          axisTicks: {
            show: true
          }
        },
        yaxis: {
          min: 0,
          max: 500,
          tickAmount: 5
        },
        tooltip: {
          x: {
            show: true,
            formatter: function(value) {
              return `${value}`;
            }
          },
          y: {
            show: true
          }
        }
      },
      optionsLine: {
        chart: {
          id: 'chart1',
          height: 300,
          type: 'stepline',
          brush: {
            target: 'chart2',
            enabled: true
          },
          selection: {
            enabled: true,
            xaxis: {
              min: daySuccessInform.totalDaySuccessList[0],
              max:
                daySuccessInform.totalDaySuccessList[
                  daySuccessInform.totalDaySuccessList - 1
                ]
            }
          }
        },
        colors: ['#008FFB', '#ff9b0f', '#0e3173'],
        stroke: {
          curve: 'stepline',
          width: 3
        },
        xaxis: {
          labels: {
            show: false
          }
        },
        yaxis: {
          tickAmount: 2,
          min: 0,
          max: 500
        }
      }
    };
  }, [
    data,
    data.length,
    daySuccessInform,
    daySuccessInform.totalDaySuccessList,
    daySuccessInform.totalDaySuccessList.length,
    daySuccessInform.aiSleepDaySuccessList,
    daySuccessInform.aiSleepDaySuccessList.length,
    daySuccessInform.sensingDaySuccessList,
    daySuccessInform.sensingDaySuccessList.length
  ]);

  const lineChartHourState = useMemo(() => {
    return {
      series: [
        {
          name: '총 성공 개수',
          data: sessionTotalInform.totalHourSuccessList
        },
        {
          name: 'AI수면 개수',
          data: sessionTotalInform.aiSleepHourSuccessList
        },
        {
          name: '센싱 개수',
          data: sessionTotalInform.sensingHourSuccessList
        }
      ],
      options: {
        title: {
          text: '시간별 성공 세션 추이'
        },
        chart: {
          id: 'chart3',
          type: 'stepline',
          height: 500,
          toolbar: {
            autoSelected: 'pan',
            show: false
          }
        },
        colors: ['#008FFB', '#ff9b0f', '#0e3173'],
        stroke: {
          curve: 'stepline',
          width: [1, 4, 3, 1]
        },
        dataLabels: {
          enabled: false
        },
        fill: {
          opacity: 1
        },
        markers: {
          size: 0,
          hover: {
            sizeOffset: 3
          }
        },
        xaxis: {
          type: 'datetime',
          labels: {
            show: true
          },
          axisTicks: {
            show: true
          }
        },
        yaxis: {
          min: 0,
          max: 200,
          tickAmount: 5
        },
        tooltip: {
          x: {
            show: true,
            format: 'YYYY-MM-DD HH:mm:ss',
            formatter: function(value) {
              return new Date(value)
                .toISOString()
                .replace('T', ' ')
                .substring(0, 19);
            }
          },
          y: {
            show: true
          }
        }
      },
      optionsLine: {
        chart: {
          id: 'chart4',
          height: 200,
          type: 'stepline',
          brush: {
            target: 'chart3',
            enabled: true
          },
          selection: {
            enabled: true,
            xaxis: {
              min: sessionTotalInform?.totalHourSuccessList[0],
              max:
                sessionTotalInform?.totalHourSuccessList[
                  sessionTotalInform?.totalHourSuccessList - 1
                ]
            }
          }
        },
        colors: ['#008FFB', '#ff9b0f', '#0e3173'],
        stroke: {
          curve: 'stepline',
          width: 3
        },
        xaxis: {
          type: 'datetime',
          tooltip: {
            enabled: false
          }
        },
        yaxis: {
          tickAmount: 2,
          min: 0,
          max: 200
        }
      }
    };
  }, [
    data,
    data.length,
    sessionTotalInform,
    sessionTotalInform.totalHourSuccessList,
    sessionTotalInform.totalHourSuccessList.length,
    sessionTotalInform.aiSleepHourSuccessList,
    sessionTotalInform.aiSleepHourSuccessList.length,
    sessionTotalInform.sensingHourSuccessList,
    sessionTotalInform.sensingHourSuccessList.length
  ]);

  const pieChartTotalState = useMemo(() => {
    return {
      series: [
        sessionTotalInform.totalSuccessCount,
        sessionTotalInform.totalFailCount
      ],
      options: {
        colors: ['#008FFB', '#546E7A'],
        chart: {
          width: 380,
          type: 'donut'
        },
        title: {
          text: '총 세션의 과금/비과금 비율'
        },
        labels: ['과금', '비과금'],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: 'bottom'
              }
            }
          }
        ]
      }
    };
  }, [sessionTotalInform]);

  const pieChartSleepState = useMemo(() => {
    return {
      series: [
        sessionTotalInform.aiSleepSuccessCount,
        sessionTotalInform.sensingSuccessCount
      ],
      options: {
        colors: ['#ff9b0f', '#0e3173'],
        chart: {
          width: 380,
          type: 'donut'
        },
        labels: ['Ai수면', '수면센싱'],
        title: {
          text: '총 과금의 수면 종류 비율'
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: 'bottom'
              }
            }
          }
        ]
      }
    };
  }, [sessionTotalInform]);

  const pieChartSensingOffRatioState = useMemo(() => {
    return {
      series: [
        averageInform.sleepRatio.sensing.off.rem,
        averageInform.sleepRatio.sensing.off.wake,
        averageInform.sleepRatio.sensing.off.deep,
        averageInform.sleepRatio.sensing.off.light
      ],
      options: {
        chart: {
          width: 380,
          type: 'donut'
        },
        labels: ['REM', 'WAKE', 'DEEP', 'LIGHT'],
        title: {
          text: 'OFF모드 수면센싱의 수면 비율'
        },
        tooltip: { enabled: false },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: 'bottom'
              }
            }
          }
        ]
      }
    };
  }, [
    averageInform,
    sessionTotalInform,
    sessionTotalInform.sensingOffReportCount
  ]);
  const pieChartSensingNormalRatioState = useMemo(() => {
    return {
      series: [
        averageInform.sleepRatio.sensing.normal.rem,
        averageInform.sleepRatio.sensing.normal.wake,
        averageInform.sleepRatio.sensing.normal.deep,
        averageInform.sleepRatio.sensing.normal.light
      ],
      options: {
        chart: {
          width: 380,
          type: 'donut'
        },
        labels: ['REM', 'WAKE', 'DEEP', 'LIGHT'],
        title: {
          text: '항온모드 수면센싱의 수면 비율'
        },
        tooltip: { enabled: false },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: 'bottom'
              }
            }
          }
        ]
      }
    };
  }, [
    averageInform,
    sessionTotalInform,
    sessionTotalInform.sensingNormalReportCount
  ]);
  const pieChartSensingSleepRatioState = useMemo(() => {
    return {
      series: [
        averageInform.sleepRatio.sensing.sleep.rem,
        averageInform.sleepRatio.sensing.sleep.wake,
        averageInform.sleepRatio.sensing.sleep.deep,
        averageInform.sleepRatio.sensing.sleep.light
      ],
      options: {
        chart: {
          width: 380,
          type: 'donut'
        },
        labels: ['REM', 'WAKE', 'DEEP', 'LIGHT'],
        title: {
          text: '수면모드 수면센싱의 수면 비율'
        },
        tooltip: { enabled: false },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: 'bottom'
              }
            }
          }
        ]
      }
    };
  }, [
    averageInform,
    sessionTotalInform,
    sessionTotalInform.sensingSleepReportCount
  ]);
  const pieChartSensingCustomRatioState = useMemo(() => {
    return {
      series: [
        averageInform.sleepRatio.sensing.custom.rem,
        averageInform.sleepRatio.sensing.custom.wake,
        averageInform.sleepRatio.sensing.custom.deep,
        averageInform.sleepRatio.sensing.custom.light
      ],
      options: {
        chart: {
          width: 380,
          type: 'donut'
        },
        labels: ['REM', 'WAKE', 'DEEP', 'LIGHT'],
        title: {
          text: '맞춤모드 수면센싱의 수면 비율'
        },
        tooltip: { enabled: false },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: 'bottom'
              }
            }
          }
        ]
      }
    };
  }, [
    averageInform,
    sessionTotalInform,
    sessionTotalInform.sensingCustomReportCount
  ]);
  const pieChartAiSleepRatioState = useMemo(() => {
    return {
      series: [
        averageInform.sleepRatio.aiSleep.rem,
        averageInform.sleepRatio.aiSleep.wake,
        averageInform.sleepRatio.aiSleep.deep,
        averageInform.sleepRatio.aiSleep.light
      ],
      options: {
        chart: {
          width: 380,
          type: 'donut'
        },
        labels: ['REM', 'WAKE', 'DEEP', 'LIGHT'],
        title: {
          text: 'AI수면의 수면 비율'
        },
        tooltip: { enabled: false },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: 'bottom'
              }
            }
          }
        ]
      }
    };
  }, [
    averageInform,
    sessionTotalInform,
    sessionTotalInform.aiSleepReportCount
  ]);

  return (
    <Card>
      <CardHeader title={STRING_TITLE} />
      <Divider />
      {data && data.length > 0 && (
        <CardContent>
          <Grid container spacing={2}>
            <Grid item md={4} xs={6}>
              <ReactApexChart
                options={pieChartTotalState.options}
                series={pieChartTotalState.series}
                type="pie"
                width={380}
              />
            </Grid>
            <Grid item md={4} xs={6}>
              <ReactApexChart
                options={pieChartSleepState.options}
                series={pieChartSleepState.series}
                type="pie"
                width={380}
              />
            </Grid>
            <Grid item md={4} xs={6}>
              <Card>
                <CardHeader title="과금 세션 개수 및 총 비용" />
                <Divider />
                <CardContent>
                  <Box display="flex">
                    <p>{`총 비용 : ${(
                      sessionTotalInform.aiSleepSuccessCount * 250 +
                      sessionTotalInform.sensingSuccessCount * 150
                    ).toLocaleString('ko-KR')}원`}</p>
                  </Box>
                  <Box display="flex">
                    <p>{`AI수면 비용 : ${(
                      sessionTotalInform.aiSleepSuccessCount * 250
                    ).toLocaleString('ko-KR')}원`}</p>
                  </Box>
                  <Box display="flex">
                    <p>{`수면센싱 비용 : ${(
                      sessionTotalInform.sensingSuccessCount * 150
                    ).toLocaleString('ko-KR')}원`}</p>
                  </Box>
                </CardContent>
                <Divider />
                <CardContent>
                  <Box display="flex">
                    <p>{`AI수면 과금 개수 : ${sessionTotalInform.aiSleepSuccessCount.toLocaleString(
                      'ko-KR'
                    )} 개`}</p>
                  </Box>
                  <Box display="flex">
                    <p>{`수면 센싱 과금 개수 : ${sessionTotalInform.sensingSuccessCount.toLocaleString(
                      'ko-KR'
                    )} 개`}</p>
                  </Box>
                </CardContent>
                <Divider />
                <CardContent>
                  <Box display="flex">
                    <p>{`AI수면 총 콜 수 : ${(
                      sessionTotalInform.aiSleepSuccessCount +
                      sessionTotalInform.aiSleepFailCount
                    ).toLocaleString('ko-KR')} 개`}</p>
                  </Box>
                  <Box display="flex">
                    <p>{`수면 센싱 총 콜수 : ${(
                      sessionTotalInform.sensingSuccessCount +
                      sessionTotalInform.sensingFailCount
                    ).toLocaleString('ko-KR')} 개`}</p>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Card>
            <div id="wrapper">
              <ReactApexChart
                options={lineChartDayState.options}
                series={lineChartDayState.series}
                type="line"
                height={300}
              />
            </div>
            <div id="chart-line">
              <ReactApexChart
                options={lineChartDayState.optionsLine}
                series={lineChartDayState.series}
                type="area"
                height={130}
              />
            </div>
          </Card>
          <Card>
            <div id="wrapper">
              <ReactApexChart
                options={lineChartHourState.options}
                series={lineChartHourState.series}
                type="line"
                height={300}
              />
            </div>
            <div id="chart-line">
              <ReactApexChart
                options={lineChartHourState.optionsLine}
                series={lineChartHourState.series}
                type="area"
                height={130}
              />
            </div>
          </Card>
          <Divider />
          {/* <h3>아래 데이터는 5시간 이상 숙면한 데이터를 기준으로 합니다.</h3>
          <Grid container>
            <Card>
              <CardHeader title="AI수면" />
              <Divider />
              <CardContent>
                <Box display="flex">
                  <p>{`총 ${sessionTotalInform.aiSleepReportCount}개`}</p>
                </Box>
                {sessionTotalInform.aiSleepReportCount > 0 && (
                  <>
                    <Box display="flex">
                      <p>{`평균 수면 점수 : ${(
                        averageInform.sleepIndex.aiSleep /
                        sessionTotalInform.aiSleepReportCount
                      ).toFixed(2)}점`}</p>
                    </Box>
                    <Box display="flex">
                      <p>{`평균 설정 온도 : ${(
                        averageInform.setTemp.aiSleep /
                        sessionTotalInform.aiSleepReportCount
                      ).toFixed(2)}`}</p>
                    </Box>
                  </>
                )}
              </CardContent>
            </Card>
            {sessionTotalInform.aiSleepReportCount > 0 && (
              <Grid item md={4} xs={4}>
                <ReactApexChart
                  options={pieChartAiSleepRatioState.options}
                  series={pieChartAiSleepRatioState.series}
                  type="pie"
                  width={380}
                />
              </Grid>
            )}
            <Card>
              <CardHeader title="수면센싱 항온모드" />
              <Divider />
              <CardContent>
                <Box display="flex">
                  <p>{`총 ${sessionTotalInform.sensingNormalReportCount}개`}</p>
                </Box>
                {sessionTotalInform.sensingNormalReportCount > 0 && (
                  <>
                    <Box display="flex">
                      <p>{`평균 수면 점수 : ${(
                        averageInform.sleepIndex.sensing.normal /
                        sessionTotalInform.sensingNormalReportCount
                      ).toFixed(2)}점`}</p>
                    </Box>
                    <Box display="flex">
                      <p>{`평균 설정 온도 : ${(
                        averageInform.setTemp.sensing.normal /
                        sessionTotalInform.sensingNormalReportCount
                      ).toFixed(2)}`}</p>
                    </Box>
                  </>
                )}
              </CardContent>
            </Card>
            {sessionTotalInform.sensingNormalReportCount > 0 && (
              <Grid item md={4} xs={4}>
                <ReactApexChart
                  options={pieChartSensingNormalRatioState.options}
                  series={pieChartSensingNormalRatioState.series}
                  type="pie"
                  width={380}
                />
              </Grid>
            )}
          </Grid>
          <Grid container>
            <Card>
              <CardHeader title="수면센싱 OFF모드" />
              <Divider />
              <CardContent>
                <Box display="flex">
                  <p>{`총 ${sessionTotalInform.sensingOffReportCount}개`}</p>
                </Box>
                {sessionTotalInform.sensingOffReportCount > 0 && (
                  <>
                    <Box display="flex">
                      <p>{`평균 수면 점수 : ${(
                        averageInform.sleepIndex.sensing.off /
                        sessionTotalInform.sensingOffReportCount
                      ).toFixed(2)}점`}</p>
                    </Box>
                  </>
                )}
              </CardContent>
            </Card>
            {sessionTotalInform.sensingOffReportCount > 0 && (
              <Grid item md={4} xs={4}>
                <ReactApexChart
                  options={pieChartSensingOffRatioState.options}
                  series={pieChartSensingOffRatioState.series}
                  type="pie"
                  width={380}
                />
              </Grid>
            )}
            <Card>
              <CardHeader title="수면센싱 수면모드" />
              <Divider />
              <CardContent>
                <Box display="flex">
                  <p>{`총 ${sessionTotalInform.sensingSleepReportCount}개`}</p>
                </Box>
                {sessionTotalInform.sensingSleepReportCount > 0 && (
                  <>
                    <Box display="flex">
                      <p>{`평균 수면 점수 : ${(
                        averageInform.sleepIndex.sensing.sleep /
                        sessionTotalInform.sensingSleepReportCount
                      ).toFixed(2)}점`}</p>
                    </Box>
                    <Box display="flex">
                      <p>{`평균 설정 온도 : ${(
                        averageInform.setTemp.sensing.sleep /
                        sessionTotalInform.sensingSleepReportCount
                      ).toFixed(2)}`}</p>
                    </Box>
                  </>
                )}
              </CardContent>
            </Card>
            {sessionTotalInform.sensingSleepReportCount > 0 && (
              <Grid item md={4} xs={4}>
                <ReactApexChart
                  options={pieChartSensingSleepRatioState.options}
                  series={pieChartSensingSleepRatioState.series}
                  type="pie"
                  width={380}
                />
              </Grid>
            )}
          </Grid>
          <Grid container>
            <Card>
              <CardHeader title="수면센싱 맞춤모드" />
              <Divider />
              <CardContent>
                <Box display="flex">
                  <p>{`총 ${sessionTotalInform.sensingCustomReportCount}개`}</p>
                </Box>
                {sessionTotalInform.sensingCustomReportCount > 0 && (
                  <>
                    <Box display="flex">
                      <p>{`평균 수면 점수 : ${(
                        averageInform.sleepIndex.sensing.custom /
                        sessionTotalInform.sensingCustomReportCount
                      ).toFixed(2)}점`}</p>
                    </Box>
                    <Box display="flex">
                      <p>{`평균 설정 온도 : ${(
                        averageInform.setTemp.sensing.custom /
                        sessionTotalInform.sensingCustomReportCount
                      ).toFixed(2)}`}</p>
                    </Box>
                  </>
                )}
              </CardContent>
            </Card>
            {sessionTotalInform.sensingCustomReportCount > 0 && (
              <Grid item md={4} xs={4}>
                <ReactApexChart
                  options={pieChartSensingCustomRatioState.options}
                  series={pieChartSensingCustomRatioState.series}
                  type="pie"
                  width={380}
                />
              </Grid>
            )}
          </Grid> */}
        </CardContent>
      )}
    </Card>
  );
};

UpSleepStatisticsChart.propTypes = {
  data: PropTypes.array
};

export default UpSleepStatisticsChart;
