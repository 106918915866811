import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState, useContext } from 'react';
import useAsync from '../../../asyncNet';
import { Backdrop, CircularProgress, Typography } from '@material-ui/core';
import { DormancyTable, DormancyToolbar } from './components';
import { notificationDormantUser } from '../APIs/tokapis';
import publicIp from 'public-ip';
import { userInfoContext } from '../../../App';
import {
  NO_PERMISSION,
  NO_PERMISSION_WARNING,
  NO_WHITELIST_INSIDE_WARNING,
  READ_PERMISSION
} from '../../Common/Config/naviwatchConfig';
import { naviwatchCheckWhiteListValidation } from '../../Common/APIs/naviwatchApis';
import AlertPopup from './components/DormancyDialog/AlertPopup';
import { STRING_NO_NOTIFICATION_LOG } from '../StringTable/StringTable';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));


const Dormancy = ({ history }) => {
  const classes = useStyles();
  const [query, setQuery] = useState({
    userId: '',
    from: '',
    to: ''
  });

  const [state, tryRefetch] = useAsync(getNotificationDormantUser, [], true);
  const { loading: dormancyLoading, data: dormancy, error } = state;
  const [ipCheckState, tryCheckWhitelist] = useAsync(checkIpValidation, [], true);
  const [ipAddress, setIpAddress] = useState(undefined);
  const { loading: ipCheckLoading, data: ipValid } = ipCheckState;
  const authInfo = useContext(userInfoContext);
  const [noPermissionMsg, setNoPermissionMsg] = useState("");
  const [permission, setPermission] = useState(NO_PERMISSION);
  const [search, setSearch] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const openPopup = () => setPopupOpen(true);
  const closePopup = () => setPopupOpen(false);

  useEffect(() => {
    var pageInfo = sessionStorage.getItem('pages');
    if (pageInfo !== "undefined") {
      var pageInfoObj = JSON.parse(pageInfo);
      var pageArray = pageInfoObj.filter(page => page.name.toLowerCase().includes("inactive"));
      if (pageArray !== undefined && pageArray.length > 0) {
        var page = pageArray[0];
        if (page.permission === undefined) {
          setNoPermissionMsg(NO_PERMISSION_WARNING);
        }
        setPermission(page.permission);
      }
    }
  }, [authInfo.pages]);

  async function getNotificationDormantUser() {
    return notificationDormantUser(query.userId, query.from, query.to, ipAddress);
  }

  async function checkIpValidation() {
    return naviwatchCheckWhiteListValidation(ipAddress);
  }

  const searchDormacy = (searchdata) => {
    var _from = '';
    var _to = '';
    if (searchdata.from !== '') {
      _from = new Date(searchdata.from).setHours(0, 0, 0, 0) + 9 * 60 * 60 * 1000
    }
    if (searchdata.to !== '') {
      _to = new Date(searchdata.to).setHours(23, 59, 59, 0) + 9 * 60 * 60 * 1000
    }
    setQuery(
      {
        userId: searchdata.userId,
        from: _from,
        to: _to
      });
    setSearch(true);
  };

  useEffect(() => {
    if (search == true) {
      tryRefetch();
      setSearch(false);
    }
  }, [search]);

  useEffect(() => {
    if (ipCheckState.data === false) {
      setNoPermissionMsg(NO_WHITELIST_INSIDE_WARNING + `(${ipAddress})`);
    }
  }, [ipCheckState]);

  useEffect(() => {
    if (ipAddress !== undefined) {
      tryCheckWhitelist();
    }
  }, [ipAddress]);


  useEffect(() => {
    if (sessionStorage.getItem('naviwatch_permission') !== 'true')
      history.push('/');
    (async () => {
      setIpAddress(await publicIp.v4());
    })();
  }, []);

  useEffect(() => {
    if (dormancy !== null && dormancy !== undefined && (dormancy.dormantNotification).length === 0) {
      openPopup()
    }
  }, [dormancy]);


  return (
    <div>
      {permission >= READ_PERMISSION && ipValid === true ?
        <div className={classes.root}>
          <Backdrop className={classes.backdrop} open={dormancyLoading || ipCheckLoading}>
            <CircularProgress color={'primary'} />
          </Backdrop>
          <DormancyToolbar callbackFunc={searchDormacy} />
          <div className={classes.content}>
            {
              dormancyLoading === false && dormancy !== null && dormancy !== undefined &&
              <DormancyTable dormantNotification={dormancy.dormantNotification} />
            }
          </div>
        </div> :
        <Typography className={classes.root}>{noPermissionMsg}</Typography>
      }
      <AlertPopup open={popupOpen} handleClose={closePopup} comment={STRING_NO_NOTIFICATION_LOG} color={'black'} severity={'info'} />
    </div>
  );
};

export default Dormancy;
