import React from 'react';
import {
  Dialog, DialogActions, DialogContent, Button,
  DialogTitle, Divider, Typography, makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';

const STRING_TITLE = '처리 결과';
const STRING_OK = '확인';



const useStyles = makeStyles(theme => ({
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  linear:{
    display: 'flex',
    alignItems: 'flex-end'
  },
  datas:{
    marginLeft: theme.spacing(2)
  },
  icons:{
    marginLeft: theme.spacing(2),
    padding: theme.spacing(0)
  },
  fwDescription:{
    marginLeft: theme.spacing(2),
    width: '75%'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));

const AlertDialog  = props => {
  const { open, handleClose, message} = props;
  const handleCloseClick = () =>{
    handleClose();
  };

  return (
    <div>
      <Dialog
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{STRING_TITLE}</DialogTitle>
        <DialogContent>
          <Typography variant={"h7"}>{message}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseClick} color="primary" autoFocus>
            {STRING_OK}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

AlertDialog.propTypes = {
  className: PropTypes.string
};

export default AlertDialog;
