import React, { useState, useEffect, useMemo } from 'react';
import { useLocation, withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useRecoilValue } from 'recoil';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';

import { Footer } from './Default/Main/components';
import Nav from './Nav';
import secureLocalStarage from 'react-secure-storage';
import { unSidebarPathList } from '../common/unSidebar';
// import { userAccount } from '../common/mainStore';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 56,
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64
    }
  },
  content: {
    height: '100%'
  },
  shiftContent: {
    paddingLeft: 240
  },
}));

const Layout = props => {
  const { children, history } = props;

  const classes = useStyles();
  const theme = useTheme();
  const location = useLocation();
  const [path, setPath] = useState(location.pathname.split('/')[1]);
  const userAccount = useMemo(()=>{
    console.log(secureLocalStarage.getItem('account'))
    return secureLocalStarage.getItem('account')
  },[path])

  // const userAccountState = useRecoilValue(userAccount);

  useEffect(() => {
    setPath(location.pathname.split('/')[1]);
    console.log('path' + location.pathname.split('/')[1]);
  }, [location, location.pathname]);

  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  });

  useEffect(()=>{
    if(!userAccount && unSidebarPathList.indexOf(path)==-1) history.push('/sign-in')
  },[path, userAccount])
  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: isDesktop && unSidebarPathList.indexOf(path) == -1
      })}>
      <Nav userAccount={userAccount} path={path}/>
      <main className={classes.content}>
        {children}
        {unSidebarPathList.indexOf(path) ==
          -1 && <Footer />}
      </main>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node
};

export default withRouter(Layout);
