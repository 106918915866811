import React, { useState, useEffect, useMemo } from 'react';
import { useLocation, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';
import { useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';

import Topbar from './Topbar';
import Sidebar from './Sidebar/Sidebar';
import { unSidebarPathList } from '../common/unSidebar';

const Nav = props => {
  const { history, userAccount, path } = props;

  const theme = useTheme();

  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  });


  const [openSidebar, setOpenSidebar] = useState(true);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  useEffect(()=>{
    setOpenSidebar(true)
  },[path])

  const shouldOpenSidebar = isDesktop ? true : openSidebar;

  return (
    <>
      <Topbar onSidebarOpen={handleSidebarOpen} history={history} path={path} />
      {unSidebarPathList.indexOf(path) ==
        -1 && (
        <Sidebar
          onClose={handleSidebarClose}
          open={shouldOpenSidebar}
          path={path}
          userAccount={userAccount}
          variant={isDesktop ? 'persistent' : 'temporary'}
        />
      )}
    </>
  );
};

Nav.propTypes = {
  children: PropTypes.node
};

export default withRouter(Nav);
