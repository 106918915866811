import axios from 'axios';
import { deploy } from 'config';
import AWS from 'aws-sdk';

var ApiInstance = null;
var ECSClusterName = '';
var ECSServiceName = '';
var DBClusterName = '';
var LoadBalancerName = '';
var OTA_DEVICE_FW_PATH = '/airone/firmwares';
var OTA_DEVICE_FW_PATH_MQTT = ''; // pure device path is file name.
var OTA_DEVICE_CERT_PATH = '/airone/certificates/';
var OTA_CERTIFICATE_ARN = '';
var OTA_S3_BUCKET = '';
var OTA_S3_SIGNING_PREFIX = 'SignedImages/';

if (deploy === 'dev') {
  ApiInstance = axios.create({
    baseURL: 'https://krtc-dev-nw-api.naviensmartcontrol.com'
  });
  ECSClusterName = 'airone-dev-ecs-cluster-converter';
  ECSServiceName = 'airone-dev-ecs-service-converter';
  DBClusterName = 'airone-dev-stack-rds-dbcluster624cfb91-a7fkgwrkp8hk';
  LoadBalancerName = 'net/airone-dev-ec2-nlb-converter/df0730778d056592';
  OTA_CERTIFICATE_ARN =
    'arn:aws:acm:ap-northeast-2:195830161280:certificate/db88e944-5279-4c26-aaf4-7e9cf6634123';
  OTA_S3_BUCKET = 'airone-dev-s3-bucket-firmware';
} else if (deploy === 'stage') {
  ApiInstance = axios.create({
    baseURL: 'https://krtc-stg-nw-api.naviensmartcontrol.com'
  });
  ECSClusterName = 'airone-stage-ecs-cluster-converter';
  ECSServiceName = 'airone-stage-ecs-service-converter';
  DBClusterName = 'airone-stage-stack-rds-dbcluster624cfb91-qf7sgco1gtdu';
  LoadBalancerName = 'net/airone-stage-ec2-nlb-converter/c3e3d8ac6aec9a49';
  OTA_CERTIFICATE_ARN =
    'arn:aws:acm:ap-northeast-2:195830161280:certificate/db88e944-5279-4c26-aaf4-7e9cf6634123';
  OTA_S3_BUCKET = 'airone-stage-s3-bucket-firmware';
} else if (deploy === 'prod') {
  ApiInstance = axios.create({
    baseURL: 'https://krtc-nw-api.naviensmartcontrol.com'
  });
  ECSClusterName = 'airone-prod-ecs-cluster-converter';
  ECSServiceName = 'airone-prod-ecs-service-converter';
  DBClusterName = 'airone-prod-stack-rds-dbcluster624cfb91-wx7yamqkx6si';
  LoadBalancerName = 'net/airone-prod-ec2-nlb-converter/7a33458b963c9625';
}

export async function dashboardGetDeviceStatistics() {
  var response = undefined;
  let reqUrl = '/naviwatch/api/v1/dashboard/get-device-statistics';
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function dashboardGetAirmonitorStatistics() {
  var response = undefined;
  let reqUrl = '/naviwatch/api/v1/dashboard/get-am-statistics';
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function dashboardGetUserStatistics() {
  var response = undefined;
  let reqUrl = '/naviwatch/api/v1/dashboard/get-user-statistics';
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function dashboardGetMobileStatistics() {
  var response = undefined;
  let reqUrl = '/naviwatch/api/v1/dashboard/get-mobile-statistics';
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function dashboardGetModelStatistics(query) {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/dashboard/get-model-statistics?query=${query}`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function dashboardGetODUModelStatistics(query) {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/dashboard/get-odu-model-statistics?query=${query}`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function dashboardServerMetric(metricName) {
  var response = undefined;
  const currentTime = Math.floor(new Date() / 1000);

  let reqUrl =
    `/naviwatch/api/v1/dashboard/get-server-metric?clusterName=${ECSClusterName}` +
    `&serviceName=${ECSServiceName}&metricName=${metricName}&endtimeSec=${currentTime}` +
    `&timelineSec=1200&timePeriodSec=60`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function dashboardGetUserIncrease(day, basis) {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/dashboard/get-user-increasing?day=${day}&basis=${basis}`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function dashboardRegionStatistics() {
  var response = undefined;

  let reqUrl = `/naviwatch/api/v1/dashboard/get-region-statistics`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function serverConverterMetric(
  metricName,
  timelineSec,
  timePeriodSec,
  currentTime
) {
  var response = undefined;
  let reqUrl =
    `/naviwatch/api/v1/server/get-converter-metric?` +
    `clusterName=${ECSClusterName}&serviceName=${ECSServiceName}&metricName=${metricName}` +
    `&endtimeSec=${currentTime}&timelineSec=${timelineSec}` +
    `&timePeriodSec=${timePeriodSec}`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function serverDBMetric(
  metricName,
  timelineSec,
  timePeriodSec,
  currentTime,
  unit,
  stat
) {
  var response = undefined;
  let reqUrl =
    `/naviwatch/api/v1/server/get-db-metric?` +
    `clusterName=${DBClusterName}&metricName=${metricName}` +
    `&endtimeSec=${currentTime}&timelineSec=${timelineSec}` +
    `&timePeriodSec=${timePeriodSec}&unit=${unit}&stat=${stat}`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function serverLBMetric(
  metricName,
  timelineSec,
  timePeriodSec,
  currentTime
) {
  var response = undefined;
  let reqUrl =
    `/naviwatch/api/v1/server/get-lb-metric?` +
    `loadBalancer=${LoadBalancerName}&metricName=${metricName}` +
    `&endtimeSec=${currentTime}&timelineSec=${timelineSec}` +
    `&timePeriodSec=${timePeriodSec}`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function getEcsLogLevel() {
  const reqUrl = '/naviwatch/api/v1/server/get-ecs-log-level';
  const config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };

  try {
    const response = await ApiInstance.get(reqUrl, config);

    if (response.status == 200) {
      return response.data;
    } else {
      console.log(response);
    }
  } catch (error) {
    // Handle error
    console.error(error);
  }

  return null;
}

export async function setEcsLogLevel(logLevel) {
  const reqUrl = '/naviwatch/api/v1/server/set-ecs-log-level';
  const config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };

  const data = JSON.stringify({
    level: logLevel
  });

  try {
    const response = await ApiInstance.post(reqUrl, data, config);
    if (response.status == 200) {
      return response.data;
    } else {
      console.log(response);
    }
  } catch (error) {
    // Handle error
    console.error(error);
  }

  return null;
}

export async function deviceTotalCount() {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/device/get-total-count`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function deviceSearch(
  deviceId,
  userId,
  username,
  modelcode,
  connection,
  regionCodeFrom,
  regionCodeTo
) {
  var response = undefined;
  let reqUrl =
    `/naviwatch/api/v1/device/search?deviceid=${deviceId}` +
    `&userid=${userId}&username=${username}&modelcode=${modelcode}` +
    `&connection=${connection}&regionfrom=${regionCodeFrom}` +
    `&regionto=${regionCodeTo}`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function deviceDetailSearch(deviceId) {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/device/search-detail?deviceid=${deviceId}`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function userTotalCount() {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/user/get-total-count`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function userSearch(
  naviwatchEmail,
  ip,
  userid,
  useremail,
  username
) {
  var response = undefined;
  let reqUrl =
    `/naviwatch/api/v1/user/search?naviwatchid=${naviwatchEmail}` +
    `&ip=${ip}&userid=${userid}&useremail=${useremail}&username=${username}`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function userDetailSearch(naviwatchEmail, ip, userid) {
  var response = undefined;
  let reqUrl =
    `/naviwatch/api/v1/user/search-detail?` +
    `naviwatchid=${naviwatchEmail}&ip=${ip}&userid=${userid}`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function userSendPush(userid, title, content, endCallback) {
  var response = undefined;
  let reqUrl =
    `/naviwatch/api/v1/user/send-push?` +
    `userid=${userid}&title=${title}&content=${content}`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  endCallback();
  return response.data;
}

export async function dormancyGetDormancyHistory(condition, userid) {
  var response = undefined;
  let reqUrl =
    `/naviwatch/api/v1/dormancy-user/get-history?` +
    `condition=${condition}&userid=${userid}`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function dormancySearch(naviwatchemail, ip, userid, from, to) {
  var response = undefined;
  let reqUrl =
    `/naviwatch/api/v1/dormancy-user/search?` +
    `naviwatchemail=${naviwatchemail}&ip=${ip}&userid=${userid}&` +
    `from=${from}&to=${to}`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  console.log('response: ' + JSON.stringify(response));
  return response.data;
}

export async function dormancyGetDormancyCount() {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/dormancy-user/get-count`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  console.log('dormancy count res: ' + JSON.stringify(response));
  return response.data;
}

export async function pushSend(modelCode, title, content, endCallback) {
  var response = undefined;
  let reqUrl =
    `/naviwatch/api/v1/push/send-push?` +
    `modelCode=${modelCode}&title=${title}&content=${content}`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  endCallback(response.data);
  return response.data;
}

export async function recordGet(searchId, idType, detail, from, to) {
  var response = undefined;
  let reqUrl =
    `/naviwatch/api/v1/record/get-record?` +
    `idType=${idType}&searchId=${searchId}&detail=${detail}&from=${from}&to=${to}`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function recordGetFirmwareRecords(searchId, idType, from, to) {
  var response = undefined;

  let reqUrl =
    `/naviwatch/api/v1/record/get-fw-record?` +
    `idType=${idType}&searchId=${searchId}&from=${from}&to=${to}`;

  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function firmwareModelInfo() {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/firmware/get-model-info`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  console.log(response.data);
  return response.data;
}

export async function firmwareList(modelCode) {
  var response = undefined;
  let reqUrl =
    `/naviwatch/api/v1/firmware/get-firmware-list?` + `modelcode=${modelCode}`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  console.log('firmwarelist : ' + JSON.stringify(response.data));
  return response.data['fw_list'];
}

export async function firmwareUpload(
  naviwatchUser,
  ip,
  buffer,
  majorVersion,
  minorVersion,
  size,
  modelCode,
  fwDescription,
  activeFlag,
  forcedFlag,
  groupOtaFlag,
  endCallback
) {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/firmware/upload-firmware`;
  let data = JSON.stringify({
    naviwatchUser: naviwatchUser,
    ip: ip,
    buffer: buffer,
    majorVersion: majorVersion,
    minorVersion: minorVersion,
    size: size,
    modelCode: modelCode,
    fwDescription: fwDescription,
    activeFlag: activeFlag,
    forcedFlag: forcedFlag,
    groupOtaFlag: groupOtaFlag
  });
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.post(reqUrl, data, config);
  endCallback();
  return response.data;
}

export async function firmwareThingsOfJob(
  majorVersion,
  minorVersion,
  buildNumber,
  modelCode,
  status,
  token
) {
  var response = undefined;
  console.log('call firmwareThingsOfJob');
  let reqUrl =
    `/naviwatch/api/v1/firmware-mqtt/get-thing-of-job?` +
    `majorVersion=${majorVersion}&minorVersion=${minorVersion}&buildNumber=${buildNumber}&` +
    `modelCode=${modelCode}&status=${status}&token=${token}`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  console.log('firmwareThingsOfJob response : ' + JSON.stringify(response));
  return response.data;
}

export async function firmwareGetJobFile(jobId) {
  var response = undefined;
  console.log('call firmwareThingsOfJob');
  let reqUrl =
    `/naviwatch/api/v1/firmware-mqtt/get-job-doc?` + `jobId=${jobId}`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);

  console.log('response: ' + JSON.stringify(response));
  return response.data;
}

export async function firmwareSearchThingGroups(groupName, token) {
  var response = undefined;
  console.log('call firmwareThingsOfJob');
  let reqUrl =
    `/naviwatch/api/v1/firmware-mqtt/search-thinggroups?` +
    `groupName=${groupName}&token=${token}`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);

  console.log('response: ' + JSON.stringify(response));
  return response.data;
}

export async function firmwareSearchThings(
  attributeName,
  attributeValue,
  token
) {
  var response = undefined;
  console.log('call firmwareThingsOfJob');
  let reqUrl =
    `/naviwatch/api/v1/firmware/search-things?` +
    `attributeName=${attributeName}&attributeValue=${attributeValue}&token=${token}`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);

  console.log('response: ' + JSON.stringify(response));
  return response.data;
}

export async function firmwareSearchThingsMQTT(
  attributeName,
  attributeValue,
  token
) {
  var response = undefined;
  console.log('call firmwareThingsOfJob');
  let reqUrl =
    `/naviwatch/api/v1/firmware-mqtt/search-things?` +
    `attributeName=${attributeName}&attributeValue=${attributeValue}&token=${token}`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);

  console.log('response: ' + JSON.stringify(response));
  return response.data;
}

export async function firmwareModelInfoMqtt() {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/firmware-mqtt/get-model-info-mqtt`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  console.log(response.data);
  return response.data;
}

export async function firmwareListMqtt(modelCode) {
  var response = undefined;
  let reqUrl =
    `/naviwatch/api/v1/firmware-mqtt/get-firmware-list-mqtt?` +
    `modelcode=${modelCode}`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  console.log('firmwarelistmqtt : ' + JSON.stringify(response.data));
  return response.data['fw_list'];
}

export async function jobCompleteHistory(deviceId) {
  var response = undefined;
  let reqUrl =
    `/naviwatch/api/v1/firmware-mqtt/get-job-complete-history-mqtt?` +
    `deviceid=${deviceId}`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  // console.log('before',response.data.job_complete_history)
  // console.log('history type is :', typeof('response.data.job_complete_history')) // string

  return response.data;
}

const MODEL_CODE_NRT_30L = 1000;
const MODEL_CODE_NRT_30SX = 1001;
const MODEL_CODE_NRT_30D = 1002;
const MODEL_CODE_NRZ530S = 1003;
const MODEL_CODE_NRZ530Z = 1004;
const MODEL_CODE_NRG530S = 1005;

const MODEL_CODE_NRT30S = 1100;
const MODEL_CODE_NRT530Z = 1101;
const MODEL_CODE_NRT530S = 1102;

const MODEL_CODE_NRT530S_2W = 1103;
const MODEL_CODE_NRT530Z_2W = 1104;

export function getS3Path(modelCode) {
  if (parseInt(modelCode) === MODEL_CODE_NRT_30L) {
    return '1000';
  } else if (parseInt(modelCode) === MODEL_CODE_NRT_30SX) {
    return '1001';
  } else if (parseInt(modelCode) === MODEL_CODE_NRT_30D) {
    return '1002';
  } else if (parseInt(modelCode) === MODEL_CODE_NRZ530S) {
    return '1003';
  } else if (parseInt(modelCode) === MODEL_CODE_NRZ530Z) {
    return '1004';
  } else if (parseInt(modelCode) === MODEL_CODE_NRG530S) {
    return '1005';
  } else if (parseInt(modelCode) === MODEL_CODE_NRT30S) {
    return '1100';
  } else if (parseInt(modelCode) === MODEL_CODE_NRT530Z) {
    return '1101';
  } else if (parseInt(modelCode) === MODEL_CODE_NRT530S) {
    return '1102';
  } else if (parseInt(modelCode) === MODEL_CODE_NRT530S_2W) {
    return '1103';
  } else if (parseInt(modelCode) === MODEL_CODE_NRT530Z_2W) {
    return '1104';
  }
}
//

export async function rcAddFirmware(
  naviwatchUser,
  ip,
  fwId,
  desc,
  users,
  s3Path,
  s3Files,
  majorVersion,
  minorVersion,
  buildNumber,
  fwType,
  modelCode,
  activeFlag,
  forcedFlag,
  groupOtaFlag
) {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/firmware-mqtt/rc-add-firmware`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };

  let data = JSON.stringify({
    naviwatchUser: naviwatchUser,
    ip: ip,
    fwId: fwId,
    description: desc,
    users: users,
    deviceFwPath: OTA_DEVICE_FW_PATH_MQTT,
    deviceCertPath: OTA_DEVICE_CERT_PATH,
    certificateArn: OTA_CERTIFICATE_ARN,
    s3Bucket: OTA_S3_BUCKET,
    s3Path: s3Path,
    s3Files: s3Files,
    s3SigningPrefix: OTA_S3_SIGNING_PREFIX,
    majorVersion: majorVersion,
    minorVersion: minorVersion,
    buildNumber: buildNumber,
    fwType: fwType,
    modelCode: modelCode,
    activeFlag: activeFlag,
    forcedFlag: forcedFlag,
    groupOtaFlag: groupOtaFlag
  });
  await getSts();
  response = await ApiInstance.post(reqUrl, data, config);
  return response.data;
}

export async function getSts() {
  var logins = {};
  logins[
    'cognito-idp.' +
      'ap-northeast-2' +
      '.amazonaws.com/' +
      'ap-northeast-2_pczJUEhHi'
  ] = sessionStorage.getItem('idToken');

  AWS.config.credentials = new AWS.CognitoIdentityCredentials(
    {
      IdentityPoolId: 'ap-northeast-2:a7b28af1-d655-458e-b37c-2dcf225cc7a7',
      Logins: logins
    },
    { region: 'ap-northeast-2' }
  );
  console.log(AWS.config.credentials);

  AWS.config.credentials.clearCachedId();

  const client = new AWS.STS({ region: 'ap-northeast-2' });
  let params = {
    RoleArn: '',
    RoleSessionName: ''
  };

  if (deploy === 'dev') {
    params = {
      RoleArn:
        'arn:aws:iam::479691314464:role/airone-dev-iam-role-cross-access-ex',
      RoleSessionName: 'naviwatch-sts'
    };
  } else if (deploy === 'stage') {
    params = {
      RoleArn:
        'arn:aws:iam::023723365910:role/airone-stage-iam-role-cross-access-ex',
      RoleSessionName: 'naviwatch-sts'
    };
  } else if (deploy === 'prod') {
    params = {
      RoleArn:
        'arn:aws:iam::300849255808:role/airone-prod-iam-role-cross-access-ex',
      RoleSessionName: 'naviwatch-sts'
    };
  }

  const assumeRole = await client.assumeRole(params).promise();
  console.log('Changed Credentials');
  const accessparams = {
    accessKeyId: assumeRole.Credentials.AccessKeyId,
    secretAccessKey: assumeRole.Credentials.SecretAccessKey,
    sessionToken: assumeRole.Credentials.SessionToken
  };
  AWS.config.update(accessparams);
  return accessparams;
}

export async function firmwareModifyMQTT(
  naviwatchUser,
  ip,
  savedMajorVersion,
  savedMinorVersion,
  savedBuildNumber,
  fwType,
  modelCode,
  majorVersion,
  minorVersion,
  buildNumber,
  activeFlag,
  forcedFlag,
  groupOtaFlag,
  fwDescription,
  groupList
) {
  var response = undefined;
  let s3Path = getS3Path(modelCode);
  let reqUrl = `/naviwatch/api/v1/firmware-mqtt/modify-firmware-mqtt`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  // alert(getS3Path(modelCode));
  let data = JSON.stringify({
    naviwatchUser: naviwatchUser,
    ip: ip,
    savedMajorVersion: savedMajorVersion,
    savedMinorVersion: savedMinorVersion,
    savedBuildNumber: savedBuildNumber,
    fwType: fwType,
    modelCode: modelCode,
    majorVersion: majorVersion,
    minorVersion: minorVersion,
    buildNumber: buildNumber,
    activeFlag: activeFlag,
    forcedFlag: forcedFlag,
    groupOtaFlag: groupOtaFlag,
    fwDescription: fwDescription,
    groupList: groupList,
    deviceFwPath: OTA_DEVICE_FW_PATH_MQTT,
    s3Bucket: OTA_S3_BUCKET,
    s3Path: s3Path,
    s3SigningPrefix: OTA_S3_SIGNING_PREFIX
  });
  // endCallback();

  response = await ApiInstance.post(reqUrl, data, config);
  return response.data;
}

export async function firmwareDeleteMQTT(
  naviwatchUser,
  ip,
  majorVersion,
  minorVersion,
  buildNumber,
  modelCode,
  s3Path,
  endCallback
) {
  var response = undefined;
  let reqUrl =
    `/naviwatch/api/v1/firmware-mqtt/delete-firmware-mqtt?` +
    `naviwatchUser=${naviwatchUser}&ip=${ip}&majorVersion=${majorVersion}&minorVersion=${minorVersion}&buildNumber=${buildNumber}` +
    `&modelCode=${modelCode}&s3Bucket=${OTA_S3_BUCKET}&s3Path=${s3Path}`;

  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  endCallback();
  return response.data;
}

export async function firmwareGetGroupUsersMQTT(
  majorVersion,
  minorVersion,
  buildNumber,
  modelCode
) {
  var response = undefined;
  let reqUrl =
    `/naviwatch/api/v1/firmware-mqtt/get-group-users-mqtt?` +
    `majorVersion=${majorVersion}&minorVersion=${minorVersion}&buildNumber=${buildNumber}` +
    `&modelCode=${modelCode}`;

  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  console.log('response.data: ' + JSON.stringify(response.data));
  return response.data;
}

export async function firmwareSetGroupUsersMQTT(
  naviwatchUser,
  ip,
  majorVersion,
  minorVersion,
  buildNumber,
  modelCode,
  deviceList,
  endCallback
) {
  var response = undefined;
  let data = JSON.stringify({
    naviwatchUser: naviwatchUser,
    ip: ip,
    majorVersion: majorVersion,
    minorVersion: minorVersion,
    buildNumber: buildNumber,
    modelCode: modelCode,
    deviceList: deviceList
  });
  let reqUrl = `/naviwatch/api/v1/firmware-mqtt/set-group-users-mqtt`;

  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  console.log('deviceList: ' + deviceList);
  response = await ApiInstance.post(reqUrl, data, config);
  endCallback();
  return response.data;
}

export function formatter(n) {
  if (n !== undefined) {
    var tempval = new Date((n + 60 * 60 * 9) * 1000);
    var timeStr = tempval.toISOString();
    timeStr = timeStr.replaceAll('T', ' ');
    timeStr = timeStr.slice(0, 19);
    return timeStr;
  } else {
    return undefined;
  }
}

export async function firmwareModify(
  naviwatchUser,
  ip,
  savedMajorVersion,
  savedMinorVersion,
  modelCode,
  majorVersion,
  minorVersion,
  activeFlag,
  forcedFlag,
  groupOtaFlag,
  description,
  endCallback
) {
  var response = undefined;
  let reqUrl =
    `/naviwatch/api/v1/firmware/modify-firmware?` +
    `naviwatchUser=${naviwatchUser}&ip=${ip}&savedMajorVersion=${savedMajorVersion}&savedMinorVersion=${savedMinorVersion}` +
    `&modelCode=${modelCode}&majorVersion=${majorVersion}&minorVersion=${minorVersion}` +
    `&activeFlag=${activeFlag}&forcedFlag=${forcedFlag}&groupOtaFlag=${groupOtaFlag}` +
    `&fwDescription=${description}`;

  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  endCallback();
  return response.data;
}

export async function firmwareDelete(
  naviwatchUser,
  ip,
  majorVersion,
  minorVersion,
  modelCode,
  endCallback
) {
  var response = undefined;
  let reqUrl =
    `/naviwatch/api/v1/firmware/delete-firmware?` +
    `naviwatchUser=${naviwatchUser}&ip=${ip}&majorVersion=${majorVersion}&minorVersion=${minorVersion}` +
    `&modelCode=${modelCode}`;

  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  endCallback();
  return response.data;
}

export async function firmwareGetGroupUsers(
  majorVersion,
  minorVersion,
  modelCode
) {
  var response = undefined;
  let reqUrl =
    `/naviwatch/api/v1/firmware/get-group-users?` +
    `majorVersion=${majorVersion}&minorVersion=${minorVersion}` +
    `&modelCode=${modelCode}`;

  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  console.log('response.data: ' + JSON.stringify(response.data));
  return response.data;
}

export async function firmwareSetGroupUsers(
  naviwatchUser,
  ip,
  majorVersion,
  minorVersion,
  modelCode,
  deviceList,
  endCallback
) {
  var response = undefined;
  let data = JSON.stringify({
    naviwatchUser: naviwatchUser,
    ip: ip,
    majorVersion: majorVersion,
    minorVersion: minorVersion,
    modelCode: modelCode,
    deviceList: deviceList
  });
  let reqUrl = `/naviwatch/api/v1/firmware/set-group-users`;

  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  console.log('deviceList: ' + deviceList);
  response = await ApiInstance.post(reqUrl, data, config);
  endCallback();
  return response.data;
}

export async function statisticsFilterResetCount(month) {
  var response = undefined;

  let reqUrl = `/naviwatch/api/v1/statistics/filter-reset-count?month=${month}`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function statisticsAMConnectCount() {
  var response = undefined;

  let reqUrl = `/naviwatch/api/v1/statistics/am-connect-count`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function statisticsODURunningCount() {
  var response = undefined;

  let reqUrl = `/naviwatch/api/v1/statistics/odu-running-count`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function statisticsDeepSleepModeCount() {
  var response = undefined;

  let reqUrl = `/naviwatch/api/v1/statistics/deep-sleep-count`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function statisticsAMBrightnessCount() {
  var response = undefined;

  let reqUrl = `/naviwatch/api/v1/statistics/am-brightness-count`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function statisticsOduOperationModeCount() {
  var response = undefined;

  let reqUrl = `/naviwatch/api/v1/statistics/odu-operation-mode`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function statisticsFilterUsedTime() {
  var response = undefined;

  let reqUrl = `/naviwatch/api/v1/statistics/filter-used-time`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function statisticsRegionalTotalAirData(
  regionCodeFrom,
  regionCodeTo,
  date
) {
  var response = undefined;
  let reqUrl =
    `/naviwatch/api/v1/statistics/regional-total-air?` +
    `regionFrom=${regionCodeFrom}&regionTo=${regionCodeTo}&date=${date}`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function statisticsOduAirVolume() {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/statistics/odu-air-volume`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function statisticsFilterAlarmCount(month) {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/statistics/filter-alarm-count?month=${month}`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function statisticsCPUResetCount() {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/statistics/cpu-reset-count`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function statisticsWatchdogResetCount() {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/statistics/watchdog-reset-count`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function statisticsHepafilterResetCount() {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/statistics/hepafilter-reset-count`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function statisticsFreefilterResetCount() {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/statistics/freefilter-reset-count`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function statisticsVentilationCount() {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/statistics/ventilation-count`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function statisticsCondensationUser() {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/statistics/condensation-user`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function deviceGetAirReport(deviceId, modelCode, from, to) {
  var response = undefined;
  console.log(deviceId, modelCode, from, to);
  if (deploy === 'prod' || deploy === 'stage') {
    console.log('ddd');
    let reqUrl = `/naviwatch/api/v1/device/get-air-report?deviceId=${deviceId}&from=${from}&to=${to}&modelCode=${modelCode}`;
    let config = {
      headers: {
        authorizer: sessionStorage.getItem('idToken')
      }
    };
    response = await ApiInstance.get(reqUrl, config);
    return response.data;
  } else {
    return { data: null };
  }
}

export async function deviceGetAliveReport(deviceId, modelCode, from, to) {
  var response = undefined;
  if (deploy === 'prod' || deploy === 'stage') {
    let reqUrl = `/naviwatch/api/v1/device/get-alive-report?deviceId=${deviceId}&from=${from}&to=${to}&modelCode=${modelCode}`;
    let config = {
      headers: {
        authorizer: sessionStorage.getItem('idToken')
      }
    };
    response = await ApiInstance.get(reqUrl, config);
    return response.data;
  } else {
    return { data: null };
  }
}

export async function deviceGetKitchenplusReport(deviceId, from, to) {
  var response = undefined;
  if (deploy === 'prod' || deploy === 'stage') {
    let reqUrl = `/naviwatch/api/v1/device/get-kitchenplus-report?deviceId=${deviceId}&from=${from}&to=${to}`;
    let config = {
      headers: {
        authorizer: sessionStorage.getItem('idToken')
      }
    };
    response = await ApiInstance.get(reqUrl, config);
    return response.data;
  } else {
    return { data: null };
  }
}

export async function deviceGetStatusReport(deviceId, modelCode, from, to) {
  var response = undefined;
  if (deploy === 'prod' || deploy === 'stage') {
    let reqUrl = `/naviwatch/api/v1/device/get-status-report?deviceId=${deviceId}&from=${from}&to=${to}&modelCode=${modelCode}`;
    let config = {
      headers: {
        authorizer: sessionStorage.getItem('idToken')
      }
    };
    response = await ApiInstance.get(reqUrl, config);
    return response.data;
  } else {
    return { data: null };
  }
}

export async function deviceGetUserInteractionReport(
  deviceId,
  modelCode,
  from,
  to
) {
  var response = undefined;
  if (deploy === 'prod' || deploy === 'stage') {
    let reqUrl = `/naviwatch/api/v1/device/get-user-interaction-report?deviceId=${deviceId}&from=${from}&to=${to}&modelCode=${modelCode}`;
    let config = {
      headers: {
        authorizer: sessionStorage.getItem('idToken')
      }
    };
    response = await ApiInstance.get(reqUrl, config);
    return response.data;
  } else {
    return { data: null };
  }
}

export async function getDeviceInteractionReport(deviceId, from) {
  let reqUrl = `naviwatch/api/v1/device/get-device-interaction-report?deviceId=${deviceId}&from=${from}`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  const response = await ApiInstance.get(reqUrl, config);
  return response;
}

export function getFWType(type) {
  switch (type) {
    case 1:
      return 'MCU';
    case 2:
      return 'Wifi';
    case 3:
      return 'Certificate';
    default:
      return 'Not Defined';
  }
}

export async function deviceGetReport(deviceId, modelCode, from, to, type) {
  var response = undefined;
  if (deploy === 'prod' || deploy === 'stage') {
    let reqUrl = `/naviwatch/api/v1/device/get-report?deviceId=${deviceId}&from=${from}&to=${to}&modelCode=${modelCode}&reportType=${type}`;
    let config = {
      headers: {
        authorizer: sessionStorage.getItem('idToken')
      }
    };
    response = await ApiInstance.get(reqUrl, config);
    return response.data;
  } else {
    return { data: null };
  }
}
