import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import DeviceToolbar from './components/DeviceToolbar';
import DeviceTable from './components/DeviceTable';
import useAsync from '../../../asyncNet';
import { Backdrop, CircularProgress, Typography } from '@material-ui/core';
import regionCode from '../../../common/regionCode';
import {
  deviceSearch
} from '../APIs/aironeApis';
import { NO_PERMISSION, NO_PERMISSION_WARNING, READ_PERMISSION } from '../../Common/Config/naviwatchConfig';
import { userInfoContext } from '../../../App';

const STRING_MODEL_NTR10PW = 'NTR10PW';
const STRING_MODEL_NTR10PW2ND = 'NTR10PW2ND';
const STRING_MODEL_NRT20D = 'NRT20D';
const STRING_MODEL_NRT20L = 'NRT20L';
const STRING_MODEL_NRT21D = 'NRT21D';
const STRING_MODEL_NRT20DS = 'NRT20DS';
const STRING_MODEL_NRT20DSW = 'NRT20DSW';
const STRING_MODEL_NRT30L = 'NRT30L';
const STRING_MODEL_NRT30D = 'NRT30D';
const STRING_MODEL_NRT30SFT = 'NRT30SFT';
const STRING_MODEL_NRZ530S = 'NRZ530S';
const STRING_MODEL_NRZ530Z = 'NRZ530Z';
const STRING_MODEL_NRG530S = 'NRG530S';
const STRING_MODEL_NRT30S = 'NRT30S';
const STRING_MODEL_NRT530Z = 'NRT530Z';
const STRING_MODEL_NRT530S = 'NRT530S';
const STRING_MODEL_NRT530S2 = 'NRT530S2';
const STRING_MODEL_NRT530Z2 = 'NRT530Z2';
const STRING_MODEL_NRT530S2W2ND = 'NRT530S2W2ND';
const STRING_MODEL_NRT530Z2W2ND = 'NRT530Z2W2ND';
const STRING_MODEL_NRT530T2W = 'NRT530T2W';
const STRING_MODEL_WP50001 = 'WP50001';

const STRING_CONNECTION_ON = '연결 상태';
const STRING_CONNECTION_OFF = '비연결 상태';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));


const Device = ({ history }) => {
  const classes = useStyles();
  const [query, setQuery] = useState({
    deviceid: '',
    userid: '',
    username: '',
    modelcode: '',
    connection: '',
    regionCodeFrom: '',
    regionCodeTo: ''
  });
  const [state, tryRefetch] = useAsync(()=>getDeviceSearch(), [], true);
  const { loading, data: devices } = state;
  const getDeviceSearch = ()=>{
    return deviceSearch(query.deviceid, query.userid, query.username, query.modelcode, query.connection,
      query.regionCodeFrom, query.regionCodeTo)
  };
  const [noPermissionMsg, setNoPermissionMsg] = useState("");
  const authInfo =  useContext(userInfoContext);
  const [permission, setPermission] = useState(NO_PERMISSION);
  useEffect(()=>{
    var pageInfo = sessionStorage.getItem('pages');
    if(pageInfo!=="undefined") {
      var pageInfoObj = JSON.parse(pageInfo);
      var pageArray = pageInfoObj.filter(page => page.name.toLowerCase().includes("device"));
      if (pageArray !== undefined && pageArray.length > 0) {
        var page = pageArray[0];
        if(page.permission===undefined){
          setNoPermissionMsg(NO_PERMISSION_WARNING);
        }
        setPermission(page.permission);
      }
    }
  }, [authInfo.pages]);

  useEffect(() => {
    console.log('useEffect -- modelcode' + query.modelcode);
    if (query.deviceid !== '' || query.userid !== '' || query.modelcode !== '' || query.connection !== ''
      || query.regionCodeFrom !== ''|| query.regionCodeTo !== '' || query.username!=='') {
      tryRefetch();
    }
  }, [query]);

  const searchDevice = (search) => {
    var modelNumber = '';
    if (search.model === STRING_MODEL_NTR10PW) {
      modelNumber = 2;
    } else if (search.model === STRING_MODEL_NTR10PW2ND) {
      modelNumber = 3;
    } else if (search.model === STRING_MODEL_NRT20D) {
      modelNumber = 4;
    } else if (search.model === STRING_MODEL_NRT20L) {
      modelNumber = 5;
    } else if (search.model === STRING_MODEL_NRT21D) {
      modelNumber = 6;
    } else if (search.model === STRING_MODEL_NRT20DS) {
      modelNumber = 7;
    } else if (search.model === STRING_MODEL_NRT20DSW) {
      modelNumber = 8;
    } else if (search.model === STRING_MODEL_NRT30L) {
      modelNumber = 1000;
    } else if (search.model === STRING_MODEL_NRT30D) {
      modelNumber = 1001;
    } else if (search.model === STRING_MODEL_NRT30SFT) {
      modelNumber = 1002;
    } else if (search.model === STRING_MODEL_NRZ530S) {
      modelNumber = 1003;
    } else if (search.model === STRING_MODEL_NRZ530Z) {
      modelNumber = 1004;
    } else if (search.model === STRING_MODEL_NRG530S) {
      modelNumber = 1005;
    } else if (search.model === STRING_MODEL_NRT30S) {
      modelNumber = 1100;
    } else if (search.model === STRING_MODEL_NRT530Z) {
      modelNumber = 1101;
    } else if (search.model === STRING_MODEL_NRT530S) {
      modelNumber = 1102;
    } else if (search.model === STRING_MODEL_NRT530S2) {
      modelNumber = 1103;
    } else if (search.model === STRING_MODEL_NRT530Z2) {
      modelNumber = 1104;
    } else if (search.model === STRING_MODEL_NRT530S2W2ND) {
      modelNumber = 1105;
    } else if (search.model === STRING_MODEL_NRT530Z2W2ND) {
      modelNumber = 1106;
    } else if (search.model === STRING_MODEL_NRT530T2W) {
      modelNumber = 1107;
    } else if (search.model === STRING_MODEL_WP50001) {
      modelNumber = 50001;
    }



    var connection = '';
    if (search.connection === STRING_CONNECTION_ON) {
      connection = 1;
    } else if (search.connection === STRING_CONNECTION_OFF) {
      connection = 0;
    }
    var regionFrom = '';
    var regionTo = '';
    console.log('searchDevice call, region : '+search.region);
    if(search.region !==''){
      for ( var index=0 ; index< regionCode.length ; ++index){
        console.log('stringfy : '+JSON.stringify(regionCode[index]));

        console.log('loop try region.name : '+regionCode[index]['name']);
        if (regionCode[index]['name'] === search.region){
          var regionCodes = regionCode[index]['regionCode'];
          if(regionCodes.length===1){
            regionFrom = regionCodes[0]['code'][0];
            regionTo = regionCodes[0]['code'][1];
          }
          console.log('regionMain:'+regionFrom);
          console.log('regionSub1:'+regionTo);
          break;
        }
      }

    }

    setQuery({
      deviceid: search.deviceid,
      userid: search.userid,
      username: search.username,
      modelcode: modelNumber,
      connection: connection,
      regionCodeFrom : regionFrom,
      regionCodeTo: regionTo
    });
  };


  useEffect(() => {
    if (sessionStorage.getItem('naviwatch_permission') !== 'true')
      history.push('/');
  }, []);


  return (
    <div >
      {permission>=READ_PERMISSION?
        <div className={classes.root}>
          <Backdrop className={classes.backdrop} open={loading}>
            <CircularProgress color={'primary'}/>
          </Backdrop>
          < DeviceToolbar callbackFunc={searchDevice}/>
          <div className={classes.content}>
          {
            loading === false && devices !== null && devices !== undefined && <DeviceTable devices={devices.devices}/>
          }
          </div>
        </div>:
        <Typography className={classes.root}>{noPermissionMsg}</Typography>
      }
    </div>
  );
};

export default Device;
