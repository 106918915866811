export default [
  {
    PRODUCT_ID: 0,
    PRODUCT_NAME: "communicationmodule",
    PRODUCT_IMAGE: "/images/nscada/communicationmodule.png",
    PRODUCT_ENABLE: true
  },
  {
    PRODUCT_ID: 1,
    PRODUCT_NAME: "controller",
    PRODUCT_IMAGE: "/images/nscada/controller.png",
    PRODUCT_ENABLE: true
  }
];
