import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/core/styles';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination
} from '@material-ui/core';

import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  MinInner: {
    minWidth: '10vw',
    maxWidth: '15vw'
  },
  TableHeader: {
    minWidth: '10vw',
    maxWidth: '40vw',
    borderWidth: 0.1,
    borderColor: theme.palette.action.selected,
    borderStyle:"solid"
  },
  smallInner: {
    width: '8vw',
    maxWidth: '50vw',
    minWidth: '8vw'
  },
  mouseOver:{
    cursor: 'pointer'
  },
  tableRow: {
    width: '100vw'
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  deviceView: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  deviceDescriptView: {
    display: 'flex',
    flexDirection: 'column'
  },
  deviceDescriptDivider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

const STRING_MAIN_SECTION_SEARCHER = '취급자 식별정보';
const STRING_MAIN_SECTION_USER = '정보주체 식별정보';
const STRING_MAIN_SECTION_CONNECTION_INFO = '접속 기록';
const STRING_MAIN_SECTION_SEARCH_INFO = '처리 내역';
const STRING_SUB_SEARCHER_ID='아이디';
const STRING_SUB_USER_IDENTIFIER='식별정보';
const STRING_SUB_CONNECTION_TIME='접속시간';
const STRING_SUB_CONNECTION_MENU='접속메뉴';
const STRING_SUB_CONNECTION_IP='접속지';
const STRING_SUB_SEARCHINFO_WHAT='처리업무';
const STRING_SUB_SEARCHINFO_RESULT='처리결과';


const FirmwareModifyTable = props => {
  const { className, records, ...rest } = props;
  const classes = useStyles();
  const [selectedUsers] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  return (
    <div>
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table >
                <TableHead>
                  <TableRow >
                    <TableCell align={'center'} className={classes.TableHeader} colSpan={1}>
                      <span><b>{STRING_MAIN_SECTION_SEARCHER}</b></span>
                    </TableCell>
                    <TableCell align={'center'} className={classes.TableHeader} colSpan={1}>
                     <span><b> {STRING_MAIN_SECTION_USER}</b></span>
                    </TableCell>
                    <TableCell align={'center'} className={classes.TableHeader} colSpan={3}>
                      <span><b>{STRING_MAIN_SECTION_CONNECTION_INFO}</b></span>
                    </TableCell>
                    <TableCell align={'center'} className={classes.TableHeader} colSpan={2}>
                      <span><b>{STRING_MAIN_SECTION_SEARCH_INFO}</b></span>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align={'center'} className={classes.TableHeader} ><span><b>{STRING_SUB_SEARCHER_ID}</b></span></TableCell>

                    <TableCell align={'center'} className={classes.TableHeader} ><span><b>{STRING_SUB_USER_IDENTIFIER}</b></span></TableCell>

                    <TableCell align={'center'} className={classes.TableHeader} ><span><b>{STRING_SUB_CONNECTION_TIME}</b></span></TableCell>
                    <TableCell align={'center'} className={classes.TableHeader} ><span><b>{STRING_SUB_CONNECTION_MENU}</b></span></TableCell>
                    <TableCell align={'center'} className={classes.TableHeader} ><span><b>{STRING_SUB_CONNECTION_IP}</b></span></TableCell>

                    <TableCell align={'center'} className={classes.TableHeader} ><span><b>{STRING_SUB_SEARCHINFO_WHAT}</b></span></TableCell>
                    <TableCell align={'center'} className={classes.TableHeader} ><span><b>{STRING_SUB_SEARCHINFO_RESULT}</b></span></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody >
                  {records !== null && records !== undefined && records.slice((page * rowsPerPage), ((page + 1) * rowsPerPage)).map(record => (
                    <TableRow
                      className={classes.tableRow}
                      hover
                      key={record.ID}
                      selected={selectedUsers.indexOf(record.ID) !== -1}

                    >
                      <TableCell align={'center'} className={classes.MinInner}>
                        {record.SEARCHER}
                      </TableCell>
                      <TableCell className={classes.MinInner}>
                        <ul>{record.PARAMETER.split(',').map(str=><li key={str}>{str}</li>)}</ul>
                      </TableCell>
                      <TableCell align={'center'} className={classes.MinInner}>
                        {moment((record.DATE-(60 * 60 * 9)) * 1000).format('YYYY-MM-DD HH:mm')}
                      </TableCell>
                      <TableCell align={'center'} className={classes.MinInner}>
                        {record.NAME}
                      </TableCell>
                      <TableCell align={'center'} className={classes.MinInner}>
                        {record.IP}
                      </TableCell>
                      <TableCell align={'center'} className={classes.MinInner}>
                        {record.FEATURE}
                      </TableCell>
                      <TableCell className={classes.MinInner}>
                        <Tooltip disableFocusListener title="Copy to clipboard" className={classes.mouseOver} >
                          <CopyToClipboard text={record.RESULT} >
                            <Typography noWrap={true}  >
                              {record.RESULT}
                            </Typography>
                          </CopyToClipboard>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={records !== undefined?records.length:0}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </CardActions>
      </Card>
    </div>
  );
};

FirmwareModifyTable.propTypes = {
  className: PropTypes.string,
  records: PropTypes.array.isRequired
};

export default FirmwareModifyTable;
