import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Alert from '@material-ui/lab/Alert';
import Divider from '@material-ui/core/Divider';
import moment from 'moment';
import { Typography } from '@material-ui/core';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import DialogActions from '@material-ui/core/DialogActions';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import useAsync from '../../../../../asyncNet';
import { getModelCount } from '../../../../SmartTok/APIs/tokapis';

const STRING_ID_LABEL = '사용자 ID';
const STRING_EMAIL_LABEL = '사용자 메일';
const STRING_NAME_LABEL = '사용자 이름';

const STRING_PHONE_LABEL = '사용자 전화번호';
const STRING_REGISTER_AT_LABEL = '사용자 가입 날짜';
const STRING_DEVICE_ID_LABAL = '디바이스 ID';
const STRING_DEVICE_SERVER_CONNECTION_ID_LABAL = '디바이스 ID (서버)';
const STRING_MODEL_LABEL = '모델 정보';
const STRING_SW_MAJOR_VERSION = 'SW MAJOR VERSION';
const STRING_SW_MINOR_VERSION = 'SW MINOR VERSION';
const STRING_SERVER_CONNECTION = '서버 연결 여부';
const STRING_ROOMCON_CONNECTION = '룸콘 연결 여부';
const STRING_AREA = '지역';
const STRING_SSID = 'SSID';
const STRING_NEXT_LABEL = '다음';
const STRING_BACK_LABEL = '이전';
const STRING_WARNING_USERINFO = '사용자 개인정보 입니다. 정보 이용에 각별한 주의를 바랍니다.';
const STRING_FORCED_PAIRING = '강제 페어링';


const useStyles = makeStyles(theme => ({

  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  deviceView: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  deviceDescriptView: {
    display: 'flex',
    flexDirection: 'column'
  },
  deviceDescriptDivider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  img:{
    marginTop: theme.spacing(2),
  }
}));

const getModelName = (modelCode) => {
  var modelName = 'Not defined';
  if (modelCode === 2)
    modelName = 'NTR10PW';
  else if (modelCode === 3)
    modelName = 'NTR10PW2ND';
  else if (modelCode === 4)
    modelName = 'NRT20D';
  else if (modelCode === 5)
    modelName = 'NRT20L';
  else if (modelCode === 6)
    modelName = 'NRT21D';
  else if (modelCode === 7)
    modelName = 'NRT20DS';
  else if (modelCode === 8)
    modelName = 'NRT20DSW';
  else if (modelCode === 0x1E)
    modelName = 'NTS20W';
  else if (modelCode === 0x1F)
    modelName = 'NTS20W';
  else if (modelCode === 0x20)
    modelName = 'NAA20D';
  else if (modelCode === 0x21)
    modelName = 'NTS20W3RD';
  else if (modelCode === 0x22)
    modelName = 'NAA30DM';
  else if (modelCode === 0x23)
    modelName = 'NAA-21DM';
  else if (modelCode === 0x24)
    modelName = 'NAA-10DM';

  return modelName;
};

const isRoomController = (modelCode) => {
  if(modelCode === 2 || modelCode === 3 || modelCode === 4 ||
  modelCode === 5 || modelCode === 6 || modelCode === 7 || modelCode === 8){
    return true;
  }else{
    return false;
  }
};

const isAirMonitor = (modelCode) =>{
  if(modelCode === 0x1E || modelCode === 0x1F || modelCode === 0x20 ||
    modelCode === 0x21 || modelCode === 0x22 || modelCode === 0x23 || modelCode === 0x24 ){
    return true;
  }else{
    return false;
  }
};


const getConnectionValue = (connect) => {
  var conn = 'Not defined';
  if (connect === 0)
    conn = '연결되지 않음';
  else if (connect === 1)
    conn = '연결됨';

  return conn;
};

const getRoomConConnectionValue = (connect) => {
  var conn = 'Not defined';
  if (connect === 0)
    conn = 'Default값';
  else if (connect === 1)
    conn = '연결되지 않음';
  else if (connect === 2)
    conn = '연결됨';

  return conn;
};

const getRoomconImageSrc = (modelCode) => {
  var defaultImage = '/images/airone/roomcon.png';
  if (modelCode === 4)
    defaultImage = '/images/airone/roomcon_prism.png';
  else if (modelCode === 5)
    defaultImage = '/images/airone/roomcon_prism.png';
  else if (modelCode === 6)
    defaultImage = '/images/airone/roomcon_prism.png';
  else if (modelCode === 7)
    defaultImage = '/images/airone/roomcon_prism.png';
  else if (modelCode === 8)
    defaultImage = '/images/airone/roomcon_prism.png';
  return defaultImage;
};


const getAirMonitorImageSrc = (modelCode) => {
  var defaultImage = '/images/airone/airmonitor.png';
  if (modelCode === 0x20)
    defaultImage = '/images/airone/prism_airmonitor.png';
  else if (modelCode === 0x21)
    defaultImage = '/images/airone/prism_airmonitor.png';
  else if (modelCode === 0x22)
    defaultImage = '/images/airone/radon_airmonitor.png';
  else if (modelCode === 0x23)
    defaultImage = '/images/airone/prism_airmonitor.png';
  else if (modelCode === 0x24)
    defaultImage = '/images/airone/radon_airmonitor.png';
  return defaultImage;
};

const checkNeededForcePairing = (mobileAdditional, serverAdditional) => {
  if (mobileAdditional !== serverAdditional && serverAdditional != null) {
    return true;
  }
  return false;
};


const DetailDialog = props => {
  const { open, handleClose, userInfo, rendering } = props;
  const classes = useStyles();

  const [activeStep, setActiveStep] = React.useState(0);
  const [maxSteps, setMaxStep] = React.useState(2);
  const [state, forcedPairing] = useAsync(tryForcedPairing, [], true);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  async function tryForcedPairing() {
    rendering();
  }

  useEffect(() => {
    if (userInfo !== null) {
      setMaxStep(userInfo.device.length);
    }
  }, [userInfo]);

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">User Information</DialogTitle>
        <DialogContent>
          <DialogContentText component={'div'}>
            <Alert severity={'error'}><span style={{ color: 'red' }}>{STRING_WARNING_USERINFO}</span></Alert>
            <Divider className={classes.divider}/>
            <span><b>{STRING_ID_LABEL}</b> : {userInfo !== null && userInfo.userdetail.USER_ID}</span>
            <Divider className={classes.divider}/>
            <span><b>{STRING_NAME_LABEL}</b> : {userInfo !== null && userInfo.userdetail.USER_NAME}</span>
            <Divider className={classes.divider}/>
            <span><b>{STRING_EMAIL_LABEL}</b> : {userInfo !== null && userInfo.userdetail.USER_EMAIL}</span>
            <Divider className={classes.divider}/>
            <span><b>{STRING_PHONE_LABEL}</b> : {userInfo !== null && userInfo.userdetail.USER_PHONE}</span>
            <Divider className={classes.divider}/>
            <span><b>{STRING_REGISTER_AT_LABEL}</b> : {userInfo !== null && moment(userInfo.userdetail.USER_REGISTER * 1000).format('YYYY-MM-DD')}</span>


            <Divider className={classes.divider}/>
            { userInfo!=null && userInfo.device!=null &&
            userInfo.device.length>0 && (isRoomController(userInfo.device[activeStep].MODEL_CODE)  === true ?
              <div>
                <Typography variant={'h6'}>RoomController : </Typography>
                <div className={classes.deviceView}>
                  <img
                    className={classes.img}
                    src={getRoomconImageSrc(userInfo.device[activeStep].MODEL_CODE)}
                    height={'30%'}
                    width={'30%'}
                  />
                  <div className={classes.deviceDescriptView}>
                    <span><b>{STRING_DEVICE_ID_LABAL}</b> : {userInfo.device[activeStep].MAC_ADDRESS + userInfo.device[activeStep].ADDITIONAL_VALUE}</span>
                    <Divider className={classes.deviceDescriptDivider}/>
                    <span><b>{STRING_MODEL_LABEL}</b> : {getModelName(userInfo.device[activeStep].MODEL_CODE)}</span>
                    <Divider className={classes.deviceDescriptDivider}/>
                    <span><b>{STRING_SW_MAJOR_VERSION}</b> : {userInfo.device[activeStep].SW_MAJOR_VERSION}</span>
                    <Divider className={classes.deviceDescriptDivider}/>
                    <span><b>{STRING_SW_MINOR_VERSION}</b> : {userInfo.device[activeStep].STRING_SW_MINOR_VERSION}</span>
                    <Divider className={classes.deviceDescriptDivider}/>
                    <span><b>{STRING_SERVER_CONNECTION}</b> : {getConnectionValue(userInfo.device[activeStep].CONNECTED)}</span>
                    <Divider className={classes.deviceDescriptDivider}/>
                    <span><b>{STRING_AREA}</b> : {userInfo.device[activeStep].AREA}</span>
                    <Divider className={classes.deviceDescriptDivider}/>
                    <span><b>{STRING_SSID}</b> : {userInfo.device[activeStep].SSID}</span>
                  </div>
                </div>
              </div> : (isAirMonitor(userInfo.device[activeStep].MODEL_CODE) === true ?
              <div>
                <Typography variant={'h6'}>AirMonitor : </Typography>
                <div className={classes.deviceView}>
                  <img
                    className={classes.img}
                    src={getAirMonitorImageSrc(userInfo.device[activeStep].MODEL_CODE)}
                    height={'30%'}
                    width={'30%'}
                  />
                  <div className={classes.deviceDescriptView}>
                    <span><b>{STRING_DEVICE_ID_LABAL}</b> : {userInfo.device[activeStep].MAC_ADDRESS + userInfo.device[activeStep].ADDITIONAL_VALUE}</span>
                    <Divider className={classes.deviceDescriptDivider}/>
                    <span><b>{STRING_MODEL_LABEL}</b> : {getModelName(userInfo.device[activeStep].MODEL_CODE)}</span>
                    <Divider className={classes.deviceDescriptDivider}/>
                    <span><b>{STRING_SW_MAJOR_VERSION}</b> : {userInfo.device[activeStep].SW_MAJOR_VERSION}</span>
                    <Divider className={classes.deviceDescriptDivider}/>
                    <span><b>{STRING_SW_MINOR_VERSION}</b> : {userInfo.device[activeStep].STRING_SW_MINOR_VERSION}</span>
                    <Divider className={classes.deviceDescriptDivider}/>
                    <span><b>{STRING_SERVER_CONNECTION}</b> : {getConnectionValue(userInfo.device[activeStep].CONNECTED)}</span>
                    <Divider className={classes.deviceDescriptDivider}/>
                    <span><b>{STRING_ROOMCON_CONNECTION}</b> : {getRoomConConnectionValue(userInfo.device[activeStep].ROOM_CONTROLLER_CONNECTED)}</span>
                    <Divider className={classes.deviceDescriptDivider}/>
                    <span><b>{STRING_SSID}</b> : {userInfo.device[activeStep].SSID}</span>
                  </div>
                </div>
              </div> : <div>
                  <Typography variant={'h6'}>ODU : </Typography>
                    <img
                      className={classes.img}
                      src={'/images/airone/odu.png'}
                      height={'30%'}
                      width={'30%'}
                    />
                    <div className={classes.deviceView}>
                  </div>
                </div>
                ))
            }
              <MobileStepper
                steps={maxSteps}
                position="static"
                variant="text"
                activeStep={activeStep}
                nextButton={
                <Button
                  size="small"
                  onClick={handleNext}
                  disabled={activeStep === maxSteps - 1}
                >
                  {STRING_NEXT_LABEL}
                  <KeyboardArrowRight/>
                </Button>
                }
                backButton={
                <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                  <KeyboardArrowLeft/>
                  {STRING_BACK_LABEL}
                </Button>
              }
              />

            <Divider className={classes.divider}/>
          </DialogContentText>
          <form className={classes.form} noValidate>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

DetailDialog.propTypes = {
  className: PropTypes.string
};

export default DetailDialog;
