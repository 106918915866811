import React from 'react';
import clsx from 'clsx';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { LinearProgress } from '@material-ui/core';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
  } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  deviceView: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  deviceDescriptView: {
    display: 'flex',
    flexDirection: 'column'
  },
  deviceDescriptDivider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

const STRING_FAMILY_SEQ = '가족 순번';
const STRING_USER_ID = '사용자 ID';
const STRING_USER_TYPE = '사용자 타입';
const STRING_USER_NAME = '사용자 이름';
const STRING_REGISTER_AT = '가족 등록일';

const FamilyDialog = props => {
  const { familyOpen, handleFamilyClose, familyInfo } = props;
  const classes = useStyles();

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={familyOpen}
        onClose={handleFamilyClose}
        aria-labelledby="max-width-dialog-title"
      >
        {familyInfo === null ? <LinearProgress /> : null}
        <DialogContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align={'center'}><span><b>{STRING_FAMILY_SEQ}</b></span></TableCell>
                <TableCell align={'center'}><span><b>{STRING_USER_ID}</b></span></TableCell>
                <TableCell align={'center'}><span><b>{STRING_USER_TYPE}</b></span></TableCell>
                <TableCell align={'center'}><span><b>{STRING_USER_NAME}</b></span></TableCell>
                <TableCell align={'center'}><span><b>{STRING_REGISTER_AT}</b></span></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {familyInfo !== null && familyInfo !== undefined && familyInfo.data.map(family => (
                <TableRow>
                  <TableCell align={'center'}>
                    {family.familySeq}
                  </TableCell>
                  <TableCell align={'center'}>
                    {family.userId}
                  </TableCell>
                  <TableCell align={'center'}>
                    {family.userType}
                  </TableCell>
                  <TableCell align={'center'}>
                    {family.userName}
                  </TableCell>
                  <TableCell align={'center'}>
                    {family.registerAt}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFamilyClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

FamilyDialog.propTypes = {
  className: PropTypes.string
};

export default FamilyDialog;
