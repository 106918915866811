import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { HorizontalBar } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core/styles';
import palette from 'theme/palette';

import {
  Card,
  CardHeader,
  CardContent,
  Divider,
} from '@material-ui/core';

import useAsync from '../../../../../asyncNet';
import {
  statisticsCPUResetCount
} from '../../../APIs/aironeApis';

const STRING_TITLE='모델별 CPU Reset 합계';
const STRING_MODEL_CODE_2 = '1세대';
const STRING_MODEL_CODE_3 = '듀얼링크';
const STRING_MODEL_CODE_4 = '프리즘';
const STRING_MODEL_CODE_5 = '제습청정';
const STRING_MODEL_CODE_6 = '중대형';
const STRING_COUNT = 'count';

const useStyles = makeStyles(() => ({
  height: '100%',
  card: {
    backgroundColor: '#fff9db'
  },
  chartContainer: {
    height: '100%',
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const options = {
  legend: {
    display: false
  }
};

const CPUResetCountChart = props => {

  const { className, ...rest } = props;
  const [state, tryRefetch] = useAsync(statisticsCPUResetCount, []);
  const [chartdata, setChartData] = useState({
    labels: [],
    datasets: []
  });
  const [chartLabel, setChartLabel] = useState([]);
  const [chartValue, setChartValue] = useState([]);
  const [chartColor, setChartColor] = useState([]);

  const { data: metric } = state;
  const classes = useStyles();

  const makeChartValue = (numeric, count) =>{
    let tempLevel = [];
    let tempValue = [];
    let tempColor = [];

    for (var i = 0; i < numeric.length; i++) {
      switch (parseInt(numeric[i])) {
        case 2:
          tempLevel.push(STRING_MODEL_CODE_2);
          tempValue.push(count[i]);
          tempColor.push('#FFFAFD');
          break;
        case 3:
          tempLevel.push(STRING_MODEL_CODE_3);
          tempValue.push(count[i]);
          tempColor.push('#FFFFCD');
          break;
        case 4:
          tempLevel.push(STRING_MODEL_CODE_4);
          tempValue.push(count[i]);
          tempColor.push('#FF730F');
          break;
        case 5:
          tempLevel.push(STRING_MODEL_CODE_5);
          tempValue.push(count[i]);
          tempColor.push('#FFDACD');
          break;
        case 6:
          tempLevel.push(STRING_MODEL_CODE_6);
          tempValue.push(count[i]);
          tempColor.push('#FDDACD');
          break;
      }
    }
    setChartLabel(tempLevel);
    setChartValue(tempValue);
    setChartColor(tempColor);

  };

  useEffect(() => {
    try {
        setChartData({
          ...chartdata,
          labels: chartLabel,
          datasets: [
              {
                label: STRING_COUNT,
                borderWidth: 1,
                backgroundColor: chartColor,
                data: chartValue,
              }
              ]
          }
        );

    } catch (e) {
      console.log('metric update failed :' + JSON.stringify(e));
    }
  }, [chartLabel, chartValue]);

  useEffect(()=>{
    if (metric !== null && metric !== undefined) {
      console.log('update cpu reset count: '+JSON.stringify(metric));
      makeChartValue(metric['modelCode'], metric['count']);
    }
  }, [metric]);


  return (
    <div>
      <Card
        {...rest}
        className={clsx(classes.root, className)}>
        <CardHeader
          title={STRING_TITLE}
        />
        <Divider/>
        <CardContent>
          {chartdata !== undefined  &&
          <div className={classes.chartContainer}>
            <HorizontalBar
              data={chartdata}
              options={options}
            />
          </div>
          }
        </CardContent>
        <Divider/>
      </Card>
    </div>
  );
};

CPUResetCountChart.propTypes = {
  className: PropTypes.string
};

export default React.memo(CPUResetCountChart);
