import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/core/styles';

import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination
} from '@material-ui/core';

import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  deviceView: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  deviceDescriptView: {
    display: 'flex',
    flexDirection: 'column'
  },
  deviceDescriptDivider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));
const STRING_ID_LABEL = '사용자 ID';
const STRING_LAST_LOGIN = '최종 접속 일자';
const STRING_NOTIFICATION_DATE = '고지 발송 일자';
const STRING_DORMANCY_DATE = '휴면 처리 일자';



const DormancyTable = props => {
  const { className, dormancy, type, ...rest } = props;
  const classes = useStyles();
  const [selectedUsers] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  return (
    <div>
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align={'center'}><span><b>{STRING_ID_LABEL}</b></span></TableCell>
                    <TableCell align={'center'}><span><b>{STRING_LAST_LOGIN}</b></span></TableCell>
                    {type==="history"&&<TableCell align={'center'}><span><b>{STRING_NOTIFICATION_DATE}</b></span></TableCell>}
                    <TableCell align={'center'}><span><b>{STRING_DORMANCY_DATE}</b></span></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dormancy !== null && dormancy !== undefined && dormancy.slice((page * rowsPerPage), ((page + 1) * rowsPerPage)).map(user => (
                    <TableRow
                      className={classes.tableRow}
                      hover
                      key={user.id}
                      selected={selectedUsers.indexOf(user.id) !== -1}
                    >
                      <TableCell align={'center'}>
                        {user.id}
                      </TableCell>
                      <TableCell align={'center'}>
                        {user.lastConnection?moment((user.lastConnection-(60 * 60 * 9)) * 1000).format('YYYY-MM-DD HH:mm'):"-"}
                      </TableCell>
                      {type === "history" &&
                      <TableCell align={'center'}>
                        {user.notificationDate ? moment((user.notificationDate - (60 * 60 * 9)) * 1000).format('YYYY-MM-DD HH:mm') : "-"}
                      </TableCell>
                      }
                      <TableCell align={'center'}>
                        {user.dormancyDate?moment((user.dormancyDate-(60 * 60 * 9)) * 1000).format('YYYY-MM-DD HH:mm'):"-"}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={dormancy !== undefined?dormancy.length:0}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </CardActions>
      </Card>
    </div>
  );
};

DormancyTable.propTypes = {
  className: PropTypes.string,
  dormancy: PropTypes.array.isRequired
};

export default DormancyTable;
