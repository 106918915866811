import React, { useState } from 'react';
import {
  Dialog, DialogActions, DialogContent, Button,
  makeStyles, Backdrop, CircularProgress
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';
import { STRING_CLOSE } from '../../../StringTable/StringTable';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));

const AlertPopup = props => {
  const { open, handleClose, comment, color, severity, comment2 } = props;
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const handleCloseClick = () => {
    setLoading(true);
    handleClose();
    setLoading(false);
  };

  return (
    <div>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color={'primary'} />
      </Backdrop>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Alert severity={severity}>
            <span style={{ color: color }}>
              {comment}
              <div>
                {comment2 && comment2}
              </div>
            </span>
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseClick} color="primary" autoFocus>
            {STRING_CLOSE}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

AlertPopup.propTypes = {
  className: PropTypes.string
};

export default AlertPopup;
