import { makeStyles } from '@material-ui/core/styles';
import React, { useContext, useEffect, useState } from 'react';
import useAsync from '../../../asyncNet';
import axios from 'axios';
import { Backdrop, CircularProgress, Typography } from '@material-ui/core';
import DormancyTable from './components/DormancyTable';
import { DormancyHistoryToolbar } from './components';
import { dormancyGetDormancyHistory } from '../APIs/aironeApis';
import {
  NO_PERMISSION,
  NO_PERMISSION_WARNING,
  NO_WHITELIST_INSIDE_WARNING,
  READ_PERMISSION
} from '../../Common/Config/naviwatchConfig';
import { userInfoContext } from '../../../App';
import { naviwatchCheckWhiteListValidation } from '../../Common/APIs/naviwatchApis';
import publicIp from 'public-ip';
const STRING_SEARCH_ALL = '전체';
const STRING_SEARCH_SEPERATE = '개별';
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));


const DormancyHistory = ({ history }) => {
  const classes = useStyles();
  const [query, setQuery] = useState({
    condition: '',
    userid: '',
  });
  const [dormancyHistoryState, tryGetDormancy] = useAsync(getDormancy, [], true);
  const [ipCheckState, tryCheckWhitelist] = useAsync(checkIpValidation, [], true);

  const { loading: dormancyLoading, data: dormancy } = dormancyHistoryState;
  const { loading: ipCheckLoading, data: ipValid } = ipCheckState;

  const [ipAddress, setIpAddress] = useState(undefined);

  const [noPermissionMsg, setNoPermissionMsg] = useState("");
  const authInfo =  useContext(userInfoContext);
  const [permission, setPermission] = useState(NO_PERMISSION);
  useEffect(()=>{
    var pageInfo = sessionStorage.getItem('pages');
    if(pageInfo!=="undefined") {
      var pageInfoObj = JSON.parse(pageInfo);
      var pageArray = pageInfoObj.filter(page => page.name.toLowerCase().includes("inactive"));
      if (pageArray !== undefined && pageArray.length > 0) {
        var page = pageArray[0];
        if(page.permission===undefined){
          setNoPermissionMsg(NO_PERMISSION_WARNING);
        }
        setPermission(page.permission);
      }
    }
  }, [authInfo.pages]);
  async function getDormancy() {
    return dormancyGetDormancyHistory(query.condition, query.userid);
  }

  async function checkIpValidation() {
    return naviwatchCheckWhiteListValidation(ipAddress);
  }

  const searchDormacy = (searchdata) => {
    var cond = '';
    if(searchdata.condition === STRING_SEARCH_ALL){
      cond = 'all'
    }else if (searchdata.condition === STRING_SEARCH_SEPERATE){
      cond = 'each'
    }

    setQuery(

      {
        condition: cond,
        userid: searchdata.userid
      });
    console.log('clicked : ' + JSON.stringify(query));
  };

  useEffect(() => {
    if (query.userid !== '' || query.condition !== '') {
      tryGetDormancy();
    }
  }, [query]);

  useEffect(()=>{
    if(ipCheckState.data===false){
      setNoPermissionMsg(NO_WHITELIST_INSIDE_WARNING+`(${ipAddress})`);
    }
  }, [ipCheckState]);

  useEffect(()=>{
    if(ipAddress!==undefined){
      tryCheckWhitelist();
    }
  }, [ipAddress]);

  useEffect(() => {
    if (sessionStorage.getItem('naviwatch_permission') !== 'true')
      history.push('/');
    (async () => {
      setIpAddress(await publicIp.v4());
    })();
  }, []);

  return (
    <div>
      {permission>=READ_PERMISSION && ipValid===true ?
        <div className={classes.root}>
          <Backdrop className={classes.backdrop} open={dormancyLoading||ipCheckLoading}>
            <CircularProgress color={'primary'}/>
          </Backdrop>
          <DormancyHistoryToolbar callbackFunc={searchDormacy}/>
          <div className={classes.content}>
            {
              dormancyLoading === false && dormancy !== null && dormancy !== undefined &&
              <DormancyTable dormancy={dormancy.dormancyList} type={"history"}/>
            }
          </div>
        </div>:
        <Typography className={classes.root}>{noPermissionMsg}</Typography>
      }
    </div>
  );
};

export default DormancyHistory;
