import React, { useEffect, useState } from 'react';
import {
  Dialog, DialogActions, DialogContent, Button,
  DialogTitle, Table, TableHead, TableRow,
  TableCell, TableBody, Card, CardActions,
  makeStyles, Backdrop, CircularProgress,
  Divider, TableContainer, CardContent, Chip,
  TablePagination
} from '@material-ui/core';
import PropTypes from 'prop-types';
import useAsync from '../../../../../asyncNet';
import TextField from '@material-ui/core/TextField';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { getEngineerInfo, searchEngineer, setGroupEngineer } from '../../../APIs/tokapis';
import {
  STRING_SETTING_ENGINEER,
  STRING_ENGINEER_LIST,
  STRING_INPUT_NAME_OR_CENTER,
  STRING_CONFIRM,
  STRING_ADD,
  STRING_NAME,
  STRING_CENTER,
  STRING_LOGIN_ID,
  STRING_PHONE_NUMBER,
  STRING_ENGINEER_ID,
  STRING_DELETE,
  STRING_CLEAN,
  STRING_CANCEL,
  STRING_SEARCH,
  STRING_SETTING_ENGINEER_COMPLETE,
  STRING_DUPLICATE_ENGINEER
} from '../../../StringTable/StringTable';
import AlertPopup from './AlertPopup';

const useStyles = makeStyles(theme => ({
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  icons: {
    marginLeft: theme.spacing(2),
    padding: theme.spacing(0)
  },
  fwDescription: {
    marginLeft: theme.spacing(2),
    width: "75%"
  },
  button: {
    marginLeft: theme.spacing(2),
    justifyContent: "center",
    marginBottom: "16px"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  root: {
    justifyContent: "center",
    marginBottom: "16px",
    width: "100%"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2)
  }
}));

const BSEngineerDialog = props => {
  const { open, handleClose, refetch, selectedFW } = props;
  const [state, tryRefetch] = useAsync(() => getEngineerInfo(selectedFW.swCode, selectedFW.version, selectedFW.releaseType), []);
  const { loading, data: engineer } = state;
  const [keyword, setKeyword] = useState("");
  const [engineerId, setEngineerId] = useState([]);
  const [rows, setRows] = useState([]);
  const [result, setResult] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [listRowsPerPage, setListRowsPerPage] = useState(5);
  const [listPage, setListPage] = useState(0);
  const [duplicateOpen, setDuplicateOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);


  useEffect(() => {
    if (selectedFW !== null) {
      tryRefetch();
    }
  }, [selectedFW, open]);

  useEffect(() => {
    setRows([]);
    setEngineerId([]);
  }, [handleClose]);

  useEffect(() => {
    if (engineer !== null && engineer.userList !== undefined) {
      let eid = []
      for (let i = 0; i < engineer.userList.length; i++) {
        eid[i] = engineer.userList[i].userId;
      };
      setRows(engineer.userList);
      setEngineerId(eid);
    };
  }, [engineer]);

  const classes = useStyles();
  let data = {}
  let eid = []
  const addRow = (userName, agencyName, userId, userPhone, userCode) => () => {
    for (let i = 0; i <= rows.length; i++) {
      if (rows[i]) {
        eid[i] = rows[i].userId;
        if (eid.includes(userId) === false) {
          data = {
            index: rows.length + 1,
            userId: userId,
            userName: userName,
            agencyName: agencyName,
            userPhone: userPhone,
            userCode: userCode
          };
          setRows([...rows, data]);
          setKeyword("");
          setEngineerId([...engineerId, data.userId]);
        } else {
          setRows([...rows]);
          setKeyword("");
          setEngineerId([...engineerId]);
          openDuplicatePopup();
        }
      } else {
        if (rows.length == 0) {
          data = {
            index: rows.length + 1,
            userId: userId,
            userName: userName,
            agencyName: agencyName,
            userPhone: userPhone,
            userCode: userCode
          };
          setRows([...rows, data]);
          setKeyword("");
          setEngineerId([...engineerId, data.userId]);
        }
      }
    }
  };

  const deleteRow = (eid) => () => {
    let tempRows = rows.filter(row => {
      return row.userId !== eid;
    });
    let tempId = engineerId.filter(userId => {
      return userId !== eid;
    });
    setRows(tempRows);
    setEngineerId(tempId);
  };


  const handleChange = async () => {
    let group = await setGroupEngineer(selectedFW.version, selectedFW.swCode, selectedFW.releaseType, engineerId);
    if (group.msg === 'SUCCESS') {
      openAlertPopup();
      handleCloseClick();
    }
  };

  const handleValueChange = (event) => setKeyword(event.target.value);
  const searchClick = async () => {
    let result = await searchEngineer(keyword);
    setResult(result);
  };

  const handlePageChange = (event, newPage) => setPage(newPage);
  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleListPageChange = (event, newPage) => setListPage(newPage);
  const handleListRowsPerPageChange = (event) => {
    setListRowsPerPage(event.target.value);
    setListPage(0);
  };

  const allDeleteRow = () => {
    setRows([]);
    setEngineerId([]);
    setKeyword("");
    setResult([]);
  };
  const handleCloseClick = () => {
    handleClose();
    setKeyword("");
    setResult([]);
    setRows([]);
    setEngineerId([]);
  };

  const openAlertPopup = () => setAlertOpen(true);
  const closeAlertPopup = () => setAlertOpen(false);
  const openDuplicatePopup = () => setDuplicateOpen(true);
  const closeDuplicatePopup = () => setDuplicateOpen(false);

  return (
    <div>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color={'primary'} />
      </Backdrop>
      <Dialog
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{STRING_SETTING_ENGINEER}</DialogTitle>
        <DialogContent>
          <Card className={classes.root}>
            <CardActions className={classes.button}>
              <TextField
                style={{ width: 300 }}
                id="keyword"
                label={STRING_INPUT_NAME_OR_CENTER}
                value={keyword}
                onChange={handleValueChange}
              />
              <Button color="primary" variant="contained" onClick={searchClick} disabled={keyword === ""}>{STRING_SEARCH}</Button>
              <Button variant="contained" color="primary" onClick={allDeleteRow} disabled={rows.length === 0}>{STRING_CLEAN}</Button>
            </CardActions>
            {result.userList !== null && result.userList !== undefined &&
              <div>
                <CardContent className={classes.content}>
                  <div>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell align={'center'} variant={'footer'}><span><b>{STRING_NAME}</b></span></TableCell>
                            <TableCell align={'center'} variant={'footer'}><span><b>{STRING_CENTER}</b></span></TableCell>
                            <TableCell align={'center'} variant={'footer'}><span><b>{STRING_LOGIN_ID}</b></span></TableCell>
                            <TableCell align={'center'} variant={'footer'}><span><b>{STRING_PHONE_NUMBER}</b></span></TableCell>
                            <TableCell align={'center'} variant={'footer'}><span><b>{STRING_ENGINEER_ID}</b></span></TableCell>
                            <TableCell align={'center'} variant={'footer'}><span><b>{STRING_ADD}</b></span></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {result.userList.slice((page * rowsPerPage), ((page + 1) * rowsPerPage)).map((d, i) => (
                            <TableRow key={i}>
                              <TableCell align={'center'} variant={'footer'}>{d.userName ? d.userName : '-'}</TableCell>
                              <TableCell align={'center'} variant={'footer'}>{d.agencyName ? d.agencyName : '-'}</TableCell>
                              <TableCell align={'center'} variant={'footer'}>{d.userId ? d.userId : '-'}</TableCell>
                              <TableCell align={'center'} variant={'footer'}>{d.userPhone ? d.userPhone : '-'}</TableCell>
                              <TableCell align={'center'} variant={'footer'}>{d.userCode ? d.userCode : '-'}</TableCell>
                              <TableCell align={'center'}>
                                <Button onClick={addRow(d.userName, d.agencyName, d.userId, d.userPhone, d.userCode)}>
                                  <AddCircleOutlineIcon />
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </CardContent>
                <CardActions className={classes.actions}>
                  <TablePagination
                    component="div"
                    count={result.userList !== null && result.userList !== undefined && result.userList.length}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[1, 3, 5]}
                  />
                </CardActions>
              </div>
            }
          </Card>
          <Divider className={classes.divider} />
          <Card className={classes.root}>
            <Chip className={classes.title} label={STRING_ENGINEER_LIST} color="primary" variant="outlined" />
            {rows.length !== 0 &&
              <div>
                <CardContent className={classes.content}>
                  <div>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell align={'center'} variant={'footer'}><span><b>{STRING_NAME}</b></span></TableCell>
                            <TableCell align={'center'} variant={'footer'}><span><b>{STRING_CENTER}</b></span></TableCell>
                            <TableCell align={'center'} variant={'footer'}><span><b>{STRING_LOGIN_ID}</b></span></TableCell>
                            <TableCell align={'center'} variant={'footer'}><span><b>{STRING_PHONE_NUMBER}</b></span></TableCell>
                            <TableCell align={'center'} variant={'footer'}><span><b>{STRING_ENGINEER_ID}</b></span></TableCell>
                            <TableCell align={'center'} variant={'footer'}><span><b>{STRING_DELETE}</b></span></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rows.slice((listPage * listRowsPerPage), ((listPage + 1) * listRowsPerPage)).map((row, i) => (
                            <TableRow key={i}>
                              <TableCell align={'center'} variant={'footer'}>{row.userName ? row.userName : '-'}</TableCell>
                              <TableCell align={'center'} variant={'footer'}>{row.agencyName ? row.agencyName : '-'}</TableCell>
                              <TableCell align={'center'} variant={'footer'}>{row.userId ? row.userId : '-'}</TableCell>
                              <TableCell align={'center'} variant={'footer'}>{row.userPhone ? row.userPhone : '-'}</TableCell>
                              <TableCell align={'center'} variant={'footer'}>{row.userCode ? row.userCode : '-'}</TableCell>
                              <TableCell align={'center'}>
                                <Button onClick={deleteRow(row.userId)}>
                                  <RemoveCircleOutlineIcon />
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </CardContent>
                <CardActions className={classes.actions}>
                  <TablePagination
                    component="div"
                    count={rows.length !== 0 && rows.length}
                    onPageChange={handleListPageChange}
                    onRowsPerPageChange={handleListRowsPerPageChange}
                    page={listPage}
                    rowsPerPage={listRowsPerPage}
                    rowsPerPageOptions={[1, 3, 5]}
                  />
                </CardActions>
              </div>
            }
          </Card>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseClick} color="primary" autoFocus>
            {STRING_CANCEL}
          </Button>
          <Button onClick={handleChange} color="primary" autoFocus>
            {STRING_CONFIRM}
          </Button>
        </DialogActions>
      </Dialog>
      <AlertPopup open={alertOpen} handleClose={closeAlertPopup} comment={STRING_SETTING_ENGINEER_COMPLETE} color={'green'} severity={'success'} />
      <AlertPopup open={duplicateOpen} handleClose={closeDuplicatePopup} comment={STRING_DUPLICATE_ENGINEER} color={'red'} severity={'error'} />
    </div >
  );
};

BSEngineerDialog.propTypes = {
  className: PropTypes.string
};

export default BSEngineerDialog;
