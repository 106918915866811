import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CellWifiTwoToneIcon from '@material-ui/icons/CellWifiTwoTone';
import PortableWifiOffTwoToneIcon from '@material-ui/icons/PortableWifiOffTwoTone';
import ZoomInTwoToneIcon from '@material-ui/icons/ZoomInTwoTone';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DescriptionIcon from '@material-ui/icons/Description';

import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination
} from '@material-ui/core';

import Divider from '@material-ui/core/Divider';
import useAsync from '../../../../../asyncNet';
import axios from 'axios';
import Alert from '@material-ui/lab/Alert';
import {
  deviceDetailSearch,
  eoneGetSleepStatusOperationSetting
} from '../../../APIs/mateApis';
import SacControlDialog from '../Dialog/SacControlDialog';
import SleepStatusReportDialog from '../Dialog/SleepStatusReportDialog';
import SleepStatusControlDialog from '../Dialog/SleepStatusControlDialog';

const STRING_DEVICE_ID = '디바이스ID';
const STRING_NICKNAME = '디바이스 닉네임';
const STRING_LEFT_NICKNAME = '디바이스 좌측 닉네임';
const STRING_RIGHT_NICKNAME = '디바이스 우측 닉네임';
const STRING_USER_ID = '소유자 아이디';
const STRING_COUNTRY = '국가';
const STRING_REGION = '지역';
const STRING_MODEL = '모델';
const STRING_MAT_TYPE = '매트 타입';
const STRING_CAPACITY = '용량 타입';
const STRING_SW_MAINVERSION = 'SW MAIN 버전';
const STRING_SW_SUBVERSION = 'SW SUB 버전';
const STRING_INFO_DEVICE = '디바이스 정보입니다.';
const STRING_CONNECTIVITY = '연결상태';
const STRING_CONNECT = '연결됨';
const STRING_DISCONNECT = '비연결 ';
const STRING_DISCONNECT_TIMEINFO = '에 연결이 마지막으로 끊어졌습니다.';
const STRING_DETAIL = '상세보기';
const STRING_SAC_CONTROL = '사계절 매트 온도 제어';
const STRING_REPORT = '리포트'; // jrlim
const STRING_SLEEP_SENSOR_CONTROL = '슬립 센서 동작 제어';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}));

const getCapacityValue = capacity => {
  var capaType = `알 수 없음 (code: ${capacity})`;
  if (capacity === '1') capaType = '싱글';
  else if (capacity === '2') capaType = '퀸 (더블)';
  else if (capacity === '3') capaType = '킹 (더블';
  return capaType;
};

const getCountry = countryCode => {
  var country = `알 수 없음 (code: ${countryCode})`;
  if (countryCode === '82') country = '한국';
  else if (countryCode === '86') country = '중국';
  else if (countryCode === '1') country = '미국';
  else if (countryCode === '7') country = '러시아';
  return country;
};

const getMatType = matType => {
  var country = `알 수 없음 (code: ${matType})`;
  if (matType === '1') country = '슬림 매트';
  else if (matType === '2') country = '쿠션 매트';

  return country;
};

const valueChecker = value => {
  if (value === null || value === '' || value === undefined)
    return '정의되지 않음';
  else return value;
};

const connectInfo = (connect, time) => {
  var infoString = '';
  if (connect === 0) infoString += STRING_DISCONNECT;
  else if (connect === 1) infoString += STRING_CONNECT;

  if (connect === 0) {
    infoString +=
      ', ' +
      new Date(time * 1000)
        .toISOString()
        .slice(5, 10)
        .replace('-', '월 ') +
      '일';
    infoString += STRING_DISCONNECT_TIMEINFO;
  }

  return infoString;
};

const DeviceTable = props => {
  const { className, devices, ...rest } = props;

  const classes = useStyles();

  const [selectedDevices] = useState([]);
  const [choiceddevice, setDeviceChoice] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [open, setOpen] = React.useState(false);
  const [sacOpen, setSacOpen] = React.useState(false);

  const [sleepStatusOpen, setSleepStatusOpen] = React.useState(false);

  const [sleepStatusState, trySleepStatusRefetch] = useAsync(
    getSleepStatusOpearationSettiongByDeivceId,
    [],
    true
  );
  const { data: sleepStatusOpSetting } = sleepStatusState;

  const [state, tryRefetch] = useAsync(getDeviceInfoDetail, [], true);
  const { data: deviceInfo } = state;
  const [deviceInfoState, setDeviceInfoState] = useState({});

  const [reportDialogOpen, setReportDialogOpen] = React.useState(false);
  const handleReportOpen = (deviceId, deviceSeq) => {
    console.log('handleReportOpen');
    setDeviceChoice(deviceId);
    setReportDialogOpen(true);
  };

  const handleReportClose = () => {
    console.log('handleReportClose');
    setDeviceChoice('');
    setReportDialogOpen(false);
  };

  useEffect(() => {
    console.log('useEffect');
    if (open == true && choiceddevice !== '') {
      tryRefetch();
    }
  }, [open]);

  useEffect(() => {
    console.log(deviceInfo);
    setDeviceInfoState(deviceInfo);
  }, [deviceInfo]);

  async function getDeviceInfoDetail() {
    return deviceDetailSearch(choiceddevice);
  }

  async function getSleepStatusOpearationSettiongByDeivceId() {
    return eoneGetSleepStatusOperationSetting(choiceddevice);
  }

  const handleClickOpen = deviceId => {
    console.log('handleClickOpen');
    setDeviceChoice(deviceId);
    setOpen(true);
  };

  const handleClose = () => {
    setDeviceChoice('');
    setOpen(false);
  };

  const handleSACPannel = () => {
    setSacOpen(true);
  };

  const handleSACClose = () => {
    setSacOpen(false);
  };

  const handleSleepStatusControlPannel = () => {
    trySleepStatusRefetch();
    setSleepStatusOpen(true);
  };

  const handleSleepStatusControlPannelClose = () => {
    setSleepStatusOpen(false);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  return (
    <div>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align={'center'}>
                      <span>
                        <b>{STRING_DEVICE_ID}</b>
                      </span>
                    </TableCell>
                    <TableCell align={'center'}>
                      <span>
                        <b>{STRING_MODEL}</b>
                      </span>
                    </TableCell>
                    <TableCell align={'center'}>
                      <span>
                        <b>{STRING_NICKNAME}</b>
                      </span>
                    </TableCell>
                    <TableCell align={'center'}>
                      <span>
                        <b>{STRING_USER_ID}</b>
                      </span>
                    </TableCell>
                    <TableCell align={'center'}>
                      <span>
                        <b>{STRING_CONNECTIVITY}</b>
                      </span>
                    </TableCell>
                    <TableCell align={'center'}>
                      <span>
                        <b>{STRING_DETAIL}</b>
                      </span>
                    </TableCell>
                    <TableCell align={'center'}>
                      <span>
                        <b>{STRING_REPORT}</b>
                      </span>
                    </TableCell>
                    {/* jrlim */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {devices !== null &&
                    devices !== undefined &&
                    devices
                      .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                      .map(dev => (
                        <TableRow
                          className={classes.tableRow}
                          hover
                          key={dev.id}
                          selected={selectedDevices.indexOf(dev.id) !== -1}>
                          <TableCell align={'center'}>{dev.id}</TableCell>
                          <TableCell align={'center'}>
                            {dev.modelName}
                          </TableCell>
                          <TableCell align={'center'}>{dev.nickName}</TableCell>
                          <TableCell align={'center'}>{dev.username}</TableCell>
                          <TableCell align={'center'}>
                            {dev.connection === 1 ? (
                              <CellWifiTwoToneIcon color={'secondary'} />
                            ) : (
                              <PortableWifiOffTwoToneIcon color={'error'} />
                            )}
                          </TableCell>
                          <TableCell align={'center'}>
                            <Tooltip title={STRING_DETAIL}>
                              <IconButton
                                aria-label={STRING_DETAIL}
                                onClick={() => handleClickOpen(dev.id)}>
                                <ZoomInTwoToneIcon />
                              </IconButton>
                            </Tooltip>
                          </TableCell>

                          <TableCell align={'center'}>
                            <Tooltip title={STRING_REPORT}>
                              <IconButton
                                aria-label={STRING_REPORT}
                                onClick={() => handleReportOpen(dev.id)}>
                                <DescriptionIcon />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                          {/* jrlim */}
                        </TableRow>
                      ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          {devices !== null && devices !== undefined && (
            <TablePagination
              component="div"
              count={
                devices !== null && devices !== undefined && devices.length
              }
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleRowsPerPageChange}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[5, 10, 25]}
            />
          )}
        </CardActions>
      </Card>
      <SleepStatusReportDialog
        open={reportDialogOpen}
        handleClose={handleReportClose}
        device={choiceddevice}
      />
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title">
        <DialogTitle id="max-width-dialog-title">
          device Information
        </DialogTitle>
        <DialogContent>
          <DialogContentText component={'div'}>
            <Alert severity={'info'}>
              <span style={{ color: 'CornflowerBlue' }}>
                {STRING_INFO_DEVICE}
              </span>
            </Alert>
            <Divider className={classes.divider} />
            <span>
              <b>{STRING_DEVICE_ID}</b> :{' '}
              {deviceInfo !== null &&
                ' ' +
                  (deviceInfo.data.macAddress +
                    deviceInfo.data.additionalValue)}
            </span>
            <Divider className={classes.divider} />
            <span>
              <b>{STRING_USER_ID}</b> :{' '}
              {deviceInfo !== null &&
                ' ' + valueChecker(deviceInfo.data.username)}
            </span>
            <Divider className={classes.divider} />
            <span>
              <b>{STRING_NICKNAME}</b> :{' '}
              {deviceInfo !== null &&
                ' ' + valueChecker(deviceInfo.data.nickname)}
            </span>
            <Divider className={classes.divider} />
            <span>
              <b>{STRING_LEFT_NICKNAME}</b> :{' '}
              {deviceInfo !== null &&
                ' ' + valueChecker(deviceInfo.data.leftNickname)}
            </span>
            <Divider className={classes.divider} />
            <span>
              <b>{STRING_RIGHT_NICKNAME}</b> :{' '}
              {deviceInfo !== null &&
                ' ' + valueChecker(deviceInfo.data.rightNickname)}
            </span>
            <Divider className={classes.divider} />
            <span>
              <b>{STRING_COUNTRY}</b> :{' '}
              {deviceInfo !== null &&
                ' ' + getCountry(deviceInfo.data.countryCode)}
            </span>
            <Divider className={classes.divider} />
            <span>
              <b>{STRING_REGION}</b> :{' '}
              {deviceInfo !== null &&
                ' ' + valueChecker(deviceInfo.data.regionCode)}
            </span>
            <Divider className={classes.divider} />
            <span>
              <b>{STRING_MODEL}</b> :{' '}
              {deviceInfo !== null &&
                ' ' + valueChecker(deviceInfo.data.modelName)}
            </span>
            <Divider className={classes.divider} />
            <span>
              <b>{STRING_MAT_TYPE}</b> :{' '}
              {deviceInfo !== null && ' ' + getMatType(deviceInfo.data.matType)}
            </span>
            <Divider className={classes.divider} />
            <span>
              <b>{STRING_CAPACITY}</b> :{' '}
              {deviceInfo !== null &&
                ' ' + getCapacityValue(deviceInfo.data.capacityInfo)}
            </span>
            <Divider className={classes.divider} />
            <span>
              <b>{STRING_SW_MAINVERSION}</b> :{' '}
              {deviceInfo !== null &&
                ' ' + valueChecker(deviceInfo.data.mainVersion)}
            </span>
            <Divider className={classes.divider} />
            <span>
              <b>{STRING_SW_SUBVERSION}</b> :{' '}
              {deviceInfo !== null &&
                ' ' + valueChecker(deviceInfo.data.subVersion)}
            </span>
            <Divider className={classes.divider} />
            <span>
              <b>{STRING_CONNECTIVITY}</b> :{' '}
              {deviceInfo !== null &&
                ' ' +
                  connectInfo(
                    deviceInfo.data.connected,
                    deviceInfo.data.lastModify
                  )}
            </span>
          </DialogContentText>
          <form className={classes.form} noValidate></form>
        </DialogContent>
        <DialogActions>
          {deviceInfo !== null && deviceInfo.data.modelCode === '513' && (
            <Button onClick={handleSACPannel} color="primary">
              {STRING_SAC_CONTROL}
            </Button>
          )}

          {/* {deviceInfo !== null && deviceInfo.data.username === 'yhlee' && ( */}
          {deviceInfo !== null && (
            <Button onClick={handleSleepStatusControlPannel} color="primary">
              {STRING_SLEEP_SENSOR_CONTROL}
            </Button>
          )}
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
        <SacControlDialog
          open={sacOpen}
          handleClose={handleSACClose}
          deviceId={
            deviceInfo !== null &&
            deviceInfo.data.macAddress + deviceInfo.data.additionalValue
          }
        />
        <SleepStatusControlDialog
          open={sleepStatusOpen}
          handleClose={handleSleepStatusControlPannelClose}
          deviceInfo={deviceInfoState}
          sleepData={sleepStatusOpSetting}
        />
      </Dialog>
    </div>
  );
};

DeviceTable.propTypes = {
  className: PropTypes.string,
  devices: PropTypes.array.isRequired
};

export default DeviceTable;
