import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  Typography,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper, Grid, TextField, Button
} from '@material-ui/core';
import useAsync from '../../../../../asyncNet';
import {
  naviwatchGetWhiteList,
  naviwatchAddWhiteList,
  naviwatchDeleteWhiteList
} from '../../../../Common/APIs/naviwatchApis';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { userInfoContext } from '../../../../../App';
import { NO_PERMISSION, NO_PERMISSION_WARNING, WRITE_PERMISSION } from '../../../Config/naviwatchConfig';
const useStyles = makeStyles(theme => ({
  root: {
    height: '80vh'
  },
  whitelist: {
    marginTop: '20px',
    height: '80%'
  },
  details: {
    display: 'flex'
  },
  avatar: {
    marginLeft: 'auto',
    height: 110,
    width: 110,
    flexShrink: 0,
    flexGrow: 0
  },
  progress: {
    marginTop: theme.spacing(2)
  },
  uploadButton: {
    marginRight: theme.spacing(2)
  },
  divider:{
    marginTop: 5
  },
  tableContainer:{
    maxHeight: '100%',
    marginTop: '15px'
  },
  table: {
    minWidth: 400
  },
  toolbar:{
    display: 'flex',
    alignItems: 'center'
  },
  toolbarBtn: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  btn:{
    margin: '2px'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  }

}));
const STR_WHITELIST_TITLE='WhiteList 관리';
const STR_WHITELIST_SUBTITLE='WhiteList 에 미포함된 곳에서 접속한 경우, 개인정보 검색이 불가합니다.';

const STR_WHITELIST_LIST='등록된 WhiteList';
const STR_SEARCH='검색';
const STR_ADD='추가';
const STR_DELETE='삭제';


const STR_IP='IP';
const STR_DESCRIPTION='DESCRIPTION';
const STR_DATE='처리일시';



const searchWhitelist = (styleClass, values, setValues, handleChange, searchCallback, addCallback) =>{
  return(
    <div>
      <Grid
        className={styleClass.toolbar}
        container
        spacing={3}
      >
        <Grid
          item
          md={4}
          xs={12}
        >
          <TextField
            fullWidth
            label={STR_IP}
            margin="dense"
            name="ip"
            onChange={handleChange}
            value={values.ip}
            variant="outlined"
          />
        </Grid>
        <Grid
          item
          md={5}
          xs={12}
        >
          <TextField
            fullWidth
            label={STR_DESCRIPTION}
            margin="dense"
            name="description"
            onChange={handleChange}
            value={values.description}
            variant="outlined"
          />
        </Grid>
        <Grid
          item
          md={3}
          xs={12}
        >
          <div className={styleClass.toolbarBtn}>
            <Button
              className={styleClass.btn}
              color="primary"
              variant="outlined"
              onClick={addCallback}
            >
              {STR_ADD}
            </Button>
            <Button
              className={styleClass.btn}
              color="primary"
              variant="outlined"
              onClick={searchCallback}
            >
              {STR_SEARCH}
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

const whiteListTable = (styleClass, ipList, deleteCallback) =>{
  return(
    <div className={styleClass.whitelist} >
      <Typography color="textSecondary" variant={'h6'} >{STR_WHITELIST_LIST}</Typography>
      <Divider className={styleClass.divider}/>
      <TableContainer  className={styleClass.tableContainer} component={Paper}>
        <Table className={styleClass.table} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell align="center">{STR_IP}</TableCell>
              <TableCell align="center">{STR_DESCRIPTION}</TableCell>
              <TableCell align="center">{STR_DATE}</TableCell>
              <TableCell align="center">{STR_DELETE}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ipList!==undefined && ipList.map((ip) => (
              <TableRow key={ip.ip}>
                <TableCell align="center">{ip.ip}</TableCell>
                <TableCell align="center">{ip.description}</TableCell>
                <TableCell align="center">{ip.register}</TableCell>
                <TableCell align={'center'}>
                  <Tooltip title={STR_DELETE}>
                    <IconButton aria-label={STR_DELETE}
                                onClick={() => deleteCallback(ip.ip)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const WhiteList = props => {
  const { className,  ...rest } = props;
  const classes = useStyles();
  const [searchState, trySearch] = useAsync(getWhiteLists, [], false);
  const [addState, tryAdd] = useAsync(addWhiteLists, [], true);
  const [deleteState, tryDelete] = useAsync(deleteWhiteLists, [], true);

  const { loading: searchLoading, data: whitelist } = searchState;
  const { loading: addLoading } = addState;
  const { loading: deleteLoading } = deleteState;

  const [values, setValues] = useState({
    ip: '',
    description: ''
  });
  const [deleteIp, setDeleteIp] = useState("");
  const authInfo =  useContext(userInfoContext);
  const [noPermissionMsg, setNoPermissionMsg] = useState("");
  const [permission, setPermission] = useState(NO_PERMISSION);
  useEffect(()=>{
    var pageInfo = sessionStorage.getItem('pages');
    if(pageInfo!=="undefined") {
      var pageInfoObj = JSON.parse(pageInfo);
      var pageArray = pageInfoObj.filter(page => page.name.toLowerCase().includes("setting"));
      if (pageArray !== undefined && pageArray.length > 0) {
        var page = pageArray[0];
        if(page.permission===undefined){
          setNoPermissionMsg(NO_PERMISSION_WARNING);
        }
        setPermission(page.permission);
      }
    }
  }, [authInfo.pages]);

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  async function getWhiteLists() {
    return naviwatchGetWhiteList(values.ip, values.description);
  }

  async function addWhiteLists() {
    return naviwatchAddWhiteList(values.ip, values.description);
  }

  async function deleteWhiteLists() {
    if(deleteIp.length>0)
      return naviwatchDeleteWhiteList(deleteIp);
  }

  const searchCallback = () =>{
    trySearch();
  };

  const addCallback = () =>{
    if(permission>=WRITE_PERMISSION) {
      tryAdd().then(r => {
        trySearch();
      });
    }
  };

  const deleteCallback = (ip) =>{
    if(permission>=WRITE_PERMISSION) {
      setDeleteIp(ip);
    }
  };

  useEffect(()=>{
    if(deleteIp.length>0){
      tryDelete().then(r => {
        trySearch();
      });
    }
  }, [deleteIp]);

  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={searchLoading || addLoading || deleteLoading} >
        <CircularProgress color={'primary'}/>
      </Backdrop>
      {whitelist !== null &&
        <Card
          {...rest}
          className={clsx(classes.root, className)}
        >
          <CardContent className={classes.root}>
            <div className={classes.details}>
              <div>
                <Typography
                  gutterBottom
                  variant="h2"
                >
                  {STR_WHITELIST_TITLE}
                </Typography>
                <Typography
                  className={classes.locationText}
                  color="textSecondary"
                  variant="body1"
                >
                  {STR_WHITELIST_SUBTITLE}
                </Typography>
              </div>
              <ListAltIcon
                className={classes.avatar}
              />
            </div>
            {searchWhitelist(classes, values, setValues, handleChange, searchCallback, addCallback)}
            {whiteListTable(classes, whitelist.data, deleteCallback)}
          </CardContent>

        </Card>
      }
    </div>
  );
};

WhiteList.propTypes = {
  className: PropTypes.string
};

export default WhiteList;
