import { makeStyles } from '@material-ui/core/styles';
import React, { useContext, useEffect, useState } from 'react';
import useAsync from '../../../asyncNet';
import { Backdrop, CircularProgress, Typography } from '@material-ui/core';
import DormancyTable from './components/DormancyTable';
import { DormancyUserToolbar } from './components';
import { dormancySearch } from '../APIs/aironeApis';
import publicIp from 'public-ip';
import {
  NO_PERMISSION,
  NO_PERMISSION_WARNING,
  NO_WHITELIST_INSIDE_WARNING,
  READ_PERMISSION
} from '../../Common/Config/naviwatchConfig';
import { userInfoContext } from '../../../App';
import { naviwatchCheckWhiteListValidation } from '../../Common/APIs/naviwatchApis';
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));


const DormancyUser = ({ history }) => {
  const classes = useStyles();
  const [query, setQuery] = useState({
    initial: true,
    userId: '',
    from: '',
    to: ''
  });
  const [dormancyState, trySearchDormancy] = useAsync(getDormancySearch, [], true);
  const [ipCheckState, tryCheckWhitelist] = useAsync(checkIpValidation, [], true);

  const { loading: dormancyLoading, data: dormancyHistory } = dormancyState;
  const { loading: ipCheckLoading, data: ipValid } = ipCheckState;

  const [ipAddress, setIpAddress] = useState(undefined);
  const [noPermissionMsg, setNoPermissionMsg] = useState("");
  const authInfo =  useContext(userInfoContext);
  const [permission, setPermission] = useState(NO_PERMISSION);
  useEffect(()=>{
    var pageInfo = sessionStorage.getItem('pages');
    if(pageInfo!=="undefined") {
      var pageInfoObj = JSON.parse(pageInfo);
      var pageArray = pageInfoObj.filter(page => page.name.toLowerCase().includes("inactive"));
      if (pageArray !== undefined && pageArray.length > 0) {
        var page = pageArray[0];
        if(page.permission===undefined){
          setNoPermissionMsg(NO_PERMISSION_WARNING);
        }
        setPermission(page.permission);
      }
    }
  }, [authInfo.pages]);
  async function getDormancySearch() {
    return dormancySearch(sessionStorage.getItem('email'),
      ipAddress, query.userId, query.from, query.to);
  }

  async function checkIpValidation() {
    return naviwatchCheckWhiteListValidation(ipAddress);
  }

  const searchDormacyHistory = (searchdata) => {
    var _from='';
    var _to='';
    if(searchdata.from!==''){
      _from = Date.parse(searchdata.from)
    }
    if(searchdata.to!==''){
      _to = Date.parse(searchdata.to)
    }
    console.log('from : ' + _from);
    console.log('to : ' + _to);
    setQuery({
      initial: false,
      userId: searchdata.userId,
      from: _from,
      to: _to
    });
  };

  useEffect(() => {
    if(query.initial === false)
      trySearchDormancy();
  }, [query]);

  useEffect(()=>{
    if(ipCheckState.data===false){
      setNoPermissionMsg(NO_WHITELIST_INSIDE_WARNING+`(${ipAddress})`);
    }
  }, [ipCheckState]);

  useEffect(()=>{
    if(ipAddress!==undefined){
      tryCheckWhitelist();
    }
  }, [ipAddress]);

  useEffect(() => {
    if (sessionStorage.getItem('naviwatch_permission') !== 'true')
      history.push('/');
    (async () => {
      setIpAddress(await publicIp.v4());
    })();
  }, []);

  return (
    <div>
      {permission >= READ_PERMISSION && ipValid===true ?
        <div className={classes.root}>
          <Backdrop className={classes.backdrop} open={dormancyLoading||ipCheckLoading}>
            <CircularProgress color={'primary'}/>
          </Backdrop>
          <DormancyUserToolbar callbackFunc={searchDormacyHistory}/>
          <div className={classes.content}>
            {
              dormancyLoading === false && dormancyHistory !== null && dormancyHistory !== undefined &&
              <DormancyTable dormancy={dormancyHistory.dormancyList} type={"user"}/>
            }
          </div>
        </div> :
        <Typography className={classes.root}>{noPermissionMsg}</Typography>
      }
    </div>
  );
};

export default DormancyUser;
