import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import useAsync from '../../../../../asyncNet';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField, Typography
} from '@material-ui/core';
import { dormancyGetDormancyCount } from '../../../APIs/aironeApis';

const useStyles = makeStyles((theme) => ({
  root: {},
  actions: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  container: {
    display: 'flex'
  },
  item:{
    padding: '20px',
    alignSelf: 'center'
  },
  subtitle:{
    marginTop: theme.spacing(2),
  }
}));
const STRING_SEARCH = '검색';
const STRING_TITLE = '휴면 사용자 관리';
const STRING_ID_LABEL = '사용자 ID';
const STRING_USER_COUNT = '휴면 사용자는 ';
const STRING_USER_COUNT2 = '기준 총 ';
const STRING_USER_COUNT3 = ' 명 입니다.';
const STRING_SEARCH_CLEAN = '초기화';
const STRING_SEND_DATE = '휴면전환일';
const STRING_SEARCH_ID = '아이디';


const DormancyUserToolbar = props => {
  const { className, callbackFunc, ...rest } = props;

  const classes = useStyles();
  const [state] = useAsync(dormancyGetDormancyCount, []);
  const { data: dormancyCount } = state;
  const today = new Date().toLocaleDateString();

  const [values, setValues] = useState({
    userId: '',
    from: '',
    to: ''
  });

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };


  const cleanSearchInput = () =>{
    setValues({
      userId: '',
      from: '',
      to: ''
    });
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        title={STRING_TITLE}
        subheader={
          <div className={classes.subtitle}>
          <span>{STRING_USER_COUNT + today + STRING_USER_COUNT2+(dormancyCount === null ? 0 : dormancyCount.dormancyCount)+STRING_USER_COUNT3}<br/></span>
          </div>
        }
      />
      <Divider/>
      <CardContent>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            md={7}
            xs={12}
          >
            <div className={classes.container}>
              <Typography className={classes.item}>{STRING_SEND_DATE}</Typography>
              <TextField
                className={classes.item}
                id="from"
                name="from"
                type="date"
                value={values.from}
                onChange={handleChange}
                // disabled={values.condition !== STRING_SEARCH_SEPERATE}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Typography className={classes.item}>~</Typography>
              <TextField
                className={classes.item}
                id="to"
                name="to"
                type="date"
                value={values.to}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
            />
            </div>
          </Grid>
          <Grid
            item
            md={5}
            xs={12}
          >
            <div className={classes.container}>
              <Typography className={classes.item}>
                {STRING_SEARCH_ID}
              </Typography>
              <TextField
                className={classes.item}
                id="userId"
                margin="dense"
                name="userId"
                onChange={handleChange}
                value={values.userId}
                variant="outlined"
              />
            </div>
          </Grid>
        </Grid>
      </CardContent>
      <Divider/>
      <CardActions className={classes.actions}>
        <Button
          color="primary"
          variant="contained"
          onClick={cleanSearchInput}
          disabled={values.userid==="" && values.condition===""}
        >
          {STRING_SEARCH_CLEAN}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={()=>callbackFunc(values)}
          disabled={values.userid==="" && values.condition===""}
        >
          {STRING_SEARCH}
        </Button>
      </CardActions>
    </Card>
  );
};

DormancyUserToolbar.propTypes = {
  className: PropTypes.string
};

export default DormancyUserToolbar;
