import React, { useState, useEffect } from 'react';
import {
  Dialog, DialogActions, DialogContent, Button,
  DialogTitle, Divider, Typography, makeStyles,
  Checkbox, Input, Backdrop, CircularProgress,
  Select
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import { NO_PERMISSION, WRITE_PERMISSION } from '../../../../Common/Config/naviwatchConfig';
import {
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Grid,
    TextField,
    FormGroup,
    FormControlLabel,
    Switch,
    MenuItem,
    InputLabel,
    FormControl
  } from '@material-ui/core';
import AWS from 'aws-sdk';
import { addAnnouncement, getSts } from 'views/Smart/APIs/smartApis';
import { deploy } from 'config';
import useAsync from 'asyncNet/useAsync';




const STRING_TITLE = '공지사항 등록';
const STRING_SUBTITLE = '파일선택 후 업로드버튼을 눌러주세요';
const STRING_NOTI_TITLE = '제목';
const STRING_NOTI_CONTENTS = '내용';
const STRING_UPLOAD = '업로드'
const STRING_LINK = '링크';
const STRING_PUSH_CHECKBOX = '푸시전송';
const STRING_CONDITION_CLEAN = '초기화';
const STRING_NOTI_TYPE = '공지 타입';

const NOTI_OPTIONS = [
  { value: "3100", label: "서버점검사전안내" },
  { value: "3200", label: "APP업데이트안내" },
  { value: "3300", label: "개인정보변경동의" },
  { value: "3400", label: "설문조사 이벤트/광고" },
];

const STRING_CLOSE = '취소';
// const STRING_UPLOAD = '업로드';




const useStyles = makeStyles(theme => ({
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  linear:{
    display: 'flex',
    alignItems: 'flex-end'
  },
  datas:{
    marginLeft: theme.spacing(2)
  },
  icons:{
    marginLeft: theme.spacing(2),
    padding: theme.spacing(0)
  },
  fwDescription:{
    marginLeft: theme.spacing(2),
    width: '65%'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));

const S3_BUCKET = {
    dev: 'nskr-dev-naviwatch',
    stage: 'nskr-stage-naviwatch',
    prod: 'nskr-prod-naviwatch',
  }
  
const REGION ='ap-northeast-2';  

const AddAnnouncementDialog  = props => {
  const { open, handleClose, refetch } = props;
  const [loading, setLoading] = useState(false);
  
  const [noticode, setNoticode] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [pushSwitch, setPushSwitch] = useState(false);
  const [values, setValues] = useState({
    title : '',
    content : '',
    link : ''
  });
  const [permission, setPermission] = useState(NO_PERMISSION);
  const classes = useStyles();

  const addAnnouncementFunc = () => {
    var ret;
    ret = addAnnouncement(noticode, values.content, values.title, 
    values.link, imageUrl, pushSwitch)
    setLoading(true)
    handleClose()
    return ret;
  }

  const [state, callAddAnnounce] = useAsync(addAnnouncementFunc, [], true);
  const { addAnnounceLoading, data: addResponse } = state;

  const uploadFile = (file) => {
    if (file == null) {
      alert(`파일을 선택해주세요`);
      return
    }

    const fileName = 'notice/' + new Date().getTime() + '_' + file.name;
    const myBucket = new AWS.S3({
      params: { Bucket: S3_BUCKET[deploy]},
      region: REGION
    });
    const params = {
      Body: file,
      Bucket: S3_BUCKET[deploy],
      Key: fileName
    };
    myBucket.putObject(params, (err, data) => {
      if (err) {
        alert(`업로드가 실패하였습니다. \n ${err}`);
      } else {
        alert('업로드 되었습니다.');
      }
      console.log(`data: ${JSON.stringify(data)}, err: ${err}`);
    });
    setImageUrl(`https://${S3_BUCKET[deploy]}.s3.ap-northeast-2.amazonaws.com/${fileName}`)
  }

  const handleCloseClick = () =>{
    setNoticode("")
    setSelectedFile(null)
    setImageUrl(null)
    setPushSwitch(false)
    setValues("")
  
    handleClose();
  };

  const refetchList = () => {
    setLoading(false);
    refetch();
  };

 

  useEffect(()=> {
    if (addResponse !== null && addResponse !== undefined){
      if (addResponse.code === 200) {
        refetchList(" 등록이 성공하였습니다.")
      } else {
        refetchList("예상치 못한 에러가 발생하였습니다.")
      }
    }
  }, [addResponse]);



  const handleChangeNoticode = (event) => {
    setNoticode(event.target.value);
  };

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleFileInput = (e) => {
    setSelectedFile(e.target.files[0]);
  }

  const handleUpload = () => {

    callAddAnnounce();
    setLoading(true);
    handleCloseClick()

  }


  const loadCredentials = async() => {
    try {
      const data = await getSts();
      AWS.config.update(data);
    } catch (error) {
      console.log(`PushToolbar::loadCredentials error = ${JSON.stringify(error)}`);
    }
  }

  
  return (
    <div>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color={'primary'}/>
      </Backdrop>
      <Dialog
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{STRING_TITLE}</DialogTitle>
        <DialogContent>
          {/* <Alert severity={'error'}><span style={{ color: 'red' }}>{STRING_ALERT_FW_UPLOAD}</span></Alert> */}
          <Divider className={classes.divider}/>
          <div className={classes.linear}>
            <InputLabel id="select-helper-label">{STRING_NOTI_TYPE}</InputLabel>
              <Select labelId="select-helper-label" value={noticode} onChange={handleChangeNoticode}>
                {NOTI_OPTIONS.map((option) => (
                  <MenuItem  key={option.value} value={option.value}> {option.label} </MenuItem >
                ))}
              </Select>
          </div>

          <Divider className={classes.divider}/>
          <div className={classes.linear}>
            
            <TextField
              fullWidth
              label={STRING_NOTI_TITLE}
              margin="dense"
              name="title"
              onChange={handleChange}
              value={values.title}
              variant="outlined"
            />
          </div>


            <TextField
              fullWidth
              label={STRING_NOTI_CONTENTS}
              margin="dense"
              multiline
              name="content"
              onChange={handleChange}
              minRows={10}
              value={values.content}
              variant="outlined"
            />
        
            <TextField 
              fullWidth 
              label={STRING_LINK} 
              name="link"
              onChange={handleChange} 
              size="medium" 
              value={values.link} 
            />
            
            <Input
              className={classes.input}
              id="uploadInput"
              onChange={handleFileInput}
              type="file"
            />
            <Button
              color="secondary"
              component="span"
              onClick={() => uploadFile(selectedFile)}
              size="small"
              variant="outlined"
            > {STRING_UPLOAD} 
            </Button>

            <FormGroup>
              <FormControlLabel 
                control={
                  <Switch 
                    checked={pushSwitch}
                    onClick={() => {
                      setPushSwitch(!pushSwitch);
                    }}
                  />
                } 
                label={STRING_PUSH_CHECKBOX} 
                labelPlacement="start"
              />
            </FormGroup>
            

       
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseClick} color="primary" autoFocus>
            {STRING_CLOSE}
          </Button>
          <Button onClick={handleUpload} color="primary" autoFocus>
            {STRING_UPLOAD}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

AddAnnouncementDialog.propTypes = {
  className: PropTypes.string
};

export default AddAnnouncementDialog;
