import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import useAsync from '../../../../../asyncNet';
import { getUserCount } from "../../../APIs/tokapis";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField
} from '@material-ui/core';
import {
  STRING_USER_SEARCH,
  STRING_ALERT_USER_INFO,
  STRING_USER_PHONE_HYPHEN,
  STRING_TOTAL_USER_COUNT1,
  STRING_TOTAL_USER_COUNT2,
  STRING_SEARCH,
  STRING_USER_ID,
  STRING_USER_NAME,
  STRING_USER_EMAIL,
  STRING_CLEAN
} from '../../../StringTable/StringTable';

const useStyles = makeStyles(() => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  },
  title: {
    fontWeight: 700
  }
}));

const UsersToolbar = props => {
  const { className, callbackFunc, ...rest } = props;

  const classes = useStyles();
  const [state, tryRefetch] = useAsync(getUserCount, []);
  const { loading, data: userCount, error } = state;

  const [values, setValues] = useState({
    userId: '',
    userEmail: '',
    userName: '',
    userPhone: '',
  });

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };


  const cleanSearchInput = () => {
    setValues({
      userId: '',
      userEmail: '',
      userName: '',
      userPhone: '',
    });
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        title={STRING_USER_SEARCH}
        subheader={<div>
          <span>{STRING_TOTAL_USER_COUNT1 + (userCount === null ? 0 : userCount['totalCount']) + STRING_TOTAL_USER_COUNT2}<br /></span>
          <span style={{ color: 'red' }}>{STRING_ALERT_USER_INFO}</span></div>}
      />
      <Divider />
      <CardContent>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            md={2}
            xs={12}
          >
            <TextField
              fullWidth
              label={STRING_USER_ID}
              margin="dense"
              name="userId"
              onChange={handleChange}
              value={values.userId}
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            md={2}
            xs={12}
          >
            <TextField
              fullWidth
              label={STRING_USER_NAME}
              margin="dense"
              name="userName"
              onChange={handleChange}
              value={values.userName}
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            md={4}
            xs={12}
          >
            <TextField
              fullWidth
              label={STRING_USER_EMAIL}
              margin="dense"
              name="userEmail"
              type='email'
              onChange={handleChange}
              value={values.userEmail}
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            md={4}
            xs={12}
          >
            <TextField
              fullWidth
              label={STRING_USER_PHONE_HYPHEN}
              margin="dense"
              name="userPhone"
              onChange={handleChange}
              value={values.userPhone}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>
        <Button
          color="primary"
          variant="contained"
          onClick={cleanSearchInput}
          disabled={values.userId === "" && values.userEmail === "" && values.userName === "" && values.userPhone === ""}
        >
          {STRING_CLEAN}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => callbackFunc(values)}
          disabled={values.userId === "" && values.userEmail === "" && values.userName === "" && values.userPhone === ""}
        >
          {STRING_SEARCH}
        </Button>
      </CardActions>
    </Card>
  );
};

UsersToolbar.propTypes = {
  className: PropTypes.string
};

export default UsersToolbar;
