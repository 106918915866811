const STRING_SHOW_ALL_REGION = '모든 지역 보기';
const STRING_REGION_COUNT = '지역별 기기 분포';
const STRING_ALL_REGION = '모든 지역';
const STRING_ALL_DEVICE_DISTRIBUTION = '전체 기기 분포';
const STRING_ONLINE_DEVICE_DISTRIBUTION = '온라인 기기 분포';
const STRING_REALTIME_MOBILE_CONNECT = '실시간 앱 접속';
const STRING_DEVICE = '디바이스';
const STRING_ERROR_STATUS = '에러 현황';
const STRING_NOW = '현재';
const STRING_24H = '누적(1일)';
const STRING_WEEK = '누적(1주)';
const STRING_MONTH = '누적(한달)';
const STRING_USER = '사용자';
const STRING_DEVICE_USER = '기기사용자';
const STRING_ALL = '전체';
const STRING_ONLINE = '온라인';
const STRING_OFFLINE = '오프라인';
const STRING_STATUS_CON1 = '1st Converter';
const STRING_STATUS_CON2 = '2nd Converter';
const STRING_STATUS_DB = 'DB';
const STRING_STATUS_AGS = '외부 연동';
const STRING_MOBILE_IOS = 'iOS';
const STRING_MOBILE_ANDROID = 'Android';
const STRING_DB_SERVER = 'DB SERVER';
const STRING_AGS_SERVER = 'AGS SERVER';
const STRING_PROXY_SERVER = 'PROXY SERVER';
const STRING_PRIVACY_SERVER = 'PRIVACY SERVER';
const STRING_MIGRATE_SERVER = 'MIGRATE SERVER';
const STRING_SERVICETOOLKIT_SERVER = 'SERVICE TOOLKIT';
const STRING_DEVICE_DAY_TREND = '디바이스 일별 추이';
const STRING_REGISTER_DAY_TREND = '일별 가입자 추이';
const STRING_USER_ID = '사용자 ID';
const STRING_USER_EMAIL = '사용자 이메일';
const STRING_USER_NAME = '사용자 이름';
const STRING_USER_PHONE = '사용자 전화번호';
const STRING_USER_REGISTER_AT = '사용자 가입 날짜';
const STRING_USER_LAST_LOGIN_AT = '사용자 최종 로그인 날짜';
const STRING_DEVICE_ID = '디바이스 ID';
const STRING_DEVICE_MODEL_NAME = '디바이스 모델명';
const STRING_CONNECTION_STATE = '연결 여부';
const STRING_WARNING_USER_PRIVATE_INFO = '사용자 개인정보 입니다. 정보 이용에 각별한 주의를 바랍니다.';
const STRING_UNKNOWN = '알 수 없음';
const STRING_USE = '사용함';
const STRING_NOT_USE = '사용안함';
const STRING_CONNECTED = '연결됨';
const STIRNG_UNCONNECTED = '연결되지 않음';
const STRING_START_DATE = '시작 날짜';
const STRING_END_DATE = '끝 날짜';
const STRING_DEVICE_LOG_SEARCH = '기기 로그 조회';
const STRING_LAST_1MONTH = '(최근 한 달 이내의 로그만 조회 가능합니다.)';
const STRING_DEVICE_CONTROL_LOG = 'Device Control Log';
const STRING_DATE_FORMAT = 'YYYY-MM-DD';
const STRING_DATEPICKER_FORMAT = 'yyyy-MM-dd(eee)';
const STRING_DEVICE_CONTROL_LOG_INFO = '사용자의 디바이스 제어 로그에 관한 정보입니다. 정보 이용에 각별한 주의 바랍니다.';
const STRING_NO_CONTROL_LOG = '해당 날짜의 제어 로그가 없습니다.';
const STRING_DATE = '날짜';
const STRING_SETTING_MODE = '설정 모드';
const STRING_SETTING_VALUE = '설정 값';
const STRING_DATE_FORMAT_INCLUDE_SEC = 'YYYY-MM-DD HH:mm:ss';
const STRING_DEVICE_TYPE = '디바이스 종류';
const STRING_SHOW_DETAIL = '상세보기';
const STRING_SEARCH = '검색';
const STRING_CLEAN = '초기화';
const STRING_DEVICE_SEARCH = '디바이스 검색';
const STRING_TOTAL_DEVICE_IS_1 = '총 디바이스는 ';
const STRING_TOTAL_DEVICE_IS_2 = '개 입니다. 아래 검색을 통해 개별 디바이스를 조회할 수 있습니다.';
const STRING_MEMBER_ID = '회원 ID';
const STRING_LAST_CONNECT_AT = '최종 접속 일자';
const STRING_NOTIFICATION_STATE = '발송 여부';
const STRING_NOTIFICATION_DATE = '발송 시간';
const STRING_NO_CONNECT_INFOMATION = '접속 정보 없음';
const STRING_SENT = '발송';
const STRING_RESERVE_SEND = '발송 예정';
const STRING_DORMANCY_INFO = '휴면 전환 안내';
const STRING_INPUT_ID = '아이디를 입력하세요.';
const STRING_DORMANCY_INFO_IS_1 = '휴면 전환 메일 발송 건 수는 ';
const STRING_DORMANCY_INFO_IS_2 = ' 기준 총 ';
const STRING_DORMANCY_INFO_IS_3 = '건 입니다.';
const STRING_DORMANCY_USER_IS_1 = '휴면 사용자 수는 ';
const STRING_DORMANCY_USER_IS_2 = ' 기준 총 ';
const STRING_DORMANCY_USER_IS_3 = '명 입니다.';
const STRING_ID = '아이디';
const STRING_SEND_DATE = '발송일자';
const STRING_DATE_FORMAT_LOWER_CASE = 'yyyy-mm-dd';
const STRING_DORMANCY_DATE = '휴면 전환 일자';
const STRING_DORMANCY_MANAGE = '휴면 사용자 관리';
const STRING_FW_ADD = '펌웨어 추가';
const STRING_FOTA_FW = 'FOTA용 펌웨어';
const STRING_SERVICE_TOOLKIT_FW = 'Service Toolkit용 펌웨어';
const STRING_BS_FW = 'BS용 펌웨어';
const STRING_FW_UPLOAD = '펌웨어 업로드';
const STRING_ACTIVE = 'ACTIVE';
const STRING_FORCE = 'FORCE';
const STRING_GROUP = 'GROUP';
const STRING_FW_DESCRIPTION = '펌웨어 설명';
const STRING_FILE_NAME = '파일명';
const STRING_FILE_SIZE = '파일 크기';
const STRING_FILE_SELECT = '파일 선택';
const STRING_ALERT_FW_UPLOAD = '업로드 시 사전 검증 완료된 펌웨어만 등록해 주세요.';
const STRING_COMPULSORY_INPUT_DESCRIPTION = '펌웨어 설명 필수로 입력해야 업로드 활성화됩니다.';
const STRING_CANCEL = '취소';
const STRING_UPLOAD = '업로드';
const STRING_FILE_CRC = '파일 CRC';
const STRING_DATA_CRC = 'DATA CRC';
const STRING_PRODUCT = '제품군';
const STRING_MODEL_NUMBER = '모델 Number';
const STRING_TYPE = 'Type';
const STRING_FW_VERSION = '펌웨어 버전';
const STRING_SW_CODE = 'SW 코드';
const STRING_UPLOAD_TIME = '업데이트 예상 소요 시간';
const STRING_LINE_LIMIT = '4줄 이하로 입력하세요.';
const STRING_MINUTE = '분';
const STRING_EXIST_ACTIVE_FIRMWARE = '(현재 활성화된 펌웨어가 존재합니다 !)';
const STIRNG_SUCCESS_ADD_FIRMWARE = '펌웨어 추가 성공 !';
const STRING_FAIL_ADD_FIRMWARE = '펌웨어 추가 실패 !';
const STRING_WRONG_FW_INFO = '펌웨어 정보가 잘못되었습니다. 파일을 다시 확인하거나 다른 경로에서 추가해주세요 !';
const STRING_ALERT_CHECKBOX = '원하지 않을 경우, 체크박스를 해제해 주세요 !';
const STRING_CLOSE = '닫기';
const STRING_SETTING_ENGINEER = '서비스 기사 설정';
const STRING_ENGINEER_LIST = '서비스 기사 리스트';
const STRING_INPUT_NAME_OR_CENTER = '이름 또는 소속 대리점을 입력하세요.';
const STRING_CONFIRM = '확인';
const STRING_ADD = '추가';
const STRING_NAME = '이름';
const STRING_CENTER = '소속대리점';
const STRING_LOGIN_ID = '로그인 ID';
const STRING_PHONE_NUMBER = '휴대폰번호';
const STRING_ENGINEER_ID = '기사번호';
const STRING_DELETE = '삭제';
const STRING_NUMBER = '번호';
const STRING_FW_DELETE = '펌웨어 삭제';
const STRING_FW_URL = '펌웨어 경로';
const STRING_ALERT_FW_DELETE = '펌웨어 삭제 후 복구가 불가능합니다. 유의해주세요';
const STRING_FW_DETAIL_INFO = '펌웨어 상세 정보';
const STRING_DOWNROAD_URL = '다운로드 주소';
const STRING_FW_REGISTER_AT = '펌웨어 등록일';
const STRING_FW_LAST_MODIFY_AT = '펌웨어 수정일';
const STRING_ENTIRE_CRC = '(파일 전체에 대한 CRC)';
const STRING_GROUP_OTA = 'GROUP OTA';
const STRING_ALERT_GROUP_OTA_ENABLED = 'GROUP OTA를 수행할 디바이스를 입력하세요.';
const STRING_SUCCESS_GROUP = '그룹 설정 성공 !';
const STRING_FAIL_GROUP = '그룹 설정 실패 !';
const STRING_DUPLICATE_DEVICE_ID = '중복된 디바이스 아이디 입니다 !';
const STRING_FIRMWARE_UPDATE = '펌웨어 수정';
const STRING_FIRMWARE_DELETE = '펌웨어 삭제';
const STRING_CHECK_DELETE_FW = '삭제 전에 다시 한 번 펌웨어를 확인해주세요 !';
const STRING_CLICK_DELETE_BUTTON = '해당 펌웨어를 삭제하려면 확인 버튼을 클릭해주세요.';
const STRING_FAIL_DELETE_FIRMWARE = '펌웨어 삭제에 실패했습니다.';
const STIRNG_SUCCESS_DELETE_FIRMWARE = '펌웨어 삭제를 완료했습니다.';
const STRING_ALERT_FW_UPDATE_CHECK = '수정 사항을 한번 더 확인해주세요.';
const STRING_UPDATE = '수정';
const STIRNG_SUCCESS_UPDATE_FIRMWARE = '펌웨어 수정 성공 !';
const STRING_FAIL_UPDATE_FIRMWARE = '펌웨어 수정 실패 !';
const STRING_SELECT_MODEL = '모델을 선택해 주세요.';
const STRING_FIRMWARE_COUNT = '펌웨어 수';
const STRING_LAST_FW_REGISTER_AT = '마지막 펌웨어 등록 날짜';
const STRING_LAST_FW_MODIFY_AT = '마지막 펌웨어 업데이트 날짜';
const STRING_FILE_SIZE_BYTES = '파일크기(bytes)';
const STRING_ALERT_FW_LIST = '펌웨어 추가, 수정 시 각별한 주의를 부탁드립니다. 펌웨어 삭제는 담당자에게 문의하시기 바랍니다.';
const STRING_REGISTER_AT = '등록일';
const STRING_LAST_MODIFY_AT = '수정일';
const STRING_FIRMWARE_TYPE = '펌웨어 타입';
const STRING_ROOMCON = '룸콘';
const STRING_SUB_ROOMCON = '서브 룸콘';
const STRING_VALVE_CONTROLLER = '밸브 컨트롤러';
const STRING_SERVICE_TOOLKIT = '서비스 툴킷';
const STRING_WIFI = '와이파이 모듈';
const STRING_CERTIFICATE = '인증서';
const STRING_CONTROLLER = '컨트롤러';
const STRING_PANEL = '패널';
const STRING_CLICK_FILENAME = '파일명을 클릭 시 해당 파일을 다운로드 받을 수 있습니다. (인증서는 다운로드 불가)';
const STRING_PUSH_SERVICE = '푸시 서비스';
const STRING_ALERT_PUSH_MESSAGE = '푸시 발송 메시지는 신중히 기입 부탁드립니다.';
const STRING_TITLE = '제목';
const STRING_CONTENT = '내용';
const STRING_MODEL = '모델';
const STRING_MODEL_NR30D = 'NR-30D';
const STRING_MODEL_NR35D = 'NR-35D';
const STRING_MODEL_NR40D = 'NR-40D';
const STRING_MODEL_NRM35D = 'NRM-35D';
const STRING_MODEL_NR6XD = 'NR-6XD';
const STRING_MODEL_NRM6XD = 'NRM-6XD';
const STRING_MODEL_ALL = '전체모델';
const STRING_SUCCESS_PUSH_TITLE = '푸시 발송 성공';
const STRING_SUCCESS_PUSH_CONTENT = '푸시 발송이 성공적으로 완료되었습니다.';
const STRING_FAIL_PUSH_TITLE = '푸시 발송 실패';
const STRING_FAIL_PUSH_CONTENT = '푸시 발송이 실패하였습니다.';
const STRING_HANDLER_TYPE = '계정등급';
const STRING_ID_HANDLER = '아이디(처리자)';
const STRING_CONNECTION_DATE = '접속일시';
const STRING_CONNECTION_IP = '접속지정보';
const STRING_ID_MEMBER = '아이디(회원)';
const STRING_MENU = '메뉴';
const STRING_FEATURE = '수행업무';
const STRING_FEATURE_DETAIL = '수행상세내역';
const STRING_MANAGER = '관리자';
const STRING_DORMANT_USER = '휴면 사용자';
const STRING_APP = '앱';
const STRING_CHANGE_PASSWORD = '비밀번호변경';
const STRING_UPDATE_USER_PRIVATE_INFO = '개인정보수정';
const STRING_DEREGISTER_USER = '회원탈퇴';
const STRING_EMAIL = '이메일';
const STRING_MEMBER = '회원';
const STRING_RECORD = '개인정보 접속 및 처리 기록';
const STRING_PRIVATE_INFO_LIST = '개인정보 열람 내역을 확인합니다(처리결과는 clipboard copy → http://jasonviewer.stac.hu 사이트 viewer로 확인).';
const STRING_CHANGE = "변경";
const STRING_USER_DELETE = "사용자 삭제";
const STRING_ALERT_USER_DELETE_1 = "사용자를 삭제하면 다시 복구할 수 없습니다.";
const STRING_ALERT_USER_DELETE_2 = "사용자를 삭제할까요?";
const ROLE_CHANGE_HISTORY = '권한변경 이력';
const STRING_ACCOUNT = '계정';
const STRING_DELETE_DATE = '삭제 일시';
const STRING_GRADE = '등급';
const STRING_LAST_CONNECT = '최근접속';
const STRING_NAVIWATCH_USER_SEARCH = 'Naviwatch 사용자 검색';
const STRING_ROLE = '역할';
const STRING_USER_COUNT1 = 'Naviwatch 사용자수는 ';
const STRING_USER_COUNT2 = '기준 ';
const STRING_USER_COUNT3 = '명입니다.';
const STRING_SEARCH_DELETE_USER = '삭제된 유저 조회';
const STRING_ROLE_PAGE = '처음으로 돌아가기';
const STRING_1MIN = '1분';
const STRING_5MIN = '5분';
const STRING_10MIN = '10분';
const STRING_1HOUR = '1시간';
const STRING_1DAY = '1일';
const STRING_30DAY = '30일';
const STRING_MAX = 'MAX';
const STRING_MIN = 'MIN';
const STRING_AVG = 'AVG';
const STRING_DAY_ROOMCON_COUNT = '일일 룸콘 접속 수';
const STRING_DAY_USER_COUNT = '일일 사용자 수';
const STRING_DAY_CONTROL_COUNT = '일일 제어 수';
const STRING_CONNECT_TREND_MONITORING = '접속 추이 모니터링';
const STRING_OPERATING_MODE = '운전모드';
const STRING_INSIDE_HEAT = '실내난방';
const STRING_ONDOL_HEAT = '온돌난방';
const STRING_WATER_HEAT = '온수전용';
const STIRNG_RESERVATION_HEAT = '예약난방';
const STRING_ETC = '기타';
const STRING_REGION_CONNECT_USER = '지역 별 접속자';
const STRING_SET_DEVICE_COUNT = '설정한 디바이스 수';
const STRING_TEMPERATURE = '도';
const STRING_INSIDE_HEAT_DISTRIBUTION = '실내 난방 온도 분포';
const STRING_ONDOL_HEAT_DISTRIBUTION = '온돌 난방 온도 분포';
const STRING_WATER_HEAT_DISTRIBUTION = '온수 온도 분포';
const STRING_REGION_USER_DISTRIBUTION = '지역별 사용자 분포 (App을 통해 기기의 지역이 설정된 사용자만 집계됨)';
const STRING_CONNECT_USER = '접속자';
const STRING_PLANNING = '해당 서비스 지원 예정';
const STRING_PUSH_TITLE = '개별 푸시 발송';
const STRING_PUSH_CONTENT = ' 의 모바일에만 푸시를 전송합니다.';
const STRING_TIME_RANGE_AGAIN = '시간 범위를 다시 입력 해 주세요.';
const STRING_PUSH_SEND = '푸시 발송';
const STRING_USER_SEARCH = '사용자 검색';
const STRING_ALERT_USER_INFO = '사용자 정보는 법적 보호 대상입니다. 외부 유출, 사적 이용시 법적인 문제가 있을 수 있으니 각별히 유의해 주세요.';
const STRING_USER_PHONE_HYPHEN = '사용자 휴대폰 번호( - 제외)';
const STRING_TOTAL_USER_COUNT1 = '총 사용자수는 ';
const STRING_TOTAL_USER_COUNT2 = '명(휴면 회원 제외) 입니다. 아래 검색을 통해 개별사용자를 조회할 수 있습니다.';
const STRING_PHONE = '전화번호';
const STRING_RC_CONNECT_COUNT = '룸콘 접속 수';
const STRING_SETTING_ENGINEER_COMPLETE = '서비스 기사 설정을 완료하였습니다.';
const STRING_DUPLICATE_ENGINEER = '중복된 서비스 기사 입니다.';
const STRING_BS_CHECK = 'BS CHECK';
const STRING_CLEAN_ENGINEER_LIST_BS_FIRMWARE = '체크 박스 해제 시 기존에 설정되어 있던 서비스 기사 리스트가 초기화 됩니다 !';
const STRING_NO_NOTIFICATION_LOG = '발송 이력이 없습니다.';
const STRING_NO_DORMANT_USER = '휴면사용자가 아닙니다.';
const STRING_NO_SEARCH_DATA = '검색 결과가 없습니다.';
const STRING_NAVIEN_SMART_MIGRATE = '나비엔스마트 계정연동';
const STRING_EC2_AGS = 'stkr.prod.ec2.instance.ags';
const STRING_EC2_1ST_CONVERTER_A = 'stkr.prod.ec2.instance.converter1st.az2a';
const STRING_EC2_1ST_CONVERTER_B = 'stkr.prod.ec2.instance.converter1st.az2b';
const STRING_EC2_1ST_CONVERTER_C = 'stkr.prod.ec2.instance.converter1st.az2c';
const STRING_EC2_2ND_CONVERTER_A = 'stkr.prod.ec2.instance.converter2nd.az2a';
const STRING_EC2_2ND_CONVERTER_B = 'stkr.prod.ec2.instance.converter2nd.az2b';
const STRING_EC2_2ND_CONVERTER_C = 'stkr.prod.ec2.instance.converter2nd.az2c';
const STRING_EC2_PROXY = 'stkr.prod.ec2.instance.proxy';
const STRING_EC2_SERVICETOOLKIT = 'stkr.prod.ec2.instance.servicetoolkit';
const STRING_EC2_PRIVACY = 'stkr.prod.ec2.instance.privacy';
const STRING_RDS = 'stkr-prod-rds-cluster-main';
const STRING_EXTHEATDEMANDUSE = '외부 접점기능 사용 여부';
const STRING_EXTCOMMTYPE = '외부 디바이스 연동 방식';
const STRING_DELETE_AFTER_DISABLE_ACTIVE_FLAG = '펌웨어가 활성화되어 있습니다. 삭제하려면 먼저 비활성화해주세요.';
export {
    STRING_SERVICETOOLKIT_SERVER,
    STRING_NAVIEN_SMART_MIGRATE,
    STRING_EC2_AGS,
    STRING_EC2_1ST_CONVERTER_A,
    STRING_EC2_1ST_CONVERTER_B,
    STRING_EC2_1ST_CONVERTER_C,
    STRING_EC2_2ND_CONVERTER_A,
    STRING_EC2_2ND_CONVERTER_B,
    STRING_EC2_2ND_CONVERTER_C,
    STRING_EC2_PROXY,
    STRING_EC2_SERVICETOOLKIT,
    STRING_EC2_PRIVACY,
    STRING_RDS,
    STRING_ALL_REGION,
    STRING_REGION_COUNT,
    STRING_SHOW_ALL_REGION,
    STRING_ALL_DEVICE_DISTRIBUTION,
    STRING_ONLINE_DEVICE_DISTRIBUTION,
    STRING_REALTIME_MOBILE_CONNECT,
    STRING_DEVICE,
    STRING_ERROR_STATUS,
    STRING_NOW,
    STRING_24H,
    STRING_WEEK,
    STRING_MONTH,
    STRING_USER,
    STRING_DEVICE_USER,
    STRING_ALL,
    STRING_ONLINE,
    STRING_OFFLINE,
    STRING_STATUS_CON1,
    STRING_STATUS_CON2,
    STRING_STATUS_DB,
    STRING_STATUS_AGS,
    STRING_MOBILE_IOS,
    STRING_MOBILE_ANDROID,
    STRING_DB_SERVER,
    STRING_AGS_SERVER,
    STRING_PROXY_SERVER,
    STRING_PRIVACY_SERVER,
    STRING_MIGRATE_SERVER,
    STRING_DEVICE_DAY_TREND,
    STRING_REGISTER_DAY_TREND,
    STRING_USER_ID,
    STRING_USER_EMAIL,
    STRING_USER_NAME,
    STRING_USER_PHONE,
    STRING_USER_REGISTER_AT,
    STRING_USER_LAST_LOGIN_AT,
    STRING_DEVICE_ID,
    STRING_DEVICE_MODEL_NAME,
    STRING_CONNECTION_STATE,
    STRING_WARNING_USER_PRIVATE_INFO,
    STRING_UNKNOWN,
    STRING_USE,
    STRING_NOT_USE,
    STRING_CONNECTED,
    STIRNG_UNCONNECTED,
    STRING_START_DATE,
    STRING_END_DATE,
    STRING_DEVICE_LOG_SEARCH,
    STRING_LAST_1MONTH,
    STRING_DEVICE_CONTROL_LOG,
    STRING_DATE_FORMAT,
    STRING_DATEPICKER_FORMAT,
    STRING_DEVICE_CONTROL_LOG_INFO,
    STRING_NO_CONTROL_LOG,
    STRING_DATE,
    STRING_SETTING_MODE,
    STRING_SETTING_VALUE,
    STRING_DATE_FORMAT_INCLUDE_SEC,
    STRING_DEVICE_TYPE,
    STRING_SHOW_DETAIL,
    STRING_SEARCH,
    STRING_CLEAN,
    STRING_DEVICE_SEARCH,
    STRING_TOTAL_DEVICE_IS_1,
    STRING_TOTAL_DEVICE_IS_2,
    STRING_MEMBER_ID,
    STRING_LAST_CONNECT_AT,
    STRING_NOTIFICATION_STATE,
    STRING_NOTIFICATION_DATE,
    STRING_NO_CONNECT_INFOMATION,
    STRING_SENT,
    STRING_RESERVE_SEND,
    STRING_DORMANCY_INFO,
    STRING_INPUT_ID,
    STRING_DORMANCY_INFO_IS_1,
    STRING_DORMANCY_INFO_IS_2,
    STRING_DORMANCY_INFO_IS_3,
    STRING_ID,
    STRING_SEND_DATE,
    STRING_DATE_FORMAT_LOWER_CASE,
    STRING_DORMANCY_DATE,
    STRING_DORMANCY_MANAGE,
    STRING_FW_ADD,
    STRING_FOTA_FW,
    STRING_SERVICE_TOOLKIT_FW,
    STRING_BS_FW,
    STRING_FW_UPLOAD,
    STRING_ACTIVE,
    STRING_FORCE,
    STRING_GROUP,
    STRING_FW_DESCRIPTION,
    STRING_FILE_NAME,
    STRING_FILE_SIZE,
    STRING_FILE_SELECT,
    STRING_ALERT_FW_UPLOAD,
    STRING_COMPULSORY_INPUT_DESCRIPTION,
    STRING_CANCEL,
    STRING_UPLOAD,
    STRING_FILE_CRC,
    STRING_DATA_CRC,
    STRING_PRODUCT,
    STRING_MODEL_NUMBER,
    STRING_TYPE,
    STRING_FW_VERSION,
    STRING_SW_CODE,
    STRING_UPLOAD_TIME,
    STRING_LINE_LIMIT,
    STRING_MINUTE,
    STRING_EXIST_ACTIVE_FIRMWARE,
    STIRNG_SUCCESS_ADD_FIRMWARE,
    STRING_FAIL_ADD_FIRMWARE,
    STRING_WRONG_FW_INFO,
    STRING_ALERT_CHECKBOX,
    STRING_CLOSE,
    STRING_SETTING_ENGINEER,
    STRING_ENGINEER_LIST,
    STRING_INPUT_NAME_OR_CENTER,
    STRING_CONFIRM,
    STRING_ADD,
    STRING_NAME,
    STRING_CENTER,
    STRING_LOGIN_ID,
    STRING_PHONE_NUMBER,
    STRING_ENGINEER_ID,
    STRING_DELETE,
    STRING_NUMBER,
    STRING_FW_DELETE,
    STRING_FW_URL,
    STRING_ALERT_FW_DELETE,
    STRING_FW_DETAIL_INFO,
    STRING_DOWNROAD_URL,
    STRING_FW_REGISTER_AT,
    STRING_FW_LAST_MODIFY_AT,
    STRING_ENTIRE_CRC,
    STRING_GROUP_OTA,
    STRING_ALERT_GROUP_OTA_ENABLED,
    STRING_SUCCESS_GROUP,
    STRING_FAIL_GROUP,
    STRING_DUPLICATE_DEVICE_ID,
    STRING_FIRMWARE_UPDATE,
    STRING_FIRMWARE_DELETE,
    STRING_CHECK_DELETE_FW,
    STRING_CLICK_DELETE_BUTTON,
    STRING_FAIL_DELETE_FIRMWARE,
    STIRNG_SUCCESS_DELETE_FIRMWARE,
    STRING_ALERT_FW_UPDATE_CHECK,
    STRING_UPDATE,
    STIRNG_SUCCESS_UPDATE_FIRMWARE,
    STRING_FAIL_UPDATE_FIRMWARE,
    STRING_SELECT_MODEL,
    STRING_FIRMWARE_COUNT,
    STRING_LAST_FW_REGISTER_AT,
    STRING_LAST_FW_MODIFY_AT,
    STRING_FILE_SIZE_BYTES,
    STRING_ALERT_FW_LIST,
    STRING_REGISTER_AT,
    STRING_LAST_MODIFY_AT,
    STRING_FIRMWARE_TYPE,
    STRING_ROOMCON,
    STRING_SUB_ROOMCON,
    STRING_VALVE_CONTROLLER,
    STRING_SERVICE_TOOLKIT,
    STRING_WIFI,
    STRING_CERTIFICATE,
    STRING_CONTROLLER,
    STRING_PANEL,
    STRING_CLICK_FILENAME,
    STRING_PUSH_SERVICE,
    STRING_ALERT_PUSH_MESSAGE,
    STRING_TITLE,
    STRING_CONTENT,
    STRING_MODEL,
    STRING_MODEL_NR30D,
    STRING_MODEL_NR35D,
    STRING_MODEL_NR40D,
    STRING_MODEL_NRM35D,
    STRING_MODEL_NR6XD,
    STRING_MODEL_NRM6XD,
    STRING_MODEL_ALL,
    STRING_SUCCESS_PUSH_TITLE,
    STRING_SUCCESS_PUSH_CONTENT,
    STRING_FAIL_PUSH_TITLE,
    STRING_FAIL_PUSH_CONTENT,
    STRING_HANDLER_TYPE,
    STRING_ID_HANDLER,
    STRING_CONNECTION_DATE,
    STRING_CONNECTION_IP,
    STRING_ID_MEMBER,
    STRING_MENU,
    STRING_FEATURE,
    STRING_FEATURE_DETAIL,
    STRING_MANAGER,
    STRING_DORMANT_USER,
    STRING_APP,
    STRING_CHANGE_PASSWORD,
    STRING_UPDATE_USER_PRIVATE_INFO,
    STRING_DEREGISTER_USER,
    STRING_EMAIL,
    STRING_MEMBER,
    STRING_RECORD,
    STRING_PRIVATE_INFO_LIST,
    STRING_CHANGE,
    STRING_USER_DELETE,
    STRING_ALERT_USER_DELETE_1,
    STRING_ALERT_USER_DELETE_2,
    ROLE_CHANGE_HISTORY,
    STRING_ACCOUNT,
    STRING_DELETE_DATE,
    STRING_GRADE,
    STRING_LAST_CONNECT,
    STRING_NAVIWATCH_USER_SEARCH,
    STRING_ROLE,
    STRING_USER_COUNT1,
    STRING_USER_COUNT2,
    STRING_USER_COUNT3,
    STRING_SEARCH_DELETE_USER,
    STRING_ROLE_PAGE,
    STRING_1MIN,
    STRING_5MIN,
    STRING_10MIN,
    STRING_1HOUR,
    STRING_1DAY,
    STRING_30DAY,
    STRING_MAX,
    STRING_MIN,
    STRING_AVG,
    STRING_DAY_ROOMCON_COUNT,
    STRING_DAY_USER_COUNT,
    STRING_DAY_CONTROL_COUNT,
    STRING_CONNECT_TREND_MONITORING,
    STRING_OPERATING_MODE,
    STRING_INSIDE_HEAT,
    STRING_ONDOL_HEAT,
    STRING_WATER_HEAT,
    STIRNG_RESERVATION_HEAT,
    STRING_ETC,
    STRING_REGION_CONNECT_USER,
    STRING_SET_DEVICE_COUNT,
    STRING_TEMPERATURE,
    STRING_INSIDE_HEAT_DISTRIBUTION,
    STRING_ONDOL_HEAT_DISTRIBUTION,
    STRING_WATER_HEAT_DISTRIBUTION,
    STRING_REGION_USER_DISTRIBUTION,
    STRING_CONNECT_USER,
    STRING_PLANNING,
    STRING_PUSH_TITLE,
    STRING_PUSH_CONTENT,
    STRING_TIME_RANGE_AGAIN,
    STRING_PUSH_SEND,
    STRING_USER_SEARCH,
    STRING_ALERT_USER_INFO,
    STRING_USER_PHONE_HYPHEN,
    STRING_TOTAL_USER_COUNT1,
    STRING_TOTAL_USER_COUNT2,
    STRING_PHONE,
    STRING_RC_CONNECT_COUNT,
    STRING_SETTING_ENGINEER_COMPLETE,
    STRING_DUPLICATE_ENGINEER,
    STRING_BS_CHECK,
    STRING_CLEAN_ENGINEER_LIST_BS_FIRMWARE,
    STRING_NO_NOTIFICATION_LOG,
    STRING_NO_DORMANT_USER,
    STRING_NO_SEARCH_DATA,
    STRING_DORMANCY_USER_IS_1,
    STRING_DORMANCY_USER_IS_2,
    STRING_DORMANCY_USER_IS_3,
    STRING_EXTHEATDEMANDUSE,
    STRING_EXTCOMMTYPE,
    STRING_DELETE_AFTER_DISABLE_ACTIVE_FLAG
};