import React, { useContext, useEffect, useState } from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ProductCard from './ProductCard';
import MockData from '../../../product';
import useAsync from '../../../asyncNet';
import { naviwatchGetFullPolicies, naviwatchGetFullRoles } from '../APIs/naviwatchApis';
import { dispatchContext, userInfoContext } from '../../../App';
import { Backdrop, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    height: '90vh'
  },
  content: {
    height: '100%'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  spacer: {
    flexGrow: 1
  }
}));


const IntroProductView = ({history}) => {
  const classes = useStyles();
  const dispatch = useContext(dispatchContext);
  const authInfo =  useContext(userInfoContext);

  const [policies, tryGetPolicies] = useAsync(naviwatchGetFullPolicies, [], false);
  const [roles, tryGetRolus] = useAsync(naviwatchGetFullRoles, [], false);
  useEffect(() => {
    if(sessionStorage.getItem('naviwatch_permission')!=="true")
      history.push('/')
  }, []);

  useEffect(()=>{
    if(policies.data!==null){
      dispatch({
        type:'POLICY_UPDATE',
        policies: policies.data.data
      });
    }
  },[policies]);

  useEffect(()=>{
    if(roles.data!==null){
      dispatch({
        type:'ROLE_UPDATE',
        roles: roles.data.data
      });
    }
  },[roles]);

  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={!(authInfo.fullPolicies!==undefined&&authInfo.fullRoles!==undefined)}>
        <CircularProgress color={'primary'}/>
      </Backdrop>
      <Grid
        className={classes.content}
        container
        spacing={3}
      >
        {MockData.map(prd => (
            <ProductCard product={prd} disable={!prd.PRODUCT_ENABLE}/>
        ))}
      </Grid>
    </div>

  );
};

export default IntroProductView;
