import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core/styles';
import palette from 'theme/palette';

import {
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Divider,
  Button
} from '@material-ui/core';

import {
  options,
  hourCallback,
  dayCallback,
  getLavelDayCallback,
  getLavelHourCallback
} from './chart';
import axios from 'axios';
import useAsync from '../../../../../asyncNet';
import { getLambdaErrorCount } from '../../../APIs/mateApis';
const STRING_ERROR = 'Lambda Error';
const STRING_CHART_HOUR = '시간';
const STRING_CHART_DAY = '일간';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
  chartContainer: {
    position: 'relative',
    height: '400px'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const ErrorChart = props => {
  const { className, metricName, ...rest } = props;
  const [chartBasis, setChartBasis] = useState(STRING_CHART_DAY);
  const [optionsState, setOptionsState] = useState(options);
  const [state, tryRefetch] = useAsync(() => getMetric(chartBasis), []);
  const [chartdata, setChartData] = useState({
    labels: [],
    datasets: []
  });

  const { data: metric } = state;

  const classes = useStyles();

  const getMetric = unit => {
    console.log('getMetric unit: ' + unit);
    return getLambdaErrorCount(unit);
  };

  useEffect(() => {
    try {
      // console.log('@@@@@@@@@:' + metric['day']); '2024-08-04T05:50:00+00:00'
      if (metric !== null) {
        let optionsStateCopy = JSON.parse(JSON.stringify(optionsState));
        if (chartBasis === STRING_CHART_HOUR) {
          optionsStateCopy.scales.xAxes[0].ticks.callback = hourCallback;
          optionsStateCopy.tooltips.callbacks.label = getLavelHourCallback;
          setOptionsState(optionsStateCopy);
        } else {
          optionsStateCopy.scales.xAxes[0].ticks.callback = dayCallback;
          optionsStateCopy.tooltips.callbacks.label = getLavelDayCallback;
          setOptionsState(optionsStateCopy);
        }

        setChartData(chardata => ({
          ...chartdata,
          labels: metric['day'],
          datasets: [
            {
              type: 'line',
              label: 'line',
              fill: false,
              borderWidth: 2,
              borderColor: palette.error.main,
              data: metric['count']
            },
            {
              type: 'bar',
              label: 'error',
              lineTension: 0,
              fill: false,
              data: metric['count']
            }
          ]
        }));
      }
    } catch (e) {
      console.log('metric update failed :' + JSON.stringify(e));
    }
  }, [metric]);

  return (
    <div>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardHeader title={STRING_ERROR} />
        <Divider />
        <CardContent>
          <div className={classes.chartContainer}>
            <Bar data={chartdata} options={optionsState} />
          </div>
        </CardContent>
        <Divider />
        <CardActions className={classes.actions}>
          <Button
            color="primary"
            size="small"
            variant="text"
            onClick={() => {
              setChartBasis(STRING_CHART_HOUR);
              tryRefetch(STRING_CHART_HOUR);
            }}>
            {STRING_CHART_HOUR}
          </Button>
          <Button
            color="primary"
            size="small"
            variant="text"
            onClick={() => {
              setChartBasis(STRING_CHART_DAY);
              tryRefetch(STRING_CHART_DAY);
            }}>
            {STRING_CHART_DAY}
          </Button>
        </CardActions>
        <Divider />
      </Card>
    </div>
  );
};

ErrorChart.propTypes = {
  className: PropTypes.string
};

export default React.memo(ErrorChart);
