import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core/styles';
import palette from 'theme/palette';

import {
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Divider,
  Button
} from '@material-ui/core';

import { options } from './chart';
import axios from 'axios';
import useAsync from '../../../../../asyncNet';
import { getSignupCount } from '../../../APIs/tokapis';
const STRING_WEEKLY_DEVICE = ' 디바이스 추이';
const STRING_WEEKLY_USER = ' 가입자 추이';
const STRING_CHART_DAY = "일간";
const STRING_CHART_WEEK = "주간";
const STRING_CHART_MONTH = "월간";
const STRING_CHART_YEAR = "연간";
const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
  chartContainer: {
    position: 'relative',
    height: '400px'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));


const WeeklyChart = props => {

  const { className, metricName, ...rest } = props;
  const [state, tryRefetch] = useAsync(() => getMetric(metricName), []);
  const [chartdata, setChartData] = useState({
    labels: [],
    datasets: []
  });
  const [chartBasis, setChartBasis] = useState(STRING_CHART_MONTH);
  const { data: metric } = state;
  const classes = useStyles();
  const datasetKeyProvider = () => {
    return btoa(Math.random()).substring(0, 12)
  }

  const getMetric = metricName => {
    const currentTime = Math.floor(new Date() / 1000);
    if (metricName === "User") {
      var base = "month";
      if (chartBasis === STRING_CHART_DAY) {
        base = "day";
      } else if (chartBasis === STRING_CHART_WEEK) {
        base = "week";
      } else if (chartBasis === STRING_CHART_MONTH) {
        base = "month";
      } else if (chartBasis === STRING_CHART_YEAR) {
        base = "year";
      }
      return getSignupCount(base, 14);
    }
  }

  useEffect(() => {
    try {
      if (metric !== null) {
        const formattedTime = metric['date'];
        setChartData(chardata => ({
          ...chartdata,
          labels: formattedTime,
          datasets: [
            {
              type: 'line',
              fill: false,
              borderWidth: 2,
              borderColor: metricName === "User" ? palette.info.main : palette.error.main,
              data: metric['count'],
              barThickness: 12,
              maxBarThickness: 10,
              barPercentage: 0.5,
              categoryPercentage: 0.5,
            },
            {
              type: 'bar',
              label: metricName,
              lineTension: 0,
              fill: false,
              data: metric['count'],
              barThickness: 12,
              maxBarThickness: 10,
              barPercentage: 0.5,
              categoryPercentage: 0.5,
            }
          ]
        })
        );
      }
    } catch (e) {
      console.log('metric update failed :' + JSON.stringify(e));
    }
  }, [metric]);

  useEffect(() => {
    tryRefetch();
  }, [chartBasis]);


  return (
    <div>
      <Card
        {...rest}
        className={clsx(classes.root, className)}>
        <CardHeader
          title={metricName === 'User' ? chartBasis + STRING_WEEKLY_USER : chartBasis + STRING_WEEKLY_DEVICE}
        />
        <Divider />
        <CardContent>
          <div className={classes.chartContainer}>
            <Bar
              data={chartdata}
              datasetKeyProvider={datasetKeyProvider}
              options={options}
            />
          </div>
        </CardContent>
        <Divider />
        <CardActions className={classes.actions}>
          <Button
            color="primary"
            size="small"
            variant="text"
            onClick={() => {
              setChartBasis(STRING_CHART_DAY);
            }}
          >
            {STRING_CHART_DAY}
          </Button>
          <Button
            color="primary"
            size="small"
            variant="text"
            onClick={() => {
              setChartBasis(STRING_CHART_WEEK);
            }}
          >
            {STRING_CHART_WEEK}
          </Button>
          <Button
            color="primary"
            size="small"
            variant="text"
            onClick={() => {
              setChartBasis(STRING_CHART_MONTH);
            }}
          >
            {STRING_CHART_MONTH}
          </Button>
          <Button
            color="primary"
            size="small"
            variant="text"
            onClick={() => {
              setChartBasis(STRING_CHART_YEAR);
            }}
          >
            {STRING_CHART_YEAR}
          </Button>
        </CardActions>
        <Divider />
      </Card>
    </div>
  );
};

WeeklyChart.propTypes = {
  className: PropTypes.string
};

export default React.memo(WeeklyChart);
