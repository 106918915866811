import { atom } from 'recoil'

export const userAccount = atom({
  key: 'userAccount',
  default: ''
});

export const idToken = atom({
  key: 'idToken',
  default: ''
});

export const accessToken = atom({
  key: 'accessToken',
  default: ''
});

export const navienSmartUserOffset = atom({
  key: 'navienSmartUserOffset',
  default: 0
})