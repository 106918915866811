import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core/styles';
import palette from 'theme/palette';

import {
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Divider,
} from '@material-ui/core';
import {
  statisticsAMConnectCount,
  statisticsDeepSleepModeCount,
  statisticsODURunningCount
} from '../../../APIs/aironeApis';
import useAsync from '../../../../../asyncNet';

const STRING_AIRMONITOR_CONNECT = '연결';
const STRING_AIRMONITOR_DISCONNECT = '미연결';
const STRING_ODU_RUNNING = '동작';
const STRING_ODU_NOT_RUNNING = '미동작';
const STRING_DEEP_SLEEP_USE = '숙면 사용';
const STRING_DEEP_SLEEP_NOT_USE = '숙면 미사용';
const STRING_AM_TITLE = '실시간 에어모니터 연결 여부';
const STRING_ODU_TITLE = '실시간 ODU 동작 여부';
const STRING_DEEP_SLEEP_TITLE = '숙면 모드 사용 비율';


const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
  card: {
    backgroundColor: '#fff9db'
  },
  chartContainer: {
    height: '100%',
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const apiCall = (chartName) =>{
  if(chartName === "AM"){
    return statisticsAMConnectCount()
  } else if(chartName === "ODU"){
    return statisticsODURunningCount()
  } else if(chartName === "DEEPSLEEP") {
    return statisticsDeepSleepModeCount()
  }
};

const DoughnutChart = props => {
  const { className, chartName, ...rest } = props;
  const [state, tryRefetch] = useAsync(()=>apiCall(chartName),[]);
  const { data: metric} = state;
  const [chartLabel, setChartLabel] = useState([]);
  const [chartTitle, setChartTitle] = useState("");
  const [chartDataset, setChartDataset] = useState([]);

  const classes = useStyles();
  const [chartdata, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: '',
        fill: false,
        backgroundColor: palette.primary.main,
        data: []
      }
    ]
  });
  const options = {
    legend: {
      display: true,
      position: "right",
      align: "center",
      fontFamily: "Allianz-Neo",
      textDirection: 'ltr',
      labels: {
        usePointStyle: true,
        fontColor: "#006192",
      }
    }
  };

  const setData = (metric) =>{
    if(chartName === "AM"){
      let rate=Math.round(metric['connect']/(metric['connect']+metric['disconnect'])*100);
      setChartLabel([STRING_AIRMONITOR_CONNECT +' '+rate+'%', STRING_AIRMONITOR_DISCONNECT+' '+(100-rate)+'%']);
      setChartDataset([metric['connect'], metric['disconnect']]);
    } else if(chartName === "ODU"){
      let rate=Math.round(metric['connect']/(metric['connect']+metric['disconnect'])*100);
      setChartLabel([STRING_ODU_RUNNING +' '+rate+'%', STRING_ODU_NOT_RUNNING+' '+(100-rate)+'%']);
      setChartDataset([metric['connect'], metric['disconnect']]);
    } else if(chartName === "DEEPSLEEP"){
      let rate=Math.round(metric['use']/(metric['use']+metric['unUse'])*100);
      setChartLabel([STRING_DEEP_SLEEP_USE +' '+rate+'%', STRING_DEEP_SLEEP_NOT_USE+' '+(100-rate)+'%']);
      setChartDataset([metric['use'], metric['unUse']]);
    }
  };

  const setTitle = () =>{
    if(chartName === "AM"){
      setChartTitle(STRING_AM_TITLE);
    } else if(chartName === "ODU"){
      setChartTitle(STRING_ODU_TITLE);
    } else if(chartName === "DEEPSLEEP"){
      setChartTitle(STRING_DEEP_SLEEP_TITLE);
    }
  };


  useEffect(() => {
    try {
      setChartData({
          ...chartdata,
          labels: chartLabel,
          datasets: [
            {
              label: chartTitle,
              borderWidth: 1,
              backgroundColor: [palette.primary.main, palette.grey],
              data: chartDataset
            }]
        }
      );
    } catch (e) {
      console.log('metric update failed :' + JSON.stringify(e));
    }
  }, [chartDataset, chartLabel]);

  useEffect(()=>{
    if (metric !== null && metric !== undefined) {
      console.log('update doughnut: '+JSON.stringify(metric));
      setData(metric);
    }
  }, [metric]);

  useEffect( () =>{
    setTitle();
  }, []);

  return (
    <div>
      <Card
        {...rest}
        className={classes.root}>
        <CardHeader
          action={
            <IconButton size="small" style={{ color: 'white' }}>
            </IconButton>
          }
          title={chartTitle}
        />

        <Divider/>
        <CardContent>
          <div className={classes.chartContainer}>
            <Doughnut
              data={chartdata}
              options={options}
            />
          </div>
        </CardContent>
        <Divider/>
      </Card>
    </div>
  );
};

DoughnutChart.propTypes = {
  className: PropTypes.string
};

export default React.memo(DoughnutChart);
