import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
} from '@material-ui/core';
import { NO_PERMISSION, WRITE_PERMISSION } from '../../../../Common/Config/naviwatchConfig';

const useStyles = makeStyles(() => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  },
  title: {
    fontWeight: 700
  }
}));
const STRING_PUSH_SEND = '발송';
const STRING_TITLE = '푸시 서비스';
const STRING_SUBTITLE = '푸시 발송 메시지는 신중히 기입 부탁드립니다.';
const STRING_PUSH_TITLE = '제목';
const STRING_PUSH_CONTENTS = '내용';
const STRING_CONDITION_CLEAN = '초기화';
const STRING_MODEL_LABEL = '모델';
const STRING_MODEL_NTR10PW = 'NTR10PW';
const STRING_MODEL_NTR10PW2ND = 'NTR10PW2ND';
const STRING_MODEL_NRT20D = 'NRT20D';
const STRING_MODEL_NRT20L = 'NRT20L';
const STRING_MODEL_NRT21D = 'NRT21D';
const STRING_MODEL_NRT20DS = 'NRT20DS';
const STRING_MODEL_NRT20DSW = 'NRT20DSW';
const STRING_MODEL_NRT30L = 'NRT30L';
const STRING_MODEL_NRT30S = 'NRT30S';
const STRING_MODEL_ALL = '전체모델';

const models = [
  {
    value: ''
  },
  {
    value: STRING_MODEL_ALL
  },
  {
    value: STRING_MODEL_NTR10PW
  },
  {
    value: STRING_MODEL_NTR10PW2ND
  },
  {
    value: STRING_MODEL_NRT20D
  },
  {
    value: STRING_MODEL_NRT20L
  },
  {
    value: STRING_MODEL_NRT21D
  },
  {
    value: STRING_MODEL_NRT20DS
  },
  {
    value: STRING_MODEL_NRT20DSW
  },
  {
    value: STRING_MODEL_NRT30L
  },
  {
    value: STRING_MODEL_NRT30S
  }
];

const PushToolbar = props => {
  const { className, callbackFunc, ...rest } = props;

  const classes = useStyles();

  const [values, setValues] = useState({
    model: '',
    title: '',
    content: '',
  });

  const [permission, setPermission] = useState(NO_PERMISSION);
  useEffect(()=>{
    var pageInfo = sessionStorage.getItem('pages');
    var pageInfoObj = JSON.parse(pageInfo);
    var pageArray = pageInfoObj.filter(page=>page.name.toLowerCase().includes("push"));
    if(pageArray!==undefined && pageArray.length > 0) {
      var page = pageArray[0];
      setPermission(page.permission);
    }
  }, []);
  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };


  const cleanInput = () =>{
    setValues({
      model: '',
      title: '',
      content: '',
    });
  };

  const sendPush = () =>{
    callbackFunc(values);
    cleanInput();
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        title={STRING_TITLE}
        subheader={<div>
          <span style={{ color: 'red' }}>{STRING_SUBTITLE}</span></div>}
      />
      <Divider/>
      <CardContent>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            md={4}
            xs={12}
          >
            <TextField
              fullWidth
              label={STRING_MODEL_LABEL}
              margin="dense"
              name="model"
              onChange={handleChange}
              select
              // eslint-disable-next-line react/jsx-sort-props
              SelectProps={{ native: true }}
              value={values.model}
              variant="outlined"
            >
              {models.map(option => (
                <option
                  key={option.value}
                  value={option.value}
                >
                  {option.value}
                </option>
              ))}
            </TextField>
          </Grid>
          <Grid
            item
            md={12}
            xs={12}
          >
            <TextField
              fullWidth
              label={STRING_PUSH_TITLE}
              margin="dense"
              name="title"
              onChange={handleChange}
              value={values.title}
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            md={12}
            xs={12}
          >
            <TextField
              fullWidth
              multiline={true}
              rows={10}
              label={STRING_PUSH_CONTENTS}
              margin="dense"
              name="content"
              onChange={handleChange}
              value={values.content}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </CardContent>
      <Divider/>
      <CardActions className={classes.actions}>
        <Button
          color="primary"
          variant="contained"
          onClick={cleanInput}
          disabled={values.model==="" && values.title===""&& values.content===""}
        >
          {STRING_CONDITION_CLEAN}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={sendPush}
          disabled={values.model==="" || values.title===""||
          values.content==="" || permission<WRITE_PERMISSION}
        >
          {STRING_PUSH_SEND}
        </Button>
      </CardActions>
    </Card>
  );
};

PushToolbar.propTypes = {
  className: PropTypes.string
};

export default PushToolbar;
