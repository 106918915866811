import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import useAsync from '../../../../../asyncNet';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
} from '@material-ui/core';
import { userGetTotalCount } from '../../../APIs/mateApis';

const useStyles = makeStyles((theme) => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  },
  title: {
    fontWeight: 700
  },
  subtitle:{
    marginTop: theme.spacing(2),
  }
}));
const STRING_SEARCH = '검색';
const STRING_TITLE = '사용자 검색';
const STRING_ID_LABEL = '사용자 ID';
const STRING_EMAIL_LABEL = '사용자 EMAIL';
const STRING_NAME_LABEL = '사용자 이름';
const STRING_USER_COUNT = '일반 사용자수는 ';
const STRING_USER_COUNT2 = '기준 ';

const STRING_USER_COUNT3 = '명 입니다.';
const STRING_SEARCH_CLEAN = '초기화';

const UsersToolbar = props => {
  const { className, callbackFunc, ...rest } = props;

  const classes = useStyles();
  const [state] = useAsync(userGetTotalCount, []);
  const { data: userCount } = state;
  const today = new Date().toLocaleDateString();

  const [values, setValues] = useState({
    userid: '',
    useremail: '',
    username: '',
  });

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };


  const cleanSearchInput = () =>{
    setValues({
      userid: '',
      useremail: '',
      username: '',
    });
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        title={STRING_TITLE}
        subheader={
          <div className={classes.subtitle}>
          <span>{STRING_USER_COUNT +today+STRING_USER_COUNT2 + (userCount === null ? 0 : userCount.userCount) + STRING_USER_COUNT3}<br/></span>
          </div>
        }
      />
      <Divider/>
      <CardContent>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            md={4}
            xs={12}
          >
            <TextField
              fullWidth
              label={STRING_ID_LABEL}
              margin="dense"
              name="userid"
              onChange={handleChange}
              value={values.userid}
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            md={4}
            xs={12}
          >
            <TextField
              fullWidth
              label={STRING_EMAIL_LABEL}
              margin="dense"
              name="useremail"
              type='email'
              onChange={handleChange}
              value={values.useremail}
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            md={4}
            xs={12}
          >
            <TextField
              fullWidth
              label={STRING_NAME_LABEL}
              margin="dense"
              name="username"
              onChange={handleChange}
              value={values.username}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </CardContent>
      <Divider/>
      <CardActions className={classes.actions}>
        <Button
          color="primary"
          variant="contained"
          onClick={cleanSearchInput}
          disabled={values.userid==="" && values.useremail===""&& values.username===""}
        >
          {STRING_SEARCH_CLEAN}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={()=>callbackFunc(values)}
          disabled={values.userid==="" && values.useremail===""&& values.username===""}
        >
          {STRING_SEARCH}
        </Button>
      </CardActions>
    </Card>
  );
};

UsersToolbar.propTypes = {
  className: PropTypes.string
};

export default UsersToolbar;
