import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Bar  } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core/styles';
import palette from 'theme/palette';

import {
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Divider,
} from '@material-ui/core';

import useAsync from '../../../../../asyncNet';
import { statisticsWMTemperaturelevel } from '../../../APIs/mateApis';

const STRING_TEMPERATURE = '사용자 설정 온도 분포';


const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
  card: {
    backgroundColor: '#fff9db'
  },
  chartContainer: {
    height: '40vh'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));


const getTitle = () =>{
  var title = STRING_TEMPERATURE;

  return title;
};

const TemperatureChart = props => {
  const { className, ...rest } = props;
  const [state, tryRefetch] = useAsync(() => statisticsWMTemperaturelevel());
  const { data: metric } = state;
  const classes = useStyles();

  const [chartdata, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: '',
        fill: false,
        backgroundColor: palette.info.main,
        data: []
      }
    ]
  });

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: { display: false },
    cornerRadius: 5,
    scales: {
      xAxes: [
        {
          barPercentage: 0.5,
          categoryPercentage: 0.5,
          ticks: {
            fontColor: palette.text.secondary
          },
          gridLines: {
            display: false,
            drawBorder: false
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            fontColor: palette.text.secondary,
          },
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: palette.divider
          }
        }
      ]
    },
  };
  const xAxesStringMaker = n => {
    var xAxes = n+"단";
    return xAxes;
  };

  useEffect(() => {
    try {
      if (metric !== null) {
        const formattedXAxes = metric['temperature'].map(xAxesStringMaker);
        setChartData(chardata => ({
            ...chartdata,
            labels: formattedXAxes,
            datasets: [
              {
                label: '온도값 설정 수',
                borderWidth: 1,
                backgroundColor: 'rgba(54, 162, 235, 0.5)',
                barThickness: 15,
                data: metric['count'],
              }]
          })
        );
      }
    } catch (e) {
      console.log('metric update failed :' + JSON.stringify(e));
    }
  }, [metric]);

  useEffect(() => {
    tryRefetch();
  }, []);

  return (
    <div>
      <Card
        {...rest}
        className={classes.root}>
        <CardHeader
          action={
            <IconButton size="small" style={{ color: 'white' }}>
            </IconButton>
          }
          title={getTitle()}
        />

        <Divider/>
        <CardContent>
          <div className={classes.chartContainer}>
            <Bar
              data={chartdata}
              options={options}
            />
          </div>
        </CardContent>
        <Divider/>
      </Card>
    </div>
  );
};

TemperatureChart.propTypes = {
  className: PropTypes.string
};

export default React.memo(TemperatureChart);
