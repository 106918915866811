import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import ReactApexChart from 'react-apexcharts';
import moment from 'moment';

import { Card } from '@material-ui/core';

const DeviceLogChart = props => {
  const { chartData, ...rest } = props;
  const COLOR = ['#546E7A', '#FF0000', '#008FFB', '#9933FF'];
  const CHART_COLUMN = ['set', 'current', 'operationMode', 'connected'];

  const logData = useMemo(() => {
    const chartColumns = Object.keys(chartData[0]).filter(x =>
      CHART_COLUMN.includes(x)
    );
    return chartData.reduce((data, current, index) => {
      let currentEventDate = new Date(current['eventdate']);
      if (index == 0) {
        chartColumns.map(column => {
          data[column] = [];
        });
      }
      currentEventDate = currentEventDate.setHours(
        currentEventDate.getHours() + 9
      );
      chartColumns.map(column => {
        data[column].push({
          x: currentEventDate,
          y: ['operationMode', 'connected'].includes(column)
            ? Number(current[column]) * 5
            : current[column]
        });
      });
      return data;
    }, {});
  }, [chartData, chartData.length]);

  const chartState = useMemo(() => {
    if (Object.keys(logData).length > 0) {
      return {
        series: Object.keys(logData).map(log => {
          return {
            name: log,
            data: logData[log]
          };
        }),
        options: {
          chart: {
            id: 'chart2',
            type: 'line',
            height: 300,
            toolbar: {
              autoSelected: 'pan',
              show: false
            }
          },
          colors: COLOR.slice(0, Object.keys(logData).length),
          stroke: {
            curve: 'stepline',
            width: [5],
            dashArray: [5]
          },
          dataLabels: {
            enabled: false
          },
          fill: {
            opacity: 1
          },
          markers: {
            size: 0,
            hover: {
              sizeOffset: 3
            }
          },
          xaxis: {
            type: 'datetime',
            labels: {
              show: true,
              rotate: -45
            },
            axisTicks: {
              show: true
            }
          },
          yaxis: {
            min: 0,
            max: 40,
            tickAmount: 5
          },
          tooltip: {
            x: {
              show: true,
              format: 'YYYY-MM-DD HH:mm:ss',
              formatter: function(value) {
                return new Date(value)
                  .toISOString()
                  .replace('T', ' ')
                  .substring(0, 19);
              }
            },
            y: {
              show: true,
              formatter: function(value) {
                if (value == 0) {
                  return '0';
                } else if (value < 6) {
                  return (value / 5).toString();
                }
                return value.toString();
              }
            }
          }
        },
        optionsLine: {
          chart: {
            id: 'chart1',
            height: 130,
            type: 'line',
            brush: {
              target: 'chart2',
              enabled: true
            },
            selection: {
              enabled: true,
              xaxis: {
                min: logData[Object.keys(logData)[0]][0].x,
                max:
                  logData[Object.keys(logData)[0]][
                    logData[Object.keys(logData)[0]].length - 1
                  ].x
              }
            }
          },
          colors: COLOR.slice(0, Object.keys(logData).length),
          stroke: {
            curve: 'stepline',
            width: 3
          },
          xaxis: {
            type: 'datetime',
            tooltip: {
              enabled: false
            }
          },
          yaxis: {
            tickAmount: 2,
            min: 0,
            max: 40
          }
        }
      };
    } else {
      return {
        optionsLine: {},
        series: [],
        options: {}
      };
    }
  }, [logData]);

  return (
    <div>
      {Object.keys(logData).length > 0 && (
        <Card>
          <div id="wrapper">
            <ReactApexChart
              options={chartState.options}
              series={chartState.series}
              type="line"
              height={300}
            />
          </div>
          <div id="chart-line">
            <ReactApexChart
              options={chartState.optionsLine}
              series={chartState.series}
              type="area"
              height={130}
            />
          </div>
        </Card>
      )}
    </div>
  );
};

DeviceLogChart.propTypes = {
  className: PropTypes.string,
  resultSet: PropTypes.array
};

export default DeviceLogChart;
