import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core/styles';
import palette from 'theme/palette';
import {
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Divider
} from '@material-ui/core';
import useAsync from '../../../../../asyncNet';
import {
  STRING_INSIDE_HEAT_DISTRIBUTION,
  STRING_ONDOL_HEAT_DISTRIBUTION,
  STRING_WATER_HEAT_DISTRIBUTION,
  STRING_SET_DEVICE_COUNT,
  STRING_TEMPERATURE
} from '../../../StringTable/StringTable';


const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
  card: {
    backgroundColor: '#fff9db'
  },
  chartContainer: {
    height: '20vh'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

// TODO : to be implemented.. 
async function getMetric() {
  return;
}




const TemperatureChart = props => {
  const { className, metricName, ...rest } = props;
  const [state, tryRefetch] = useAsync(() => getMetric(metricName));
  const { loading, data: metric, error } = state;
  const classes = useStyles();


  const getTitle = metricName => {
    var title = '';
    if (metricName === 'InsideHeat') {
      title = STRING_INSIDE_HEAT_DISTRIBUTION;
    } else if (metricName === 'OndolHeat') {
      title = STRING_ONDOL_HEAT_DISTRIBUTION;
    } else if (metricName === 'WaterHeat') {
      title = STRING_WATER_HEAT_DISTRIBUTION;
    }

    return title;
  };

  const [chartdata, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: '',
        fill: false,
        backgroundColor: palette.info.main,
        data: []
      }
    ]
  });

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: { display: false },
    cornerRadius: 5,
    scales: {
      xAxes: [
        {
          barPercentage: 0.5,
          categoryPercentage: 0.5,
          ticks: {
            fontColor: palette.text.secondary
          },
          gridLines: {
            display: false,
            drawBorder: false
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            fontColor: palette.text.secondary,
          },
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: palette.divider
          }
        }
      ]
    },
  };
  const xAxesStringMaker = n => {
    var xAxes = n + STRING_TEMPERATURE;
    return xAxes;
  };

  useEffect(() => {
    try {
      if (metric !== null) {
        // const formattedXAxes = metric['temperature'].map(xAxesStringMaker);
        if (metricName === 'InsideHeat') {
          var arr = [];
          var heat = 10;
          for (let i = 0; i <= 60; i++) {
            arr[i] = heat;
            heat = heat + 0.5;
          }
          const formattedXAxes = arr.map(xAxesStringMaker);
          setChartData(chardata => ({
            ...chartdata,
            labels: formattedXAxes,
            datasets: [
              {
                label: STRING_SET_DEVICE_COUNT,
                borderWidth: 1,
                backgroundColor: 'rgba(255, 233, 133)',
                barThickness: 20,
                // data: metric['count'],
                data: [22, 98, 68, 43, 83, 23, 82, 198, 168, 143, 183, 224, 353, 264, 403, 205, 130, 224, 653, 264, 403, 205, 130,
                  303, 150, 132, 364, 634, 722, 630, 835, 632, 364, 722, 630, 835, 632, 364, 234, 122, 653, 264, 303, 150,
                  132, 364, 403, 205, 82, 198, 168, 143, 183, 98, 68, 43, 83, 23, 10, 23, 15]
              }]
          })
          );
        } else if (metricName === 'OndolHeat') {
          var arr = [];
          var heat = 40;
          for (let i = 0; i <= 80; i++) {
            arr[i] = heat;
            heat = heat + 0.5;
          }
          const formattedXAxes = arr.map(xAxesStringMaker);
          setChartData(chardata => ({
            ...chartdata,
            labels: formattedXAxes,
            datasets: [
              {
                label: STRING_SET_DEVICE_COUNT,
                borderWidth: 1,
                backgroundColor: 'rgba(255, 53, 56)',
                barThickness: 15,
                // data: metric['count'],
                data: [22, 98, 68, 43, 83, 23, 22, 98, 68, 43, 83, 23, 82, 198, 168, 143, 183, 224, 353, 264, 403, 205, 130, 224, 653, 264, 403, 205, 130,
                  303, 150, 132, 364, 634, 722, 630, 835, 632, 364, 722, 630, 835, 632, 364, 234, 122, 653, 264, 303, 150, 132, 364,
                  403, 205, 82, 198, 168, 143, 183, 98, 68, 43, 83, 132, 364, 234, 622,
                  1003, 2005, 1300, 903, 1503, 632, 364, 234, 122, 22, 98, 68, 43, 83, 23]
              }]
          })
          );

        } else {
          var arr = [];
          var heat = 60;
          for (let i = 0; i <= 40; i++) {
            arr[i] = heat;
            heat = heat + 0.5;
          }
          const formattedXAxes = arr.map(xAxesStringMaker);
          setChartData(chardata => ({
            ...chartdata,
            labels: formattedXAxes,
            datasets: [
              {
                label: STRING_SET_DEVICE_COUNT,
                borderWidth: 1,
                backgroundColor: 'rgba(54, 162, 235)',
                barThickness: 30,
                // data: metric['count'],
                data: [22, 98, 68, 43, 83, 23, 150, 132, 364, 634, 722, 630, 835, 632, 364, 722, 630, 835, 632, 364, 1003,
                  2005, 1300, 903, 1503, 1325, 1235, 1630, 835, 634, 722, 630, 632, 364, 234, 122, 98, 68, 43, 83, 23]
              }]
          })
          );
        }




      }
    } catch (e) {
      console.log('metric update failed :' + JSON.stringify(e));
    }
  }, [metric]);

  useEffect(() => {
    tryRefetch();
  }, []);

  return (
    <div>
      <Card
        {...rest}
        className={classes.root}
        style={{ flex: 1, backgroundColor: 'lightgray' }}
      >
        <CardHeader
          action={
            <IconButton size="small" style={{ color: 'white' }}>
            </IconButton>
          }
          title={getTitle(metricName)}
        />

        <Divider />
        <CardContent>
          <div className={classes.chartContainer}>
            <Bar
              data={chartdata}
              options={options}
            />
          </div>
        </CardContent>
        <Divider />
      </Card>
    </div>
  );
};

TemperatureChart.propTypes = {
  className: PropTypes.string
};

export default React.memo(TemperatureChart);
