import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core/styles';
import palette from 'theme/palette';

import {
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Divider,
  Button
} from '@material-ui/core';

import { options } from './chart';
import axios from 'axios';
import useAsync from '../../../../../asyncNet';
import { dashboardGetUserIncrease } from '../../../APIs/aironeApis';
const STRING_WEEKLY_DEVICE = ' 디바이스 추이';
const STRING_WEEKLY_USER = ' 가입자 추이';
const STRING_CHART_DAY = "일간";
const STRING_CHART_WEEK = "주간";
const STRING_CHART_MONTH = "월간";
const STRING_CHART_YEAR = "연간";

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
  chartContainer: {
    position: 'relative',
    height: '400px'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));


const WeeklyChart = props => {

  const { className, ...rest } = props;
  const [state, tryRefetch] = useAsync(() => getMetric(), []);
  const [chartdata, setChartData] = useState({
    labels: [],
    datasets: []
  });
  const [chartBasis, setChartBasis] = useState(STRING_CHART_MONTH);

  const { data: metric } = state;

  const classes = useStyles();


  const getMetric = () => {
    var basis = "month";
    if(chartBasis === STRING_CHART_DAY){
      basis = "day";
    }else if(chartBasis === STRING_CHART_WEEK){
      basis = "week";
    }else if(chartBasis === STRING_CHART_MONTH){
      basis = "month";
    }else if(chartBasis === STRING_CHART_YEAR){
      basis = "year";
    }
    return dashboardGetUserIncrease(14, basis);
  }

  useEffect(() => {
    try {
      console.log('@@@@@@@@@:' + metric['day']);
      if (metric !== null) {
        const formattedTime = metric['day'];
        setChartData(chardata => ({
          ...chartdata,
          labels: formattedTime,
          datasets: [
            {
              type: 'line',
              label : 'line',
              fill: false,
              borderWidth: 2,
              borderColor: palette.info.main,
              data: metric['count']
            },
            {
              type: 'bar',
              label: "User",
              lineTension: 0,
              fill: false,
              data: metric['count']
            }
          ]
        })
        );
      }
    } catch (e) {
      console.log('metric update failed :' + JSON.stringify(e));
    }
  }, [metric]);

  useEffect(() => {
    tryRefetch();
  }, [chartBasis]);


  return (
    <div>
      <Card
        {...rest}
        className={clsx(classes.root, className)}>
        <CardHeader
          title={chartBasis+STRING_WEEKLY_USER}
        />
        <Divider />
        <CardContent>
          <div className={classes.chartContainer}>
            <Bar
              data={chartdata}
              options={options}
            />
          </div>
        </CardContent>
        <Divider />
        <CardActions className={classes.actions}>
        <Button
          color="primary"
          size="small"
          variant="text"
          onClick={()=>{
            setChartBasis(STRING_CHART_DAY);
          }}
        >
          {STRING_CHART_DAY}
        </Button>
        <Button
          color="primary"
          size="small"
          variant="text"
          onClick={()=>{
            setChartBasis(STRING_CHART_WEEK);
          }}
        >
          {STRING_CHART_WEEK}
        </Button>
        <Button
          color="primary"
          size="small"
          variant="text"
          onClick={()=>{
            setChartBasis(STRING_CHART_MONTH);
          }}
        >
          {STRING_CHART_MONTH}
        </Button>
        <Button
          color="primary"
          size="small"
          variant="text"
          onClick={()=>{
            setChartBasis(STRING_CHART_YEAR);
          }}
        >
          {STRING_CHART_YEAR}
        </Button>

      </CardActions>
        <Divider />
      </Card>
    </div>
  );
};

WeeklyChart.propTypes = {
  className: PropTypes.string
};

export default React.memo(WeeklyChart);
