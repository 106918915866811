import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Card, CardHeader } from '@material-ui/core';
import ProductCard from './ProductCard';
import { Link } from 'react-router-dom';
import MockData from './Product';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    height: '90vh'
  },
  content: {
    height: '100%'
  },
  item: {
    height: '50%'
  },
  spacer: {
    flexGrow: 1
  }
}));

const FirmwareProductView = ({ history, match }) => {
  const classes = useStyles();
  let firmwareType = match.params.firmwareType;
  if (firmwareType == undefined) {
    firmwareType = 'firmwareOfficial';
  }
  const [mouseOver, setMouseOver] = useState(-1);
  useEffect(() => {
    if (sessionStorage.getItem('naviwatch_permission') !== "true")
      history.push('/')
  }, []);

  return (
    <>
      {firmwareType &&
        <Card>
          <CardHeader
            title={firmwareType}
          />
          <div className={classes.root}>
            <Grid
              className={classes.content}
              container
              spacing={3}
            >

              {MockData.map(prd => (
                <Grid
                  className={classes.item}
                  item
                  key={prd.PRODUCT_ID}
                  lg={4}
                  md={4}
                  xs={6}
                  onMouseOver={() => setMouseOver(prd.PRODUCT_ID)}
                  onMouseOut={() => setMouseOver(-1)}
                >
                  <Link to={prd.PRODUCT_ENABLE ? `/smartplus/firmware/${firmwareType}/${prd.PRODUCT_NAME}` : `smartplus/firmware/${firmwareType}`}>
                    <ProductCard product={prd} mouseOver={mouseOver} disable={!prd.PRODUCT_ENABLE} /></Link>
                </Grid>
              ))}
            </Grid>
          </div>
        </Card>
      }
    </>

  );
};

export default FirmwareProductView;
