import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Button,
  Divider,
  Typography
} from '@material-ui/core';
import HotTubIcon from '@material-ui/icons/HotTub';
import useAsync from '../../../../../asyncNet';
import axios from 'axios';
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from '@material-ui/core/InputLabel';
import MemoryIcon from '@material-ui/icons/Memory';
import { getModelCount } from '../../../APIs/tokapis';
import { STRING_ONLINE_DEVICE_DISTRIBUTION, STRING_ALL_DEVICE_DISTRIBUTION } from '../../../StringTable/StringTable'


const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  chartContainer: {
    position: 'relative',
    height: '300px'
  },
  stats: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center'
  },
  device: {
    textAlign: 'center',
    padding: theme.spacing(1)
  },
  deviceIcon: {
    color: theme.palette.icon
  }
}));

const ModelCounter = props => {
  const { className, status, ...rest } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [state, tryRefetch] = useAsync(() => getModelCount(status), []);
  const { loading, data: models, error } = state;
  const [modelsChart, setModelsChart] = useState({});
  const [modelData, setModelData] = useState([]);


  useEffect(() => {
    try {
      if (models !== null) {
        // update model data
        if (models['thingsName'].length >= 3) {
          // update chart
          setModelsChart(modelsChart => ({
            ...modelsChart,
            datasets: [
              {
                data: models['thingsCount'],
                backgroundColor: [
                  theme.palette.primary.main,
                  theme.palette.info.main,
                  theme.palette.error.main,
                  theme.palette.secondary.main,
                  theme.palette.warning.main,
                  theme.palette.success.main
                ],
                borderWidth: 8,
                borderColor: theme.palette.white,
                hoverBorderColor: theme.palette.white
              }
            ],
            labels: models['thingsName']
          }));

          var firstValue = Math.round(models['thingsCount'][0] *
            (100 / (models['thingsCount'][0] + models['thingsCount'][1] + models['thingsCount'][2] + models['thingsCount'][3] + models['thingsCount'][4] + models['thingsCount'][5])));
          var secondValue = Math.round(models['thingsCount'][1] *
            (100 / (models['thingsCount'][0] + models['thingsCount'][1] + models['thingsCount'][2] + models['thingsCount'][3] + models['thingsCount'][4] + models['thingsCount'][5])));
          var thirdValue = Math.round(models['thingsCount'][2] *
            (100 / (models['thingsCount'][0] + models['thingsCount'][1] + models['thingsCount'][2] + models['thingsCount'][3] + models['thingsCount'][4] + models['thingsCount'][5])));
          var fourthValue = Math.round(models['thingsCount'][3] *
            (100 / (models['thingsCount'][0] + models['thingsCount'][1] + models['thingsCount'][2] + models['thingsCount'][3] + models['thingsCount'][4] + models['thingsCount'][5])));
          var fifthValue = Math.round(models['thingsCount'][4] *
            (100 / (models['thingsCount'][0] + models['thingsCount'][1] + models['thingsCount'][2] + models['thingsCount'][3] + models['thingsCount'][4] + models['thingsCount'][5])));
          var sixthValue = Math.round(models['thingsCount'][5] *
            (100 / (models['thingsCount'][0] + models['thingsCount'][1] + models['thingsCount'][2] + models['thingsCount'][3] + models['thingsCount'][4] + models['thingsCount'][5])));
          setModelData(
            [
              {
                title: models['thingsName'][0],
                value: firstValue,
                icon: <MemoryIcon style={{ color: theme.palette.primary.main }} />,
                color: theme.palette.primary.main
              },
              {
                title: models['thingsName'][1],
                value: secondValue,
                icon: <MemoryIcon style={{ color: theme.palette.info.main }} />,
                color: theme.palette.info.main
              },
              {
                title: models['thingsName'][2],
                value: thirdValue,
                icon: <MemoryIcon style={{ color: theme.palette.error.main }} />,
                color: theme.palette.error.main
              },
              {
                title: models['thingsName'][3],
                value: fourthValue,
                icon: <MemoryIcon style={{ color: theme.palette.secondary.main }} />,
                color: theme.palette.secondary.main
              },
              {
                title: models['thingsName'][4],
                value: fifthValue,
                icon: <MemoryIcon style={{ color: theme.palette.warning.main }} />,
                color: theme.palette.warning.main
              },
              {
                title: models['thingsName'][5],
                value: sixthValue,
                icon: <MemoryIcon style={{ color: theme.palette.success.main }} />,
                color: theme.palette.success.main
              }
            ]
          )
        }
      }
    } catch (e) {
      console.log('get models count failed: ' + JSON.stringify(e));
    }

  }, [models]);

  const options = {
    legend: {
      display: false
    },
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      borderWidth: 1,
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.white,
      titleFontColor: theme.palette.text.primary,
      bodyFontColor: theme.palette.text.secondary,
      footerFontColor: theme.palette.text.secondary
    }
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        title={status === "online" ? STRING_ONLINE_DEVICE_DISTRIBUTION : STRING_ALL_DEVICE_DISTRIBUTION}
      />
      <Divider />
      <CardContent>
        <div className={classes.chartContainer}>
          {modelsChart !== null &&
            <Doughnut
              data={modelsChart}
              options={options}
            />
          }
        </div>
        <div className={classes.stats}>
          {modelData !== null && modelData.map(device => (
            <div
              className={classes.device}
              key={device.title}
            >
              <span className={classes.deviceIcon}>{device.icon}</span>
              <Typography variant="body1">{device.title}</Typography>
              <Typography
                style={{ color: device.color }}
                variant="body1"
              >
                {device.value}%
              </Typography>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};

ModelCounter.propTypes = {
  className: PropTypes.string
};

export default ModelCounter;
