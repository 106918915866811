import { makeStyles } from '@material-ui/core/styles';
import React, { useContext, useEffect, useState } from 'react';
import useAsync from '../../../asyncNet';
import { Backdrop, CircularProgress, Typography } from '@material-ui/core';
import { UserRecordToolbar, RecordTable, WithdrawUserTable } from './components';
import { recordGetRecords } from '../APIs/mateApis';
import { userInfoContext } from '../../../App';
import {
  NO_PERMISSION,
  NO_PERMISSION_WARNING,
  NO_WHITELIST_INSIDE_WARNING,
  READ_PERMISSION
} from '../../Common/Config/naviwatchConfig';
import publicIp from 'public-ip';
import { naviwatchCheckWhiteListValidation } from '../../Common/APIs/naviwatchApis';
const STRING_SEARCH_ADMIN = '관리자';
const STRING_SEARCH_USER = '고객';
const STRING_SEARCH_DETAIL_WITHDRAW = '탈퇴';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));


const UserRecord = ({ history }) => {
  const classes = useStyles();
  const [query, setQuery] = useState({
    condition: '',
    detail: '',
    searcherid: '',
    from: '',
    to: '',
  });
  const [recordState, tryGetRecord] = useAsync(()=>callRecordApi(query), [], true);
  const [ipCheckState, tryCheckWhitelist] = useAsync(checkIpValidation, [], true);

  const { loading: recordLoading, data: records } = recordState;
  const { loading: ipCheckLoading, data: ipValid } = ipCheckState;

  const [ipAddress, setIpAddress] = useState(undefined);
  const [canShow, setCanShow] = useState(false);

  const authInfo =  useContext(userInfoContext);
  const [noPermissionMsg, setNoPermissionMsg] = useState("");
  const [permission, setPermission] = useState(NO_PERMISSION);
  useEffect(()=>{
    var pageInfo = sessionStorage.getItem('pages');
    if(pageInfo!=="undefined") {
      var pageInfoObj = JSON.parse(pageInfo);
      var pageArray = pageInfoObj.filter(page => page.name.toLowerCase().includes("search log"));
      if (pageArray !== undefined && pageArray.length > 0) {
        var page = pageArray[0];
        if(page.permission===undefined){
          setNoPermissionMsg(NO_PERMISSION_WARNING);
        }
        setPermission(page.permission);
      }
    }
  }, [authInfo.pages]);

  async function checkIpValidation() {
    return naviwatchCheckWhiteListValidation(ipAddress);
  }

  async function callRecordApi(query){
    var type = "";
    var detail = "";
    if(query.condition===STRING_SEARCH_ADMIN){
      type="admin";
    }else if(query.condition===STRING_SEARCH_USER){
      type="user";
    }

    console.log("detail : "+query.detail);

    if(query.detail === STRING_SEARCH_DETAIL_WITHDRAW){
      detail = "withdraw";
    }
    var result = recordGetRecords(query.searcherid, type, detail, query.from, query.to);
    setCanShow(true);
    return result;
  }

  useEffect(()=>{
    if(ipCheckState.data===false){
      setNoPermissionMsg(NO_WHITELIST_INSIDE_WARNING+`(${ipAddress})`);
    }
  }, [ipCheckState]);

  useEffect(()=>{
    if(ipAddress!==undefined){
      tryCheckWhitelist();
    }
  }, [ipAddress]);


  const searchRecords = (searchdata) => {
    var _from='';
    var _to='';
    if(searchdata.from!==''){
      _from = Date.parse(searchdata.from)
    }
    if(searchdata.to!==''){
      _to = Date.parse(searchdata.to)
    }
    setCanShow(false);

    setQuery(
      {
        condition: searchdata.condition,
        detail: searchdata.detail,
        searcherid : searchdata.searcherid,
        from: _from,
        to: _to,
      });
    console.log('from : ' + _from);
    console.log('to : ' + _to);
    console.log('clicked : ' + JSON.stringify(searchdata));
  };

  const tableShowing = ()=>{
    if(recordLoading===false && records !== null && records !== undefined && canShow === true){
      if(query.condition === STRING_SEARCH_ADMIN){
        return <RecordTable records={records.records}/>;
      }else if(query.condition === STRING_SEARCH_USER){
        return <WithdrawUserTable records={records.records}/>;
      }
    }
  }

  useEffect(() => {
    if ((query.from !=='' && query.to !== '') ||query.searcherid !== '' || query.condition !== '') {
      tryGetRecord();
    }
  }, [query]);

  useEffect(() => {
    if (sessionStorage.getItem('naviwatch_permission') !== 'true')
      history.push('/');
    (async () => {
      setIpAddress(await publicIp.v4());
    })();
  }, []);

  return (
    <div>
      { permission >= READ_PERMISSION  && ipValid===true ?
        <div className={classes.root}>
          <Backdrop className={classes.backdrop} open={recordLoading}>
            <CircularProgress color={'primary'}/>
          </Backdrop>
          <UserRecordToolbar callbackFunc={searchRecords}/>
          <div className={classes.content}>
            {
              tableShowing()
            }
          </div>
        </div>:
        <Typography className={classes.root}>{noPermissionMsg}</Typography>
      }
    </div>
  );
};

export default UserRecord;
