import React, { useState } from 'react';
import {
  Dialog, DialogActions, DialogContent, Button,
  DialogTitle, Divider, Typography, makeStyles,
  Backdrop, CircularProgress
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import { firmwareDelete, getFWType, getS3Path } from '../../../APIs/mateApis';


const STRING_TITLE = '펌웨어 삭제';
const STRING_MAJOR_VERSION = 'MAJOR 버전 :';
const STRING_MINOR_VERSION = 'MINOR 버전 :';
const STRING_BUILD_NUMBER = '빌드 넘버 :';
const STRING_FW_TYPE = 'FW TYPE :';
const STRING_FW_URL = '펌웨어 경로 :';
const STRING_ALERT_FW_DELETE = '펌웨어 삭제 후 복구가 불가능합니다. 유의해주세요';
const STRING_CLOSE = '취소';
const STRING_DELETE = '삭제';


const useStyles = makeStyles(theme => ({
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  linear:{
    display: 'flex',
    alignItems: 'flex-end'
  },
  datas:{
    marginLeft: theme.spacing(2)
  },
  icons:{
    marginLeft: theme.spacing(2),
    padding: theme.spacing(0)
  },
  fwDescription:{
    marginLeft: theme.spacing(2),
    width: '75%'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));

const DeleteFirmwareDialog  = props => {
  const { open, ipAddress, handleClose, refetch, selectedFW } = props;
  const [loading, setLoading] = useState(false);

  const classes = useStyles();

  const handleCloseClick = () =>{
    handleClose();
  };

  const refetchList = () => {
    setLoading(false);
    refetch();
  };

  const handleDelete = () => {
    setLoading(true);
    // AWSMATEIOTPF-3218 파라미터 object 처리
    firmwareDelete(sessionStorage.getItem('email'), ipAddress,
      selectedFW, getS3Path(selectedFW), refetchList);

    handleCloseClick()
  };

  return (
    <div>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color={'primary'}/>
      </Backdrop>
      <Dialog
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{STRING_TITLE}</DialogTitle>
        <DialogContent>
          <Alert severity={'error'}><span style={{ color: 'red' }}>{STRING_ALERT_FW_DELETE}</span></Alert>
          <Divider className={classes.divider}/>
          <div className={classes.linear}>
            <Typography variant="h6">{STRING_MAJOR_VERSION}</Typography>
            <Typography className={classes.datas} variant="h6">{selectedFW.majorVersion}</Typography>
          </div>
          <Divider className={classes.divider}/>
          <div className={classes.linear}>
            <Typography variant="h6">{STRING_MINOR_VERSION}</Typography>
            <Typography className={classes.datas} variant="h6">{selectedFW.minorVersion}</Typography>
          </div>
          <Divider className={classes.divider}/>
          <div className={classes.linear}>
            <Typography variant="h6">{STRING_BUILD_NUMBER}</Typography>
            <Typography className={classes.datas} variant="h6">{selectedFW.buildNumber}</Typography>
          </div>
          <Divider className={classes.divider}/>
          <div className={classes.linear}>
            <Typography variant="h6">{STRING_FW_TYPE}</Typography>
            <Typography className={classes.datas} variant="h6">{getFWType(selectedFW.fwType)}</Typography>
          </div>
          <Divider className={classes.divider}/>
          <div className={classes.linear}>
            <Typography variant="h6">{STRING_FW_URL}</Typography>
            <Typography className={classes.datas} variant="h6">{selectedFW.downloadUrl}</Typography>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseClick} color="primary" autoFocus>
            {STRING_CLOSE}
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            {STRING_DELETE}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

DeleteFirmwareDialog.propTypes = {
  className: PropTypes.string
};

export default DeleteFirmwareDialog;
