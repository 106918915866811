import palette from 'theme/palette';

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  legend: { display: false },
  cornerRadius: 20,
  tooltips: {
    enabled: true,
    mode: 'index',
    intersect: false,
    borderWidth: 1,
    borderColor: palette.divider,
    backgroundColor: palette.white,
    titleFontColor: palette.text.primary,
    bodyFontColor: palette.text.secondary,
    footerFontColor: palette.text.secondary,
    callbacks: {}
  },
  layout: { padding: 0 },
  scales: {
    xAxes: [
      {
        barThickness: 12,
        maxBarThickness: 10,
        barPercentage: 0.5,
        categoryPercentage: 0.5,
        ticks: {
          fontColor: palette.text.secondary
        },
        gridLines: {
          display: false,
          drawBorder: false
        }
      }
    ],
    yAxes: [
      {
        ticks: {
          fontColor: palette.text.secondary
        },
        gridLines: {
          borderDash: [2],
          borderDashOffset: [2],
          color: palette.divider,
          drawBorder: false,
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2],
          zeroLineColor: palette.divider
        }
      }
    ]
  }
};

export const hourCallback = (value, index, ticks) => {
  const backStr = value.split('T')[1];
  return backStr.substr(0, 2);
};

export const dayCallback = (value, index, ticks) => {
  const frontStr = value.split('T')[0];
  return frontStr.substr(5);
};

export const getLavelHourCallback = (t, d) => {
  if (t.datasetIndex === 0) {
    return '세시간 합계: ' + t.yLabel;
  } else if (t.datasetIndex === 1) {
    return '';
  }
};

export const getLavelDayCallback = (t, d) => {
  if (t.datasetIndex === 0) {
    return '일일 합계: ' + t.yLabel;
  } else if (t.datasetIndex === 1) {
    return '';
  }
};
