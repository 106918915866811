import React, { useContext, useEffect, useState } from 'react';
import {
  makeStyles, CardContent, TableHead, TableRow, TableCell, CardActions,
  Card, Table, TableBody, TablePagination, Box, Button, Tooltip,
  IconButton, Typography, Backdrop, CircularProgress
} from '@material-ui/core';

import useAsync from '../../../asyncNet';
import { firmwareFwlList, getModelName, getFWType } from '../APIs/mateApis';
import PerfectScrollbar from 'react-perfect-scrollbar';
import BorderColorIcon from '@material-ui/icons/BorderColor'
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';
import publicIp from 'public-ip';

import GroupAddIcon from '@material-ui/icons/GroupAdd';
import Alert from '@material-ui/lab/Alert';
import {
  AddFirmwareDialog, DeleteFirmwareDialog,
  GroupOTADialog, ModifyFirmwareDialog, DetailInfoDialog, AlertDialog
} from './components';
import {
  NO_PERMISSION,
  NO_PERMISSION_WARNING,
  READ_PERMISSION,
  WRITE_PERMISSION,
  NO_WHITELIST_INSIDE_WARNING
} from '../../Common/Config/naviwatchConfig';

import { userInfoContext } from '../../../App';
import { naviwatchCheckWhiteListValidation } from '../../Common/APIs/naviwatchApis';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  alert:{
    marginBottom: theme.spacing(2)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  backButton: {
    marginRight: theme.spacing(1)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));

const STRING_SW_MAJOR_VERSION = 'MAJOR 버전';
const STRING_SW_MINOR_VERSION = 'MINOR 버전';
const STRING_SW_FIRMWARE_BUILD_NUMBER = '빌드 넘버';
const STRING_SW_FIRMWARE_TYPE = 'FW Type';

const STRING_MODEL_CODE = '모델 코드';
const STRING_FIRMWARE_SIZE = '사이즈';
const STRING_FIRMWARE_URL = '펌웨어 주소';
const STRING_ALERT = '펌웨어 수정, 업로드시 각별한 주의를 부탁드립니다.';
const STRING_ACTIVE_FLAG = 'ACTIVE';
const STRING_FORCED_FLAG = 'FORCE';
const STRING_REGISTER_AT = '등록일';
const STRING_LAST_MODIFY_AT = '수정일';
const STRING_MODIFY = '수정';
const STRING_DETAIL = '상세';
const STRING_GROUP_OTA = 'GROUP OTA';
const STRING_DELETE = '삭제';

const FirmwareList = ({history, match}) => {
  const classes = useStyles();
  const { modelCode } = match.params;
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [state, tryRefetch] = useAsync(()=>firmwareFwlList(modelCode), [], false);
  const { loading, data: firmwares } = state;
  const [addFWDialogOpen, setAddFWDialogOpen] = useState(false);
  const [modifyFWDialogOpen, setModifyFWDialogOpen] = useState(false);
  const [deleteFWDialogOpen, setDeleteFWDialogOpen] = useState(false);
  const [groupOTADialogOpen, setGroupOTADialogOpen] = useState(false);
  const [detailSearchDialogOpen, setDetailSearchDialogOpen] = useState(false);
  const [alertDialogOpen, setAlertDialogOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [ipAddress, setIpAddress] = useState(undefined);
  const [ipCheckState, tryCheckWhitelist] = useAsync(checkIpValidation, [], true);
  const { loading: ipCheckLoading, data: ipValid } = ipCheckState;

  const [selectedFirmware, setSelectedFirmware] = useState({
    majorVersion: 10,
    minorVersion: 0,
    modelCode: 0,
    activeFlag: false,
    forcedFlag: false,
    downloadUrl: "",
    jobId:"",
    fwType: 1,
    fwDescription: "",
    groupOta: false
  });

  const [noPermissionMsg, setNoPermissionMsg] = useState("");
  const authInfo =  useContext(userInfoContext);
  const [permission, setPermission] = useState(NO_PERMISSION);
  useEffect(()=>{
    var pageInfo = sessionStorage.getItem('pages');
    if(pageInfo!=="undefined") {
      var pageInfoObj = JSON.parse(pageInfo);
      var pageArray = pageInfoObj.filter(page => page.name.toLowerCase().includes("firmware"));
      if (pageArray !== undefined && pageArray.length > 0) {
        var page = pageArray[0];
        if(page.permission===undefined){
          setNoPermissionMsg(NO_PERMISSION_WARNING);
        }
        setPermission(page.permission);
      }
    }
  }, [authInfo.pages]);

  useEffect(()=>{
    if(ipCheckState.data===false){
      setNoPermissionMsg(NO_WHITELIST_INSIDE_WARNING+`(${ipAddress})`);
    }
  }, [ipCheckState]);

  async function checkIpValidation() {
    return naviwatchCheckWhiteListValidation(ipAddress);
  }

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const clickBack = () => {
    history.push('/mate-us/firmware');
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  const openAddFirmware = () => {
    setAddFWDialogOpen(true);
  };

  const closeAddFirmware = () => {
    setAddFWDialogOpen(false);
  };

  const openModifyFirmware = () => {
    setModifyFWDialogOpen(true);
  };

  const closeModifyFirmware = () => {
    setModifyFWDialogOpen(false);
  };

  const openDeleteFirmware = () => {
    setDeleteFWDialogOpen(true);
  };

  const closeDeleteFirmware = () => {
    setDeleteFWDialogOpen(false);
  };

  const openGroupOTADialog = () => {
    setGroupOTADialogOpen(true);
  };

  const closeGroupOTADialog = () => {
    setGroupOTADialogOpen(false);
  };

  const detailSearch = (firmware) =>{
    console.log("detailSearch Click :"+JSON.stringify(firmware))
    setSelectedFirmware({
      ...selectedFirmware,
      majorVersion: firmware.FIRMWARE_MAJOR_VERSION,
      minorVersion: firmware.FIRMWARE_MINOR_VERSION,
      modelCode: firmware.MODEL_CODE,
      buildNumber: firmware.FIRMWARE_BUILD_NUMBER,
      activeFlag: firmware.ACTIVE_FLAG !== 0,
      forcedFlag: firmware.FORCED_FLAG !== 0,
      downloadUrl: firmware.FIRMWARE_DOWNLOAD_URL,
      jobId: firmware.FIRMWARE_JOB_ID,
      fwType: firmware.FIRMWARE_TYPE,
      fwDescription: firmware.FIRMWARE_DESCRIPTION,
      groupOta: firmware.GROUP_FLAG !== 0
    });
    openDetailSearch()
  };
  const openDetailSearch = () => {
    setDetailSearchDialogOpen(true);
  };

  const closeDetailSearch = () => {
    setDetailSearchDialogOpen(false);
  };
  const modifyClick = (firmware) =>{
    setSelectedFirmware({
      ...selectedFirmware,
      majorVersion: firmware.FIRMWARE_MAJOR_VERSION,
      minorVersion: firmware.FIRMWARE_MINOR_VERSION,
      modelCode: firmware.MODEL_CODE,
      buildNumber: firmware.FIRMWARE_BUILD_NUMBER,
      activeFlag: firmware.ACTIVE_FLAG !== 0,
      forcedFlag: firmware.FORCED_FLAG !== 0,
      downloadUrl: firmware.FIRMWARE_DOWNLOAD_URL,
      jobId: firmware.FIRMWARE_JOB_ID,
      fwType: firmware.FIRMWARE_TYPE,
      fwDescription: firmware.FIRMWARE_DESCRIPTION,
      groupOta: firmware.GROUP_FLAG !== 0
    });
    console.log("firmware.FIRMWARE_GROUP_FLAG : "+(firmware.FIRMWARE_GROUP_FLAG))

    console.log("groupOTA : "+(firmware.FIRMWARE_GROUP_FLAG !== 0))
    openModifyFirmware();
  };

  const deleteClick = (firmware) =>{
    setSelectedFirmware({
      ...selectedFirmware,
      majorVersion: firmware.FIRMWARE_MAJOR_VERSION,
      minorVersion: firmware.FIRMWARE_MINOR_VERSION,
      modelCode: firmware.MODEL_CODE,
      buildNumber: firmware.FIRMWARE_BUILD_NUMBER,
      activeFlag: firmware.ACTIVE_FLAG !== 0,
      forcedFlag: firmware.FORCED_FLAG !== 0,
      downloadUrl: firmware.FIRMWARE_DOWNLOAD_URL,
      jobId: firmware.FIRMWARE_JOB_ID,
      fwType: firmware.FIRMWARE_TYPE,
      fwDescription: firmware.FIRMWARE_DESCRIPTION,
      groupOta: firmware.GROUP_FLAG !== 0
    });
    openDeleteFirmware()
  };

  const groupClick = (firmware) =>{
    setSelectedFirmware({
      ...selectedFirmware,
      majorVersion: firmware.FIRMWARE_MAJOR_VERSION,
      minorVersion: firmware.FIRMWARE_MINOR_VERSION,
      modelCode: firmware.MODEL_CODE,
      buildNumber: firmware.FIRMWARE_BUILD_NUMBER,
      activeFlag: firmware.ACTIVE_FLAG !== 0,
      forcedFlag: firmware.FORCED_FLAG !== 0,
      downloadUrl: firmware.FIRMWARE_DOWNLOAD_URL,
      jobId: firmware.FIRMWARE_JOB_ID,
      fwType: firmware.FIRMWARE_TYPE,
      fwDescription: firmware.FIRMWARE_DESCRIPTION,
      groupOta: firmware.GROUP_FLAG
    });
    openGroupOTADialog()
  };

  const refetchWithDialog = (message) =>{
    setAlertMessage(message)
    setAlertDialogOpen(true);
    tryRefetch();
  };

  const closeAlertDialog = () =>{
    setAlertDialogOpen(false);
  };

  useEffect(()=>{
    if(ipAddress!==undefined){
      tryCheckWhitelist();
    }
  }, [ipAddress]);

  useEffect(() => {
    if (sessionStorage.getItem('naviwatch_permission') !== 'true')
      history.push('/');
    (async () => {
      setIpAddress(await publicIp.v4());
    })();
  }, []);

  return (
    <div>
      {permission>=READ_PERMISSION && ipValid===true?
        <div className={classes.root}>
          <Backdrop className={classes.backdrop} open={ipCheckLoading}>
            <CircularProgress color={'primary'}/>
          </Backdrop>
          <Alert className={classes.alert} severity={'error'}><span style={{ color: 'red' }}>{STRING_ALERT}</span></Alert>
          <Card >
            <CardContent className={classes.content}>
              <Typography variant={'h3'}>{getModelName(modelCode)}</Typography>
              <PerfectScrollbar>
                <div className={classes.inner}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align={'center'}><span><b>{STRING_SW_MAJOR_VERSION}</b></span></TableCell>
                        <TableCell align={'center'}><span><b>{STRING_SW_MINOR_VERSION}</b></span></TableCell>
                        <TableCell align={'center'}><span><b>{STRING_SW_FIRMWARE_BUILD_NUMBER}</b></span></TableCell>
                        <TableCell align={'center'}><span><b>{STRING_SW_FIRMWARE_TYPE}</b></span></TableCell>
                        <TableCell align={'center'}><span><b>{STRING_FIRMWARE_URL}</b></span></TableCell>
                        <TableCell align={'center'}><span><b>{STRING_ACTIVE_FLAG}</b></span></TableCell>
                        <TableCell align={'center'}><span><b>{STRING_FORCED_FLAG}</b></span></TableCell>
                        <TableCell align={'center'}><span><b>{STRING_GROUP_OTA}</b></span></TableCell>
                        <TableCell align={'center'}><span><b>{STRING_MODIFY}</b></span></TableCell>
                        <TableCell align={'center'}><span><b>{STRING_DETAIL}</b></span></TableCell>
                        <TableCell align={'center'}><span><b>{STRING_REGISTER_AT}</b></span></TableCell>
                        <TableCell align={'center'}><span><b>{STRING_LAST_MODIFY_AT}</b></span></TableCell>
                        <TableCell align={'center'}><span><b>{STRING_DELETE}</b></span></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {firmwares !== null && firmwares !== undefined && firmwares.fwList!==undefined &&
                      firmwares.fwList.slice((page * rowsPerPage), ((page + 1) * rowsPerPage)).map(firmware => (
                        <TableRow
                          className={classes.tableRow}
                          hover
                          key={firmware.FIRMWARE_JOB_ID}
                        >
                          <TableCell align={'center'}>
                            {firmware.FIRMWARE_MAJOR_VERSION}
                          </TableCell>
                          <TableCell align={'center'}>
                            {firmware.FIRMWARE_MINOR_VERSION}
                          </TableCell>
                          <TableCell align={'center'}>
                            {firmware.FIRMWARE_BUILD_NUMBER}
                          </TableCell>
                          <TableCell align={'center'}>
                            {getFWType(firmware.FIRMWARE_TYPE)}
                          </TableCell>
                          <TableCell>
                            {firmware.FIRMWARE_DOWNLOAD_URL}
                          </TableCell>
                          <TableCell align={'center'}>
                            {firmware.ACTIVE_FLAG}
                          </TableCell>
                          <TableCell align={'center'}>
                            {firmware.FORCED_FLAG}
                          </TableCell>
                          <TableCell align={'center'}>
                            <Tooltip title={STRING_GROUP_OTA}>
                              <IconButton aria-label={STRING_GROUP_OTA}
                                          onClick={() => groupClick(firmware)}
                                          disabled={firmware.GROUP_FLAG !== 1 || permission<WRITE_PERMISSION}
                              >
                                <GroupAddIcon />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                          <TableCell align={'center'}>
                            <Tooltip title={STRING_MODIFY}>
                              <IconButton aria-label={STRING_MODIFY}
                                          disabled={permission<WRITE_PERMISSION}
                                          onClick={() => modifyClick(firmware)}>
                                <BorderColorIcon />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                          <TableCell align={'center'}>
                            <Tooltip title={STRING_DETAIL}>
                              <IconButton aria-label={STRING_DETAIL}
                                          onClick={() => detailSearch(firmware)}
                              >
                                <SearchIcon />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                          <TableCell align={'center'}>
                            {firmware.REGISTER_AT}
                          </TableCell>
                          <TableCell align={'center'}>
                            {firmware.LAST_MODIFY_AT}
                          </TableCell>
                          <TableCell align={'center'}>
                            <Tooltip title={STRING_DELETE}>
                              <IconButton aria-label={STRING_DELETE}
                                          disabled={permission<WRITE_PERMISSION}
                                          onClick={() => deleteClick(firmware)}>
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </PerfectScrollbar>
            </CardContent>
            <CardActions className={classes.actions}>
              <TablePagination
                component="div"
                count={firmwares !== null && firmwares !== undefined && firmwares.fwList.length}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handleRowsPerPageChange}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
              />
              <Box m={3}
                   display="flex"
                   justifyContent="flex-end"
              >
                <Button className={classes.backButton}
                        onClick={clickBack}>
                  Back
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={openAddFirmware}
                  disabled={permission<WRITE_PERMISSION}
                >
                  Add firmware
                </Button>
              </Box>
            </CardActions>
          </Card>
          <AddFirmwareDialog open={addFWDialogOpen} ipAddress = {ipAddress} handleClose={closeAddFirmware} refetch={refetchWithDialog} modelCode={modelCode}/>
          <ModifyFirmwareDialog open={modifyFWDialogOpen} ipAddress = {ipAddress} handleClose={closeModifyFirmware} refetch={refetchWithDialog} selectedFW={selectedFirmware}/>
          <DeleteFirmwareDialog open={deleteFWDialogOpen} ipAddress = {ipAddress} handleClose={closeDeleteFirmware} refetch={tryRefetch} selectedFW={selectedFirmware}/>
          <GroupOTADialog open={groupOTADialogOpen} ipAddress = {ipAddress} handleClose={closeGroupOTADialog} refetch={refetchWithDialog} selectedFW={selectedFirmware}/>
          <DetailInfoDialog open={detailSearchDialogOpen} handleClose={closeDetailSearch} refetch={tryRefetch} selectedFW={selectedFirmware}/>
          <AlertDialog open={alertDialogOpen} handleClose={closeAlertDialog} message={alertMessage}/>
        </div>:
        <Typography className={classes.root}>{noPermissionMsg}</Typography>
      }
    </div>
  );
};

export default FirmwareList;
