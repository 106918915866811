import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Avatar,
  Divider,
  Button,
  CardActions
} from '@material-ui/core';
import useAsync from '../../../../../asyncNet';
import PhonelinkRingIcon from '@material-ui/icons/PhonelinkRing';
import PersonIcon from '@material-ui/icons/Person';
import MemoryIcon from '@material-ui/icons/Memory';
import SelectAllIcon from '@material-ui/icons/SelectAll';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import RefreshIcon from '@material-ui/icons/Refresh';
import {
  dashboardGetDeviceStatistics,
  dashboardGetMobileStatistics,
  dashboardGetUserStatistics,
  dashboardGetAirmonitorStatistics
} from '../../../APIs/aironeApis';
const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  container: {
    paddingBottom: 8
  },
  content: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    width: '100%',
    height:'100%'
  },
  title: {
    fontWeight: 700
  },
  mobileAvatar: {
    backgroundColor: theme.palette.success.main,
    height: 56,
    width: 56
  },
  deviceAvatar: {
    backgroundColor: theme.palette.error.main,
    height: 56,
    width: 56
  },
  airMonitorAvatar: {
    backgroundColor: theme.palette.warning.main,
    height: 56,
    width: 56
  },
  userAvatar: {
    backgroundColor: theme.palette.info.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  fabProgress: {
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  mobileDifferenceIcon: {
    color: theme.palette.success.dark
  },
  mobileDifferenceValue: {
    color: theme.palette.success.dark,
    marginRight: theme.spacing(1)
  },
  mobileRoot:{
    color: theme.palette.success.light,
  },
  deviceDifferenceIcon: {
    color: theme.palette.error.dark
  },
  deviceDifferenceValue: {
    color: theme.palette.error.dark,
    marginRight: theme.spacing(1)
  },
  deviceRoot:{
    color: theme.palette.error.light,
  },
  userDifferenceIcon: {
    color: theme.palette.info.dark
  },
  userDifferenceValue: {
    color: theme.palette.info.dark,
    marginRight: theme.spacing(1)
  },
  userRoot:{
    color: theme.palette.info.light,
  },
  actions: {
    justifyContent: 'space-between',
    padding: 0,
    marginTop: 8,
    height: 24
  }
}));

const STRING_TITLE_MOBILE = '실시간 앱 사용자';
const STRING_TITLE_ROOMCON = '룸콘';
const STRING_TITLE_AIRMONITOR = '에어모니터';
const STRING_TITLE_USER = '사용자';
const STRING_TITLE_USER_DAY = '금일 사용자';
const STRING_TITLE_USER_WEEK = '금주 사용자';
const STRING_TITLE_ALL='전체';
const STRING_TITLE_ONLINE='온라인';
const STRING_TITLE_OFFLINE='오프라인';
const STRING_TITLE_AM_CONNECT='연결';
const STRING_TITLE_AM_DiSCON='미연결';
const STRING_TITLE_MOBILE_IOS='iOS';
const STRING_TITLE_MOBILE_ANDROID='Android';
const STRING_ALLREGION = '상세 디바이스 보기';

const RealtimeIcon = props => {
  //console.log('type : '+JSON.stringify(type));
  if (props.type === 'mobile')
    return (<PhonelinkRingIcon className={props.classname}/>);
  else if (props.type === 'roomcon')
    return (<MemoryIcon className={props.classname}/>);
  else if (props.type === 'user')
    return (<PersonIcon className={props.classname}/>);
  else if (props.type === 'airmonitor')
    return (<SelectAllIcon className={props.classname}/> );
  else
    return null;
};
const getRealNumber = (param)=>{
  if(param === null || param === undefined){
    return 0;
  } else{
    return param;
  }
};
const RealtimeCounter = props => {
  const { type, dialogCallback } = props;

  const getCountFunction = () => {
    if (type === 'mobile') {
      return dashboardGetMobileStatistics();
    } else if (type === 'roomcon') {
      return dashboardGetDeviceStatistics();
    } else if (type === 'airmonitor') {
      return dashboardGetAirmonitorStatistics();
    } else if (type === 'user') {
      return dashboardGetUserStatistics();
    }
  };
  const [state, tryRefetch] = useAsync(getCountFunction, []);
  const { data: count } = state;
  const [firstFieldCountValue, setFirstFieldCountValue] = useState(0);
  const [secondFieldCountValue, setSecondFieldCountValue] = useState(0);
  const [thirdFieldCountValue, setThirdFieldCountValue] = useState(0);
  const classes = useStyles();

  var counterTitle;
  var firstTitle;
  var secondTitle;
  var thirdTitle;
  var classesAvatar;

  if (type === 'mobile') {
    counterTitle = STRING_TITLE_MOBILE;
    firstTitle = STRING_TITLE_ALL;
    secondTitle = STRING_TITLE_MOBILE_IOS;
    thirdTitle = STRING_TITLE_MOBILE_ANDROID;
    classesAvatar = classes.mobileAvatar;
  } else if (type === 'roomcon') {
    counterTitle = STRING_TITLE_ROOMCON;
    firstTitle = STRING_TITLE_ALL;
    secondTitle = STRING_TITLE_ONLINE;
    thirdTitle = STRING_TITLE_OFFLINE;
    classesAvatar = classes.deviceAvatar;
  } else if (type === 'airmonitor') {
    counterTitle = STRING_TITLE_AIRMONITOR;
    firstTitle = STRING_TITLE_ALL;
    secondTitle = STRING_TITLE_AM_CONNECT;
    thirdTitle = STRING_TITLE_AM_DiSCON;
    classesAvatar = classes.airMonitorAvatar;
  } else if (type === 'user') {
    counterTitle = STRING_TITLE_USER;
    firstTitle = STRING_TITLE_ALL;
    secondTitle = STRING_TITLE_USER_DAY;
    thirdTitle = STRING_TITLE_USER_WEEK;
    classesAvatar = classes.userAvatar;
  }


  useEffect(() => {
    try {
      if (count !== null) {
        if (type === 'mobile' && count['totalCount'] !== undefined) {
          setFirstFieldCountValue(getRealNumber(count['totalCount']));
          setSecondFieldCountValue(getRealNumber(count['iOSCount']));
          setThirdFieldCountValue(getRealNumber(count['androidCount']));
        } else if (type === 'roomcon' && count['totalCount'] !== undefined) {
          setFirstFieldCountValue(getRealNumber(count['totalCount']));
          setSecondFieldCountValue(getRealNumber(count['connectCount']));
          setThirdFieldCountValue(getRealNumber(count['disconnectCount']));
        } else if (type === 'airmonitor' && count['totalCount'] !== undefined) {
          setFirstFieldCountValue(getRealNumber(count['totalCount']));
          setSecondFieldCountValue(getRealNumber(count['connectCount']));
          setThirdFieldCountValue(getRealNumber(count['disconnectCount']));
        } else if (type === 'user' && count['totalCount'] !== undefined) {
          setFirstFieldCountValue(getRealNumber(count['totalCount']));
          setSecondFieldCountValue(getRealNumber(count['connectDayCount']));
          setThirdFieldCountValue(getRealNumber(count['connectWeekCount']));
        }
      }
    } catch (e) {
      console.log('get count failed: ' + JSON.stringify(e));
    }

  }, [count]);

  useEffect(() => {
    tryRefetch();
  }, []);

  return (
    <Card>
      <CardContent>
        <Grid
          container
          justifyContent="space-around"
        >
          <Grid item>
            <div className={classes.content}>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
                variant="body2"
              >
                {counterTitle}
              </Typography>
              <Avatar className={classesAvatar}>
                <RealtimeIcon type={type} classname={classes.icon}/>
              </Avatar>
            </div>
          </Grid>
          <Grid item>
            <div className={classes.content}>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
                variant="body2"
              >
                {firstTitle}
              </Typography>
              <Typography
                className={classes.caption}
                variant="h3"
              >
                {firstFieldCountValue}
              </Typography>
            </div>
          </Grid>
          <Grid item>
            <div className={classes.content}>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
                variant="body2"
              >
                {secondTitle}
              </Typography>
              <Typography
                className={classes.caption}
                variant="h3"
              >
                {secondFieldCountValue}
              </Typography>
            </div>
          </Grid>
          <Grid item>
            <div className={classes.content}>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
                variant="body2"
              >
                {thirdTitle}
              </Typography>
              <Typography
                className={classes.caption}
                variant="h3"
              >
                {thirdFieldCountValue}
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Divider />
        <CardActions className={classes.actions}>
          <Button
            color="primary"
            size="small"
            variant="text"
            onClick={() => {
              tryRefetch();
            }}>
            <RefreshIcon />
          </Button>
          {type == 'roomcon' && (
            <Button
              color="primary"
              size="small"
              variant="text"
              onClick={() => {
                dialogCallback(count);
              }}>
              {STRING_ALLREGION} <ArrowRightIcon />
            </Button>
          )}
        </CardActions>
      </CardContent>
    </Card>
  );
};

RealtimeCounter.propTypes = {
  className: PropTypes.string
};

export default RealtimeCounter;
