import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { PushToolbar, PushDialog } from './components';
import useAsync from '../../../asyncNet';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { pushSend } from '../APIs/smartApis';
import { NO_PERMISSION, NO_PERMISSION_WARNING, READ_PERMISSION } from '../../Common/Config/naviwatchConfig';
import { Typography } from '@material-ui/core';
import { userInfoContext } from '../../../App';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  }
}));

const STRING_SUCCESS_PUSH_TITLE='공지사항이 등록되었습니다';
const STRING_SUCCESS_PUSH_CONTENT='';
const STRING_FAIL_PUSH_TITLE='공지사항 등록이 실패하였습니다';
const STRING_FAIL_PUSH_CONTENT='';


const Push = ({history}) => {
  const classes = useStyles();

  useEffect(() => {
    if (sessionStorage.getItem('naviwatch_permission') !== 'true')
      history.push('/');
  }, []);

  const authInfo =  useContext(userInfoContext);
  const [noPermissionMsg, setNoPermissionMsg] = useState("");
  const [permission, setPermission] = useState(NO_PERMISSION);
  useEffect(()=>{
    var pageInfo = sessionStorage.getItem('pages');
    if(pageInfo!=="undefined") {
      var pageInfoObj = JSON.parse(pageInfo);
      var pageArray = pageInfoObj.filter(page => page.name.toLowerCase().includes("push"));
      if (pageArray !== undefined && pageArray.length > 0) {
        var page = pageArray[0];
        if(page.permission===undefined){
          setNoPermissionMsg(NO_PERMISSION_WARNING);
        }
        setPermission(page.permission);
      }
    }
  }, [authInfo.pages]);


  const [message, setMessage] = useState("");
  const [dialogString, setDialogString] = useState("");

  const getPushData = (data) =>{
    setMessage(data);
  };
  useEffect(() => {
    if (message !== "")
      tryRefetch();
  }, [message]);


  const [state, tryRefetch] = useAsync(sendPush, [], true);
  const { loading } = state;

  async function sendPush() {
    console.log(`push::call sendPush API: ${JSON.stringify(message)}`);
    setDialogString({
      title: STRING_FAIL_PUSH_TITLE,
      content: STRING_FAIL_PUSH_CONTENT
    });

    try {
      const response = await pushSend(message);
      console.log(`push::call sendPush response: ${JSON.stringify(response)}`);
      if (response.code===200){
        setDialogString({
          title: STRING_SUCCESS_PUSH_TITLE,
          content: STRING_SUCCESS_PUSH_CONTENT
        });
      }
      return response;
    } catch (error) {
      console.log(`push::call sendPush err: ${error}`);
    } finally{
      handleClickOpen();
    }
  };


  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    console.log(`push::handleClickOpen: ${JSON.stringify(dialogString)}`);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setMessage("");
  };


  return (
    <div>
      {permission>=READ_PERMISSION?
        <div className={classes.root}>
          <Backdrop className={classes.backdrop} open={loading} >
            <CircularProgress color={'primary'}/>
          </Backdrop>
          <PushToolbar callbackFunc={getPushData}/>
          <PushDialog open={open} handleClose={handleClose} dialogString={dialogString}/>
        </div>:
        <Typography className={classes.root}>{noPermissionMsg}</Typography>
      }
    </div>
  );
};

export default Push;
