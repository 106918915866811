import React from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  CardContent,
  TableHead,
  TableRow,
  TableCell,
  CardActions,
  Card,
  Table,
  TableBody,
  Tooltip,
  IconButton
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));

const LogLevelList = props => {
  LogLevelList.propTypes = {
    logLevelList: PropTypes.array,
    syncLogLevelList: PropTypes.func
  };

  const classes = useStyles();

  return (
    <Card>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align={'center'}>
                    <span>
                      <b>{'Instance Private IP'}</b>
                    </span>
                  </TableCell>
                  <TableCell align={'center'}>
                    <span>
                      <b>{'Current Log level'}</b>
                    </span>
                  </TableCell>
                  <TableCell align={'center'}>
                    <span>
                      <b>{'마지막 동기화 시간'}</b>
                    </span>
                  </TableCell>
                  <TableCell align={'center'}>
                    <span>
                      <b>{'DESCRIPTION'}</b>
                    </span>
                  </TableCell>
                  <TableCell align={'center'}>
                    <span>
                      <b>{'동기화'}</b>
                    </span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.logLevelList.map(info => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={info.privateIp}>
                    <TableCell align={'center'}>{info.privateIp}</TableCell>
                    <TableCell align={'center'} style={{ fontWeight: 'bold' }}>
                      {info.currentLogLevel}
                    </TableCell>
                    <TableCell align={'center'}>{info.syncTime}</TableCell>
                    <TableCell align={'center'}>{info.description}</TableCell>
                    <TableCell align={'center'}>
                      <Tooltip title={'로그 레벨 변경'}>
                        <IconButton
                          aria-label={'로그 레벨 변경'}
                          // disabled={permission < WRITE_PERMISSION}
                          onClick={() => props.syncLogLevelList()}>
                          <SettingsBackupRestoreIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}></CardActions>
    </Card>
  );
};

export default LogLevelList;
