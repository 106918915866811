/* eslint-disable linebreak-style */
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AWS from 'aws-sdk';

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  Input,
  FormGroup,
  FormControlLabel,
  Switch,
  Select,
  MenuItem,
  InputLabel,
  FormControl,//
  TableHead, TableRow, TableCell, Table, TableBody, Box, Typography, Checkbox, IconButton, Tooltip, TablePagination

} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';

import BorderColorIcon from '@material-ui/icons/BorderColor'
import DeleteIcon from '@material-ui/icons/Delete';

import { NO_PERMISSION, WRITE_PERMISSION } from '../../Common/Config/naviwatchConfig';
import { getSts } from 'views/Smart/APIs/smartApis';
import { deploy } from 'config';
import { AddAnnouncementDialog } from './components/Dialog';
import { DeleteAnnouncementDialog } from './components/Dialog';
import { ModifyAnnouncementDialog } from './components/Dialog';
import { searchAnnouncement } from 'views/Smart/APIs/smartApis';
import useAsync from 'asyncNet/useAsync';
// import {} from ../APIs/smartApis


const useStyles = makeStyles(() => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  },
  input:{
    width: '90%'
  }
}));

const STRING_SEARCH = '검색';
const STRING_NOTICODE_LABEL = '공지사항 종류';

const STRING_NOTI_SEND = '등록';
const STRING_TITLE = '공지사항'; 
const STRING_INFOLIST = '공지사항 리스트';
const STRING_REGIST = '공지사항 등록';
const STRING_SUBTITLE = '파일선택 후 업로드버튼을 눌러주세요';
const STRING_NOTI_TITLE = '제목';
const STRING_NOTI_CONTENTS = '내용';
const STRING_UPLOAD = '업로드'
const STRING_LINK = '링크';
const STRING_PUSH_CHECKBOX = '푸시전송';
const STRING_CONDITION_CLEAN = '초기화';
const STRING_NOTI_TYPE = '공지 타입';
// const STRING_MODIFY = '수정';

const STRING_CHECKBOX = '체크박스';
const STRING_ANNOUNCEMENT = '종류';
const STRING_ANNOUNCEMENT_TITLE = '제목';
const STRING_ANNOUNCEMENT_MESSAGE = '내용';
const STRING_ANNOUNCEMENT_IMAGE_URL = 'Image url';
// const STRING_ANNOUNCEMENT_NOTICODE = '공지사항 noticode';
const STRING_ANNOUNCEMENT_REGISTER_AT = '등록일';
const STRING_ANNOUNCEMENT_LAST_MODIFY_AT = '수정일';
const STRING_MODIFY = '수정';
const STRING_DELETE = '삭제';


const NOTI_OPTIONS = [
  { value: "3100", label: "서버점검사전안내" },
  { value: "3200", label: "APP업데이트안내" },
  { value: "3300", label: "개인정보변경동의" },
  { value: "3400", label: "설문조사 이벤트/광고" },
];

const REGION ='ap-northeast-2';
const S3_BUCKET = {
  dev: 'nskr-dev-naviwatch',
  stage: 'nskr-stage-naviwatch',
  prod: 'nskr-prod-naviwatch',
}

  const Announcement = ({history, className}) => {
  const { callbackFunc, ...rest } = ({history});
  const classes = useStyles();
  useEffect(() => {
    if (sessionStorage.getItem('naviwatch_permission') !== 'true')
      history.push('/');
  }, []);

  
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)
  const [state, tryRefetch] = useAsync(()=>searchAnnouncement(0,'2023-03-01T00:00','2099-12-15T00:00'),[],false) // 연월일시분
  const { loading, data: Annoucements} = state
  console.log("Annoucements :"+JSON.stringify(Annoucements))

  const [addAnnouncementDialogOpen, setAddAnnouncementDialogOpen] = useState(false);
  const [modifyAnnouncementDialogOpen, setModifyAnnouncementDialogOpen] = useState(false);
  const [deleteAnnouncementDialogOpen, setDeleteAnnouncementDialogOpen] = useState(false);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [noticode, setNoticode] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [pushSwitch, setPushSwitch] = useState(false);

  const [registerAt, setregisterAt] = useState("")
  console.log('registerAt' + registerAt)

  const [content, setContent] = useState("")
  console.log('content' + content)
  
  const [alertMessage, setAlertMessage] = useState("");
  const [alertDialogOpen, setAlertDialogOpen] = useState(false);

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };
  
  
  const [values, setValues] = useState({
    title : '',
    content : '',
    link : ''
  });
  const [permission, setPermission] = useState(NO_PERMISSION);
  const [deleteflag, setDeleteFlag] = useState(false); // check box 관리

  useEffect(()=>{
    var pageInfo = sessionStorage.getItem('pages');
    var pageInfoObj = JSON.parse(pageInfo);
    var pageArray = pageInfoObj.filter(page=>page.name.toLowerCase().includes("announcement"));
    if(pageArray!==undefined && pageArray.length > 0) {
      var page = pageArray[0];
      setPermission(page.permission);
    }
    loadCredentials();
    setDeleteFlag(deleteflag);
  }, []);

  const handleDeleteFlag = () =>{
    if(deleteflag){
      setDeleteFlag(false);
    }else{
      setDeleteFlag(true);
    }
  };

  const handleSearch = () => {
    console.log('noticode'+(noticode))
    console.log('fromDate'+(fromDate))
    console.log('toDate'+(toDate))
    if (noticode !== '' && fromDate !== '' && toDate !== '') {
      fetchReport();
    }
    console.log('fetchReport '+ fetchReport)
    console.log('handleSearch searchNoticode'+JSON.stringify(searchNoticode))
  };
  
  const openAddAnnoucement = () => {
    setAddAnnouncementDialogOpen(true);
  };

  const closeAddAnnoucement = () => {
    setAddAnnouncementDialogOpen(false);
  };

  const openModifyRegisterAt = (registerTime) =>{
    setregisterAt(registerTime)
  }

  const closeModifyRegisterAt = () =>{
    setregisterAt("")
  }

  const openDeleteRegisterAt = (registerTime) =>{
    setregisterAt(registerTime)
  }

  const closeDeleteRegisterAt = () =>{
    setregisterAt("")
  }

  const openDeleteContent = (content) =>{
    setContent(content)
  }

  const closeDeleteContent = () =>{
    setContent("")
  }

  const openModifyAnnoucement = () => {
    setModifyAnnouncementDialogOpen(true);
  };

  const closeModifyAnnoucement = () => {
    setModifyAnnouncementDialogOpen(false);
  };

  const openDeleteAnnoucement = () => {
    setDeleteAnnouncementDialogOpen(true);
  };

  const closeDeleteAnnoucement = () => {
    setDeleteAnnouncementDialogOpen(false);
  };

  const refetchWithDialog = (message) =>{
    setAlertMessage(message)
    setAlertDialogOpen(true);
    tryRefetch();
  };

  const closeAlertDialog = () =>{
    setAlertDialogOpen(false);
  };



  const loadCredentials = async() => {
    try {
      const data = await getSts();
      AWS.config.update(data);
    } catch (error) {
      console.log(`PushToolbar::loadCredentials error = ${JSON.stringify(error)}`);
    }
  }

  const cleanInput = () =>{
    setValues({
      title: '',
      content: '',
      link : '',
    });
    setNoticode("");
    setSelectedFile(null);
    setImageUrl(null);
    document.getElementById('uploadInput').value = '';
    setPushSwitch(false);
  };

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const [searchNoticode, fetchReport] = useAsync(() => { 
    // setLoading(true);
    
    console.log('Notificationcode'+JSON.stringify(noticode))
    console.log('NOTI_OPTIONS.value'+JSON.stringify(NOTI_OPTIONS.value))
    console.log('fromDate'+(fromDate))
    console.log('toDate'+(toDate))
    switch(noticode){ //NOTI_OPTIONS.value
      case "3100":
        console.log('case 3100')
        return searchAnnouncement(noticode, fromDate, toDate);
      case "3200":
        console.log('case 3200')
        return searchAnnouncement(noticode, fromDate, toDate);
      case "3300":
        console.log('case 3300')
        return searchAnnouncement(noticode, fromDate, toDate);
      case "3400":
        console.log('case 3400')
        return searchAnnouncement(noticode, fromDate, toDate);
      default:
        console.log('case default')
        return searchAnnouncement(0, fromDate, toDate);
    }
  }, [], true);

  const { load, data: NotiAnnoucements} = searchNoticode

  const handleChangeNoticode = (event) => {
    setNoticode(event.target.value);
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader 
        title={STRING_TITLE}
      />
      <Divider/>
      <CardContent>
        <PerfectScrollbar>
        <Grid
          container
          spacing={3}
        >
        
        <Grid container spacing={3}>
        <Grid item md={6} xs={6} >
            <FormControl  fullWidth>
              <InputLabel id="select-helper-label">{STRING_NOTICODE_LABEL}</InputLabel>
              <Select labelId="select-helper-label" value={noticode} onChange={handleChangeNoticode}>
                {NOTI_OPTIONS.map((option) => (
                  <MenuItem  key={option.value} value={option.value}> {option.label} </MenuItem >
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={2} xs={4} spacing={3}>
            <form className={classes.container} noValidate>
              <TextField
                id="from"
                name="from"
                label="From"
                type="datetime-local"
                className={classes.textField}
                value={'' || fromDate}
                onChange={e => setFromDate(e.target.value)}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </form>
          </Grid>
          <Grid item md={2} xs={4} spacing={3}>
            <form className={classes.container} noValidate>
              <TextField
                id="to"
                name="to"
                label="To"
                type="datetime-local"
                className={classes.textField}
                value={'' || toDate}
                onChange={e => setToDate(e.target.value)}
                // disabled={values.condition === ''}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </form>
          </Grid>
          <Grid item md={2} xs={4} spacing={3}>
            <CardActions className={classes.actions}>
              <Button 
                color="primary"
                variant="contained"
                onClick={handleSearch}
                disabled={noticode === '' || !fromDate || !toDate}>
                {STRING_SEARCH}
              </Button>
            </CardActions>
          </Grid>
        </Grid>
        < Grid item md={12} xs={12}>
          <CardHeader
            title={STRING_INFOLIST} // 공지사항 리스트
          />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align={'center'}><span><b>{STRING_ANNOUNCEMENT}</b></span></TableCell>
                <TableCell align={'center'}><span><b>{STRING_ANNOUNCEMENT_TITLE}</b></span></TableCell>
                <TableCell align={'center'}><span><b>{STRING_ANNOUNCEMENT_MESSAGE}</b></span></TableCell>
                <TableCell align={'center'}><span><b>{STRING_ANNOUNCEMENT_IMAGE_URL}</b></span></TableCell>
                <TableCell align={'center'}><span><b>{STRING_ANNOUNCEMENT_REGISTER_AT}</b></span></TableCell>
                <TableCell align={'center'}><span><b>{STRING_ANNOUNCEMENT_LAST_MODIFY_AT}</b></span></TableCell>
                <TableCell align={'center'}><span><b>{STRING_MODIFY}</b></span></TableCell>
                <TableCell align={'center'}><span><b>{STRING_DELETE}</b></span></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Annoucements !== null && Annoucements !== undefined && NotiAnnoucements === null &&
                Annoucements.data.data.slice((page * rowsPerPage), ((page + 1) * rowsPerPage)).map(announcement => (
              <TableRow
                  className={classes.tableRow}
                  hover
                  key={announcement.notification.contents.message}
                >
                   
                  <TableCell align={'center'}>
                    {announcement.notification.notiType}
                  </TableCell>
                  <TableCell align={'center'}>
                    {announcement.notification.contents.parameters.template.title}
                  </TableCell>
                  <TableCell align={'center'}>
                    {announcement.notification.contents.message}
                  </TableCell>
                  <TableCell>
                  {announcement.notification.contents.parameters.template.imageUrl}
                  </TableCell>
                  <TableCell>
                  {announcement.notification.registerAt}
                  </TableCell>
                  <TableCell align={'center'}>
                  {announcement.notification.registerAt}
                  </TableCell>
                  <TableCell align={'center'}>
                      <Tooltip title={STRING_MODIFY}>
                        <IconButton aria-label={STRING_MODIFY}
                                    disabled={permission<WRITE_PERMISSION}
                                    onClick={() => {
                                      openModifyRegisterAt(announcement.notification.registerAt)
                                      openModifyAnnoucement()
                                    }}
                                    >
                          <BorderColorIcon />
                        </IconButton>
                      </Tooltip>
                  </TableCell>

                  <TableCell align={'center'}>
                      <Tooltip title={STRING_DELETE}>
                        <IconButton aria-label={STRING_DELETE}
                                    disabled={permission<WRITE_PERMISSION}
                                    onClick={() => {
                                      openDeleteRegisterAt(announcement.notification.registerAt)
                                      openDeleteContent(announcement.notification.contents.message)
                                      openDeleteAnnoucement()
                                    }}
                                    >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                  </TableCell>
                
              </TableRow>
                ))}
            </TableBody>
              
            <TableBody>
              {NotiAnnoucements !== null && NotiAnnoucements !== undefined && searchNoticode !== undefined &&
                NotiAnnoucements.data.data.slice((page * rowsPerPage), ((page + 1) * rowsPerPage)).map(announcement => (
              <TableRow
                  className={classes.tableRow}
                  hover
                  key={announcement.notification.contents.message}
                >
                   
                  <TableCell align={'center'}>
                    {announcement.notification.notiType}
                  </TableCell>
                  <TableCell align={'center'}>
                    {announcement.notification.contents.parameters.template.title}
                  </TableCell>
                  <TableCell align={'center'}>
                    {announcement.notification.contents.message}
                  </TableCell>
                  <TableCell>
                  {announcement.notification.contents.parameters.template.imageUrl}
                  </TableCell>
                  <TableCell>
                  {announcement.notification.registerAt}
                  </TableCell>
                  <TableCell align={'center'}>
                  {announcement.notification.registerAt}
                  </TableCell>
                  <TableCell align={'center'}>
                      <Tooltip title={STRING_MODIFY}>
                        <IconButton aria-label={STRING_MODIFY}
                                    disabled={permission<WRITE_PERMISSION}
                                    onClick={() => {
                                      openModifyRegisterAt(announcement.notification.registerAt)
                                      openModifyAnnoucement()
                                    }}
                                    >
                          <BorderColorIcon />
                        </IconButton>
                      </Tooltip>
                  </TableCell>

                  <TableCell align={'center'}>
                      <Tooltip title={STRING_DELETE}>
                        <IconButton aria-label={STRING_DELETE}
                                    disabled={permission<WRITE_PERMISSION}
                                    onClick={() => {
                                      openDeleteRegisterAt(announcement.notification.registerAt)
                                      openDeleteContent(announcement.notification.contents.message)
                                      openDeleteAnnoucement()
                                    }}
                                    >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                  </TableCell>
                
              </TableRow>
                ))}
            </TableBody>

          </Table>
        </Grid>
        <Grid container justifyContent="flex-end" >
            <CardActions className={classes.actions}>
              {Annoucements !== null && Annoucements !== undefined &&
                <TablePagination
                  component="div"
                  count={Annoucements !== null && Annoucements !== undefined && Annoucements.length}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handleRowsPerPageChange}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={[5, 10, 25]}
                />}
                <Box m={3}
                     display="flex"
                     justifyContent="flex-end"
                >
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={openAddAnnoucement}
                    disabled={permission<WRITE_PERMISSION}
                    onChange={handleChange}
                    value={values.title}
                  >
                    공지사항 추가하기
                  </Button>
                </Box>
              </CardActions>
        </Grid>
        </Grid>
        </PerfectScrollbar>
      </CardContent>

    <AddAnnouncementDialog open={addAnnouncementDialogOpen} handleClose={closeAddAnnoucement} refetch ={refetchWithDialog}/>
    <ModifyAnnouncementDialog registerAt = {registerAt} open={modifyAnnouncementDialogOpen} handleClose={closeModifyAnnoucement} refetch ={refetchWithDialog}/>
    <DeleteAnnouncementDialog content = {content} registerAt = {registerAt} open={deleteAnnouncementDialogOpen} handleClose={closeDeleteAnnoucement} refetch ={tryRefetch}/>
    </Card>
  );
  
};

// Announcement.propTypes = {
//   className: PropTypes.string
// };

export default Announcement;
