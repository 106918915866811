import React, { forwardRef, useContext, useEffect, useState } from 'react';
import { NavLink as RouterLink, withRouter } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, Button, colors } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import {
  NO_PERMISSION,
  READ_PERMISSION,
  WRITE_PERMISSION
} from '../../../../views/Common/Config/naviwatchConfig';
import { dispatchContext } from '../../../../App';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const useStyles = makeStyles(theme => ({
  root: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main
    }
  },
  nested: {
    paddingLeft: theme.spacing(3)
  }
}));
const PAGE_UPDATE_STATE_INIT = 0;
const PAGE_UPDATE_STATE_RUNNING = 1;
const PAGE_UPDATE_STATE_FINISH = 2;

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

const SidebarNav = props => {
  const {
    pages,
    className,
    userInfo,
    subNaviState,
    setSubNaviState,
    naviIcons,
    account,
    productFullPolicies,
    path,
    myPolicies
  } = props;

  const classes = useStyles();
  const [currentPages, setCurrentPages] = useState(pages);
  const [pageUpdating, setPageUpdating] = useState(PAGE_UPDATE_STATE_INIT);
  const dispatch = useContext(dispatchContext);

  const handleClick = subNaviIndex => {
    if (subNaviIndex === undefined) {
      setSubNaviState(
        subNaviState.map(item => ({ ...item, openState: false }))
      );
    } else {
      setSubNaviState(
        subNaviState.map(item =>
          item.index === subNaviIndex
            ? { ...item, openState: !item.openState }
            : item
        )
      );
    }
  };

  useEffect(() => {
    setPageUpdating(PAGE_UPDATE_STATE_RUNNING);
  }, [path]);

  useEffect(() => {
    if (myPolicies !== undefined) {
      for (var pageIdx = 0; pageIdx < pages.length; ++pageIdx) {
        var page = pages[pageIdx];
        for (var policyIdx = 0; policyIdx < myPolicies.length; ++policyIdx) {
          var policy = productFullPolicies.filter(
            policy => policy.policyId === myPolicies[policyIdx]
          );
          if (
            policy.length > 0 &&
            policy[0].policyName
              .toLowerCase()
              .includes(page.name.toLocaleString()) === true
          ) {
            if (
              policy[0].policy === READ_PERMISSION ||
              policy[0].policy === WRITE_PERMISSION
            ) {
              page.permission = policy[0].policy;
              setCurrentPages(
                pages.map(side =>
                  side.name
                    .toLowerCase()
                    .includes(page.name.toLocaleString()) === true
                    ? { ...side, permission: page.permission }
                    : side
                )
              );
              break;
            }
          }
        }
      }
    } else {
      setCurrentPages(pages);
    }
    setPageUpdating(PAGE_UPDATE_STATE_FINISH);
  }, [productFullPolicies, myPolicies, pages, path, account]);

  useEffect(() => {
    if (pageUpdating === PAGE_UPDATE_STATE_FINISH) {
      dispatch({
        type: 'PAGE_UPDATE',
        pages: currentPages
      });
    }
  }, [pageUpdating, currentPages, path, pages]);

  return (
    <List className={clsx(classes.root, className)}>
      {currentPages.map(page => {
        return (
          <div key={page.name}>
            {account !== undefined && page.permission >= READ_PERMISSION && (
              <div>
                <ListItem
                  className={classes.item}
                  disableGutters
                  key={page.title}
                  onClick={() => handleClick(page.subNavIndex)}>
                  <Button
                    activeClassName={classes.active}
                    className={classes.button}
                    component={CustomRouterLink}
                    to={page.href}>
                    <div className={classes.icon}>
                      {
                        naviIcons.filter(
                          icon => page.name.indexOf(icon.name) !== -1
                        )[0]?.icon
                      }
                    </div>
                    {page.title}
                    {page.subNavIndex !== undefined && <ArrowDropDownIcon />}
                  </Button>
                </ListItem>
                {page.subNavIndex !== undefined && (
                  <Collapse
                    in={subNaviState[page.subNavIndex]?.openState}
                    timeout="auto"
                    unmountOnExit>
                    <List component="div" disablePadding>
                      {subNaviState[page.subNavIndex]?.subNav.map(subItem => (
                        <ListItem
                          className={classes.item}
                          disableGutters
                          key={subItem.title}>
                          <Button
                            activeClassName={classes.active}
                            className={classes.button}
                            component={CustomRouterLink}
                            to={subItem.href}>
                            <div className={classes.nested} />
                            <div className={classes.icon}>{subItem.icon}</div>
                            {subItem.title}
                          </Button>
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                )}
              </div>
            )}
          </div>
        );
      })}
    </List>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired
};

export default SidebarNav;
