import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Box
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  },
  title: {
    fontWeight: 700
  },
  formControl: {
    // margin: theme.spacing(1),
    width: 300
  },
  labelText: {
    color: '#aaaaaa'
  },
  indeterminateColor: {
    color: '#f50057'
  },
  selectAllText: {
    fontWeight: 500
  },
  selectedAll: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)'
    }
  }
}));

const STRING_SEARCH = '검색';
const STRING_SEARCH_CLEAN = '초기화';
const STRING_TITLE = ' AI수면 통계';
const UpSleepStatisticsToolbar = props => {
  const {
    className,
    setLoadingAnimation,
    getSleepStatistics,
    today,
    ...rest
  } = props;

  const classes = useStyles();
  const [values, setValues] = useState({
    from: '',
    to: ''
  });
  const [date, setDate] = useState('');

  useEffect(() => {
    setDate(`${values.from} ~ ${values.to}`);
  }, [values]);

  useEffect(() => {
    const locale = new Date().toLocaleString('en-US', {
      timeZone: 'UTC'
    });
    const year = new Date(locale).getFullYear();
    const month = new Date(locale).getMonth();
    const day = new Date(locale).getDate();
    setDate(`${year}-${month + 1}-1 ~ ${year}-${month + 1}-${day}`);
  }, []);

  const handleChange = event => {
    if (event.target.value > today) {
      alert('오늘 이후의 날짜는 선택할 수 없습니다.');
      return;
    }
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  useEffect(() => {
    if (
      values.to &&
      values.from &&
      (new Date(values.to).getTime() - new Date(values.from).getTime()) /
        86400000 >
        32
    ) {
      alert('한 달치 이내 데이터만 조회해주세요.');
      setValues({
        ...values,
        from: '',
        to: ''
      });
    }
  }, [values, values.from, values.to]);

  const cleanSearchInput = () => {
    setValues({
      from: '',
      to: ''
    });
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader title={date + STRING_TITLE} />
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item md={6} xs={6}>
            <form className={classes.container} noValidate>
              <TextField
                id="from"
                name="from"
                label="From"
                type="date"
                className={classes.textField}
                value={values.from}
                onChange={handleChange}
                disabled={values.condition === ''}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </form>
          </Grid>
          <Grid item md={6} xs={6}>
            <form className={classes.container} noValidate>
              <TextField
                id="to"
                name="to"
                label="To"
                type="date"
                className={classes.textField}
                value={values.to}
                onChange={handleChange}
                disabled={values.condition === ''}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </form>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions className={classes.actions}>
        <Button
          color="primary"
          variant="contained"
          onClick={cleanSearchInput}
          disabled={!values.from && !values.to}>
          {STRING_SEARCH_CLEAN}
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={!values.from || !values.to}
          onClick={() => getSleepStatistics(values)}>
          {STRING_SEARCH}
        </Button>
      </CardActions>
    </Card>
  );
};

UpSleepStatisticsToolbar.propTypes = {
  className: PropTypes.string
};

export default UpSleepStatisticsToolbar;
