import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core/styles';
import palette from 'theme/palette';
import {
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Divider
} from '@material-ui/core';
import useAsync from '../../../../../asyncNet';
import {
  STRING_OPERATING_MODE,
  STRING_INSIDE_HEAT,
  STRING_ONDOL_HEAT,
  STRING_WATER_HEAT,
  STIRNG_RESERVATION_HEAT,
  STRING_ETC
} from '../../../StringTable/StringTable';

const useStyles = makeStyles(() => ({
  root: {
  },
  chartContainer: {
    width: "100%",
    height: "100%",
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

// TODO : to be implemented.. 
async function getMetric() {
  return;
}


const OperatingModeCharts = props => {
  const { className, ...rest } = props;
  const [state, tryRefetch] = useAsync(() => getMetric());
  const { loading, data: metric, error } = state;
  const classes = useStyles();
  const [chartdata, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: '',
        fill: false,
        backgroundColor: palette.primary.main,
        data: []
      }
    ]
  });
  const options = {
    legend: {
      display: true,
      position: "right",
      align: "center",
      fontFamily: "Allianz-Neo",
      textDirection: 'ltr',
      labels: {
        usePointStyle: true,
        fontColor: "#006192",
      }
    }
  };

  useEffect(() => {
    //TODO : using real data
    // try {
    // if (metric !== null) {
    // var insideHeat = 0;
    // var ondolHeat = 0;
    // var waterHeat = 0;
    // var reservationHeat = 0;
    // var etc = 0;

    // console.log('count:'+metric['total'].length)
    // for(var i=0; i< metric['total'].length; i++)
    // {
    //   insideHeat += (metric['insideCount'][i]);
    //   ondolHeat += metric['ondolCount'][i];
    //   waterHeat += metric['ondolCount'][i];
    //   reservationHeat += metric['ondolCount'][i];
    //   etc += metric['ondolCount'][i];
    // }
    let insideHeat = 38;
    let ondolHeat = 29;
    let waterHeat = 16;
    let reservationHeat = 10;
    let etc = 7;
    let label;
    let color;
    let insiderate = Math.round(insideHeat / (insideHeat + ondolHeat + waterHeat + reservationHeat + etc) * 100);
    let ondolrate = Math.round(ondolHeat / (insideHeat + ondolHeat + waterHeat + reservationHeat + etc) * 100);
    let waterrate = Math.round(waterHeat / (insideHeat + ondolHeat + waterHeat + reservationHeat + etc) * 100);
    let reservationrate = Math.round(reservationHeat / (insideHeat + ondolHeat + waterHeat + reservationHeat + etc) * 100);
    let etcrate = Math.round(etc / (insideHeat + ondolHeat + waterHeat + reservationHeat + etc) * 100);


    label = [STRING_INSIDE_HEAT + ' ' + insiderate + '%',
    STRING_ONDOL_HEAT + ' ' + ondolrate + '%',
    STRING_WATER_HEAT + ' ' + waterrate + '%',
    STIRNG_RESERVATION_HEAT + ' ' + reservationrate + '%',
    STRING_ETC + ' ' + etcrate + '%']
    color = palette.primary.main;

    setChartData(chardata => ({
      ...chartdata,
      labels: label,
      datasets: [
        {
          label: "",
          borderWidth: 1,
          backgroundColor: [palette.error.main, palette.secondary.main, palette.success.main, palette.primary.main],
          data: [38, 29, 16, 10, 7]
        }]
    })
    );
    // }
    // } catch (e) {
    //   console.log('metric update failed :' + JSON.stringify(e));
    // }
  }, [metric]);

  useEffect(() => {
    tryRefetch();
  }, []);

  return (
    <div>
      <Card style={{ flex: 1, backgroundColor: 'lightgray' }}
        {...rest}
        className={classes.root}>
        <CardHeader
          action={
            <IconButton size="small" style={{ color: 'white' }}>
            </IconButton>
          }
          title={STRING_OPERATING_MODE}
        />
        <Divider />
        <div className={classes.chartContainer}>
          <CardContent>
            <Doughnut
              data={chartdata}
              options={options}
            />
          </CardContent>
        </div>
        <Divider />
      </Card>
    </div>
  );
};

OperatingModeCharts.propTypes = {
  className: PropTypes.string
};

export default React.memo(OperatingModeCharts);
