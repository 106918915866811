import { CSVLink } from 'react-csv';
import React, { useEffect, useRef, } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
}));

const headers = [
    { label: "사용자ID", key: "id" },
    { label: "사용자 이름", key: "name" },
    { label: "사용자 전화번호", key: "phone" },
    { label: "개인정보 수집 동의", key: "marketingAgreeAt" },
    { label: "개인정보 마케팅 동의", key: "collectAgreeAt" },
    { label: "개인정보 제공 동의", key: "serveAgreeAt" }
];

const unixtimeToDatetime = async (excelData) => {
    for (var i = 0; i < excelData.length; i++) {
        if (excelData[i].marketingAgreeAt !== null && excelData[i].marketingAgreeAt !== undefined && excelData !== undefined) {
            var dateMarketing = new Date(excelData[i].marketingAgreeAt * 1000);

            excelData[i].marketingAgreeAt = dateMarketing.getFullYear() +
                "/" + (dateMarketing.getMonth() + 1).toString().padStart(2, '0') +
                "/" + dateMarketing.getDate().toString().padStart(2, '0') +
                " " + dateMarketing.getHours().toString().padStart(2, '0') +
                ":" + dateMarketing.getMinutes().toString().padStart(2, '0') +
                ":" + dateMarketing.getSeconds().toString().padStart(2, '0');
        }
        if (excelData[i].collectAgreeAt !== null && excelData[i].collectAgreeAt !== undefined && excelData !== undefined) {
            var dateCollect = new Date(excelData[i].collectAgreeAt * 1000);

            excelData[i].collectAgreeAt = dateCollect.getFullYear() +
                "/" + (dateCollect.getMonth() + 1).toString().padStart(2, '0') +
                "/" + dateCollect.getDate().toString().padStart(2, '0') +
                " " + dateCollect.getHours().toString().padStart(2, '0') +
                ":" + dateCollect.getMinutes().toString().padStart(2, '0') +
                ":" + dateCollect.getSeconds().toString().padStart(2, '0');
        }
        if (excelData[i].serveAgreeAt !== null && excelData[i].serveAgreeAt !== undefined && excelData !== undefined) {
            var dateServe = new Date(excelData[i].serveAgreeAt * 1000);

            excelData[i].serveAgreeAt = dateServe.getFullYear() +
                "/" + (dateServe.getMonth() + 1).toString().padStart(2, '0') +
                "/" + dateServe.getDate().toString().padStart(2, '0') +
                " " + dateServe.getHours().toString().padStart(2, '0') +
                ":" + dateServe.getMinutes().toString().padStart(2, '0') +
                ":" + dateServe.getSeconds().toString().padStart(2, '0');
        }
    }
}

const ExcelDownload = props => {
    const { className, excelDownloadData, ...rest } = props;
    const classes = useStyles();

    const btnRef = useRef(null);
    useEffect(() =>
        btnRef.current?.click(),
        [btnRef, excelDownloadData]);

    unixtimeToDatetime(excelDownloadData);

    return (
        <div>
            <CSVLink data={excelDownloadData} filename="회원정보" headers={headers} target="_blank" >
                <span ref={btnRef} />
            </CSVLink>
        </div>
    );
};

ExcelDownload.propTypes = {
    className: PropTypes.string
};

export default ExcelDownload;
