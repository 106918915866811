import React, {useEffect, useState} from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Typography } from '@material-ui/core';

import ProfileDialog from './ProfileDialog';
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  avatar: {
    width: 60,
    height: 60
  },
  name: {
    marginTop: theme.spacing(1)
  }
}));

const Profile = props => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const user = {
    name: sessionStorage.getItem('name'),
    avatar: '/images/avatars/human.png',
    bio: sessionStorage.getItem('team'),
    accessToken: sessionStorage.getItem('accessToken'),
    email: sessionStorage.getItem('email')
  };

  const handleClickOpen = (accessToken, email) => {
    console.log('handleClickOpen');
    setOpen(true);
  };

  const handleClose = () => {
    console.log('handleClose');
    setOpen(false);
  };

  useEffect(()=>{
    console.log("profile rendering")
  },[])


  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Avatar
        alt="Person"
        className={classes.avatar}
        src={user.avatar}
        onClick={() => handleClickOpen(user.accessToken, user.email)}
      />
      <Typography
        className={classes.name}
        variant="h4"
      >
        {user.name}
      </Typography>
      <Typography variant="body2">{user.bio}</Typography>
      <ProfileDialog open={open} handleClose={handleClose} accessToken={user.accessToken} email={user.email} />
    </div>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;
