import React, { useContext, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Divider, Drawer } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import TapAndPlayIcon from '@material-ui/icons/TapAndPlay';
import TimelineIcon from '@material-ui/icons/Timeline';
import DeveloperBoardIcon from '@material-ui/icons/DeveloperBoard';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import { Profile, SidebarNav } from './components';
import { userInfoContext } from '../../../../../App';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import PhonelinkSetupIcon from '@material-ui/icons/PhonelinkSetup';
import RemoveIcon from '@material-ui/icons/Remove';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import SettingsIcon from '@material-ui/icons/Settings';
const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  }
}));
const NAME_DASHBOARD = '대시보드';
const NAME_DEVICES = '디바이스';
const NAME_USERS = '사용자';
const NAME_PUSH = '푸시';
const NAME_ROLE = '권한';
const NAME_DORMANCY = '휴면 사용자';
const NAME_DORMANCY_HISTORY = '휴면 전환 안내';
const NAME_DORMANCY_MANAGE = '휴면 사용자 관리';
const NAME_QUERY_RECORD = '접속 및 처리 기록';
const NAME_SERVER = '서버';
const NAME_STATISTICS = '통계';
const NAME_FIRMWARE = '펌웨어';
const NAME_FIRMWARE_OFFICIAL = 'OFFICIAL';
const NAME_FIRMWARE_BS = 'BS';
const NAME_SETTING = '설정';

const Sidebar = props => {
  const { open, variant, onClose, prodid, className, ...rest } = props;
  const authInfo = useContext(userInfoContext);

  const classes = useStyles();

  const pages = [
    {
      name: 'dashboard',
      title: `${NAME_DASHBOARD}`,
      href: `/smarttok-cn/dashboard`,
      permission: undefined
    },
    {
      name: 'firmware',
      title: `${NAME_FIRMWARE}`,
      href: `/smarttok-cn/firmware/firmwareOfficial`,
      subNavIndex: 0,
      permission: undefined
    },
    {
      name: 'role',
      title: `${NAME_ROLE}`,
      href: `/smarttok-cn/role`,
      permission: undefined
    },
    {
      name: 'setting',
      title: `${NAME_SETTING}`,
      href: `/smarttok-cn/setting`,
      permission: undefined
    }
  ];

  const naviIcons = [
    {
      name: 'dashboard',
      icon: <DashboardIcon />,
    },
    {
      name: 'server',
      icon: <TimelineIcon />,
    },
    {
      name: 'analytics',
      icon: <EqualizerIcon />,
    },
    {
      name: 'device',
      icon: <DeveloperBoardIcon />,
    },
    {
      name: 'user',
      icon: <PeopleIcon />,
    },
    {
      name: 'inactive user',
      icon: <SentimentVeryDissatisfiedIcon />,
    },
    {
      name: 'push',
      icon: <TapAndPlayIcon />,
    },
    {
      name: 'firmware',
      icon: <PhonelinkSetupIcon />,
    },
    {
      name: 'search log',
      icon: <BorderColorIcon />,
    },
    {
      name: 'role',
      icon: <HowToRegIcon />,
    },
    {
      name: 'setting',
      icon: <SettingsIcon />,
    }
  ];
  const subNaviState = [
    {
      index: 0,
      openState: false,
      subNav: [
        {
          title: `${NAME_FIRMWARE_OFFICIAL}`,
          href: `/smarttok-cn/firmware/firmwareOfficial`,
          icon: <RemoveIcon />
        },
        {
          title: `${NAME_FIRMWARE_BS}`,
          href: `/smarttok-cn/firmware/firmwareBS`,
          icon: <RemoveIcon />
        }
      ]
    }
  ];
  const [subNavi, setSubNaviState] = useState(subNaviState);

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div
        {...rest}
        className={clsx(classes.root, className)}
      >
        <Profile />
        <Divider className={classes.divider} />
        <SidebarNav
          className={classes.nav}
          pages={pages}
          userInfo={authInfo}
          subNaviState={subNavi}
          setSubNaviState={setSubNaviState}
          naviIcons={naviIcons}
        />
        {/*<UpgradePlan />*/}
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;
