import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core/styles';
import palette from 'theme/palette';

import {
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Divider,
} from '@material-ui/core';

import useAsync from '../../../../asyncNet';
import {
  statisticsGetCapacityTypeCount
} from '../../APIs/mateApis';

const STRING_SINGLE = '싱글';
const STRING_QUEEN = '퀸';
const STRING_KING = '킹';

const STRING_USER_NO_USE_PSERSONALIZATION = '미사용';
const STRING_USER_USE_WEEKLYRESERVATION = '사용';
const STRING_USER_NO_USE_WEEKLYRESERVATION = '미사용';
const STRING_USER_USE_SLEEP = '사용';
const STRING_USER_NO_USE_SLEEP = '미사용';
const STRING_CAPACITY_TYPE = '용량 타입';


const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
  card: {
    backgroundColor: '#fff9db'
  },
  chartContainer: {
    height: '100%',
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));


const CapacityTypeCharts = props => {
  const { className, metricName, ...rest } = props;
  const [state, tryRefetch] = useAsync(() => statisticsGetCapacityTypeCount());
  const { data: metric} = state;
  const classes = useStyles();
  const [chartdata, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: '',
        fill: false,
        backgroundColor: palette.primary.main,
        data: []
      }
    ]
  });
  const options = {
    legend: {
      display: true,
      position: "right",
      align: "center",
      fontFamily: "Allianz-Neo",
      textDirection: 'ltr',
      labels: {
        usePointStyle: true,
        fontColor: "#006192",
      }
    }
  };

  useEffect(() => {
    try {
      if (metric !== null) {
        let singleRate=Math.round(metric['singleDeviceCount']/metric['total']*100);
        let queenRate=Math.round(metric['queenDeviceCount']/metric['total']*100);

        var label = [STRING_SINGLE +' '+singleRate+'%', STRING_QUEEN+' '+queenRate+'%',
                  STRING_KING+' '+(100-singleRate-queenRate)+'%']

        setChartData(chardata => ({
            ...chartdata,
            labels: label,
            datasets: [
              {
                label: "",
                borderWidth: 1,
                backgroundColor: [palette.error.main, '#5993a6', palette.orange],
                data: [metric['singleDeviceCount'], metric['queenDeviceCount'], metric['kingDeviceCount']]
              }]
          })
        );
      }
    } catch (e) {
      console.log('metric update failed :' + JSON.stringify(e));
    }
  }, [metric]);

  useEffect(() => {
    tryRefetch();
  }, []);

  return (
    <div>
      <Card
        {...rest}
        className={classes.root}>
        <CardHeader
          action={
            <IconButton size="small" style={{ color: 'white' }}>
            </IconButton>
          }
          title={STRING_CAPACITY_TYPE}
        />

        <Divider/>
        <CardContent>
          <div className={classes.chartContainer}>
            <Doughnut
              data={chartdata}
              options={options}
            />
          </div>
        </CardContent>
        <Divider/>
      </Card>
    </div>
  );
};

CapacityTypeCharts.propTypes = {
  className: PropTypes.string
};

export default React.memo(CapacityTypeCharts);
