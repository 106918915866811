import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { HorizontalBar } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core/styles';

import {
  Card,
  CardHeader,
  CardContent,
  Divider,
} from '@material-ui/core';

import useAsync from '../../../../../asyncNet';
import {
  statisticsHepafilterResetCount
} from '../../../APIs/aironeApis';

const STRING_HEPA_FILTER_TITLE='초미세 / 프리 필터 알람 수';
const STRING_HEPA_RESET_COUNT = '초미세 필터 교체 알람';
const STRING_FREE_RESET_COUNT = '프리 필터 청소 알람';
const STRING_MODEL_COUNT = '모델 수';
const useStyles = makeStyles(() => ({
  height: '100%',
  card: {
    backgroundColor: '#fff9db'
  },
  chartContainer: {
    height: '100%',
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const options = {
  legend: {
    display: false
  }
};

const HepafilterResetCountChart = props => {

  const { className, ...rest } = props;
  const [state, tryRefetch] = useAsync(statisticsHepafilterResetCount, [], false);
  const [chartdata, setChartData] = useState({
    labels: [],
    datasets: []
  });

  const [hepaValue, sethepaValue] = useState([]);
  const [freeValue, setfreeValue] = useState([]);

  const [modelValue, setModelValue] = useState([]);
  const [chartColor, setChartColor] = useState([]);
  const chartLabel = ["TAC 510/550", "TAC 511/551", "중대형"];
  const { data: metric } = state;
  const classes = useStyles();

  const makeChartValue = (numeric, hepaResetCount, freeResetCount, modelCount) =>{
    let hepaValue = [0, 0, 0];
    let freeValue = [0, 0, 0];
    let modelValue = [0, 0, 0];
    let tempColor = [];

    for (var i = 0; i < numeric.length; i++) {
      switch (parseInt(numeric[i])) {
        case 2:
          hepaValue[0]+=hepaResetCount[i];
          freeValue[0]+=freeResetCount[i];
          modelValue[0]+=modelCount[i];
          tempColor.push('#FFFAFD');
          break;
        case 3:
          hepaValue[0]+=hepaResetCount[i];
          freeValue[0]+=freeResetCount[i];
          modelValue[0]+=modelCount[i];
          tempColor.push('#FFFFCD');
          break;
        case 4:
          hepaValue[1]+=hepaResetCount[i];
          freeValue[1]+=freeResetCount[i];
          modelValue[1]+=modelCount[i];
          tempColor.push('#FF730F');
          break;
        case 5:
          break;
        case 6:
          hepaValue[2]+=hepaResetCount[i];
          freeValue[2]+=freeResetCount[i];
          modelValue[2]+=modelCount[i];
          tempColor.push('#FDDACD');
          break;
      }
    }
    sethepaValue(hepaValue);
    setfreeValue(freeValue);
    setModelValue(modelValue);
    setChartColor(tempColor);

  };

  useEffect(() => {
    try {
      setChartData({
          ...chartdata,
          labels: chartLabel,
          datasets: [
            {
              label: STRING_HEPA_RESET_COUNT,
              borderWidth: 1,
              backgroundColor: '#FFFFCD',
              data: hepaValue//metric['count'],
            },
            {
              label: STRING_FREE_RESET_COUNT,
              borderWidth: 1,
              backgroundColor: '#FDDACD',
              data: freeValue//metric['count'],
            },
            {
              label: STRING_MODEL_COUNT,
              borderWidth: 1,
              backgroundColor: '#FF730F',
              data: modelValue//metric['count'],
            }
          ]
        }
      );
    } catch (e) {
      console.log('metric update failed :' + JSON.stringify(e));
    }
  }, [hepaValue]);

  useEffect(()=>{
    if (metric !== null && metric !== undefined) {
      console.log('update hepafilter: '+JSON.stringify(metric));
      makeChartValue(metric['modelCode'], metric['heapResetCount'], metric['freeResetCount'], metric['modelCount']);
    }
  }, [metric]);

  return (
    <div>
      <Card
        {...rest}
        className={clsx(classes.root, className)}>
        <CardHeader
          title={STRING_HEPA_FILTER_TITLE}
        />
        <Divider/>
        <CardContent>
          {chartdata !== undefined  &&
          <div className={classes.chartContainer}>
            <HorizontalBar
              data={chartdata}
              options={options}
            />
          </div>
          }
        </CardContent>
        <Divider/>
      </Card>
    </div>
  );
};

HepafilterResetCountChart.propTypes = {
  className: PropTypes.string
};

export default React.memo(HepafilterResetCountChart);
