import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import useAsync from '../../../../../asyncNet';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  Typography
} from '@material-ui/core';
import {
  STRING_PUSH_SERVICE,
  STRING_ALERT_PUSH_MESSAGE,
  STRING_TITLE,
  STRING_CONTENT,
  STRING_MODEL,
  STRING_MODEL_NR30D,
  STRING_MODEL_NR35D,
  STRING_MODEL_NR40D,
  STRING_MODEL_NRM35D,
  STRING_MODEL_NR6XD,
  STRING_MODEL_NRM6XD,
  STRING_MODEL_ALL,
  STRING_SENT,
  STRING_CLEAN
} from '../../../StringTable/StringTable';

const useStyles = makeStyles(() => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  },
  title: {
    fontWeight: 700
  }
}));

const models = [
  {
    value: ''
  },
  {
    value: STRING_MODEL_ALL
  },
  {
    value: STRING_MODEL_NR30D
  },
  {
    value: STRING_MODEL_NR35D
  },
  {
    value: STRING_MODEL_NR40D
  },
  {
    value: STRING_MODEL_NRM35D
  },
  {
    value: STRING_MODEL_NR6XD
  },
  {
    value: STRING_MODEL_NRM6XD
  }
];

const PushToolbar = props => {
  const { className, callbackFunc, ...rest } = props;

  const classes = useStyles();

  const [values, setValues] = useState({
    model: '',
    title: '',
    content: '',
  });

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };


  const cleanInput = () => {
    setValues({
      model: '',
      title: '',
      content: '',
    });
  };

  const sendPush = () => {
    callbackFunc(values);
    cleanInput();
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        title={STRING_PUSH_SERVICE}
        subheader={<div>
          <span style={{ color: 'red' }}>{STRING_ALERT_PUSH_MESSAGE}</span></div>}
      />
      <Divider />
      <CardContent>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            md={4}
            xs={12}
          >
            <TextField
              fullWidth
              label={STRING_MODEL}
              margin="dense"
              name="model"
              onChange={handleChange}
              select
              // eslint-disable-next-line react/jsx-sort-props
              SelectProps={{ native: true }}
              value={values.model}
              variant="outlined"
            >
              {models.map(option => (
                <option
                  key={option.value}
                  value={option.value}
                >
                  {option.value}
                </option>
              ))}
            </TextField>
          </Grid>
          <Grid
            item
            md={12}
            xs={12}
          >
            <TextField
              fullWidth
              label={STRING_TITLE}
              margin="dense"
              name="title"
              onChange={handleChange}
              value={values.title}
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            md={12}
            xs={12}
          >
            <TextField
              fullWidth
              multiline={true}
              rows={10}
              label={STRING_CONTENT}
              margin="dense"
              name="content"
              onChange={handleChange}
              value={values.content}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>
        <Button
          color="primary"
          variant="contained"
          onClick={cleanInput}
          disabled={values.model === "" && values.title === "" && values.content === ""}
        >
          {STRING_CLEAN}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={sendPush}
          disabled={values.model === "" || values.title === "" || values.content === ""}
        >
          {STRING_SENT}
        </Button>
      </CardActions>
    </Card>
  );
};

PushToolbar.propTypes = {
  className: PropTypes.string
};

export default PushToolbar;
