import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormGroup,
  Checkbox, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions
} from '@material-ui/core';
import useAsync from '../../../../../asyncNet';
import {
  naviwatchGetPolicies,
  naviwatchGetRoles,
  naviwatchSetPolicy, naviwatchWithdraw
} from '../../../../Common/APIs/naviwatchApis';
import {
  NO_PERMISSION,
  NO_PERMISSION_WARNING,
  READ_PERMISSION,
  WRITE_PERMISSION
} from '../../../../Common/Config/naviwatchConfig';
const STRING_SAVE = "변경";
const STRING_DELETE = "유저 삭제";
const STRING_DELETE_DIALOG_TITLE = "사용자 삭제";
const STRING_DELETE_DIALOG_CONTENT1 = "사용자를 삭제하면 다시 복구할 수 없습니다.";
const STRING_DELETE_DIALOG_CONTENT2 = "사용자를 삭제할까요?";

const TITLE = "ROLE & POLICY";
const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  form: {
    height: '70%',
  },
  grid: {
    padding: theme.spacing(3),
    height: '100%',
  },
  gridItem:{
    backgroundColor: '#fff9db'
  },
  cardAction:{
    paddingTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'flex-end'
  },
  cardItem:{
    width: '100px',
    margin: '4px'
  },
  policyLabel:{
    display: 'flex',
    position: 'relative'
  },
  policy:{
    position: 'absolute',
    left: '40%'
  }
}));

const AccountDetails = (props) => {
  const { email, roleId, history, className,  ...rest } = props;
  const [roleIdValue, setRoleId] = useState(0);
  const [originRoleId, setOriginRoleId] = useState(0);
  const [policyObj, setPolicyObj] = useState(undefined);
  const [roles, tryRoleRefetch] = useAsync(getProductRoles, [], false);
  const { data: productRoles } = roles;

  const [policies, tryPolicyRefetch] = useAsync(getProductPolicies, [], false);
  const [roleOfUser, trySetRoleOfUserRefetch] = useAsync(setRoleOfUser, [], true);
  const [removedUser, tryUserDelete] = useAsync(deleteNaviwatchUser, [], true);


  const { data: productPolicies } = policies;

  async function getProductRoles() {
    return naviwatchGetRoles(localStorage.getItem("productID"));
  }

  async function getProductPolicies() {
    return naviwatchGetPolicies(localStorage.getItem("productID"));
  }
  
  async function setRoleOfUser() {
    return naviwatchSetPolicy(email, localStorage.productID, roleIdValue,
      sessionStorage.getItem("email"), ()=>{setOriginRoleId(roleIdValue)});
  }

  async function deleteNaviwatchUser() {
    return naviwatchWithdraw(email);
  }

  const classes = useStyles();
  const [permission, setPermission] = useState(NO_PERMISSION);
  useEffect(()=>{
    var pageInfo = sessionStorage.getItem('pages');
    var pageInfoObj = JSON.parse(pageInfo);
    var pageArray = pageInfoObj.filter(page=>page.name.toLowerCase().includes("role"));
    if(pageArray!==undefined && pageArray.length > 0) {
      var page = pageArray[0];
      setPermission(page.permission);
    }
  }, []);

  const setSelectedPolicy = (roleId) =>{
    if(productRoles!==null) {
      var policyArray = productRoles.data.filter(role => role.roleId === roleId);
      if (policyArray !== undefined && policyArray.length > 0) {
        var policyObj = policyArray[0];
        setPolicyObj(policyObj);
      }
    }
  };

  useEffect(()=>{
    if(productRoles!==null){
      setSelectedPolicy(Number(roleId));
    }
  }, [productRoles]);

  useEffect(() => {
    console.log('roleIe : '+Number(roleId));
    setRoleId(Number(roleId));
    setOriginRoleId(Number(roleId));
  }, []);

  const handleChange = (event) =>{
    setRoleId(Number(event.target.value));
    setSelectedPolicy(Number(event.target.value));
  };

  const getPolicyName = (policyIdx) =>{
    var policyArray = productPolicies.data.policy.filter(productPolicy=>productPolicy.policyId===policyIdx);

    if(policyArray !== undefined && policyArray.length>0){
      var policyObj = policyArray[0];
      return policyObj.policyName;
    }
    return undefined;
  };

  const getPolicy = (policyIdx) =>{
    var policyArray = productPolicies.data.policy.filter(productPolicy=>productPolicy.policyId===policyIdx);

    if(policyArray !== undefined && policyArray.length>0){
      var policyObj = policyArray[0];
      return policyObj.policy;
    }
    return undefined;
  };



  const [dialogTxt, setDialogTxt] = useState(
    {
      dialogTitle: '',
      dialogContent1: '',
      dialogContent2: ''
    }
  );
  const [dialogOpen, setDialog] = useState(false);
  const setDialogClose = () => {
    setDialog(false);
  };

  const deleteAndDialogClose = () => {
    tryUserDelete().then(r => {
      setDialog(false);
      history.push('/airone/role');
    });
  };

  const setDialogOpen = () => {
    setDialog(true);
  };

  const dialogOn = (title, content1, content2) => {
    setDialogTxt({
      ...dialogTxt,
      dialogTitle: title,
      dialogContent1: content1,
      dialogContent2: content2
    });
    setDialogOpen();
  };
  const setPolicy = () => {
    console.log("called set policy");
    trySetRoleOfUserRefetch();
  };

  const deleteUser = () => {
    console.log("called delete user");
    dialogOn(STRING_DELETE_DIALOG_TITLE, STRING_DELETE_DIALOG_CONTENT1,
      email + STRING_DELETE_DIALOG_CONTENT2);
  };
  const warningDialog = () => {
    return (
      <Dialog
        open={dialogOpen}
        onClose={setDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dialogTxt.dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogTxt.dialogContent1}
            <br/>
            {dialogTxt.dialogContent2}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={deleteAndDialogClose} color="primary">
            네
          </Button>
          <Button onClick={setDialogClose} color="primary">
            아니요
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <div className={classes.root}>
      {permission>=READ_PERMISSION?
        <div className={classes.root}>
          <Card
            {...rest}
            className={clsx(classes.root, className)}
          >
            <form
              className={classes.form}
              autoComplete="off"
              noValidate
            >
              <CardHeader
                title={TITLE}
              />
              <Divider />
              <CardContent className={classes.root}>
                <Grid
                  className={classes.grid}
                  container
                  spacing={3}
                >
                 <Grid
                   item
                   lg={4}
                   md={4}
                   xl={4}
                   xs={12}>
                   <FormControl component={'fieldset'}>
                     <FormLabel component={"legend"}> Role </FormLabel>
                     <RadioGroup aria-label={"Role"} name ="role" value={roleIdValue} onChange={handleChange}>
                       {
                          productRoles!==null && productRoles.data.map(role=>(
                         <FormControlLabel value={role.roleId} control={<Radio/>} label={role.roleName}/>
                       ))}
                     </RadioGroup>
                   </FormControl>
                 </Grid>
                  <Grid
                    item
                    lg={8}
                    md={8}
                    xl={8}
                    xs={12}>
                    <FormGroup>
                      <FormLabel component={"legend"}> Policy </FormLabel>
                      {
                        productPolicies!==null&&
                        policyObj!==undefined&&
                        policyObj.policy.length>0 &&
                        policyObj.policy.map(policyId=>(
                          <div className={classes.policyLabel}>
                            <FormControlLabel checked disabled control={<Checkbox />}
                                              label={getPolicyName(policyId)} />
                            {
                              <div className={classes.policy}>
                                <FormControlLabel checked disabled control={<Checkbox checked={true}/>}
                                                  label={"READ"}/>
                                <FormControlLabel checked disabled control={<Checkbox checked={getPolicy(policyId)===2}/>}
                                                  label={"WRITE"}/>
                              </div>
                            }
                          </div>
                        ))
                      }
                    </FormGroup>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <CardActions className={classes.cardAction}>
                <Button
                  className={classes.cardItem}
                  color="primary"
                  variant="contained"
                  disabled={permission<WRITE_PERMISSION}
                  onClick={()=>deleteUser()}
                >
                  {STRING_DELETE}
                </Button>
                <Button
                  className={classes.cardItem}
                  color="primary"
                  variant="contained"
                  disabled={originRoleId===roleIdValue || permission<WRITE_PERMISSION}
                  onClick={()=>setPolicy(roleIdValue)}
                >
                  {STRING_SAVE}
                </Button>
              </CardActions>
            </form>
          </Card>
          {warningDialog()}
        </div>:
        <Typography>{NO_PERMISSION_WARNING}</Typography>
      }
    </div>
  );
};

AccountDetails.propTypes = {
  className: PropTypes.string
};

export default AccountDetails;
