import React, { useContext, useEffect, useState } from 'react';
import {
  makeStyles,
  Typography,
  Backdrop,
  CircularProgress
} from '@material-ui/core';

import { userInfoContext } from '../../../../App';
import {
  NO_PERMISSION,
  NO_PERMISSION_WARNING,
  READ_PERMISSION,
  WRITE_PERMISSION
} from '../../../Common/Config/naviwatchConfig';
import LogLevelList from '../component/LogLevelList';
import LogLevelToolbar from '../component/LogLevelToolbar';
import { getEcsLogLevel } from '../../APIs/mateApis';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));

const ConverterLogLevel = ({ history }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const authInfo = useContext(userInfoContext);
  const [noPermissionMsg, setNoPermissionMsg] = useState('');
  const [permission, setPermission] = useState(NO_PERMISSION);

  const [logLevelList, setLogLevelList] = useState([]);

  useEffect(() => {
    const pageInfo = sessionStorage.getItem('pages');
    if (pageInfo !== 'undefined') {
      const pageInfoObj = JSON.parse(pageInfo);
      const pageArray = pageInfoObj.filter(page =>
        page.name.toLowerCase().includes('device')
      );
      if (pageArray !== undefined && pageArray.length > 0) {
        const page = pageArray[0];
        if (page.permission === undefined) {
          setNoPermissionMsg(NO_PERMISSION_WARNING);
        }
        setPermission(page.permission);
      }
    }
  }, [authInfo.pages]);

  useEffect(() => {
    syncLogLevelList();
    if (sessionStorage.getItem('naviwatch_permission') !== 'true')
      history.push('/');
  }, []);

  const onSetLoading = loading => {
    setLoading(loading);
  };

  const syncLogLevelList = async () => {
    try {
      setLoading(true);
      const response = await getEcsLogLevel();
      if (response.data && Array.isArray(response.data)) {
        const currentDate = new Date();
        const formattedDate = `${currentDate.getFullYear()}-${currentDate.getMonth() +
          1}-${currentDate.getDate()} ${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()}`;
        const logDataFormatArr = [];
        response.data.forEach(logData => {
          logDataFormatArr.push({
            privateIp: logData.ip,
            currentLogLevel: logData.level,
            syncTime: formattedDate,
            description: ''
          });
        });
        setLogLevelList(logDataFormatArr);
      }
    } catch (e) {
      console.log('syncLogLevelList error: ' + e);
    }
    setLoading(false);
  };

  return (
    <div>
      {permission >= READ_PERMISSION ? (
        <div className={classes.root}>
          <Backdrop className={classes.backdrop} open={loading}>
            <CircularProgress color={'primary'} />
          </Backdrop>
          <LogLevelToolbar
            onSetLoading={onSetLoading}
            syncLogLevelList={syncLogLevelList}
          />
          {logLevelList && logLevelList.length > 0 && (
            <div className={classes.content}>
              <LogLevelList
                logLevelList={logLevelList}
                syncLogLevelList={syncLogLevelList}
              />
            </div>
          )}
        </div>
      ) : (
        <Typography className={classes.root}>{noPermissionMsg}</Typography>
      )}
    </div>
  );
};

export default ConverterLogLevel;
