import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
  import { Bar } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core/styles';
import palette from 'theme/palette';

import {
  Card,
  CardHeader,
  CardContent,
  Divider,
} from '@material-ui/core';

import { options } from './chart';
import useAsync from '../../../../../asyncNet';
import {
  statisticsFilterResetCount
} from '../../../APIs/aironeApis';

const STRING_WEEKLY_USER='월별 초미세 먼지 필터 교체수';

const useStyles = makeStyles(() => ({
  root: {},
  chartContainer: {
    height: 400,
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));


const FilterResetChart = props => {

  const { className, ...rest } = props;
  const [state, tryRefetch] = useAsync(() => statisticsFilterResetCount(7), []);
  const [chartdata, setChartData] = useState({
    labels: [],
    datasets: []
  });

  const { data: dataset } = state;

  const [receivedDataSet, setReceivedDataset] = useState([]);

  const classes = useStyles();

  const updateDataset = (dataset) =>{
    setReceivedDataset(dataset)
  };

  useEffect(() => {
    try {
      const formattedTime = receivedDataSet['month'];
      setChartData({
            ...chartdata,
          labels: formattedTime,
          datasets: [
            {
              type: 'bar',
              label: "bar",
              lineTension: 0,
              backgroundColor: palette.info.main,
              fill: false,
              data: receivedDataSet['count'],
            }
          ]
        }
      );

    } catch (e) {
      console.log('metric update failed :' + JSON.stringify(e));
    }
  }, [receivedDataSet]);

  useEffect(()=>{
    if (dataset !== null && dataset !== undefined) {
      console.log('update filter reset: '+JSON.stringify(dataset));
      updateDataset(dataset);
    }
  }, [dataset]);


  return (
    <div>
      <Card
        {...rest}
        className={clsx(classes.root, className)}>
        <CardHeader
          title={STRING_WEEKLY_USER}
        />
        <Divider/>
        <CardContent>
          {chartdata !== undefined  &&
          <div className={classes.chartContainer}>
            <Bar
              data={chartdata}
              options={options}
            />
          </div>
          }
        </CardContent>
        <Divider/>
      </Card>
    </div>
  );
};

FilterResetChart.propTypes = {
  className: PropTypes.string
};

export default React.memo(FilterResetChart);
