import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import Layout from './layouts/Layout';
import AppRoute from './AppRoute';

const AppContainer = () => {
  return (
    <Switch>
      <Redirect exact from="/" to="/sign-in" />
      <Layout>
        <AppRoute />
      </Layout>
    </Switch>
  );
};

export default AppContainer;
