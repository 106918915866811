import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FirmwareToolbar } from './components';
import Grid from '@material-ui/core/Grid';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  }
}));

const FirmwareModels = ({ match, history }) => {
  const classes = useStyles();

  let productName = match.params.productname;
  let firmwareType = match.params.firmwareType;

  useEffect(() => {
    if (sessionStorage.getItem('naviwatch_permission') !== 'true')
      history.push('/');
  }, [match]);

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          lg={12}
          sm={12}
          xl={12}
          xs={12}
        >
          {<FirmwareToolbar productName={productName} firmwareType={firmwareType} />}
        </Grid>
      </Grid>
    </div>
  );
};

export default FirmwareModels;
