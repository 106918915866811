import React, { useEffect, useState, Children } from 'react';
import { Doughnut } from 'react-chartjs-2';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography,
} from '@material-ui/core';
import useAsync from '../../../../../asyncNet';
import {
  dashboardGetModelStatistics
} from '../../../APIs/aironeApis';
import ToysIcon from '@material-ui/icons/Toys';
import { template } from 'underscore';

const STRING_ALL_NAME='룸콘별 분포';
const STRING_ONLINE_NAME='온라인 기기 분포';


const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  chartContainer: {
    position: 'relative',
    height: '200px'
  },
  stats: {
    marginTop: theme.spacing(2),
    height: '200px',
    display: 'grid',
    gridTemplateRows: 'repeat(5, 100px)',
    gridTemplateColumns: 'repeat(5, 1fr)'
  },
  // stats: {
  //   marginTop: theme.spacing(2),
  //   justifyContent: 'center',
  //   display:'grid',
  //   gridTemplateColumns:'repeat(5, 1fr)'
  // },
  device: {
    textAlign: 'center',
    padding: theme.spacing(1)
  },

  modelName:{
    marginRight: theme.spacing(5),
    width: theme.spacing(15)
  },
  deviceIcon: {
    color: theme.palette.icon
  },
  '@keyframes spinIcon':{
    from:{
      transform: 'rotate(0deg)',
    },
    to:{
      transform: 'rotate(360deg)'
    }
  },
  spinningImag: {
    animationName: '$spinIcon',
    animationDuration: '3s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
    // fontSize:'0.85rem'
  },
}));

const RoomconModelCounter = props => {
  const { className, status, ...rest } = props;

  const classes = useStyles();
  const theme = useTheme();
  const [state] = useAsync(() => dashboardGetModelStatistics(status), []);
  const { data: models } = state;
  const [modelsChart, setModelsChart] = useState({});
  const [modelData, setModelData] = useState( []);
  const backgroundColor = [
    theme.palette.primary.main,
    theme.palette.info.main,
    theme.palette.error.main,
    theme.palette.error.light,
    theme.palette.error.dark,
    theme.palette.warning.main,
    theme.palette.warning.light,
    theme.palette.warning.dark,
    theme.palette.info.light,
    theme.palette.info.dark,
  ]

  useEffect(()=>{
    try{
      if(models!==null){
        console.log('models : ', JSON.stringify(models))
        // update model data
          // update chart
        console.log('models.lenth:', models['modelName'].length)

        const modelList = models['modelName'].reduce((model, current)=>{
          model.push(current)
          return model
        },[])

        // for (var i = 0; i < models['modelName'].length; i++) {
        //   if(models['modelName'][i])
        //     modelList.push(models['modelName'][i])
        //   // if (models['modelName'][i]==="NTR-10PW") {
        //   //   modelList.push("NTR-10PW");
        //   // } else if(models['modelName'][i]==="NTR-10PW 2nd"){
        //   //   modelList.push("NTR-10PW 2nd");
        //   // } else if(models['modelName'][i]==="NRT-20D"){
        //   //   modelList.push("NRT-20D");
        //   // } else if(models['modelName'][i]==="NRT-20L"){
        //   //   modelList.push("NRT-20L");
        //   // } else if(models['modelName'][i]==="NRT-21D"){
        //   //   modelList.push("NRT-21D");
        //   // } else if(models['modelName'][i]==="NRT-20DS"){
        //   //   modelList.push("NRT-20DS");
        //   // } else if(models['modelName'][i]==="NRT-20DSW"){
        //   //   modelList.push("NRT-20DSW");
        //   // } else if(models['modelName'][i]==="NRT-30L"){
        //   //   modelList.push("NRT-30L");
        //   // }  else if(models['modelName'][i]==="NRT-30S"){
        //   //   modelList.push("NRT-30S");
        //   // }
        // }

        console.log('modelList:', modelList)
        setModelsChart(modelsChart=>({
            ...modelsChart,
            datasets: [
              {
                data: models['modelCount'],
                backgroundColor,
                borderWidth: 8,
                borderColor: theme.palette.white,
                hoverBorderColor: theme.palette.white
              }
            ],
            // labels: modelList
            labels: models['modelName']
          }));

          let sum = models['modelCount'].reduce((value, current) => {
            value += current;
            return value
          },0)
          // let sum = models['modelCount'][0] + models['modelCount'][1] + models['modelCount'][2]
          //           + models['modelCount'][3] + models['modelCount'][4] + models['modelCount'][5]
          //           + models['modelCount'][6] + models['modelCount'][7] + models['modelCount'][8] + models['modelCount'][9];

          console.log('sum:   ', sum)

          // var value0=0;
          // var value1=0;
          // var value2=0;
          // var value3=0;
          // var value4=0;
          // var value5=0;
          // var value6=0;
          // var value7=0;
          // var value8=0;
          // var value9=0;
          // if(sum!==0){
          //   value0 = Math.floor(models['modelCount'][0] * (100 / (sum)));
          //   value1 = Math.floor(models['modelCount'][1] * (100 / (sum)));
          //   value2 = Math.floor(models['modelCount'][2] * (100 / (sum)));
          //   value3 = Math.floor(models['modelCount'][3] * (100 / (sum)));
          //   value4 = Math.floor(models['modelCount'][4] * (100 / (sum)));
          //   value5 = Math.floor(models['modelCount'][5] * (100 / (sum)));
          //   value6 = Math.floor(models['modelCount'][6] * (100 / (sum)));
          //   value7 = Math.floor(models['modelCount'][7] * (100 / (sum)));
          //   value8 = Math.floor(models['modelCount'][8] * (100 / (sum)));
          //   value9 = Math.floor(models['modelCount'][9] * (100 / (sum)));
          // }
          console.log('changedModelName : '+modelList);

          let modelDataResult = modelList.reduce((model, current, index)=>{
            model.push({
              title: current,
              value: Math.floor(models['modelCount'][index] * (100 / (sum))),
              color: backgroundColor[index],
              icon: <ToysIcon className={classes.spinningImag} style={{ color: backgroundColor[index] }}/>,
            })
            return model
          },[])

          setModelData(
            ...modelData,
            modelDataResult
          )
            // [
            //   {
            //     title: modelList[0],
            //     value: value0,
            //     color: theme.palette.primary.main,
            //     icon: <ToysIcon className={classes.spinningImag} style={{ color: theme.palette.primary.main }}/>,
            //   },
            //   {
            //     title: modelList[1],
            //     value: value1,
            //     color: theme.palette.info.main,
            //     icon: <ToysIcon className={classes.spinningImag} style={{ color: theme.palette.info.main }}/>,
            //   },
            //   {
            //     title: modelList[2],
            //     value: value2,
            //     color: theme.palette.error.main,
            //     icon: <ToysIcon className={classes.spinningImag} style={{ color: theme.palette.error.main }}/>,
            //   },
            //   {
            //     title: modelList[3],
            //     value: value3,
            //     color: theme.palette.info.main,
            //     icon: <ToysIcon className={classes.spinningImag} style={{ color: theme.palette.error.light }}/>,
            //   },
            //   {
            //     title: modelList[4],
            //     value: value4,
            //     color: theme.palette.error.main,
            //     icon: <ToysIcon className={classes.spinningImag} style={{ color: theme.palette.error.dark }}/>,
            //   },
            //   {
            //     title: modelList[5],
            //     value: value5,
            //     color: theme.palette.info.main,
            //     icon: <ToysIcon className={classes.spinningImag} style={{ color: theme.palette.warning.main }}/>,
            //   },
            //   {
            //     title: modelList[6],
            //     value: value6,
            //     color: theme.palette.error.main,
            //     icon: <ToysIcon className={classes.spinningImag} style={{ color: theme.palette.warning.light }}/>,
            //   },
            //   {
            //     title: modelList[7],
            //     value: value7,
            //     color: theme.palette.info.main,
            //     icon: <ToysIcon className={classes.spinningImag} style={{ color: theme.palette.warning.dark }}/>,
            //   },
            //   {
            //     title: modelList[8],
            //     value: value8,
            //     color: theme.palette.error.main,
            //     icon: <ToysIcon className={classes.spinningImag} style={{ color: theme.palette.info.light }}/>,
            //   },
            //   {
            //     title: modelList[9],
            //     value: value9,
            //     color: theme.palette.info.main,
            //     icon: <ToysIcon className={classes.spinningImag} style={{ color: theme.palette.info.dark }}/>,
            //   }
            // ]
        }

    }catch (e) {
      console.log('get models count failed: '+JSON.stringify(e));
    }

  }, [models, state]);

  const options = {
    legend: {
      display: false
    },
    responsive: true,
    maintainAspectRatio: false,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      borderWidth: 1,
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.white,
      titleFontColor: theme.palette.text.primary,
      bodyFontColor: theme.palette.text.secondary,
      footerFontColor: theme.palette.text.secondary
    }
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        title={status==="online"?STRING_ONLINE_NAME:STRING_ALL_NAME}
      />
      <Divider />
      <CardContent>
        <div className={classes.chartContainer}>
          {modelsChart!==null &&
            <Doughnut
              data={modelsChart}
              options={options}
            />
          }
        </div>
        <div className={classes.stats}>
          {modelData!==null && Children.toArray(modelData.map(device => (
            <div
              className={classes.device}
              key={device.title}
            >
              <span className={classes.spinningImag}>{device.icon}</span>
              <Typography variant="body2">{device.title}</Typography>
              <Typography
                style={{ color: device.color }}
                variant="h6"
              >
                {device.value}%
              </Typography>

            </div>
          )))}
        </div>
      </CardContent>
    </Card>
  );
};

RoomconModelCounter.propTypes = {
  className: PropTypes.string
};

export default RoomconModelCounter;
