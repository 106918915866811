import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
} from '@material-ui/core';

import {
  STRING_USER_GUBUN,
  STRING_DATE_FORMAT,
  STRING_USER_LOGIN_AT,
  STRING_USER_LOGIN_IP,
  STRING_USER_HANDLE_ID,
  STRING_CHANGE_FIELD,
  STRING_USER_ID,
  STRING_FEATURE_DETAIL,
} from '../../../StringTable/StringTable';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  deviceView: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  deviceDescriptView: {
    display: 'flex',
    flexDirection: 'column'
  },
  deviceDescriptDivider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

const RecordTable = props => {
  const { className, records, callbackFunc, sendPageInfo, initRowsPerPage, initPage, ...rest } = props;
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = useState(initRowsPerPage);
  const [page, setPage] = useState(initPage);


  const handlePageChange = (event, page) => {
    setPage(page);
    sendPageInfo(rowsPerPage * page, rowsPerPage);
  };

  const handleRowsPerPageChange = event => {
    setPage(0);
    setRowsPerPage(event.target.value);
    sendPageInfo(0, event.target.value);
  };

  return (
    <div>
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardContent className={classes.content}>
          {records.data.totalCount > 0 &&
            <b>{" 검색 전체 : " + records.data.totalCount + "명"}</b>
          }
          <PerfectScrollbar>
            <div>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align={'center'}><span><b>NO.</b></span></TableCell>
                    <TableCell align={'center'}><span><b>{STRING_USER_GUBUN}</b></span></TableCell>
                    <TableCell align={'center'}><span><b>{STRING_USER_ID}</b></span></TableCell>
                    <TableCell align={'center'}><span><b>{STRING_USER_LOGIN_AT}</b></span></TableCell>
                    <TableCell align={'center'}><span><b>{STRING_USER_LOGIN_IP}</b></span></TableCell>
                    <TableCell align={'center'}><span><b>{STRING_USER_HANDLE_ID}</b></span></TableCell>
                    <TableCell align={'center'}><span><b>{STRING_FEATURE_DETAIL}</b></span></TableCell>
                    <TableCell align={'center'}><span><b>{STRING_CHANGE_FIELD}</b></span></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {records !== null && records !== undefined && records.data.records.map((record, index) => (
                    <TableRow
                      className={classes.tableRow}
                      hover
                      key={index}
                    >
                      <TableCell align={'center'}>
                        {record.handlerType ? ++index + page * rowsPerPage : '-'}
                      </TableCell>
                      <TableCell align={'center'}>
                        {record.handlerType ? record.handlerType : '-'}
                      </TableCell>
                      <TableCell align={'center'}>
                        {record.handlerId ? record.handlerId : '-'}
                      </TableCell>
                      <TableCell align={'center'}>
                        {record.registerAt ? moment(record.registerAt * 1000).format(STRING_DATE_FORMAT) : '-'}
                      </TableCell>
                      <TableCell align={'center'}>
                        {record.ip ? record.ip : '-'}
                      </TableCell>
                      <TableCell align={'center'}>
                        {record.userId ? record.userId : '-'}
                      </TableCell>
                      <TableCell align={'center'}>
                        {record.meunu ? record.meunu : '' + record.feature}
                      </TableCell>
                      <TableCell align={'center'}>
                        {record.featureSub ? record.featureSub : '-'}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={records !== undefined ? records.data.totalCount : 0}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[10, 50, 100]}
          />
        </CardActions>
      </Card>
    </div>

  );
};

RecordTable.propTypes = {
  className: PropTypes.string,
  // records: PropTypes.array.isRequired
};

export default RecordTable;

