import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  content: {
    paddingTop: 150,
    textAlign: 'center'
  },
  image: {
    marginTop: 50,
    display: 'inline-block',
    maxWidth: '100%',
    width: 1024
  }
}));

const Planning = ({history}) => {
  const classes = useStyles();

  useEffect(()=>{
    if(sessionStorage.getItem('naviwatch_permission')!=="true")
      history.push('/')
  },[]);

  return (
    <div className={classes.root}>
      <Grid
        container
        justifyContent="center"
        spacing={4}
      >
        <Grid
          item
          lg={6}
          xs={12}
        >
          <div className={classes.content}>
            <Typography variant="h1">
              We are currently planning this page.
            </Typography>
            <Typography variant="subtitle2">
              Please wait more time to us :-)
            </Typography>
            <img
              alt="Under development"
              className={classes.image}
              src="/images/Planning.png"
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Planning;
