import axios from 'axios';
import { deploy } from 'config';
import AWS from 'aws-sdk';

var ApiInstance = null;

if (deploy === 'dev') {
  ApiInstance = axios.create({
    baseURL: 'https://nskr-dev.naviensmartcontrol.com'
  });
} else if (deploy === 'stage') {
  ApiInstance = axios.create({
    baseURL: 'https://nskr-stg.naviensmartcontrol.com'
  });
} else if (deploy === 'prod') {
  ApiInstance = axios.create({
    baseURL: 'https://nskr.naviensmartcontrol.com'
  });
}

export async function getSts() {
  // use naviwatch(mate-dev) user's credentials
  var logins = {};
  logins[
    'cognito-idp.' +
      'ap-northeast-2' +
      '.amazonaws.com/' +
      'ap-northeast-2_pczJUEhHi'
  ] = sessionStorage.getItem('idToken');

  AWS.config.credentials = new AWS.CognitoIdentityCredentials(
    {
      IdentityPoolId: 'ap-northeast-2:a7b28af1-d655-458e-b37c-2dcf225cc7a7',
      Logins: logins
    },
    { region: 'ap-northeast-2' }
  );
  console.log(AWS.config.credentials);

  AWS.config.credentials.clearCachedId();

  // get navien smart role using STS
  const client = new AWS.STS({ region: 'ap-northeast-2' });
  let params = {
    RoleArn: '',
    RoleSessionName: ''
  };

  if (deploy === 'dev') {
    params = {
      RoleArn:
        'arn:aws:iam::129207394945:role/nskr-dev-iam-role-naviwatch-cross-access-ex',
      RoleSessionName: 'naviwatch-sts'
    };
  } else if (deploy === 'stage') {
    params = {
      RoleArn:
        'arn:aws:iam::034602161211:role/nskr-stage-iam-role-naviwatch-cross-access-ex',
      RoleSessionName: 'naviwatch-sts'
    };
  } else if (deploy === 'prod') {
    params = {
      RoleArn:
        'arn:aws:iam::427344857081:role/nskr-prod-iam-role-naviwatch-cross-access-ex',
      RoleSessionName: 'naviwatch-sts'
    };
  }

  const assumeRole = await client.assumeRole(params).promise();
  console.log('Changed Credentials, role:' + JSON.stringify(params));

  const accessparams = {
    accessKeyId: assumeRole.Credentials.AccessKeyId,
    secretAccessKey: assumeRole.Credentials.SecretAccessKey,
    sessionToken: assumeRole.Credentials.SessionToken
  };
  AWS.config.update(accessparams);
  return accessparams;
}

export async function pushSend(data) {
  var response = undefined;
  let reqUrl = `api/backoffice/v1.0/push/send-notification`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.post(reqUrl, data, config);
  return response.data;
}

export async function getTotalCount() {
  var response = undefined;
  let reqUrl = `api/backoffice/v1.0/users/get-total-count`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken'),
      Authorization: sessionStorage.getItem('accessToken')
    },
    params: {
      naviWatchId: sessionStorage.getItem('email')
    }
  };
  response = await ApiInstance.get(reqUrl, config);

  console.log(response.data);

  return response.data;
}

export async function getUsers(userId, userEmail, userName, offset) {
  var response = undefined;
  let reqUrl = `api/backoffice/v1.0/users`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken'),
      Authorization: sessionStorage.getItem('accessToken')
    },
    params: {
      naviWatchId: sessionStorage.getItem('email'),
      userId,
      userEmail,
      userName,
      offset
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function getFamily(userSeq) {
  var response = undefined;
  let reqUrl = `api/backoffice/v1.0/users/${userSeq}/family`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken'),
      Authorization: sessionStorage.getItem('accessToken')
    },
    params: {
      naviWatchId: sessionStorage.getItem('email')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function getDevices(userSeq) {
  var response = undefined;
  let reqUrl = `api/backoffice/v1.0/users/${userSeq}/devices`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken'),
      Authorization: sessionStorage.getItem('accessToken')
    },
    params: {
      naviWatchId: sessionStorage.getItem('email')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function dashboardGetSignUpCountByPlatform() {
  var response = undefined;
  let reqUrl = `api/backoffice/v1.0/dashboard/signup`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);

  return response.data.data;
}

export async function dashboardGetSignInCountByPlatform() {
  var response = undefined;
  let reqUrl = `api/backoffice/v1.0/dashboard/signin`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);

  return response.data.data;
}

export async function dashboardGetMobileActiveCount() {
  var response = undefined;
  let reqUrl = `api/backoffice/v1.0/dashboard/realtime`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);

  return response.data.data;
}

export async function dashboardGetUserIncrease(day, basis) {
  var response = undefined;
  let reqUrl = `api/backoffice/v1.0/dashboard/signup-chart?day=${day}&basis=${basis}`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);

  console.log(response.data.data);
  return response.data.data;
}

export async function getAirOneAppHistoryReport(deviceId, from) {
  let reqUrl = `api/backoffice/v1.0/device/app-history?deviceId=${deviceId}&from=${from}`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  const response = await ApiInstance.get(reqUrl, config);
  return response;
}

// Annoucement - 조회, 추가, 수정, 삭제
export async function searchAnnouncement(noticode, from, to) {
  var response = undefined;
  let reqUrl = `api/backoffice/v1.0/announcement?notificationCode=${noticode}&startDate=${from +
    ':00'}&endDate=${to + ':00'}`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response;
}

export async function addAnnouncement(
  notiCode,
  message,
  title,
  link,
  imageUrl,
  push
) {
  var response = undefined;
  let reqUrl = `api/backoffice/v1.0/announcement`;
  let data = JSON.stringify({
    'body-json': {
      notificationCode: notiCode,
      message: message,
      parameters: {
        notiCode: notiCode,
        template: {
          title: title,
          description: message,
          link: link,
          imageUrl: imageUrl,
          buttons: [
            {
              title: '상세보기'
            }
          ]
        }
      },
      push: push
    }
  });
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.post(reqUrl, data, config);
  console.log('response data is ' + JSON.stringify(response.data));
  return response.data;
}

export async function modifyAnnouncement(
  notiCode,
  message,
  title,
  link,
  imageUrl,
  registerAt
) {
  var response = undefined;
  let reqUrl = `api/backoffice/v1.0/announcement`;
  let data = {
    notificationCode: notiCode,
    message: message,
    parameters: {
      notiCode: notiCode,
      template: {
        title: title,
        description: message,
        link: link,
        imageUrl: imageUrl,
        buttons: [
          {
            title: '상세보기'
          }
        ]
      }
    },
    registerAt: registerAt
  };
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.put(reqUrl, data, config);
  console.log('response data is ' + JSON.stringify(response.data));
  return response.data;
}

export async function deleteAnnouncement(content, registerAt, endCallback) {
  console.log('content' + content);
  console.log('registerAt' + registerAt);
  var response = undefined;
  let reqUrl = `api/backoffice/v1.0/announcement/${content}/${registerAt}`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  console.log('reqUrl is ' + reqUrl);
  response = await ApiInstance.delete(reqUrl, config);
  endCallback();
  console.log('response is ' + JSON.stringify(response));
  console.log('response data is ' + JSON.stringify(response.data));
  return response.data;
}

export async function getSleepSessionList({ values }) {
  let params = {
    naviWatchId: sessionStorage.getItem('email'),
    dateGte: values.from.split('T')[0],
    dateLte: values.to.split('T')[0]
  };

  let reqUrl = `api/backoffice/v1.0/upsleep`;
  if (values?.userId) {
    params.userId = values.userId;
  }
  if (values?.userSeq) {
    params.userSeq = values.userSeq;
  }
  let eventTypeList = [];
  if (values.eventTypeList) {
    if (values.eventTypeList[0]) {
      eventTypeList.push('SESS#COMP');
    }
    if (values.eventTypeList[1]) {
      eventTypeList.push('SESS#FAIL');
    }
  }
  params.eventTypeList = encodeURI(eventTypeList);
  let apiKeyList = [];
  if (values.apiKeyList) {
    if (values.apiKeyList[0]) {
      apiKeyList.push(
        deploy == 'prod'
          ? 'RqSxny35gjBTYAMQs6Fyz2ZARJIjgyR4VrjR7BdG'
          : '8FZOIRp1qw0ROF6d5zLTeDwK7TQ9hNa083OBfuZc'
      );
    }
    if (values.apiKeyList[1]) {
      apiKeyList.push(
        deploy == 'prod'
          ? '9JBivrvDnD3FxUj0oJ2yEOzesminhkN7DbOUJxHo'
          : '1wp8ItfdEzG8frthLpnXluuPXF0jc3EXjwv5Zo3y'
      );
    }
  }
  params.apiKeyList = encodeURI(apiKeyList);

  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken'),
      Authorization: sessionStorage.getItem('accessToken')
    },
    params
  };
  const response = await ApiInstance.get(reqUrl, config);
  return response;
}

export async function getSleepSessionDetail(values) {
  console.log(values);
  let reqUrl = `api/backoffice/v1.0/upsleep/${values.sessionId}`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken'),
      Authorization: sessionStorage.getItem('accessToken')
    },
    params: {
      naviWatchId: sessionStorage.getItem('email'),
      userSeq: values.userSeq
    }
  };
  const response = await ApiInstance.get(reqUrl, config);
  return response;
}

export async function getSleepStatistics(values) {
  console.log(values);
  let reqUrl = `api/backoffice/v1.0/upsleep/statistics`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken'),
      Authorization: sessionStorage.getItem('accessToken')
    },
    params: {
      naviWatchId: sessionStorage.getItem('email'),
      from: values.from,
      to: values.to
    }
  };
  const response = await ApiInstance.get(reqUrl, config);
  return response;
}

export async function getDeviceLog(values) {
  let reqUrl = `api/backoffice/v1.0/device/log/${values.deviceSeq}`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken'),
      Authorization: sessionStorage.getItem('accessToken')
    },
    params: {
      naviWatchId: sessionStorage.getItem('email'),
      ...values
    }
  };

  const response = await ApiInstance.get(reqUrl, config);
  return response.data;
}
