import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import DeviceHistoryToolbar from './components/DeviceHistoryToolbar';
import DeviceHistoryTable from './components/DeviceHistoryTable';
import { Backdrop, CircularProgress, Typography } from '@material-ui/core';
import { getAirOneAppHistoryReport } from '../APIs/smartApis';
import {
  NO_PERMISSION,
  NO_PERMISSION_WARNING,
  READ_PERMISSION
} from '../../Common/Config/naviwatchConfig';
import { userInfoContext } from '../../../App';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));

const TableType = {
  CONNECTION: 'Connection',
  DEVICE: 'Device',
  CONTROL: 'Control'
};

const NsAironeDeviceHistory = ({ history }) => {
  const classes = useStyles();
  const [noPermissionMsg, setNoPermissionMsg] = useState('');
  const authInfo = useContext(userInfoContext);
  const [permission, setPermission] = useState(NO_PERMISSION);
  const [loading, setLoading] = useState(false);
  const [historyData, setHistoryData] = useState([]);

  useEffect(() => {
    var pageInfo = sessionStorage.getItem('pages');
    if (pageInfo !== 'undefined') {
      var pageInfoObj = JSON.parse(pageInfo);
      var pageArray = pageInfoObj.filter(page =>
        page.name.toLowerCase().includes('device')
      );
      if (pageArray !== undefined && pageArray.length > 0) {
        var page = pageArray[0];
        if (page.permission === undefined) {
          setNoPermissionMsg(NO_PERMISSION_WARNING);
        }
        setPermission(page.permission);
      }
    }
  }, [authInfo.pages]);

  const getTopicType = topic => {
    let type = TableType.CONTROL;
    if (topic.includes('connected')) {
      type = TableType.CONNECTION;
    } else if (
      topic.includes('dt/rc') ||
      topic.includes('sync') ||
      topic.includes('register') ||
      topic.includes('/res')
    ) {
      type = TableType.DEVICE;
    }

    return type;
  };

  const getHistory = async (id, from) => {
    setLoading(true);
    const formatData = [];
    try {
      const response = await getAirOneAppHistoryReport(id, from);
      if (response) {
        if (response.status == 200) {
          const responseDataObj = JSON.parse(response.data.data);
          responseDataObj.forEach(data => {
            console.log(data);
            const format = {
              date: data.date,
              type: data.type,
              topic: data.topic,
              detail: data
            };
            // format.type = getTopicType(data.requestTopic);
            formatData.push(format);
          });
        } else {
          console.log(response);
          alert(response.status + '\n' + response.statusText);
        }
      } else {
        console.log(response);
      }
    } catch (e) {
      console.log(e);
    }
    setHistoryData(formatData);
    setLoading(false);
  };

  useEffect(() => {
    if (sessionStorage.getItem('naviwatch_permission') !== 'true')
      history.push('/');
  }, []);

  return (
    <div>
      {permission >= READ_PERMISSION ? (
        <div className={classes.root}>
          <Backdrop className={classes.backdrop} open={loading}>
            <CircularProgress color={'primary'} />
          </Backdrop>
          <DeviceHistoryToolbar callbackFunc={getHistory} />
          <div className={classes.content}>
            {loading === false &&
              historyData !== null &&
              historyData.length > 0 && (
                <DeviceHistoryTable historyData={historyData} />
              )}
          </div>
        </div>
      ) : (
        <Typography className={classes.root}>{noPermissionMsg}</Typography>
      )}
    </div>
  );
};

export default NsAironeDeviceHistory;
