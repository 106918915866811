import { List, ListItem, ListItemText, Menu, MenuItem, Toolbar, Typography } from '@material-ui/core';
import Products from '../../../../../../product';
import React, {useState} from 'react';
import PropTypes from 'prop-types';


const ValueView = props => {
  const { className, items, selectedState, setSelectedState, choiceCallback, ...rest } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };


  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (event, state) => {
    setSelectedState(state);
    console.log('index set to :'+ state)
    setAnchorEl(null);
    choiceCallback();
  };

  return (
    <div>
      <List component="nav" aria-label="Device settings">
        <ListItem
          button
          aria-haspopup="true"
          aria-controls="lock-menu"
          onClick={handleClickListItem}
        >
          <ListItemText primary={<Typography color={'primary'}> {items[selectedState].value} </Typography>}/>
        </ListItem>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {items.map((item, state) => (
          <MenuItem
            key={state}
            selected={state === selectedState}
            onClick={(event) => handleMenuItemClick(event, state)}
          >
            {item.value}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};


ValueView.propTypes = {
  className: PropTypes.string
};

export default React.memo(ValueView);
