import React, { useContext, useEffect, useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Backdrop, CircularProgress, Typography } from '@material-ui/core';
import publicIp from 'public-ip';
import { useRecoilValue } from 'recoil';

import { navienSmartUserOffset } from '../../../common/mainStore';
import { UsersToolbar, UsersTable } from './components';
import useAsync from '../../../asyncNet';
import { getUsers } from '../APIs/smartApis';
import { userInfoContext } from '../../../App';
import { NO_PERMISSION, NO_PERMISSION_WARNING, READ_PERMISSION, NO_WHITELIST_INSIDE_WARNING } from '../../Common/Config/naviwatchConfig';
import { naviwatchCheckWhiteListValidation } from '../../Common/APIs/naviwatchApis';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));

const User = ({ history }) => {
  const classes = useStyles();
  const offset = useRecoilValue(navienSmartUserOffset); // navienSmart Paging Offset
  const [users, setUsers] = useState([])
  const [totalCount, setTotalCount] = useState(0)

  const [query, setQuery] = useState({
    userId: '',
    userEmail: '',
    userName: ''
  });


  async function getUserSearch (){
    return await getUsers(query.userId, query.userEmail, query.userName, offset);
  }

  const [userState, trySearchUser] = useAsync(getUserSearch, [], true);
  const { loading: userSearchLoading, data: navienSmartUserData } = userState;

  // search user when offset bigger than 0 (second search)
  useEffect(()=>{
    if(offset > 0)
    trySearchUser()
  },[offset])

  // modify users state when search data fetch
  useEffect(()=>{
    if(navienSmartUserData){
      setUsers(prevState =>[...prevState, ...navienSmartUserData.data.users])
      setTotalCount(navienSmartUserData.data.totalCount)
    }

  },[navienSmartUserData])

  const [ipCheckState, tryCheckWhitelist] = useAsync(checkIpValidation, [], true);
  const { loading: ipCheckLoading, data: ipValid } = ipCheckState;

  const [ipAddress, setIpAddress] = useState(undefined);
  const [noPermissionMsg, setNoPermissionMsg] = useState("");
  const [permission, setPermission] = useState(NO_PERMISSION);
  const authInfo =  useContext(userInfoContext);

  useEffect(() => {
    var pageInfo = sessionStorage.getItem('pages');
    if(pageInfo !== "undefined") {
      var pageInfoObj = JSON.parse(pageInfo);
      var pageArray = pageInfoObj.filter(page => page.name.toLowerCase().includes("user"));
      if (pageArray !== undefined && pageArray.length > 0) {
        var page = pageArray[0];
        if(page.permission===undefined){
          setNoPermissionMsg(NO_PERMISSION_WARNING);
        }
        setPermission(page.permission);
      }
    }
  }, [authInfo.pages]);

  async function checkIpValidation() {
    return naviwatchCheckWhiteListValidation(ipAddress);
  }

  const searchUsers = (searchdata) => {
    setQuery(
      {
        userId: searchdata.userId,
        userEmail: searchdata.userEmail,
        userName: searchdata.userName
      });
  };

  useEffect(() => {
    if (query.userId !== '' || query.userEmail !== '' || query.userName !== '') {
      trySearchUser();
    }
  }, [query]);

  useEffect(() => {
    if(ipCheckState.data === false){
      setNoPermissionMsg(NO_WHITELIST_INSIDE_WARNING+`(${ipAddress})`);
    }
  }, [ipCheckState]);

  useEffect(() => {
    if(ipAddress !== undefined){
      tryCheckWhitelist();
    }
  }, [ipAddress]);

  useEffect(() => {
    if (sessionStorage.getItem('naviwatch_permission') !== 'true')
      history.push('/');
    (async () => {
      setIpAddress(await publicIp.v4());
    })();
  }, []);

  return (
    <div>
      { permission >= READ_PERMISSION && ipValid === true ?
        <div className={classes.root}>
          <Backdrop className={classes.backdrop} open={userSearchLoading||ipCheckLoading}>
            <CircularProgress color={'primary'}/>
          </Backdrop>
          <UsersToolbar callbackFunc={searchUsers}/>
          <div className={classes.content}>
            {
               users !== null && users !== undefined && users.length>0 && <UsersTable users={users} totalCount={totalCount}/>
            }
          </div>
        </div>:
        <Typography className={classes.root}>{noPermissionMsg}</Typography>
      }
    </div>
  );
};

export default User;
