import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { UsersToolbar, UsersTable } from './components';
import {requestSearchUser} from "../APIs/tokapis";

import useAsync from '../../../asyncNet';
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const UserList = ({history}) => {
  const classes = useStyles();
  useEffect(()=>{
    if(sessionStorage.getItem('naviwatch_permission')!=="true")
      history.push('/')
  },[]);

  const [users, usersSet] = useState([]);
  const [searchText, searchTextSet] = useState('');
  const [userListData, requestSearchUserList] = useAsync(() => requestSearchUser(searchText), [searchText], true);

  useEffect(()=>{
    if(searchText.length >= 2) {
      requestSearchUserList();
    }
  },[searchText]);

  useEffect(()=> {
    const {loading, data: userList, error} = userListData;

    if (loading == false && error == null && userList != undefined) {
      usersSet(userList.data);
    }
  },[userListData]);

  return (
    <div className={classes.root}>
      <UsersToolbar onRequestSearchUser={searchTextSet}/>
      <div className={classes.content}>
        <UsersTable users={users} />
      </div>
    </div>
  );
};

export default UserList;
