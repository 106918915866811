import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Grid, Typography, Avatar } from '@material-ui/core';
import useAsync from '../../../../../asyncNet';
import PersonIcon from '@material-ui/icons/Person';
import MemoryIcon from '@material-ui/icons/Memory';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import MobileFriendlyIcon from '@material-ui/icons/MobileFriendly';
import { getDeviceCount, getUserCount, getConnectUserCount } from '../../../APIs/tokapis';
import {
  STRING_REALTIME_MOBILE_CONNECT,
  STRING_DEVICE,
  STRING_24H,
  STRING_WEEK,
  STRING_MONTH,
  STRING_MEMBER,
  STRING_DEVICE_USER,
  STRING_DORMANT_USER,
  STRING_USER,
  STRING_CONNECT_USER,
  STRING_ALL,
  STRING_ONLINE,
  STRING_OFFLINE,
  STRING_STATUS_CON1,
  STRING_STATUS_CON2,
  STRING_STATUS_DB,
  STRING_STATUS_AGS,
  STRING_MOBILE_IOS,
  STRING_MOBILE_ANDROID
} from '../../../StringTable/StringTable';


const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    width: '100%',
    height: '100%'
  },
  title: {
    fontWeight: 700
  },
  mobileAvatar: {
    backgroundColor: theme.palette.success.main,
    height: 56,
    width: 56
  },
  deviceAvatar: {
    backgroundColor: theme.palette.warning.main,
    height: 56,
    width: 56
  },
  userAvatar: {
    backgroundColor: theme.palette.info.main,
    height: 56,
    width: 56
  },
  errorAvatar: {
    backgroundColor: theme.palette.secondary.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  mobileDifferenceIcon: {
    color: theme.palette.success.dark
  },
  mobileRoot: {
    color: theme.palette.success.light
  },
  deviceDifferenceIcon: {
    color: theme.palette.warning.dark
  },
  deviceRoot: {
    color: theme.palette.warning.light
  },
  userDifferenceIcon: {
    color: theme.palette.info.dark
  },
  userRoot: {
    color: theme.palette.info.light
  },
  errorDifferenceIcon: {
    color: theme.palette.secondary.dark
  },
  errorRoot: {
    color: theme.palette.secondary.light
  }
}));


const RealtimeIcon = props => {
  //console.log('type : '+JSON.stringify(type));
  if (props.type === 'appUser')
    return (<MobileFriendlyIcon className={props.classname} />);
  else if (props.type === 'device')
    return (<MemoryIcon className={props.classname} />);
  else if (props.type === 'user')
    return (<PersonIcon className={props.classname} />);
  else if (props.type === 'connectUser')
    return (<NotificationImportantIcon className={props.classname} />);
  else if (props.type === 'status')
    return (<CheckCircleOutlineIcon className={props.classname} />);
  else
    return null;
};

const RealtimeCounter = props => {
  const { className, type, ...rest } = props;

  const getCountFunction = () => {
    // console.log('call getCountFunction');
    if (type === 'appUser') {
      //TODO : Add api
      return;
    } else if (type === 'device') {
      return getDeviceCount();
    } else if (type === 'user') {
      return getUserCount();
    } else if (type === 'connectUser') {
      return getConnectUserCount();
    }
  };
  const [state, tryRefetch] = useAsync(getCountFunction, []);
  const { loading, data: count, error } = state;
  const [firstFieldCountValue, setFirstFieldCountValue] = useState(null);
  const [secondFieldCountValue, setSecondFieldCountValue] = useState(null);
  const [thirdFieldCountValue, setThirdFieldCountValue] = useState(null);
  const [fourthFieldCountValue, setFourthFieldCountValue] = useState(null);
  const classes = useStyles();

  var counterTitle;
  var firstTitle;
  var secondTitle;
  var thirdTitle;
  var classesIcon;
  var classesAvatar;
  var classesRoot;

  if (type === 'appUser') {
    counterTitle = STRING_REALTIME_MOBILE_CONNECT;
    firstTitle = STRING_ALL;
    secondTitle = STRING_MOBILE_IOS;
    thirdTitle = STRING_MOBILE_ANDROID;
    classesRoot = classes.mobileRoot;
    classesIcon = classes.mobileDifferenceIcon;
    classesAvatar = classes.mobileAvatar;
  } else if (type === 'device') {
    counterTitle = STRING_DEVICE;
    firstTitle = STRING_ALL;
    secondTitle = STRING_ONLINE;
    thirdTitle = STRING_OFFLINE;
    classesRoot = classes.deviceRoot;
    classesIcon = classes.deviceDifferenceIcon;
    classesAvatar = classes.deviceAvatar;
  } else if (type === 'user') {
    counterTitle = STRING_MEMBER;
    firstTitle = STRING_ALL;
    secondTitle = STRING_USER;
    thirdTitle = STRING_DORMANT_USER;
    classesRoot = classes.userRoot;
    classesIcon = classes.userDifferenceIcon;
    classesAvatar = classes.userAvatar;
  } else if (type === 'connectUser') {
    counterTitle = STRING_CONNECT_USER;
    firstTitle = STRING_24H;
    secondTitle = STRING_WEEK;
    thirdTitle = STRING_MONTH;
    classesRoot = classes.errorRoot;
    classesIcon = classes.errorDifferenceIcon;
    classesAvatar = classes.errorAvatar;
  } else if (type === 'status') {
    counterTitle = STRING_STATUS_CON1;
    firstTitle = STRING_STATUS_CON2;
    secondTitle = STRING_STATUS_DB;
    thirdTitle = STRING_STATUS_AGS;
  }


  useEffect(() => {
    try {
      if (count !== null) {
        if (type === 'appUser') {
          setFirstFieldCountValue(count['totalCount']);
          setSecondFieldCountValue(count['iOSCount']);
          setThirdFieldCountValue(count['androidCount']);
        } else if (type === 'device') {
          setFirstFieldCountValue(count['totalCount']);
          setSecondFieldCountValue(count['onlineCount']);
          setThirdFieldCountValue(count['offlineCount']);
        } else if (type === 'user') {
          setFirstFieldCountValue(count['totalCount']);
          setSecondFieldCountValue(count['userCount']);
          setThirdFieldCountValue(count['dormantCount']);
          setFourthFieldCountValue(count['deviceUserCount']);
        } else if (type === 'connectUser') {
          setFirstFieldCountValue(count['dayCount']);
          setSecondFieldCountValue(count['weekCount']);
          setThirdFieldCountValue(count['monthCount']);
        }
      }
    } catch (e) {
      console.log('get count failed: ' + JSON.stringify(e));
    }
  }, [count]);

  useEffect(() => {
    const timer = setInterval(function () {
      tryRefetch();
    }, 60 * 1000);
    // console.log('setInterval intervalidx:' + timer);
    return () => {
      // console.log('clearinterval: ' + timer);
      clearInterval(timer);
    };
  }, []);


  return (
    <Card>
      <CardContent>
        <Grid
          container
          justifyContent="space-around"
        >
          <Grid item>
            <div className={classes.content}>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
                variant="body2"
              >
                {counterTitle}
              </Typography>
              <Avatar className={classesAvatar}>
                <RealtimeIcon type={type} classname={classes.icon} />
              </Avatar>
            </div>
          </Grid>
          <Grid item>
            <div className={classes.content}>
              <Typography
                className={classes.title}
                color="textSecondary"
                variant="body2"
                align="center"
              >
                {firstTitle}
              </Typography>
              <Typography
                className={classes.caption}
                variant="h4"
              >
                {firstFieldCountValue !== null ? firstFieldCountValue : '-'}
              </Typography>
            </div>
          </Grid>
          <Grid item>
            <div className={classes.content}>
              <Typography
                className={classes.title}
                color="textSecondary"
                variant="body2"
                align="center"
              >
                {secondTitle}
                {type === 'user' &&
                  <div style={{ fontsize: 5 }}>
                    {"(" + STRING_DEVICE_USER + ")"}
                  </div>
                }
              </Typography>
              <Typography
                className={classes.caption}
                variant="h4"
                align="center"
              >
                {secondFieldCountValue !== null ? secondFieldCountValue : '-'}
                {type === 'user' &&
                  <div style={{ fontSize: '0.9vw' }}>
                    {fourthFieldCountValue !== null ? "(" + fourthFieldCountValue + ")" : '-'}
                  </div>
                }
              </Typography>
            </div>
          </Grid>
          <Grid item>
            <div className={classes.content}>
              <Typography
                className={classes.title}
                color="textSecondary"
                variant="body2"
              >
                {thirdTitle}
              </Typography>
              <Typography
                className={classes.caption}
                variant="h4"
              >
                {thirdFieldCountValue !== null ? thirdFieldCountValue : '-'}
              </Typography>
            </div>
          </Grid>
        </Grid>

      </CardContent>
    </Card>
  );
};

RealtimeCounter.propTypes = {
  className: PropTypes.string
};

export default RealtimeCounter;
