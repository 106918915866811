import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography
} from '@material-ui/core';
import useAsync from '../../../../../asyncNet';
import {
  dashboardGetODUModelStatistics
} from '../../../APIs/aironeApis';
import ToysIcon from '@material-ui/icons/Toys';

const STRING_ALL_NAME='제품별 분포';
const STRING_ONLINE_NAME='온라인 기기 분포';


const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  chartContainer: {
    position: 'relative',
    height: '200px'
  },
  stats: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center'
  },
  device: {
    textAlign: 'center',
    padding: theme.spacing(1)
  },
  /*
  stats: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  device: {
    display: 'flex',
    justifyContent: 'flexStart',
    width: theme.spacing(25)
  },*/
  modelName:{
    marginRight: theme.spacing(5),
    width: theme.spacing(15)
  },
  deviceIcon: {
    color: theme.palette.icon
  },
  '@keyframes spinIcon':{
    from:{
      transform: 'rotate(0deg)',
    },
    to:{
      transform: 'rotate(360deg)'
    }
  },
  spinningImag: {
    animationName: '$spinIcon',
    animationDuration: '3s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
  },
}));

const ODUModelCounter = props => {
  const { className, status, ...rest } = props;

  const classes = useStyles();
  const theme = useTheme();
  const [state] = useAsync(() => dashboardGetODUModelStatistics(status), []);
  const { data: models } = state;
  const [modelsChart, setModelsChart] = useState({});
  const [modelData, setModelData] = useState( []);

  useEffect(()=>{
    try{
      if(models!==null){
        console.log('models : ', JSON.stringify(models))
        // update model data
          // update chart
        var modelList = ["TAC 510/550", "TAC 511/551"];
        var modelData = [0, 0];
        for (var i = 0; i < models['modelName'].length; i++) {
          if (models['modelName'][i]==="NTR-10PW" || models['modelName'][i]==="NTR-10PW 2nd") {
            modelData[0]+=models['modelCount'][i];
          } else if(models['modelName'][i]==="NRT-20D" || models['modelName'][i]==="NRT-21L"
          || models['modelName'][i]==="NRT-20DS" || models['modelName'][i]==="NRT-20DSW"
          || models['modelName'][i]==="NRT-30L" || models['modelName'][i]==="NRT-30S"){
            modelData[1]+=models['modelCount'][i];
          }
        }
        setModelsChart(modelsChart=>({
            ...modelsChart,
            datasets: [
              {
                data: modelData,
                backgroundColor: [
                  theme.palette.info.main,
                  theme.palette.primary.main,
                ],
                borderWidth: 8,
                borderColor: theme.palette.white,
                hoverBorderColor: theme.palette.white
              }
            ],
            labels: modelList
          }));

          let sum = modelData[0]+modelData[1];
          var value0=0;
          var value1=0;
          if(sum!==0){
            value0 = Math.floor(modelData[0] * (100 / (sum)));
            value1 = 100-value0;
          }
          setModelData(
            [
              {
                title: modelList[1],
                value: value1,
                color: theme.palette.primary.main,
                icon: <ToysIcon className={classes.spinningImag} style={{ color: theme.palette.primary.main }}/>,
              },
              {
                title: modelList[0],
                value: value0,
                color: theme.palette.info.main,
                icon: <ToysIcon className={classes.spinningImag} style={{ color: theme.palette.info.main }}/>,
              }
            ]
          )
        }

    }catch (e) {
      console.log('get models count failed: '+JSON.stringify(e));
    }

  }, [models]);

  const options = {
    legend: {
      display: false
    },
    responsive: true,
    maintainAspectRatio: false,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      borderWidth: 1,
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.white,
      titleFontColor: theme.palette.text.primary,
      bodyFontColor: theme.palette.text.secondary,
      footerFontColor: theme.palette.text.secondary
    }
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        title={status==="online"?STRING_ONLINE_NAME:STRING_ALL_NAME}
      />
      <Divider />
      <CardContent>
        <div className={classes.chartContainer}>
          {modelsChart!==null &&
            <Doughnut
              data={modelsChart}
              options={options}
            />
          }
        </div>
        <div className={classes.stats}>
          {modelData!==null && modelData.map(device => (
            <div
              className={classes.device}
              key={device.title}
            >
              <span className={classes.spinningImag}>{device.icon}</span>
              <Typography variant="body2">{device.title}</Typography>
              <Typography
                style={{ color: device.color }}
                variant="h2"
              >
                {device.value}%
              </Typography>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};

ODUModelCounter.propTypes = {
  className: PropTypes.string
};

export default ODUModelCounter;
