import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core/styles';
import palette from 'theme/palette';

import {
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Divider,
} from '@material-ui/core';

import useAsync from '../../../../../asyncNet';
import {
  statisticsGetPersonalization,
  statisticsGetSleepCount,
  statisticsGetWeeklyReservation
} from '../../../APIs/mateApis';

const STRING_USER_USE_PSERSONALIZATION = '사용';
const STRING_USER_NO_USE_PSERSONALIZATION = '미사용';
const STRING_USER_USE_WEEKLYRESERVATION = '사용';
const STRING_USER_NO_USE_WEEKLYRESERVATION = '미사용';
const STRING_USER_USE_SLEEP = '사용';
const STRING_USER_NO_USE_SLEEP = '미사용';
const STRING_PSERSONALIZATION = '맞춤모드';
const STRING_WEEKLYRESERVATION = '주간예약';
const STRING_SLEEP = '수면모드';


const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
  card: {
    backgroundColor: '#fff9db'
  },
  chartContainer: {
    height: '100%',
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));


async function getMetric(metricName) {
  var url = '';
  if (metricName === 'Personalization') {
    return statisticsGetPersonalization();
  } else if (metricName === 'WeeklyReservation') {
    return statisticsGetWeeklyReservation();
  } else if (metricName === 'Sleep'){
    return statisticsGetSleepCount();
  }
}

const getTitle = metricName =>{
  var title = '';
  if(metricName==='Personalization'){
    title = STRING_PSERSONALIZATION;
  }else if (metricName === 'WeeklyReservation') {
    title = STRING_WEEKLYRESERVATION;
  }else if (metricName === 'Sleep'){
    title = STRING_SLEEP
  }
  return title;
};

const ReservationCharts = props => {
  const { className, metricName, ...rest } = props;
  const [state, tryRefetch] = useAsync(() => getMetric(metricName));
  const { data: metric} = state;
  const classes = useStyles();
  const [chartdata, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: '',
        fill: false,
        backgroundColor: palette.primary.main,
        data: []
      }
    ]
  });
  const options = {
    legend: {
      display: true,
      position: "right",
      align: "center",
      fontFamily: "Allianz-Neo",
      textDirection: 'ltr',
      labels: {
        usePointStyle: true,
        fontColor: "#006192",
      }
    }
  };

  useEffect(() => {
    try {
      if (metric !== null) {
        var userNotSet = 0;
        var userSet = 0;
        console.log('count:'+metric['total'].length)
        for(var i=0; i< metric['total'].length; i++)
        {
          userNotSet += (metric['total'][i] - metric['deviceCount'][i]);
          userSet += metric['deviceCount'][i];
        }
        var label;
        var color;
        let rate=Math.round(userSet/(userNotSet+userSet)*100);
        if(metricName==="Personalization"){
          label = [STRING_USER_USE_PSERSONALIZATION +' '+rate+'%', STRING_USER_NO_USE_PSERSONALIZATION+' '+(100-rate)+'%']
          color = palette.primary.main;
        }else if(metricName==="WeeklyReservation"){
          label = [STRING_USER_USE_WEEKLYRESERVATION+' '+rate+'%', STRING_USER_NO_USE_WEEKLYRESERVATION+' '+(100-rate)+'%']
          color = palette.error.main;
        }else if(metricName === "Sleep"){
          label = [STRING_USER_USE_SLEEP+' '+rate+'%', STRING_USER_NO_USE_SLEEP+' '+(100-rate)+'%']
          color = palette.success.main;
        }
        console.log('userNotSet:' + userNotSet + ', userSet:' + userSet);
        setChartData(chardata => ({
            ...chartdata,
            labels: label,
            datasets: [
              {
                label: "",
                borderWidth: 1,
                backgroundColor: [color, palette.grey],
                data: [userSet, userNotSet]
              }]
          })
        );
      }
    } catch (e) {
      console.log('metric update failed :' + JSON.stringify(e));
    }
  }, [metric]);

  useEffect(() => {
    tryRefetch();
  }, []);

  return (
    <div>
      <Card
        {...rest}
        className={classes.root}>
        <CardHeader
          action={
            <IconButton size="small" style={{ color: 'white' }}>
            </IconButton>
          }
          title={getTitle(metricName)}
        />

        <Divider/>
        <CardContent>
          <div className={classes.chartContainer}>
            <Doughnut
              data={chartdata}
              options={options}
            />
          </div>
        </CardContent>
        <Divider/>
      </Card>
    </div>
  );
};

ReservationCharts.propTypes = {
  className: PropTypes.string
};

export default React.memo(ReservationCharts);
