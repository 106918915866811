const NO_PERMISSION = 0;
const READ_PERMISSION = 1;
const WRITE_PERMISSION = 2;

const NO_PERMISSION_WARNING ='권한이 없어 해당 페이지를 표시할 수 없습니다.';
const NO_WHITELIST_INSIDE_WARNING ='접속한 주소지에서 해당목록을 볼 수 없습니다.';


export {
  NO_PERMISSION,
  READ_PERMISSION,
  WRITE_PERMISSION,
  NO_PERMISSION_WARNING,
  NO_WHITELIST_INSIDE_WARNING
};
