import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Backdrop, Button, CircularProgress, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useAsync from '../../../../../asyncNet';
import DialogActions from '@material-ui/core/DialogActions';
import { userSendPush } from '../../../APIs/mateApis';

const STRING_PUSH_TITLE = '개별 푸시 발송';
const STRING_PUSH_CONTENT = ' 의 모바일에만 푸시를 전송합니다.';
const STRING_PUSH_TEXTFIELD_TITLE = '제목';
const STRING_PUSH_TEXTFIELD_CONTENTS = '내용';
const STRING_CLEAN = '초기화';
const STRING_PUSH = '발송';

const STRING_SUCCESS_PUSH_TITLE='푸시 발송 성공';
const STRING_SUCCESS_PUSH_CONTENT='푸시 발송이 성공적으로 완료되었습니다.';
const STRING_FAIL_PUSH_TITLE='푸시 발송 실패';
const STRING_FAIL_PUSH_CONTENT='푸시 발송이 실패하였습니다.';

const useStyles = makeStyles(theme => ({
  root: {},
  actions: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  button:{
    width:'50%',
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));

const PushDialog = props => {
  const { open, handleClose, userId} = props;
  const classes = useStyles();
  const [values, setValues] = useState({
    title: '',
    content: ''
  });

  const [state, tryRefetch] = useAsync(tryPush, [], true);
  const { loading, data: pushResult } = state;

  const [resultOpen, setResultOpen] = React.useState(false);

  const resultHandleOpen = () => {
    setResultOpen(true);
  };

  const resultHandleClose = () => {
    setResultOpen(false);
  };

  async function tryPush() {
    const response = await userSendPush(userId, values.title, values.content);
    handleClose();
    resultHandleOpen();
    return response.data;
  }

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };


  const cleanInput = () => {
    setValues({
      title: '',
      content: ''
    });
  };

  return (
    <div>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color={'primary'}/>
      </Backdrop>
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">{STRING_PUSH_TITLE}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {userId}{STRING_PUSH_CONTENT}
          </DialogContentText>
          <TextField
            margin="dense"
            name="title"
            label={STRING_PUSH_TEXTFIELD_TITLE}
            value={values.title}
            onChange={handleChange}
            fullWidth
            variant="outlined"

          />
          <TextField
            fullWidth
            multiline={true}
            rows={10}
            label={STRING_PUSH_TEXTFIELD_CONTENTS}
            margin="dense"
            name="content"
            onChange={handleChange}
            value={values.content}
            variant="outlined"
          />
          <div className={classes.actions}>
            <Button
              className={classes.button}
              color="primary"
              variant="contained"
              onClick={cleanInput}
              disabled={values.title === '' && values.content === ''}
            >
              {STRING_CLEAN}
            </Button>
            <Button
              className={classes.button}
              color="primary"
              variant="contained"
              onClick={tryRefetch}
              disabled={values.title === '' || values.content === ''}
            >
              {STRING_PUSH}
            </Button>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={resultOpen}
        onClose={resultHandleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{pushResult!==null&&pushResult!==undefined&&pushResult['code']===200?STRING_SUCCESS_PUSH_TITLE:STRING_FAIL_PUSH_TITLE}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {pushResult!==null&&pushResult!==undefined&&pushResult['code']===200?STRING_SUCCESS_PUSH_CONTENT:STRING_FAIL_PUSH_CONTENT}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={resultHandleClose} color="primary" autoFocus>
            확인
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};


PushDialog.propTypes = {
  className: PropTypes.string
};

export default PushDialog;
