import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography
} from '@material-ui/core';
import HotTubIcon from '@material-ui/icons/HotTub';
import useAsync from '../../../../../asyncNet';
import { dashboardGetModelCount } from '../../../APIs/mateApis';

const STRING_ALL_NAME = '전체 기기: ';
const STRING_ONLINE_NAME = '온라인 기기: ';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  chartContainer: {
    position: 'relative',
    width: '450px',
    height: '340px'
  },
  stats: {
    width: '40%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 50
  },
  deviceLeft: {
    width: '70px',
    textAlign: 'left'
    // display: 'flex'
  },
  device: {
    width: '70px',
    textAlign: 'right'
    // display: 'flex'
  },
  deviceIcon: {
    color: theme.palette.icon
  }
}));

const ModelCounter = props => {
  const { className, status, ...rest } = props;

  const classes = useStyles();
  const theme = useTheme();
  const [state] = useAsync(() => dashboardGetModelCount(status), []);
  const { data: models } = state;
  const [modelsChart, setModelsChart] = useState({});
  const [modelData, setModelData] = useState([]);
  const [deviceSumState, setDeviceSumState] = useState(0);
  const backgroundColor = [
    theme.palette.error.light,
    theme.palette.warning.light,
    '#EEDD33',
    theme.palette.success.light,
    theme.palette.info.light,
    '#003366',
    '#9966CC',
    '#999999'
    // theme.palette.primary.light,
    // theme.palette.warning.light,
    // theme.palette.secondary.main,
    // theme.palette.info.light,
    // theme.palette.warning.dark,
    // theme.palette.error.main,
    // theme.palette.success.main,
    // theme.palette.primary.main,
    // theme.palette.text.secondary,
    // theme.palette.chart.main
  ];

  useEffect(() => {
    try {
      if (models !== null) {
        console.log('models : ', JSON.stringify(models));
        if (models['modelName'].length >= 1) {
          setModelsChart(modelsChart => ({
            ...modelsChart,
            datasets: [
              {
                data: models['modelCount'],
                backgroundColor: backgroundColor,
                borderWidth: 8,
                borderColor: theme.palette.white,
                hoverBorderColor: theme.palette.white
              }
            ],
            labels: models['modelName']
          }));

          let sum = models['modelCount'].reduce((result, current) => {
            result += current;
            return result;
          }, 0);
          setDeviceSumState(sum);

          let usedColorIndex = 0;

          let modelDataResult = models['modelCount'].reduce(
            (model, current, index) => {
              let backgroundColorIndex = index - usedColorIndex;
              let valuePer = Math.floor(
                models['modelCount'][index] * (100 / sum)
              );
              model['modelData'].push({
                title: models['modelName'][index],
                value: models['modelCount'][index],
                valuePer: valuePer,
                color: backgroundColor[backgroundColorIndex],
                icon: (
                  <HotTubIcon
                    className={classes.spinningImag}
                    style={{ color: backgroundColor[backgroundColorIndex] }}
                  />
                )
              });
              // model['sum'] += value;
              return model;
            },
            {
              modelData: [],
              sum: 0
            }
          );

          setModelData(...modelData, modelDataResult['modelData']);
        }
      }
    } catch (e) {
      console.log('get models count failed: ' + JSON.stringify(e));
    }
  }, [models]);

  const options = {
    legend: {
      display: false
    },
    responsive: true,
    maintainAspectRatio: false,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      borderWidth: 1,
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.white,
      titleFontColor: theme.palette.text.primary,
      bodyFontColor: theme.palette.text.secondary,
      footerFontColor: theme.palette.text.secondary
    }
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader
        title={
          (status === 'online' ? STRING_ONLINE_NAME : STRING_ALL_NAME) +
          ' ' +
          deviceSumState
        }
      />
      <Divider />
      <CardContent>
        <div style={{ display: 'flex', height: '350px' }}>
          <div className={classes.chartContainer}>
            {modelsChart !== null && (
              <Doughnut data={modelsChart} options={options} />
            )}
          </div>
          <div className={classes.stats} style={{ overflowY: 'scroll' }}>
            <div>
              {modelData !== null &&
                modelData.map(device => (
                  <div className={classes.deviceLeft} key={device.title}>
                    {/* <span className={classes.deviceIcon}>{device.icon}</span> */}
                    <div></div>
                    <Typography variant="body1" style={{ color: device.color }}>
                      {device.title}
                    </Typography>
                  </div>
                ))}
            </div>
            <div>
              {modelData !== null &&
                modelData.map(device => (
                  <div className={classes.device} key={device.title}>
                    <Typography
                      style={{
                        color: device.color
                      }}
                      variant="body1">
                      {device.value} 대
                    </Typography>
                  </div>
                ))}
            </div>
            <div>
              {modelData !== null &&
                modelData.map(device => (
                  <div className={classes.device} key={device.title}>
                    <Typography
                      style={{
                        color: device.color
                      }}
                      variant="body1">
                      {device.valuePer}%
                    </Typography>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

ModelCounter.propTypes = {
  className: PropTypes.string
};

export default ModelCounter;
