import React, { useEffect, useState, useContext } from 'react';
import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import {
  NO_PERMISSION,
  NO_PERMISSION_WARNING,
  READ_PERMISSION,
} from '../../Common/Config/naviwatchConfig';
import { userInfoContext } from '../../../App';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  content: {
    paddingTop: 150,
    textAlign: 'center'
  },
  image: {
    marginTop: 50,
    display: 'inline-block',
    maxWidth: '100%',
    width: 1024
  }
}));

const Dashboard = ({ match, history }) => {
  const classes = useStyles();
  const authInfo = useContext(userInfoContext);
  const [noPermissionMsg, setNoPermissionMsg] = useState("");
  const [permission, setPermission] = useState(NO_PERMISSION);
  useEffect(() => {
    var pageInfo = sessionStorage.getItem('pages');
    if (pageInfo !== "undefined") {
      var pageInfoObj = JSON.parse(pageInfo);
      var pageArray = pageInfoObj.filter(page => page.name.toLowerCase().includes("dashboard"));
      if (pageArray !== undefined && pageArray.length > 0) {
        var page = pageArray[0];
        console.log('page : ' + JSON.stringify(page));
        if (page.permission === undefined) {
          setNoPermissionMsg(NO_PERMISSION_WARNING);
        }
        setPermission(page.permission);
      }
    }
  }, [authInfo.pages]);

  useEffect(() => {
    if (sessionStorage.getItem('naviwatch_permission') !== "true")
      history.push('/')
  }, []);


  return (
    <div className={classes.root}>
      {permission >= READ_PERMISSION ?
        <Grid
          container
          justifyContent="center"
          spacing={4}
        >
          <Grid
            item
            lg={6}
            xs={12}
          >
            <div className={classes.content}>
              <Typography variant="h1">
                We are currently planning this page.
              </Typography>
              <Typography variant="subtitle2">
                Please wait more time to us :-)
              </Typography>
              <img
                alt="Under development"
                className={classes.image}
                src="/images/Planning.png"
              />
            </div>
          </Grid>
        </Grid>
        :
        <Typography>{noPermissionMsg}</Typography>
      }
    </div>
  );
};

export default Dashboard;
