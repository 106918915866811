import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Alert from '@material-ui/lab/Alert';
import Divider from '@material-ui/core/Divider';
import moment from 'moment';
import { Typography } from '@material-ui/core';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import DialogActions from '@material-ui/core/DialogActions';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import useAsync from '../../../../../asyncNet';
import { getModelName, userResetConfirmCode } from '../../../APIs/mateApis';

const STRING_ID_LABEL = '사용자 ID';
const STRING_EMAIL_LABEL = '사용자 메일';
const STRING_NAME_LABEL = '사용자 이름';

const STRING_PHONE_LABEL = '사용자 전화번호';
const STRING_REGISTER_AT_LABEL = '사용자 가입 날짜';
const STRING_LAST_MODIFY_LABEL = '마지막 접속 날짜';

const STRING_DEVICE_ID_LABAL = '디바이스 ID';
const STRING_DEVICE_SERVER_CONNECTION_ID_LABAL = '디바이스 ID (서버)';
const STRING_MODEL_LABEL = '모델 정보';
const STRING_DEVICE_NICKNAME_LABEL = '닉네임';
const STRING_MAT_TYPE_LABEL = '매트 타입';
const STRING_CAPACITY_LABEL = '용량 타입';
const STRING_CONNECTION_LABEL = '연결 여부';
const STRING_NEXT_LABEL = '다음';
const STRING_BACK_LABEL = '이전';
const STRING_WARNING_USERINFO = '사용자 개인정보 입니다. 정보 이용에 각별한 주의를 바랍니다.';
const STRING_FORCED_PAIRING = '강제 페어링';


const STRING_EMAIL_VERIFIED = '메일 검증여부';
const STRING_PHONE_VERIFIED = '휴대폰 검증여부';
const STRING_VERIFIED_OK = '검증 완료';
const STRING_VERIFIED_NOT_YET = '미검증 상태';

const checkVerified = verified =>{
  if(verified===0){
    return STRING_VERIFIED_NOT_YET;
  }else{
    return STRING_VERIFIED_OK;
  }
}

const varifiedOk = userInfo =>{
  if(userInfo.PHONE_VERIFIED == 1 || userInfo.EMAIL_VERIFIED==1){
    return true;
  }else{
    return false;
  }
}

const useStyles = makeStyles(theme => ({

  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  deviceView: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  deviceDescriptView: {
    display: 'flex',
    flexDirection: 'column'
  },
  deviceDescriptDivider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

const MatImage = [
  {
    modelCode: 0,
    imgPath: '/images/mat/mate1.png'
  },
  {
    modeCode: 1,
    imgPath: '/images/mat/572.png'
  },
  {
    modelCode: 2,
    imgPath: '/images/mat/580.png'
  },
  {
    modelCode: 3,
    imgPath: '/images/mat/581.png'
  },
  {
    modelCode: 4,
    imgPath: '/images/mat/581.png'
  },
  {
    modelCode: 257,
    imgPath: '/images/mat/eme500.jpeg'
  }
];

const getMatType = (matType) => {
  var country = 'Not defined';
  if (matType === '1')
    country = '슬림 매트';
  else if (matType === '2')
    country = '쿠션 매트';

  return country;
};

const getCapacityValue = (capacity) => {
  var capaType = 'Not defined';
  if (capacity === '1')
    capaType = '싱글';
  else if (capacity === '2')
    capaType = '퀸 (더블)';
  else if (capacity === '3')
    capaType = '킹 (더블';
  return capaType;
};


const getConnectionValue = (connect) => {
  var conn = 'Not defined';
  if (connect === 0)
    conn = '연결되지 않음';
  else if (connect === 1)
    conn = '연결됨';

  return conn;
};

const getModelCodeImgPath = (modelCode) =>{
  if( modelCode === 1 || modelCode === 2 || modelCode ===3){
    return MatImage[modelCode].imgPath
  } else if(modelCode===257){
    return MatImage[5].imgPath
  } else{
    return MatImage[0].imgPath
  }
};

const checkNeededForcePairing = (mobileAdditional, serverAdditional) => {
  if (mobileAdditional !== serverAdditional && serverAdditional != null) {
    return true;
  }
  return false;
};


const DetailDialog = props => {
  const { open, handleClose, userInfo, rendering } = props;
  const classes = useStyles();

  const [activeStep, setActiveStep] = React.useState(0);
  const [maxSteps, setMaxStep] = React.useState(0);
  const [state, forcedPairing] = useAsync(tryForcedPairing, [], true);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const changeUnverifiedState = ()=>{
    userResetConfirmCode(userInfo.userdetail.USER_ID, rendering)
  };


  async function tryForcedPairing() {
    /*
    const response = await axios.get(`https://yiws06c8pb.execute-api.ap-northeast-2.amazonaws.com/naviwatch/api/v1/monitoring/user/get-detail-info?`+
      `naviwatchid=${sessionStorage.getItem('email')}`,
      {headers:{
          'authorizer': sessionStorage.getItem('idToken')
        }});
    console.log('response :' + JSON.stringify(response));

    return response.data;
     */
    rendering();
  }

  useEffect(() => {
    if (userInfo !== null) {
      console.log('length of users device : ' + userInfo.devices.length);
      if (userInfo.devices.length > 0 && userInfo.devices.length <= 10) {
        setMaxStep(userInfo.devices.length);
      }
    }
  }, [userInfo]);

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">User Information</DialogTitle>
        <DialogContent>
          <DialogContentText component={'div'}>
            <Alert severity={'error'}><span style={{ color: 'red' }}>{STRING_WARNING_USERINFO}</span></Alert>
            <Divider className={classes.divider}/>
            <span><b>{STRING_ID_LABEL}</b> : {userInfo !== null && userInfo.userdetail.USER_ID}</span>
            <Divider className={classes.divider}/>
            <span><b>{STRING_NAME_LABEL}</b> : {userInfo !== null && userInfo.userdetail.USER_NAME}</span>
            <Divider className={classes.divider}/>
            <span><b>{STRING_EMAIL_LABEL}</b> : {userInfo !== null && userInfo.userdetail.USER_EMAIL}</span>
            <Divider className={classes.divider}/>
            <span><b>{STRING_PHONE_LABEL}</b> : {userInfo !== null && userInfo.userdetail.USER_PHONE}</span>
            <Divider className={classes.divider}/>
            <span><b>{STRING_REGISTER_AT_LABEL}</b> : {userInfo !== null && moment((userInfo.userdetail.USER_REGISTER-(60 * 60 * 9)) * 1000).format('YYYY-MM-DD')}</span>
            <Divider className={classes.divider}/>
            <span><b>{STRING_LAST_MODIFY_LABEL}</b> : {userInfo !== null && moment((userInfo.userdetail.USER_MODIFY-(60 * 60 * 9)) * 1000).format('YYYY-MM-DD')}</span>
            <Divider className={classes.divider}/>
            <Typography variant={'h6'}>Devices : </Typography>
            {
              userInfo !== null && userInfo.devices.length > 0 &&
              <div>
                <div className={classes.deviceView}>
                  <img
                    className={classes.img}
                    src={getModelCodeImgPath(Number(userInfo.devices[activeStep].MODEL_CODE))}
                    alt={getModelCodeImgPath(Number(userInfo.devices[activeStep].MODEL_CODE))}
                    height={'30%'}
                    width={'30%'}
                  />
                  <div className={classes.deviceDescriptView}>
                    <span><b>{STRING_DEVICE_ID_LABAL}</b> : {userInfo.devices[activeStep].MAC_ADDRESS + userInfo.devices[activeStep].ADDITIONAL_VALUE}</span>
                    <Divider className={classes.deviceDescriptDivider}/>
                    <span><b>{STRING_DEVICE_SERVER_CONNECTION_ID_LABAL}</b> : {
                      userInfo.devices[activeStep].CONNECTION_ADDITIONAL_VALUE !== null ?
                        userInfo.devices[activeStep].MAC_ADDRESS + userInfo.devices[activeStep].CONNECTION_ADDITIONAL_VALUE :
                        userInfo.devices[activeStep].MAC_ADDRESS + userInfo.devices[activeStep].ADDITIONAL_VALUE
                    }</span>
                    <Divider className={classes.deviceDescriptDivider}/>
                    <span><b>{STRING_MODEL_LABEL}</b> : {getModelName(userInfo.devices[activeStep].MODEL_CODE)}</span>
                    <Divider className={classes.deviceDescriptDivider}/>
                    <span><b>{STRING_DEVICE_NICKNAME_LABEL}</b> : {userInfo.devices[activeStep].DEV_NICKNAME}</span>
                    <Divider className={classes.deviceDescriptDivider}/>
                    <span><b>{STRING_MAT_TYPE_LABEL}</b>: {getMatType(userInfo.devices[activeStep].MAT_TYPE)}</span>
                    <Divider className={classes.deviceDescriptDivider}/>
                    <span><b>{STRING_CAPACITY_LABEL}</b> : {getCapacityValue(userInfo.devices[activeStep].CAPACITY)}</span>
                    <Divider className={classes.deviceDescriptDivider}/>
                    <span><b>{STRING_CONNECTION_LABEL}</b> : {getConnectionValue(userInfo.devices[activeStep].DEV_CONNECT)}</span>
                    <Divider className={classes.deviceDescriptDivider}/>
                    {false &&
                    <Button
                      size="small"
                      variant="outlined"
                      onClick={forcedPairing}
                      disabled={
                        !checkNeededForcePairing(userInfo.devices[activeStep].ADDITIONAL_VALUE,
                          userInfo.devices[activeStep].CONNECTION_ADDITIONAL_VALUE)

                      }>
                      {STRING_FORCED_PAIRING}
                    </Button>
                    }
                  </div>
                </div>
                <MobileStepper
                  steps={maxSteps}
                  position="static"
                  variant="text"
                  activeStep={activeStep}
                  nextButton={
                    <Button
                      size="small"
                      onClick={handleNext}
                      disabled={activeStep === maxSteps - 1}
                    >
                      {STRING_NEXT_LABEL}
                      <KeyboardArrowRight/>
                    </Button>
                  }
                  backButton={
                    <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                      <KeyboardArrowLeft/>
                      {STRING_BACK_LABEL}
                    </Button>
                  }
                />
              </div>

            }
            <Divider className={classes.divider}/>
          </DialogContentText>
          <form className={classes.form} noValidate>
          </form>
        </DialogContent>
        <DialogActions>
          {/* {userInfo!==null && userInfo.userdetail !== null && varifiedOk(userInfo.userdetail)===true &&
          <Button onClick={changeUnverifiedState} color="primary">
            휴대폰&이메일 미검증상태 변경
          </Button>} */}
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

DetailDialog.propTypes = {
  className: PropTypes.string
};

export default DetailDialog;
