import React, { useContext, useEffect, useState } from 'react';
import {
  makeStyles, CardContent, TableHead, TableRow, TableCell, CardActions,
  Card, Table, TableBody, TablePagination, Box, Button, Tooltip,
  IconButton, Typography
} from '@material-ui/core';

import publicIp from 'public-ip';
import useAsync from '../../../asyncNet';
import { firmwareList } from '../APIs/aironeApis';
import PerfectScrollbar from 'react-perfect-scrollbar';
import BorderColorIcon from '@material-ui/icons/BorderColor'
import SearchIcon from '@material-ui/icons/Search';
import DeleteIcon from '@material-ui/icons/Delete';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import Alert from '@material-ui/lab/Alert';
import {
  AddFirmwareDialog, DeleteFirmwareDialog, GroupOTADialog, ModifyFirmwareDialog
} from './components';
import PhonelinkRingOutlinedIcon from '@material-ui/icons/PhonelinkRingOutlined';
import {
  NO_PERMISSION,
  NO_PERMISSION_WARNING,
  READ_PERMISSION,
  WRITE_PERMISSION
} from '../../Common/Config/naviwatchConfig';
import { userInfoContext } from '../../../App';
import FirmwareListMqtt from './FirmwareListMqtt'
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  alert:{
    marginBottom: theme.spacing(2)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  backButton: {
    marginRight: theme.spacing(1)
  }
}));

const STRING_SW_MAJOR_VERSION = 'MAJOR 버전';
const STRING_SW_MINOR_VERSION = 'MINOR 버전';
const STRING_MODEL_CODE = '모델 코드';
const STRING_FIRMWARE_SIZE = '사이즈';
const STRING_FIRMWARE_URL = '펌웨어 주소';
 const STRING_ALERT = '펌웨어 수정, 업로드시 각별한 주의를 부탁드립니다.';
const STRING_ACTIVE_FLAG = 'ACTIVE';
const STRING_FORCED_FLAG = 'FORCE';
const STRING_REGISTER_AT = '등록일';
const STRING_LAST_MODIFY_AT = '수정일';
const STRING_MODIFY = '수정';

const STRING_GROUP_OTA = 'GROUP OTA';
const STRING_DELETE = '삭제';

const FirmwareList = ({history, match}) => {
  const classes = useStyles();
  const { modelCode } = match.params;
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [state, tryRefetch] = useAsync(()=>firmwareList(modelCode), [], false);
  const { loading, data: firmwares } = state;
  const [addFWDialogOpen, setAddFWDialogOpen] = useState(false);
  const [modifyFWDialogOpen, setModifyFWDialogOpen] = useState(false);
  const [deleteFWDialogOpen, setDeleteFWDialogOpen] = useState(false);
  const [groupOTADialogOpen, setGroupOTADialogOpen] = useState(false);
  const [detailSearchDialogOpen, setDetailSearchDialogOpen] = useState(false);

  const [selectedFirmware, setSelectedFirmware] = useState({
    majorVersion: 10,
    minorVersion: 0,
    modelCode: 0,
    activeFlag: false,
    forcedFlag: false,
    downloadUrl: "",
    fwDescription: "",
    groupOta: false
  });

  const [noPermissionMsg, setNoPermissionMsg] = useState("");
  const authInfo =  useContext(userInfoContext);
  const [permission, setPermission] = useState(NO_PERMISSION);

  const [ipAddress, setIpAddress] = useState(undefined);

  useEffect(()=>{
    var pageInfo = sessionStorage.getItem('pages');
    if(pageInfo!=="undefined") {
      var pageInfoObj = JSON.parse(pageInfo);
      var pageArray = pageInfoObj.filter(page => page.name.toLowerCase().includes("firmware"));
      if (pageArray !== undefined && pageArray.length > 0) {
        var page = pageArray[0];
        if(page.permission===undefined){
          setNoPermissionMsg(NO_PERMISSION_WARNING);
        }
        setPermission(page.permission);
      }
    }
  }, [authInfo.pages]);


  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const clickBack = () => {
    history.push('/airone/firmware');
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  const openAddFirmware = () => {
    setAddFWDialogOpen(true);
  };

  const closeAddFirmware = () => {
    setAddFWDialogOpen(false);
  };

  const openModifyFirmware = () => {
    setModifyFWDialogOpen(true);
  };

  const closeModifyFirmware = () => {
    setModifyFWDialogOpen(false);
  };

  const openDeleteFirmware = () => {
    setDeleteFWDialogOpen(true);
  };

  const closeDeleteFirmware = () => {
    setDeleteFWDialogOpen(false);
  };

  const openGroupOTADialog = () => {
    setGroupOTADialogOpen(true);
  };

  const closeGroupOTADialog = () => {
    setGroupOTADialogOpen(false);
  };



  const detailSearch = (firmware) => {
    console.log("detailSearch Click :"+JSON.stringify(firmware))
    setSelectedFirmware({
      ...selectedFirmware,
      majorVersion: firmware.FIRMWARE_MAJOR_VERSION,
      minorVersion: firmware.FIRMWARE_MINOR_VERSION,
      modelCode: firmware.MODEL_CODE,
      buildNumber: firmware.FIRMWARE_BUILD_NUMBER,
      activeFlag: firmware.ACTIVE_FLAG !== 0,
      forcedFlag: firmware.FORCED_FLAG !== 0,
      downloadUrl: firmware.FIRMWARE_DOWNLOAD_URL,
      jobId: firmware.FIRMWARE_JOB_ID,
      fwType: firmware.FIRMWARE_TYPE,
      fwDescription: firmware.FIRMWARE_DESCRIPTION,
      groupOta: firmware.GROUP_FLAG !== 0
    });
    openDetailSearch()
  };

  const openDetailSearch = () => {
    setDetailSearchDialogOpen(true);
  };

  const closeDetailSearch = () => {
    setDetailSearchDialogOpen(false);
  };

  const modifyClick = (majorVersion, minorVersion, modelCode,
                       activeFlag, forcedFlag, downloadUrl, fwDescription,
                       groupOta) =>{
    setSelectedFirmware({
      ...selectedFirmware,
      majorVersion: majorVersion,
      minorVersion: minorVersion,
      modelCode: modelCode,
      activeFlag: activeFlag !== 0,
      forcedFlag: forcedFlag !== 0,
      downloadUrl: downloadUrl,
      fwDescription: fwDescription,
      groupOta: groupOta !== 0
    });
    openModifyFirmware();
  };

  const deleteClick = (majorVersion, minorVersion, modelCode,
                       activeFlag, forcedFlag, downloadUrl, fwDescription,
                       groupOta) =>{
    setSelectedFirmware({
      ...selectedFirmware,
      majorVersion: majorVersion,
      minorVersion: minorVersion,
      modelCode: modelCode,
      activeFlag: activeFlag !== 0,
      forcedFlag: forcedFlag !== 0,
      downloadUrl: downloadUrl,
      fwDescription: fwDescription,
      groupOta: groupOta !== 0
    });
    openDeleteFirmware()
  };

  const groupClick = (majorVersion, minorVersion, modelCode,
                       activeFlag, forcedFlag, downloadUrl, fwDescription,
                      groupOta) =>{
    setSelectedFirmware({
      ...selectedFirmware,
      majorVersion: majorVersion,
      minorVersion: minorVersion,
      modelCode: modelCode,
      activeFlag: activeFlag !== 0,
      forcedFlag: forcedFlag !== 0,
      downloadUrl: downloadUrl,
      fwDescription: fwDescription,
      groupOta: groupOta !== 0
    });
    openGroupOTADialog()
  };


  useEffect(() => {
    if (sessionStorage.getItem('naviwatch_permission') !== 'true')
      history.push('/');
    (async () => {
      setIpAddress(await publicIp.v4());
    })();
  }, []);

  return (modelCode >= 1000 ? (
    FirmwareListMqtt({history, match})
  ) : (
    (
      <div>
        {permission>=READ_PERMISSION?
          <div className={classes.root}>
            <Alert className={classes.alert} severity={'error'}><span style={{ color: 'red' }}>{STRING_ALERT}</span></Alert>
            <Card >
              <CardContent className={classes.content}>
                <PerfectScrollbar>
                  <div className={classes.inner}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell align={'center'}><span><b>{STRING_SW_MAJOR_VERSION}</b></span></TableCell>
                          <TableCell align={'center'}><span><b>{STRING_SW_MINOR_VERSION}</b></span></TableCell>
                          <TableCell align={'center'}><span><b>{STRING_MODEL_CODE}</b></span></TableCell>
                          <TableCell align={'center'}><span><b>{STRING_FIRMWARE_SIZE}</b></span></TableCell>
                          <TableCell align={'center'}><span><b>{STRING_FIRMWARE_URL}</b></span></TableCell>
                          <TableCell align={'center'}><span><b>{STRING_ACTIVE_FLAG}</b></span></TableCell>
                          <TableCell align={'center'}><span><b>{STRING_FORCED_FLAG}</b></span></TableCell>
                          <TableCell align={'center'}><span><b>{STRING_GROUP_OTA}</b></span></TableCell>
                          <TableCell align={'center'}><span><b>{STRING_MODIFY}</b></span></TableCell>
                          <TableCell align={'center'}><span><b>{STRING_REGISTER_AT}</b></span></TableCell>
                          <TableCell align={'center'}><span><b>{STRING_LAST_MODIFY_AT}</b></span></TableCell>
                          <TableCell align={'center'}><span><b>{STRING_DELETE}</b></span></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {firmwares !== null && firmwares !== undefined &&
                        firmwares.slice((page * rowsPerPage), ((page + 1) * rowsPerPage)).map(firmware => (
                          <TableRow
                            className={classes.tableRow}
                            hover
                            key={firmware.FIRMWARE_DOWNLOAD_URL}
                          >
                            <TableCell align={'center'}>
                              {firmware.FIRMWARE_MAJOR_VERSION}
                            </TableCell>
                            <TableCell align={'center'}>
                              {firmware.FIRMWARE_MINOR_VERSION}
                            </TableCell>
                            <TableCell align={'center'}>
                              {firmware.MODEL_CODE}
                            </TableCell>
                            <TableCell>
                              {firmware.FIRMWARE_SIZE}
                            </TableCell>
                            <TableCell>
                              {firmware.FIRMWARE_DOWNLOAD_URL}
                            </TableCell>
                            <TableCell align={'center'}>
                              {firmware.ACTIVE_FLAG}
                            </TableCell>
                            <TableCell align={'center'}>
                              {firmware.FORCED_FLAG}
                            </TableCell>
                            <TableCell align={'center'}>
                              <Tooltip title={STRING_GROUP_OTA}>
                                <IconButton aria-label={STRING_GROUP_OTA}
                                            onClick={() => groupClick(firmware.FIRMWARE_MAJOR_VERSION,
                                              firmware.FIRMWARE_MINOR_VERSION, firmware.MODEL_CODE,
                                              firmware.ACTIVE_FLAG, firmware.FORCED_FLAG,
                                              firmware.FIRMWARE_DOWNLOAD_URL,
                                              firmware.FIRMWARE_DESCRIPTION,
                                              firmware.GROUP_FLAG)}
                                            disabled={firmware.GROUP_FLAG !== 1 || permission<WRITE_PERMISSION}
                                >
                                  <GroupAddIcon />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                            <TableCell align={'center'}>
                              <Tooltip title={STRING_MODIFY}>
                                <IconButton aria-label={STRING_MODIFY}
                                            disabled={permission<WRITE_PERMISSION}
                                            onClick={() => modifyClick(firmware.FIRMWARE_MAJOR_VERSION,
                                              firmware.FIRMWARE_MINOR_VERSION, firmware.MODEL_CODE,
                                              firmware.ACTIVE_FLAG, firmware.FORCED_FLAG,
                                              firmware.FIRMWARE_DOWNLOAD_URL,
                                              firmware.FIRMWARE_DESCRIPTION,
                                              firmware.GROUP_FLAG)}>
                                  <BorderColorIcon />
                                </IconButton>
                              </Tooltip>
                            </TableCell>

                            <TableCell align={'center'}>
                              {firmware.REGISTER_AT}
                            </TableCell>
                            <TableCell align={'center'}>
                              {firmware.LAST_MODIFY_AT}
                            </TableCell>
                            <TableCell align={'center'}>
                              <Tooltip title={STRING_DELETE}>
                                <IconButton aria-label={STRING_DELETE}
                                            disabled={permission<WRITE_PERMISSION}
                                            onClick={() => deleteClick(firmware.FIRMWARE_MAJOR_VERSION,
                                              firmware.FIRMWARE_MINOR_VERSION, firmware.MODEL_CODE,
                                              firmware.ACTIVE_FLAG, firmware.FORCED_FLAG,
                                              firmware.FIRMWARE_DOWNLOAD_URL,
                                              firmware.FIRMWARE_DESCRIPTION,
                                              firmware.GROUP_FLAG)}>
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </div>
                </PerfectScrollbar>
              </CardContent>
              <CardActions className={classes.actions}>
                {firmwares !== null && firmwares !== undefined &&
                <TablePagination
                  component="div"
                  count={firmwares !== null && firmwares !== undefined && firmwares.length}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handleRowsPerPageChange}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={[5, 10, 25]}
                />}
                <Box m={3}
                     display="flex"
                     justifyContent="flex-end"
                >
                  <Button className={classes.backButton}
                  onClick={clickBack}>
                    Back
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={openAddFirmware}
                    disabled={permission<WRITE_PERMISSION}
                  >
                    Add firmware
                  </Button>
                </Box>
              </CardActions>
            </Card>
            <AddFirmwareDialog open={addFWDialogOpen} ipAddress = {ipAddress} handleClose={closeAddFirmware} refetch={tryRefetch} modelCode={modelCode}/>
            {modifyFWDialogOpen &&
            <ModifyFirmwareDialog open={modifyFWDialogOpen} ipAddress = {ipAddress} handleClose={closeModifyFirmware} refetch={tryRefetch} selectedFW={selectedFirmware}/>}
            {deleteFWDialogOpen &&
            <DeleteFirmwareDialog open={deleteFWDialogOpen} ipAddress = {ipAddress} handleClose={closeDeleteFirmware} refetch={tryRefetch} selectedFW={selectedFirmware}/>}
            {groupOTADialogOpen &&
            <GroupOTADialog open={groupOTADialogOpen} ipAddress = {ipAddress} handleClose={closeGroupOTADialog} refetch={tryRefetch} selectedFW={selectedFirmware}/>}

          </div>:
          <Typography className={classes.root}>{noPermissionMsg}</Typography>
        }
      </div>
    )
  ))
};

export default FirmwareList;
