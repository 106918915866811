import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Divider, Drawer } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import TapAndPlayIcon from '@material-ui/icons/TapAndPlay';
import TimelineIcon from '@material-ui/icons/Timeline';
import DeveloperBoardIcon from '@material-ui/icons/DeveloperBoard';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import { Profile, SidebarNav } from './components';
import { userInfoContext } from '../../../../../App';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import RemoveIcon from '@material-ui/icons/Remove';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import SettingsIcon from '@material-ui/icons/Settings';
import PhonelinkSetupIcon from '@material-ui/icons/PhonelinkSetup';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  }
}));
const NAME_DASHBOARD = '대시보드';
const NAME_DEVICES = '디바이스';
const NAME_DEVICE_SEARCH = '디바이스 검색';
const NAME_CONVERTER = '컨버터 설정';
const NAME_CONVERTER_LOG_LEVEL = '로그 레벨 설정';
const NAME_USERS = '사용자';
const NAME_DORMANCY = '휴면 사용자';
const NAME_DORMANCY_HISTORY = '휴면 전환 안내';
const NAME_DORMANCY_MANAGE = '휴면 사용자 관리';
const NAME_QUERY_RECORD = '처리 기록';
const NAME_QUERY_USER_RECORD = '사용자 기록';
const NAME_QUERY_FW_RECORD = '펌웨어 기록';
const NAME_SERVER = '서버';
const NAME_STATISTICS = '통계';
const NAME_STATISTICS_WATER = '온수매트 통계';
const NAME_STATISTICS_ELEC = '전기매트 통계';
const NAME_FIRMWARE = '펌웨어';
const NAME_REPORT = '보고';
const NAME_ROLE = '권한';
const NAME_SETTING = '설정';

const Sidebar = props => {
  const { open, variant, onClose, prodid, className, ...rest } = props;
  const authInfo = useContext(userInfoContext);
  const location = useLocation();

  const classes = useStyles();

  const pages = [
    {
      name: 'dashboard',
      title: `${NAME_DASHBOARD}`,
      href: `/mate-kr/dashboard`,
      permission: undefined
    },
    {
      name: 'server',
      title: `${NAME_SERVER}`,
      href: `/mate-kr/server`,
      permission: undefined
    },
    {
      name: 'analytics',
      title: `${NAME_STATISTICS}`,
      href: `/mate-kr/statistics/water`,
      subNavIndex: 1,
      permission: undefined
    },
    {
      name: 'device',
      title: `${NAME_DEVICES}`,
      href: `/mate-kr/device/search`,
      subNavIndex: 3,
      permission: undefined
    },
    {
      name: 'converter',
      title: `${NAME_CONVERTER}`,
      href: `/mate-kr/converter/log-level`,
      subNavIndex: 4,
      permission: undefined
    },
    {
      name: 'firmware',
      title: `${NAME_FIRMWARE}`,
      href: '/mate-kr/firmware',
      permission: undefined
    },
    {
      name: 'search log',
      title: `${NAME_QUERY_RECORD}`,
      href: `/mate-kr/record/user`,
      subNavIndex: 2,
      permission: undefined
    },
    {
      name: 'role',
      title: `${NAME_ROLE}`,
      href: '/mate-kr/role',
      permission: undefined
    },
    {
      name: 'reports',
      title: `${NAME_REPORT}`,
      href: '/mate-kr/reports',
      permission: undefined
    },
    {
      name: 'setting',
      title: `${NAME_SETTING}`,
      href: '/mate-kr/setting',
      permission: undefined
    }
  ];

  const naviIcons = [
    {
      name: 'dashboard',
      icon: <DashboardIcon />
    },
    {
      name: 'server',
      icon: <TimelineIcon />
    },
    {
      name: 'analytics',
      icon: <EqualizerIcon />
    },
    {
      name: 'device',
      icon: <DeveloperBoardIcon />
    },
    {
      name: 'converter',
      icon: <DeveloperBoardIcon />
    },
    {
      name: 'firmware',
      icon: <PhonelinkSetupIcon />
    },
    {
      name: 'search log',
      icon: <BorderColorIcon />
    },
    {
      name: 'role',
      icon: <HowToRegIcon />
    },
    {
      name: 'reports',
      icon: <ReportProblemIcon />
    },
    {
      name: 'setting',
      icon: <SettingsIcon />
    }
  ];

  const subNaviState = [
    {
      index: 0,
      openState: false,
      subNav: [
        {
          title: `${NAME_STATISTICS_WATER}`,
          href: `/mate-kr/statistics/water`,
          icon: <EqualizerIcon />
        },
        {
          title: `${NAME_STATISTICS_ELEC}`,
          href: `/mate-kr/statistics/elec`,
          icon: <EqualizerIcon />
        }
      ]
    },
    {
      index: 1,
      openState: false,
      subNav: [
        {
          title: `${NAME_QUERY_USER_RECORD}`,
          href: `/mate-kr/record/user`,
          icon: <BorderColorIcon />
        },
        {
          title: `${NAME_QUERY_FW_RECORD}`,
          href: `/mate-kr/record/firmware`,
          icon: <BorderColorIcon />
        }
      ]
    },
    {
      index: 2,
      openState: false,
      subNav: [
        {
          title: `${NAME_DEVICE_SEARCH}`,
          href: '/mate-kr/device/search',
          icon: <DeveloperBoardIcon />
        }
      ]
    },
    {
      index: 3,
      openState: false,
      subNav: [
        {
          title: `${NAME_CONVERTER_LOG_LEVEL}`,
          href: '/mate-kr/converter/log-level',
          icon: <DeveloperBoardIcon />
        }
      ]
    }
  ];

  const [subNavi, setSubNaviState] = useState(subNaviState);

  useEffect(() => {
    console.log(location.pathname.split('/')[1]);
  }, []);

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}>
      <div {...rest} className={clsx(classes.root, className)}>
        <Profile />
        <Divider className={classes.divider} />
        <SidebarNav
          className={classes.nav}
          pages={pages}
          userInfo={authInfo}
          subNaviState={subNavi}
          setSubNaviState={setSubNaviState}
          naviIcons={naviIcons}
        />
        {/*<UpgradePlan />*/}
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;
