import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Alert from '@material-ui/lab/Alert';
import Divider from '@material-ui/core/Divider';
import moment from 'moment';
import { Typography, Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import DeviceControlLog from './DeviceControlLog';
import {
  STRING_USER_ID,
  STRING_USER_EMAIL,
  STRING_USER_NAME,
  STRING_USER_PHONE,
  STRING_USER_REGISTER_AT,
  STRING_USER_LAST_LOGIN_AT,
  STRING_DEVICE_ID,
  STRING_DEVICE_MODEL_NAME,
  STRING_CONNECTION_STATE,
  STRING_WARNING_USER_PRIVATE_INFO,
  STRING_UNKNOWN,
  STRING_USE,
  STRING_NOT_USE,
  STRING_CONNECTED,
  STIRNG_UNCONNECTED,
  STRING_START_DATE,
  STRING_END_DATE,
  STRING_DEVICE_LOG_SEARCH,
  STRING_LAST_1MONTH,
  STRING_DEVICE_CONTROL_LOG,
  STRING_DATE_FORMAT,
  STRING_DATEPICKER_FORMAT,
  STRING_EXTHEATDEMANDUSE,
  STRING_EXTCOMMTYPE
} from '../../../StringTable/StringTable';

const useStyles = makeStyles(theme => ({

  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  deviceView: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  deviceDescriptView: {
    display: 'flex',
    flexDirection: 'column'
  },
  deviceDescriptDivider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  container: {
    display: "flex",
    justifyContent: "spaceAround",
  },
  containerItems: {
    alignItems: "center",
    width: "100%",
    height: "100%"
  }

}));


const getConnectionValue = (connect) => {
  let conn = STRING_UNKNOWN;
  if (connect === true)
    conn = STRING_CONNECTED;
  else if (connect === false)
    conn = STIRNG_UNCONNECTED;

  return conn;
};

const getExtHeatDemandUseValue = (extHeatDemandUse) => {
  var use = STRING_UNKNOWN;
  if (extHeatDemandUse === true)
    use = STRING_USE;
  else if (extHeatDemandUse === false)
    use = STRING_NOT_USE;
  return use;
}

const DetailDialog = props => {
  const { className, open, handleClose, userInfo, rendering, ...rest } = props;
  const classes = useStyles();
  const [logOpen, setLogOpen] = React.useState(false);
  const [startDate, setStartDate] = useState(new Date().setHours(0, 0, 0, 0));
  const [endDate, setEndDate] = useState(new Date().setHours(23, 59, 59, 0));
  const logClickOpen = () => {
    setLogOpen(true);
  };


  const logClickClose = () => {
    setLogOpen(false);
  };


  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">Device Information</DialogTitle>
        <DialogContent>
          <DialogContentText component={'div'}>
            <Alert severity={'error'}><span style={{ color: 'red' }}>{STRING_WARNING_USER_PRIVATE_INFO}</span></Alert>
            <Divider className={classes.divider} />
            <span><b>{STRING_USER_ID}</b> : {userInfo !== null && userInfo !== undefined && userInfo.userdetail.userId ? userInfo.userdetail.userId : "-"}</span>
            <Divider className={classes.divider} />
            <span><b>{STRING_USER_NAME}</b> : {userInfo !== null && userInfo.userdetail.userName ? userInfo.userdetail.userName : "-"}</span>
            <Divider className={classes.divider} />
            <span><b>{STRING_USER_EMAIL}</b> : {userInfo !== null && userInfo.userdetail.userEmail ? userInfo.userdetail.userEmail : "-"}</span>
            <Divider className={classes.divider} />
            <span><b>{STRING_USER_PHONE}</b> : {userInfo !== null && userInfo.userdetail.userPhone ? userInfo.userdetail.userPhone : "-"}</span>
            <Divider className={classes.divider} />
            <span><b>{STRING_USER_REGISTER_AT}</b> : {userInfo !== null && userInfo.userdetail.userRegister ? moment(userInfo.userdetail.userRegister * 1000).format(STRING_DATE_FORMAT) : "-"}</span>
            <Divider className={classes.divider} />
            <span><b>{STRING_USER_LAST_LOGIN_AT}</b> : {userInfo !== null && userInfo.userdetail.userLastLogin ? moment(userInfo.userdetail.userLastLogin * 1000).format(STRING_DATE_FORMAT) : "-"}</span>


            <Divider className={classes.divider} />
            <Typography variant={'h6'}>Devices : </Typography>
            {
              userInfo !== null &&
              <div>
                <div className={classes.deviceView}>
                  <img
                    className={classes.img}
                    src={userInfo.devices.roomconModelImage ?
                      '/images/smarttok/' + userInfo.devices.roomconModelImage : '/images/smarttok/noimage.png'}
                    alt={userInfo.devices.roomconModelImage ?
                      '/images/smarttok/' + userInfo.devices.roomconModelImage : '/images/smarttok/noimage.png'}
                    height={'30%'}
                    width={'30%'}
                  />
                  <div className={classes.deviceDescriptView}>
                    <span><b>{STRING_DEVICE_ID}</b> : {userInfo.devices.macAddress ? userInfo.devices.macAddress + userInfo.devices.additionalValue : '-'}</span>
                    <Divider className={classes.deviceDescriptDivider} />
                    <span><b>{STRING_DEVICE_MODEL_NAME}</b> : {userInfo.devices.roomconModelName ? userInfo.devices.roomconModelName : '-'}</span>
                    <Divider className={classes.deviceDescriptDivider} />
                    <span><b>{STRING_CONNECTION_STATE}</b> : {userInfo.devices.deviceConnection ? getConnectionValue(userInfo.devices.deviceConnection) : STRING_UNKNOWN}</span>
                    <Divider className={classes.deviceDescriptDivider} />
                    <span><b>{STRING_EXTHEATDEMANDUSE}</b> : {userInfo.devices.extHeatDemandUse !== null ? getExtHeatDemandUseValue(userInfo.devices.extHeatDemandUse) : '-'}</span>
                    <Divider className={classes.deviceDescriptDivider} />
                    <span><b>{STRING_EXTCOMMTYPE}</b> : {userInfo.devices.extCommType ? userInfo.devices.extCommType : '-'}</span>
                    <Divider className={classes.deviceDescriptDivider} />
                  </div>
                </div>
              </div>

            }
            <Divider className={classes.divider} />
            <Grid>
              <Typography
                color="textSecondary"
                gutterBottom
                variant="h5"
              >
                {STRING_DEVICE_CONTROL_LOG}
              </Typography>
              <Typography
                color="error"
                gutterBottom
                variant="body1"
              >
                {STRING_LAST_1MONTH}
              </Typography>


            </Grid>
            <Grid
              container
              spacing={4}
              className={classes.container}
            >

              <Grid
                item
                md={4}
                xs={12}
                className={classes.containerItems}
              >
                <div>
                  {STRING_START_DATE}

                </div>
                <DatePicker
                  dateFormat={STRING_DATEPICKER_FORMAT}
                  minDate={new Date().setMonth(new Date().getMonth() - 1)}
                  maxDate={new Date()}
                  selected={startDate}
                  onChange={date => setStartDate(new Date(date).setHours(0, 0, 0, 0))}
                />
              </Grid>

              <Grid
                item
                md={4}
                xs={12}
                className={classes.containerItems}
              >
                <div>
                  {STRING_END_DATE}
                </div>
                <DatePicker
                  dateFormat={STRING_DATEPICKER_FORMAT}
                  minDate={startDate}
                  maxDate={new Date()}
                  selected={endDate}
                  onChange={date => setEndDate(new Date(date).setHours(23, 59, 59, 0))}
                />
              </Grid>

              <Grid
                item
                className={classes.containerItems}
                md={4}
                xs={12}
              >
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => logClickOpen()}
                >
                  {STRING_DEVICE_LOG_SEARCH}
                </Button>
              </Grid>
            </Grid>


          </DialogContentText>
          <form className={classes.form} noValidate>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {
        logOpen !== false && userInfo !== null &&
        <DeviceControlLog open={logOpen} handleClose={logClickClose} startTime={startDate} endTime={endDate} userInfo={userInfo} />
      }
    </div>
  );
};

DetailDialog.propTypes = {
  className: PropTypes.string
};

export default DetailDialog;
