import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import UpSleepSensorToolbar from './UpSleepSensorToolbar';
import UpSleepSensorSessionList from './UpSleepSensorSessionList';

const useStyles = makeStyles(theme => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  },
  title: {
    fontWeight: 700
  },
  formControl: {
    // margin: theme.spacing(1),
    width: 300
  },
  labelText: {
    color: '#aaaaaa'
  },
  indeterminateColor: {
    color: '#f50057'
  },
  selectAllText: {
    fontWeight: 500
  },
  selectedAll: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)'
    }
  }
}));

const UpSleepSensorSearch = props => {
  const {
    className,
    sessionIdList,
    getSensorData,
    setLoadingAnimation,
    getSessionIdList,
    ...rest
  } = props;

  const [searchSessionId, setSearchSessionId] = useState('');

  return (
    <div>
      <UpSleepSensorToolbar
        getSessionIdList={getSessionIdList}
        setLoadingAnimation={setLoadingAnimation}
      />
      {sessionIdList && sessionIdList.length > 0 && (
        <UpSleepSensorSessionList
          sessionIdList={sessionIdList}
          getSensorData={getSensorData}
          searchSessionId={searchSessionId}
          setSearchSessionId={setSearchSessionId}
        />
      )}
    </div>
  );
};

UpSleepSensorSearch.propTypes = {
  className: PropTypes.string
};

export default UpSleepSensorSearch;
