import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { PushToolbar, PushDialog } from './components';
import useAsync from '../../../asyncNet';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { pushSend } from '../APIs/aironeApis';
import { NO_PERMISSION, NO_PERMISSION_WARNING, READ_PERMISSION } from '../../Common/Config/naviwatchConfig';
import { Typography } from '@material-ui/core';
import { userInfoContext } from '../../../App';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  }
}));


const STRING_MODEL_NTR10PW = 'NTR10PW';
const STRING_MODEL_NTR10PW2ND = 'NTR10PW2ND';
const STRING_MODEL_NRT20D = 'NRT20D';
const STRING_MODEL_NRT20L = 'NRT20L';
const STRING_MODEL_NRT21D = 'NRT21D';
const STRING_MODEL_NRT20DS = 'NRT20DS';
const STRING_MODEL_NRT20DSW = 'NRT20DSW';
const STRING_MODEL_NRT30L = 'NRT30L';
const STRING_MODEL_NRT30S = 'NRT30S';
const STRING_MODEL_ALL = '전체모델';
const STRING_SUCCESS_PUSH_TITLE='푸시 발송 성공';
const STRING_SUCCESS_PUSH_CONTENT='푸시 발송이 성공적으로 완료되었습니다.';
const STRING_FAIL_PUSH_TITLE='푸시 발송 실패';
const STRING_FAIL_PUSH_CONTENT='푸시 발송이 실패하였습니다.';


const Push = ({history}) => {
  const classes = useStyles();
  
  const [pushCond, setPushCond] = useState({
    modelCode: '',
    title: '',
    content: ''
  });
  const authInfo =  useContext(userInfoContext);
  const [noPermissionMsg, setNoPermissionMsg] = useState("");
  const [permission, setPermission] = useState(NO_PERMISSION);
  useEffect(()=>{
    var pageInfo = sessionStorage.getItem('pages');
    if(pageInfo!=="undefined") {
      var pageInfoObj = JSON.parse(pageInfo);
      var pageArray = pageInfoObj.filter(page => page.name.toLowerCase().includes("push"));
      if (pageArray !== undefined && pageArray.length > 0) {
        var page = pageArray[0];
        if(page.permission===undefined){
          setNoPermissionMsg(NO_PERMISSION_WARNING);
        }
        setPermission(page.permission);
      }
    }
  }, [authInfo.pages]);
  const functionCallEnd = (data) => {
    if (data.code===200){
      setDialogString({
        title: STRING_SUCCESS_PUSH_TITLE,
        content: STRING_SUCCESS_PUSH_CONTENT
      });
    }else {
      setDialogString({
        title: STRING_FAIL_PUSH_TITLE,
        content: STRING_FAIL_PUSH_CONTENT
      });
    }
    handleClickOpen();
    setPushCond({
      modelCode: '',
      title: '',
      content: ''
    });
  };

  const sendPush = () => {
    return pushSend(pushCond.modelCode, pushCond.title, pushCond.content, functionCallEnd);
  };

  const [state, tryRefetch] = useAsync(sendPush, [], true);
  const { loading } = state;

  const [dialogString, setDialogString] = useState({
    title:'',
    content:''
  });

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const pushCondition = (pushInfo) =>{
    console.log("call push "+JSON.stringify(pushInfo));
    var modelNumber='';
    if (pushInfo.model === STRING_MODEL_NTR10PW) {
      modelNumber = 2;
    } else if (pushInfo.model === STRING_MODEL_NTR10PW2ND) {
      modelNumber = 3;
    } else if (pushInfo.model === STRING_MODEL_NRT20D) {
      modelNumber = 4;
    } else if (pushInfo.model === STRING_MODEL_NRT20L) {
      modelNumber = 5;
    } else if (pushInfo.model === STRING_MODEL_NRT21D) {
      modelNumber = 6;
    } else if (pushInfo.model === STRING_MODEL_NRT20DS) {
      modelNumber = 7;
    } else if (pushInfo.model === STRING_MODEL_NRT20DSW) {
      modelNumber = 8;
    } else if (pushInfo.model === STRING_MODEL_ALL) {
      modelNumber = 0;
    } else if (pushInfo.model === STRING_MODEL_NRT30L) {
      modelNumber = 1000;
    } else if (pushInfo.model === STRING_MODEL_NRT30S) {
      modelNumber = 1001;
    } 
    setPushCond(
      {
        modelCode: modelNumber,
        title: pushInfo.title,
        content: pushInfo.content,
      });
  };

  useEffect(() => {
    console.log('useEffect -- modelcode' + pushCond.modelCode);
    if (pushCond.modelCode !== '' && pushCond.title !== '' && pushCond.content !== '') {
      tryRefetch();
    }
  }, [pushCond]);


  useEffect(() => {
    if (sessionStorage.getItem('naviwatch_permission') !== 'true')
      history.push('/');
  }, []);


  return (
    <div>
      {permission>=READ_PERMISSION?
        <div className={classes.root}>
          <Backdrop className={classes.backdrop} open={loading} >
            <CircularProgress color={'primary'}/>
          </Backdrop>
          <PushToolbar callbackFunc={pushCondition}/>
          <PushDialog open={open} handleClose={handleClose} dialogString={dialogString}/>
        </div>:
        <Typography className={classes.root}>{noPermissionMsg}</Typography>
      }
    </div>
  );
};

export default Push;
