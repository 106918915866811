import React, { useState, useEffect } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/core/styles';
import {
  Dialog, DialogActions, DialogContent,
  DialogContentText, DialogTitle, Backdrop, CircularProgress
} from '@material-ui/core';
import {
  Grid,
  Button,
  IconButton,
  TextField,
  Link,
  FormHelperText,
  Checkbox,
  Typography
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Auth } from 'aws-amplify';
import useAsync from '../../../asyncNet';
import { naviwatchSignIn, naviwatchSignUp } from '../APIs/naviwatchApis';

const STR_SIGNUP_WELCOME = '환영합니다.';
const STR_SIGNUP_DESCRIPTION = '회원가입시 기본 권한만 부여됩니다. ';
const STR_NAME = '이름';
const STR_EMAIL = '이메일';
const STR_PASSWORD = '비밀번호';
const STR_TEAMPART = '부서명';
const STR_LEGALDOC = '이용약관';
const STR_READ_LEGALDOC = '을 읽었습니다.';
const STR_ALREADY_SIGNUP = '계정이 있으신가요?';
const STR_REGISTER_USER = '회원등록';
const STR_SIGN_IN = '로그인';
const STR_EMAIL_SCHEME_ERROR_TITLE = '가입 실패';
const STR_EMAIL_SCHEME_ERROR_CONTENT1 = '죄송합니다. ';
const STR_EMAIL_SCHEME_ERROR_CONTENT2 = '@kdiwin.com 메일 서버 가입자만 가입이 가능합니다.';
const STR_PASSWORD_ERROR_TITLE = '가입 실패';
const STR_PASSWORD_SHORT_CONTENT1 = '죄송합니다. ';
const STR_PASSWORD_SHORT_CONTENT2 = '비밀번호는 최소 8자 이상 기입해주세요.';
const STR_SEND_VERIFICATION_CODE_TITLE = '인증코드를 확인해주세요.';
const STR_SEND_VERIFICATION_CODE_CONTENT1 = '인증코드가 발송되었습니다.';
const STR_SEND_VERIFICATION_CODE_CONTENT2 = '메일을 확인한 뒤, 인증코드를 기입해주세요';
const STR_ERR_VERIFICATION_CODE_TITLE = '인증코드를 확인 실패.';
const STR_ERR_VERIFICATION_CODE_CONTENT1 = '인증코드가 유효하지 않습니다.';
const STR_ERR_VERIFICATION_CODE_CONTENT2 = '인증코드를 재전송한 뒤, 다시 시도하세요.';
const STR_ERR_VERIFICATION_LIMIT_EXCEED_TITLE = '인증코드를 확인 실패.';
const STR_ERR_VERIFICATION_LIMIT_EXCEED_CONTENT1 = '인증코드 검증 제한이 초과하였습니다.';
const STR_ERR_VERIFICATION_LIMIT_EXCEED_CONTENT2 = '나중에 다시 시도하세요.';
const STR_SIGNUP_FAIL_ALREADY_REGISTERED_TITLE = '가입 실패';
const STR_SIGNUP_FAIL_ALREADY_REGISTERED_CONTENT = '죄송합니다. 이미 가입된 사용자입니다.';
const STR_SIGNUP_FAIL_TITLE = '가입 실패';
const STR_SIGNUP_FAIL_CONTENT = '죄송합니다. 내부적인 문제로 가입되지 않습니다.';
const STR_ASSIGN_USER = '회원 이메일';
const STR_CONFIRM_NUMBER = '인증번호';
const STR_SIGN_UP = '인증 완료';
const STR_RESEND_VEFIRICATION_CODE = '재전송';
const STR_KDNAVIEN_EMAILFORM = '@kdiwin.com';
const STR_OK_VERIFICATION_TITLE = '가입 완료';
const STR_OK_VERIFICATION_CONTENT1 = '가입절차가 완료되었습니다.';
const STR_OK_VERIFICATION_CONTENT2 = '로그인후 사용하세요.';


const schema = {
  name: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 32
    }
  },
  teamPart: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128
    }
  },
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64
    }
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128
    }
  },
  policy: {
    presence: { allowEmpty: false, message: 'is required' },
    checked: true
  }
};


const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/intro.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 20,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  policy: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center'
  },
  policyCheckbox: {
    marginLeft: '-14px'
  },
  signUpButton: {
    margin: theme.spacing(2, 0)
  },
  verifyField: {
    display: 'flex',
    alignItems: 'flexCenter',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2)
  },
  doneVerifyBtn: {
    flexBasis: '85%',
    marginTop: theme.spacing(2, 0)
  },
  requestResendCodeBtn: {
    marginTop: theme.spacing(2, 0)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));

const SignUp = props => {
  const { history } = props;

  const classes = useStyles();

  const [dialogOpen, setDialog] = useState(false);
  const [dialogTxt, setDialogTxt] = useState(
    {
      dialogTitle: '',
      dialogContent1: '',
      dialogContent2: ''
    }
  );

  const setDialogOpen = () => {
    setBackdrop(false);
    setDialog(true);
  };

  const setDialogClose = () => {
    setDialog(false);
    if (formState.isFinishSignup === true) {
      console.log('go back to login page');
      history.push('/');
    }
  };

  const [backdropStatus, setBackdrop] = useState(false);


  const [formState, setFormState] = useState({
    isSignupValid: false,
    isVerifyValid: false,
    isFinishSignup: false,
    verifyUserEmail: '',
    verifyNumber: '',
    values: {},
    touched: {},
    errors: {}
  });

  const [state, trySignUp] = useAsync(()=>signUp(), [], true);
  const { data: userInfo } = state;

  const signUp = () =>{
    return naviwatchSignUp(formState.values.name, formState.values.email,
      formState.values.password, formState.values.teamPart);
  };

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isSignupValid: errors ? false : true,
      errors: errors || {}
    }));

    if (formState.verifyUserEmail.includes(STR_KDNAVIEN_EMAILFORM) === true &&
      formState.verifyNumber.length !== 0) {
      setFormState(formState => ({
        ...formState,
        isVerifyValid: true
      }));
    }
  }, [formState.values, formState.verifyNumber, formState.verifyUserEmail]);

  useEffect(()=>{
    if(formState.isFinishSignup === true){
      dialogOn(STR_OK_VERIFICATION_TITLE,
        STR_OK_VERIFICATION_CONTENT1,
        STR_OK_VERIFICATION_CONTENT2);
    }

  },[formState.isFinishSignup]);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const verifyNumberChange = event => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      [event.target.name]: event.target.value
    }));
  };

  const verifyUserEmail = event => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      [event.target.name]: event.target.value
    }));
  };

  const handleBack = () => {
    history.goBack();
  };

  const dialogOn = (title, content1, content2) => {
    setDialogTxt({
      ...dialogTxt,
      dialogTitle: title,
      dialogContent1: content1,
      dialogContent2: content2
    });
    setDialogOpen();
  };

  async function awsSignUp(username, email, password, team) {
    console.log('username: ' + username);
    console.log('email: ' + email);
    setBackdrop(true);
    const { user } = await Auth.signUp({
      'username': email,
      'password': password,
      attributes: {
        'name': username,
        'email': email,
        'custom:team': team
        // other custom attributes
      }
    });
    console.log(user);
    return user;
  }

  const confirmSignUp = event => {
    event.preventDefault();
    console.log('code: ' + formState.verifyNumber);

    awsConfirmSignUp(formState.verifyUserEmail, formState.verifyNumber).then(() => {
      console.log('Success verify code Signup');
      setFormState(formState => ({
        ...formState,
        isFinishSignup: true
      }));

      dialogOn(STR_OK_VERIFICATION_TITLE,
        STR_OK_VERIFICATION_CONTENT1,
        STR_OK_VERIFICATION_CONTENT2);

    }).catch(e => {
      console.log('confirm signup Fail code :' + e.code);
      if (e.code === 'ExpiredCodeException' || e.code === 'CodeMismatchException') {
        dialogOn(STR_ERR_VERIFICATION_CODE_TITLE,
          STR_ERR_VERIFICATION_CODE_CONTENT1,
          STR_ERR_VERIFICATION_CODE_CONTENT2);

        console.log('Fail signup');
      } else if (e.code === 'LimitExceededException') {
        dialogOn(STR_ERR_VERIFICATION_LIMIT_EXCEED_TITLE,
          STR_ERR_VERIFICATION_LIMIT_EXCEED_CONTENT1,
          STR_ERR_VERIFICATION_LIMIT_EXCEED_CONTENT2);
        console.log('Fail signup');
      } else {
        dialogOn(STR_SIGNUP_FAIL_TITLE, STR_SIGNUP_FAIL_CONTENT, '');
        console.log('fail signup : ' + e.code);
      }
    });
  };

  async function awsConfirmSignUp(username, code) {
    setBackdrop(true);
    await Auth.confirmSignUp(username, code);
  }

  const checkEmail = email => {
    if (email.includes(STR_KDNAVIEN_EMAILFORM) === false) {
      dialogOn(STR_EMAIL_SCHEME_ERROR_TITLE,
        STR_EMAIL_SCHEME_ERROR_CONTENT1,
        STR_EMAIL_SCHEME_ERROR_CONTENT2);
      return false;
    } else
      return true;
  };

  const checkPassword = password => {
    if (password.length < 8) {
      dialogOn(STR_PASSWORD_ERROR_TITLE,
        STR_PASSWORD_SHORT_CONTENT1,
        STR_PASSWORD_SHORT_CONTENT2);
      return false;
    } else
      return true;
  };

  const handleSignUp = event => {
    event.preventDefault();
    console.log('email : ' + formState.values.email);
    console.log('team : ' + formState.values.teamPart);
    if (checkEmail(formState.values.email) === true &&
      checkPassword(formState.values.password) === true) {
      awsSignUp(formState.values.name, formState.values.email,
        formState.values.password, formState.values.teamPart).then(() => {
        trySignUp().then(r => {
          dialogOn(STR_SEND_VERIFICATION_CODE_TITLE,
            STR_SEND_VERIFICATION_CODE_CONTENT1,
            STR_SEND_VERIFICATION_CODE_CONTENT2);
        });
        console.log('success signup');}
      ).catch(e => {
          if (e.code === 'UsernameExistsException') {
            dialogOn(STR_SIGNUP_FAIL_ALREADY_REGISTERED_TITLE,
              STR_SIGNUP_FAIL_ALREADY_REGISTERED_CONTENT, '');
            console.log('fail signup : ' + e.code);
          } else {
            dialogOn(STR_SIGNUP_FAIL_TITLE,
              STR_SIGNUP_FAIL_CONTENT, '');
            console.log('fail signup : ' + e.code);
          }
          console.log('Exception : ' + JSON.stringify(e));
        }
      );
    }
  };

  async function awsResendSignupCode(email) {
    setBackdrop(true);
    console.log('email: ' + email);
    await Auth.resendSignUp(email);
  }

  const resendVerifyCode = () => {
    console.log('email: ' + formState.verifyUserEmail);
    if (checkEmail(formState.verifyUserEmail) === true) {
      awsResendSignupCode(formState.verifyUserEmail).then(() => {
          dialogOn(STR_SEND_VERIFICATION_CODE_TITLE,
            STR_SEND_VERIFICATION_CODE_CONTENT1,
            STR_SEND_VERIFICATION_CODE_CONTENT2);
          console.log('success sending verification code');
        }
      ).catch(e => {
        console.log('something wrong:' + e.code);
      });
    }
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={backdropStatus}>
        <CircularProgress color={'primary'}/>
      </Backdrop>

      <Dialog
        open={dialogOpen}
        onClose={setDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dialogTxt.dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogTxt.dialogContent1}
            <br/>
            {dialogTxt.dialogContent2}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={setDialogClose} color="primary">
            네
          </Button>
        </DialogActions>
      </Dialog>
      <Grid
        className={classes.grid}
        container
      >
        <Grid
          className={classes.quoteContainer}
          item
          lg={5}
        >
          <div className={classes.quote}>
            <div className={classes.quoteInner}>
              <Typography
                className={classes.quoteText}
                variant="h1"
              >
                <p>Smarter Living Environment Partner,</p>
                <p>KD Navien</p>
              </Typography>
              <div className={classes.person}>
              </div>
            </div>
          </div>
        </Grid>
        <Grid
          className={classes.content}
          item
          lg={7}
          xs={12}
        >
          <div className={classes.content}>
            <div className={classes.contentHeader}>
              <IconButton onClick={handleBack}>
                <ArrowBackIcon/>
              </IconButton>
            </div>
            <div className={classes.contentBody}>
              <div>
                <form
                  className={classes.form}
                  onSubmit={handleSignUp}
                >
                  <Typography
                    className={classes.title}
                    variant="h2"
                  >
                    {STR_SIGNUP_WELCOME}
                  </Typography>
                  <Typography
                    className={classes.textField}
                    color="textSecondary"
                    gutterBottom
                  >
                    {STR_SIGNUP_DESCRIPTION}
                  </Typography>
                  <TextField
                    className={classes.textField}
                    error={hasError('name')}
                    fullWidth
                    helperText={
                      hasError('name') ? formState.errors.name[0] : null
                    }
                    label={STR_NAME}
                    name="name"
                    onChange={handleChange}
                    type="text"
                    value={formState.values.name || ''}
                    variant="outlined"
                  />
                  <TextField
                    className={classes.textField}
                    error={hasError('email')}
                    fullWidth
                    helperText={
                      hasError('email') ? formState.errors.email[0] : null
                    }
                    label={STR_EMAIL}
                    name="email"
                    onChange={handleChange}
                    type="text"
                    value={formState.values.email || ''}
                    variant="outlined"
                  />
                  <TextField
                    className={classes.textField}
                    error={hasError('password')}
                    fullWidth
                    helperText={
                      hasError('password') ? formState.errors.password[0] : null
                    }
                    label={STR_PASSWORD}
                    name="password"
                    onChange={handleChange}
                    type="password"
                    value={formState.values.password || ''}
                    variant="outlined"
                  />
                  <TextField
                    className={classes.textField}
                    error={hasError('teamPart')}
                    fullWidth
                    helperText={
                      hasError('teamPart') ? formState.errors.teamPart[0] : null
                    }
                    label={STR_TEAMPART}
                    name="teamPart"
                    onChange={handleChange}
                    type="text"
                    value={formState.values.teamPart || ''}
                    variant="outlined"
                  />
                  <div className={classes.policy}>
                    <Checkbox
                      checked={formState.values.policy || false}
                      className={classes.policyCheckbox}
                      color="primary"
                      name="policy"
                      onChange={handleChange}
                    />
                    <Typography
                      className={classes.policyText}
                      color="textSecondary"
                      variant="body1"
                    >
                      <Link
                        color="primary"
                        component={RouterLink}
                        to="#"
                        underline="always"
                        variant="h6"
                      >
                        {STR_LEGALDOC}
                      </Link>
                      {' '} {STR_READ_LEGALDOC}

                    </Typography>
                  </div>
                  {hasError('policy') && (
                    <FormHelperText error>
                      {formState.errors.policy[0]}
                    </FormHelperText>
                  )}
                  <Button
                    className={classes.signUpButton}
                    color="primary"
                    disabled={!formState.isSignupValid}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    {STR_REGISTER_USER}
                  </Button>

                </form>
                <form
                  className={classes.form}
                  onSubmit={confirmSignUp}
                >
                  <TextField
                    className={classes.textField}
                    fullWidth
                    label={STR_ASSIGN_USER}
                    name="verifyUserEmail"
                    onChange={verifyUserEmail}
                    type="text"
                    value={formState.verifyUserEmail || ''}
                    variant="outlined"
                  />
                  <TextField
                    className={classes.textField}
                    fullWidth
                    label={STR_CONFIRM_NUMBER}
                    name="verifyNumber"
                    onChange={verifyNumberChange}
                    type="text"
                    value={formState.verifyNumber || ''}
                    variant="outlined"
                  />
                  <div className={classes.verifyField}>
                    <Button
                      className={classes.doneVerifyBtn}
                      color="primary"
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      disabled={!formState.isVerifyValid}
                    >
                      {STR_SIGN_UP}
                    </Button>
                    <Button
                      className={classes.requestResendCodeBtn}
                      color="primary"
                      size="large"
                      type="button"
                      variant="outlined"
                      onClick={resendVerifyCode}
                    >
                      {STR_RESEND_VEFIRICATION_CODE}
                    </Button>

                  </div>

                  <Typography
                    className={classes.textField}
                    color="textSecondary"
                    variant="body1"
                  >

                    {STR_ALREADY_SIGNUP}{' '}
                    <Link
                      component={RouterLink}
                      to="/sign-in"
                      variant="h6"
                    >
                      {STR_SIGN_IN}
                    </Link>
                  </Typography>
                </form>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

SignUp.propTypes = {
  history: PropTypes.object
};

export default withRouter(SignUp);
