import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Typography,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@material-ui/core';
import useAsync from '../../../../../asyncNet';
import { naviwatchPolicyHistory } from '../../../../Common/APIs/naviwatchApis';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  history: {
    height: '70%'
  },
  details: {
    display: 'flex'
  },
  avatar: {
    marginLeft: 'auto',
    height: 110,
    width: 100,
    flexShrink: 0,
    flexGrow: 0
  },
  progress: {
    marginTop: theme.spacing(2)
  },
  uploadButton: {
    marginRight: theme.spacing(2)
  },
  divider:{
    marginTop: 5
  },
  tableContainer:{
    maxHeight: '100%',
    marginTop: '15px'
  },
  table: {
    minWidth: 400
  }
}));

const ROLE_SETTING_HISTORY_TITLE='권한변경 이력';

const historyTable = (styleClass, history) =>{
  return(
    <div className={styleClass.history} >
      <Typography color="textSecondary" variant={'h6'} >{ROLE_SETTING_HISTORY_TITLE}</Typography>
      <Divider className={styleClass.divider}/>
      <TableContainer  className={styleClass.tableContainer} component={Paper}>
        <Table className={styleClass.table} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell align="center">일시</TableCell>
              <TableCell align="center">등급</TableCell>
              <TableCell align="center">처리자</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {history!==undefined && history.map((row) => (
              <TableRow key={row.date}>
                <TableCell align="center">{row.date}</TableCell>
                <TableCell align="center">{row.assign}</TableCell>
                <TableCell align="center">{row.setter}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const AccountProfile = props => {
  const { email, className,  ...rest } = props;
  const classes = useStyles();
  const [state, tryRefetch] = useAsync(getPolicyHistory, [], false);
  const { loading, data: history } = state;

  async function getPolicyHistory() {
    return naviwatchPolicyHistory(email);
  }
  return (
    <div className={classes.root}>
      {history !== null && /*console.log("history : "+JSON.stringify(history)) &&*/
        <Card
          {...rest}
          className={clsx(classes.root, className)}
        >
          <CardContent className={classes.root}>
            <div className={classes.details}>
              <div>
                <Typography
                  gutterBottom
                  variant="h2"
                >
                  {history.data.userName}
                </Typography>
                <Typography
                  className={classes.locationText}
                  color="textSecondary"
                  variant="body1"
                >
                  {history.data.userEmail} / {history.data.team}
                </Typography>
                <Typography
                  className={classes.dateText}
                  color="textSecondary"
                  variant="body1"
                >
                  {moment().format('hh:mm A')} ({'GMT-9'})
                </Typography>
              </div>
              <Avatar
                className={classes.avatar}
                src={'/images/avatars/human.png'}
              />
            </div>
            {historyTable(classes, history.data.history)}
          </CardContent>

        </Card>
      }
    </div>
  );
};

AccountProfile.propTypes = {
  className: PropTypes.string
};

export default AccountProfile;
