import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core/styles';
import palette from 'theme/palette';

import {
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Divider
} from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';

import { options } from './chart';
import useAsync from '../../../../../asyncNet';
import { dashboardGetServerMetric } from '../../../APIs/mateApis';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  chartContainer: {
    position: 'relative',
    height: '400px'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  test: {
    marginTop: theme.spacing(6)
  }
}));

const ServerChart = props => {
  const { className, metricName, ...rest } = props;
  const getMetric = () => {
    const currentTime = Math.floor(new Date() / 1000);
    return dashboardGetServerMetric(metricName, currentTime);
  };
  const [state, tryRefetch] = useAsync(getMetric, []);
  const [chartdata, setChartData] = useState({
    labels: [],
    datasets: []
  });

  const { data: metric } = state;

  const classes = useStyles();

  useEffect(() => {
    try {
      if (metric !== null) {
        const timeFormatter = n => {
          var tempval = new Date((n + 60 * 60 * 9) * 1000);
          return tempval.toISOString().slice(11, 16);
        };
        // console.log('timestamp : ' + metric['data']['average']['timestamp']);
        // console.log('data : ' + metric['data']['average']['percent']);
        const formattedTime = metric['data']['average']['timestamp'].map(
          timeFormatter
        );
        setChartData(chardata => ({
          ...chartdata,
          labels: formattedTime,
          datasets: [
            {
              label: 'maximum',
              lineTension: 0,
              fill: false,
              backgroundColor: palette.error.main,
              data: metric['data']['maximum']['percent']
            },
            {
              label: 'average',
              lineTension: 0,
              fill: false,
              backgroundColor: palette.info.main,
              data: metric['data']['average']['percent']
            }
          ]
        }));
      }
    } catch (e) {
      console.log('metric update failed :' + JSON.stringify(e));
    }
  }, [metric]);

  useEffect(() => {
    const timer = setInterval(function() {
      tryRefetch();
    }, 60 * 1000);
    console.log('setInterval intervalidx:' + timer);
    return () => {
      console.log('clearinterval: ' + timer);
      clearInterval(timer);
    };
  }, []);

  return (
    <div>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardHeader
          action={
            <IconButton size="small" onClick={tryRefetch}>
              <RefreshIcon />
            </IconButton>
          }
          title={metricName === 'CPUUtilization' ? 'CPU (%)' : 'MEM (%)'}
        />

        <Divider />
        <CardContent>
          <div className={classes.chartContainer}>
            <Line data={chartdata} options={options} />
          </div>
        </CardContent>
        <Divider className={classes.test} />
      </Card>
    </div>
  );
};

ServerChart.propTypes = {
  className: PropTypes.string
};

export default React.memo(ServerChart);
