import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination
} from '@material-ui/core';
import {
  STRING_MEMBER_ID,
  STRING_LAST_CONNECT_AT,
  STRING_NOTIFICATION_STATE,
  STRING_NOTIFICATION_DATE,
  STRING_NO_CONNECT_INFOMATION,
  STRING_SENT,
  STRING_RESERVE_SEND,
  STRING_DATE_FORMAT_INCLUDE_SEC
} from '../../../StringTable/StringTable';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  deviceView: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  deviceDescriptView: {
    display: 'flex',
    flexDirection: 'column'
  },
  deviceDescriptDivider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));


const DormancyTable = props => {
  const { className, dormantNotification, ...rest } = props;
  const classes = useStyles();
  const [selectedUsers, setSelectedUsers] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  const notification = dormantNotification.sort((a, b) => (a.notifyAt > b.notifyAt ? -1 : 1));
  // const notification = sortedDormantNoti.filter(noti => noti.notifyAt !== null);

  return (
    <div>
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >

        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align={'center'}><span><b>{STRING_MEMBER_ID}</b></span></TableCell>
                    <TableCell align={'center'}><span><b>{STRING_LAST_CONNECT_AT}</b></span></TableCell>
                    <TableCell align={'center'}><span><b>{STRING_NOTIFICATION_DATE}</b></span></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {notification !== null && notification !== undefined && notification.slice((page * rowsPerPage), ((page + 1) * rowsPerPage)).map(dormant => (
                    <TableRow
                      className={classes.tableRow}
                      hover
                      key={Math.random().toString()}
                      selected={selectedUsers.indexOf(dormant.userId) !== -1}
                    >
                      <TableCell align={'center'}>
                        {dormant.userId}
                      </TableCell>
                      <TableCell align={'center'}>
                        {dormant.lastLoginAt ? moment((dormant.lastLoginAt - 9 * 60 * 60) * 1000).format(STRING_DATE_FORMAT_INCLUDE_SEC) : STRING_NO_CONNECT_INFOMATION}
                      </TableCell>
                      <TableCell align={'center'}>
                        {dormant.notifyAt ? moment((dormant.notifyAt - 9 * 60 * 60) * 1000).format(STRING_DATE_FORMAT_INCLUDE_SEC) : "-"}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>

        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={notification !== null && notification !== undefined ? notification.length : 0}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </CardActions>
      </Card>
    </div>
  );
};

DormancyTable.propTypes = {
  className: PropTypes.string,
  dormancy: PropTypes.array.isRequired
};

export default DormancyTable;
