import React, { useEffect, useState } from 'react';
import {
  Dialog, DialogActions, DialogContent, Button,
  DialogTitle, Divider, Typography, makeStyles,
  Checkbox, Input, Backdrop, CircularProgress
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import { firmwareModify } from '../../../APIs/aironeApis';


const STRING_TITLE = '펌웨어 수정';
const STRING_MAJOR_VERSION = 'MAJOR 버전 :';
const STRING_MINOR_VERSION = 'MINOR 버전 :';
const STRING_MODEL_CODE = '모델 코드 :';
const STRING_ACTIVE = 'ACTIVE 유무 :';
const STRING_FORCE = 'FORCE 유무 :';
const STRING_GROUP_OTA = 'GROUP OTA 유무 :';
const STRING_FW_DESCRIPTION = '펌웨어 설명 :';
const STRING_ALERT_FW_UPLOAD = '수정 사항을 한번 더 확인해주세요.';
const STRING_CLOSE = '취소';
const STRING_MODIFY = '수정';


const useStyles = makeStyles(theme => ({
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  linear:{
    display: 'flex',
    alignItems: 'flex-end'
  },
  datas:{
    marginLeft: theme.spacing(2)
  },
  icons:{
    marginLeft: theme.spacing(2),
    padding: theme.spacing(0)
  },
  fwDescription:{
    marginLeft: theme.spacing(2),
    width: '75%'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));

const ModifyFirmwareDialog  = props => {
  const { open, ipAddress, handleClose, refetch, selectedFW } = props;
  const [loading, setLoading] = useState(false);
  const [forcedflag, setForcedFlag] = useState(false);
  const [activeflag, setActiveFlag] = useState(false);
  const [groupOtaflag, setGroupOtaFlag] = useState(false);
  const [majorVersion, setMajorVersion] = useState(0);
  const [minorVersion, setMinorVersion] = useState(0);
  const [fwDescription, setFwDescription] = useState("");

  const classes = useStyles();

  useEffect(()=>{
    setForcedFlag(selectedFW.forcedFlag);
    setActiveFlag(selectedFW.activeFlag);
    setGroupOtaFlag(selectedFW.groupOta);
    setMajorVersion(selectedFW.majorVersion);
    setMinorVersion(selectedFW.minorVersion);
    setFwDescription(selectedFW.fwDescription);  }, [open]);

  const handleForcedFlag = () =>{
    if(forcedflag){
      setForcedFlag(false);
    }else{
      setForcedFlag(true);
    }
  };

  const handleActiveFlag = () =>{
    if(activeflag){
      setActiveFlag(false);
    }else{
      setActiveFlag(true);
    }
  };

  const handleGroupOtaFlag = () =>{
    if(groupOtaflag){
      setGroupOtaFlag(false);
    }else{
      setGroupOtaFlag(true);
    }
  };

  const handleCloseClick = () =>{
    setForcedFlag(false);
    setActiveFlag(false);
    setGroupOtaFlag(false);
    setMajorVersion(0);
    setMinorVersion(0);
    setFwDescription("");
    handleClose();
  };

  const refetchList = () => {
    setLoading(false);
    refetch();
  };

  const handleModify = () => {
    setLoading(true);
    firmwareModify(sessionStorage.getItem('email'), ipAddress,
      selectedFW.majorVersion, selectedFW.minorVersion,
      selectedFW.modelCode, majorVersion, minorVersion,
      activeflag===true?1:0, forcedflag===true?1:0,
      groupOtaflag===true?1:0,
      fwDescription,
      refetchList);
    handleCloseClick()
  };

  const changeMajorNumber = event => {
    event.persist();
    setMajorVersion(event.target.value);
  };

  const changeMinorNumber = event => {
    event.persist();
    setMinorVersion(event.target.value);
  };

  const changeFWDescription = event => {
    event.persist();
    setFwDescription(event.target.value);
  };

  return (
    <div>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color={'primary'}/>
      </Backdrop>
      <Dialog
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{STRING_TITLE}</DialogTitle>
        <DialogContent>
          <Alert severity={'error'}><span style={{ color: 'red' }}>{STRING_ALERT_FW_UPLOAD}</span></Alert>
          <Divider className={classes.divider}/>
          <div className={classes.linear}>
            <Typography variant="h6">{STRING_MAJOR_VERSION}</Typography>
            <Input className={classes.datas}
                   type={"number"}
                   placeholder={""+majorVersion}
                   inputProps={{ 'aria-label': 'description' }}
                   onChange={changeMajorNumber}
            />
          </div>
          <Divider className={classes.divider}/>
          <div className={classes.linear}>
            <Typography variant="h6">{STRING_MINOR_VERSION}</Typography>
            <Input className={classes.datas}
                   type={"number"}
                   placeholder={""+minorVersion}
                   inputProps={{ 'aria-label': 'description' }}
                   onChange={changeMinorNumber}
            />
          </div>
          <Divider className={classes.divider}/>
          <div className={classes.linear}>
            <Typography variant="h6">{STRING_MODEL_CODE}</Typography>
            <Typography className={classes.datas} variant="h6">{selectedFW.modelCode}</Typography>
          </div>
          <Divider className={classes.divider}/>
          <div className={classes.linear}>
            <Typography variant="h6">{STRING_ACTIVE}</Typography>
            <Checkbox className={classes.icons}
                      checked={activeflag}
                      onChange={handleActiveFlag}
                      color="primary"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </div>
          <Divider className={classes.divider}/>
          <div className={classes.linear}>
            <Typography variant="h6">{STRING_FORCE}</Typography>
            <Checkbox className={classes.icons}
                      checked={forcedflag}
                      onChange={handleForcedFlag}
                      color="primary"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}/>
          </div>
          <Divider className={classes.divider}/>
          <div className={classes.linear}>
            <Typography variant="h6">{STRING_GROUP_OTA}</Typography>
            <Checkbox className={classes.icons}
                      checked={groupOtaflag}
                      onChange={handleGroupOtaFlag}
                      color="primary"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}/>
          </div>
          <Divider className={classes.divider}/>
          <div className={classes.linear}>
            <Typography variant="h6">{STRING_FW_DESCRIPTION}</Typography>
            <Input className={classes.fwDescription}
                   inputProps={{ 'aria-label': 'description' }}
                   placeholder={fwDescription}
                   onChange={changeFWDescription}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseClick} color="primary" autoFocus>
            {STRING_CLOSE}
          </Button>
          <Button onClick={handleModify} color="primary" autoFocus>
            {STRING_MODIFY}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

ModifyFirmwareDialog.propTypes = {
  className: PropTypes.string
};

export default ModifyFirmwareDialog;
