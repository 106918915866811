import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core/styles';
import {
  CardContent,
  Divider,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide
} from '@material-ui/core';
import palette from 'theme/palette';
import { options } from './chart';
import { STRING_ALL_REGION } from '../../../StringTable/StringTable';

const useStyles = makeStyles(() => ({
  chartContainer: {
    height: 400,
    position: 'relative'
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DeviceAreaDialog = props => {
  const { className, openStatus, closeFunc, data, ...rest } = props;
  const classes = useStyles();
  const [areaData, setAreaData] = useState({
    labels: [],
    datasets: []
  });

  useEffect(() => {
    try {
      if (
        data !== null &&
        data !== undefined &&
        data['countryArray'] !== undefined
      ) {
        setAreaData(areaData => ({
          ...areaData,
          labels: data['countryArray'],
          datasets: [
            {
              label: 'NR-30D',
              backgroundColor: palette.error.main,
              data: data['nr30dArray'],
              barThickness: 12,
              maxBarThickness: 10,
              barPercentage: 0.5,
              categoryPercentage: 0.5
            },
            {
              label: 'NR-35D',
              backgroundColor: palette.info.main,
              data: data['nr35dArray'],
              barThickness: 12,
              maxBarThickness: 10,
              barPercentage: 0.5,
              categoryPercentage: 0.5
            },
            {
              label: 'NR-40D',
              backgroundColor: palette.success.main,
              data: data['nr40dArray'],
              barThickness: 12,
              maxBarThickness: 10,
              barPercentage: 0.5,
              categoryPercentage: 0.5
            },
            {
              label: 'NRM-35D',
              backgroundColor: palette.primary.main,
              data: data['nrm35dArray'],
              barThickness: 12,
              maxBarThickness: 10,
              barPercentage: 0.5,
              categoryPercentage: 0.5
            },
            {
              label: 'NR-6XD',
              backgroundColor: palette.secondary.main,
              data: data['nr6xdArray'],
              barThickness: 12,
              maxBarThickness: 10,
              barPercentage: 0.5,
              categoryPercentage: 0.5
            },
            {
              label: 'NRM-6XD',
              backgroundColor: palette.warning.main,
              data: data['nrm6xdArray'],
              barThickness: 12,
              maxBarThickness: 10,
              barPercentage: 0.5,
              categoryPercentage: 0.5
            }
          ]
        }));
      }
    } catch (e) {
      console.log('areaCount update failed :' + JSON.stringify(e));
    }
  }, [data]);

  return (
    <Dialog
      open={openStatus}
      TransitionComponent={Transition}
      fullWidth={true}
      maxWidth={'xl'}
      keepMounted
      onClose={closeFunc}>
      <DialogTitle>{STRING_ALL_REGION}</DialogTitle>
      <DialogContent>
        <CardContent>
          <div className={classes.chartContainer}>
            <Bar data={areaData} options={options} />
          </div>
        </CardContent>
        <Divider />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeFunc} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DeviceAreaDialog.propTypes = {
  className: PropTypes.string
};

export default DeviceAreaDialog;
