import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState, useContext } from 'react';
import useAsync from '../../../asyncNet';
import { Backdrop, CircularProgress, Typography } from '@material-ui/core';
import { RecordToolbar, RecordTable } from './components';
import { getRecord } from '../APIs/tokapis';
import { userInfoContext } from '../../../App';
import {
  NO_PERMISSION,
  NO_PERMISSION_WARNING,
  NO_WHITELIST_INSIDE_WARNING,
  READ_PERMISSION
} from '../../Common/Config/naviwatchConfig';
import publicIp from 'public-ip';
import { naviwatchCheckWhiteListValidation } from '../../Common/APIs/naviwatchApis';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));


const Record = ({ history }) => {
  const classes = useStyles();
  const [query, setQuery] = useState({
    handlerType: '',
    from: '',
    to: '',
    handlerId: '',
    userId: '',
    feature: '',
  });
  const [state, tryRefetch] = useAsync(() => getRecord(query.handlerType, query.from, query.to, query.handlerId, query.userId, query.feature), [], true);
  const { loading, data: records } = state;
  const [ipCheckState, tryCheckWhitelist] = useAsync(checkIpValidation, [], true);
  const { loading: ipCheckLoading, data: ipValid } = ipCheckState;
  const [ipAddress, setIpAddress] = useState(undefined);
  const authInfo = useContext(userInfoContext);
  const [noPermissionMsg, setNoPermissionMsg] = useState("");
  const [permission, setPermission] = useState(NO_PERMISSION);
  useEffect(() => {
    var pageInfo = sessionStorage.getItem('pages');
    if (pageInfo !== "undefined") {
      var pageInfoObj = JSON.parse(pageInfo);
      var pageArray = pageInfoObj.filter(page => page.name.toLowerCase().includes("search log"));
      if (pageArray !== undefined && pageArray.length > 0) {
        var page = pageArray[0];
        if (page.permission === undefined) {
          setNoPermissionMsg(NO_PERMISSION_WARNING);
        }
        setPermission(page.permission);
      }
    }
  }, [authInfo.pages]);

  async function checkIpValidation() {
    return naviwatchCheckWhiteListValidation(ipAddress);
  }

  useEffect(() => {
    if (ipCheckState.data === false) {
      setNoPermissionMsg(NO_WHITELIST_INSIDE_WARNING + `(${ipAddress})`);
    }
  }, [ipCheckState]);

  useEffect(() => {
    if (ipAddress !== undefined) {
      tryCheckWhitelist();
    }
  }, [ipAddress]);

  const searchRecords = (searchdata) => {
    var _from = '';
    var _to = '';
    if (searchdata.from !== '') {
      _from = new Date(searchdata.from).setHours(0, 0, 0, 0) + 9 * 60 * 60 * 1000
    }
    if (searchdata.to !== '') {
      _to = new Date(searchdata.to).setHours(23, 59, 59, 0) + 9 * 60 * 60 * 1000
    }


    setQuery(
      {
        handlerType: searchdata.handlerType,
        from: _from,
        to: _to,
        handlerId: searchdata.handlerId,
        userId: searchdata.userId,
        feature: searchdata.feature
      });
  };

  useEffect(() => {
    if (query.handlerType !== '' && query.from !== '' && query.to !== '') {
      tryRefetch();
    }
  }, [query]);

  useEffect(() => {
    if (sessionStorage.getItem('naviwatch_permission') !== 'true')
      history.push('/');
    (async () => {
      setIpAddress(await publicIp.v4());
    })();
  }, []);

  return (
    <div>
      { permission >= READ_PERMISSION && ipValid === true ?
        <div className={classes.root}>
          <Backdrop className={classes.backdrop} open={loading}>
            <CircularProgress color={'primary'} />
          </Backdrop>
          <RecordToolbar callbackFunc={searchRecords} />
          <div className={classes.content}>
            {
              loading === false && records !== null && records !== undefined && <RecordTable records={records.record} />
            }
          </div>
        </div> :
        <Typography className={classes.root}>{noPermissionMsg}</Typography>
      }
    </div>
  );
};

export default Record;
