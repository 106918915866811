import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
} from '@material-ui/core';
import ModelBox from './ModelBox/ModelBox';
import {Link} from 'react-router-dom';

const useStyles = makeStyles(() => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  },
  items:{
    textAlign: 'center',
    textSize: 110
  },
  mouseOver:{
    cursor: 'pointer'
  },
}));

const STRING_TITLE = 'Mate Models';
const STRING_SUBTITLE = '모델을 선택해 주세요.';

const ModelToolbar = props => {
  const { className, models, ...rest } = props;

  const classes = useStyles();

  const [values, setValues] = useState({
    model: '',
    title: '',
    content: '',
  });

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        title={STRING_TITLE}
        subheader={STRING_SUBTITLE}
      />
      <Divider/>
      <CardContent>
        <Grid
          container
          spacing={3}
        >
          {models!==null && models!==undefined && models.map(model=>(
            <Grid
              className={classes.mouseOver}
              item
              lg={4}
              sm={12}
              xl={4}
              xs={12}
              key={model['modelName']}
            >
              <Link to={`/mate-us/firmware/${model['modelCode']}`}>
                <ModelBox modelName={model['modelName']} imageSrc={'/images/products/mate1.png'}
                        firmwareCount={model['modelCount']} lastUpdated={model['lastUpdatedAt']}/>
              </Link>
            </Grid>
          ))
          }
        </Grid>
      </CardContent>
      <Divider/>
    </Card>
  );
};

ModelToolbar.propTypes = {
  className: PropTypes.string
};

export default ModelToolbar;
