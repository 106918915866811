import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import dateformat from 'dateformat';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  Box,
  Typography
} from '@material-ui/core';
import {
  STRING_RECORD,
  STRING_PRIVATE_INFO_LIST,
  STRING_SEARCH,
  STRING_FEATURE,
  STRING_CLEAN,
  STRING_CHANGE_PASSWORD,
  STRING_DEREGISTER_USER,
  STRING_MANAGER,
  STRING_USER,
  STRING_CONNECTION_DATE,
  STRING_HANDLER_TYPE,
  STRING_ID_MEMBER,
  STRING_ID_HANDLER,
  STRING_DATE_FORMAT_LOWER_CASE,
  STRING_UPDATE_USER_PRIVATE_INFO
} from '../../../StringTable/StringTable';


const useStyles = makeStyles((theme) => ({
  root: { alignItems: 'center' },
  actions: {
    justifyContent: 'flex-end',
  },
  container: {
    width: '23%',
    alignItems: 'center'
  },
  margin: {
    margin: theme.spacing(1),
    alignItems: 'center',
    display: 'flex',
  }
}));


const RecordToolbar = props => {
  const { className, callbackFunc, ...rest } = props;

  const classes = useStyles();
  const [values, setValues] = useState({
    handlerType: '',
    handlerId: '',
    userId: '',
    from: dateformat(new Date(), STRING_DATE_FORMAT_LOWER_CASE),
    to: dateformat(new Date(), STRING_DATE_FORMAT_LOWER_CASE),
    feature: ''
  });

  const [state, setState] = useState({
    checkedA: true,
    checkedB: false
  });

  if (state.checkedA === true && state.checkedB !== true) {
    values.handlerType = "MANAGER";
    var feature = [];
    feature = [
      {
        value: ''
      },
      {
        value: 'SEARCH'
      },
      {
        value: 'DETAIL SEARCH'
      }
    ];
  } else if (state.checkedA !== true && state.checkedB === true) {
    values.handlerType = "USER";
    feature = [
      {
        value: ''
      },
      {
        value: STRING_UPDATE_USER_PRIVATE_INFO
      },
      {
        value: STRING_CHANGE_PASSWORD
      },
      {
        value: STRING_DEREGISTER_USER
      },
      {
        value: 'Login'
      }
    ];
  } else if (state.checkedA === true && state.checkedB === true) {
    values.handlerType = "ALL";
    feature = [
      {
        value: ''
      },
      {
        value: 'SEARCH'
      },
      {
        value: 'DETAIL SEARCH'
      },
      {
        value: STRING_UPDATE_USER_PRIVATE_INFO
      },
      {
        value: STRING_CHANGE_PASSWORD
      },
      {
        value: STRING_DEREGISTER_USER
      },
      {
        value: 'Login'
      }
    ];
  } else {
    feature = [
      {
        value: ''
      }
    ]
  };


  const handleCheckChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked
    });
  };


  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };


  const cleanSearchInput = () => {
    setValues({
      handlerType: '',
      handlerId: '',
      userId: '',
      from: dateformat(new Date(), STRING_DATE_FORMAT_LOWER_CASE),
      to: dateformat(new Date(), STRING_DATE_FORMAT_LOWER_CASE),
      feature: ''
    });
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        title={STRING_RECORD}
        subheader={<div>
          <span style={{ color: 'red' }}>{STRING_PRIVATE_INFO_LIST}</span></div>}
      />
      <Divider />
      <CardContent>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            md={6}
            xs={12}
          >
            <FormGroup row>

              <Grid
                item
                md={12}
                xs={12}
              >
                <Box className={classes.margin}>
                  <Typography><b>{STRING_HANDLER_TYPE}　</b></Typography>
                  <FormControlLabel
                    label={STRING_MANAGER}
                    control={<Checkbox
                      checked={state.checkedA}
                      onChange={handleCheckChange}
                      name="checkedA"
                    />
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.checkedB}
                        onChange={handleCheckChange}
                        name="checkedB"
                      />
                    }
                    label={STRING_USER}
                  />
                </Box>
              </Grid>
              <div />

              <div />
              <Grid
                item
                md={12}
                xs={12}
              >
                <form noValidate>
                  <Box className={classes.margin}>
                    <Typography><b>{STRING_CONNECTION_DATE}　</b></Typography>
                    <TextField
                      id="from"
                      name="from"
                      label="From"
                      type="date"
                      value={values.from}
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <Typography>{'~'}</Typography>
                    <TextField
                      id="to"
                      name="to"
                      label="To"
                      type="date"
                      value={values.to}
                      onChange={handleChange}
                      inputProps={{
                        min: values.from,
                        max: dateformat(new Date(values.from).setMonth(new Date(values.from).getMonth() + 1), STRING_DATE_FORMAT_LOWER_CASE)
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Box>
                </form>
              </Grid>
              <div />

              <div />
              <Grid
                item
                md={12}
                xs={12}
              >
                <Box className={classes.margin}>
                  <Typography><b>{STRING_FEATURE}　</b></Typography>
                  <TextField
                    label={STRING_FEATURE}
                    margin="dense"
                    name="feature"
                    onChange={handleChange}
                    select
                    SelectProps={{ native: true }}
                    value={values.feature}
                    variant="outlined"
                    disabled={values.handlerType === ''}
                  >
                    {
                      feature.map(option => (
                        <option
                          key={option.value}
                          value={option.value}
                        >
                          {option.value}
                        </option>
                      ))
                    }
                  </TextField>
                </Box>
              </Grid>
            </FormGroup>
          </Grid>

          <Grid
            item
            md={6}
            xs={12}
          >
            <Box component="span" m={2} className={classes.margin}>
              <Typography><b>　{STRING_ID_MEMBER}</b></Typography>
              <TextField
                className={classes.margin}
                label={STRING_ID_MEMBER}
                margin="dense"
                name="userId"
                onChange={handleChange}
                value={values.userId}
                variant="outlined"
                disabled={values.handlerType === ''}
              />
            </Box>
            <div />

            <div />
            <Box className={classes.margin} >
              <Typography><b>{STRING_ID_HANDLER}</b></Typography>
              <TextField
                className={classes.margin}
                label={STRING_ID_HANDLER}
                margin="dense"
                name="handlerId"
                onChange={handleChange}
                value={values.handlerId}
                variant="outlined"
                disabled={values.handlerType === ''}
              />
            </Box>
          </Grid>

          <Grid
            item
            md={2}
            xs={4}
          >

          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>
        <Button
          color="primary"
          variant="contained"
          onClick={cleanSearchInput}
          disabled={values.handlerType === '' && values.userId === '' && values.handlerId === '' && values.from === dateformat(new Date(), STRING_DATE_FORMAT_LOWER_CASE) && values.to === dateformat(new Date(), STRING_DATE_FORMAT_LOWER_CASE) && values.feature === ''}
        >
          {STRING_CLEAN}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => callbackFunc(values)}
          disabled={values.handlerType === '' && values.userId === '' && values.handlerId === '' && values.from === dateformat(new Date(), STRING_DATE_FORMAT_LOWER_CASE) && values.to === dateformat(new Date(), STRING_DATE_FORMAT_LOWER_CASE) && values.feature === ''}
        >
          {STRING_SEARCH}
        </Button>
      </CardActions>
    </Card >
  );
};

RecordToolbar.propTypes = {
  className: PropTypes.string
};

export default RecordToolbar;
