import React, { useEffect, useState, useMemo } from 'react';

import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import {
  CardContent,
  Divider,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide
} from '@material-ui/core';

import UpSleepSensorTable from './UpSleepSensorTable/UpSleepSensorTable';
import UpSleepSensorChart from './UpSleepSensorChart';

const UpSleepDialog = ({ sessionReportData, openStatus, closeFunc }) => {
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);

  const deviceReportData = useMemo(() => {
    if (!sessionReportData.deviceReport) return [];

    return sessionReportData.deviceReport.reduce((data, current) => {
      data.push({
        Date: moment(new Date(current.timestamp * 1000)).format(
          'YYYY-MM-DD HH:mm:ss'
        ),
        '동작 모드': current.operationMode,
        '현재 온도': current.currentTemperature,
        '설정 온도': current.setTemperature,
        '실시간 수면 상태': '',
        '수면 상태': '',
        timestamp: current.timestamp * 1000
      });
      return data;
    }, []);
  }, [
    sessionReportData,
    sessionReportData.deviceReport,
    sessionReportData.deviceReport?.length
  ]);

  const asleepSessionRealTimeData = useMemo(() => {
    if (!sessionReportData.asleepSessionRealtimeReport) return [];
    return sessionReportData.asleepSessionRealtimeReport.reduce(
      (data, current) => {
        data.push({
          Date: moment(new Date(current.timestamp * 1000)).format(
            'YYYY-MM-DD HH:mm:ss'
          ),
          '동작 모드': '',
          '현재 온도': '',
          '설정 온도': '',
          '실시간 수면 상태': current.sleepStage,
          '수면 상태': '',
          timestamp: current.timestamp * 1000
        });
        return data;
      },
      []
    );
  }, [
    sessionReportData,
    sessionReportData.asleepSessionRealtimeReport,
    sessionReportData.asleepSessionRealtimeReport?.length
  ]);

  const asleepSessionData = useMemo(() => {
    if (
      !sessionReportData.asleepSessionReport ||
      !sessionReportData.asleepSessionReport.sleepStageList
    )
      return {};
    let startTimestamp = Number(
      moment(sessionReportData.asleepSessionReport.startTime).format('x')
    );
    return sessionReportData.asleepSessionReport.sleepStageList.reduce(
      (data, current) => {
        startTimestamp += 30000;
        data.push({
          Date: moment(new Date(startTimestamp)).format('YYYY-MM-DD HH:mm:ss'),
          '동작 모드': '',
          '현재 온도': '',
          '설정 온도': '',
          '실시간 수면 상태': '',
          '수면 상태': current,
          timestamp: startTimestamp
        });
        return data;
      },
      []
    );
  }, [sessionReportData, sessionReportData.asleepSessionReport]);

  useEffect(() => {
    let result = [];
    if (asleepSessionData.length > 0) {
      result = result.concat(asleepSessionData);
    }
    if (deviceReportData.length > 0) {
      result = result.concat(deviceReportData);
    }
    if (asleepSessionRealTimeData.length > 0) {
      result = result.concat(asleepSessionRealTimeData);
    }
    result.sort(function(a, b) {
      return a['timestamp'] - b['timestamp'];
    });
    const columnsArr = [];
    const rowsArr = [];
    for (const objKey in result[0]) {
      columnsArr.push(objKey);
    }
    setColumns(columnsArr);
    result.forEach(data => {
      const dataArr = [];
      for (const objKey in data) {
        dataArr.push(data[`${objKey}`]);
      }
      rowsArr.push(dataArr);
    });
    setRows(rowsArr);
  }, [
    deviceReportData,
    deviceReportData?.length,
    asleepSessionRealTimeData,
    asleepSessionRealTimeData?.length,
    asleepSessionData,
    asleepSessionData?.length
  ]);

  return (
    <Dialog
      open={openStatus}
      fullWidth={true}
      maxWidth={'xl'}
      onClose={closeFunc}>
      <DialogTitle>세션 상세 정보</DialogTitle>
      <DialogContent>
        {rows.length > 0 && (
          <>
            <UpSleepSensorTable columns={columns} rows={rows} />
            <UpSleepSensorChart formatChartData={sessionReportData} />
          </>
        )}
        <Divider />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeFunc} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpSleepDialog;
