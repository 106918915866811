import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import ReactApexChart from 'react-apexcharts';
import moment from 'moment';

import { Card } from '@material-ui/core';

const UpSleepSensorChart = props => {
  const {
    formatChartData: {
      deviceReport,
      asleepSessionRealtimeReport,
      asleepSessionReport
    }
  } = props;

  const setTemperatureReportList = useMemo(() => {
    return deviceReport.reduce((data, current) => {
      data.push({
        x: new Date(current.timestamp * 1000 + 32400000),
        y: current.setTemperature
      });
      return data;
    }, []);
  }, [deviceReport, deviceReport.length]);

  const currentTemperatureReportList = useMemo(() => {
    return deviceReport.reduce((data, current) => {
      data.push({
        x: new Date(current.timestamp * 1000 + 32400000),
        y: current.currentTemperature
      });
      return data;
    }, []);
  }, [deviceReport, deviceReport.length]);

  const asleepSessionRealtimeReportList = useMemo(() => {
    return asleepSessionRealtimeReport.reduce((data, current) => {
      data.push({
        x: new Date(current.timestamp * 1000 + 32400000),
        y: current.sleepStage * 5
      });
      return data;
    }, []);
  }, [asleepSessionRealtimeReport, asleepSessionRealtimeReport.length]);

  const asleepSessionReportList = useMemo(() => {
    let startTimestamp = Number(
      moment(asleepSessionReport.startTime).format('x')
    );
    return asleepSessionReport.sleepStageList.reduce((data, current) => {
      startTimestamp += 30000;
      data.push({
        x: new Date(startTimestamp + 32400000),
        y: current * 5
      });
      return data;
    }, []);
  }, [asleepSessionReport, asleepSessionReport.length]);

  const chartState = useMemo(() => {
    if (
      setTemperatureReportList.length >= 0 &&
      currentTemperatureReportList.length >= 0 &&
      asleepSessionReportList.length > 0
    ) {
      let series = [
        {
          name: '설정 온도',
          data: setTemperatureReportList
        },
        {
          name: '현재 온도',
          data: currentTemperatureReportList
        },
        {
          name: '수면 상태 결과',
          data: asleepSessionReportList
        }
      ];
      if (asleepSessionRealtimeReportList.length > 0) {
        series.push({
          name: '실시간 수면 상태',
          data: asleepSessionRealtimeReportList
        });
      }
      return {
        series,
        options: {
          chart: {
            id: 'chart2',
            type: 'line',
            height: 300,
            toolbar: {
              autoSelected: 'pan',
              show: false
            }
          },
          colors: ['#546E7A', '#FF0000', '#008FFB', '#9933FF'],
          stroke: {
            curve: 'stepline',
            width: [5, 2, 3, 1],
            dashArray: [5, 0, 0, 0]
          },
          dataLabels: {
            enabled: false
          },
          fill: {
            opacity: 1
          },
          markers: {
            size: 0,
            hover: {
              sizeOffset: 3
            }
          },
          xaxis: {
            type: 'datetime',
            labels: {
              show: true,
              rotate: -45
            },
            axisTicks: {
              show: true
            }
          },
          yaxis: {
            min: 0,
            max: 45,
            tickAmount: 5
          },
          tooltip: {
            x: {
              show: true,
              format: 'YYYY-MM-DD HH:mm:ss',
              formatter: function(value) {
                return new Date(value)
                  .toISOString()
                  .replace('T', ' ')
                  .substring(0, 19);
              }
            },
            y: {
              show: true,
              formatter: function(value) {
                if (value <= 15) {
                  return Number(value / 5);
                }
                return value;
              }
            }
          }
        },
        optionsLine: {
          chart: {
            id: 'chart1',
            height: 130,
            type: 'line',
            brush: {
              target: 'chart2',
              enabled: true
            },
            selection: {
              enabled: true,
              xaxis: {
                min: setTemperatureReportList[0],
                max:
                  setTemperatureReportList[setTemperatureReportList.length - 1]
              }
            }
          },
          colors: ['#546E7A', '#FF0000', '#008FFB', '#9933FF'],
          stroke: {
            curve: 'stepline',
            width: 3
          },
          xaxis: {
            type: 'datetime',
            tooltip: {
              enabled: false
            }
          },
          yaxis: {
            tickAmount: 2,
            min: 0,
            max: 45
          }
        }
      };
    }
  }, [
    setTemperatureReportList,
    currentTemperatureReportList,
    asleepSessionRealtimeReportList,
    asleepSessionReportList,
    setTemperatureReportList.length,
    currentTemperatureReportList.length,
    asleepSessionRealtimeReportList.length,
    asleepSessionReportList.length
  ]);

  return (
    <div style={{ marginTop: 20 }}>
      {setTemperatureReportList.length >= 0 &&
        currentTemperatureReportList.length >= 0 &&
        asleepSessionRealtimeReport.length >= 0 &&
        asleepSessionReportList.length > 0 && (
          <Card>
            <div id="wrapper">
              <ReactApexChart
                options={chartState.options}
                series={chartState.series}
                type="line"
                height={300}
              />
            </div>
            <div id="chart-line">
              <ReactApexChart
                options={chartState.optionsLine}
                series={chartState.series}
                type="area"
                height={130}
              />
            </div>
          </Card>
        )}
    </div>
  );
};

UpSleepSensorChart.propTypes = {
  className: PropTypes.string,
  resultSet: PropTypes.object
};

export default UpSleepSensorChart;
