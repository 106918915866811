import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  CardActions,
  Button
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '80%'
  },
  action: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  image: {
    width: '40%',
  },
  button: {
    marginBottom: theme.spacing(3)
  }
}));

const ProductCard = props => {
  const { product, mouseOver, disable } = props;

  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent className={classes.card}>
        <img
          alt="Product"
          height={disable ? '70%' : (mouseOver === product.PRODUCT_ID ? '90%' : '70%')}
          src={product.PRODUCT_IMAGE}
        />

      </CardContent>
      <CardActions className={classes.action}>
        <Button
          className={classes.button}
          color="primary"
          size="large"
          type="button"
          disabled={disable}
          variant={mouseOver === product.PRODUCT_ID ? "contained" : "outlined"}
        >
          {product.PRODUCT_NAME}
        </Button>
      </CardActions>
    </Card>
  );
};

ProductCard.propTypes = {
  className: PropTypes.string,
  product: PropTypes.object.isRequired
};

export default ProductCard;
