import React, { useEffect, useState, useContext } from 'react';
import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  DeviceAreaDialog, ModelCounter, RealtimeCounter, StatusIcon, DeviceArea, RealtimeCounterTODO
} from './components'
import {
  NO_PERMISSION,
  NO_PERMISSION_WARNING,
  READ_PERMISSION,
} from '../../Common/Config/naviwatchConfig';
import { userInfoContext } from '../../../App';
import WeeklyChart from './components/WeeklyChart';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  content: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%',
    height: '100%'
  }
}));

const Dashboard = ({ match, history }) => {
  const classes = useStyles();
  const [deviceAreaDialogOpen, setDeviceAreaDialogOpen] = useState(false);
  const [deviceAreaData, setDeviceAreaData] = useState({});
  const authInfo = useContext(userInfoContext);
  const [noPermissionMsg, setNoPermissionMsg] = useState("");
  const [permission, setPermission] = useState(NO_PERMISSION);
  useEffect(() => {
    var pageInfo = sessionStorage.getItem('pages');
    if (pageInfo !== "undefined") {
      var pageInfoObj = JSON.parse(pageInfo);
      var pageArray = pageInfoObj.filter(page => page.name.toLowerCase().includes("dashboard"));
      if (pageArray !== undefined && pageArray.length > 0) {
        var page = pageArray[0];
        console.log('page : ' + JSON.stringify(page));
        if (page.permission === undefined) {
          setNoPermissionMsg(NO_PERMISSION_WARNING);
        }
        setPermission(page.permission);
      }
    }
  }, [authInfo.pages]);

  const openDeviceArea = (areaData) => {
    setDeviceAreaData(areaData);
    setDeviceAreaDialogOpen(true);
  };
  const closeDeviceArea = () => {
    setDeviceAreaDialogOpen(false);
  };
  useEffect(() => {
    if (sessionStorage.getItem('naviwatch_permission') !== "true")
      history.push('/')
  }, []);


  return (
    <div className={classes.root}>
      {permission >= READ_PERMISSION ?
        <Grid
          container
          spacing={4}
        >
          <Grid
            item
            lg={3}
            sm={6}
            md={4}
            xl={3}
            xs={12}
          >
            <RealtimeCounter type="device" />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            md={4}
            xl={3}
            xs={12}
          >
            <RealtimeCounter type="user" />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            md={4}
            xl={3}
            xs={12}
          >
            <RealtimeCounter type="connectUser" />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            md={4}
            xl={3}
            xs={12}
          >
            <RealtimeCounterTODO type="appUser" />
          </Grid>

          <Grid
            item
            lg={6}
            sm={6}
            md={4}
            xl={6}
            xs={12}
          >
            <Card>
              <CardContent>
                <div className={classes.content}>
                  <StatusIcon type="1stConverter" />
                  <StatusIcon type="2ndConverter" />
                  <StatusIcon type="db" />
                </div>
              </CardContent>
            </Card>
          </Grid>

          <Grid
            item
            lg={6}
            sm={6}
            md={4}
            xl={6}
            xs={12}
          >
            <Card>
              <CardContent>
                <div className={classes.content}>
                  <StatusIcon type="ags" />
                  <StatusIcon type="proxy" />
                  <StatusIcon type="privacy" />
                  <StatusIcon type="servicetoolkit" />
                </div>
              </CardContent>
            </Card>
          </Grid>

          <Grid
            item
            lg={6}
            md={12}
            xl={6}
            xs={12}
          >
            <DeviceArea dialogCallback={openDeviceArea} />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <ModelCounter status={"online"} />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <ModelCounter status={"all"} />
          </Grid>
          <Grid
            item
            lg={12}
            sm={12}
            xl={12}
            xs={12}
          >
            <WeeklyChart day="14" metricName="User" />
          </Grid>
          <DeviceAreaDialog openStatus={deviceAreaDialogOpen} closeFunc={closeDeviceArea} data={deviceAreaData} />
        </Grid>
        :
        <Typography>{noPermissionMsg}</Typography>
      }
    </div>
  );
};

export default Dashboard;
