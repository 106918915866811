import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { PushToolbar, PushDialog } from './components';
import useAsync from '../../../asyncNet';
import axios from 'axios';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  STRING_MODEL_NR30D,
  STRING_MODEL_NR35D,
  STRING_MODEL_NR40D,
  STRING_MODEL_NRM35D,
  STRING_MODEL_NR6XD,
  STRING_MODEL_NRM6XD,
  STRING_MODEL_ALL,
  STRING_SUCCESS_PUSH_TITLE,
  STRING_SUCCESS_PUSH_CONTENT,
  STRING_FAIL_PUSH_TITLE,
  STRING_FAIL_PUSH_CONTENT
} from '../StringTable/StringTable';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  }
}));


const Push = ({ history }) => {
  const classes = useStyles();
  const [state, tryRefetch] = useAsync(sendPush, [], true);
  const { loading, data: pushRes, error } = state;
  const [pushCond, setPushCond] = useState({
    modelCode: '',
    title: '',
    content: ''
  });
  const [dialogString, setDialogString] = useState({
    title: '',
    content: ''
  });

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  async function sendPush() {
    return;
    const response = await axios.get(/*`https://yiws06c8pb.execute-api.ap-northeast-2.amazonaws.com/naviwatch/api/v1/monitoring/server/send-push?`+
      `modelCode=${pushCond.modelCode}&title=${pushCond.title}&content=${pushCond.content}`,*/
      {
        headers: {
          'authorizer': sessionStorage.getItem('idToken')
        }
      });
    console.log('response :' + JSON.stringify(response));
    if (response.data.code === 200) {
      setDialogString({
        title: STRING_SUCCESS_PUSH_TITLE,
        content: STRING_SUCCESS_PUSH_CONTENT
      });
    } else {
      setDialogString({
        title: STRING_FAIL_PUSH_TITLE,
        content: STRING_FAIL_PUSH_CONTENT
      });
    }
    handleClickOpen();
    setPushCond({
      modelCode: '',
      title: '',
      content: ''
    });
    return response.data;
  }

  const pushCondition = (pushInfo) => {
    console.log("call push " + JSON.stringify(pushInfo));
    var modelNumber = '';
    if (pushInfo.model === STRING_MODEL_NR30D) {
      modelNumber = 1;
    } else if (pushInfo.model === STRING_MODEL_NR35D) {
      modelNumber = 2;
    } else if (pushInfo.model === STRING_MODEL_NR40D) {
      modelNumber = 3;
    } else if (pushInfo.model === STRING_MODEL_NRM35D) {
      modelNumber = 4;
    } else if (pushInfo.model === STRING_MODEL_NR6XD) {
      modelNumber = 5;
    } else if (pushInfo.model === STRING_MODEL_NRM6XD) {
      modelNumber = 6;
    } else if (pushInfo.model === STRING_MODEL_ALL) {
      modelNumber = 0;
    }
    setPushCond(
      {
        modelCode: modelNumber,
        title: pushInfo.title,
        content: pushInfo.content,
      });
  };

  useEffect(() => {
    if (pushCond.modelCode !== '' && pushCond.title !== '' && pushCond.content !== '') {
      tryRefetch();
    }
  }, [pushCond]);


  useEffect(() => {
    if (sessionStorage.getItem('naviwatch_permission') !== 'true')
      history.push('/');
  }, []);


  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={loading} >
        <CircularProgress color={'primary'} />
      </Backdrop>
      <PushToolbar callbackFunc={pushCondition} />
      <PushDialog open={open} handleClose={handleClose} dialogString={dialogString} />
    </div>
  );
};

export default Push;
