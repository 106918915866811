import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Backdrop, CircularProgress } from '@material-ui/core';
import { UsersToolbar, UsersTable } from './components';
import useAsync from '../../../asyncNet';
import publicIp from 'public-ip';
import { userSearch } from '../APIs/tokapis';
import { userInfoContext } from '../../../App';
import { NO_PERMISSION, NO_PERMISSION_WARNING, READ_PERMISSION, NO_WHITELIST_INSIDE_WARNING } from '../../Common/Config/naviwatchConfig';
import { naviwatchCheckWhiteListValidation } from '../../Common/APIs/naviwatchApis';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));


const User = ({ history }) => {
  const classes = useStyles();
  const [query, setQuery] = useState({
    userId: '',
    userEmail: '',
    userName: '',
    userPhone: ''
  });
  const [userState, trySearchUser] = useAsync(getUserSearch, [], true);
  const { loading: userSearchLoading, data: users } = userState;
  const [ipCheckState, tryCheckWhitelist] = useAsync(checkIpValidation, [], true);
  const { loading: ipCheckLoading, data: ipValid } = ipCheckState;
  const [ipAddress, setIpAddress] = useState(undefined);
  const authInfo = useContext(userInfoContext);
  const [noPermissionMsg, setNoPermissionMsg] = useState("");
  const [permission, setPermission] = useState(NO_PERMISSION);
  useEffect(() => {
    var pageInfo = sessionStorage.getItem('pages');
    if (pageInfo !== "undefined") {
      var pageInfoObj = JSON.parse(pageInfo);
      var pageArray = pageInfoObj.filter(page => page.name.toLowerCase().includes("user"));
      if (pageArray !== undefined && pageArray.length > 0) {
        var page = pageArray[0];
        if (page.permission === undefined) {
          setNoPermissionMsg(NO_PERMISSION_WARNING);
        }
        setPermission(page.permission);
      }
    }
  }, [authInfo.pages]);

  async function getUserSearch() {
    return userSearch(query.userId, query.userName, query.userEmail, query.userPhone, ipAddress);
  }

  async function checkIpValidation() {
    return naviwatchCheckWhiteListValidation(ipAddress);
  }

  const searchUsers = (searchdata) => {
    setQuery(
      {
        userId: searchdata.userId,
        userEmail: searchdata.userEmail,
        userName: searchdata.userName,
        userPhone: searchdata.userPhone
      });
  };

  useEffect(() => {
    if (query.userId !== '' || query.userName !== '' || query.userEmail !== '' || query.userPhone !== '') {
      trySearchUser();
    }
  }, [query]);

  useEffect(() => {
    if (ipCheckState.data === false) {
      setNoPermissionMsg(NO_WHITELIST_INSIDE_WARNING + `(${ipAddress})`);
    }
  }, [ipCheckState]);

  useEffect(() => {
    if (ipAddress !== undefined) {
      tryCheckWhitelist();
    }
  }, [ipAddress]);

  useEffect(() => {
    if (sessionStorage.getItem('naviwatch_permission') !== 'true')
      history.push('/');
    (async () => {
      setIpAddress(await publicIp.v4());
    })();
  }, []);

  return (
    <div>
      { permission >= READ_PERMISSION && ipValid === true ?
        <div className={classes.root}>
          <Backdrop className={classes.backdrop} open={userSearchLoading || ipCheckLoading}>
            <CircularProgress color={'primary'} />
          </Backdrop>
          <UsersToolbar callbackFunc={searchUsers} />
          <div className={classes.content}>
            {
              userSearchLoading === false && users !== null && users !== undefined && <UsersTable users={users.users} ip={ipAddress} />
            }
          </div>
        </div> :
        <Typography className={classes.root}>{noPermissionMsg}</Typography>
      }
    </div>
  );
};

export default User;