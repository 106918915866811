export default [
  {
    PRODUCT_ID: 0,
    PRODUCT_NAME: "hydrofurnacecontroller",
    PRODUCT_IMAGE: "/images/hydrofurnace/hydrofurnacecontroller.png",
    PRODUCT_ENABLE: true
  },
  {
    PRODUCT_ID: 1,
    PRODUCT_NAME: "hydrofurnacefrontpanel",
    PRODUCT_IMAGE: "/images/hydrofurnace/hydrofurnacefrontpanel.png",
    PRODUCT_ENABLE: true
  }
];
