import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import useAsync from '../../../../../asyncNet';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  Typography
} from '@material-ui/core';
import "react-datepicker/dist/react-datepicker.css";
import dateformat from 'dateformat';
import { getDormantUserCount } from '../../../APIs/tokapis'
import {
  STRING_SEARCH,
  STRING_DORMANCY_INFO,
  STRING_INPUT_ID,
  STRING_DORMANCY_INFO_IS_1,
  STRING_DORMANCY_INFO_IS_2,
  STRING_DORMANCY_INFO_IS_3,
  STRING_ID,
  STRING_SEND_DATE,
  STRING_DATE_FORMAT_LOWER_CASE,
  STRING_DORMANCY_DATE,
  STRING_CLEAN
} from '../../../StringTable/StringTable';

const useStyles = makeStyles((theme) => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  },
  title: {
    fontWeight: 700
  },
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  containerItems: {
    display: 'flex',
    alignItems: 'ceneter'
  }
}));


const DormancyToolbar = props => {
  const { className, callbackFunc, ...rest } = props;

  const classes = useStyles();
  const [state, tryRefetch] = useAsync(() => getDormantUserCount(), []);
  const { loading, data: dormancyCount, error } = state;

  const [values, setValues] = useState({
    userId: '',
    from: '',
    to: ''
  });

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };


  const cleanSearchInput = () => {
    setValues({
      userId: '',
      from: '',
      to: ''
    });
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        title={STRING_DORMANCY_INFO}
        subheader={STRING_DORMANCY_INFO_IS_1 + (dateformat(new Date(), STRING_DATE_FORMAT_LOWER_CASE)).toString() + STRING_DORMANCY_INFO_IS_2 + (dormancyCount == null ? '-' : dormancyCount.notificationCount) + STRING_DORMANCY_INFO_IS_3}
      />
      <Divider />
      <CardContent>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            md={12}
            xs={12}
            lg={12}
            xl={12}
            className={classes.containerItems}
          >
            <Typography
              align="center"
              variant="body1" className={classes.container} noValidate >
              {STRING_SEND_DATE}{' :'}
            </Typography>
            <TextField
              id="from"
              name="from"
              label="From"
              type="date"
              className={classes.textField}
              value={values.from}
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Typography align="center"
              variant="body1" className={classes.container} noValidate>
              ~
            </Typography>
            <TextField
              id="to"
              name="to"
              label="To"
              type="date"
              className={classes.textField}
              value={values.to}
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Typography align="center"
              variant="body1" className={classes.container} noValidate>
              {STRING_ID}{' :'}
            </Typography>
            <TextField
              style={{ paddingLeft: 10 }}
              label={STRING_INPUT_ID}
              margin="dense"
              name="userId"
              // disabled={values.condition!==STRING_SEARCH_SEPERATE}
              onChange={handleChange}
              value={values.userId}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>
        <Button
          color="primary"
          variant="contained"
          onClick={cleanSearchInput}
        >
          {STRING_CLEAN}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => callbackFunc(values)}
        >
          {STRING_SEARCH}
        </Button>
      </CardActions>
    </Card >
  );

};

DormancyToolbar.propTypes = {
  className: PropTypes.string
};

export default DormancyToolbar;
