import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography
} from '@material-ui/core';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';

import useAsync from '../../../../../asyncNet';
import { dashboardGetMobileCount } from '../../../APIs/mateApis';

const STRING_MOBILE_TITLE='모바일 기기 분포';


const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  chartContainer: {
    position: 'relative',
    height: '300px'
  },
  stats: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center'
  },
  device: {
    textAlign: 'center',
    padding: theme.spacing(1)
  },
  deviceIcon: {
    color: theme.palette.icon
  }
}));

const MobileCounter = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const theme = useTheme();
  const [state] = useAsync(()=>dashboardGetMobileCount("all"), []);
  const { data: mobiles } = state;
  const [mobileChart, setMobileChart] = useState({});
  const [mobileData, setMobileData] = useState( []);

  useEffect(()=>{
    try{
      if(mobiles!==null){
        console.log('mobiles : ', JSON.stringify(mobiles))

        // update model data
        if(mobiles['mobileList'].length>=2) {
          // update chart
          setMobileChart(mobileChart=>({
            ...mobileChart,
            datasets: [
              {
                data: mobiles['mobileCount'],
                backgroundColor: [
                  theme.palette.primary.main,
                  theme.palette.info.main,
                  theme.palette.error.main
                ],
                borderWidth: 8,
                borderColor: theme.palette.white,
                hoverBorderColor: theme.palette.white
              }
            ],
            labels: mobiles['mobileList']
          }));

          var sum = mobiles['mobileCount'][0] + mobiles['mobileCount'][1];
          var firstValue = Math.floor(mobiles['mobileCount'][0] * (100 / sum));
          //var secondValud = Math.floor(models['mobile'][1]['count'] * (100 / sum));
          //var secondValud = Math.floor(models['mobileCount'][1] * (100 / sum));
          //var thirdValud = Math.floor(models['mobileCount'][2] * (100 / sum));

          setMobileData(
            [
              {
                title: mobiles['mobileList'][0],
                value: firstValue,
                icon: <PhoneAndroidIcon style={{ color: theme.palette.primary.main }}/>,
                color: theme.palette.primary.main
              },
              {
                title: mobiles['mobileList'][1],
                value: 100-firstValue,
                icon: <PhoneAndroidIcon style={{ color: theme.palette.info.main }}/>,
                color: theme.palette.info.main
              }
            ]
          )
        }
      }
    }catch (e) {
      console.log('get mobiles count failed: '+JSON.stringify(e));
    }

  }, [mobiles]);

  const options = {
    legend: {
      display: false
    },
    responsive: true,
    maintainAspectRatio: false,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      borderWidth: 1,
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.white,
      titleFontColor: theme.palette.text.primary,
      bodyFontColor: theme.palette.text.secondary,
      footerFontColor: theme.palette.text.secondary
    }
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        title={STRING_MOBILE_TITLE}
      />
      <Divider />
      <CardContent>
        <div className={classes.chartContainer}>
          {mobileChart!==null &&
            <Doughnut
              data={mobileChart}
              options={options}
            />
          }
        </div>
        <div className={classes.stats}>
          {mobileData!==null && mobileData.map(mobile => (
            <div
              className={classes.device}
              key={mobile.title}
            >
              <span className={classes.deviceIcon}>{mobile.icon}</span>
              <Typography variant="body1">{mobile.title}</Typography>
              <Typography
                style={{ color: mobile.color }}
                variant="h2"
              >
                {mobile.value}%
              </Typography>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};

MobileCounter.propTypes = {
  className: PropTypes.string
};

export default MobileCounter;
