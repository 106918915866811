import React, { useEffect, useState } from 'react';
import {
  Dialog, DialogActions, DialogContent, Button,
  DialogTitle, Divider, Typography, makeStyles,
  Checkbox, Input, Backdrop, CircularProgress, Accordion, AccordionSummary, AccordionDetails
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import { firmwareAdd, getS3Path, getModelName, firmwareGetGroupUsers } from '../../../APIs/mateApis';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import useAsync from '../../../../../asyncNet';
import { CopyToClipboard } from 'react-copy-to-clipboard';


const STRING_TITLE = '펌웨어 추가';
const STRING_MAJOR_VERSION = 'MAJOR 버전 :';
const STRING_MINOR_VERSION = 'MINOR 버전 :';
const STRING_MODEL_NAME = '모델 이름 :';
const STRING_FIRMWARE_BUILD_NUMBER = 'BUILD 넘버 :';

const STRING_FW_URL = '펌웨어 업로드 :';
const STRING_FW_TYPE = '펌웨어 Type :';
const STRING_FW_TYPE_1 = '1 (MCU)';
const STRING_FW_TYPE_2 = '2 (Wifi)';
const STRING_FW_TYPE_3 = '3 (Certificate)';

const STRING_ACTIVE = 'ACTIVE 유무 :';
const STRING_FORCE = 'FORCE 유무 :';
const STRING_GROUP_OTA = 'GROUP OTA 유무 :';
const STRING_FW_DESCRIPTION = '펌웨어 설명 :';
const STRING_FILE_NAME = '파일 :';
const STRING_FILE_SIZE = '파일 크기 :';
const STRING_FILE_SELECT = '파일 선택';
const STRING_ALERT_FW_UPLOAD = '업로드시 사전 검증 완료된 펌웨어만 등록해주세요.';
const STRING_CLOSE = '취소';
const STRING_UPLOAD = '업로드';
const STRING_ADD_GROUP = '그룹 추가';
const GROUP_EMPTY_WARNING = "ACTIVE 상태에서 Group OTA를 설정할 경우 Group User는 반드시 등록해야 합니다.";
const GROUP_NON_JSON_WARNING = "JSON 값이 비정상입니다.";

const useStyles = makeStyles(theme => ({
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  linear:{
    display: 'flex',
    alignItems: 'flex-end'
  },
  clipboardBtn:{
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1)

  },
  datas:{
    marginLeft: theme.spacing(2)
  },
  dataSelection:{
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0)
  },
  icons:{
    marginLeft: theme.spacing(2),
    padding: theme.spacing(0)
  },
  fwDescription:{
    marginLeft: theme.spacing(2),
    width: '65%'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));

const placeHolderValue = [
  {
    "USERNAME": "Sample1"
  },
  {
    "USERNAME": "Sample2"
  }
];

const fwFileType = [
  {
    value: STRING_FW_TYPE_1
  },
  {
    value: STRING_FW_TYPE_2
  },
  {
    value: STRING_FW_TYPE_3
  }
];

const AddFirmwareDialog  = props => {
  const { open, ipAddress, handleClose, refetch, modelCode } = props;
  const [forcedflag, setForcedFlag] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeflag, setActiveFlag] = useState(false);
  const [groupOtaflag, setGroupOtaFlag] = useState(false);
  const [fileBuffer, setFileBuffer] = useState("");
  const [selectedFileName, setSelectedFileName] = useState("");
  const [selectedFileSize, setSelectedFileSize] = useState(0);
  const [majorVersion, setMajorVersion] = useState("");
  const [minorVersion, setMinorVersion] = useState("");
  const [buildNumber, setbuildNumber] = useState("");
  const [fwDescription, setFwDescription] = useState("");
  const [ updatedUserList, setUpdatedUserList ] = useState("");
  const [ warningMessage, setWarningMessage ] = useState("");
  const [ fwTypeName, setFwTypeName ] = useState(fwFileType[0].value);

  const handleCloseClick = () =>{
    setForcedFlag(false);
    setActiveFlag(false);
    setGroupOtaFlag(false);
    setFileBuffer("");
    setSelectedFileName("");
    setSelectedFileSize(0);
    setMajorVersion("");
    setbuildNumber("");
    setMinorVersion("");
    setFwDescription("");
    handleClose();
  };

  const addFirmwareFunction = () =>{
    var ret;
    if(fileBuffer.length!==0) {
      var arrayValue = null;
      if(updatedUserList.length>0) {
        arrayValue = updatedUserList.replace(/(\r\n|\n|\r)/gm, "");
      }
      var splitedFileName = selectedFileName.split(".");
      var fwId = splitedFileName[0];
      var fwType = fwFileType.map(function (e){ return e.value}).indexOf(fwTypeName) + 1;
      console.log('fwType : '+fwType)
      if (fwId.length < 40) {
        ret = firmwareAdd(sessionStorage.getItem('email'), ipAddress,
          fwId, fwDescription, arrayValue, getS3Path(modelCode), majorVersion,
          minorVersion, buildNumber, fwType, selectedFileSize, modelCode,
          activeflag, forcedflag, groupOtaflag, fileBuffer);
        setLoading(true);
      }
      handleCloseClick()
    }
    return ret;
  };
  const [state, callAddFirmware] = useAsync(addFirmwareFunction, [], true);
  const { addFirmLoading, data: addResponse } = state;

  const classes = useStyles();


  useEffect(()=> {
    if (addResponse !== null && addResponse !== undefined){
      if (addResponse.code === 200) {
        refetchList("펌웨어 등록이 성공하였습니다.")
      } else if (addResponse.code === 606) {
        refetchList("majorVersion, minorVersion이 동일한 펌웨어가 이미 존재합니다.")
      } else {
        refetchList("예상치 못한 에러가 발생하였습니다.")
      }
    }
  }, [addResponse]);

  useEffect(()=>{
    if(majorVersion.length!==0 && majorVersion.length!==2){
      setWarningMessage("majorVersion은 반드시 2자리수를 만족해야 합니다.")
    }
    if(minorVersion.length!==0 &&minorVersion.length!==2){
      setWarningMessage("minorVersion은 반드시 2자리수를 만족해야 합니다.")
    }
    if(buildNumber.length!==0 && buildNumber.length!==4){
      setWarningMessage("buildNumber은 반드시 4자리수를 만족해야 합니다.")
    }

    if(majorVersion.length===2 && minorVersion.length===2 && buildNumber.length ===4){
      setWarningMessage("");
    }

  },[majorVersion, minorVersion, buildNumber])

  const handleForcedFlag = () =>{
    if(forcedflag){
      setForcedFlag(false);
    }else{
      setForcedFlag(true);
    }
  };

  const fwTypeChange = (event) => {
    event.persist();
    setFwTypeName(event.target.value)
  };

  const handleActiveFlag = () =>{
    if(groupOtaflag && !activeflag){
      if(updatedUserList.length === 0){
        setWarningMessage(GROUP_EMPTY_WARNING)
      }
    }else{
      setWarningMessage("")
    }
    if(activeflag){
      setActiveFlag(false);
    }else{
      setActiveFlag(true);
    }
  };

  const handleGroupOtaFlag = () =>{
    if(!groupOtaflag && activeflag){
      if(updatedUserList.length === 0){
        setWarningMessage(GROUP_EMPTY_WARNING)
      }
    }else{
      setWarningMessage("")
    }
    if(groupOtaflag){
      setGroupOtaFlag(false);
    }else{
      setGroupOtaFlag(true);
    }
  };

  function _arrayBufferToBase64( buffer ) {
    var binary = '';
    var bytes = new Uint8Array( buffer );
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
  }

  const handleUploadClick = event => {
    console.log();
    var file = event.target.files[0];
    const reader = new FileReader();
    var url =    reader.readAsArrayBuffer(file);
    reader.onloadend = function(e) {
      if(file.name.length>40){
        setWarningMessage("파일 이름은 40자를 넘을 수 없습니다.")
      } else if(file.name.match(/[a-zA-Z0-9_]+.?[a-zA-Z0-9_]+/)[0].length !== file.name.length){
        setWarningMessage("파일 이름은 대소문자, 숫자, 언더바(_) 만 포함할 수 있습니다. :"+file.name)
      } else if(file.size>4000000){
        setWarningMessage("펌웨어 파일 사이즈는 4MB를 넘을 수 없습니다.:"+file.size)
      } else {
        setWarningMessage("")
        var data = reader.result;
        var base64String = _arrayBufferToBase64(data);
        setFileBuffer(base64String);
        setSelectedFileName(file.name);
        setSelectedFileSize(file.size);
      }
    }.bind(this);
  };



  const refetchList = (warningMessage) => {
    setLoading(false);
    refetch(warningMessage);
  };

  const handleUpload = () => {
      callAddFirmware();
  };

  const changeMajorNumber = event => {
    event.persist();
    setMajorVersion(event.target.value);
  };

  const changebuildNumber = event =>{
    event.persist();
    setbuildNumber(event.target.value);
  };

  const changeMinorNumber = event => {
    event.persist();
    setMinorVersion(event.target.value);
  };

  const changeFWDescription = event => {
    event.persist();
    setFwDescription(event.target.value);
  };


  const editUpdateUser = event =>{
    setUpdatedUserList(event.target.value);
  };

  useEffect(()=>{
    if(activeflag && groupOtaflag){
      if(updatedUserList.length===0){
        setWarningMessage(GROUP_EMPTY_WARNING)
      }
      else if(updatedUserList.length>0) {
        var arrayValue = updatedUserList.replace(/(\r\n|\n|\r)/gm, "");
        try {
          var parsedJson = JSON.parse(arrayValue);
          var count = parsedJson.length;
          if( count >= 1){
            if(Object.keys(parsedJson[0]).includes("USERNAME")===true){
              setWarningMessage("")
            }else{
              setWarningMessage(GROUP_NON_JSON_WARNING)
            }
          }
        } catch (e) {
          setWarningMessage(GROUP_NON_JSON_WARNING)
        }
      }else {
          setWarningMessage(GROUP_EMPTY_WARNING)
        }
      }
    } ,[updatedUserList]);

  return (
    <div>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color={'primary'}/>
      </Backdrop>
      <Dialog
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{STRING_TITLE}</DialogTitle>
        <DialogContent>
          <Alert severity={'error'}><span style={{ color: 'red' }}>{STRING_ALERT_FW_UPLOAD}</span></Alert>
          <Divider className={classes.divider}/>
          <div className={classes.linear}>
            <Typography variant="h6">{STRING_MAJOR_VERSION}</Typography>
            <Input className={classes.datas}
                   placeholder={STRING_MAJOR_VERSION}
                   inputProps={{ 'aria-label': 'description' }}
                   onChange={changeMajorNumber}
            />
          </div>
          <Divider className={classes.divider}/>
          <div className={classes.linear}>
            <Typography variant="h6">{STRING_MINOR_VERSION}</Typography>
            <Input className={classes.datas}
                   placeholder={STRING_MINOR_VERSION}
                   inputProps={{ 'aria-label': 'description' }}
                   onChange={changeMinorNumber}
            />
          </div>
          <Divider className={classes.divider}/>
          <div className={classes.linear}>
            <Typography variant="h6">{STRING_FIRMWARE_BUILD_NUMBER}</Typography>
            <Input className={classes.datas}
                   placeholder={STRING_FIRMWARE_BUILD_NUMBER}
                   inputProps={{ 'aria-label': 'description' }}
                   onChange={changebuildNumber}
            />
          </div>
          <Divider className={classes.divider}/>
          <div className={classes.linear}>
            <Typography variant="h6">{STRING_MODEL_NAME}</Typography>
            <Typography className={classes.datas} variant="h6">{getModelName(modelCode)}</Typography>
          </div>
          <Divider className={classes.divider}/>
          <div className={classes.linear}>
            <Typography variant="h6">{STRING_FW_TYPE}</Typography>
            <TextField
              className={classes.dataSelection}
              margin="dense"
              name="fileType"
              onChange={fwTypeChange}
              select
              // eslint-disable-next-line react/jsx-sort-props
              SelectProps={{ native: true }}
              value={fwTypeName}
              variant="outlined"
            >
              {fwFileType.map(option => (
                <option
                  key={option.value}
                  value={option.value}
                >
                  {option.value}
                </option>
              ))}
            </TextField>
          </div>

          <Divider className={classes.divider}/>
          <div className={classes.linear}>
            <Typography variant="h6">{STRING_ACTIVE}</Typography>
            <Checkbox className={classes.icons}
                      checked={activeflag}
                      onChange={handleActiveFlag}
                      color="primary"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </div>
          <Divider className={classes.divider}/>
          <div className={classes.linear}>
            <Typography variant="h6">{STRING_FORCE}</Typography>
            <Checkbox className={classes.icons}
                      checked={forcedflag}
                      onChange={handleForcedFlag}
                      color="primary"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}/>
          </div>
          <Divider className={classes.divider}/>
          <div className={classes.linear}>
            <Typography variant="h6">{STRING_GROUP_OTA}</Typography>
            <Checkbox className={classes.icons}
                      checked={groupOtaflag}
                      onChange={handleGroupOtaFlag}
                      color="primary"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}/>
          </div>
          {groupOtaflag===true&&
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <Typography className={classes.heading}>{STRING_ADD_GROUP}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                id="update-user-id"
                multiline
                fullWidth
                rows={15}
                placeholder={JSON.stringify(placeHolderValue, null, 2)}
                onChange={editUpdateUser}
                value={updatedUserList}
                variant="outlined"
              />
            </AccordionDetails>
            <div className={classes.clipboardBtn}>
              <CopyToClipboard text={updatedUserList.length===0?JSON.stringify(placeHolderValue, null, 2):updatedUserList}>
                <Button
                  variant="outlined"
                >
                  Copy to clipboard
                </Button>
              </CopyToClipboard>
            </div>
          </Accordion>}
          <Divider className={classes.divider}/>
          <div className={classes.linear}>
            <Typography variant="h6">{STRING_FW_URL}</Typography>
            <Button className={classes.icons} component="label"
            onChange={handleUploadClick}>
              {STRING_FILE_SELECT}
              <input
                type="file"
                hidden
              />
            </Button>
          </div>
          <Divider className={classes.divider}/>
          <div className={classes.linear}>
            <Typography variant="h6">{STRING_FW_DESCRIPTION}</Typography>
          </div>
          <TextField
            id="change-desc"
            multiline
            fullWidth
            rows={5}
            onChange={changeFWDescription}
            value={fwDescription}
            variant="outlined"
          />
          <Divider className={classes.divider}/>
            <div className={classes.linear}>
              <Typography variant="h6">{STRING_FILE_NAME}</Typography>
              <Typography className={classes.datas} variant="h6">{selectedFileName}</Typography>
            </div>
          <Divider className={classes.divider}/>
            <div className={classes.linear}>
              <Typography variant="h6">{STRING_FILE_SIZE}</Typography>
              <Typography className={classes.datas} variant="h6">{selectedFileSize===0?0:selectedFileSize+" bytes"}</Typography>
            </div>
          <Divider className={classes.divider}/>
          <Typography>{warningMessage}</Typography>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseClick} color="primary" autoFocus>
            {STRING_CLOSE}
          </Button>
          <Button onClick={handleUpload} color="primary"
                  disabled={warningMessage.length>0}
                  autoFocus>
            {STRING_UPLOAD}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

AddFirmwareDialog.propTypes = {
  className: PropTypes.string
};

export default AddFirmwareDialog;
