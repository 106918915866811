import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DeviceToolbar from './components/DeviceToolbar';
import DeviceTable from './components/DeviceTable';
import publicIp from 'public-ip';
import useAsync from '../../../asyncNet';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { deviceSearch } from '../APIs/tokapis';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));


const Device = ({ history }) => {
  const classes = useStyles();
  const [query, setQuery] = useState({
    deviceId: ''
  });
  const [ip, setIp] = useState('');
  const [state, tryRefetch] = useAsync(()=> deviceSearch(query.deviceId, ip), [], true);
  const { loading, data: devices, error } = state;
  

  useEffect(() => {
    if (query.deviceId !== '') {
      tryRefetch();
    }
  }, [query]);

  const searchDevice = (search) => {
    setQuery({
      deviceId: search.deviceId
    });
  };


  useEffect(() => {
    if (sessionStorage.getItem('naviwatch_permission') !== 'true')
      history.push('/');
    (async () => {
      setIp(await publicIp.v4());
    })();
  }, []);


  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color={'primary'}/>
      </Backdrop>
      <DeviceToolbar callbackFunc={searchDevice}/>
      <div className={classes.content}>
        {
          loading === false && devices !== null && devices !== undefined && <DeviceTable devices={devices.devices} ip={ip}/>
        }
      </div>
    </div>
  );
};

export default Device;
