import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Card, Typography, Avatar, Button } from '@material-ui/core';
import useAsync from '../../../../../asyncNet';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { Link } from 'react-router-dom'
import { getRdsMetric, getEC2Metric, getPrivacyEC2Metric } from '../../../APIs/tokapis';
import {
  STRING_STATUS_CON1,
  STRING_STATUS_CON2,
  STRING_DB_SERVER,
  STRING_AGS_SERVER,
  STRING_PROXY_SERVER,
  STRING_PRIVACY_SERVER,
  STRING_SERVICETOOLKIT_SERVER,
  STRING_EC2_AGS,
  STRING_EC2_1ST_CONVERTER_A,
  STRING_EC2_2ND_CONVERTER_A,
  STRING_EC2_PROXY,
  STRING_EC2_SERVICETOOLKIT,
  STRING_EC2_PRIVACY,
  STRING_RDS
} from '../../../StringTable/StringTable';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    width: '100%',
    height: '100%'
  },
  title: {
    fontWeight: 700
  },
  warningAvatar: {
    backgroundColor: theme.palette.warning.main,
    height: 56,
    width: 56
  },
  stableAvatar: {
    backgroundColor: theme.palette.success.main,
    height: 56,
    width: 56
  },
  unstableAvatar: {
    backgroundColor: theme.palette.error.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  }
}));

async function getrdsMetric(rdsName, metricName, unit) {
  const timelineSec = 60 * 30;
  const timePeriodSec = 60 * 30;
  //endtimeSec is current time
  const endtimeSec = Math.floor(new Date() / 1000);

  return getRdsMetric(rdsName, metricName, endtimeSec, timelineSec, timePeriodSec, unit);
}

async function getec2Metric(ec2Name, metricName) {
  const timelineSec = 60 * 30;
  const timePeriodSec = 60 * 30;
  //endtimeSec is current time
  const endtimeSec = Math.floor(new Date() / 1000);

  if (ec2Name === STRING_EC2_PRIVACY) {
    return getPrivacyEC2Metric(ec2Name, metricName, endtimeSec, timelineSec, timePeriodSec);
  } else {
    return getEC2Metric(ec2Name, metricName, endtimeSec, timelineSec, timePeriodSec);
  }
}

const Status = props => {
  if (props.type === '1stConverter' || props.type === '2ndConverter' || props.type === 'db' || props.type === 'ags' || props.type === 'proxy' || (props.type === 'privacy') || (props.type === 'servicetoolkit'))
    return (<CheckCircleOutlineIcon className={props.classname} />);
  else
    return null;
};

const StatusIcon = props => {
  const { type } = props;
  const getTitle = () => {
    if (type === '1stConverter') {
      return STRING_STATUS_CON1;
    }
    else if (type === '2ndConverter') {
      return STRING_STATUS_CON2;
    }
    else if (type === 'db') {
      return STRING_DB_SERVER;
    }
    else if (type === 'ags') {
      return STRING_AGS_SERVER;
    }
    else if (type === 'proxy') {
      return STRING_PROXY_SERVER;
    }
    else if (type === 'privacy') {
      return STRING_PRIVACY_SERVER;
    }
    else if (type === 'servicetoolkit') {
      return STRING_SERVICETOOLKIT_SERVER;
    }
  };

  var title = getTitle();

  const getStatus1Function = () => {
    if (type === '1stConverter') {
      return getec2Metric(STRING_EC2_1ST_CONVERTER_A, "CPUUtilization");
    }
    else if (type === '2ndConverter') {
      return getec2Metric(STRING_EC2_2ND_CONVERTER_A, "CPUUtilization");
    }
    else if (type === 'db') {
      return getrdsMetric(STRING_RDS, "CPUUtilization", "Percent");
    }
    else if (type === 'ags') {
      return getec2Metric(STRING_EC2_AGS, "CPUUtilization");
    }
    else if (type === 'proxy') {
      return getec2Metric(STRING_EC2_PROXY, "CPUUtilization");
    }
    else if (type === 'privacy') {
      return getec2Metric(STRING_EC2_PRIVACY, "CPUUtilization");
    }
    else if (type === 'servicetoolkit') {
      return getec2Metric(STRING_EC2_SERVICETOOLKIT, "CPUUtilization");
    }
  };

  const getStatus2Function = () => {
    if (type === '1stConverter') {
      return getec2Metric(STRING_EC2_1ST_CONVERTER_A, "MemoryUtilization");
    }
    else if (type === '2ndConverter') {
      return getec2Metric(STRING_EC2_2ND_CONVERTER_A, "MemoryUtilization");
    }
    else if (type === 'db') {
      return getrdsMetric(STRING_RDS, "DatabaseConnections", "Count");
    }
    else if (type === 'ags') {
      return getec2Metric(STRING_EC2_AGS, "MemoryUtilization");
    }
    else if (type === 'proxy') {
      return getec2Metric(STRING_EC2_PROXY, "MemoryUtilization");
    }
    else if (type === 'privacy') {
      return getec2Metric(STRING_EC2_PRIVACY, "MemoryUtilization");
    }
    else if (type === 'servicetoolkit') {
      return getec2Metric(STRING_EC2_SERVICETOOLKIT, "MemoryUtilization");
    }

  };

  const [state1, tryRefetch1] = useAsync(getStatus1Function);
  const { data: metric1 } = state1;
  const [state2, tryRefetch2] = useAsync(getStatus2Function);
  const { data: metric2 } = state2;
  const classes = useStyles();
  const [classesAvatar, setClassAvatar] = useState(null);


  useEffect(() => {
    try {
      if (metric1 !== null && metric2 !== null && metric1['data']['average']['value'].length !== 0 && metric2['data']['average']['value'].length !== 0 &&
        metric1['data']['maximum']['value'] !== 0 && metric2['data']['maximum']['value'] !== 0) {
        if (type === 'db') {
          if (metric1['data']['average']['value'] < 70) {
            if (metric2['data']['maximum']['value'] < 900) {
              setClassAvatar(classes.stableAvatar);
            } else if (900 <= metric2['data']['average']['value'] && metric2['data']['maximum']['value'] < 1000) {
              setClassAvatar(classes.warningAvatar);
            } else {
              setClassAvatar(classes.unstableAvatar);
            }
          } else if (70 <= metric1['data']['average']['value'] && metric1['data']['maximum']['value'] < 90) {
            if (metric2['data']['maximum']['value'] < 900) {
              setClassAvatar(classes.stableAvatar);
            } else if (900 <= metric2['data']['average']['value'] && metric2['data']['maximum']['value'] < 1000) {
              setClassAvatar(classes.warningAvatar);
            } else {
              setClassAvatar(classes.unstableAvatar);
            }
          } else {
            if (metric2['data']['maximum']['value'] < 900) {
              setClassAvatar(classes.stableAvatar);
            } else if (900 <= metric2['data']['average']['value'] && metric2['data']['maximum']['value'] < 1000) {
              setClassAvatar(classes.warningAvatar);
            } else {
              setClassAvatar(classes.unstableAvatar);
            }
          }
        }
        else if (type === '1stConverter' || type === '2ndConverter' || type === 'ags' || type === 'proxy' || type === 'privacy' || type === 'servicetoolkit') {
          if (metric1['data']['average']['value'] < 70) {
            if (metric2['data']['average']['value'] < 70) {
              setClassAvatar(classes.stableAvatar);
            } else if (70 <= metric2['data']['average']['value'] && metric2['data']['average']['value'] < 90) {
              setClassAvatar(classes.warningAvatar);
            } else {
              setClassAvatar(classes.unstableAvatar);
            }
          } else if (70 <= metric1['data']['average']['value'] && metric1['data']['average']['value'] < 90) {
            if (metric2['data']['average']['value'] < 70) {
              setClassAvatar(classes.stableAvatar);
            } else if (70 <= metric2['data']['average']['value'] && metric2['data']['average']['value'] < 90) {
              setClassAvatar(classes.warningAvatar);
            } else {
              setClassAvatar(classes.unstableAvatar);
            }
          } else {
            if (metric2['data']['average']['value'] < 70) {
              setClassAvatar(classes.stableAvatar);
            } else if (70 <= metric2['data']['average']['value'] && metric2['data']['average']['value'] < 90) {
              setClassAvatar(classes.warningAvatar);
            } else {
              setClassAvatar(classes.unstableAvatar);
            }
          }
        }
      }
    } catch (e) {
      // console.log('metric update failed :' + JSON.stringify(e));
    }
  }, [metric1, metric2, type]);

  useEffect(() => {
    const timer = setInterval(function () {
      tryRefetch1();
      tryRefetch2();
    }, 60 * 1000);
    // console.log('setInterval intervalidx:' + timer);
    return () => {
      // console.log('clearinterval: ' + timer);
      clearInterval(timer);
    };
  }, []);

  return (
    <Card>
      <Link to="/smarttok/server">
        <Button>
          <div className={classes.content}>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              {title}
            </Typography>
            <Avatar className={classesAvatar}>
              <Status type={type} classname={classes.icon} />
            </Avatar>
          </div>
        </Button>
      </Link>
    </Card>
  );
};


StatusIcon.propTypes = {
  className: PropTypes.string
};

export default StatusIcon;
