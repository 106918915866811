import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import {
  DeviceArea, DeviceAreaDialog,
  ServerChart, MobileCounter, RealtimeCounter, WeeklyChart
} from './components';
import {
  NO_PERMISSION,
  NO_PERMISSION_WARNING,
  READ_PERMISSION,
} from '../../Common/Config/naviwatchConfig';
import { userInfoContext } from '../../../App';
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  }
}));

const Dashboard = ({match, history}) => {
  const classes = useStyles();
  const [deviceAreaDialogOpen, setDeviceAreaDialogOpen] = useState(false);
  const [deviceAreaData, setDeviceAreaData] = useState({});


  const authInfo =  useContext(userInfoContext);
  const [noPermissionMsg, setNoPermissionMsg] = useState("");
  const [permission, setPermission] = useState(NO_PERMISSION);
  useEffect(()=>{
    var pageInfo = sessionStorage.getItem('pages');
    if(pageInfo!=="undefined") {
      var pageInfoObj = JSON.parse(pageInfo);
      var pageArray = pageInfoObj.filter(page => page.name.toLowerCase().includes("dashboard"));
      if (pageArray !== undefined && pageArray.length > 0) {
        var page = pageArray[0];
        if(page.permission===undefined){
          setNoPermissionMsg(NO_PERMISSION_WARNING);
        }
        setPermission(page.permission);
      }
    }
  }, [authInfo.pages]);


  const openDeviceArea = (areaData) =>{
    setDeviceAreaData(areaData);
    setDeviceAreaDialogOpen(true);
  };
  const closeDeviceArea = () =>{
    setDeviceAreaDialogOpen(false);
  };
  useEffect(()=>{
    if(sessionStorage.getItem('naviwatch_permission')!=="true")
      history.push('/')
  });

  return (
    <div className={classes.root}>
      {permission>=READ_PERMISSION?
        <Grid
          container
          spacing={4}
        >
          <Grid
            item
            lg={4}
            sm={4}
            xl={4}
            xs={12}
          >
            <RealtimeCounter type="device" />
          </Grid>
          <Grid
            item
            lg={4}
            sm={4}
            xl={4}
            xs={12}
          >
            <RealtimeCounter type="user" />
          </Grid>
          <Grid
            item
            lg={4}
            sm={4}
            xl={4}
            xs={12}
          >
            <RealtimeCounter type="mobile" />
          </Grid>
          <Grid
            item
            lg={8}
            md={12}
            xl={8}
            xs={12}
          >
            <DeviceArea dialogCallback={openDeviceArea}/>
          </Grid>
          <Grid
            item
            lg={4}
            md={6}
            xl={4}
            xs={12}
          >
            <MobileCounter />
          </Grid>
          <Grid
            item
            lg={6}
            md={6}
            xl={6}
            xs={12}
          >
            <WeeklyChart metricName="User"/>
          </Grid>
          <Grid
            item
            lg={3}
            md={6}
            xl={3}
            xs={12}
          >
            <ServerChart metricName="CPUUtilization"/>
          </Grid>
          <Grid
            item
            lg={3}
            md={6}
            xl={3}
            xs={12}
          >
            <ServerChart metricName="MemoryUtilization" />
          </Grid>
          <DeviceAreaDialog openStatus={deviceAreaDialogOpen} closeFunc={closeDeviceArea} data={deviceAreaData}/>
        </Grid>:
        <Typography>{noPermissionMsg}</Typography>
      }
    </div>
  );
};

export default Dashboard;
