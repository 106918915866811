import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import {
  FilterRestChart,
  DoughnutChart,
  BrightnessChart,
  OduOperationModeChart,
  FilterUsedTimeChart,
  RegionalTotalAirChart,
  OduAirVolumeChart,
  FilterAlarmChart,
  CPUResetCountChart,
  WatchdogResetCountChart,
  HepafilterResetCountChart,
  VentilationCountChart, CondensationUserChart
} from './components';
import { NO_PERMISSION, NO_PERMISSION_WARNING, READ_PERMISSION } from '../../Common/Config/naviwatchConfig';
import { userInfoContext } from '../../../App';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  }
}));

const Statistics = ({match, history}) => {
  const classes = useStyles();
  const authInfo =  useContext(userInfoContext);
  const [noPermissionMsg, setNoPermissionMsg] = useState("");
  const [permission, setPermission] = useState(NO_PERMISSION);
  useEffect(()=>{
    var pageInfo = sessionStorage.getItem('pages');
    if(pageInfo!=="undefined") {
      var pageInfoObj = JSON.parse(pageInfo);
      var pageArray = pageInfoObj.filter(page => page.name.toLowerCase().includes("analytics"));
      if (pageArray !== undefined && pageArray.length > 0) {
        var page = pageArray[0];
        if(page.permission===undefined){
          setNoPermissionMsg(NO_PERMISSION_WARNING);
        }
        setPermission(page.permission);
      }
    }
  }, [authInfo.pages]);
  useEffect(()=>{
    if(sessionStorage.getItem('naviwatch_permission')!=="true")
      history.push('/')
  });
  return (
    <div>
      {permission>=READ_PERMISSION?
        <div className={classes.root}>
          <Grid
            container
            spacing={4}
          >
            <Grid
            item
            lg={3}
            md={12}
            xl={3}
            xs={12}
          >
            <DoughnutChart chartName={"ODU"}/>
            </Grid>
            <Grid
            item
            lg={3}
            md={12}
            xl={3}
            xs={12}
          >
            <DoughnutChart chartName={"DEEPSLEEP"}/>
            </Grid>
            <Grid
              item
              lg={3}
              md={12}
              xl={3}
              xs={12}
            >
              <OduOperationModeChart />
            </Grid>
            <Grid
              item
              lg={3}
              md={12}
              xl={3}
              xs={12}
            >
              <OduAirVolumeChart />
            </Grid>
            <Grid
              item
              lg={3}
              md={12}
              xl={3}
              xs={12}
            >
              <BrightnessChart />
            </Grid>
            <Grid
              item
              lg={3}
              md={12}
              xl={3}
              xs={12}
            >
              <WatchdogResetCountChart />
            </Grid>
            <Grid
              item
              lg={3}
              md={12}
              xl={3}
              xs={12}
            >
              <HepafilterResetCountChart />
            </Grid>
            <Grid
              item
              lg={3}
              md={12}
              xl={3}
              xs={12}
            >
              <CondensationUserChart />
            </Grid>
            <Grid
              item
              lg={4}
              md={12}
              xl={4}
              xs={12}
            >
              <FilterUsedTimeChart/>
            </Grid>
            <Grid
              item
              lg={4}
              md={12}
              xl={4}
              xs={12}
            >
              <FilterRestChart />
            </Grid>
            <Grid
              item
              lg={4}
              md={12}
              xl={4}
              xs={12}
            >
              <FilterAlarmChart />
            </Grid>
          </Grid>
        </div>:
        <Typography className={classes.root}>{noPermissionMsg}</Typography>
      }
    </div>
  );
};

export default Statistics;
