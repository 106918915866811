const cognito = {
  REGION: 'ap-northeast-2',
  USER_POOL_ID: 'ap-northeast-2_pczJUEhHi',
  APP_CLIENT_ID: '1ieshqqds60kiman3er25i9qd5',
  IDENTITY_POOL_ID: 'ap-northeast-2:a7b28af1-d655-458e-b37c-2dcf225cc7a7'
};
/*dev, stage, prod */
const deploy = 'prod';

const getGA4Code = () => {
  if (deploy === 'dev') {
    return 'G-9W0ES613BZ';
  } else if (deploy === 'stage') {
    return 'G-C3T58NWLXB';
  } else if (deploy === 'prod') {
    return 'G-S5T6RY53ET';
  }
};

export { cognito, deploy, getGA4Code };
