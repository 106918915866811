import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  },
  title: {
    fontWeight: 700
  }
}));
const STRING_SEARCH = '검색';
const STRING_TITLE = '개인정보 처리 & 열람 기록';
const STRING_SUBTITLE = '개인 정보 열람 내역, 탈퇴내역을 확인합니다. (처리결과는 clipboard copy후 http://jsonviewer.stack.hu 에서 viewer로 확인하시면 편합니다.)';
const STRING_SEARCHER_LABEL_NAVIWATCH = '개인정보 취급자 Email (Naviwatch 접속 Email)';
const STRING_SEARCHER_LABEL_USER = '고객 ID';
const STRING_SEARCH_CLEAN = '초기화';
const STRING_SEARCH_COND = '검색 조건';
const STRING_SEARCH_COND_DETAIL = '세부 조건';

const STRING_SEARCH_ADMIN = '관리자';
const STRING_SEARCH_USER = '고객';

const STRING_SEARCH_DETAIL_WITHDRAW = '탈퇴';

const states = [
  {
    value: ''
  },
  {
    value: STRING_SEARCH_ADMIN
  },
  {
    value: STRING_SEARCH_USER
  }
];

const detail = [
  {
    value: STRING_SEARCH_DETAIL_WITHDRAW
  }
];

const RecordToolbar = props => {
  const { className, callbackFunc, ...rest } = props;

  const classes = useStyles();

  const [values, setValues] = useState({
    condition: states[0].value,
    detail: detail[0].value,
    searcherid: '',
    from: '',
    to: '',
  });

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };


  const cleanSearchInput = () => {
    setValues({
      condition: states[0].value,
      detail: detail[0].value,
      searcherid: '',
      from: '',
      to: '',
    });
  };

  const idLabelFind = () =>{
    if(values.condition===STRING_SEARCH_ADMIN){
      return STRING_SEARCHER_LABEL_NAVIWATCH;
    } else if(values.condition===STRING_SEARCH_USER){
      return STRING_SEARCHER_LABEL_USER;
    }
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        title={STRING_TITLE}
        subheader={<div>
          <span style={{ color: 'red' }}>{STRING_SUBTITLE}</span></div>}
      />
      <Divider/>
      <CardContent>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            md={6}
            xs={12}
          >
            <TextField
              fullWidth
              label={STRING_SEARCH_COND}
              margin="dense"
              name="condition"
              onChange={handleChange}
              select
              // eslint-disable-next-line react/jsx-sort-props
              SelectProps={{ native: true }}
              value={values.condition}
              variant="outlined"
            >
              {states.map(option => (
                <option
                  key={option.value}
                  value={option.value}
                >
                  {option.value}
                </option>
              ))}
            </TextField>
          </Grid>
          {values.condition!==STRING_SEARCH_USER?
          <Grid
            item
            md={6}
            xs={0}
          >
          </Grid>:
          <Grid
            item
            md={6}
            xs={12}
          >
            <TextField
              fullWidth
              label={STRING_SEARCH_COND_DETAIL}
              margin="dense"
              name="detail"
              onChange={handleChange}
              select
              // eslint-disable-next-line react/jsx-sort-props
              SelectProps={{ native: true }}
              value={values.detail}
              variant="outlined"
            >
              {detail.map(option => (
                <option
                  key={option.value}
                  value={option.value}
                >
                  {option.value}
                </option>
              ))}
            </TextField>
          </Grid>
          }
          <Grid
            item
            md={6}
            xs={12}
          >
            <TextField
              fullWidth
              label={idLabelFind()}
              margin="dense"
              name="searcherid"
              disabled={values.condition === ""}
              onChange={handleChange}
              value={values.searcherid}
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            md={2}
            xs={4}
          >
            <form className={classes.container} noValidate>
              <TextField
                id="from"
                name="from"
                label="From"
                type="datetime-local"
                className={classes.textField}
                value={values.from}
                onChange={handleChange}
                disabled={values.condition === ""}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </form>
          </Grid>
          <Grid
            item
            md={2}
            xs={4}
          >
            <form className={classes.container} noValidate>
              <TextField
                id="to"
                name="to"
                label="To"
                type="datetime-local"
                className={classes.textField}
                value={values.to}
                onChange={handleChange}
                disabled={values.condition === ""}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </form>
          </Grid>
        </Grid>
      </CardContent>
      <Divider/>
      <CardActions className={classes.actions}>
        <Button
          color="primary"
          variant="contained"
          onClick={cleanSearchInput}
          disabled={values.searcherid === '' && values.userid === '' && values.useremail === '' && values.username === '' && values.condition === ''}
        >
          {STRING_SEARCH_CLEAN}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => callbackFunc(values)}
          disabled={
            (values.condition!==STRING_SEARCH_ADMIN)&&(values.userid===''&&values.useremail === '' && values.username === '' &&values.searcherid === '' )
          }
        >
          {STRING_SEARCH}
        </Button>
      </CardActions>
    </Card>
  );
};

RecordToolbar.propTypes = {
  className: PropTypes.string
};

export default RecordToolbar;
