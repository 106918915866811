import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  DialogTitle,
  Divider,
  Typography,
  DialogContentText,
  makeStyles,
  Backdrop,
  CircularProgress, Grid, CardContent, AccordionDetails
} from '@material-ui/core';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes, { number } from 'prop-types';
import {
  sacGetServerState,
  sacGetServerMem,
  sacGetEnvs,
  sacGetParams,
  sacGetStatus,
  sacSetEnvs,
  sacSetParams,
  sacStart,
  sacStop,
  sacGetLog,
  sacGetAllCsvLog
} from '../../../APIs/mateApis';
import Box from '@material-ui/core/Box';
import {formatter} from '../../../APIs/mateApis'
import useAsync from '../../../../../asyncNet';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import { dashboardRegionStatistics } from '../../../../Airone/APIs/aironeApis';
import { relativeTimeRounding } from 'moment';
import { times } from 'underscore';
const STRING_CLOSE = '종료';
const STRING_REFERENCE = '참조 페이지';
const STRING_REFERENCE_PAGE = 'http://wiki.kdiwin.com:8090/pages/viewpage.action?pageId=33415149';
const STRING_SEARCH_LOG = 'FullLog';
const STRING_SEARCH_CSV_LOG = 'CSVFile';
const STRING_SEARCH_CSV_ALL_LOG = 'CSVFile(All Device) (Do not click except admin)';


const useStyles = makeStyles(theme => ({
  divider: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5)
  },
  button:{
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex'
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  overview_status_container:{
    display: 'flex',
    justifyContent: 'space-between'
  },
  overview_box_margin:{
    marginTop: theme.spacing(1),
  },
  jobFileTextEdit:{
    width: '700px'
  },
  linear:{
    display: 'flex',
    alignItems: 'flex-end'
  },
  datas:{
    marginLeft: theme.spacing(2)
  },
  inputBox:{
    marginLeft: theme.spacing(2),
    padding: theme.spacing(0)
  },
  dataSelection:{
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    width: '40%'
  },
  tabPannel:{
    width: '100%'
  }
}));

const STRING_DIALOG_NAME= '4 Season IR Controller tester page';

const STRING_DEVICE_ID = '디바이스 ID';
const STRING_IR_HUB_ID = 'IR HUB ID';
const STRING_IR_REMOTE_ID = 'IR REMOTE ID';
const STRING_IR_CONTROL_ENABLE = 'IR 연동 여부 (1: on / 0: off)';
const STRING_POWER_SAVING_MODE = '절전모드 (1: on / 0: off)';
const STRING_AIR_LOWEST_TEMP = '실내 안전 온도';
const STRING_AC_START_TEMP = '에어컨 시작 온도';
const STRING_AC_HIGHEST_TEMP = '에어컨 상한 최대 온도 ';
const STRING_AC_LOWEST_TEMP = '에어컨 하한 최소 온도';

const STRING_JUDGE_VALUE = '매트 온도 차이값 기준';
const STRING_WAITING_TEMP_DOWN_BY_AIR = '대기시간 - TEMP DOWN(BY AIR) (seconds)';
const STRING_WAITING_TEMP_UP_BY_AIR = '대기시간 - TEMP UP(BY AIR) (seconds)';
const STRING_WAITING_TEMP_DOWN_BY_AC = '대기시간 - TEMP DOWN(BY AC) (seconds)';
const STRING_WAITING_TEMP_UP_BY_AC = '대기시간 - TEMP UP(BY AC) (seconds)';
const STRING_INTERVAL_CHECK = "체크 횟수";
const STRING_WAITING_AFTER_AC_UP ="대기시간 - AC UP 직후 (seconds)";
const STRING_WAITING_AFTER_AC_DOWN ="대기시간 - AC DOWN 직후 (seconds)";
const STRING_WAITING_AFTER_POWER_ON ="대기시간 - 전원 인가 이후 (seconds)";
const STRING_POWER_SAVING_RUNNING_MINUTE = '절전모드시 가동시간 (minute)';
const STRING_POWER_SAVING_BREAK_TEMPERATURE = '절전모드 탈출 온도';
const STRING_POWER_SAVING_RUNNING_MAX_COUNT = '절전모드 수행 횟수';
const STRING_AIR_TEMPERATURE_REFRESH_TIME = '대기 온도 갱신 시간 (seconds)';
const STRING_AIR_IR_CMD_RETRY_CNT = 'IR 신호 송출 횟수(동일명령)';
const STRING_AIR_IR_CMD_INTERVAL = 'IR 신호 발생 간격 (seconds)';
const STRING_COOLING_POSSIBLE_GAP = '자체 냉각 가능값';
const STRING_TARGET_COOLING_POSSIBLE_GAP = '자체 냉각 도달 가능값';
const STRING_WAIT_TIME_PENDING_TIME = '종료 대기 시간 (wifi disconnect시) (seconds)'

const STRING_AC_MODE = 'AC Mode';
const STRING_AC_MODE_COOL = 'COOL';
const STRING_AC_MODE_HEAT = 'HEAT';
const STRING_AC_MODE_AUTO = 'AUTO';
const STRING_AC_MODE_FAN = 'FAN';
const STRING_AC_MODE_DRY = 'DRY';

const STRING_AC_FAN_SPEED = 'AC Fan Speed';
const STRING_AC_FAN_SPEED_AUTO = 'AUTO';
const STRING_AC_FAN_SPEED_LOW = 'LOW';
const STRING_AC_FAN_SPEED_MEDIUM = 'MEDIUM';
const STRING_AC_FAN_SPEED_HIGH = 'HIGH';

const STRING_STATE = '현재 STATE';
const STRING_RUNNING = 'Running';
const STRING_CURRENT_AIR_TEMP = 'Air 온도';
const STRING_CURRENT_AC_SETTING = '에어컨 설정 온도';
const STRING_CURRENT_MAT_TEMP_LEFT = '매트 현재 온도(Left)';
const STRING_CURRENT_MAT_TEMP_RIGHT = '매트 현재 온도(Right)';
const STRING_SETTING_MAT_TEMP_LEFT = '매트 설정 온도(Left)';
const STRING_SETTING_MAT_TEMP_RIGHT = '매트 설정 온도(Right)';
const STRING_START_TIME = '시작 시간';

const STRING_TOTAL_MEM = '전체 메모리 (bytes)';
const STRING_USED_MEM = '사용 메모리 (bytes)';
const STRING_FREE_MEM = '미사용 메모리 (bytes)';
const STRING_USAGE = '사용률(%)';

const STRING_SERVER_TOTAL_DEV_CNT = "전체 디바이스 수";
const STRING_SERVER_IDLE_CNT = 'IDLE 디바이스 수';
const STRING_SERVER_POWER_SAVING_CNT = 'POWER SAVING 디바이스 수';
const STRING_SERVER_TEMP_DOWN_AIR_CNT = 'TEMP DOWN(BY AIR) 디바이스 수';
const STRING_SERVER_TEMP_UP_AIR_CNT = 'TEMP UP(BY AIR) 디바이스 수';
const STRING_SERVER_TEMP_DOWN_AC_CNT = 'TEMP DOWN(BY AC) 디바이스 수';
const STRING_SERVER_TEMP_UP_AC_CNT = 'TEMP UP(BY AC) 디바이스 수';
const STRING_SERVER_EXIT_CNT = 'EXIT 디바이스 수';
const STRING_SERVER_EXIT_PENDING_CNT = 'EXIT_PENDING 디바이스 수';
const STRING_CONFIRM = '확인';
const IR_ENABLE = 2;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

const getParameter = (obj, key) => {
  if(obj!==null && obj!==undefined){
    return obj[key];
  }else{
    return "";
  }
};

const getDate = (timestamp) =>{
  if(timestamp!==null && timestamp!==undefined && timestamp!==""){
    console.log("timestamp : "+timestamp);
    var tempValue = new Date(timestamp+(9 * 60*60*1000));
    return tempValue.toISOString();
  }
  return null;
};

const getRunningState = (running) =>{
  if(running === true){
    return "동작중";
  }
  return "종료됨";
};
const getOnOff = (running) =>{
  if(running === true){
    return "true";
  }
  return "false";
};

const makeSeconds = (milliSeconds) =>{
  if( typeof milliSeconds === 'number'){
    return milliSeconds/1000;
  }
  return "";
};

const makeMilliSeconds = (seconds) =>{
  if( typeof seconds !== '') {
    return parseInt(seconds) * 1000;
  }
  return "";
};
const acModeType = [
  {
    value:STRING_AC_MODE_COOL
  },
  {
    value: STRING_AC_MODE_HEAT
  },
  {
    value: STRING_AC_MODE_AUTO
  },
  {
    value: STRING_AC_MODE_FAN
  },
  {
    value: STRING_AC_MODE_DRY
  }
];

const acFanSpeedType = [
  {
    value: STRING_AC_FAN_SPEED_AUTO
  },
  {
    value: STRING_AC_FAN_SPEED_LOW
  },
  {
    value: STRING_AC_FAN_SPEED_MEDIUM
  },
  {
    value: STRING_AC_FAN_SPEED_HIGH
  }
];

const getAcModeKey = (mode)=>{
  if( mode === "COOL")
    return 0;
  else if(mode ==="HEAT")
    return 1;
  else if(mode ==="AUTO")
    return 2;
  else if(mode ==="FAN")
    return 3;
  else if(mode==="DRY")
    return 4;
  else
    return 0;
};

const getAcFanSpeedKey = (speed)=>{
  if (speed==="AUTO")
    return 0;
  else if(speed ==="LOW")
    return 1;
  else if(speed ==="MEDIUM")
    return 2;
  else if(speed === "HIGH")
    return 3;
  else
    return 1;
};

const getAcMode = (mode) => {
  if(mode===0){
    return "COOL"
  }else if(mode ===1){
    return "HEAT"
  }else if(mode===2){
    return "AUTO"
  }else if(mode===3){
    return "FAN"
  }else if(mode===4){
    return "DRY"
  }
};

const getAcFanSpeed = (speed) =>{
  if(speed===0){
    return "AUTO";
  }else if(speed ===1){
    return "LOW";
  }else if(speed ===2){
    return "MEDIUM";
  }else if(speed ===3){
    return "HIGH"
  }
};

const SacControlDialog  = props => {
  const { open, handleClose, refetch, deviceId } = props;
  const [value, setValue] = React.useState(0);
  const [sacParams, setSacParams] = useState({
    'irHubId' : '',
    'irRemoteId' : '',
    'irEnable' : '',
    'powerSavingMode': '',
    'airLowestTemp' : '',
    'acStartTemp': '',
    'acHighestTemp' : '',
    'acLowestTemp': ''
  });

  const [sacEnvs, setSacEnvs] = useState({
    'judgeValue' : '',
    'waitingTimeTempDownByAir' : '',
    'waitingTimeTempUpByAir' : '',
    'waitingTimeTempDownByAC' : '',
    'waitingTimeTempUpByAC' : '',
    'checkInterval' : '',
    'waitingTimeAfterACUp' : '',
    'waitingTimeAfterACDown' : '',
    'waitingTimeAfterPowerOn' : '',
    'powerSavingRunningMinutes': '',
    'powerSavingBreakTemperature': '',
    'powerSavingRunningMaxCount': '',
    'airTemperatureRefreshTime': '',
    'irCmdRetryCnt': '',
    'irSendInterval': '',
    'acMode': '',
    'acFanSpeed': '',
    'coolingPossibleGap': '',
    'targetCoolingPossibleGap': '',
    'exitWaitPendingTime': ''
  });

  const [sacState, setSacState] = useState({
    'state' : '',
    'running' : '',
    'currentAirTemp' : '',
    'settingMatTempLeft' : '',
    'settingMatTempRight' : '',
    'currentMatTempLeft' : '',
    'currentMatTempRight' : '',
    'acSettingTemp': '',
    'startTime': ''
  });

  const [sacServerMem, setSacServerMem] = useState({
    'totalMem' : '',
    'usedMem' : '',
    'freeMem' : '',
    'usage' : ''
  });

  const [sacServerState, setSacServerState] = useState({
    'totalDeviceCnt' : '',
    'idleCnt': '',
    'powerSavingCnt': '',
    'tempDownByAirCnt': '',
    'tempUpByAirCnt': '',
    'tempDownByACCnt': '',
    'tempUpByACCnt': '',
    'exitCnt': '',
    'exitPendingCnt': ''
  });

  const [logPeriod, setLogPeriod] = useState({
    'from': '',
    'to' : ''
  });

  const [dialogOpen, setDialog] = useState(false);
  const [dialogMsg, setDialogMsg] = useState("");
  const [backdropOpen, setBackdrop] = useState(false);
  const [logType, setLogType] = useState("");
  const [sacLogDownloadUrl, setSacLogDownloadUrl] = useState("");

  const [sacStartResult, sacStartRefetch] = useAsync(() => sacStart(deviceId, sacParams.irHubId, sacParams.irRemoteId,
    sacParams.powerSavingMode, sacParams.acStartTemp, sacParams.airLowestTemp, sacParams.acHighestTemp, sacParams.acLowestTemp),
    [], true);
  useEffect(()=>{
    if(sacStartResult.loading === false && sacStartResult.data !== null){
      console.log("sacStartResult.data : "+JSON.stringify(sacStartResult.data));
      setBackdrop(false);
      if(sacStartResult.data['code'] === 200) {
        setDialogMsg("Start 성공하였습니다.")
      }else{
        setDialogMsg("Start 실패하였습니다. (SP3팀 문의해주세요.)")
      }
      openDialog();
    }
  }, [sacStartResult]);

  const startRequest = () =>{
    setBackdrop(true);
    sacStartRefetch();
  };

  const [sacStopResult, sacStopRefetch] = useAsync(()=>sacStop(deviceId), [], true);
  useEffect(()=>{
    if(sacStopResult.loading === false && sacStopResult.data !== null){
      console.log("sacStopResult.data : "+JSON.stringify(sacStopResult.data));
      setBackdrop(false);
      if(sacStopResult.data['code'] === 200)  {
        setDialogMsg("Stop 성공하였습니다.")
      }else{
        setDialogMsg("Stop 실패하였습니다. (SP3팀 문의해주세요.)")
      }
      openDialog();
    }
  }, [sacStopResult]);

  const stopRequest = () => {
    setBackdrop(true);
    sacStopRefetch();
  };

  const [sacSetParamResult, sacSetParamRefetch] = useAsync(()=>sacSetParams(deviceId, sacParams.irEnable, sacParams.irHubId, sacParams.irRemoteId,
    sacParams.powerSavingMode, sacParams.acStartTemp, sacParams.airLowestTemp, sacParams.acHighestTemp, sacParams.acLowestTemp),
    [], true);
  useEffect(()=>{
    if(sacSetParamResult.loading === false && sacSetParamResult.data !== null){
      console.log("sacSetParamResult.data : "+JSON.stringify(sacSetParamResult.data));
      setBackdrop(false);
      if(sacSetParamResult.data['code'] === 200)  {
        setDialogMsg("Param 설정 성공하였습니다.")
      }else{
        setDialogMsg("Param 설정 실패하였습니다. (SP3팀 문의해주세요.)")
      }
      openDialog();
    }
  }, [sacSetParamResult]);

  const setParamRequest = () => {
    setBackdrop(true);
    sacSetParamRefetch();
  };

  const [sacSetEnvResult, sacSetEnvRefetch] = useAsync(()=>sacSetEnvs(deviceId, sacEnvs.judgeValue,
    makeMilliSeconds(sacEnvs.waitingTimeTempDownByAir),
    makeMilliSeconds(sacEnvs.waitingTimeTempUpByAir),
    makeMilliSeconds(sacEnvs.waitingTimeTempDownByAC),
    makeMilliSeconds(sacEnvs.waitingTimeTempUpByAC),
    sacEnvs.checkInterval,
    makeMilliSeconds(sacEnvs.waitingTimeAfterACUp),
    makeMilliSeconds(sacEnvs.waitingTimeAfterACDown),
    makeMilliSeconds(sacEnvs.waitingTimeAfterPowerOn),
    sacEnvs.powerSavingRunningMinutes,
    sacEnvs.powerSavingBreakTemperature,
    sacEnvs.powerSavingRunningMaxCount,
    makeMilliSeconds(sacEnvs.airTemperatureRefreshTime),
    sacEnvs.irCmdRetryCnt,
    makeMilliSeconds(sacEnvs.irSendInterval),
    getAcModeKey(sacEnvs.acMode),
    getAcFanSpeedKey(sacEnvs.acFanSpeed),
    sacEnvs.coolingPossibleGap,
    sacEnvs.targetCoolingPossibleGap,
    makeMilliSeconds(sacEnvs.exitWaitPendingTime)),
    [], true);
  useEffect(()=>{
    if(sacSetEnvResult.loading === false && sacSetEnvResult.data !== null){
      console.log("sacSetEnvResult.data : "+JSON.stringify(sacSetEnvResult.data));
      setBackdrop(false);
      if(sacSetEnvResult.data['code'] === 200)  {
        setDialogMsg("Envs 설정 성공하였습니다.")
      }else{
        setDialogMsg("Envs 설정 실패하였습니다. (SP3팀 문의해주세요.)")
      }
      openDialog();
    }
  }, [sacSetEnvResult]);

  const setEnvsRequest = () => {
    setBackdrop(true);
    sacSetEnvRefetch();
  };

  const [sacLogData, sacLogDataRefetch] = useAsync(()=>
    {
      if(logType==="ALLCSV"){
        return sacGetAllCsvLog(deviceId, logPeriod.from,logPeriod.to, logType);
      } else {
        return sacGetLog(deviceId, logPeriod.from,logPeriod.to, logType);
      }
    }, [], true);

  useEffect(()=>{
    if(sacLogData.loading === false && sacLogData.data !== null && sacLogData.data!==undefined){
      console.log("sacLogData: "+sacLogData.data['downloadUrl']);
      setSacLogDownloadUrl(sacLogData.data['downloadUrl']);
      setLogType("");
    }
  }, [sacLogData]);

  const downloadFile = ()=> {
    if (sacLogDownloadUrl !== "") {
      window.open(sacLogDownloadUrl);
    }
  };

  const fullLogClick = () =>{
    setLogType('ALL')
  };

  const csvLogClick = () =>{
    setLogType('CSV')
  };

  const csvAllLogClick = () =>{
    setLogType('ALLCSV')
  };

  useEffect(()=>{
    if(logType!==""){
      sacLogDataRefetch();
    }
  }, [logType]);


  const [sacGetParamResult, sacGetParamRefetch] = useAsync(()=>sacGetParams(deviceId), [], true);
  useEffect(()=>{
    if(sacGetParamResult.loading === false && sacGetParamResult.data !== null && sacGetParamResult.data){
      setSacParams({
        'irHubId' : getParameter(sacGetParamResult['data']['param'],'irHubDeviceId'),
        'irRemoteId' : getParameter(sacGetParamResult['data']['param'],'irHubRemoteId'),
        'irEnable' : getParameter(sacGetParamResult['data']['param'],'irEnable'),
        'powerSavingMode' : getParameter(sacGetParamResult['data']['param'],'powerSavingMode'),
        'airLowestTemp' : getParameter(sacGetParamResult['data']['param'],'lowestSafetyTemperature'),
        'acStartTemp': getParameter(sacGetParamResult['data']['param'],'acStartTemperature'),
        'acHighestTemp' : getParameter(sacGetParamResult['data']['param'],'acHighestTemperature'),
        'acLowestTemp': getParameter(sacGetParamResult['data']['param'],'acLowestTemperature'),
      })
    }
  }, [sacGetParamResult]);

  const [sacGetEnvResult, sacGetEnvRefetch] = useAsync(()=>sacGetEnvs(deviceId), [], true);
  useEffect(()=>{
    if(sacGetEnvResult.loading === false && sacGetEnvResult.data !== null){
      console.log("sacGetEnvResult : "+JSON.stringify(sacGetEnvResult))
      setSacEnvs({
        'judgeValue' : getParameter(sacGetEnvResult['data']['env'],'judgeValue'),
        'waitingTimeTempDownByAir' : makeSeconds(getParameter(sacGetEnvResult['data']['env'],'checkTimeOfTemperatureDownByAir')),
        'waitingTimeTempUpByAir' : makeSeconds(getParameter(sacGetEnvResult['data']['env'],'checkTimeOfTemperatureUpByAir')),
        'waitingTimeTempDownByAC' : makeSeconds(getParameter(sacGetEnvResult['data']['env'],'checkTimeOfTemperatureDownByAC')),
        'waitingTimeTempUpByAC' : makeSeconds(getParameter(sacGetEnvResult['data']['env'],'checkTimeOfTemperatureUpByAC')),
        'checkInterval' : getParameter(sacGetEnvResult['data']['env'],'intervalOfCheck'),
        'waitingTimeAfterACUp' : makeSeconds(getParameter(sacGetEnvResult['data']['env'],'waitingTimeAfterACUp')),
        'waitingTimeAfterACDown' : makeSeconds(getParameter(sacGetEnvResult['data']['env'],'waitingTimeAfterACDown')),
        'waitingTimeAfterPowerOn' : makeSeconds(getParameter(sacGetEnvResult['data']['env'],'waitingTimeAfterPowerOn')),
        'powerSavingRunningMinutes' :  getParameter(sacGetEnvResult['data']['env'],'powerSavingRunningMinutes'),
        'powerSavingBreakTemperature' :  getParameter(sacGetEnvResult['data']['env'],'powerSavingBreakTemperature'),
        'powerSavingRunningMaxCount' :  getParameter(sacGetEnvResult['data']['env'],'powerSavingRunningMaxCount'),
        'airTemperatureRefreshTime' :  makeSeconds(getParameter(sacGetEnvResult['data']['env'],'airTemperatureRefreshTime')),
        'irCmdRetryCnt' :  getParameter(sacGetEnvResult['data']['env'],'irRetryCnt'),
        'irSendInterval' :  makeSeconds(getParameter(sacGetEnvResult['data']['env'],'irSendInterval')),
        'acMode' : getParameter(sacGetEnvResult['data']['env'],'acMode'),
        'acFanSpeed' : getParameter(sacGetEnvResult['data']['env'],'acFanSpeed'),
        'coolingPossibleGap': getParameter(sacGetEnvResult['data']['env'],'coolingPossibleGap'),
        'targetCoolingPossibleGap': getParameter(sacGetEnvResult['data']['env'],'targetCoolingPossibleGap'),
        'exitWaitPendingTime': makeSeconds(getParameter(sacGetEnvResult['data']['env'],'exitWaitPendingTime')),
    })
    }
  }, [sacGetEnvResult]);


  const [sacGetStatusResult, sacGetStatusRefetch] = useAsync(()=>sacGetStatus(deviceId), [], true);
  useEffect(()=>{
    if(sacGetStatusResult.loading === false && sacGetStatusResult.data !== null){
      setSacState({
        'state' : getParameter(sacGetStatusResult['data']['status'],'state'),
        'running' : getParameter(sacGetStatusResult['data']['status'],'running'),
        'currentAirTemp' : getParameter(sacGetStatusResult['data']['status'],'currentAirTemperature'),
        'settingMatTempLeft' : getParameter(sacGetStatusResult['data']['status'],'settingMateTemperatureLeft'),
        'settingMatTempRight' : getParameter(sacGetStatusResult['data']['status'],'settingMateTemperatureRight'),
        'currentMatTempLeft' : getParameter(sacGetStatusResult['data']['status'],'currentMateTemperatureLeft'),
        'currentMatTempRight' : getParameter(sacGetStatusResult['data']['status'],'currentMateTemperatureRight'),
        'acSettingTemp' : getParameter(sacGetStatusResult['data']['status'],'acSettingTemperature'),
        'startTime' : getParameter(sacGetStatusResult['data']['status'],'startTime'),
      })
    }
  }, [sacGetStatusResult]);

  const [sacServerStateResult, sacServerStateRefetch] = useAsync(()=>sacGetServerState(deviceId), [], true);
  useEffect(()=>{
    if(sacServerStateResult.loading === false && sacServerStateResult.data !== null){
      setSacServerState({
          'totalDeviceCnt' : getParameter(sacServerStateResult['data']['stateInfo'],'totalDeviceCnt'),
          'idleCnt': getParameter(sacServerStateResult['data']['stateInfo'],'idleCnt'),
          'powerSavingCnt': getParameter(sacServerStateResult['data']['stateInfo'],'powerSavingCnt'),
          'tempDownByAirCnt': getParameter(sacServerStateResult['data']['stateInfo'],'tempDownByAirCnt'),
          'tempUpByAirCnt': getParameter(sacServerStateResult['data']['stateInfo'],'tempUpByAirCnt'),
          'tempDownByACCnt': getParameter(sacServerStateResult['data']['stateInfo'],'tempDownByACCnt'),
          'tempUpByACCnt': getParameter(sacServerStateResult['data']['stateInfo'],'tempUpByACCnt'),
          'exitCnt': getParameter(sacServerStateResult['data']['stateInfo'],'exitCnt'),
          'exitPendingCnt': getParameter(sacServerStateResult['data']['stateInfo'],'exitPendingCnt'),
        }
      );
    }
  }, [sacServerStateResult]);

  const [sacServerMemResult, sacServerMemRefetch] = useAsync(()=>sacGetServerMem(deviceId),[], true);
  useEffect(()=>{
    if(sacServerMemResult.loading === false && sacServerMemResult.data !== null){
      setSacServerMem({
          'totalMem' : getParameter(sacServerMemResult['data']['memoryInfo'],'totalMemory'),
          'usedMem' : getParameter(sacServerMemResult['data']['memoryInfo'],'usedMemory'),
          'freeMem' : getParameter(sacServerMemResult['data']['memoryInfo'],'freeMemory'),
          'usage' : getParameter(sacServerMemResult['data']['memoryInfo'],'usage'),
        }
      )
    }
  }, [sacServerMemResult]);

  useEffect(()=>{
    if(deviceId!==false&&deviceId!==null&&deviceId!==undefined){
      sacGetStatusRefetch();
    }
  }, [deviceId]);

  const classes = useStyles();
  const re = /^[0-9.\b]+$/;

  const a11yProps =index => {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  };

  const handleChange = (event, newValue) => {
    switch (newValue) {
      case 0:
        sacGetStatusRefetch();
        break;
      case 1:
        sacGetParamRefetch();
        break;
      case 2:
        break;
      case 3:
        sacGetEnvRefetch();
        break;
      case 4:
        sacGetEnvRefetch();
        break;
      case 5:
        sacServerStateRefetch();
        break;
      case 6:
        break;
      default:
        break;
    }
    setValue(newValue);
  };

  const handleCloseClick = () =>{
    handleClose();
  };

  const handleReferenceClick = () =>{
    //handleClose();
    window.open(STRING_REFERENCE_PAGE);
  };

  const changeLogPeriod = (event) => {
    setLogPeriod({
      ...logPeriod,
      [event.target.name]: event.target.value
    })
  };

  const changeParamsString = event => {
    event.persist();
    setSacParams({
      ...sacParams,
      [event.target.name]: event.target.value
    });
  };

  const changeParamsNumber = event => {
    event.persist();
    if (event.target.value === '' || re.test(event.target.value)) {
      setSacParams({
        ...sacParams,
        [event.target.name]: event.target.value
      });
    }
  };

  const changeEnvs = event => {
    event.persist();
    console.log("changeEnvs : "+event.target.name + ", value : "+event.target.value);
    //if (event.target.value === '' || re.test(event.target.value)) {
      setSacEnvs({
        ...sacEnvs,
        [event.target.name]: event.target.value
      });
    //}
  };


  const changeEnvsString = event => {
    event.persist();
    console.log("changeEnvs : "+event.target.name + ", value : "+event.target.value);
    setSacEnvs({
      ...sacEnvs,
      [event.target.name]: event.target.value
    });

  };
  const closeDialog = () =>{
    setDialog(false)
  };

  const openDialog = () =>{
    setDialog(true)
  };

  const changeSuccessDialog = (open, handleClose, title, message) =>{
    return (
      <div>
        <Backdrop className={classes.backdrop} open={ backdropOpen }>
          <CircularProgress color={'primary'}/>
        </Backdrop>
        <Dialog
          open={open}
          onClose={handleClose}
        >
          <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" autoFocus>
              {STRING_CONFIRM}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  };

  const logPannel = (classes) =>{
    return (
      <div>
        <Backdrop className={classes.backdrop} open={ logType!=="" }>
          <CircularProgress color={'primary'}/>
        </Backdrop>
        <Grid
          container
          spacing={3}
          className={classes.overview_status_container}
        >
          <Grid
            item
            md={5}
            xs={10}
          >
            <form className={classes.container} noValidate>
              <TextField
                id="from"
                name="from"
                label="From"
                type="datetime-local"
                value={logPeriod.from}
                onChange={changeLogPeriod}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </form>
          </Grid>
          <Grid
            item
            md={5}
            xs={10}
          >
            <form className={classes.container} noValidate>
              <TextField
                id="to"
                name="to"
                label="To"
                type="datetime-local"
                value={logPeriod.to}
                onChange={changeLogPeriod}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </form>
          </Grid>
        </Grid>
        <Grid
          item
          md={2}
          xs={10}
        >
          <Button
            className={classes.button}
            onClick={fullLogClick}
            color="primary"
            autoFocus>
            {STRING_SEARCH_LOG}
          </Button>

        </Grid>
        <Grid
          item
          md={2}
          xs={10}
        >
          <Button
            className={classes.button}
            onClick={csvLogClick}
            color="primary"
            autoFocus>
            {STRING_SEARCH_CSV_LOG}
          </Button>

        </Grid>
        <Grid
          item
          xs={10}
        >
          <Button
            className={classes.button}
            onClick={csvAllLogClick}
            color="primary"
            autoFocus>
            {STRING_SEARCH_CSV_ALL_LOG}
          </Button>

        </Grid>
        <Grid
          item
        >
          <TextField
            className={classes.button}
            onClick={downloadFile}
            color="primary"
            autoFocus
            disabled={true}
            fullWidth={true}
            value={sacLogDownloadUrl}>
          </TextField>

        </Grid>
      </div>
    )
  };

  const paramPannel = (classes, editDisabled) => {
    return (
      <div>
        <div className={classes.linear}>
          <Typography variant="h6">{STRING_DEVICE_ID}</Typography>
          <Typography className={classes.datas} variant="h6">{deviceId}</Typography>
        </div>
        <Divider className={classes.divider}/>
        <div className={classes.linear}>
          <Typography variant="h6">{STRING_IR_HUB_ID}</Typography>
          {editDisabled===true?
            <Typography className={classes.datas} variant="h6">{sacParams.irHubId}</Typography>
            :<TextField
              inputProps={{style:{padding:5}}}
              className={classes.inputBox}
              id="irHubId"
              name="irHubId"
              disabled={editDisabled}
              onChange={changeParamsString}
              value={sacParams.irHubId}
              variant="outlined"
            />
          }
        </div>
        <Divider className={classes.divider}/>
        <div className={classes.linear}>
          <Typography variant="h6">{STRING_IR_REMOTE_ID}</Typography>
          {editDisabled === true ?
            <Typography className={classes.datas} variant="h6">{sacParams.irRemoteId}</Typography>
            : <TextField
              inputProps={{ style: { padding: 5 } }}
              className={classes.inputBox}
              id="irRemoteId"
              name="irRemoteId"
              disabled={editDisabled}
              onChange={changeParamsString}
              value={sacParams.irRemoteId}
              variant="outlined"
            />}
        </div>
        <Divider className={classes.divider}/>
        <div className={classes.linear}>
          <Typography variant="h6">{STRING_IR_CONTROL_ENABLE}</Typography>
          {editDisabled === true ?
            <Typography className={classes.datas} variant="h6">{sacParams.irEnable}</Typography>
            : <TextField
              inputProps={{ style: { padding: 5 } }}
              className={classes.inputBox}
              id="irEnable"
              name="irEnable"
              disabled={editDisabled}
              onChange={changeParamsString}
              value={sacParams.irEnable}
              variant="outlined"
            />}
        </div>
        <Divider className={classes.divider}/>
        <div className={classes.linear}>
          <Typography variant="h6">{STRING_POWER_SAVING_MODE}</Typography>
          {editDisabled === true ?
            <Typography className={classes.datas} variant="h6">{sacParams.powerSavingMode}</Typography>
            : <TextField
              inputProps={{ style: { padding: 5 } }}
              className={classes.inputBox}
              id="powerSavingMode"
              name="powerSavingMode"
              disabled={editDisabled}
              onChange={changeParamsString}
              value={sacParams.powerSavingMode}
              variant="outlined"
            />}
        </div>
        <Divider className={classes.divider}/>
        <div className={classes.linear}>
          <Typography variant="h6">{STRING_AIR_LOWEST_TEMP}</Typography>
          {editDisabled === true ?
            <Typography className={classes.datas} variant="h6">{sacParams.airLowestTemp}</Typography>
            :<TextField
              inputProps={{style:{padding:5}}}
              className={classes.inputBox}
              id="airLowestTemp"
              name="airLowestTemp"
              disabled={editDisabled}
              onChange={changeParamsNumber}
              value={sacParams.airLowestTemp}
              variant="outlined"
            />}
        </div>
        <Divider className={classes.divider}/>
        <div className={classes.linear}>
          <Typography variant="h6">{STRING_AC_START_TEMP}</Typography>
          {editDisabled === true ?
            <Typography className={classes.datas} variant="h6">{sacParams.acStartTemp}</Typography>
            : <TextField
              inputProps={{ style: { padding: 5 } }}
              className={classes.inputBox}
              id="acStartTemp"
              name="acStartTemp"
              disabled={editDisabled}
              onChange={changeParamsNumber}
              value={sacParams.acStartTemp}
              variant="outlined"
            />}
        </div>
        <Divider className={classes.divider}/>
        <div className={classes.linear}>
          <Typography variant="h6">{STRING_AC_HIGHEST_TEMP}</Typography>
          {editDisabled === true ?
            <Typography className={classes.datas} variant="h6">{sacParams.acHighestTemp}</Typography>
            : <TextField
              inputProps={{style:{padding:5}}}
              className={classes.inputBox}
              id="acHighestTemp"
              name="acHighestTemp"
              disabled={editDisabled}
              onChange={changeParamsNumber}
              value={sacParams.acHighestTemp}
              variant="outlined"
            />}
        </div>
        <Divider className={classes.divider}/>
        <div className={classes.linear}>
          <Typography variant="h6">{STRING_AC_LOWEST_TEMP}</Typography>
          {editDisabled === true ?
            <Typography className={classes.datas} variant="h6">{sacParams.acLowestTemp}</Typography>
            : <TextField
              inputProps={{style:{padding:5}}}
              className={classes.inputBox}
              id="acLowestTemp"
              name="acLowestTemp"
              disabled={editDisabled}
              onChange={changeParamsNumber}
              value={sacParams.acLowestTemp}
              variant="outlined"
            />}
        </div>
      </div>
    )
  };

  const getChecktimeReference = (value, interval) =>{
    if(value!=="" && interval !==""){
      if(Math.floor(value/interval) > 0) {
        return "  (" + Math.floor(value / interval) + "초당 1회씩 체크)";
      }else{
        var millisec = value * 1000;
        return "  (" + Math.floor(millisec / interval) + "밀리초당 1회씩 체크)";
      }
    }
  };

  const envsPannel = (classes, editDisabled) => {
    return (
      <div>
        <div className={classes.linear}>
          <Typography variant="h6">{STRING_DEVICE_ID}</Typography>
          <Typography className={classes.datas} variant="h6">{deviceId}</Typography>
        </div>
        <Divider className={classes.divider}/>
        <div className={classes.linear}>
          <Typography variant="h6">{STRING_JUDGE_VALUE}</Typography>
          {editDisabled === true ?
            <Typography className={classes.datas} variant="h6">{sacEnvs.judgeValue}</Typography>
            : <TextField
              inputProps={{style:{padding:5}}}
              className={classes.inputBox}
              id="judgeValue"
              name="judgeValue"
              disabled={editDisabled}
              onChange={changeEnvs}
              value={sacEnvs.judgeValue}
              variant="outlined"
            />}
        </div>
        <Divider className={classes.divider}/>
        <div className={classes.linear}>
          <Typography variant="h6">{STRING_WAITING_TEMP_DOWN_BY_AIR}</Typography>
          {editDisabled === true ?
            <Typography className={classes.datas} variant="h6">{sacEnvs.waitingTimeTempDownByAir}</Typography>
            : <TextField
              inputProps={{style:{padding:5}}}
              className={classes.inputBox}
              id="waitingTimeTempDownByAir"
              name="waitingTimeTempDownByAir"
              disabled={editDisabled}
              onChange={changeEnvs}
              value={sacEnvs.waitingTimeTempDownByAir}
              variant="outlined"
            />}
          <Typography className={classes.datas} variant="h6">
            {getChecktimeReference(sacEnvs.waitingTimeTempDownByAir, sacEnvs.checkInterval)}
          </Typography>
        </div>
        <Divider className={classes.divider}/>
        <div className={classes.linear}>
          <Typography variant="h6">{STRING_WAITING_TEMP_UP_BY_AIR}</Typography>
          {editDisabled === true ?
            <Typography className={classes.datas} variant="h6">{sacEnvs.waitingTimeTempUpByAir}</Typography>
            : <TextField
              inputProps={{style:{padding:5}}}
              className={classes.inputBox}
              id="waitingTimeTempUpByAir"
              name="waitingTimeTempUpByAir"
              disabled={editDisabled}
              onChange={changeEnvs}
              value={sacEnvs.waitingTimeTempUpByAir}
              variant="outlined"
            />}
          <Typography className={classes.datas} variant="h6">
            {getChecktimeReference(sacEnvs.waitingTimeTempUpByAir, sacEnvs.checkInterval)}
          </Typography>
        </div>
        <Divider className={classes.divider}/>
        <div className={classes.linear}>
          <Typography variant="h6">{STRING_WAITING_TEMP_DOWN_BY_AC}</Typography>
          {editDisabled === true ?
            <Typography className={classes.datas} variant="h6">{sacEnvs.waitingTimeTempDownByAC}</Typography>
            : <TextField
              inputProps={{style:{padding:5}}}
              className={classes.inputBox}
              id="waitingTimeTempDownByAC"
              name="waitingTimeTempDownByAC"
              disabled={editDisabled}
              onChange={changeEnvs}
              value={sacEnvs.waitingTimeTempDownByAC}
              variant="outlined"
            />}
          <Typography className={classes.datas} variant="h6">
            {getChecktimeReference(sacEnvs.waitingTimeTempDownByAC, sacEnvs.checkInterval)}
          </Typography>
        </div>
        <Divider className={classes.divider}/>
        <div className={classes.linear}>
          <Typography variant="h6">{STRING_WAITING_TEMP_UP_BY_AC}</Typography>
          {editDisabled === true ?
            <Typography className={classes.datas} variant="h6">{sacEnvs.waitingTimeTempUpByAC}</Typography>
            : <TextField
              inputProps={{style:{padding:5}}}
              className={classes.inputBox}
              id="waitingTimeTempUpByAC"
              name="waitingTimeTempUpByAC"
              disabled={editDisabled}
              onChange={changeEnvs}
              value={sacEnvs.waitingTimeTempUpByAC}
              variant="outlined"
            />}
          <Typography className={classes.datas} variant="h6">
            {getChecktimeReference(sacEnvs.waitingTimeTempUpByAC, sacEnvs.checkInterval)}
          </Typography>
        </div>
        <Divider className={classes.divider}/>
        <div className={classes.linear}>
          <Typography variant="h6">{STRING_INTERVAL_CHECK}</Typography>
          {editDisabled === true ?
            <Typography className={classes.datas} variant="h6">{sacEnvs.checkInterval}</Typography>
            : <TextField
              inputProps={{style:{padding:5}}}
              className={classes.inputBox}
              id="checkInterval"
              name="checkInterval"
              disabled={editDisabled}
              onChange={changeEnvs}
              value={sacEnvs.checkInterval}
              variant="outlined"
            />}
        </div>
        <Divider className={classes.divider}/>
        <div className={classes.linear}>
          <Typography variant="h6">{STRING_WAITING_AFTER_AC_UP}</Typography>
          {editDisabled === true ?
            <Typography className={classes.datas} variant="h6">{sacEnvs.waitingTimeAfterACUp}</Typography>
            : <TextField
              inputProps={{style:{padding:5}}}
              className={classes.inputBox}
              id="waitingTimeAfterACUp"
              name="waitingTimeAfterACUp"
              disabled={editDisabled}
              onChange={changeEnvs}
              value={sacEnvs.waitingTimeAfterACUp}
              variant="outlined"
            />}
        </div>
        <Divider className={classes.divider}/>
        <div className={classes.linear}>
          <Typography variant="h6">{STRING_WAITING_AFTER_AC_DOWN}</Typography>
          {editDisabled === true ?
            <Typography className={classes.datas} variant="h6">{sacEnvs.waitingTimeAfterACDown}</Typography>
            : <TextField
              inputProps={{style:{padding:5}}}
              className={classes.inputBox}
              id="waitingTimeAfterACDown"
              name="waitingTimeAfterACDown"
              disabled={editDisabled}
              onChange={changeEnvs}
              value={sacEnvs.waitingTimeAfterACDown}
              variant="outlined"
            />}
        </div>
        <Divider className={classes.divider}/>
        <div className={classes.linear}>
          <Typography variant="h6">{STRING_WAITING_AFTER_POWER_ON}</Typography>
          {editDisabled === true ?
            <Typography className={classes.datas} variant="h6">{sacEnvs.waitingTimeAfterPowerOn}</Typography>
            : <TextField
              inputProps={{style:{padding:5}}}
              className={classes.inputBox}
              id="waitingTimeAfterPowerOn"
              name="waitingTimeAfterPowerOn"
              disabled={editDisabled}
              onChange={changeEnvs}
              value={sacEnvs.waitingTimeAfterPowerOn}
              variant="outlined"
            />}
        </div>
        <Divider className={classes.divider}/>
        <div className={classes.linear}>
          <Typography variant="h6">{STRING_POWER_SAVING_RUNNING_MINUTE}</Typography>
          {editDisabled === true ?
            <Typography className={classes.datas} variant="h6">{sacEnvs.powerSavingRunningMinutes}</Typography>
            : <TextField
              inputProps={{style:{padding:5}}}
              className={classes.inputBox}
              id="powerSavingRunningMinutes"
              name="powerSavingRunningMinutes"
              disabled={editDisabled}
              onChange={changeEnvs}
              value={sacEnvs.powerSavingRunningMinutes}
              variant="outlined"
            />}
        </div>
        <Divider className={classes.divider}/>
        <div className={classes.linear}>
          <Typography variant="h6">{STRING_POWER_SAVING_BREAK_TEMPERATURE}</Typography>
          {editDisabled === true ?
            <Typography className={classes.datas} variant="h6">{sacEnvs.powerSavingBreakTemperature}</Typography>
            : <TextField
              inputProps={{style:{padding:5}}}
              className={classes.inputBox}
              id="powerSavingBreakTemperature"
              name="powerSavingBreakTemperature"
              disabled={editDisabled}
              onChange={changeEnvs}
              value={sacEnvs.powerSavingBreakTemperature}
              variant="outlined"
            />}
        </div>
        <Divider className={classes.divider}/>
        <div className={classes.linear}>
          <Typography variant="h6">{STRING_POWER_SAVING_RUNNING_MAX_COUNT}</Typography>
          {editDisabled === true ?
            <Typography className={classes.datas} variant="h6">{sacEnvs.powerSavingRunningMaxCount}</Typography>
            : <TextField
              inputProps={{style:{padding:5}}}
              className={classes.inputBox}
              id="powerSavingRunningMaxCount"
              name="powerSavingRunningMaxCount"
              disabled={editDisabled}
              onChange={changeEnvs}
              value={sacEnvs.powerSavingRunningMaxCount}
              variant="outlined"
            />}
        </div>
        <Divider className={classes.divider}/>
        <div className={classes.linear}>
          <Typography variant="h6">{STRING_AIR_TEMPERATURE_REFRESH_TIME}</Typography>
          {editDisabled === true ?
            <Typography className={classes.datas} variant="h6">{sacEnvs.airTemperatureRefreshTime}</Typography>
            : <TextField
              inputProps={{style:{padding:5}}}
              className={classes.inputBox}
              id="airTemperatureRefreshTime"
              name="airTemperatureRefreshTime"
              disabled={editDisabled}
              onChange={changeEnvs}
              value={sacEnvs.airTemperatureRefreshTime}
              variant="outlined"
            />}
        </div>
        <Divider className={classes.divider}/>
        <div className={classes.linear}>
          <Typography variant="h6">{STRING_AIR_IR_CMD_RETRY_CNT}</Typography>
          {editDisabled === true ?
            <Typography className={classes.datas} variant="h6">{sacEnvs.irCmdRetryCnt}</Typography>
            : <TextField
              inputProps={{style:{padding:5}}}
              className={classes.inputBox}
              id="irCmdRetryCnt"
              name="irCmdRetryCnt"
              disabled={editDisabled}
              onChange={changeEnvs}
              value={sacEnvs.irCmdRetryCnt}
              variant="outlined"
            />}
        </div>
        <Divider className={classes.divider}/>
        <div className={classes.linear}>
          <Typography variant="h6">{STRING_AIR_IR_CMD_INTERVAL}</Typography>
          {editDisabled === true ?
            <Typography className={classes.datas} variant="h6">{sacEnvs.irSendInterval}</Typography>
            : <TextField
              inputProps={{style:{padding:5}}}
              className={classes.inputBox}
              id="irSendInterval"
              name="irSendInterval"
              disabled={editDisabled}
              onChange={changeEnvs}
              value={sacEnvs.irSendInterval}
              variant="outlined"
            />}
        </div>


        <Divider className={classes.divider}/>
        <div className={classes.linear}>
          <Typography variant="h6">{STRING_AC_MODE}</Typography>
          {editDisabled === true ?
            <Typography className={classes.datas} variant="h6">{getAcMode(sacEnvs.acMode)}</Typography>
            : <TextField
              inputProps={{style:{padding:5}}}
              className={classes.dataSelection}
              margin="dense"
              id="acMode"
              name="acMode"
              select
              // eslint-disable-next-line react/jsx-sort-props
              SelectProps={{ native: true }}
              onChange={changeEnvsString}
              value={getAcMode(sacEnvs.acMode)}
              variant="outlined"
            >
              {acModeType.map(option => (
                <option
                  key={option.id}
                  value={option.id}
                >
                  {option.value}
                </option>
              ))}
            </TextField>
            }
        </div>
        <Divider className={classes.divider}/>
        <div className={classes.linear}>
          <Typography variant="h6">{STRING_AC_FAN_SPEED}</Typography>
          {editDisabled === true ?
            <Typography className={classes.datas} variant="h6">{getAcFanSpeed(sacEnvs.acFanSpeed)}</Typography>
            : <TextField
              inputProps={{style:{padding:5}}}
              className={classes.dataSelection}
              margin="dense"
              id="acFanSpeed"
              name="acFanSpeed"
              select
              // eslint-disable-next-line react/jsx-sort-props
              SelectProps={{ native: true }}
              onChange={changeEnvsString}
              value={getAcFanSpeed(sacEnvs.acFanSpeed)}
              variant="outlined"
            >
              {acFanSpeedType.map(option => (
                <option
                  key={option.value}
                  value={option.value}
                >
                  {option.value}
                </option>
              ))}
            </TextField>
          }
        </div>
        <Divider className={classes.divider}/>
        <div className={classes.linear}>
          <Typography variant="h6">{STRING_COOLING_POSSIBLE_GAP}</Typography>
          {editDisabled === true ?
            <Typography className={classes.datas} variant="h6">{sacEnvs.coolingPossibleGap}</Typography>
            : <TextField
              inputProps={{style:{padding:5}}}
              className={classes.inputBox}
              id="coolingPossibleGap"
              name="coolingPossibleGap"
              disabled={editDisabled}
              onChange={changeEnvs}
              value={sacEnvs.coolingPossibleGap}
              variant="outlined"
            />}
        </div>
        <Divider className={classes.divider}/>
        <div className={classes.linear}>
          <Typography variant="h6">{STRING_TARGET_COOLING_POSSIBLE_GAP}</Typography>
          {editDisabled === true ?
            <Typography className={classes.datas} variant="h6">{sacEnvs.targetCoolingPossibleGap}</Typography>
            : <TextField
              inputProps={{style:{padding:5}}}
              className={classes.inputBox}
              id="targetCoolingPossibleGap"
              name="targetCoolingPossibleGap"
              disabled={editDisabled}
              onChange={changeEnvs}
              value={sacEnvs.targetCoolingPossibleGap}
              variant="outlined"
            />}
        </div>
        <Divider className={classes.divider}/>
        <div className={classes.linear}>
          <Typography variant="h6">{STRING_WAIT_TIME_PENDING_TIME}</Typography>
          {editDisabled === true ?
            <Typography className={classes.datas} variant="h6">{sacEnvs.exitWaitPendingTime}</Typography>
            : <TextField
              inputProps={{style:{padding:5}}}
              className={classes.inputBox}
              id="exitWaitPendingTime"
              name="exitWaitPendingTime"
              disabled={editDisabled}
              onChange={changeEnvs}
              value={sacEnvs.exitWaitPendingTime}
              variant="outlined"
            />}
        </div>

      </div>
    )
  };

  const statusPannel = (classes) => {
    return (
      <div>
        <div className={classes.linear}>
          <Typography variant="h6">{STRING_DEVICE_ID}</Typography>
          <Typography className={classes.datas} variant="h6">{deviceId}</Typography>
        </div>
        <Divider className={classes.divider}/>
        <div className={classes.linear}>
          <Typography variant="h6">{STRING_STATE}</Typography>
          <Typography className={classes.datas} variant="h6">{sacState.state}</Typography>
        </div>
        <Divider className={classes.divider}/>
        <div className={classes.linear}>
          <Typography variant="h6">{STRING_RUNNING}</Typography>
          <Typography className={classes.datas} variant="h6">{getRunningState(sacState.running)}</Typography>
        </div>
        <Divider className={classes.divider}/>
        <div className={classes.linear}>
          <Typography variant="h6">{STRING_CURRENT_AIR_TEMP}</Typography>
          <Typography className={classes.datas} variant="h6">{sacState.currentAirTemp}</Typography>
        </div>
        <Divider className={classes.divider}/>
        <div className={classes.linear}>
          <Typography variant="h6">{STRING_CURRENT_AC_SETTING}</Typography>
          <Typography className={classes.datas} variant="h6">{sacState.acSettingTemp}</Typography>
        </div>
        <Divider className={classes.divider}/>
        <div className={classes.linear}>
          <Typography variant="h6">{STRING_SETTING_MAT_TEMP_LEFT}</Typography>
          <Typography className={classes.datas} variant="h6">{sacState.settingMatTempLeft}</Typography>
        </div>
        <Divider className={classes.divider}/>
        <div className={classes.linear}>
          <Typography variant="h6">{STRING_SETTING_MAT_TEMP_RIGHT}</Typography>
          <Typography className={classes.datas} variant="h6">{sacState.settingMatTempRight}</Typography>
        </div>
        <Divider className={classes.divider}/>
        <div className={classes.linear}>
          <Typography variant="h6">{STRING_CURRENT_MAT_TEMP_LEFT}</Typography>
          <Typography className={classes.datas} variant="h6">{sacState.currentMatTempLeft}</Typography>
        </div>
        <Divider className={classes.divider}/>
        <div className={classes.linear}>
          <Typography variant="h6">{STRING_CURRENT_MAT_TEMP_RIGHT}</Typography>
          <Typography className={classes.datas} variant="h6">{sacState.currentMatTempRight}</Typography>
        </div>
        <Divider className={classes.divider}/>
        <div className={classes.linear}>
          <Typography variant="h6">{STRING_START_TIME}</Typography>
          <Typography className={classes.datas} variant="h6">{getDate(sacState.startTime)}</Typography>
        </div>
      </div>
    )
  };

  const serverMemPannel = (classes) => {
    return (
      <div>
        <div className={classes.linear}>
          <Typography variant="h6">{STRING_TOTAL_MEM}</Typography>
          <Typography className={classes.datas} variant="h6">{sacServerMem.totalMem}</Typography>
        </div>
        <Divider className={classes.divider}/>
        <div className={classes.linear}>
          <Typography variant="h6">{STRING_USED_MEM}</Typography>
          <Typography className={classes.datas} variant="h6">{sacServerMem.usedMem}</Typography>
        </div>
        <Divider className={classes.divider}/>
        <div className={classes.linear}>
          <Typography variant="h6">{STRING_FREE_MEM}</Typography>
          <Typography className={classes.datas} variant="h6">{sacServerMem.freeMem}</Typography>
        </div>
        <Divider className={classes.divider}/>
        <div className={classes.linear}>
          <Typography variant="h6">{STRING_USAGE}</Typography>
          <Typography className={classes.datas} variant="h6">{sacServerMem.usage}</Typography>
        </div>
      </div>
    )
  };


  const serverStatePannel = (classes) => {
    return (
      <div>
        <div className={classes.linear}>
          <Typography variant="h6">{STRING_SERVER_TOTAL_DEV_CNT}</Typography>
          <Typography className={classes.datas} variant="h6">{sacServerState.totalDeviceCnt}</Typography>
        </div>
        <Divider className={classes.divider}/>
        <div className={classes.linear}>
          <Typography variant="h6">{STRING_SERVER_IDLE_CNT}</Typography>
          <Typography className={classes.datas} variant="h6">{sacServerState.idleCnt}</Typography>
        </div>
        <Divider className={classes.divider}/>
        <div className={classes.linear}>
          <Typography variant="h6">{STRING_SERVER_POWER_SAVING_CNT}</Typography>
          <Typography className={classes.datas} variant="h6">{sacServerState.powerSavingCnt}</Typography>
        </div>
        <Divider className={classes.divider}/>
        <div className={classes.linear}>
          <Typography variant="h6">{STRING_SERVER_TEMP_DOWN_AIR_CNT}</Typography>
          <Typography className={classes.datas} variant="h6">{sacServerState.tempDownByAirCnt}</Typography>
        </div>
        <Divider className={classes.divider}/>
        <div className={classes.linear}>
          <Typography variant="h6">{STRING_SERVER_TEMP_UP_AIR_CNT}</Typography>
          <Typography className={classes.datas} variant="h6">{sacServerState.tempUpByAirCnt}</Typography>
        </div>
        <Divider className={classes.divider}/>
        <div className={classes.linear}>
          <Typography variant="h6">{STRING_SERVER_TEMP_DOWN_AC_CNT}</Typography>
          <Typography className={classes.datas} variant="h6">{sacServerState.tempDownByACCnt}</Typography>
        </div>
        <Divider className={classes.divider}/>
        <div className={classes.linear}>
          <Typography variant="h6">{STRING_SERVER_TEMP_UP_AC_CNT}</Typography>
          <Typography className={classes.datas} variant="h6">{sacServerState.tempUpByACCnt}</Typography>
        </div>
        <Divider className={classes.divider}/>
        <div className={classes.linear}>
          <Typography variant="h6">{STRING_SERVER_EXIT_CNT}</Typography>
          <Typography className={classes.datas} variant="h6">{sacServerState.exitCnt}</Typography>
        </div>
        <Divider className={classes.divider}/>
        <div className={classes.linear}>
          <Typography variant="h6">{STRING_SERVER_EXIT_PENDING_CNT}</Typography>
          <Typography className={classes.datas} variant="h6">{sacServerState.exitPendingCnt}</Typography>
        </div>
      </div>
    )
  };

  const startSAC =(classes) =>{
    return(
      <div>
        {paramPannel(classes, false)}
        {changeSuccessDialog(dialogOpen, closeDialog, "Start", dialogMsg)}
        <Button className={classes.button} onClick={startRequest} color="primary">
          START!!
        </Button>
      </div>
    )
  };

  const stopSAC =(classes) => {
    return (
      <div>
        {changeSuccessDialog(dialogOpen, closeDialog, "Stop", dialogMsg)}
        <div>
          <div className={classes.linear}>
            <Typography variant="h6">{STRING_DEVICE_ID}</Typography>
            <Typography className={classes.datas} variant="h6">{deviceId}</Typography>
          </div>
        </div>
        <Button className={classes.button} onClick={stopRequest} color="primary">
          STOP!!
        </Button>
      </div>
    );
  };

  const setParams = (classes) =>{
    return(
      <div>
        {paramPannel(classes, false)}
        {changeSuccessDialog(dialogOpen, closeDialog, "Param Change", dialogMsg)}
        <Button className={classes.button} onClick={setParamRequest} color="primary">
          Change Params!!
        </Button>
      </div>
    )
  };

  const setEnvs = (classes) =>{
    return(
      <div>
        {envsPannel(classes, false)}
        {changeSuccessDialog(dialogOpen, closeDialog, "Envs Change", dialogMsg)}
        <Button className={classes.button} onClick={setEnvsRequest} color="primary">
          Change Envs!!
        </Button>
      </div>
    )
  };

  const getStatus = (classes) =>{
    return (
      <div>
        {statusPannel(classes)}
      </div>
    )
  };

  const getParams = (classes) => {
    return (
      <div>
        {paramPannel(classes, true)}
      </div>
    )
  };

  const getEnvs = (classes) =>{
    return(
      <div>
        {envsPannel(classes, true)}
      </div>
    )
  };

  const getServerMem = (classes) =>{
    return(
      <div>
        {serverMemPannel(classes)}
      </div>
    )
  };

  const getServerState = (classes) =>{
    return(
      <div>
        {serverStatePannel(classes)}
      </div>
    )
  };

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <div><Typography component={'span'} variant="h6">{STRING_DIALOG_NAME}</Typography><br/>
            <Typography component={'span'} variant="h4">{deviceId}</Typography><br/>
          </div>
        </DialogTitle>
        <DialogContent className={classes.root}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            className={classes.tabs}
          >
            <Tab label="Get Status" {...a11yProps(0)} />
            <Tab label="Get Param" {...a11yProps(1)} />
            <Tab label="Set Param" {...a11yProps(2)} />
            <Tab label="Get Env" {...a11yProps(3)} />
            <Tab label="Set Env" {...a11yProps(4)} />
            <Tab label="Server State" {...a11yProps(5)} />
            <Tab label="Log" {...a11yProps(6)} />
          </Tabs>
          <TabPanel value={value} index={0}>
            {getStatus(classes)}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {getParams(classes)}
          </TabPanel>
          <TabPanel value={value} index={2}>
            {setParams(classes)}
          </TabPanel>
          <TabPanel value={value} index={3}>
            {getEnvs(classes)}
          </TabPanel>
          <TabPanel value={value} index={4}>
            {setEnvs(classes)}
          </TabPanel>
          <TabPanel value={value} index={5}>
            {getServerState(classes)}
          </TabPanel>
          <TabPanel value={value} index={6}>
            {logPannel(classes)}
          </TabPanel>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleReferenceClick} color="primary" autoFocus>
            {STRING_REFERENCE}
          </Button>
          <Button onClick={handleCloseClick} color="primary" autoFocus>
            {STRING_CLOSE}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

SacControlDialog.propTypes = {
  className: PropTypes.string
};

export default SacControlDialog;
