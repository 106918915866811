import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination
} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import HomeIcon from '@material-ui/icons/Home';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import ZoomInTwoToneIcon from '@material-ui/icons/ZoomInTwoTone';
import { useRecoilState } from 'recoil';

import { navienSmartUserOffset } from '../../../../../common/mainStore';
import useAsync from '../../../../../asyncNet';
import FamilyDialog from './FamilyDialog';
import DeviceDialog from './DeviceDialog';
import { getFamily, getDevices } from '../../../APIs/smartApis';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  deviceView: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  deviceDescriptView: {
    display: 'flex',
    flexDirection: 'column'
  },
  deviceDescriptDivider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

const STRING_USER_ID = '사용자 ID';
const STRING_FAMILY = '가족';
const STRING_USER_TYPE = '사용자 타입'
const STRING_USER_NAME = '사용자 이름';
const STRING_USER_EMAIL = '사용자 EMAIL';
const STRING_LAST_LOGIN_AT = '최종 로그인 일시';
const STRING_LAST_LOGIN_IP = '최종 접속 IP'
const STRING_DEVICE = '디바이스';

const UsersTable = props => {
  const { className, users, totalCount, ...rest } = props;
  const classes = useStyles();
  const [offset, setOffset] = useRecoilState(navienSmartUserOffset)

  const [selectedUsers] = useState([]);
  const [userSeq, setUserChoice] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [familyOpen, setFamilyOpen] = React.useState(false);
  const [deviceOpen, setDeviceOpen] = React.useState(false);

  const [familyState, familyTryRefetch] = useAsync(getUserFamily, [], true);
  const { data: familyInfo } = familyState;

  const [deviceState, deviceTryRefetch] = useAsync(getUserDevices, [], true);
  const { data: deviceInfo } = deviceState;


  // modify offset when totalCount is bigger than users and last page
  useEffect(()=>{
    if(page+1 === offset+100 / rowsPerPage){
      setOffset(offset+100)}
  },[page, rowsPerPage, offset])

  useEffect(() => {
    if (userSeq !== '') {
      familyTryRefetch();
      deviceTryRefetch();
    }
  }, [userSeq]);

  async function getUserFamily() {
    return getFamily(userSeq);
  };

  async function getUserDevices() {
    return getDevices(userSeq);
  };

  const handleFamilyOpen = (user) => {
    setUserChoice(user);
    setFamilyOpen(true);
  };

  const handleDeviceOpen = (user) => {
    setUserChoice(user);
    setDeviceOpen(true);
  };

  const handleFamilyClose = () => {
    setUserChoice('');
    setFamilyOpen(false);
  };

  const handleDeviceClose = () => {
    setUserChoice('');
    setDeviceOpen(false);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  return (
    <div>
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align={'center'}><span><b>{STRING_USER_ID}</b></span></TableCell>
                    <TableCell align={'center'}><span><b>{STRING_USER_TYPE}</b></span></TableCell>
                    <TableCell align={'center'}><span><b>{STRING_USER_NAME}</b></span></TableCell>
                    <TableCell align={'center'}><span><b>{STRING_USER_EMAIL}</b></span></TableCell>
                    <TableCell align={'center'}><span><b>{STRING_LAST_LOGIN_AT}</b></span></TableCell>
                    <TableCell align={'center'}><span><b>{STRING_LAST_LOGIN_IP}</b></span></TableCell>
                    <TableCell align={'center'}><span><b>{STRING_DEVICE}</b></span></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users !== null && users !== undefined  && users.slice((page * rowsPerPage), ((page + 1) * rowsPerPage)).map(user => (
                    <TableRow
                      className={classes.tableRow}
                      hover
                      key={user.userSeq}
                      selected={selectedUsers.indexOf(user.userSeq) !== -1}
                    >
                      <TableCell align={'center'}>
                        {user.userId}
                      </TableCell>
                      <TableCell align={'center'}>
                        <Tooltip title={STRING_FAMILY}>
                          <IconButton aria-label={STRING_FAMILY}
                                      onClick={() => handleFamilyOpen(user.userSeq)}>
                            {user.userType === '메인'? <HomeIcon /> : <HomeOutlinedIcon />}
                          </IconButton>
                        </Tooltip>
                        {user.userType}
                      </TableCell>
                      <TableCell align={'center'}>
                        {user.userName}
                      </TableCell>
                      <TableCell align={'center'}>
                        {user.userEmail}
                      </TableCell>
                      <TableCell align={'center'}>
                        {user.lastLoginAt}
                      </TableCell>
                      <TableCell align={'center'}>
                        {user.lastLoginIp}
                      </TableCell>
                      <TableCell align={'center'}>
                        <Tooltip title={STRING_DEVICE}>
                          <IconButton aria-label={STRING_DEVICE}
                                      onClick={() => handleDeviceOpen(user.userSeq)}>
                            <ZoomInTwoToneIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={users !== undefined ? totalCount : 0}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </CardActions>
      </Card>
      <FamilyDialog familyOpen={familyOpen} handleFamilyClose={handleFamilyClose} familyInfo={familyInfo}/>
      <DeviceDialog deviceOpen={deviceOpen} handleDeviceClose={handleDeviceClose} deviceInfo={deviceInfo}/>
    </div>
  );
};

UsersTable.propTypes = {
  className: PropTypes.string,
  users: PropTypes.array.isRequired
};

export default UsersTable;
