import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

import { AccountProfile, AccountDetails } from './components';
import { userInfoContext } from '../../../App';
import { NO_PERMISSION, NO_PERMISSION_WARNING, READ_PERMISSION } from '../../Common/Config/naviwatchConfig';
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    height: '80%'
  },
  grid:{
    height: '100%'
  },
  gridItem:{
    height: '100%'
  }
}));


const NaviUserDetail = ({history, match}) => {
  const classes = useStyles();
  const { userEmail, roleId } = match.params;
  const authInfo =  useContext(userInfoContext);
  const [noPermissionMsg, setNoPermissionMsg] = useState("");
  const [permission, setPermission] = useState(NO_PERMISSION);
  useEffect(()=>{
    var pageInfo = sessionStorage.getItem('pages');
    if(pageInfo!=="undefined") {
    var pageInfoObj = JSON.parse(pageInfo);
      var pageArray = pageInfoObj.filter(page => page.name.toLowerCase().includes("role"));
      if (pageArray !== undefined && pageArray.length > 0) {
      var page = pageArray[0];
      if(page.permission===undefined){
        setNoPermissionMsg(NO_PERMISSION_WARNING);
      }
      setPermission(page.permission);
    }
    }
  }, [authInfo.pages]);

  console.log("NaviUserSearch userEmail: "+JSON.stringify(match));
  useEffect(()=>{
    if(sessionStorage.getItem('naviwatch_permission')!=="true")
      history.push('/')
  },[]);


  return (
    <div className={classes.root}>
      { permission >= READ_PERMISSION ?
        <div className={classes.grid}>
          <Grid
            className={classes.grid}
            container
            spacing={4}
          >
            <Grid
              className={classes.gridItem}
              item
              lg={5}
              md={5}
              xl={5}
              xs={5}
            >
              <AccountProfile email={userEmail}/>
            </Grid>
            <Grid
              item
              lg={7}
              md={7}
              xl={7}
              xs={7}
            >
              <AccountDetails email={userEmail} roleId={roleId} history={history}/>
            </Grid>
          </Grid>
        </div>:
        <Typography className={classes.root}>{noPermissionMsg}</Typography>
      }
    </div>
  );
};

export default NaviUserDetail;
