import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core/styles';
import palette from 'theme/palette';

import {
  Card,
  CardHeader,
  CardContent,
  Divider,
} from '@material-ui/core';

import { options } from './chart';
import useAsync from '../../../../../asyncNet';
import {
  statisticsFilterAlarmCount
} from '../../../APIs/aironeApis';

const STRING_WEEKLY_USER='월별 필터 알람 수';
const STRING_ULTRA_SEMI_ALARM = '초미세 집진 필터 교환 알람';
const STRING_FREE_ALARM = '프리 필터 교환 알람';
const STRING_ELECTRIC_ALARM = '전자 필터 교환 알람';
const STRING_FREE_ELEC_ALARM = '프리/전자 필터 교환 알람';

const useStyles = makeStyles(() => ({
  root: {},
  chartContainer: {
    height: 400,
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));


const FilterAlarmChart = props => {

  const { className, ...rest } = props;
  const [state, tryRefetch] = useAsync(() => statisticsFilterAlarmCount(7), []);
  const [chartdata, setChartData] = useState({
    labels: [],
    datasets: []
  });

  const { data: metric } = state;
  const [metricData, setMetricData] = useState({});

  const classes = useStyles();
  const updateMetricData = (metric) =>{
    setMetricData(metric)
  };

  useEffect(() => {
    try {
      const formattedTime = metricData['month'];
      setChartData({
          ...chartdata,
          labels: formattedTime,
          datasets: [
            {
              label: STRING_ULTRA_SEMI_ALARM,
              backgroundColor: palette.error.main,
              data: metricData['ultraSemiCount']
            },
            {
              label: STRING_FREE_ALARM,
              backgroundColor: palette.info.main,
              data: metricData['freeCount']
            },
            {
              label: STRING_ELECTRIC_ALARM,
              backgroundColor: palette.success.main,
              data: metricData['electricCount']
            },
            {
              label: STRING_FREE_ELEC_ALARM,
              backgroundColor: palette.warning.main,
              data: metricData['freeAndElectricCount']
            }
          ]
        }
      );
    } catch (e) {
      console.log('metric update failed :' + JSON.stringify(e));
    }
  }, [metricData]);

  useEffect(()=>{
    if (metric !== null && metric !== undefined) {
      console.log('update filterAlarm: '+JSON.stringify(metric));
      updateMetricData(metric);
    }
  }, [metric]);


  return (
    <div>
      <Card
        {...rest}
        className={clsx(classes.root, className)}>
        <CardHeader
          title={STRING_WEEKLY_USER}
        />
        <Divider/>
        <CardContent>
          {chartdata !== undefined  &&
          <div className={classes.chartContainer}>
            <Bar
              data={chartdata}
              options={options}
            />
          </div>
          }
        </CardContent>
        <Divider/>
      </Card>
    </div>
  );
};

FilterAlarmChart.propTypes = {
  className: PropTypes.string
};

export default React.memo(FilterAlarmChart);
