const timezoneCode = [
  {
    'name': '',
    'gmtStr': ''
  },
  {
    'name': 'Atlantic',
    'gmtStr': 'GMT-04:00'
  },
  {
    'name': 'Eastern',
    'gmtStr': 'GMT-05:00'
  },
  {
    'name': 'Central',
    'gmtStr': 'GMT-06:00'
  },
  {
    'name': 'Mountain',
    'gmtStr': 'GMT-07:00'
  },
  {
    'name': 'Pacific',
    'gmtStr': 'GMT-08:00'
  },
  {
    'name': 'Alaskan',
    'gmtStr': 'GMT-09:00'
  },
  {
    'name': 'Hawaiian',
    'gmtStr': 'GMT-10:00'
  }
];

export default timezoneCode;
