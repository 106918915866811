/* smarttok */
const POLICY_SMARTTOK_ANALYTICS_READ = 9;
const POLICY_SMARTTOK_ANALYTICS_WRITE = 53;
const POLICY_SMARTTOK_DASHBOARD_READ = 1;
const POLICY_SMARTTOK_DASHBOARD_WRITE = 45;
const POLICY_SMARTTOK_DEVICE_READ = 13;
const POLICY_SMARTTOK_DEVICE_WRITE = 57;
const POLICY_SMARTTOK_FIRMWARE_READ = 33;
const POLICY_SMARTTOK_FIRMWARE_WRITE = 77;
const POLICY_SMARTTOK_HEMS_READ = 220;
const POLICY_SMARTTOK_HEMS_WRITE = 221;
const POLICY_SMARTTOK_INACTIVE_USER_GUIDE_READ = 25;
const POLICY_SMARTTOK_INACTIVE_USER_GUIDE_WRITE = 69;
const POLICY_SMARTTOK_INACTIVE_USER_READ = 21;
const POLICY_SMARTTOK_INACTIVE_USER_WRITE = 65;
const POLICY_SMARTTOK_PUSH_READ = 29;
const POLICY_SMARTTOK_PUSH_WRITE = 73;
const POLICY_SMARTTOK_ROLE_READ = 41;
const POLICY_SMARTTOK_ROLE_WRITE = 85;
const POLICY_SMARTTOK_SEARCH_LOG_READ = 37;
const POLICY_SMARTTOK_SEARCH_LOG_WRITE = 81;
const POLICY_SMARTTOK_SERVER_READ = 5;
const POLICY_SMARTTOK_SERVER_WRITE = 49;
const POLICY_SMARTTOK_SETTING_POLICY_READ = 163;
const POLICY_SMARTTOK_SETTING_POLICY_WRITE = 132;
const POLICY_SMARTTOK_USER_READ = 17;
const POLICY_SMARTTOK_USER_WRITE = 61;

/* smarttok-cn */
const POLICY_SMARTTOK_CN_ANALYTICS_READ = 257;
const POLICY_SMARTTOK_CN_ANALYTICS_WRITE = 268;
const POLICY_SMARTTOK_CN_DASHBOARD_READ = 255;
const POLICY_SMARTTOK_CN_DASHBOARD_WRITE = 266;
const POLICY_SMARTTOK_CN_DEVICE_READ = 258;
const POLICY_SMARTTOK_CN_DEVICE_WRITE = 269;
const POLICY_SMARTTOK_CN_FIRMWARE_READ = 263;
const POLICY_SMARTTOK_CN_FIRMWARE_WRITE = 274;
const POLICY_SMARTTOK_CN_HEMS_READ = 279;
const POLICY_SMARTTOK_CN_HEMS_WRITE = 280;
const POLICY_SMARTTOK_CN_INACTIVE_USER_GUIDE_READ = 261;
const POLICY_SMARTTOK_CN_INACTIVE_USER_GUIDE_WRITE = 272;
const POLICY_SMARTTOK_CN_INACTIVE_USER_READ = 260;
const POLICY_SMARTTOK_CN_INACTIVE_USER_WRITE = 271;
const POLICY_SMARTTOK_CN_PUSH_READ = 262;
const POLICY_SMARTTOK_CN_PUSH_WRITE = 273;
const POLICY_SMARTTOK_CN_ROLE_READ = 265;
const POLICY_SMARTTOK_CN_ROLE_WRITE = 276;
const POLICY_SMARTTOK_CN_SEARCH_LOG_READ = 264;
const POLICY_SMARTTOK_CN_SEARCH_LOG_WRITE = 275;
const POLICY_SMARTTOK_CN_SERVER_READ = 256;
const POLICY_SMARTTOK_CN_SERVER_WRITE = 267;
const POLICY_SMARTTOK_CN_SETTING_POLICY_READ = 278;
const POLICY_SMARTTOK_CN_SETTING_POLICY_WRITE = 277;
const POLICY_SMARTTOK_CN_USER_READ = 259;
const POLICY_SMARTTOK_CN_USER_WRITE = 270;

/* mate */
const POLICY_MATE_ANALYTICS_READ = 10;
const POLICY_MATE_ANALYTICS_WRITE = 54;
const POLICY_MATE_DASHBOARD_READ = 2;
const POLICY_MATE_DASHBOARD_WRITE = 46;
const POLICY_MATE_DEVICE_READ = 14;
const POLICY_MATE_DEVICE_WRITE = 58;
const POLICY_MATE_FIRMWARE_READ = 34;
const POLICY_MATE_FIRMWARE_WRITE = 78;
const POLICY_MATE_INACTIVE_USER_GUIDE_READ = 26;
const POLICY_MATE_INACTIVE_USER_GUIDE_WRITE = 70;
const POLICY_MATE_INACTIVE_USER_READ = 22;
const POLICY_MATE_INACTIVE_USER_WRITE = 66;
const POLICY_MATE_PUSH_READ = 30;
const POLICY_MATE_PUSH_WRITE = 74;
const POLICY_MATE_ROLE_READ = 42;
const POLICY_MATE_ROLE_WRITE = 86;
const POLICY_MATE_SEARCH_LOG_READ = 38;
const POLICY_MATE_SEARCH_LOG_WRITE = 82;
const POLICY_MATE_SERVER_READ = 6;
const POLICY_MATE_SERVER_WRITE = 50;
const POLICY_MATE_SETTING_POLICY_READ = 160;
const POLICY_MATE_SETTING_POLICY_WRITE = 133;
const POLICY_MATE_USER_READ = 18;
const POLICY_MATE_USER_WRITE = 62;

/* airone */
const POLICY_AIRONE_ANALYTICS_READ = 11;
const POLICY_AIRONE_ANALYTICS_WRITE = 55;
const POLICY_AIRONE_DASHBOARD_READ = 3;
const POLICY_AIRONE_DASHBOARD_WRITE = 47;
const POLICY_AIRONE_DEVICE_READ = 15;
const POLICY_AIRONE_DEVICE_WRITE = 59;
const POLICY_AIRONE_FIRMWARE_READ = 35;
const POLICY_AIRONE_FIRMWARE_WRITE = 79;
const POLICY_AIRONE_INACTIVE_USER_GUIDE_READ = 27;
const POLICY_AIRONE_INACTIVE_USER_GUIDE_WRITE = 71;
const POLICY_AIRONE_INACTIVE_USER_READ = 23;
const POLICY_AIRONE_INACTIVE_USER_WRITE = 67;
const POLICY_AIRONE_PUSH_READ = 31;
const POLICY_AIRONE_PUSH_WRITE = 75;
const POLICY_AIRONE_ROLE_READ = 43;
const POLICY_AIRONE_ROLE_WRITE = 87;
const POLICY_AIRONE_SEARCH_LOG_READ = 39;
const POLICY_AIRONE_SEARCH_LOG_WRITE = 83;
const POLICY_AIRONE_SERVER_READ = 7;
const POLICY_AIRONE_SERVER_WRITE = 51;
const POLICY_AIRONE_SETTING_POLICY_READ = 161;
const POLICY_AIRONE_SETTING_POLICY_WRITE = 134;
const POLICY_AIRONE_USER_READ = 19;
const POLICY_AIRONE_USER_WRITE = 63;

/* navilink */
const POLICY_NAVILINK_ANALYTICS_READ = 12;
const POLICY_NAVILINK_ANALYTICS_WRITE = 56;
const POLICY_NAVILINK_DASHBOARD_READ = 4;
const POLICY_NAVILINK_DASHBOARD_WRITE = 48;
const POLICY_NAVILINK_DEVICE_READ = 16;
const POLICY_NAVILINK_DEVICE_WRITE = 60;
const POLICY_NAVILINK_FIRMWARE_READ = 36;
const POLICY_NAVILINK_FIRMWARE_WRITE = 80;
const POLICY_NAVILINK_INACTIVE_USER_GUIDE_READ = 28;
const POLICY_NAVILINK_INACTIVE_USER_GUIDE_WRITE = 72;
const POLICY_NAVILINK_INACTIVE_USER_READ = 24;
const POLICY_NAVILINK_INACTIVE_USER_WRITE = 68;
const POLICY_NAVILINK_PUSH_READ = 32;
const POLICY_NAVILINK_PUSH_WRITE = 76;
const POLICY_NAVILINK_ROLE_READ = 44;
const POLICY_NAVILINK_ROLE_WRITE = 88;
const POLICY_NAVILINK_SEARCH_LOG_READ = 40;
const POLICY_NAVILINK_SEARCH_LOG_WRITE = 84;
const POLICY_NAVILINK_SERVER_READ = 8;
const POLICY_NAVILINK_SERVER_WRITE = 52;
const POLICY_NAVILINK_SETTING_POLICY_READ = 162;
const POLICY_NAVILINK_SETTING_POLICY_WRITE = 135;
const POLICY_NAVILINK_USER_READ = 20;
const POLICY_NAVILINK_USER_WRITE = 64;

/* hydrofurnace */
const POLICY_HYDROFURNACE_ANALYTICS_READ = 168;
const POLICY_HYDROFURNACE_ANALYTICS_WRITE = 181;
const POLICY_HYDROFURNACE_DASHBOARD_READ = 166;
const POLICY_HYDROFURNACE_DASHBOARD_WRITE = 179;
const POLICY_HYDROFURNACE_DEVICE_READ = 169;
const POLICY_HYDROFURNACE_DEVICE_WRITE = 182;
const POLICY_HYDROFURNACE_FIRMWARE_READ = 174;
const POLICY_HYDROFURNACE_FIRMWARE_WRITE = 187;
const POLICY_HYDROFURNACE_INACTIVE_USER_GUIDE_READ = 172;
const POLICY_HYDROFURNACE_INACTIVE_USER_GUIDE_WRITE = 185;
const POLICY_HYDROFURNACE_INACTIVE_USER_READ = 171;
const POLICY_HYDROFURNACE_INACTIVE_USER_WRITE = 184;
const POLICY_HYDROFURNACE_PRIVACY_POLICY_READ = 178;
const POLICY_HYDROFURNACE_PRIVACY_POLICY_WRITE = 191;
const POLICY_HYDROFURNACE_PUSH_READ = 173;
const POLICY_HYDROFURNACE_PUSH_WRITE = 186;
const POLICY_HYDROFURNACE_ROLE_READ = 176;
const POLICY_HYDROFURNACE_ROLE_WRITE = 189;
const POLICY_HYDROFURNACE_SEARCH_LOG_READ = 175;
const POLICY_HYDROFURNACE_SEARCH_LOG_WRITE = 188;
const POLICY_HYDROFURNACE_SERVER_READ = 167;
const POLICY_HYDROFURNACE_SERVER_WRITE = 180;
const POLICY_HYDROFURNACE_SETTING_POLICY_READ = 177;
const POLICY_HYDROFURNACE_SETTING_POLICY_WRITE = 190;
const POLICY_HYDROFURNACE_USER_READ = 170;
const POLICY_HYDROFURNACE_USER_WRITE = 183;

/* smart */
const POLICY_SMART_ANALYTICS_READ = 138;
const POLICY_SMART_ANALYTICS_WRITE = 150;
const POLICY_SMART_DASHBOARD_READ = 136;
const POLICY_SMART_DASHBOARD_WRITE = 148;
const POLICY_SMART_DEVICE_READ = 139;
const POLICY_SMART_DEVICE_WRITE = 151;
const POLICY_SMART_FIRMWARE_READ = 144;
const POLICY_SMART_FIRMWARE_WRITE = 156;
const POLICY_SMART_INACTIVE_USER_GUIDE_READ = 142;
const POLICY_SMART_INACTIVE_USER_GUIDE_WRITE = 154;
const POLICY_SMART_INACTIVE_USER_READ = 141;
const POLICY_SMART_INACTIVE_USER_WRITE = 153;
const POLICY_SMART_PUSH_READ = 165;
const POLICY_SMART_PUSH_WRITE = 164;
const POLICY_SMART_ROLE_READ = 143;
const POLICY_SMART_ROLE_WRITE = 155;
const POLICY_SMART_SEARCH_LOG_READ = 146;
const POLICY_SMART_SEARCH_LOG_WRITE = 158;
const POLICY_SMART_SERVER_READ = 145;
const POLICY_SMART_SERVER_WRITE = 157;
const POLICY_SMART_SETTING_POLICY_READ = 137;
const POLICY_SMART_SETTING_POLICY_WRITE = 149;
const POLICY_SMART_USER_READ = 147;
const POLICY_SMART_USER_WRITE = 159;

/* smartplus */
const POLICY_SMARTPLUS_ANALYTICS_READ = 91;
const POLICY_SMARTPLUS_ANALYTICS_WRITE = 103;
const POLICY_SMARTPLUS_DASHBOARD_READ = 89;
const POLICY_SMARTPLUS_DASHBOARD_WRITE = 101;
const POLICY_SMARTPLUS_DEVICE_READ = 92;
const POLICY_SMARTPLUS_DEVICE_WRITE = 104;
const POLICY_SMARTPLUS_FIRMWARE_READ = 97;
const POLICY_SMARTPLUS_FIRMWARE_WRITE = 109;
const POLICY_SMARTPLUS_HEMS_READ = 222;
const POLICY_SMARTPLUS_HEMS_WRITE = 223;
const POLICY_SMARTPLUS_INACTIVE_USER_GUIDE_READ = 95;
const POLICY_SMARTPLUS_INACTIVE_USER_GUIDE_WRITE = 107;
const POLICY_SMARTPLUS_INACTIVE_USER_READ = 94;
const POLICY_SMARTPLUS_INACTIVE_USER_WRITE = 106;
const POLICY_SMARTPLUS_NOTIFICATION_READ = 218;
const POLICY_SMARTPLUS_NOTIFICATION_WRITE = 219;
const POLICY_SMARTPLUS_PUSH_READ = 96;
const POLICY_SMARTPLUS_PUSH_WRITE = 108;
const POLICY_SMARTPLUS_ROLE_READ = 99;
const POLICY_SMARTPLUS_ROLE_WRITE = 111;
const POLICY_SMARTPLUS_SEARCH_LOG_READ = 98;
const POLICY_SMARTPLUS_SEARCH_LOG_WRITE = 110;
const POLICY_SMARTPLUS_SERVER_READ = 90;
const POLICY_SMARTPLUS_SERVER_WRITE = 102;
const POLICY_SMARTPLUS_SETTING_POLICY_READ = 100;
const POLICY_SMARTPLUS_SETTING_POLICY_WRITE = 112;
const POLICY_SMARTPLUS_USER_READ = 93;
const POLICY_SMARTPLUS_USER_WRITE = 105;

/* nscada */
const POLICY_NSCADA_ANALYTICS_READ = 194;
const POLICY_NSCADA_ANALYTICS_WRITE = 207;
const POLICY_NSCADA_DASHBOARD_READ = 192;
const POLICY_NSCADA_DASHBOARD_WRITE = 205;
const POLICY_NSCADA_DEVICE_READ = 195;
const POLICY_NSCADA_DEVICE_WRITE = 208;
const POLICY_NSCADA_FIRMWARE_READ = 200;
const POLICY_NSCADA_FIRMWARE_WRITE = 213;
const POLICY_NSCADA_INACTIVE_USER_GUIDE_READ = 198;
const POLICY_NSCADA_INACTIVE_USER_GUIDE_WRITE = 211;
const POLICY_NSCADA_INACTIVE_USER_READ = 197;
const POLICY_NSCADA_INACTIVE_USER_WRITE = 210;
const POLICY_NSCADA_PRIVACY_POLICY_READ = 204;
const POLICY_NSCADA_PRIVACY_POLICY_WRITE = 217;
const POLICY_NSCADA_PUSH_READ = 199;
const POLICY_NSCADA_PUSH_WRITE = 212;
const POLICY_NSCADA_ROLE_READ = 202;
const POLICY_NSCADA_ROLE_WRITE = 215;
const POLICY_NSCADA_SEARCH_LOG_READ = 201;
const POLICY_NSCADA_SEARCH_LOG_WRITE = 214;
const POLICY_NSCADA_SERVER_READ = 193;
const POLICY_NSCADA_SERVER_WRITE = 206;
const POLICY_NSCADA_SETTING_POLICY_READ = 203;
const POLICY_NSCADA_SETTING_POLICY_WRITE = 216;

/* nscada-kr */
const POLICY_NSCADA_KR_ANALYTICS_READ = 226;
const POLICY_NSCADA_KR_ANALYTICS_WRITE = 239;
const POLICY_NSCADA_KR_DASHBOARD_READ = 224;
const POLICY_NSCADA_KR_DASHBOARD_WRITE = 237;
const POLICY_NSCADA_KR_DEVICE_READ = 227;
const POLICY_NSCADA_KR_DEVICE_WRITE = 240;
const POLICY_NSCADA_KR_FIRMWARE_READ = 232;
const POLICY_NSCADA_KR_FIRMWARE_WRITE = 245;
const POLICY_NSCADA_KR_INACTIVE_USER_GUIDE_READ = 230;
const POLICY_NSCADA_KR_INACTIVE_USER_GUIDE_WRITE = 243;
const POLICY_NSCADA_KR_INACTIVE_USER_READ = 229;
const POLICY_NSCADA_KR_INACTIVE_USER_WRITE = 242;
const POLICY_NSCADA_KR_PRIVACY_POLICY_READ = 236;
const POLICY_NSCADA_KR_PRIVACY_POLICY_WRITE = 249;
const POLICY_NSCADA_KR_PUSH_READ = 231;
const POLICY_NSCADA_KR_PUSH_WRITE = 244;
const POLICY_NSCADA_KR_ROLE_READ = 234;
const POLICY_NSCADA_KR_ROLE_WRITE = 247;
const POLICY_NSCADA_KR_SEARCH_LOG_READ = 233;
const POLICY_NSCADA_KR_SEARCH_LOG_WRITE = 246;
const POLICY_NSCADA_KR_SERVER_READ = 225;
const POLICY_NSCADA_KR_SERVER_WRITE = 238;
const POLICY_NSCADA_KR_SETTING_POLICY_READ = 235;
const POLICY_NSCADA_KR_SETTING_POLICY_WRITE = 248;


export {
    /*smarttok*/
    POLICY_SMARTTOK_ANALYTICS_READ,
    POLICY_SMARTTOK_ANALYTICS_WRITE,
    POLICY_SMARTTOK_DASHBOARD_READ,
    POLICY_SMARTTOK_DASHBOARD_WRITE,
    POLICY_SMARTTOK_DEVICE_READ,
    POLICY_SMARTTOK_DEVICE_WRITE,
    POLICY_SMARTTOK_FIRMWARE_READ,
    POLICY_SMARTTOK_FIRMWARE_WRITE,
    POLICY_SMARTTOK_HEMS_READ,
    POLICY_SMARTTOK_HEMS_WRITE,
    POLICY_SMARTTOK_INACTIVE_USER_GUIDE_READ,
    POLICY_SMARTTOK_INACTIVE_USER_GUIDE_WRITE,
    POLICY_SMARTTOK_INACTIVE_USER_READ,
    POLICY_SMARTTOK_INACTIVE_USER_WRITE,
    POLICY_SMARTTOK_PUSH_READ,
    POLICY_SMARTTOK_PUSH_WRITE,
    POLICY_SMARTTOK_ROLE_READ,
    POLICY_SMARTTOK_ROLE_WRITE,
    POLICY_SMARTTOK_SEARCH_LOG_READ,
    POLICY_SMARTTOK_SEARCH_LOG_WRITE,
    POLICY_SMARTTOK_SERVER_READ,
    POLICY_SMARTTOK_SERVER_WRITE,
    POLICY_SMARTTOK_SETTING_POLICY_READ,
    POLICY_SMARTTOK_SETTING_POLICY_WRITE,
    POLICY_SMARTTOK_USER_READ,
    POLICY_SMARTTOK_USER_WRITE,
    /*smarttok-cn*/
    POLICY_SMARTTOK_CN_ANALYTICS_READ,
    POLICY_SMARTTOK_CN_ANALYTICS_WRITE,
    POLICY_SMARTTOK_CN_DASHBOARD_READ,
    POLICY_SMARTTOK_CN_DASHBOARD_WRITE,
    POLICY_SMARTTOK_CN_DEVICE_READ,
    POLICY_SMARTTOK_CN_DEVICE_WRITE,
    POLICY_SMARTTOK_CN_FIRMWARE_READ,
    POLICY_SMARTTOK_CN_FIRMWARE_WRITE,
    POLICY_SMARTTOK_CN_HEMS_READ,
    POLICY_SMARTTOK_CN_HEMS_WRITE,
    POLICY_SMARTTOK_CN_INACTIVE_USER_GUIDE_READ,
    POLICY_SMARTTOK_CN_INACTIVE_USER_GUIDE_WRITE,
    POLICY_SMARTTOK_CN_INACTIVE_USER_READ,
    POLICY_SMARTTOK_CN_INACTIVE_USER_WRITE,
    POLICY_SMARTTOK_CN_PUSH_READ,
    POLICY_SMARTTOK_CN_PUSH_WRITE,
    POLICY_SMARTTOK_CN_ROLE_READ,
    POLICY_SMARTTOK_CN_ROLE_WRITE,
    POLICY_SMARTTOK_CN_SEARCH_LOG_READ,
    POLICY_SMARTTOK_CN_SEARCH_LOG_WRITE,
    POLICY_SMARTTOK_CN_SERVER_READ,
    POLICY_SMARTTOK_CN_SERVER_WRITE,
    POLICY_SMARTTOK_CN_SETTING_POLICY_READ,
    POLICY_SMARTTOK_CN_SETTING_POLICY_WRITE,
    POLICY_SMARTTOK_CN_USER_READ,
    POLICY_SMARTTOK_CN_USER_WRITE,
    /*mate*/
    POLICY_MATE_ANALYTICS_READ,
    POLICY_MATE_ANALYTICS_WRITE,
    POLICY_MATE_DASHBOARD_READ,
    POLICY_MATE_DASHBOARD_WRITE,
    POLICY_MATE_DEVICE_READ,
    POLICY_MATE_DEVICE_WRITE,
    POLICY_MATE_FIRMWARE_READ,
    POLICY_MATE_FIRMWARE_WRITE,
    POLICY_MATE_INACTIVE_USER_GUIDE_READ,
    POLICY_MATE_INACTIVE_USER_GUIDE_WRITE,
    POLICY_MATE_INACTIVE_USER_READ,
    POLICY_MATE_INACTIVE_USER_WRITE,
    POLICY_MATE_PUSH_READ,
    POLICY_MATE_PUSH_WRITE,
    POLICY_MATE_ROLE_READ,
    POLICY_MATE_ROLE_WRITE,
    POLICY_MATE_SEARCH_LOG_READ,
    POLICY_MATE_SEARCH_LOG_WRITE,
    POLICY_MATE_SERVER_READ,
    POLICY_MATE_SERVER_WRITE,
    POLICY_MATE_SETTING_POLICY_READ,
    POLICY_MATE_SETTING_POLICY_WRITE,
    POLICY_MATE_USER_READ,
    POLICY_MATE_USER_WRITE,
    /*airone*/
    POLICY_AIRONE_ANALYTICS_READ,
    POLICY_AIRONE_ANALYTICS_WRITE,
    POLICY_AIRONE_DASHBOARD_READ,
    POLICY_AIRONE_DASHBOARD_WRITE,
    POLICY_AIRONE_DEVICE_READ,
    POLICY_AIRONE_DEVICE_WRITE,
    POLICY_AIRONE_FIRMWARE_READ,
    POLICY_AIRONE_FIRMWARE_WRITE,
    POLICY_AIRONE_INACTIVE_USER_GUIDE_READ,
    POLICY_AIRONE_INACTIVE_USER_GUIDE_WRITE,
    POLICY_AIRONE_INACTIVE_USER_READ,
    POLICY_AIRONE_INACTIVE_USER_WRITE,
    POLICY_AIRONE_PUSH_READ,
    POLICY_AIRONE_PUSH_WRITE,
    POLICY_AIRONE_ROLE_READ,
    POLICY_AIRONE_ROLE_WRITE,
    POLICY_AIRONE_SEARCH_LOG_READ,
    POLICY_AIRONE_SEARCH_LOG_WRITE,
    POLICY_AIRONE_SERVER_READ,
    POLICY_AIRONE_SERVER_WRITE,
    POLICY_AIRONE_SETTING_POLICY_READ,
    POLICY_AIRONE_SETTING_POLICY_WRITE,
    POLICY_AIRONE_USER_READ,
    POLICY_AIRONE_USER_WRITE,
    /*navilink*/
    POLICY_NAVILINK_ANALYTICS_READ,
    POLICY_NAVILINK_ANALYTICS_WRITE,
    POLICY_NAVILINK_DASHBOARD_READ,
    POLICY_NAVILINK_DASHBOARD_WRITE,
    POLICY_NAVILINK_DEVICE_READ,
    POLICY_NAVILINK_DEVICE_WRITE,
    POLICY_NAVILINK_FIRMWARE_READ,
    POLICY_NAVILINK_FIRMWARE_WRITE,
    POLICY_NAVILINK_INACTIVE_USER_GUIDE_READ,
    POLICY_NAVILINK_INACTIVE_USER_GUIDE_WRITE,
    POLICY_NAVILINK_INACTIVE_USER_READ,
    POLICY_NAVILINK_INACTIVE_USER_WRITE,
    POLICY_NAVILINK_PUSH_READ,
    POLICY_NAVILINK_PUSH_WRITE,
    POLICY_NAVILINK_ROLE_READ,
    POLICY_NAVILINK_ROLE_WRITE,
    POLICY_NAVILINK_SEARCH_LOG_READ,
    POLICY_NAVILINK_SEARCH_LOG_WRITE,
    POLICY_NAVILINK_SERVER_READ,
    POLICY_NAVILINK_SERVER_WRITE,
    POLICY_NAVILINK_SETTING_POLICY_READ,
    POLICY_NAVILINK_SETTING_POLICY_WRITE,
    POLICY_NAVILINK_USER_READ,
    POLICY_NAVILINK_USER_WRITE,
    /*hydrofurnace*/
    POLICY_HYDROFURNACE_ANALYTICS_READ,
    POLICY_HYDROFURNACE_ANALYTICS_WRITE,
    POLICY_HYDROFURNACE_DASHBOARD_READ,
    POLICY_HYDROFURNACE_DASHBOARD_WRITE,
    POLICY_HYDROFURNACE_DEVICE_READ,
    POLICY_HYDROFURNACE_DEVICE_WRITE,
    POLICY_HYDROFURNACE_FIRMWARE_READ,
    POLICY_HYDROFURNACE_FIRMWARE_WRITE,
    POLICY_HYDROFURNACE_INACTIVE_USER_GUIDE_READ,
    POLICY_HYDROFURNACE_INACTIVE_USER_GUIDE_WRITE,
    POLICY_HYDROFURNACE_INACTIVE_USER_READ,
    POLICY_HYDROFURNACE_INACTIVE_USER_WRITE,
    POLICY_HYDROFURNACE_PRIVACY_POLICY_READ,
    POLICY_HYDROFURNACE_PRIVACY_POLICY_WRITE,
    POLICY_HYDROFURNACE_PUSH_READ,
    POLICY_HYDROFURNACE_PUSH_WRITE,
    POLICY_HYDROFURNACE_ROLE_READ,
    POLICY_HYDROFURNACE_ROLE_WRITE,
    POLICY_HYDROFURNACE_SEARCH_LOG_READ,
    POLICY_HYDROFURNACE_SEARCH_LOG_WRITE,
    POLICY_HYDROFURNACE_SERVER_READ,
    POLICY_HYDROFURNACE_SERVER_WRITE,
    POLICY_HYDROFURNACE_SETTING_POLICY_READ,
    POLICY_HYDROFURNACE_SETTING_POLICY_WRITE,
    POLICY_HYDROFURNACE_USER_READ,
    POLICY_HYDROFURNACE_USER_WRITE,
    /*smart*/
    POLICY_SMART_ANALYTICS_READ,
    POLICY_SMART_ANALYTICS_WRITE,
    POLICY_SMART_DASHBOARD_READ,
    POLICY_SMART_DASHBOARD_WRITE,
    POLICY_SMART_DEVICE_READ,
    POLICY_SMART_DEVICE_WRITE,
    POLICY_SMART_FIRMWARE_READ,
    POLICY_SMART_FIRMWARE_WRITE,
    POLICY_SMART_INACTIVE_USER_GUIDE_READ,
    POLICY_SMART_INACTIVE_USER_GUIDE_WRITE,
    POLICY_SMART_INACTIVE_USER_READ,
    POLICY_SMART_INACTIVE_USER_WRITE,
    POLICY_SMART_PUSH_READ,
    POLICY_SMART_PUSH_WRITE,
    POLICY_SMART_ROLE_READ,
    POLICY_SMART_ROLE_WRITE,
    POLICY_SMART_SEARCH_LOG_READ,
    POLICY_SMART_SEARCH_LOG_WRITE,
    POLICY_SMART_SERVER_READ,
    POLICY_SMART_SERVER_WRITE,
    POLICY_SMART_SETTING_POLICY_READ,
    POLICY_SMART_SETTING_POLICY_WRITE,
    POLICY_SMART_USER_READ,
    POLICY_SMART_USER_WRITE,
    /*smartplus*/
    POLICY_SMARTPLUS_ANALYTICS_READ,
    POLICY_SMARTPLUS_ANALYTICS_WRITE,
    POLICY_SMARTPLUS_DASHBOARD_READ,
    POLICY_SMARTPLUS_DASHBOARD_WRITE,
    POLICY_SMARTPLUS_DEVICE_READ,
    POLICY_SMARTPLUS_DEVICE_WRITE,
    POLICY_SMARTPLUS_FIRMWARE_READ,
    POLICY_SMARTPLUS_FIRMWARE_WRITE,
    POLICY_SMARTPLUS_HEMS_READ,
    POLICY_SMARTPLUS_HEMS_WRITE,
    POLICY_SMARTPLUS_INACTIVE_USER_GUIDE_READ,
    POLICY_SMARTPLUS_INACTIVE_USER_GUIDE_WRITE,
    POLICY_SMARTPLUS_INACTIVE_USER_READ,
    POLICY_SMARTPLUS_INACTIVE_USER_WRITE,
    POLICY_SMARTPLUS_NOTIFICATION_READ,
    POLICY_SMARTPLUS_NOTIFICATION_WRITE,
    POLICY_SMARTPLUS_PUSH_READ,
    POLICY_SMARTPLUS_PUSH_WRITE,
    POLICY_SMARTPLUS_ROLE_READ,
    POLICY_SMARTPLUS_ROLE_WRITE,
    POLICY_SMARTPLUS_SEARCH_LOG_READ,
    POLICY_SMARTPLUS_SEARCH_LOG_WRITE,
    POLICY_SMARTPLUS_SERVER_READ,
    POLICY_SMARTPLUS_SERVER_WRITE,
    POLICY_SMARTPLUS_SETTING_POLICY_READ,
    POLICY_SMARTPLUS_SETTING_POLICY_WRITE,
    POLICY_SMARTPLUS_USER_READ,
    POLICY_SMARTPLUS_USER_WRITE,
    /*nscada*/
    POLICY_NSCADA_ANALYTICS_READ,
    POLICY_NSCADA_ANALYTICS_WRITE,
    POLICY_NSCADA_DASHBOARD_READ,
    POLICY_NSCADA_DASHBOARD_WRITE,
    POLICY_NSCADA_DEVICE_READ,
    POLICY_NSCADA_DEVICE_WRITE,
    POLICY_NSCADA_FIRMWARE_READ,
    POLICY_NSCADA_FIRMWARE_WRITE,
    POLICY_NSCADA_INACTIVE_USER_GUIDE_READ,
    POLICY_NSCADA_INACTIVE_USER_GUIDE_WRITE,
    POLICY_NSCADA_INACTIVE_USER_READ,
    POLICY_NSCADA_INACTIVE_USER_WRITE,
    POLICY_NSCADA_PRIVACY_POLICY_READ,
    POLICY_NSCADA_PRIVACY_POLICY_WRITE,
    POLICY_NSCADA_PUSH_READ,
    POLICY_NSCADA_PUSH_WRITE,
    POLICY_NSCADA_ROLE_READ,
    POLICY_NSCADA_ROLE_WRITE,
    POLICY_NSCADA_SEARCH_LOG_READ,
    POLICY_NSCADA_SEARCH_LOG_WRITE,
    POLICY_NSCADA_SERVER_READ,
    POLICY_NSCADA_SERVER_WRITE,
    POLICY_NSCADA_SETTING_POLICY_READ,
    POLICY_NSCADA_SETTING_POLICY_WRITE,
    /*nscada-kr*/
    POLICY_NSCADA_KR_ANALYTICS_READ,
    POLICY_NSCADA_KR_ANALYTICS_WRITE,
    POLICY_NSCADA_KR_DASHBOARD_READ,
    POLICY_NSCADA_KR_DASHBOARD_WRITE,
    POLICY_NSCADA_KR_DEVICE_READ,
    POLICY_NSCADA_KR_DEVICE_WRITE,
    POLICY_NSCADA_KR_FIRMWARE_READ,
    POLICY_NSCADA_KR_FIRMWARE_WRITE,
    POLICY_NSCADA_KR_INACTIVE_USER_GUIDE_READ,
    POLICY_NSCADA_KR_INACTIVE_USER_GUIDE_WRITE,
    POLICY_NSCADA_KR_INACTIVE_USER_READ,
    POLICY_NSCADA_KR_INACTIVE_USER_WRITE,
    POLICY_NSCADA_KR_PRIVACY_POLICY_READ,
    POLICY_NSCADA_KR_PRIVACY_POLICY_WRITE,
    POLICY_NSCADA_KR_PUSH_READ,
    POLICY_NSCADA_KR_PUSH_WRITE,
    POLICY_NSCADA_KR_ROLE_READ,
    POLICY_NSCADA_KR_ROLE_WRITE,
    POLICY_NSCADA_KR_SEARCH_LOG_READ,
    POLICY_NSCADA_KR_SEARCH_LOG_WRITE,
    POLICY_NSCADA_KR_SERVER_READ,
    POLICY_NSCADA_KR_SERVER_WRITE,
    POLICY_NSCADA_KR_SETTING_POLICY_READ,
    POLICY_NSCADA_KR_SETTING_POLICY_WRITE
};