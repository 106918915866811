import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Grid, Typography, Avatar } from '@material-ui/core';
import axios from 'axios';
import useAsync from '../../../../../asyncNet';
import PhonelinkRingIcon from '@material-ui/icons/PhonelinkRing';
import PersonIcon from '@material-ui/icons/Person';
import MemoryIcon from '@material-ui/icons/Memory';
import {
  dashboardGetDeviceActiveCount,
  dashboardGetMobileActiveCount,
  dashboardGetUserTotalCount
} from '../../../APIs/mateApis';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    width: '100%',
    height:'100%'
  },
  title: {
    fontWeight: 700
  },
  mobileAvatar: {
    backgroundColor: theme.palette.success.main,
    height: 56,
    width: 56
  },
  deviceAvatar: {
    backgroundColor: theme.palette.error.main,
    height: 56,
    width: 56
  },
  userAvatar: {
    backgroundColor: theme.palette.info.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  fabProgress: {
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  mobileDifferenceIcon: {
    color: theme.palette.success.dark
  },
  mobileDifferenceValue: {
    color: theme.palette.success.dark,
    marginRight: theme.spacing(1)
  },
  mobileRoot:{
    color: theme.palette.success.light,
  },
  deviceDifferenceIcon: {
    color: theme.palette.error.dark
  },
  deviceDifferenceValue: {
    color: theme.palette.error.dark,
    marginRight: theme.spacing(1)
  },
  deviceRoot:{
    color: theme.palette.error.light,
  },
  userDifferenceIcon: {
    color: theme.palette.info.dark
  },
  userDifferenceValue: {
    color: theme.palette.info.dark,
    marginRight: theme.spacing(1)
  },
  userRoot:{
    color: theme.palette.info.light,
  },
}));

const STRING_TITLE_MOBILE = '실시간 앱 사용자';
const STRING_TITLE_DEVICE = '디바이스';
const STRING_TITLE_USER = '사용자';
const STRING_TITLE_USER_DAY = '금일 사용자';
const STRING_TITLE_USER_WEEK = '금주 사용자';
const STRING_TITLE_ALL='전체';
const STRING_TITLE_ONLINE='온라인';
const STRING_TITLE_OFFLINE='오프라인';
const STRING_TITLE_MOBILE_IOS='iOS';
const STRING_TITLE_MOBILE_ANDROID='Android';

const getRealNumber = (param)=>{
  if(param === null || param === undefined){
    return 0;
  } else{
    return param;
  }
}

const RealtimeIcon = props => {
  //console.log('type : '+JSON.stringify(type));
  if (props.type === 'mobile')
    return (<PhonelinkRingIcon className={props.classname}/>);
  else if (props.type === 'device')
    return (<MemoryIcon className={props.classname}/>);
  else if (props.type === 'user')
    return (<PersonIcon className={props.classname}/>);
  else
    return null;
};

const RealtimeCounter = props => {
  const { type } = props;

  const getCountFunction = () => {
    console.log('call getCountFunction');
    if (type === 'mobile') {
      return dashboardGetMobileActiveCount();
    } else if (type === 'device') {
      return dashboardGetDeviceActiveCount();
    } else if (type === 'user') {
      return dashboardGetUserTotalCount();
    }
  };
  const [state, tryRefetch] = useAsync(getCountFunction, []);
  const { data: count } = state;
  const [firstFieldCountValue, setFirstFieldCountValue] = useState(0);
  const [secondFieldCountValue, setSecondFieldCountValue] = useState(0);
  const [thirdFieldCountValue, setThirdFieldCountValue] = useState(0);
  const classes = useStyles();

  var counterTitle;
  var firstTitle;
  var secondTitle;
  var thirdTitle;
  var classesAvatar;

  if (type === 'mobile') {
    counterTitle = STRING_TITLE_MOBILE;
    firstTitle = STRING_TITLE_ALL;
    secondTitle = STRING_TITLE_MOBILE_IOS;
    thirdTitle = STRING_TITLE_MOBILE_ANDROID;
    classesAvatar = classes.mobileAvatar;
  } else if (type === 'device') {
    counterTitle = STRING_TITLE_DEVICE;
    firstTitle = STRING_TITLE_ALL;
    secondTitle = STRING_TITLE_ONLINE;
    thirdTitle = STRING_TITLE_OFFLINE;
    classesAvatar = classes.deviceAvatar;
  } else if (type === 'user') {
    counterTitle = STRING_TITLE_USER;
    firstTitle = STRING_TITLE_ALL;
    secondTitle = STRING_TITLE_USER_DAY;
    thirdTitle = STRING_TITLE_USER_WEEK;
    classesAvatar = classes.userAvatar;
  }


  useEffect(() => {
    try {
      if (count !== null) {
        if (type === 'mobile' && count['totalCount'] !== undefined) {
          setFirstFieldCountValue(getRealNumber(count['totalCount']));
          setSecondFieldCountValue(getRealNumber(count['iOSCount']));
          setThirdFieldCountValue(getRealNumber(count['androidCount']));
        } else if (type === 'device' && count['totalCount'] !== undefined) {
          setFirstFieldCountValue(getRealNumber(count['totalCount']));
          setSecondFieldCountValue(getRealNumber(count['connectCount']));
          setThirdFieldCountValue(getRealNumber(count['disconnectCount']));
        } else if (type === 'user' && count['totalCount'] !== undefined) {
          setFirstFieldCountValue(getRealNumber(count['totalCount']));
          setSecondFieldCountValue(getRealNumber(count['connectDayCount']));
          setThirdFieldCountValue(getRealNumber(count['connectWeekCount']));
        }
      }
    } catch (e) {
      console.log('get count failed: ' + JSON.stringify(e));
    }

  }, [count]);

  useEffect(() => {
    const timer = setInterval(function() {
      tryRefetch();
    }, 60 * 1000);
    console.log('setInterval intervalidx:' + timer);
    return () => {
      console.log('clearinterval: ' + timer);
      clearInterval(timer);
    };
  }, []);


  return (
    <Card>
      <CardContent>
        <Grid
          container
          justifyContent="space-around"
        >
          <Grid item>
            <div className={classes.content}>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
                variant="body2"
              >
                {counterTitle}
              </Typography>
              <Avatar className={classesAvatar}>
                <RealtimeIcon type={type} classname={classes.icon}/>
              </Avatar>
            </div>
          </Grid>
          <Grid item>
            <div className={classes.content}>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
                variant="body2"
              >
                {firstTitle}
              </Typography>
              <Typography
                className={classes.caption}
                variant="h3"
              >
                {firstFieldCountValue}
              </Typography>
            </div>
          </Grid>
          <Grid item>
            <div className={classes.content}>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
                variant="body2"
              >
                {secondTitle}
              </Typography>
              <Typography
                className={classes.caption}
                variant="h3"
              >
                {secondFieldCountValue}
              </Typography>
            </div>
          </Grid>
          <Grid item>
            <div className={classes.content}>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
                variant="body2"
              >
                {thirdTitle}
              </Typography>
              <Typography
                className={classes.caption}
                variant="h3"
              >
                {thirdFieldCountValue}
              </Typography>
            </div>
          </Grid>
        </Grid>

      </CardContent>
    </Card>
  );
};

/*\<div className={classes.difference}>
          {
            previousCount === null || previousCount === currentCountValue ?
              (<RemoveIcon className={classesIcon}/>) :
              previousCount < currentCountValue ?
                (<TrendingUpIcon className={classesIcon}/>) :
                <TrendingDownIcon className={classesIcon}/>
          }
          <Grid
            container
            justify="space-between"
          >
            <Grid item>
              <Typography
                className={classes.caption}
                variant="h5"
              >
                {currentCountValue - previousCount}

              </Typography>
            </Grid>

          </Grid>

        </div>
        */
RealtimeCounter.propTypes = {
  className: PropTypes.string
};

export default RealtimeCounter;
