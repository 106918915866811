import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { HorizontalBar } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core/styles';

import {
  Card,
  CardHeader,
  CardContent,
  Divider,
} from '@material-ui/core';

import useAsync from '../../../../../asyncNet';
import {
  statisticsCondensationUser
} from '../../../APIs/aironeApis';

const STRING_FREE_VENTILATION_TITLE='정지중 결로운전 동작 횟수 TOP 10 사용자';
const STRING_CONDENSATION_ONSTOP = '정지중 결로 운전';

const useStyles = makeStyles(() => ({
  height: '100%',
  card: {
    backgroundColor: '#fff9db'
  },
  chartContainer: {
    height: '100%',
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const options = {
  legend: {
    display: false
  }
};

const CondensationUserChart = props => {

  const { className, ...rest } = props;
  const [state, tryRefetch] = useAsync(statisticsCondensationUser, [], false);
  const [chartdata, setChartData] = useState({
    labels: [],
    datasets: []
  });
  const [name, setName] = useState([]);
  const [area, setArea] = useState([]);
  const [runningCnt, setRunningCnt] = useState([]);
  const [stopCnt, setStopCnt] = useState([]);
  const [chartLabel, setChartLabel] =  useState([]);

  const { data: metric } = state;
  const classes = useStyles();

  const makeChartValue = (username, area, runningCount, stopCount) =>{
    let _name = [];
    let _area = [];
    let _runningCnt = [];
    let _stopCnt = [];

    for (var i = 0; i < username.length; i++) {
      _name.push(username[i]);
      _area.push(area[i]);
      _runningCnt.push(runningCount[i]);
      _stopCnt.push(stopCount[i]);
    }
    setName(_name);
    setArea(_area);
    setRunningCnt(_runningCnt);
    setStopCnt(_stopCnt);
  };

  useEffect(() => {
    try {
      setChartData({
          ...chartdata,
          labels: name,
          datasets: [
            {
              label: STRING_CONDENSATION_ONSTOP,
              borderWidth: 1,
              backgroundColor: '#FDDACD',
              data: stopCnt,
            }
          ]
        }
      );
    } catch (e) {
      console.log('metric update failed :' + JSON.stringify(e));
    }
  }, [name]);

  useEffect(() => {
    if (metric !== null && metric !== undefined) {
      console.log('update freefilter: '+JSON.stringify(metric));
      makeChartValue(metric['username'], metric['area'], metric['runningCount'], metric['stopCount']);
    }  }, [metric]);


  return (
    <div>
      <Card
        {...rest}
        className={clsx(classes.root, className)}>
        <CardHeader
          title={STRING_FREE_VENTILATION_TITLE}
        />
        <Divider/>
        <CardContent>
          {chartdata !== undefined  &&
          <div className={classes.chartContainer}>
            <HorizontalBar
              data={chartdata}
              options={options}
            />
          </div>
          }
        </CardContent>
        <Divider/>
      </Card>
    </div>
  );
};

CondensationUserChart.propTypes = {
  className: PropTypes.string
};

export default React.memo(CondensationUserChart);
