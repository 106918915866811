import React, { useEffect, useState, useRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Bar, Line } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core/styles';
import palette from 'theme/palette';

import {
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Divider
} from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';

import { options } from './chart';
import useAsync from '../../../../../asyncNet';
import { getNlbTcpCount } from '../../../APIs/mateApis';
const STRING_ERROR = 'NLB Connection';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  chartContainer: {
    position: 'relative',
    height: '400px'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  test: {
    marginTop: theme.spacing(6)
  }
}));

const NlbChart = props => {
  const { className, metricName, ...rest } = props;
  const [optionsState, setOptionsState] = useState(options);
  const [state, tryRefetch] = useAsync(() => getMetric(), []);
  const [chartdata, setChartData] = useState({
    labels: [],
    datasets: []
  });

  const { data: metric } = state;

  const classes = useStyles();

  const beforeDateRef = useRef('');

  const getMetric = () => {
    return getNlbTcpCount();
  };

  const xAxesConvert = (value, index, ticks) => {
    const frontStr = value.split('T')[0];
    if (
      beforeDateRef.current === '' ||
      beforeDateRef.current != frontStr.substr(5)
    ) {
      beforeDateRef.current = frontStr.substr(5);
      return beforeDateRef.current;
    }
    return beforeDateRef.current;
  };

  useEffect(() => {
    try {
      // console.log('@@@@@@@@@:' + metric['day']); '2024-08-04T05:50:00+00:00'
      if (metric !== null) {
        let optionsStateCopy = JSON.parse(JSON.stringify(optionsState));
        optionsStateCopy.scales.xAxes[0].ticks.callback = xAxesConvert;
        setOptionsState(optionsStateCopy);

        setChartData(chardata => ({
          ...chartdata,
          labels: metric['day'],
          datasets: [
            {
              type: 'line',
              label: 'Average',
              fill: false,
              borderWidth: 2,
              borderColor: palette.orange,
              data: metric['count'],
              tension: 0
            }
          ]
        }));
      }
    } catch (e) {
      console.log('metric update failed :' + JSON.stringify(e));
    }
  }, [metric]);

  return (
    <div>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardHeader
          title={STRING_ERROR}
          action={
            <IconButton size="small" onClick={tryRefetch}>
              <RefreshIcon />
            </IconButton>
          }
        />
        <Divider />
        <CardContent>
          <div className={classes.chartContainer}>
            <Line data={chartdata} options={optionsState} />
          </div>
        </CardContent>
        <Divider className={classes.test} />
      </Card>
    </div>
  );
};

NlbChart.propTypes = {
  className: PropTypes.string
};

export default React.memo(NlbChart);
