import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core/styles';
import palette from 'theme/palette';
import {
  Card,
  CardHeader,
  CardContent,
  Divider
} from '@material-ui/core';
import { options } from './chart';
import useAsync from '../../../../../asyncNet';
import { getSignupCount } from 'views/SmartTok/APIs/tokapis';
import { STRING_CONNECT_TREND_MONITORING } from '../../../StringTable/StringTable';

const useStyles = makeStyles(() => ({
  root: {},
  chartContainer: {
    height: "100%",
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));


const ConnectionTrends = props => {

  const { className, day, metricName, ...rest } = props;
  const [state, tryRefetch] = useAsync(() => getSignupCount(day), []);
  const [chartdata, setChartData] = useState({
    labels: [],
    datasets: []
  });

  const { loading, data: metric, error } = state;

  const classes = useStyles();

  useEffect(() => {
    try {
      if (metric !== null) {
        const formattedTime = metric['day'];
        setChartData(chardata => ({
          ...chartdata,
          labels: formattedTime,
          datasets: [
            {
              type: 'line',
              fill: false,
              borderWidth: 2,
              borderColor: metricName === "User" ? palette.info.main : palette.error.main,
              // data: metric['count']
              data: [3134, 3346, 3263, 3776, 3448, 4534, 4536, 4356, 4757, 5457, 5538, 6479, 6456, 6223]
            }
          ]
        })
        );
      }
    } catch (e) {
      console.log('metric update failed :' + JSON.stringify(e));
    }
  }, [metric]);

  useEffect(() => {
    tryRefetch();
  }, []);


  return (
    <div>
      <Card style={{ flex: 1, backgroundColor: 'lightgray' }}
        {...rest}
        className={clsx(classes.root, className)}>
        <CardHeader
          title={metricName === 'User' ? STRING_CONNECT_TREND_MONITORING : ''}
        />
        <Divider />
        <CardContent>
          <div className={classes.chartContainer}>
            <Bar
              data={chartdata}
              options={options}
            />
          </div>
        </CardContent>
        <Divider />
      </Card>
    </div>
  );
};

ConnectionTrends.propTypes = {
  className: PropTypes.string
};

export default React.memo(ConnectionTrends);
