import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
  import { Bar } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core/styles';
import palette from 'theme/palette';

import {
  Card,
  CardHeader,
  CardContent,
  Divider,
} from '@material-ui/core';

import { options } from './chart';
import useAsync from '../../../../../asyncNet';
import {
  statisticsFilterUsedTime
} from '../../../APIs/aironeApis';
const STRING_TITLE="초미세 먼지 필터 사용량 분포";
const useStyles = makeStyles(() => ({
  root: {},
  chartContainer: {
    height: 400,
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const labelValue = [
  '0-10 %',
  '11-20 %',
  '21-30 %',
  '31-40 %',
  '41-50 %',
  '51-60 %',
  '61-70 %',
  '71-80 %',
  '81-90 %',
  '91-100 %'
];
const FilterUsedTimeChart = props => {

  const { className, modelName, ...rest } = props;
  const [state, tryRefetch] = useAsync(statisticsFilterUsedTime, []);
  const [chartdata, setChartData] = useState({
    labels: [],
    datasets: []
  });

  const classes = useStyles();

  const { data: dataset } = state;
  const [receivedDataSet, setReceivedDataset] = useState([]);

  const updateDataset = (dataset) =>{
    setReceivedDataset(dataset)
  };


  useEffect(() => {
    try {
      setChartData({
          ...chartdata,
          labels: labelValue,
          datasets: [
            {
              label: 'TAC 510/550',
              data: receivedDataSet['dualLink'],
              backgroundColor: palette.info.main
            },
            {
              label: 'TAC 511/551',
              data: receivedDataSet['prism'],
              backgroundColor: palette.error.main
            }
          ]
        }
      );
    } catch (e) {
      console.log('metric update failed :' + JSON.stringify(e));
    }
  }, [receivedDataSet]);

  useEffect(()=>{
    if (dataset !== null && dataset !== undefined) {
      console.log('update filter reset: '+JSON.stringify(dataset));
      updateDataset(dataset);
    }
  }, [dataset]);


  return (
    <div>
      <Card
        {...rest}
        className={clsx(classes.root, className)}>
        <CardHeader
          title={STRING_TITLE}
        />
        <Divider/>
        <CardContent>
          {chartdata !== undefined  &&
          <div className={classes.chartContainer}>
            <Bar
              data={chartdata}
              options={options}
            />
          </div>
          }
        </CardContent>
        <Divider/>
      </Card>
    </div>
  );
};

FilterUsedTimeChart.propTypes = {
  className: PropTypes.string
};

export default React.memo(FilterUsedTimeChart);
