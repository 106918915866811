import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Divider } from '@material-ui/core';
import {
  STRING_FIRMWARE_COUNT,
  STRING_LAST_FW_REGISTER_AT,
  STRING_LAST_FW_MODIFY_AT,
  STRING_DATE_FORMAT_INCLUDE_SEC
} from '../../../StringTable/StringTable';

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 320,
  },
  media: {
    height: 150,
    width: '50%',
    marginLeft: '25%'
  },
  action: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const ModelBox = props => {
  const { className, productName, modelName, imageSrc, count, lastRegisterAt, lastModifyAt, swCodeMasking, firmwareType, ...rest } = props;
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <Link to={`/hydrofurnace/firmware/${firmwareType}/${productName}/${swCodeMasking}/${modelName}`}>
        <CardMedia
          className={classes.media}
          image={imageSrc}
          title="Roomcon Image"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {modelName}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {STRING_FIRMWARE_COUNT}{' : '}{count}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {STRING_LAST_FW_REGISTER_AT}{' : '}{lastRegisterAt ? moment((lastRegisterAt - 9 * 60 * 60) * 1000).format(STRING_DATE_FORMAT_INCLUDE_SEC) : '-'}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {STRING_LAST_FW_MODIFY_AT}{' : '}{lastModifyAt ? moment((lastModifyAt - 9 * 60 * 60) * 1000).format(STRING_DATE_FORMAT_INCLUDE_SEC) : '-'}
          </Typography>
        </CardContent>
        <CardActions className={classes.action}>

          <Button size="small" color="primary" variant='outlined'>
            Show Firmware List
          </Button>
        </CardActions>
      </Link>
    </Card>
  );
};

ModelBox.propTypes = {
  className: PropTypes.string
};

export default ModelBox;