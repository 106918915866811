import axios from 'axios';
import { deploy } from 'config';


var ApiInstance = null;
var oldApiInstance = null;
//prod : https://3i4vrvf2sk.execute-api.ap-northeast-2.amazonaws.com
//stage : https://9tx4dm82ec.execute-api.ap-northeast-2.amazonaws.com
//dev : https://ajfec18sl3.execute-api.ap-northeast-2.amazonaws.com
// new prod : https://okvyy0r9n3.execute-api.ap-northeast-2.amazonaws.com

if (deploy === "dev") {
  ApiInstance = axios.create({ baseURL: 'https://ajfec18sl3.execute-api.ap-northeast-2.amazonaws.com' });
  oldApiInstance = axios.create({ baseURL: 'https://ajfec18sl3.execute-api.ap-northeast-2.amazonaws.com' });
} else if (deploy === "stage") {
  ApiInstance = axios.create({ baseURL: 'https://9tx4dm82ec.execute-api.ap-northeast-2.amazonaws.com' });
  oldApiInstance = axios.create({ baseURL: 'https://9tx4dm82ec.execute-api.ap-northeast-2.amazonaws.com' });
} else if (deploy === "prod") {
  ApiInstance = axios.create({ baseURL: 'https://okvyy0r9n3.execute-api.ap-northeast-2.amazonaws.com' });
  oldApiInstance = axios.create({ baseURL: 'https://3i4vrvf2sk.execute-api.ap-northeast-2.amazonaws.com' });
}

export async function getConnectedDeviceNumber() {
  var response = undefined;
  let reqUrl = '/prod/statistics/count';
  let config = {
    headers: {
      "authorizer": sessionStorage.getItem('idToken')
    }
  };

  //console.log('getConnectedDeviceNumber : ' + reqUrl);
  response = await ApiInstance.get(reqUrl, config);
  //console.log(JSON.stringify(response.data, null, ' '));

  return response.data;
}

/**
 * @brief
 * requestSearchUser is requested user's list
 * @param searchText User ID, Device ID, Name, Email and Phone number
 * @return user list in JSON format
 */
export async function requestSearchUser(searchText) {
  var response = undefined;
  let reqUrl = '/prod/find?search=' + searchText;
  let config = {
    headers: {
      "authorizer": sessionStorage.getItem('idToken')
    }
  };

  //console.log('searchUserList : ' + reqUrl);
  response = await ApiInstance.get(reqUrl, config);
  //console.log(JSON.stringify(response.data, null, ' '));

  return response.data;
}

/**
 * @brief
 * requestSearchUserByUserId is requested user's detail information using user ID
 * @param searchText User ID
 * @return user detail information in JSON format
 */
export async function requestSearchUserByUserId(searchText) {
  var response = undefined;
  let reqUrl = '/prod/find/userid?search=' + searchText;
  let config = {
    headers: {
      "authorizer": sessionStorage.getItem('idToken')
    }
  };

  //console.log('searchUserList : ' + reqUrl);
  response = await ApiInstance.get(reqUrl, config);
  //console.log(JSON.stringify(response.data, null, ' '));

  return response.data;
}


//APIs using smart views
export async function getDeviceCount() {
  var response = undefined;
  let reqUrl = '/naviwatch/api/v1/monitoring/device/get-count';
  let config = {
    headers: {
      "authorizer": sessionStorage.getItem('idToken')
    }
  };

  //console.log('getDeviceCount : ' + reqUrl);
  response = await ApiInstance.get(reqUrl, config);
  //console.log(JSON.stringify(response.data, null, ' '));

  return response.data;
}

export async function getDeviceRegionCount() {
  var response = undefined;
  let reqUrl = '/naviwatch/api/v1/monitoring/device/get-region-count';
  let config = {
    headers: {
      "authorizer": sessionStorage.getItem('idToken')
    }
  };

  //console.log('getDeviceRegionCount : ' + reqUrl);
  response = await ApiInstance.get(reqUrl, config);
  //console.log(JSON.stringify(response.data, null, ' '));

  return response.data;
}

export async function deviceSearch(deviceId, ip) {
  var response = undefined;
  let reqUrl = '/naviwatch/api/v1/monitoring/device/search';
  let config = {
    headers: {
      "authorizer": sessionStorage.getItem('idToken')
    }
  };
  let data = JSON.stringify({
    "naviwatchId": sessionStorage.getItem('email'),
    "deviceId": deviceId,
    "ip": ip
  });
  response = await ApiInstance.post(reqUrl, data, config);
  // console.log("deviceSearch : " + JSON.stringify(response.data, null, ' '));

  return response.data;
}

export async function deviceDetailInfo(macAddress, additionalValue, ip) {
  var response = undefined;
  let reqUrl = '/naviwatch/api/v1/monitoring/device/get-detail-info';
  let config = {
    headers: {
      "authorizer": sessionStorage.getItem('idToken')
    }
  };
  let data = JSON.stringify({
    "naviwatchId": sessionStorage.getItem('email'),
    "macAddress": macAddress,
    "additionalValue": additionalValue,
    "ip": ip
  });
  response = await ApiInstance.post(reqUrl, data, config);
  // console.log("deviceDetailInfo : " + JSON.stringify(response.data, null, ' '));

  return response.data;
}

export async function getModelCount(status) {
  var response = undefined;
  let reqUrl = '/naviwatch/api/v1/monitoring/device/get-model-count';
  let config = {
    headers: {
      "authorizer": sessionStorage.getItem('idToken')
    }
  };
  let data = JSON.stringify({ "query": status });

  response = await ApiInstance.post(reqUrl, data, config);
  // console.log(JSON.stringify(response.data, null, ' '));

  return response.data;
}

export async function getControlLog(userInfo, startTime, endTime) {
  var response = undefined;
  let reqUrl = '/naviwatch/api/v1/monitoring/device/get-control-log';

  // console.log("getControlLog userInfo : " + JSON.stringify(userInfo, null, ' '));
  // console.log("getControlLog range : " + startTime + ", " + endTime);
  let config = {
    headers: {
      "authorizer": sessionStorage.getItem('idToken')
    }
  };
  let data = JSON.stringify({
    "deviceId": userInfo.devices.macAddress + userInfo.devices.additionalValue,
    "thingsType": userInfo.devices.thingsType,
    "range": [Math.floor(new Date(startTime).getTime()), Math.floor(new Date(endTime).getTime())]
  });
  //console.log('getControlLog : ' + reqUrl);
  response = await ApiInstance.post(reqUrl, data, config);
  // console.log("get-control-log : " + JSON.stringify(response.data, null, ' '));
  return response.data;
}

//user
export async function getSignupCount(base, limit) {
  var response = undefined;
  let reqUrl = '/naviwatch/api/v1/monitoring/user/get-signup-count';
  let config = {
    headers: {
      "authorizer": sessionStorage.getItem('idToken')
    }
  };
  let data = JSON.stringify({
    "base": base,
    "limit": limit
  });
  //console.log('getSignupCount : ' + reqUrl);
  response = await ApiInstance.post(reqUrl, data, config);
  //console.log(JSON.stringify(response.data, null, ' '));
  return response.data;
}

export async function getUserCount() {
  var response = undefined;
  let reqUrl = '/naviwatch/api/v1/monitoring/user/get-count';
  let config = {
    headers: {
      "authorizer": sessionStorage.getItem('idToken')
    }
  };

  //console.log('getUserCount : ' + reqUrl);
  response = await ApiInstance.get(reqUrl, config);
  //console.log(JSON.stringify(response.data, null, ' '));

  return response.data;
}

export async function getConnectUserCount() {
  var response = undefined;
  let reqUrl = '/naviwatch/api/v1/monitoring/user/get-connect-count';
  let config = {
    headers: {
      "authorizer": sessionStorage.getItem('idToken')
    }
  };

  //console.log('getUserCount : ' + reqUrl);
  response = await ApiInstance.get(reqUrl, config);
  //console.log(JSON.stringify(response.data, null, ' '));

  return response.data;
}

export async function userDetailInfo(userId, ip) {
  var response = undefined;
  let reqUrl = '/naviwatch/api/v1/monitoring/user/get-detail-info';
  let config = {
    headers: {
      "authorizer": sessionStorage.getItem('idToken')
    }
  };
  let data = JSON.stringify({
    "naviwatchId": sessionStorage.getItem('email'),
    "userId": userId,
    "ip": ip
  });
  response = await ApiInstance.post(reqUrl, data, config);
  // console.log("userDetailInfo : " + JSON.stringify(response.data, null, ' '));

  return response.data;
}

export async function getUserRegionCount() {
  var response = undefined;
  let reqUrl = '/naviwatch/api/v1/monitoring/user/get-region-count';
  let config = {
    headers: {
      "authorizer": sessionStorage.getItem('idToken')
    }
  };

  //console.log('getUserRegionCount : ' + reqUrl);
  response = await ApiInstance.get(reqUrl, config);
  //console.log(JSON.stringify(response.data, null, ' '));
  return response.data;
}

export async function userSearch(userId, userName, userEmail, userPhone, ipAddress) {
  var response = undefined;
  let reqUrl = '/naviwatch/api/v1/monitoring/user/search';
  let config = {
    headers: {
      "authorizer": sessionStorage.getItem('idToken')
    }
  };
  let data = JSON.stringify({
    "naviwatchId": sessionStorage.getItem('email'),
    "userId": userId,
    "ip": ipAddress,
    "userEmail": userEmail,
    "userName": userName,
    "userPhone": userPhone
  });
  response = await ApiInstance.post(reqUrl, data, config);
  // console.log("userSearch : " + JSON.stringify(response.data, null, ' '));

  return response.data;
}

export async function getRecord(handlerType, from, to, handlerId, userId, feature) {
  var response = undefined;
  let reqUrl = '/naviwatch/api/v1/monitoring/user/get-record';
  let config = {
    headers: {
      "authorizer": sessionStorage.getItem('idToken')
    }
  };
  if (feature === '비밀번호변경') {
    feature = 'CHANGE_PASSWORD';
  } else if (feature === '개인정보수정') {
    feature = 'UPDATE_USER';
  } else if (feature === '회원탈퇴') {
    feature = 'DEREGISTER_USER';
  }
  let data = JSON.stringify({
    "handlerType": handlerType,
    "fromDate": from,
    "toDate": to,
    "handlerId": handlerId,
    "userId": userId,
    "feature": feature
  });
  // console.log("data : " + JSON.stringify(data, null, ' '));

  response = await ApiInstance.post(reqUrl, data, config);
  // console.log("getRecord : " + JSON.stringify(response.data, null, ' '));

  return response.data;
}

export async function notificationDormantUser(userId, from, to, ip) {
  var response = undefined;
  let reqUrl = '/naviwatch/api/v1/monitoring/dormant/get-notification-log';
  let config = {
    headers: {
      "authorizer": sessionStorage.getItem('idToken')
    }
  };
  let data = JSON.stringify({
    "userId": (userId !== '' ? userId : null),
    "fromDate": (from !== '' ? from : null),
    "toDate": (to !== '' ? to : null),
    "naviwatchId": sessionStorage.getItem('email'),
    "ip": ip
  });
  // console.log("data : " + JSON.stringify(data, null, ' '));

  response = await ApiInstance.post(reqUrl, data, config);
  // console.log("notificationDormantUser : " + JSON.stringify(response.data, null, ' '));

  return response.data;
}

export async function searchDormantUser(userId, from, to, ip) {
  var response = undefined;
  let reqUrl = '/naviwatch/api/v1/monitoring/dormant/search';
  let config = {
    headers: {
      "authorizer": sessionStorage.getItem('idToken')
    }
  };
  let data = JSON.stringify({
    "userId": (userId !== '' ? userId : null),
    "fromDate": (from !== '' ? from : null),
    "toDate": (to !== '' ? to : null),
    "naviwatchId": sessionStorage.getItem('email'),
    "ip": ip
  });
  // console.log("data : " + JSON.stringify(data, null, ' '));

  response = await ApiInstance.post(reqUrl, data, config);
  // console.log("getRecord : " + JSON.stringify(response.data, null, ' '));

  return response.data;
}

export async function getDormantUserCount() {
  var response = undefined;
  let reqUrl = '/naviwatch/api/v1/monitoring/dormant/get-count';
  let config = {
    headers: {
      "authorizer": sessionStorage.getItem('idToken')
    }
  };

  //console.log('getUserCount : ' + reqUrl);
  response = await ApiInstance.get(reqUrl, config);
  //console.log(JSON.stringify(response.data, null, ' '));

  return response.data;
}

//server
export async function getPrivacyEC2Metric(ec2Name, metricName, endtimeSec, timelineSec, timePeriodSec) {
  var response = undefined;
  let reqUrl = '/naviwatch/api/v1/monitoring/server/get-ec2-metric';
  let config = {
    headers: {
      "authorizer": sessionStorage.getItem('idToken')
    }
  };
  let data = JSON.stringify({
    "ec2Name": ec2Name,
    "metricName": metricName,
    "endtimeSec": endtimeSec,
    "timelineSec": timelineSec,
    "timePeriodSec": timePeriodSec
  });
  //console.log('getEC2Metric : ' + reqUrl);
  response = await oldApiInstance.post(reqUrl, data, config);
  //console.log(JSON.stringify(response.data, null, ' '));

  return response.data;
}

export async function getEC2Metric(ec2Name, metricName, endtimeSec, timelineSec, timePeriodSec) {
  var response = undefined;
  let reqUrl = '/naviwatch/api/v1/monitoring/server/get-ec2-metric';
  let config = {
    headers: {
      "authorizer": sessionStorage.getItem('idToken')
    }
  };
  let data = JSON.stringify({
    "ec2Name": ec2Name,
    "metricName": metricName,
    "endtimeSec": endtimeSec,
    "timelineSec": timelineSec,
    "timePeriodSec": timePeriodSec
  });
  //console.log('getEC2Metric : ' + reqUrl);
  response = await ApiInstance.post(reqUrl, data, config);
  //console.log(JSON.stringify(response.data, null, ' '));

  return response.data;
}

export async function getRdsMetric(rdsName, metricName, endtimeSec, timelineSec, timePeriodSec, unit) {
  var response = undefined;
  let reqUrl = '/naviwatch/api/v1/monitoring/server/get-rds-metric';
  let config = {
    headers: {
      "authorizer": sessionStorage.getItem('idToken')
    }
  };
  let data = JSON.stringify({
    "rdsName": rdsName,
    "metricName": metricName,
    "endtimeSec": endtimeSec,
    "timelineSec": timelineSec,
    "timePeriodSec": timePeriodSec,
    "unit": unit
  });

  //console.log('getRdsMetric : ' + reqUrl);
  response = await ApiInstance.post(reqUrl, data, config);
  //console.log(JSON.stringify(response.data, null, ' '));

  return response.data;
}


//firmware
export async function getFirmwareModelInfo(productName, firmwareType) {
  var response = undefined;
  let reqUrl = '/naviwatch/api/v1/monitoring/firmware/get-model-info';
  let config = {
    headers: {
      "authorizer": sessionStorage.getItem('idToken')
    }
  };

  let data = JSON.stringify({
    "type": productName,
    "firmwareType": firmwareType
  });

  //console.log('getFirmwareModelInfo : ' + reqUrl);
  response = await ApiInstance.post(reqUrl, data, config);
  // console.log(JSON.stringify(response.data, null, ' '));

  return response.data;
}

export async function getFirmwareList(swCodeMasking, firmwareType) {
  var response = undefined;
  let reqUrl = '/naviwatch/api/v1/monitoring/firmware/get-list';
  let config = {
    headers: {
      "authorizer": sessionStorage.getItem('idToken')
    }
  };

  let data = JSON.stringify({
    "swCodeMasking": swCodeMasking,
    "firmwareType": firmwareType
  });

  //console.log('getFirmwareList : ' + reqUrl);
  response = await ApiInstance.post(reqUrl, data, config);
  // console.log(JSON.stringify(response.data, null, ' '));

  return response.data;
}

export async function getServiceToolkitInfo(version, swCode) {
  var response = undefined;
  let reqUrl = '/naviwatch/api/v1/monitoring/firmware/get-servicetoolkit-info';
  let config = {
    headers: {
      "authorizer": sessionStorage.getItem('idToken')
    }
  };

  let data = JSON.stringify({
    "version": version,
    "swCode": swCode
  });

  //console.log('getFirmwareList : ' + reqUrl);
  response = await ApiInstance.post(reqUrl, data, config);
  // console.log(JSON.stringify(response.data, null, ' '));
  return response.data;
}

export async function getFirmwareGroupDevices(version, swCode, releaseType) {
  var response = undefined;
  let reqUrl = '/naviwatch/api/v1/monitoring/firmware/get-group-devices';
  let config = {
    headers: {
      "authorizer": sessionStorage.getItem('idToken')
    }
  };
  let data = JSON.stringify({
    "version": version,
    "swCode": swCode,
    "releaseType": releaseType
  });

  //console.log('getFirmwareGroupDevices : ' + reqUrl);
  response = await ApiInstance.post(reqUrl, data, config);
  //console.log(JSON.stringify(response.data, null, ' '));

  return response.data;
}

export async function setFirmwareGroupDevices(version, swCode, deviceList, releaseType, endCallback) {
  var response = undefined;
  let reqUrl = '/naviwatch/api/v1/monitoring/firmware/set-group-devices';
  let config = {
    headers: {
      "authorizer": sessionStorage.getItem('idToken')
    }
  };
  let data = JSON.stringify({
    "version": version,
    "swCode": swCode,
    "deviceList": deviceList,
    "releaseType": releaseType,
    "naviWatchId": sessionStorage.getItem('email')
  });

  //console.log('setFirmwareGroupdevices : ' + reqUrl);
  response = await ApiInstance.post(reqUrl, data, config);
  //console.log(JSON.stringify(response.data, null, ' '));
  endCallback();
  return response.data;
}


export async function addFirmware(firmwares, endCallback) {
  var response = undefined;
  let reqUrl = '/naviwatch/api/v1/monitoring/firmware/add';
  let config = {
    headers: {
      "authorizer": sessionStorage.getItem('idToken')
    }
  };
  let data = JSON.stringify({
    "firmwares": firmwares
  });
  // console.log('addFirmware : ' + reqUrl);
  response = await ApiInstance.post(reqUrl, data, config);
  // console.log(JSON.stringify(response.data, null, ' '));
  endCallback();
  return response.data;
}

export async function updateFirmware(version, swCode, activeFlag, forceFlag, groupFlag, bsCheckFlag, description, updateTime, firmwareType, endCallback) {
  var response = undefined;
  let reqUrl = '/naviwatch/api/v1/monitoring/firmware/update';
  let config = {
    headers: {
      "authorizer": sessionStorage.getItem('idToken')
    }
  };

  let data = JSON.stringify({
    "version": version,
    "swCode": swCode,
    "activeFlag": activeFlag,
    "forceFlag": forceFlag,
    "groupFlag": groupFlag,
    "bsCheckFlag": bsCheckFlag,
    "description": description,
    "naviWatchId": sessionStorage.getItem('email'),
    "updateTime": updateTime,
    "firmwareType": firmwareType
  })


  //console.log('updateFirmware : ' + reqUrl);
  response = await ApiInstance.post(reqUrl, data, config);
  //console.log(JSON.stringify(response.data, null, ' '));
  endCallback();
  return response.data;
}

export async function deleteFirmware(version, swCode, endCallback) {
  var response = undefined;
  let reqUrl = '/naviwatch/api/v1/monitoring/firmware/delete';
  let config = {
    headers: {
      "authorizer": sessionStorage.getItem('idToken')
    }
  };
  let data = JSON.stringify({
    "version": version,
    "swCode": swCode,
    "naviWatchId": sessionStorage.getItem('email')
  });

  //console.log('deleteFirmware : ' + reqUrl);
  response = await ApiInstance.post(reqUrl, data, config);
  //console.log(JSON.stringify(response.data, null, ' '));
  endCallback();
  return response.data;
}

export async function getEngineerInfo(swCode, version, releaseType) {
  var response = undefined;
  let reqUrl = '/naviwatch/api/v1/monitoring/firmware/get-engineer-info';
  let config = {
    headers: {
      "authorizer": sessionStorage.getItem('idToken')
    }
  };
  let data = JSON.stringify({
    "version": version,
    "swCode": swCode,
    "releaseType": releaseType
  });

  //console.log('deleteFirmware : ' + reqUrl);
  response = await ApiInstance.post(reqUrl, data, config);
  // console.log(JSON.stringify(response.data, null, ' '));
  return response.data;
}

export async function searchEngineer(keyword) {
  var response = undefined;
  let reqUrl = '/naviwatch/api/v1/monitoring/firmware/search-engineer';
  let config = {
    headers: {
      "authorizer": sessionStorage.getItem('idToken')
    }
  };
  let data = JSON.stringify({
    "keyword": keyword
  });

  //console.log('deleteFirmware : ' + reqUrl);
  response = await ApiInstance.post(reqUrl, data, config);
  // console.log(JSON.stringify(response.data, null, ' '));
  return response.data;
}

export async function setGroupEngineer(version, swCode, releaseType, engineerList) {
  var response = undefined;
  let reqUrl = '/naviwatch/api/v1/monitoring/firmware/set-group-engineer';
  let config = {
    headers: {
      "authorizer": sessionStorage.getItem('idToken')
    }
  };
  let data = JSON.stringify({
    "version": version,
    "swCode": swCode,
    "releaseType": releaseType,
    "engineerList": engineerList
  });

  //console.log('deleteFirmware : ' + reqUrl);
  response = await ApiInstance.post(reqUrl, data, config);
  // console.log(JSON.stringify(response.data, null, ' '));
  return response.data;
}