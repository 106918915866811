import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Input,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormGroup,
  Button,
  TextField
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import DeleteIcon from '@material-ui/icons/Delete';
import template from '../../../../../common/mateTemplate';

const useStyles = makeStyles(theme => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  },
  title: {
    fontWeight: 700
  },
  formControl: {
    // margin: theme.spacing(1),
    width: '100%'
  },
  labelText: {
    color: '#aaaaaa'
  },
  indeterminateColor: {
    color: '#f50057'
  },
  selectAllText: {
    fontWeight: 500
  },
  selectedAll: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)'
    }
  }
}));

const STRING_DEVICE_SEQ = 'Device SEQ';
const STRING_SEARCH = '검색';
const STRING_SEARCH_CLEAN = '초기화';
const STRING_TITLE = '온수매트 로그 조회';
const STRING_MAT_COUNT =
  '로그를 조회하고 싶은 deviceSeq (Navien Smart 기준)을 입력하세요. 올바른 편측과 일자를 입력하면 조회가 가능합니다.';
const DeviceLogToolbar = props => {
  const { className, callbackFunc, ...rest } = props;

  const classes = useStyles();
  const [whereKeys, setWhereKeys] = useState([]);

  const [values, setValues] = useState({
    deviceSeq: '',
    from: '',
    to: '',
    table: '',
    side: '',
    columns: [],
    wheres: []
  });
  const columnList = useMemo(() => {
    let result = [];
    if (values.table.length > 0) {
      result = Object.keys(template.table[values.table].columns);
    }
    return result;
  }, [values, values.table]);

  const whereColumnList = useMemo(() => {
    let result = [];
    if (values.table.length > 0) {
      result = Object.keys(template.table[values.table].columns).filter(
        column => !whereKeys.includes(column)
      );
    }
    return result;
  }, [values, values.wheres, whereKeys]);

  const isAllSelected = useMemo(() => {
    return (
      values.columns.length > 0 && values.columns.length === columnList.length
    );
  }, [columnList, values, values.table, values.columns]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    },
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center'
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'center'
    }
  };

  const cleanSearchInput = () => {
    setValues({
      deviceSeq: '',
      from: '',
      to: '',
      side: '',
      table: '',
      columns: [],
      wheres: []
    });
  };

  const handleSideRadioChange = event => {
    setValues({
      ...values,
      side: event.target.value
    });
  };

  const sideRadioBox = () => {
    return (
      <FormControl component={'fieldset'}>
        <FormLabel component={'legend'}> Side Select </FormLabel>
        <RadioGroup
          aria-label={'Side Select'}
          name="side"
          value={values.side}
          onChange={handleSideRadioChange}>
          {template !== null &&
            ['single', 'left', 'right', 'both'].map(side => (
              <FormControlLabel value={side} control={<Radio />} label={side} />
            ))}
        </RadioGroup>
      </FormControl>
    );
  };

  const handleTableRadioChange = event => {
    setValues({
      ...values,
      table: event.target.value,
      columns: []
    });
  };

  const tableRadioBox = () => {
    return (
      <FormControl component={'fieldset'}>
        <FormLabel component={'legend'}> Table Select </FormLabel>
        <RadioGroup
          aria-label={'Table Select'}
          name="table"
          value={values.table}
          onChange={handleTableRadioChange}>
          {template !== null &&
            Object.keys(template.table).map(table => (
              <FormControlLabel
                value={table}
                control={<Radio />}
                label={table}
              />
            ))}
        </RadioGroup>
      </FormControl>
    );
  };

  const handleBoxChange = event => {
    const value = event.target.value;

    if (value[value.length - 1] === 'all') {
      setValues(
        values.columns.length === columnList.length
          ? {
              ...values,
              columns: []
            }
          : {
              ...values,
              columns: columnList
            }
      );
    } else {
      const columnsArr = value.filter(item => {
        return item;
      });

      setValues({
        ...values,
        columns: columnsArr
      });
    }
  };

  const multiSelectBox = () => {
    const selected = values.columns;
    return values.table.length > 0 ? (
      <FormControl className={classes.formControl}>
        <InputLabel className={classes.labelText} id="mutiple-select-label">
          Column Select
        </InputLabel>
        <Select
          labelId="mutiple-select-label"
          multiple
          value={selected}
          onChange={handleBoxChange}
          renderValue={selected => selected.join(', ')}
          MenuProps={MenuProps}>
          <MenuItem
            selected={false}
            value="all"
            classes={{
              root: isAllSelected ? classes.selectedAll : ''
            }}>
            <ListItemIcon>
              <Checkbox
                classes={{ indeterminate: classes.indeterminateColor }}
                checked={isAllSelected}
                indeterminate={
                  selected.length > 0 && selected.length < columnList.length
                }
              />
            </ListItemIcon>
            <ListItemText
              disabled={true}
              inset={false}
              disableTypography={false}
              classes={{ primary: classes.selectAllText }}
              primary={<p>Select All</p>}
            />
          </MenuItem>

          {columnList.map(option => (
            <MenuItem key={option} value={option}>
              <ListItemIcon>
                <Checkbox checked={selected.indexOf(option) > -1} />
              </ListItemIcon>
              <ListItemText primary={option} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    ) : (
      <div />
    );
  };

  const handleWhereBoxChange = index => event => {
    const value = event.target.value;

    const keys = [];
    const data = values.wheres.reduce((result, current, currentIndex) => {
      if (index === currentIndex) {
        result.push({
          column: value,
          value: ''
        });
        keys.push(value);
      } else {
        result.push({ ...current });
        keys.push(current['column']);
      }
      return result;
    }, []);
    setValues({
      ...values,
      wheres: data
    });
    setWhereKeys(keys);
  };

  const onWhereInputChange = index => e => {
    const value = e.target.value;
    const data = values.wheres.reduce((result, current, currentIndex) => {
      if (index != currentIndex) {
        result.push({ ...current });
      } else {
        result.push({
          ...current,
          value
        });
      }
      return result;
    }, []);
    setValues({
      ...values,
      wheres: data
    });
  };

  const onClickDeleteWhere = (index, where) => () => {
    const data = values.wheres.reduce((result, current, currentIndex) => {
      if (index != currentIndex) {
        result.push({ ...current });
      }
      return result;
    }, []);
    setValues({
      ...values,
      wheres: data
    });
    setWhereKeys(whereKeys.filter(x => x != where));
  };

  const whereSelectBox = (where, index) => {
    return (
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
          <FormControl style={{ width: '400px', marginRight: '20px' }}>
            <InputLabel className={classes.labelText} id="where-select-label">
              Where Column Select
            </InputLabel>
            <Select
              labelId="where-select-label"
              value={where['column']}
              onChange={handleWhereBoxChange(index)}
              renderValue={selected => {
                return selected;
              }}
              MenuProps={MenuProps}>
              {whereColumnList.map(option => (
                <MenuItem key={option} value={option}>
                  <ListItemText primary={option} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {where['column'] && (
            <FormControl style={{ width: '400px' }}>
              <InputLabel className={classes.labelText} id="where-select-label">
                Where Value
              </InputLabel>
              <Input
                type={
                  template.table[values.table].columns[where['column']] ==
                  'string'
                    ? 'text'
                    : 'number'
                }
                onChange={onWhereInputChange(index)}
                labelId="where-value">
                {values.wheres[index].value}
              </Input>
            </FormControl>
          )}
        </div>
        <DeleteIcon
          style={{ cursor: 'pointer' }}
          onClick={onClickDeleteWhere(index, where)}
        />
      </>
    );
  };

  const handleAddInput = () => {
    setValues({
      ...values,
      wheres: [
        ...values.wheres,
        {
          column: '',
          value: ''
        }
      ]
    });
  };

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };
  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader title={STRING_TITLE} subheader={STRING_MAT_COUNT} />
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item md={4} xs={12}>
            <TextField
              fullWidth
              label={STRING_DEVICE_SEQ}
              margin="dense"
              name="deviceSeq"
              disabled={values.condition === ''}
              onChange={handleChange}
              value={values.deviceSeq}
              variant="outlined"
            />
          </Grid>
          <Grid item md={1} xs={2}>
            <div
              style={{
                display: 'flex'
              }}>
              {sideRadioBox()}
            </div>
          </Grid>
          <Grid item md={1} xs={2}>
            <div
              style={{
                display: 'flex'
              }}>
              {tableRadioBox()}
            </div>
          </Grid>
          <Grid item md={6} xs={4}>
            <div
              style={{
                display: 'flex',
                width: '100%'
              }}>
              {multiSelectBox()}
            </div>
            {whereColumnList.length > 0 && (
              <Button
                color="primary"
                variant="contained"
                onClick={handleAddInput}
                style={{
                  marginTop: '10px',
                  cursor: 'pointer'
                }}
                // disabled={!values.deviceSeq && !values.from && !values.to}
              >
                {'Where Add Button'}
              </Button>
            )}
          </Grid>
          {values.wheres.length > 0 &&
            values.wheres.map((where, index) => (
              <Grid item md={12} xs={4}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                  {whereSelectBox(where, index)}
                </div>
              </Grid>
            ))}
          <Grid item md={3} xs={4}>
            <form className={classes.container} noValidate>
              <TextField
                id="from"
                name="from"
                label="From"
                type="datetime-local"
                className={classes.textField}
                value={values.from}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </form>
          </Grid>
          <Grid item md={3} xs={4}>
            <form className={classes.container} noValidate>
              <TextField
                id="to"
                name="to"
                label="To"
                type="datetime-local"
                className={classes.textField}
                value={values.to}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </form>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>
        <Button
          color="primary"
          variant="contained"
          onClick={cleanSearchInput}
          disabled={!values.deviceSeq && !values.from && !values.to}>
          {STRING_SEARCH_CLEAN}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            callbackFunc(values);
            console.log('click');
          }}
          disabled={!values.deviceSeq || !values.from || !values.to}>
          {STRING_SEARCH}
        </Button>
      </CardActions>
    </Card>
  );
};

DeviceLogToolbar.propTypes = {
  className: PropTypes.string
};

export default DeviceLogToolbar;
