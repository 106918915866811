import React, { useEffect, useState } from 'react';
import {
  Dialog, DialogActions, DialogContent, Button,
  DialogTitle, Divider, Typography, makeStyles,
  Checkbox, TextField, Backdrop, CircularProgress,
  OutlinedInput, InputAdornment
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import { updateFirmware } from '../../../APIs/tokapis';
import AlertPopup from './AlertPopup';
import {
  STRING_FIRMWARE_UPDATE,
  STRING_ALERT_FW_UPDATE_CHECK,
  STRING_COMPULSORY_INPUT_DESCRIPTION,
  STRING_UPDATE,
  STRING_FW_VERSION,
  STRING_SW_CODE,
  STRING_ACTIVE,
  STRING_FORCE,
  STRING_GROUP,
  STRING_FW_DESCRIPTION,
  STRING_CANCEL,
  STRING_UPLOAD_TIME,
  STRING_LINE_LIMIT,
  STRING_MINUTE,
  STRING_EXIST_ACTIVE_FIRMWARE,
  STIRNG_SUCCESS_UPDATE_FIRMWARE,
  STRING_FAIL_UPDATE_FIRMWARE,
  STRING_ALERT_CHECKBOX,
  STRING_BS_CHECK,
  STRING_CLEAN_ENGINEER_LIST_BS_FIRMWARE
} from '../../../StringTable/StringTable';


const useStyles = makeStyles(theme => ({
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  linear: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(2)
  },
  datas: {
    marginLeft: theme.spacing(2)
  },
  icons: {
    marginLeft: theme.spacing(2),
    padding: theme.spacing(0)
  },
  fwDescription: {
    marginLeft: theme.spacing(2),
    width: 400
  },
  uploadTime: {
    marginLeft: theme.spacing(2),
    width: '20%'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: theme.spacing(2)
  }
}));

const UpdateFirmwareDialog = props => {
  const { open, handleClose, refetch, selectedFW, firmwareType, product, modelName, activeCheck, bsCheck } = props;
  const [loading, setLoading] = useState(false);
  const [forceFlag, setForceFlag] = useState(false);
  const [activeFlag, setActiveFlag] = useState(false);
  const [groupFlag, setGroupFlag] = useState(false);
  const [bsCheckFlag, setBSCheckFlag] = useState(false);
  const [description, setDescription] = useState("");
  const [updateTime, setUpdateTime] = useState("");
  const [successOpen, setSuccessOpen] = useState(false);
  const [failOpen, setFailOpen] = useState(false);
  const [activeOpen, setActiveOpen] = useState(false);
  const [forceOpen, setForceOpen] = useState(false);
  const [groupOpen, setGroupOpen] = useState(false);
  const [groupClose, setGroupClose] = useState(false);
  const [bsCheckOpen, setBSCheckOpen] = useState(false);
  const classes = useStyles();
  const STRING_ALERT_ACTIVE_CHECK = '연결된 ' + modelName + '의 모든 ' + product + '을 업데이트를 활성화하게 됩니다 !';
  const STRING_ALERT_FORCE_CHECK = '연결된 ' + modelName + '의 모든 ' + product + '을 강제로 업데이트하게 됩니다 !';
  const STRING_ALERT_GROUP_CHECK = modelName + '에 선택된 디바이스 그룹의 ' + product + '만 업데이트 하게 됩니다 !';
  const STRING_ALERT_GROUP_CLOSE = '체크 박스 해제 시 ' + product + '의 펌웨어 모델 ' + modelName + '에 속한 모든 디바이스 그룹이 삭제됩니다 !';
  const STRING_ALERT_BS_CHECK = '해당 펌웨어의 OS 펌웨어를 BS 펌웨어 리스트에 추가합니다 !';

  useEffect(() => {
    if (firmwareType === 'firmwareOfficial') {
      setForceFlag(selectedFW.forceFlag);
      setActiveFlag(selectedFW.activeFlag);
      setGroupFlag(selectedFW.groupFlag);
      setBSCheckFlag(selectedFW.bsCheckFlag);
    }
    setDescription(selectedFW.description);
    setUpdateTime(selectedFW.updateTime);
  }, [open]);

  const handleForceFlag = () => {
    if (forceFlag) {
      setForceFlag(false);
    } else {
      openAlertForce();
      setForceFlag(true);
    }
  };

  const handleActiveFlag = () => {
    if (activeFlag) {
      setActiveFlag(false);
    } else {
      openAlertActive();
      setActiveFlag(true);
    }
  };

  const handleGroupFlag = () => {
    if (groupFlag) {
      openAlertGroupClose();
      setGroupFlag(false);
    } else {
      openAlertGroup();
      setGroupFlag(true);
    }
  };

  const handleBSCheckFlag = () => {
    if (bsCheckFlag) {
      setBSCheckFlag(false);
    } else {
      openAlertBSCheck();
      setBSCheckFlag(true);
    }
  };

  const handleCloseClick = () => {
    setForceFlag(false);
    setActiveFlag(false);
    setGroupFlag(false);
    setBSCheckFlag(false);
    setDescription("");
    setUpdateTime("");
    handleClose();
  };

  const handleModify = async () => {
    setLoading(true);
    handleCloseClick();
    let updateFW = await updateFirmware(
      selectedFW.version,
      selectedFW.swCode,
      activeFlag,
      forceFlag,
      groupFlag,
      bsCheckFlag,
      description,
      updateTime,
      firmwareType,
      refetchList);
    setLoading(false);
    if (updateFW.msg === "SUCCESS") {
      openAlertSuccess();
    } else {
      openAlertFail();
    }
  };

  const changeFWDescription = event => {
    event.persist();
    setDescription(event.target.value);
  };

  const changeUploadTime = event => {
    event.persist();
    setUpdateTime(event.target.value);
  };

  const refetchList = () => refetch();
  const openAlertSuccess = () => setSuccessOpen(true);
  const closeAlertSuccess = () => setSuccessOpen(false);
  const openAlertFail = () => setFailOpen(true);
  const closeAlertFail = () => setFailOpen(false);
  const openAlertActive = () => setActiveOpen(true);
  const closeAlertActive = () => setActiveOpen(false);
  const openAlertForce = () => setForceOpen(true);
  const closeAlertForce = () => setForceOpen(false);
  const openAlertGroup = () => setGroupOpen(true);
  const closeAlertGroup = () => setGroupOpen(false);
  const openAlertGroupClose = () => setGroupClose(true);
  const closeAlertGroupClose = () => setGroupClose(false);
  const openAlertBSCheck = () => setBSCheckOpen(true);
  const closeAlertBSCheck = () => setBSCheckOpen(false);


  return (
    <div>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color={'primary'} />
      </Backdrop>
      <Dialog
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{STRING_FIRMWARE_UPDATE}</DialogTitle>
        <DialogContent>
          <Alert severity={'error'}>
            <span style={{ color: 'red' }}>{STRING_ALERT_FW_UPDATE_CHECK}</span>
            <div style={{ color: 'red' }}>{STRING_COMPULSORY_INPUT_DESCRIPTION}</div>
          </Alert>
          <Divider className={classes.divider} />
          <div className={classes.linear}>
            <Typography variant="h6">{STRING_FW_VERSION}{' :'}</Typography>
            <Typography className={classes.datas} variant="h6">{selectedFW.version ? 'V' + parseInt(selectedFW.version.substr(0, 2), 16) + '-' + parseInt(selectedFW.version.substr(2, 2), 16) : '-'}</Typography>
          </div>
          <Divider className={classes.divider} />
          <div className={classes.linear}>
            <Typography variant="h6">{STRING_SW_CODE}{' :'}</Typography>
            <Typography className={classes.datas} variant="h6">{selectedFW.swCode ? '0x' + selectedFW.swCode : '-'}</Typography>
          </div>
          {firmwareType === "firmwareOfficial" &&
            <div>
              <Divider className={classes.divider} />
              <div className={classes.linear}>
                <Typography variant="h6">{STRING_ACTIVE}</Typography>
                <Checkbox className={classes.icons}
                  checked={activeFlag}
                  onChange={handleActiveFlag}
                  color="primary"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                  disabled={selectedFW.activeFlag == false && activeCheck == true}
                />
                <div>　　　</div>
                <Typography variant="h6">{STRING_FORCE}</Typography>
                <Checkbox className={classes.icons}
                  checked={forceFlag}
                  onChange={handleForceFlag}
                  color="primary"
                  inputProps={{ 'aria-label': 'secondary checkbox' }} />
                <div>　　　</div>
                <Typography variant="h6">{STRING_GROUP}</Typography>
                <Checkbox className={classes.icons}
                  checked={groupFlag}
                  onChange={handleGroupFlag}
                  color="primary"
                  inputProps={{ 'aria-label': 'secondary checkbox' }} />
                <div>　　　</div>
                <Typography variant="h6">{STRING_BS_CHECK}</Typography>
                <Checkbox className={classes.icons}
                  checked={bsCheckFlag}
                  onChange={handleBSCheckFlag}
                  color="primary"
                  inputProps={{ 'aria-label': 'secondary checkbox' }} />
              </div>
              {bsCheck == true && bsCheckFlag == false && <Typography variant="caption" color="error" className={classes.linear}>{STRING_CLEAN_ENGINEER_LIST_BS_FIRMWARE}</Typography>}
            </div>
          }
          <Divider className={classes.divider} />
          <div className={classes.linear}>
            <Typography variant="h6">{STRING_FW_DESCRIPTION}{' :'}</Typography>
            <pre>
              <TextField
                id="description"
                className={classes.fwDescription}
                multiline
                defaultValue={selectedFW.description}
                maxRows={4}
                onChange={changeFWDescription}
                label={STRING_LINE_LIMIT}
              />
            </pre>
          </div>
          <Divider className={classes.divider} />
          <div className={classes.linear}>
            <Typography variant="h6">{STRING_UPLOAD_TIME}{' :'}</Typography>
            <OutlinedInput
              className={classes.uploadTime}
              id="upload-time"
              size="small"
              margin="dense"
              defaultValue={selectedFW.updateTime}
              onChange={changeUploadTime}
              endAdornment={<InputAdornment position="end">{STRING_MINUTE}</InputAdornment>}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseClick} color="primary" autoFocus>
            {STRING_CANCEL}
          </Button>
          {firmwareType === "firmwareOfficial" &&
            <Button onClick={handleModify}
              disabled={(description == selectedFW.description && updateTime == selectedFW.updateTime
                && forceFlag == selectedFW.forceFlag && activeFlag == selectedFW.activeFlag && groupFlag == selectedFW.groupFlag && bsCheckFlag == selectedFW.bsCheckFlag) || (description !== null && description.length == 0)} color="primary" autoFocus>
              {STRING_UPDATE}
            </Button>}
          {firmwareType === "firmwareBS" &&
            <Button onClick={handleModify}
              disabled={(description == selectedFW.description && updateTime == selectedFW.updateTime) || (description !== null && description.length == 0)} color="primary" autoFocus>
              {STRING_UPDATE}
            </Button>}
        </DialogActions>
      </Dialog>
      <AlertPopup open={successOpen} handleClose={closeAlertSuccess} comment={STIRNG_SUCCESS_UPDATE_FIRMWARE} color={'green'} severity={'success'} />
      <AlertPopup open={failOpen} handleClose={closeAlertFail} comment={STRING_FAIL_UPDATE_FIRMWARE} color={'red'} severity={'error'} />
      <AlertPopup open={activeOpen} handleClose={closeAlertActive} comment={STRING_ALERT_ACTIVE_CHECK}
        comment2={STRING_ALERT_CHECKBOX} color={'red'} severity={'error'} />
      <AlertPopup open={forceOpen} handleClose={closeAlertForce} comment={STRING_ALERT_FORCE_CHECK}
        comment2={STRING_ALERT_CHECKBOX} color={'red'} severity={'error'} />
      <AlertPopup open={groupOpen} handleClose={closeAlertGroup} comment={STRING_ALERT_GROUP_CHECK}
        comment2={STRING_ALERT_CHECKBOX} color={'red'} severity={'error'} />
      <AlertPopup open={groupClose} handleClose={closeAlertGroupClose} comment={STRING_ALERT_GROUP_CLOSE} color={'red'} severity={'error'} />
      <AlertPopup open={bsCheckOpen} handleClose={closeAlertBSCheck} comment={STRING_ALERT_BS_CHECK}
        comment2={STRING_ALERT_CHECKBOX} color={'red'} severity={'error'} />
    </div>
  );
};

UpdateFirmwareDialog.propTypes = {
  className: PropTypes.string
};

export default UpdateFirmwareDialog;
