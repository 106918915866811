import axios from 'axios';
import { deploy } from 'config';

var ApiInstance = null;
var S3ApiInstance = null;
if (deploy === "dev") {
  ApiInstance = axios.create({ baseURL: 'https://q3gl7lx0s8.execute-api.eu-west-2.amazonaws.com' });
  S3ApiInstance = axios.create({ baseURL: 'https://spuk-dev-fota-temp.s3.amazonaws.com' });
} else if (deploy === "stage") {
  ApiInstance = axios.create({ baseURL: 'https://waxtwlt50d.execute-api.eu-west-2.amazonaws.com' });
  S3ApiInstance = axios.create({ baseURL: 'https://spuk-stage-fota-temp.s3.amazonaws.com' });
} else if (deploy === "prod") {
  ApiInstance = axios.create({ baseURL: 'https://jciwz2uaxl.execute-api.eu-west-2.amazonaws.com' });
  S3ApiInstance = axios.create({ baseURL: 'https://spuk-prod-fota-temp.s3.amazonaws.com' });
}

// credentials
export async function getCredentials() {
  var response = undefined;
  let reqUrl = '/backoffice/api/v1/monitoring/credentials';
  let config = {
    headers: {
      "authorizer": sessionStorage.getItem('idToken')
    }
  }
  try {
    response = await ApiInstance.get(reqUrl, config);
  } catch (e) {
    // console.log("error : " + JSON.stringify(e));
    return { 'code': e.status, 'msg': e.code };
  }
  // console.log(JSON.stringify(response.data, null, ' '));
  return response.data;
}

//firmware
export async function getFirmwareModelInfo(productName, firmwareType) {
  var response = undefined;
  let reqUrl = '/backoffice/api/v1/monitoring/firmware/get-model-info';
  let config = {
    headers: {
      "authorizer": sessionStorage.getItem('idToken')
    }
  };
  let data = JSON.stringify({
    "type": productName,
    "firmwareType": firmwareType
  });

  //console.log('getFirmwareModelInfo : ' + reqUrl);
  response = await ApiInstance.post(reqUrl, data, config);
  // console.log(JSON.stringify(response.data, null, ' '));
  return response.data;
}

export async function getFirmwareList(swCodeMasking, firmwareType) {
  var response = undefined;
  let reqUrl = '/backoffice/api/v1/monitoring/firmware/get-list';
  let config = {
    headers: {
      "authorizer": sessionStorage.getItem('idToken')
    }
  };
  let data = JSON.stringify({
    "swCodeMasking": swCodeMasking,
    "firmwareType": firmwareType
  });

  //console.log('getFirmwareList : ' + reqUrl);
  response = await ApiInstance.post(reqUrl, data, config);
  // console.log(JSON.stringify(response.data, null, ' '));
  return response.data;
}

export async function getFirmwareGroupDevices(version, swCode, releaseType) {
  var response = undefined;
  let reqUrl = '/backoffice/api/v1/monitoring/firmware/get-group-devices';
  let config = {
    headers: {
      "authorizer": sessionStorage.getItem('idToken')
    }
  };
  let data = JSON.stringify({
    "version": version,
    "swCode": swCode,
    "releaseType": releaseType
  });

  //console.log('getFirmwareGroupDevices : ' + reqUrl);
  response = await ApiInstance.post(reqUrl, data, config);
  // console.log(JSON.stringify(response.data, null, ' '));
  return response.data;
}

export async function setFirmwareGroupDevices(version, swCode, deviceList, releaseType, endCallback) {
  var response = undefined;
  let reqUrl = '/backoffice/api/v1/monitoring/firmware/set-group-devices';
  let config = {
    headers: {
      "authorizer": sessionStorage.getItem('idToken')
    }
  };
  let data = JSON.stringify({
    "version": version,
    "swCode": swCode,
    "deviceList": deviceList,
    "releaseType": releaseType,
    "naviWatchId": sessionStorage.getItem('email')
  });

  //console.log('setFirmwareGroupdevices : ' + reqUrl);
  response = await ApiInstance.post(reqUrl, data, config);
  // console.log(JSON.stringify(response.data, null, ' '));
  endCallback();
  return response.data;
}


export async function addFirmware(firmwares, endCallback) {
  var response = undefined;
  let reqUrl = '/backoffice/api/v1/monitoring/firmware/add';
  let config = {
    headers: {
      "authorizer": sessionStorage.getItem('idToken')
    }
  };
  let data = JSON.stringify({
    "firmwares": firmwares
  });
  // console.log('addFirmware : ' + reqUrl);
  try {
    response = await ApiInstance.post(reqUrl, data, config);
  } catch (e) {
    // console.log("error : " + JSON.stringify(e));
    return { 'code': e.status, 'msg': e.code, 'data': { 'errorMsg': e.code } };
  }
  // console.log(JSON.stringify(response.data, null, ' '));
  endCallback();
  return response.data;
}

export async function putFileIntoS3(key, md5, file, fileName) {
  var response = undefined;
  let requestUrl = '/' + fileName;
  let config = {
    headers: {
      'x-amz-grant-full-control': key,
      'Content-MD5': md5
    }
  }
  let data = file
  try {
    response = await S3ApiInstance.put(requestUrl, data, config);
  } catch (e) {
    // console.log("error : " + JSON.stringify(e));
    return e;
  }
  // console.log(JSON.stringify(response, null, ' '));
  return response;
}

export async function updateFirmware(version, swCode, activeFlag, forceFlag, groupFlag, bsCheckFlag, description, updateTime, firmwareType, endCallback) {
  var response = undefined;
  let reqUrl = '/backoffice/api/v1/monitoring/firmware/update';
  let config = {
    headers: {
      "authorizer": sessionStorage.getItem('idToken')
    }
  };
  let data = JSON.stringify({
    "version": version,
    "swCode": swCode,
    "activeFlag": activeFlag,
    "forceFlag": forceFlag,
    "groupFlag": groupFlag,
    "bsCheckFlag": bsCheckFlag,
    "description": description,
    "naviWatchId": sessionStorage.getItem('email'),
    "updateTime": updateTime,
    "firmwareType": firmwareType
  })

  //console.log('updateFirmware : ' + reqUrl);
  response = await ApiInstance.post(reqUrl, data, config);
  // console.log(JSON.stringify(response.data, null, ' '));
  endCallback();
  return response.data;
}

export async function deleteFirmware(version, swCode, endCallback) {
  var response = undefined;
  let reqUrl = '/backoffice/api/v1/monitoring/firmware/delete';
  let config = {
    headers: {
      "authorizer": sessionStorage.getItem('idToken')
    }
  };
  let data = JSON.stringify({
    "version": version,
    "swCode": swCode,
    "naviWatchId": sessionStorage.getItem('email')
  });

  //console.log('deleteFirmware : ' + reqUrl);
  try {
    response = await ApiInstance.post(reqUrl, data, config);
  } catch (e) {
    // console.log("error : " + JSON.stringify(e));
    return { 'code': e.status, 'msg': e.code, 'data': { 'errorMsg': e.code } };
  }
  // console.log(JSON.stringify(response.data, null, ' '));
  endCallback();
  return response.data;
}

export async function getEngineerInfo(swCode, version, releaseType) {
  var response = undefined;
  let reqUrl = '/backoffice/api/v1/monitoring/firmware/get-engineer-info';
  let config = {
    headers: {
      "authorizer": sessionStorage.getItem('idToken')
    }
  };
  let data = JSON.stringify({
    "version": version,
    "swCode": swCode,
    "releaseType": releaseType
  });

  //console.log('getEngineerInfo : ' + reqUrl);
  response = await ApiInstance.post(reqUrl, data, config);
  // console.log(JSON.stringify(response.data, null, ' '));
  return response.data;
}

export async function searchEngineer(keyword) {
  var response = undefined;
  let reqUrl = '/backoffice/api/v1/monitoring/firmware/search-engineer';
  let config = {
    headers: {
      "authorizer": sessionStorage.getItem('idToken')
    }
  };
  let data = JSON.stringify({
    "keyword": keyword
  });

  //console.log('searchEngineer : ' + reqUrl);
  response = await ApiInstance.post(reqUrl, data, config);
  // console.log(JSON.stringify(response.data, null, ' '));
  return response.data;
}

export async function setGroupEngineer(version, swCode, releaseType, userList) {
  var response = undefined;
  let reqUrl = '/backoffice/api/v1/monitoring/firmware/set-group-engineer';
  let config = {
    headers: {
      "authorizer": sessionStorage.getItem('idToken')
    }
  };
  let data = JSON.stringify({
    "version": version,
    "swCode": swCode,
    "releaseType": releaseType,
    "userList": userList
  });

  //console.log('setGroupEngineer : ' + reqUrl);
  response = await ApiInstance.post(reqUrl, data, config);
  // console.log(JSON.stringify(response.data, null, ' '));
  return response.data;
}

export async function getServiceToolkitInfo(version, swCode) {
  var response = undefined;
  let reqUrl = '/backoffice/api/v1/monitoring/firmware/get-servicetoolkit-info';
  let config = {
    headers: {
      "authorizer": sessionStorage.getItem('idToken')
    }
  };

  let data = JSON.stringify({
    "version": version,
    "swCode": swCode
  });

  //console.log('getServiceToolkitInfo : ' + reqUrl);
  response = await ApiInstance.post(reqUrl, data, config);
  // console.log(JSON.stringify(response.data, null, ' '));
  return response.data;
}