import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField
} from '@material-ui/core';
import { setEcsLogLevel } from '../../APIs/mateApis';

const useStyles = makeStyles(theme => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  },
  title: {
    fontWeight: 700
  }
}));

const logLevelList = [
  {
    value: 'TRACE'
  },
  {
    value: 'DEBUG'
  },
  {
    value: 'INFO'
  },
  {
    value: 'WARN'
  },
  {
    value: 'ERROR'
  }
];

const LogLevelToolbar = props => {
  LogLevelToolbar.propTypes = {
    className: PropTypes.string,
    onSetLoading: PropTypes.func,
    syncLogLevelList: PropTypes.func
  };

  const { className, ...rest } = props;
  const [selectedValue, setSelectedValue] = useState(logLevelList[0].value);

  const classes = useStyles();

  const postLogLevel = async e => {
    try {
      props.onSetLoading(true);
      const setResult = await setEcsLogLevel(selectedValue);
      if (setResult && setResult.code == 200) {
        props.syncLogLevelList();
      }
    } catch (e) {
      console.log('setEcsLogLevel error: ' + e);
    }
    props.onSetLoading(false);
  };

  return (
    <Card className={clsx(classes.content, className)}>
      <CardHeader title={'컨버터'} subheader={'로그 레벨 설정 및 목록 확인'} />
      <Divider />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              label={'로그레벨'}
              margin="dense"
              name="model"
              onChange={e => setSelectedValue(e.target.value)}
              select
              // eslint-disable-next-line react/jsx-sort-props
              SelectProps={{ native: true }}
              value={selectedValue}
              variant="outlined">
              {logLevelList.map(level => (
                <option key={level.value} value={level.value}>
                  {level.value}
                </option>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>
        <Button
          color="primary"
          variant="contained"
          onClick={e => postLogLevel(e)}>
          {'전체 변경'}
        </Button>
      </CardActions>
    </Card>
  );
};

export default LogLevelToolbar;
