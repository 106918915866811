import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core/styles';
import palette from 'theme/palette';

import {
  Card,
  CardHeader,
  CardContent,
  Divider, CardActions, IconButton, TextField
} from '@material-ui/core';

import { options } from './chart';
import useAsync from '../../../../../asyncNet';
import {
  statisticsRegionalTotalAirData
} from '../../../APIs/aironeApis';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import regionCode from '../../../../../common/regionCode';
import ListView from '../../../Components/ListView/ListView';

const STRING_REGIONAL_TITLE_1='ODU 동작 유무에 따른 댁내 공기질 평균값 통계';

const useStyles = makeStyles(theme => ({
  root: {},
  chartContainer: {
    height: 400,
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const changeFormat = (regionInfo) =>{
  if(regionInfo.regionCode.length === 0){
    return {
      id: 0,
      value: ""
    }
  }
  return {
    id: regionInfo.regionCode[0].code[0],
    value: regionInfo.name,
    regionCodeFrom: regionInfo.regionCode[0].code[0],
    regionCodeTo: regionInfo.regionCode[0].code[1]
  }
};

const regionData = regionCode.map(changeFormat);

const RegionalTotalAirChart = props => {
  const { className, ...rest } = props;
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [date, setDate] = useState(new Date());

  const callRegionalTotalAirData = () => {
    return statisticsRegionalTotalAirData(
      regionData[selectedIndex].regionCodeFrom,
      regionData[selectedIndex].regionCodeTo,
      Math.ceil(date.getTime()/1000)
      );
  };

  const [state, tryRefetch] = useAsync(callRegionalTotalAirData, []);
  const [chartdata, setChartData] = useState({
    labels: [],
    datasets: []
  });

  const { data: metric } = state;
  const classes = useStyles();

  const backbtnClick = ()=>{
    var temp = new Date(date);
    temp.setDate(temp.getDate()-1);
    setDate(temp);
  };

  const forwardbtnClick = ()=>{
    var currentDay = new Date();
    var temp = new Date(date);
    var dateDiff =
      Math.floor((currentDay.getTime()-temp.getTime())/(1000*3600*24));
    if(dateDiff>0) {
      temp.setDate(temp.getDate() + 1);
      setDate(temp);
    }
  };

  const forceRefresh = () =>{
    setDate(new Date());
  };

  const dateChangedByUser  = event =>{
    var temp = new Date(event.target.value);
    var currentDay = new Date();
    var dateDiff =
      Math.floor((currentDay.getTime()-temp.getTime())/(1000*3600*24));
    if(dateDiff>=0) {
      setDate(temp);
    }
  };

  useEffect(() => {
    try {
      if (metric !== null && metric !== undefined) {
        setChartData({
            ...chartdata,
            labels: metric['time'],
            datasets: [
              {
                lineTension: 0,
                fill: false,
                backgroundColor: palette.info.main,
                data: metric['on'],
                label: 'ODU가 동작중인 가정의 평균 공기질'
              },
              {
                lineTension: 0,
                fill: false,
                backgroundColor: palette.success.main,
                data: metric['off'],
                label: 'ODU가 꺼진 가정의 평균 공기질'
              }
            ]
          }
        );
      }
    } catch (e) {
      console.log('metric update failed :' + JSON.stringify(e));
    }
  }, [metric]);

  useEffect(() => {
    tryRefetch();
  }, [date]);


  return (
    <div>
      <Card
        {...rest}
        className={clsx(classes.root, className)}>
        <CardHeader
          title={STRING_REGIONAL_TITLE_1+" ("+ regionData[selectedIndex].value + " )"}
        />
        <Divider/>
        <CardContent>
          {chartdata != undefined  &&
          <div className={classes.chartContainer}>
            <Line
              data={chartdata}
              options={options}
            />
          </div>
          }
        </CardContent>
        <CardActions className={classes.actions}>
          <form className={classes.container} noValidate>
            <TextField
              id="date"
              type="date"
              value={date.toISOString().substr(0, 10)}
              className={classes.textField}
              onChange={dateChangedByUser}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </form>
          <IconButton size="small" onClick={backbtnClick}>
            <ArrowLeftIcon color={'primary'}/>
          </IconButton>

          <IconButton size="small" onClick={forwardbtnClick}>
            <ArrowRightIcon color={'primary'}/>
          </IconButton>

          <ListView
            items={regionData}
            selectedIdx={selectedIndex}
            setSelectedIdx={setSelectedIndex}
            choiceCallback={forceRefresh}
          />

        </CardActions>
        <Divider/>
      </Card>
    </div>
  );
};

RegionalTotalAirChart.propTypes = {
  className: PropTypes.string
};

export default React.memo(RegionalTotalAirChart);
