import axios from 'axios';
import { deploy } from 'config';
import {
  STRING_USER_MEMBER,
  STRING_MANAGER,
  STRING_REGISTER_USER,
  STRING_MEMBER_LIST,
  STRING_MEMBER_INFO_SEARCH,
  STRING_MEMBER_INFO_CHANGE,
  STRING_MEMBER_PASSWORD_CHANGE,
  STRING_MEMBER_SIGNOUT,
} from '../StringTable/StringTable';

var ApiInstance = null;
//prod : https://nskr.naviensmartcontrol.com
//stage : https://nskr-stg.naviensmartcontrol.com
//dev : https://nskr-dev.naviensmartcontrol.com
if (deploy === "dev") {
  ApiInstance = axios.create({ baseURL: 'https://nskr-dev.naviensmartcontrol.com' });
} else if (deploy === "stage") {
  ApiInstance = axios.create({ baseURL: 'https://nskr-stg.naviensmartcontrol.com' });
} else if (deploy === "prod") {
  ApiInstance = axios.create({ baseURL: 'https://nskr.naviensmartcontrol.com' });
}

/**
 * @brief
 * getUsers is requested user's list
 * @param input, searchType, legalType, from, to, ipAddress
 * @return user list in JSON format
 */
export async function getUsers(input, searchType, legalType, from, to, ipAddress, isDownload, offset, limit) {
  let queryParams = {};
  queryParams['naviWatchId'] = sessionStorage.getItem('email');
  queryParams['download'] = isDownload;
  queryParams['ip'] = ipAddress;
  if (input !== "" && searchType == '아이디') {
    queryParams['id'] = input;
  } else if (input !== "" && searchType == '이름') {
    queryParams['name'] = input;
  } else if (input !== "" && searchType == '전화번호') {
    queryParams['phone'] = input;
  };

  if (legalType == '수집 동의') {
    queryParams['legalType'] = 'pi'
  } else if (legalType == '마케팅 동의') {
    queryParams['legalType'] = 'mt'
  } else if (legalType == '제공 동의') {
    queryParams['legalType'] = 'pi'
  };

  if (from !== "" && from !== undefined) {
    queryParams['startTime'] = Math.floor(new Date(from).getTime());
  }
  if (to !== "" && from !== undefined) {
    queryParams['endTime'] = Math.floor(new Date(to + " 23:59:59").getTime() + 9 * 60 * 60 * 1000);
  }
  if (offset !== "" && offset !== undefined) {
    queryParams['offset'] = offset;
  }
  if (limit !== "" && limit !== undefined) {
    queryParams['limit'] = limit;
  }

  var response = undefined;
  let reqUrl = '/api/backoffice/v1.0/privacy-users?';
  let config = {
    headers: {
      "authorizer": sessionStorage.getItem('idToken'),
      "Authorization": sessionStorage.getItem('accessToken')
    },
    params: queryParams

  };

  response = await ApiInstance.get(reqUrl, config);

  return response.data;
}

/**
 * @brief
 * userDetailInfo is requested user's list
 * @param userSeq
 * @return user's information in JSON format
 */
export async function userDetailInfo(userSeq) {
  var response = undefined;
  let reqUrl = '/api/backoffice/v1.0/privacy-users/' + userSeq;
  let config = {
    headers: {
      "authorizer": sessionStorage.getItem('idToken'),
      "Authorization": sessionStorage.getItem('accessToken')
    },
    params: {
      "naviWatchId": sessionStorage.getItem('email'),
    }
  };

  response = await ApiInstance.get(reqUrl, config);

  return response.data;
}

/**
 * @brief
 * getRecords is requested records's list
 * @param searchText All, User ID, Device ID, Name, Email and Phone number
 * @return user list in JSON format
 */
export async function getRecords(handlerType, featureType, from, to, isDownload, offset, limit) {
  let queryParams = {};
  queryParams['naviWatchId'] = sessionStorage.getItem('email');
  queryParams['download'] = isDownload;

  if (handlerType === STRING_USER_MEMBER) {
    queryParams['handlerType'] = 'USER';
  } else if (handlerType === STRING_MANAGER) {
    queryParams['handlerType'] = 'MANAGER';
  }

  if (featureType === STRING_REGISTER_USER) {
    queryParams['feature'] = 'REGISTER_USER';
  } else if (featureType === STRING_MEMBER_LIST) {
    queryParams['feature'] = 'SEARCH ';
  } else if (featureType === STRING_MEMBER_INFO_SEARCH) {
    queryParams['feature'] = 'DETAIL_SEARCH ';
  } else if (featureType === STRING_MEMBER_INFO_CHANGE) {
    queryParams['feature'] = 'UPDATE_USER';
  } else if (featureType === STRING_MEMBER_PASSWORD_CHANGE) {
    queryParams['feature'] = 'CHANGE_PASSWORD';
  } else if (featureType === STRING_MEMBER_SIGNOUT) {
    queryParams['feature'] = 'DEREGISTER_USER';
  }

  if (from !== "" && from !== undefined) {
    queryParams['startTime'] = Math.floor(new Date(from).getTime());
  }
  if (to !== "" && from !== undefined) {
    queryParams['endTime'] = Math.floor(new Date(to + " 23:59:59").getTime() + 9 * 60 * 60 * 1000);
  }
  if (offset !== "" && offset !== undefined) {
    queryParams['offset'] = offset;
  }
  if (limit !== "" && limit !== undefined) {
    queryParams['limit'] = limit;
  }

  var response = undefined;
  let reqUrl = 'api/backoffice/v1.0/records/get-records?';
  let config = {
    headers: {
      "authorizer": sessionStorage.getItem('idToken'),
      "Authorization": sessionStorage.getItem('accessToken')
    },
    params: queryParams

  };

  response = await ApiInstance.get(reqUrl, config);

  return response.data;
}