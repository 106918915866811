import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import palette from 'theme/palette';
import { options } from './chart';
import useAsync from '../../../../../asyncNet';
import { getUserRegionCount } from '../../../APIs/tokapis';
import {
  STRING_REGION_USER_DISTRIBUTION,
  STRING_SHOW_ALL_REGION,
  STRING_USER
} from '../../../StringTable/StringTable';

const useStyles = makeStyles(() => ({
  root: {
    height: "100%"
  },
  chartContainer: {
    height: '25vh',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const UserArea = props => {

  const { className, dialogCallback, ...rest } = props;
  const [state, tryRefetch] = useAsync(() => getUserRegionCount(), []);
  const { loading, data: counts, error } = state;
  const classes = useStyles();
  const [areaData, setAreaData] = useState({
    labels: [],
    datasets: []
  });

  useEffect(() => {
    try {
      const arrData = counts['userCount'];
      const arrArea = counts['region'];
      for (let i = 0; i <= arrData.length; i++) {
        for (let j = i + 1; j < arrData.length; j++) {
          if (arrData[j] > arrData[i]) {
            var temp = arrArea[i];
            arrArea[i] = arrArea[j];
            arrArea[j] = temp;
            var temp1 = arrData[i];
            arrData[i] = arrData[j];
            arrData[j] = temp1;
          }
        }
      }
      if (counts !== null && counts !== undefined && counts['region'] !== undefined) {
        console.log("arrArea : " + arrArea);
        setAreaData(areaData => ({
          ...areaData,
          labels: arrArea.slice(0, 7),
          datasets: [
            {
              label: { STRING_USER },
              backgroundColor: palette.primary.main,
              data: arrData.slice(0, 7)
            }
          ]
        }))
      }
    } catch (e) {
      console.log('areaCount update failed :' + JSON.stringify(e));
    }
  }, [counts])

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        title={STRING_REGION_USER_DISTRIBUTION}
      />
      <Divider />
      <CardContent>
        <div className={classes.chartContainer}>
          <Bar data={areaData} options={options} />
        </div>
        <Divider />
      </CardContent>

      <CardActions className={classes.actions}>
        <Button
          color="primary"
          size="small"
          variant="text"
          onClick={() => {
            dialogCallback(counts)
          }}
        >
          {STRING_SHOW_ALL_REGION} <ArrowRightIcon />
        </Button>
      </CardActions>

    </Card>
  );
};

UserArea.propTypes = {
  className: PropTypes.string
};

export default UserArea;
