import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import dateformat from 'dateformat';
import ExcelDownload from './ExcelDownload';
import { getRecords } from 'views/Smart/APIs/tokapis';

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  Typography,
} from '@material-ui/core';

import {
  STRING_ALERT_USER_INFO,
  STRING_SEARCH,
  STRING_EXCEL,
  STRING_CLEAN,
  STRING_ALL,
  STRING_DATE_FORMAT_LOWER_CASE,
  STRING_USER_DIVISION,
  STRING_USER_MEMBER,
  STRING_MANAGER,
  STRING_REGISTER_USER,
  STRING_MEMBER_LIST,
  STRING_MEMBER_INFO_SEARCH,
  STRING_MEMBER_INFO_CHANGE,
  STRING_MEMBER_PASSWORD_CHANGE,
  STRING_MEMBER_SIGNOUT,
  STRING_FEATURE,
} from '../../../StringTable/StringTable';



const useStyles = makeStyles((theme) => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  },
  title: {
    fontWeight: 700
  },
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  textField: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: 200,
  },
  containerItems: {
    display: 'flex',
    alignItems: 'ceneter',
  },
  box1: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(3),
  },
  box2: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(4.7),
  }
}));

const handlerTypes = [
  STRING_ALL,
  STRING_USER_MEMBER,
  STRING_MANAGER,
];

const featureTypes = [
  STRING_ALL,
  STRING_REGISTER_USER,
  STRING_MEMBER_LIST,
  STRING_MEMBER_INFO_SEARCH,
  STRING_MEMBER_INFO_CHANGE,
  STRING_MEMBER_PASSWORD_CHANGE,
  STRING_MEMBER_SIGNOUT,
];

const featureUserTypes = [
  STRING_ALL,
  STRING_REGISTER_USER,
  STRING_MEMBER_INFO_CHANGE,
  STRING_MEMBER_PASSWORD_CHANGE,
  STRING_MEMBER_SIGNOUT,
];

const RecordToolbar = props => {
  const { className, callbackFunc, records, ...rest } = props;

  const classes = useStyles();
  const [recording, setRecording] = useState([]);
  const [excelData, setExcelData] = useState([]);

  const [values, setValues] = useState({
    handlerType: STRING_ALL,
    featureType: STRING_ALL,
    from: dateformat(new Date().setMonth(new Date().getMonth() - 3), STRING_DATE_FORMAT_LOWER_CASE),
    to: dateformat(new Date(), STRING_DATE_FORMAT_LOWER_CASE),
    isDownload: false,
    offset: 0,
    limit: 10,
  });

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const cleanSearchInput = () => {
    setValues({
      handlerType: STRING_ALL,
      featureType: STRING_ALL,
      from: dateformat(new Date().setMonth(new Date().getMonth() - 3), STRING_DATE_FORMAT_LOWER_CASE),
      to: dateformat(new Date(), STRING_DATE_FORMAT_LOWER_CASE),
      offset: 0,
      limit: 10,
    });
  };

  const excelDownload = async () => {
    console.log("ExcelDownload");
    let userExcelList = await getRecords(values.handlerType, values.featureType, values.from, values.to, true);
    setExcelData(userExcelList.data.records);
    console.log("userExcelList : " + JSON.stringify(userExcelList));
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        title={'접속기록 점검'}
        subheader={<div>
          {/* <span>{STRING_TOTAL_USER_COUNT1 + (userCount === null ? 0 : userCount['totalCount']) + STRING_TOTAL_USER_COUNT2}<br /></span> */}
          <span style={{ color: 'red' }}>{STRING_ALERT_USER_INFO}</span></div>}
      />
      <Divider />
      <CardContent>

        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            md={12}
            xs={12}
            lg={12}
            xl={12}
            className={classes.containerItems}
          >
            <Typography
              align='center'
              variant="body1" className={classes.box1} noValidate
            >
              <b>{STRING_USER_DIVISION}</b>
            </Typography>

            <Grid
              item
            >
              <TextField
                label={STRING_USER_DIVISION}
                margin="dense"
                name="handlerType"
                onChange={handleChange}
                select
                SelectProps={{ native: true }}
                value={values.handlerType}
                variant="outlined"
                style={{ width: 168 }}
              >
                {
                  handlerTypes.map(handlerType => (
                    <option
                      key={handlerType}
                      value={handlerType}
                    >
                      {handlerType}
                    </option>
                  ))
                }
              </TextField>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>

      <CardContent>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            md={12}
            xs={12}
            lg={12}
            xl={12}
            className={classes.containerItems}
          >
            <Typography
              align='center'
              variant="body1" className={classes.box2} noValidate
            >
              <b>{STRING_FEATURE}</b>
            </Typography>

            <Grid
              item
            >
              <TextField
                label={STRING_FEATURE}
                margin="dense"
                name="featureType"
                onChange={handleChange}
                select
                SelectProps={{ native: true }}
                value={values.featureType}
                variant="outlined"
                disabled={values.handlerType === ''}

              >
                {
                  (values.handlerType === STRING_USER_MEMBER) &&
                  featureUserTypes.map(featureType => (
                    <option
                      key={featureType}
                      value={featureType}
                    >
                      {featureType}
                    </option>
                  ))
                }

                {
                  (values.handlerType === STRING_MANAGER || values.handlerType === STRING_ALL) &&
                  featureTypes.map(featureType => (
                    <option
                      key={featureType}
                      value={featureType}
                    >
                      {featureType}
                    </option>
                  ))
                }
              </TextField>
            </Grid>

            <TextField
              id="from"
              name="from"
              label="From"
              type="date"
              className={classes.textField}
              style={{ borderTopWidth: 5 }}
              value={values.from}
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Typography align="center"
              variant="body1" className={classes.container} noValidate>
              ~
            </Typography>
            <TextField
              id="to"
              name="to"
              label="To"
              type="date"
              className={classes.textField}
              value={values.to}
              onChange={handleChange}
              inputProps={{
                max: dateformat(new Date(values.from).setMonth(new Date(values.from).getMonth() + 3), STRING_DATE_FORMAT_LOWER_CASE)
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
      <Divider />

      <CardActions className={classes.actions}>
        <Button
          color="primary"
          variant="contained"
          onClick={cleanSearchInput}
        >
          {STRING_CLEAN}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => callbackFunc(values, false)}
        >
          {STRING_SEARCH}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => excelDownload()}
          disabled={records == false || records == null}
        >
          {STRING_EXCEL}
        </Button>
        {
          excelData !== undefined && excelData !== null && excelData.length > 0 &&
          <ExcelDownload excelDownloadData={excelData} />
        }

      </CardActions>
      <Typography
        align='center'
        variant="body1" className={classes.container} noValidate
      >
      </Typography>
    </Card>


  );
};

RecordToolbar.propTypes = {
  className: PropTypes.string
};

export default RecordToolbar;
