import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
} from '@material-ui/core';
import TimezoneCode from '../../../../../common/TimezoneCode';
import axios from 'axios';
import useAsync from '../../../../../asyncNet';
import { deviceTotalCount } from '../../../APIs/mateApis';

const useStyles = makeStyles(theme => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  },
  title: {
    fontWeight: 700
  }
}));

const STRING_SEARCH = '검색';
const STRING_SEARCH_CLEAN = '초기화';
const STRING_TITLE = '온수매트 검색';
const STRING_DEVICE_LABEL = '디바이스 ID';
const STRING_USER_LABEL = '소유자 ID';
const STRING_MODEL_LABEL = '모델';
const STRING_CONNECTION_LABEL = '서버 연결여부';
const STRING_MAT_COUNT = '총 온수매트는 ';
const STRING_MAT_COUNT2 = '개 입니다. 아래 검색을 통해 개별 온수매트를 조회할 수 있습니다.';
const STRING_MODEL_580 = 'EQM-580';
const STRING_MODEL_582 = 'EQM-582';
const STRING_MODEL_EME_501 = 'EME-501';
const STRING_CONNECTION_ON = '연결 상태';
const STRING_CONNECTION_OFF = '비연결 상태';
const STRING_REGION = '지역';

const DeviceToolbar = props => {
  const { className, callbackFunc, ...rest } = props;

  const classes = useStyles();
  const [state] = useAsync(deviceTotalCount, []);
  const { data: deviceCount } = state;

  const [values, setValues] = useState({
    deviceid: '',
    userid: '',
    model: '',
    connection: '',
    region:''
  });

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const states = [
    {
      value: ''
    },
    {
      value: STRING_CONNECTION_ON
    },
    {
      value: STRING_CONNECTION_OFF
    }
  ];

  const models = [
    {
      value: ''
    },
    {
      value: STRING_MODEL_580
    },
    {
      value: STRING_MODEL_582
    },
    {
      value: STRING_MODEL_EME_501
    }
  ];

  const cleanSearchInput = () =>{
    setValues({
      searchcond: '',
      userid: ''
    });
  };


  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >

      <CardHeader
        title={STRING_TITLE}
        subheader={STRING_MAT_COUNT + (deviceCount === null ? 0 : deviceCount.deviceCount) + STRING_MAT_COUNT2}
      />
      <Divider/>
      <CardContent>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            md={6}
            xs={12}
          >
            <TextField
              fullWidth
              label={STRING_MODEL_LABEL}
              margin="dense"
              name="model"
              onChange={handleChange}
              select
              // eslint-disable-next-line react/jsx-sort-props
              SelectProps={{ native: true }}
              value={values.model}
              variant="outlined"
            >
              {models.map(option => (
                <option
                  key={option.value}
                  value={option.value}
                >
                  {option.value}
                </option>
              ))}
            </TextField>
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
          >
            <TextField
              fullWidth
              label={STRING_CONNECTION_LABEL}
              margin="dense"
              name="connection"
              onChange={handleChange}
              select
              // eslint-disable-next-line react/jsx-sort-props
              SelectProps={{ native: true }}
              value={values.connection}
              variant="outlined"
            >
              {states.map(option => (
                <option
                  key={option.value}
                  value={option.value}
                >
                  {option.value}
                </option>
              ))}
            </TextField>
          </Grid>
          <Grid
            item
            md={4}
            xs={12}
          >
            <TextField
              fullWidth
              label={STRING_REGION}
              margin="dense"
              name="region"
              onChange={handleChange}
              select
              // eslint-disable-next-line react/jsx-sort-props
              SelectProps={{ native: true }}
              value={values.region}
              variant="outlined"
            >
              {TimezoneCode.map(option => (
                <option
                  key={option.name}
                  value={option.name}
                >
                  {option.name}
                </option>
              ))}
            </TextField>
          </Grid>
          <Grid
            item
            md={4}
            xs={12}
          >
            <TextField
              fullWidth
              label={STRING_DEVICE_LABEL}
              margin="dense"
              name="deviceid"
              onChange={handleChange}
              value={values.deviceid}
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            md={4}
            xs={12}
          >
            <TextField
              fullWidth
              label={STRING_USER_LABEL}
              margin="dense"
              name="userid"
              onChange={handleChange}
              value={values.userid}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </CardContent>
      <Divider/>
      <CardActions className={classes.actions}>
        <Button
          color="primary"
          variant="contained"
          onClick={cleanSearchInput}
          disabled={values.userid === '' && values.deviceid === '' && values.connection === '' && values.model === '' && values.region ===''}
        >
          {STRING_SEARCH_CLEAN}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => callbackFunc(values)}
          disabled={values.userid === '' && values.deviceid === '' && values.connection === '' && values.model === '' && values.region === ''}
        >
          {STRING_SEARCH}
        </Button>
      </CardActions>
    </Card>
  );
};

DeviceToolbar.propTypes = {
  className: PropTypes.string
};

export default DeviceToolbar;
