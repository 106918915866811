import React, { useState, useEffect, useMemo } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { CSVLink } from 'react-csv';
import { Button, makeStyles, TextField } from '@material-ui/core';
import moment from 'moment';
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}));

const DeviceLogTable = props => {
  const { className, formatTableData } = props;

  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [selectedDevices] = useState([]);
  const [columns, setColumns] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [csvHeader, setCsvHeader] = useState([]);

  const [movePageNum, setMovePageNum] = useState(0);

  const deviceLogData = useMemo(() => {
    if (!formatTableData || formatTableData.length == 0) return [];
    console.log(formatTableData);
    return formatTableData.reduce((data, current) => {
      let columns = Object.keys(current);
      let objectData = {};
      columns.forEach(column => {
        typeof current[column] == 'boolean'
          ? (objectData[column] = current[column].toString())
          : (objectData[column] = current[column]);
      });
      data.push(objectData);
      return data;
    }, []);
  }, [formatTableData, formatTableData.length]);

  useEffect(() => {
    console.log(deviceLogData);
    if (deviceLogData.length > 0) {
      setPage(0);
      setMovePageNum(1);
      const columnsArr = [];
      for (const objKey in deviceLogData[0]) {
        columnsArr.push(objKey);
      }
      setColumns(columnsArr);
      console.log(columnsArr);
    }
  }, [deviceLogData, deviceLogData.length]);

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  const downLoadCSV = () => {
    const csv_array = [];
    const csv_header = [];
    try {
      columns.forEach(columnInfoItem => {
        csv_header.push({
          label: columnInfoItem,
          key: columnInfoItem
        });
      });

      deviceLogData.forEach(data => {
        let csvContent = {};
        data.forEach((innerData, index) => {
          csvContent[csv_header[index].key] = innerData;
        });
        csv_array.push(csvContent);
      });

      setCsvHeader(csv_header);
      setCsvData(csv_array);
    } catch (e) {
      alert('Fail csv parsing\n' + e);
    }
  };

  return (
    columns.length > 0 && (
      <div style={{ marginTop: 20 }}>
        <Card className={clsx(classes.root, className)}>
          <CardContent className={classes.content}>
            <PerfectScrollbar>
              <div className={classes.inner}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {columns.map((colItem, colIndex) => {
                        return (
                          <TableCell key={colIndex} align={'center'}>
                            <span>
                              <b>{colItem}</b>
                            </span>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {deviceLogData.length > 0 ? (
                      deviceLogData
                        .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                        .map((rowsItem, rowsIndex) => {
                          return (
                            <TableRow
                              className={classes.tableRow}
                              hover
                              key={rowsIndex}
                              selected={
                                selectedDevices.indexOf(rowsIndex) !== -1
                              }>
                              {Object.keys(rowsItem).map(column => {
                                return (
                                  <TableCell key={column} align={'center'}>
                                    {rowsItem[column]}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })
                    ) : (
                      <div />
                    )}
                  </TableBody>
                </Table>
              </div>
            </PerfectScrollbar>
          </CardContent>
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}>
            <Button
              style={{
                marginLeft: 10
              }}
              color="primary"
              variant="contained">
              <CSVLink
                headers={csvHeader}
                data={csvData}
                onClick={() => downLoadCSV()}
                filename={'chartData.csv'}
                target="_blank">
                <p style={{ color: 'white' }}>Export CSV</p>
              </CSVLink>
            </Button>
            <CardActions className={classes.actions}>
              <TextField
                margin="dense"
                name="modePage"
                onChange={e => {
                  setMovePageNum(e.target.value);
                }}
                value={movePageNum}
                variant="outlined"
              />
              <Button
                style={{
                  marginLeft: 10
                }}
                onClick={() => {
                  const pageNum = Number(movePageNum) - 1;
                  if (
                    pageNum >= 0 &&
                    pageNum * rowsPerPage < deviceLogData.length
                  ) {
                    setPage(pageNum);
                  }
                }}
                color="primary"
                variant="contained">
                Move page
              </Button>
              {deviceLogData.length > 0 && (
                <TablePagination
                  component="div"
                  count={deviceLogData.length}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleRowsPerPageChange}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={[5, 10, 25]}
                />
              )}
            </CardActions>
          </div>
        </Card>
      </div>
    )
  );
};

DeviceLogTable.propTypes = {
  className: PropTypes.string,
  resultSet: PropTypes.object
};

export default DeviceLogTable;
