import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/core/styles';
import { userDetailInfo } from '../../../APIs/tokapis';
import moment from 'moment';

import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
} from '@material-ui/core';

import useAsync from '../../../../../asyncNet';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import ZoomInTwoToneIcon from '@material-ui/icons/ZoomInTwoTone';
import DetailDialog from './DetailDialog';
import {
  STRING_USER_ID,
  STRING_USER_NAME,
  STRING_USER_PHONE,
  STRING_SHOW_DETAIL,
  STRING_PRIVACY_COLLECTION,
  STRING_PRIVACY_MARKETING,
  STRING_PRIVACY_OFFER,
  STRING_DATE_FORMAT
} from '../../../StringTable/StringTable';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  deviceView: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  deviceDescriptView: {
    display: 'flex',
    flexDirection: 'column'
  },
  deviceDescriptDivider: {
    marginTop: theme.spacing(1),
    marginBottom:
      theme.spacing(1)
  }
}));


const UsersTable = props => {
  const { className, users, ip, sendPageInfo, initRowsPerPage, initPage, ...rest } = props;

  const classes = useStyles();

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [userSeq, setUserChoice] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(initRowsPerPage);
  const [page, setPage] = useState(initPage);

  const [open, setOpen] = React.useState(false);
  const [pushOpen, setPushOpen] = React.useState(false);
  const [state, tryRefetch] = useAsync(() => userDetailInfo(userSeq), [], true);
  const { loading, data: userInfo, error } = state;

  useEffect(() => {
    if (userSeq !== '') {
      tryRefetch();
    }

  }, [userSeq]);

  const handleClickOpen = (user) => {
    setUserChoice(user);
    setOpen(true);
  };

  const handleClose = () => {
    setUserChoice('');
    setOpen(false);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
    sendPageInfo(rowsPerPage * page, rowsPerPage);
  };

  const handleRowsPerPageChange = event => {
    setPage(0);
    setRowsPerPage(event.target.value);
    sendPageInfo(0, event.target.value);
  };

  return (
    <div>
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardContent className={classes.content}>
          {users.data.totalCount > 0 &&
            <b>{" 검색 전체 : " + users.data.totalCount + "명"}</b>
          }
          <PerfectScrollbar>
            <div>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align={'center'}><span><b>NO.</b></span></TableCell>
                    <TableCell align={'center'}><span><b>{STRING_USER_ID}</b></span></TableCell>
                    <TableCell align={'center'}><span><b>{STRING_USER_NAME}</b></span></TableCell>
                    <TableCell align={'center'}><span><b>{STRING_USER_PHONE}</b></span></TableCell>
                    <TableCell align={'center'}><span><b>{STRING_PRIVACY_COLLECTION}</b></span></TableCell>
                    <TableCell align={'center'}><span><b>{STRING_PRIVACY_MARKETING}</b></span></TableCell>
                    <TableCell align={'center'}><span><b>{STRING_PRIVACY_OFFER}</b></span></TableCell>
                    <TableCell align={'center'}><span><b>{STRING_SHOW_DETAIL}</b></span></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users !== null && users !== undefined && users.data.users.map((user, index) => (
                    <TableRow
                      className={classes.tableRow}
                      hover
                      key={index}
                      selected={selectedUsers.indexOf(user.userSeq) !== -1}
                    >
                      <TableCell align={'center'}>
                        {user.userSeq ? ++index + page * rowsPerPage : '-'}
                      </TableCell>
                      <TableCell align={'center'}>
                        {user.id ? user.id : '-'}
                      </TableCell>
                      <TableCell align={'center'}>
                        {user.name ? user.name : '-'}
                      </TableCell>
                      <TableCell align={'center'}>
                        {user.phone ? user.phone : '-'}
                      </TableCell>
                      <TableCell align={'center'}>
                        {user.collectAgreeAt ? moment(user.collectAgreeAt * 1000).format(STRING_DATE_FORMAT) : '-'}
                      </TableCell>
                      <TableCell align={'center'}>
                        {user.marketingAgreeAt ? moment(user.marketingAgreeAt * 1000).format(STRING_DATE_FORMAT) : '-'}
                      </TableCell>
                      <TableCell align={'center'}>
                        {user.serveAgreeAt ? moment(user.serveAgreeAt * 1000).format(STRING_DATE_FORMAT) : '-'}
                      </TableCell>
                      <TableCell align={'center'}>
                        <Tooltip title={STRING_SHOW_DETAIL}>
                          <IconButton aria-label={STRING_SHOW_DETAIL}
                            onClick={() => handleClickOpen(user.userSeq)}>
                            <ZoomInTwoToneIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={users !== undefined ? users.data.totalCount : 0}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[10, 50, 100]}
          />
        </CardActions>
      </Card>
      <DetailDialog open={open} handleClose={handleClose} userInfo={userInfo} rendering={tryRefetch} />
    </div>
  );
};

UsersTable.propTypes = {
  className: PropTypes.string,
  // users: PropTypes.array.isRequired
};

export default UsersTable;

