import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Alert from '@material-ui/lab/Alert';
import Divider from '@material-ui/core/Divider';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import "react-datepicker/dist/react-datepicker.css";
import {
  STRING_USER_ID,
  STRING_USER_EMAIL,
  STRING_USER_NAME,
  STRING_USER_PHONE,
  STRING_USER_REGISTER_AT,
  STRING_USER_LAST_LOGIN_AT,
  STRING_WARNING_USER_PRIVATE_INFO,
  STRING_DATE_FORMAT,
} from '../../../StringTable/StringTable';

const useStyles = makeStyles(theme => ({

  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  deviceView: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  deviceDescriptView: {
    display: 'flex',
    flexDirection: 'column'
  },
  deviceDescriptDivider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  container: {
    display: "flex",
    justifyContent: "spaceAround",
  },
  containerItems: {
    alignItems: "center",
    width: "100%",
    height: "100%"
  }

}));


const DetailDialog = props => {
  const { className, open, handleClose, userInfo, rendering, ...rest } = props;
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [logOpen, setLogOpen] = React.useState(false);



  const logClickOpen = () => {
    setLogOpen(true);
  };


  const logClickClose = () => {
    setLogOpen(false);
  };

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title"><b>사용자 정보 조회</b></DialogTitle>
        <DialogContent>
          <DialogContentText component={'div'}>
            <Alert severity={'error'}><span style={{ color: 'red' }}>{STRING_WARNING_USER_PRIVATE_INFO}</span></Alert>
            <Divider className={classes.divider} />
            <span><b>{STRING_USER_ID}</b> : {userInfo !== null && userInfo.data.id}</span>
            <Divider className={classes.divider} />
            <span><b>{STRING_USER_NAME}</b> : {userInfo !== null && userInfo.data.name}</span>
            <Divider className={classes.divider} />
            <span><b>{STRING_USER_EMAIL}</b> : {userInfo !== null && userInfo.data.email}</span>
            <Divider className={classes.divider} />
            <span><b>{STRING_USER_PHONE}</b> : {userInfo !== null && userInfo.data.phone}</span>
            <Divider className={classes.divider} />
            <span><b>{STRING_USER_REGISTER_AT}</b> : {userInfo !== null && userInfo.data.registerAt ? moment(userInfo.data.registerAt * 1000).format(STRING_DATE_FORMAT) : "-"}</span>
            <Divider className={classes.divider} />
            <span><b>{STRING_USER_LAST_LOGIN_AT}</b> : {userInfo !== null && userInfo.data.lastLoginAt ? moment(userInfo.data.lastLoginAt * 1000).format(STRING_DATE_FORMAT) : "-"}</span>

          </DialogContentText>
          <form className={classes.form} noValidate>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
     
    </div>
  );
};

DetailDialog.propTypes = {
  className: PropTypes.string
};

export default DetailDialog;
