/* smarttok */
const ROLE_SMARTTOK_ADMIN = 1;
const ROLE_SMARTTOK_ENGINEER_MANAGER = 52;
const ROLE_SMARTTOK_FW_MANAGER = 45;
const ROLE_SMARTTOK_SECURITY = 23;
const ROLE_SMARTTOK_SUPER_ADMIN = 3;
const ROLE_SMARTTOK_VIEWER = 2;
/* smarttok-cn */
const ROLE_SMARTTOK_CN_ADMIN = 41;
const ROLE_SMARTTOK_CN_ENGINEER_MANAGER = 53;
const ROLE_SMARTTOK_CN_FW_MANAGER = 46;
const ROLE_SMARTTOK_CN_SECURITY = 44;
const ROLE_SMARTTOK_CN_SUPER_ADMIN = 43;
const ROLE_SMARTTOK_CN_VIEWER = 42;
/* mate */
const ROLE_MATE_ADMIN = 4;
const ROLE_MATE_ANALYST = 16;
const ROLE_MATE_FW_MANAGER = 13;
const ROLE_MATE_SECURITY = 20;
const ROLE_MATE_SUPER_ADMIN = 6;
const ROLE_MATE_VIEWER = 5;
/* airone */
const ROLE_AIRONE_ADMIN = 7;
const ROLE_AIRONE_ANALYST = 15;
const ROLE_AIRONE_FW_MANAGER = 14;
const ROLE_AIRONE_SECURITY = 21;
const ROLE_AIRONE_SUPER_ADMIN = 9;
const ROLE_AIRONE_VIEWER = 8;
/* navilink */
const ROLE_NAVILINK_ADMIN = 10;
const ROLE_NAVILINK_ENGINEER_MANAGER = 55;
const ROLE_NAVILINK_FW_MANAGER = 48;
const ROLE_NAVILINK_SECURITY = 24;
const ROLE_NAVILINK_SUPER_ADMIN = 12;
const ROLE_NAVILINK_VIEWER = 11;
/* hydrofurnace */
const ROLE_HYDROFURNACE_ADMIN = 31;
const ROLE_HYDROFURNACE_ENGINEER_MANAGER = 56;
const ROLE_HYDROFURNACE_FW_MANAGER = 49;
const ROLE_HYDROFURNACE_SECURITY = 34;
const ROLE_HYDROFURNACE_SUPER_ADMIN = 33;
const ROLE_HYDROFURNACE_VIEWER = 32;
/* smartplus */
const ROLE_SMARTPLUS_ADMIN = 17;
const ROLE_SMARTPLUS_ENGINEER_MANAGER = 54;
const ROLE_SMARTPLUS_FW_MANAGER = 47;
const ROLE_SMARTPLUS_SECURITY = 22;
const ROLE_SMARTPLUS_SUPER_ADMIN = 19;
const ROLE_SMARTPLUS_VIEWER = 18;
/* smart */
const ROLE_SMART_ADMIN = 25;
const ROLE_SMART_ANALYST = 28;
const ROLE_SMART_FW_MANAGER = 30;
const ROLE_SMART_SECURITY = 29;
const ROLE_SMART_SUPER_ADMIN = 27;
const ROLE_SMART_VIEWER = 26;
/* nscada */
const ROLE_NSCADA_ADMIN = 35;
const ROLE_NSCADA_ENGINEER_MANAGER = 57;
const ROLE_NSCADA_FW_MANAGER = 50;
const ROLE_NSCADA_SUPER_ADMIN = 37;
const ROLE_NSCADA_VIEWER = 36;
/* nscada-kr */
const ROLE_NSCADA_KR_ADMIN = 38;
const ROLE_NSCADA_KR_ENGINEER_MANAGER = 58;
const ROLE_NSCADA_KR_FW_MANAGER = 51;
const ROLE_NSCADA_KR_SUPER_ADMIN = 40;
const ROLE_NSCADA_KR_VIEWER = 39;

export {
    /*smarttok*/
    ROLE_SMARTTOK_ADMIN,
    ROLE_SMARTTOK_ENGINEER_MANAGER,
    ROLE_SMARTTOK_FW_MANAGER,
    ROLE_SMARTTOK_SECURITY,
    ROLE_SMARTTOK_SUPER_ADMIN,
    ROLE_SMARTTOK_VIEWER,
    /*smarttok-cn*/
    ROLE_SMARTTOK_CN_ADMIN,
    ROLE_SMARTTOK_CN_ENGINEER_MANAGER,
    ROLE_SMARTTOK_CN_FW_MANAGER,
    ROLE_SMARTTOK_CN_SECURITY,
    ROLE_SMARTTOK_CN_SUPER_ADMIN,
    ROLE_SMARTTOK_CN_VIEWER,
    /*mate*/
    ROLE_MATE_ADMIN,
    ROLE_MATE_ANALYST,
    ROLE_MATE_FW_MANAGER,
    ROLE_MATE_SECURITY,
    ROLE_MATE_SUPER_ADMIN,
    ROLE_MATE_VIEWER,
    /*airone*/
    ROLE_AIRONE_ADMIN,
    ROLE_AIRONE_ANALYST,
    ROLE_AIRONE_FW_MANAGER,
    ROLE_AIRONE_SECURITY,
    ROLE_AIRONE_SUPER_ADMIN,
    ROLE_AIRONE_VIEWER,
    /*navilink*/
    ROLE_NAVILINK_ADMIN,
    ROLE_NAVILINK_ENGINEER_MANAGER,
    ROLE_NAVILINK_FW_MANAGER,
    ROLE_NAVILINK_SECURITY,
    ROLE_NAVILINK_SUPER_ADMIN,
    ROLE_NAVILINK_VIEWER,
    /*hydrofurnace*/
    ROLE_HYDROFURNACE_ADMIN,
    ROLE_HYDROFURNACE_ENGINEER_MANAGER,
    ROLE_HYDROFURNACE_FW_MANAGER,
    ROLE_HYDROFURNACE_SECURITY,
    ROLE_HYDROFURNACE_SUPER_ADMIN,
    ROLE_HYDROFURNACE_VIEWER,
    /*smartplus*/
    ROLE_SMARTPLUS_ADMIN,
    ROLE_SMARTPLUS_ENGINEER_MANAGER,
    ROLE_SMARTPLUS_FW_MANAGER,
    ROLE_SMARTPLUS_SECURITY,
    ROLE_SMARTPLUS_SUPER_ADMIN,
    ROLE_SMARTPLUS_VIEWER,
    /*smart*/
    ROLE_SMART_ADMIN,
    ROLE_SMART_ANALYST,
    ROLE_SMART_FW_MANAGER,
    ROLE_SMART_SECURITY,
    ROLE_SMART_SUPER_ADMIN,
    ROLE_SMART_VIEWER,
    /*nscada*/
    ROLE_NSCADA_ADMIN,
    ROLE_NSCADA_ENGINEER_MANAGER,
    ROLE_NSCADA_FW_MANAGER,
    ROLE_NSCADA_SUPER_ADMIN,
    ROLE_NSCADA_VIEWER,
    /*nscada-kr*/
    ROLE_NSCADA_KR_ADMIN,
    ROLE_NSCADA_KR_ENGINEER_MANAGER,
    ROLE_NSCADA_KR_FW_MANAGER,
    ROLE_NSCADA_KR_SUPER_ADMIN,
    ROLE_NSCADA_KR_VIEWER
};