import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { HorizontalBar } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core/styles';
import palette from 'theme/palette';

import {
  Card,
  CardHeader,
  CardContent,
  Divider,
} from '@material-ui/core';

//import { option } from './chart';
import useAsync from '../../../../../asyncNet';
import {
  statisticsAMBrightnessCount
} from '../../../APIs/aironeApis';

const STRING_AIRMONITOR_BRIGHTNESS='에어모니터 밝기 분포';
const STRING_LEVEL_0 = '어두움';
const STRING_LEVEL_1 = '보통';
const STRING_LEVEL_2 = '밝음';
const STRING_LEVEL_3 = '매우밝음';

const STRING_COUNT = 'count';

const useStyles = makeStyles(() => ({
  height: '100%',
  card: {
    backgroundColor: '#fff9db'
  },
  chartContainer: {
    height: '100%',
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const options = {
  legend: {
    display: false
  }
};

const BrightnessChart = props => {

  const { className, ...rest } = props;
  const [state, tryRefetch] = useAsync(statisticsAMBrightnessCount, [], false);
  const [chartdata, setChartData] = useState({
    labels: [],
    datasets: []
  });
  const [chartLabel, setChartLabel] = useState([]);
  const [chartValue, setChartValue] = useState([]);
  const [chartColor, setChartColor] = useState([]);

  const { data: metric } = state;


  const classes = useStyles();
  const makeChartValue = (numeric, count) => {
    let tempLevel = [];
    let tempValue = [];
    let tempColor = [];
    for (var i = 0; i < numeric.length; i++) {
      switch (parseInt(numeric[i])) {
        case 0:
          tempLevel.push(STRING_LEVEL_0);
          tempValue.push(count[i]);
          tempColor.push('#FFFACD');
          break;
        case 1:
          tempLevel.push(STRING_LEVEL_1);
          tempValue.push(count[i]);
          tempColor.push('#FFFFCD');
          break;
        case 2:
          tempLevel.push(STRING_LEVEL_2);
          tempValue.push(count[i]);
          tempColor.push('#FF730F');
          break;
        case 3:
          tempLevel.push(STRING_LEVEL_3);
          tempValue.push(count[i]);
          tempColor.push('#FFDACD');
          break;
        default:
          console.log("Expected Value is 0, 1, 2, 3 but value is "+numeric[i]);
      }
    }
    setChartLabel(tempLevel);
    setChartValue(tempValue);
    setChartColor(tempColor);
  };

  useEffect(() => {
    try {
      setChartData({
          ...chartdata,
          labels: chartLabel,
          datasets: [
            {
              label: STRING_COUNT,
              borderWidth: 1,
              backgroundColor: chartColor,
              data: chartValue,
            }
          ]
        }
      );
    } catch (e) {
      console.log('metric update failed :' + JSON.stringify(e));
    }
  }, [chartLabel, chartValue]);

  useEffect(()=>{
    if (metric !== null && metric !== undefined) {
      console.log('update brightness: '+JSON.stringify(metric));
      makeChartValue(metric['level'], metric['count']);
    }
  }, [metric]);


  return (
    <div>
      <Card
        {...rest}
        className={clsx(classes.root, className)}>
        <CardHeader
          title={STRING_AIRMONITOR_BRIGHTNESS}
        />
        <Divider/>
        <CardContent>
          {chartdata !== undefined  &&
          <div className={classes.chartContainer}>
            <HorizontalBar
              data={chartdata}
              options={options}
            />
          </div>
          }
        </CardContent>
        <Divider/>
      </Card>
    </div>
  );
};

BrightnessChart.propTypes = {
  className: PropTypes.string
};

export default React.memo(BrightnessChart);
