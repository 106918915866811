import { makeStyles } from '@material-ui/core/styles';
import React, { useContext, useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import ReservationCharts from './ReservationChart/ReservationCharts';
import TemperatureChart from './TemperatureChart/TemperatureChart';
import { userInfoContext } from '../../../../App';
import { NO_PERMISSION, NO_PERMISSION_WARNING, READ_PERMISSION } from '../../../Common/Config/naviwatchConfig';



const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  }
}));


const Statistics = ({ history }) => {
  const classes = useStyles();
  const authInfo =  useContext(userInfoContext);
  const [noPermissionMsg, setNoPermissionMsg] = useState("");
  const [permission, setPermission] = useState(NO_PERMISSION);
  useEffect(()=>{
    var pageInfo = sessionStorage.getItem('pages');
    if(pageInfo!=="undefined") {
      var pageInfoObj = JSON.parse(pageInfo);
      var pageArray = pageInfoObj.filter(page => page.name.toLowerCase().includes("analytics"));
      if (pageArray !== undefined && pageArray.length > 0) {
        var page = pageArray[0];
        if(page.permission===undefined){
          setNoPermissionMsg(NO_PERMISSION_WARNING);
        }
        setPermission(page.permission);
      }
    }
  }, [authInfo.pages]);
  useEffect(() => {
    if (sessionStorage.getItem('naviwatch_permission') !== 'true')
      history.push('/');
  });

  return (
    <div>
      { permission >= READ_PERMISSION ?
        <div className={classes.root}>
          <Grid
            container
            spacing={4}
          >
            <Grid
            item
            lg={4}
            md={6}
            xl={4}
            xs={12}
          >
            <ReservationCharts metricName="Reservation-On"/>
            </Grid>
            <Grid
              item
              lg={4}
              md={6}
              xl={4}
              xs={12}
            >
              <ReservationCharts metricName="Reservation-Off"/>
            </Grid>
            <Grid
              item
              lg={4}
              md={6}
              xl={4}
              xs={12}
            >
              <ReservationCharts metricName="Sleep" />
            </Grid>
            <Grid
              item
              lg={12}
              md={6}
              xl={12}
              xs={12}
            >
              <TemperatureChart/>
            </Grid>
          </Grid>
        </div>:
        <Typography className={classes.root}>{noPermissionMsg}</Typography>
      }
    </div>
  );
};

export default Statistics;
