import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField
} from '@material-ui/core';
import regionCode from '../../../../../common/regionCode';
import useAsync from '../../../../../asyncNet';

const useStyles = makeStyles(theme => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  },
  title: {
    fontWeight: 700
  }
}));

const STRING_SEARCH = '검색';
const STRING_TITLE = '에어룸콘 검색';
const STRING_DEVICE_LABEL = 'RoomController 아이디';

const DeviceHistoryToolbar = props => {
  const { className, callbackFunc, ...rest } = props;

  const classes = useStyles();
  const [roomControllerId, setRoomControllerId] = useState('');
  const [fromDate, setFromDate] = useState('');

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader title={STRING_TITLE} />
      <Divider />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item md={3} xs={12}>
            <TextField
              fullWidth
              label={STRING_DEVICE_LABEL}
              margin="dense"
              name="deviceid"
              onChange={e => setRoomControllerId(e.target.value)}
              value={'' || roomControllerId}
              variant="outlined"
            />
          </Grid>
          <Grid item md={2} xs={4}>
            <form className={classes.container} noValidate>
              <TextField
                id="from"
                name="from"
                label="From"
                type="datetime-local"
                className={classes.textField}
                value={'' || fromDate}
                onChange={e => setFromDate(e.target.value)}
                // disabled={values.condition === ''}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </form>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>
        <Button
          color="primary"
          variant="contained"
          onClick={() => callbackFunc(roomControllerId, fromDate)}
          disabled={roomControllerId === '' || !fromDate}>
          {STRING_SEARCH}
        </Button>
      </CardActions>
    </Card>
  );
};

DeviceHistoryToolbar.propTypes = {
  className: PropTypes.string
};

export default DeviceHistoryToolbar;
