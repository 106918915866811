import axios from 'axios';

import {deploy} from 'config';
var ApiInstance = null;
var ECSClusterName = "";
var ECSServiceName = "";
var DBClusterName = "";
var LoadBalancerName = "";
var OTA_DEVICE_FW_PATH = "/mate/firmwares/";
var OTA_DEVICE_CERT_PATH = "/mate/certificates/";
var OTA_CERTIFICATE_ARN="";
var OTA_S3_BUCKET="";
var OTA_S3_SIGNING_PREFIX="SignedImages/";

if(deploy === "dev"){
  ApiInstance = axios.create({ baseURL : 'https://usnm-dev-nw-api.naviensmartcontrol.com' });//cdk
  ECSClusterName = "mateus-dev-ecs-cluster-converter";
  ECSServiceName = "mateus-dev-ecs-service-converter";
  DBClusterName = "mateus-dev-stack-rds-dbcluster624cfb91-fyy1y18z737g";
  LoadBalancerName = "net/mateus-dev-ec2-nlb-converter/458ddcdf55a101fe";
  OTA_CERTIFICATE_ARN = "arn:aws:acm:us-east-1:195830161280:certificate/d92796f4-bb51-4c46-8fe5-b11412623d91";
  OTA_S3_BUCKET = "mateus-dev-s3-bucket-firmware";
  // DBClusterName = "mateus-dev-stack-rds-dbcluster624cfb91-1iy02z7ydry8m";
  // LoadBalancerName = "net/mateus-dev-ec2-nlb-converter/82561e982c15f451";
  // OTA_S3_BUCKET = "naviwatch-dev-s3-bucket-mateus-firmware";
  //OTA_CERTIFICATE_ARN = "arn:aws:acm:ap-northeast-2:195830161280:certificate/db88e944-5279-4c26-aaf4-7e9cf6634123";
  //OTA_S3_BUCKET = "mate-dev-s3-bucket-firmware";
}else if(deploy === "stage"){
  /* Not Yet Prepare */
  ApiInstance = axios.create({ baseURL : 'https://usnm-stg-nw-api.naviensmartcontrol.com' }); // cdk
  ECSClusterName = "mateus-stage-ecs-cluster-converter";
  ECSServiceName = "mateus-stage-ecs-service-converter";
  DBClusterName = "mateus-stage-stack-rds-dbcluster624cfb91-189f6cdgl5gj7";
  LoadBalancerName = "net/mateus-stage-ec2-nlb-converter/a5b703e39d56958a";
  OTA_CERTIFICATE_ARN = "arn:aws:acm:ap-northeast-2:195830161280:certificate/db88e944-5279-4c26-aaf4-7e9cf6634123";
  OTA_S3_BUCKET = "mateus-stage-s3-bucket-firmware";
}else if(deploy === "prod"){
  /* Not Yet Prepare */
  ApiInstance = axios.create({ baseURL : 'https://usnm-nw-api.naviensmartcontrol.com' });
  ECSClusterName = "mateus-prod-ecs-cluster-converter";
  ECSServiceName = "mateus-prod-ecs-service-converter";
  DBClusterName = "mateus-prod-stack-rds-dbcluster624cfb91-og9nzjlcw0hd";
  LoadBalancerName = "net/mateus-prod-ec2-nlb-converter/a63398f24ee62ff3";
  //OTA_CERTIFICATE_ARN = "arn:aws:acm:ap-northeast-2:195830161280:certificate/db88e944-5279-4c26-aaf4-7e9cf6634123";
  //OTA_S3_BUCKET = "mate-dev-s3-bucket-firmware";
}

export async function dashboardGetDeviceArea() {
  var response = undefined;
  let reqUrl = '/naviwatch/api/v1/monitoring/device/get-region-count';
  let config = {headers:{
    "authorizer":  sessionStorage.getItem('idToken')
  }};
  response = await ApiInstance.get(reqUrl, config);
  console.log(JSON.stringify(response.data));
  return response.data;
}
export async function dashboardGetModelCount(query) {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/monitoring/device/get-model-count?query=${query}`;
  let config = {headers:{
      "authorizer":  sessionStorage.getItem('idToken')
    }};
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function dashboardGetMobileCount() {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/monitoring/mobile/get-mobile-count`;
  let config = {headers:{
      "authorizer":  sessionStorage.getItem('idToken')
    }};
  response = await ApiInstance.get(reqUrl, config);
  console.log(JSON.stringify(response.data));
  return response.data;
}

export async function dashboardGetMobileActiveCount() {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/monitoring/mobile/get-active-count`;
  let config = {headers:{
      "authorizer":  sessionStorage.getItem('idToken')
    }};
  response = await ApiInstance.get(reqUrl, config);
  console.log("mobile: "+JSON.stringify(response.data));
  return response.data;
}
export async function dashboardGetUserTotalCount() {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/monitoring/user/get-active-count`;
  let config = {headers:{
      "authorizer":  sessionStorage.getItem('idToken')
    }};
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}
export async function dashboardGetDeviceActiveCount() {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/monitoring/device/get-active-count`;
  let config = {headers:{
      "authorizer":  sessionStorage.getItem('idToken')
    }};
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}
export async function dashboardGetServerMetric(metricName, currentTime) {
  var response = undefined;
  let reqUrl = `naviwatch/api/v1/monitoring/server/get-cluster-metric?clusterName=${ECSClusterName}` +
    `&serviceName=${ECSServiceName}&metricName=${metricName}&endtimeSec=${currentTime}`+
    `&timelineSec=1200&timePeriodSec=60`;
  let config = {headers:{
      "authorizer":  sessionStorage.getItem('idToken')
    }};
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}
export async function dashboardGetDeviceWeeklyChart(day) {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/monitoring/device/get-weekly-count?day=${day}`;
  let config = {headers:{
      "authorizer":  sessionStorage.getItem('idToken')
    }};
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}
export async function dashboardGetUserIncrease(day, basis) {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/monitoring/user/get-weekly-count?day=${day}&basis=${basis}`;
  let config = {headers:{
      "authorizer":  sessionStorage.getItem('idToken')
    }};
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function serverGetServerMetric(metricName, currentTime, timelineSec, timePeriodSec) {
  var response = undefined;
  console.log("before call ");

  let reqUrl = `/naviwatch/api/v1/monitoring/server/get-cluster-metric?`+
    `clusterName=${ECSClusterName}&serviceName=${ECSServiceName}&metricName=${metricName}`+
    `&endtimeSec=${currentTime}&timelineSec=${timelineSec}&timePeriodSec=${timePeriodSec}`;
  let config = {headers:{
      "authorizer":  sessionStorage.getItem('idToken')
    }};
  response = await ApiInstance.get(reqUrl, config);
  console.log("response: "+JSON.stringify(response.data));
  return response.data;
}

export async function serverGetDBMetric(metricName, currentTime, timelineSec, timePeriodSec, unit, stat) {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/monitoring/server/get-db-metric?`+
    `clusterName=${DBClusterName}&metricName=${metricName}`+
    `&endtimeSec=${currentTime}&timelineSec=${timelineSec}&timePeriodSec=${timePeriodSec}&unit=${unit}&stat=${stat}`;
  let config = {headers:{
      "authorizer":  sessionStorage.getItem('idToken')
    }};
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function serverGetLBMetric(metricName, currentTime, timelineSec, timePeriodSec) {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/monitoring/server/get-lb-metric?`+
    `availabilityZone=ap-northeast-2b&loadBalancer=${LoadBalancerName}&metricName=${metricName}`+
    `&endtimeSec=${currentTime}&timelineSec=${timelineSec}&timePeriodSec=${timePeriodSec}`;
  let config = {headers:{
      "authorizer":  sessionStorage.getItem('idToken')
    }};
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function statisticsGetPersonalization() {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/monitoring/statistics/personal-count`;
  let config = {headers:{
      "authorizer":  sessionStorage.getItem('idToken')
    }};
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function statisticsGetWeeklyReservation() {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/monitoring/statistics/weekly-resv-count`;
  let config = {headers:{
      "authorizer":  sessionStorage.getItem('idToken')
    }};
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function statisticsGetSleepCount() {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/monitoring/statistics/sleep-count`;
  let config = {headers:{
      "authorizer":  sessionStorage.getItem('idToken')
    }};
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}
export async function statisticsGetTemperatureCount() {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/monitoring/statistics/temp-count`;
  let config = {headers:{
      "authorizer":  sessionStorage.getItem('idToken')
    }};
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function statisticsWMGetReservOnUser(){
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/monitoring/statistics/wm-on-resv-cnt`;
  let config = {headers:{
      "authorizer":  sessionStorage.getItem('idToken')
    }};
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function statisticsWMGetReservOffUser(){
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/monitoring/statistics/wm-off-resv-cnt`;
  let config = {headers:{
      "authorizer":  sessionStorage.getItem('idToken')
    }};
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function statisticsGetCapacityTypeCount(){
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/monitoring/statistics/capacity-type-count`;
  let config = {headers:{
      "authorizer":  sessionStorage.getItem('idToken')
    }};
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function statisticsWMTemperaturelevel(){
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/monitoring/statistics/wm-temp-level`;
  let config = {headers:{
      "authorizer":  sessionStorage.getItem('idToken')
    }};
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function deviceSearch(devId, userId, modelCode, connection,
                                   gmtStr) {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/monitoring/device/search` +
    `?deviceid=${devId}&userid=${userId}&modelcode=${modelCode}&connection=${connection}`+
    `&gmtStr=${gmtStr}`;
  let config = {headers:{
      "authorizer":  sessionStorage.getItem('idToken')
    }};
  response = await ApiInstance.get(reqUrl, config);
  console.log("response: "+JSON.stringify(response.data));
  return response.data;
}

export async function deviceDetailSearch(devId) {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/monitoring/device/get-detail-info?deviceid=${devId}`;
  let config = {headers:{
      "authorizer":  sessionStorage.getItem('idToken')
    }};
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function deviceTotalCount() {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/monitoring/device/get-total-count`;
  let config = {headers:{
      "authorizer":  sessionStorage.getItem('idToken')
    }};
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function dormancyGetDormancyHistory(condition, userid) {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/monitoring/user/get-dormancy?`+
  `condition=${condition}&userid=${userid}`;
  let config = {headers:{
      "authorizer":  sessionStorage.getItem('idToken')
    }};
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function dormancyGetDormancySearch(naviwatchemail, ip, userid,
                                                 from, to) {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/monitoring/user/get-dormancy-user?`+
    `naviwatchemail=${naviwatchemail}&ip=${ip}&userid=${userid}&`+
    `from=${from}&to=${to}`;
  let config = {headers:{
      "authorizer":  sessionStorage.getItem('idToken')
    }};
  response = await ApiInstance.get(reqUrl, config);
  console.log("response: "+JSON.stringify(response))
  return response.data;
}

export async function dormancyGetDormancyCount() {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/monitoring/user/get-dormancy-count`;
  let config = {headers:{
      "authorizer":  sessionStorage.getItem('idToken')
    }};
  response = await ApiInstance.get(reqUrl, config);
  console.log("dormancy count res: "+JSON.stringify(response));
  return response.data;
}

export async function pushSendPush(modelCode, title, content) {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/monitoring/server/send-push?`+
      `modelCode=${modelCode}&title=${title}&content=${content}`;
  let config = {headers:{
      "authorizer":  sessionStorage.getItem('idToken')
    }};
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function recordGetRecords(searchId, idType, detail, from, to) {
  var response = undefined;

  let reqUrl = `/naviwatch/api/v1/monitoring/user/get-records?`+
  `idType=${idType}&searchId=${searchId}&detail=${detail}&from=${from}&to=${to}`;

  let config = {headers:{
      "authorizer":  sessionStorage.getItem('idToken')
    }};
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function recordGetFirmwareRecords(searchId, idType, from, to) {
  var response = undefined;

  let reqUrl = `/naviwatch/api/v1/monitoring/firmware/get-records?`+
  `idType=${idType}&searchId=${searchId}&from=${from}&to=${to}`;

  let config = {headers:{
      "authorizer":  sessionStorage.getItem('idToken')
    }};
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function userSearch(naviwatchid, ip, userid, useremail, username) {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/monitoring/user/search?` +
    `naviwatchid=${naviwatchid}&ip=${ip}&userid=${userid}&`+
    `useremail=${useremail}&username=${username}`;
  let config = {headers:{
      "authorizer":  sessionStorage.getItem('idToken')
    }};
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function userGetTotalCount() {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/monitoring/user/get-total-count`;
  let config = {headers:{
      "authorizer":  sessionStorage.getItem('idToken')
    }};
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function userGetUserDetail(naviwatchId, ip, choicedUser) {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/monitoring/user/get-detail-info?`+
  `naviwatchid=${naviwatchId}&ip=${ip}&userid=${choicedUser}`;
  let config = {headers:{
      "authorizer":  sessionStorage.getItem('idToken')
    }};
  response = await ApiInstance.get(reqUrl, config);
  console.log("Response: "+JSON.stringify(response))
  return response.data;
}

export async function userSendPush(userId, title, content) {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/monitoring/user/send-push?`+
    `userid=${userId}&title=${title}&content=${content}`;
  let config = {headers:{
      "authorizer":  sessionStorage.getItem('idToken')
    }};
  response = await ApiInstance.get(reqUrl, config);
  return response;
}

export async function userResetConfirmCode(userId, callback) {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/monitoring/user/reset-confirm-code?`+
    `userid=${userId}`;
  let config = {headers:{
      "authorizer":  sessionStorage.getItem('idToken')
    }};
  response = await ApiInstance.get(reqUrl, config);
  callback()
  return response;
}

export async function firmwareModelList() {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/monitoring/firmware/get-model-list`;
  let config = {headers:{
      "authorizer":  sessionStorage.getItem('idToken')
    }};
  response = await ApiInstance.get(reqUrl, config);
  console.log("modelList : "+JSON.stringify(response));

  return response.data;
}

export async function firmwareFwlList(modelCode) {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/monitoring/firmware/get-fw-list?modelCode=${modelCode}`;
  let config = {headers:{
      "authorizer":  sessionStorage.getItem('idToken')
    }};
  response = await ApiInstance.get(reqUrl, config);
  console.log("fwList : "+JSON.stringify(response));
  return response.data;
}

export async function firmwareAdd(naviwatchUser, ip, fwId, desc, users, s3Path, majorVersion,
                                  minorVersion, buildNumber, fwType, size, modelCode,
                                  activeFlag, forcedFlag, groupOtaFlag, image) {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/monitoring/firmware/add-firmware`;
  let config = {headers:{
      "authorizer":  sessionStorage.getItem('idToken')
    }};
  let data = JSON.stringify({
    "naviwatchUser": naviwatchUser,
    "ip": ip,
    "fwId": fwId,
    "description": desc,
    "users": users,
    "deviceFwPath": OTA_DEVICE_FW_PATH,
    "deviceCertPath": OTA_DEVICE_CERT_PATH,
    "certificateArn": OTA_CERTIFICATE_ARN,
    "s3Bucket": OTA_S3_BUCKET,
    "s3Path": s3Path,
    "s3SigningPrefix": OTA_S3_SIGNING_PREFIX,
    "majorVersion": majorVersion,
    "minorVersion": minorVersion,
    "buildNumber": buildNumber,
    "fwType": fwType,
    "size": size,
    "modelCode": modelCode,
    "activeFlag": activeFlag,
    "forcedFlag": forcedFlag,
    "groupOtaFlag": groupOtaFlag,
    "image": image
  });
  response = await ApiInstance.post(reqUrl, data, config);
  return response.data;
}

export async function firmwareThingsOfJob(majorVersion, minorVersion, modelCode,
                                          buildNumber, status, token) {
  var response = undefined;
  console.log("call firmwareThingsOfJob");
  let reqUrl = `/naviwatch/api/v1/monitoring/firmware/get-thing-of-job?`+
    `majorVersion=${majorVersion}&minorVersion=${minorVersion}&`+
    `modelCode=${modelCode}&buildNumber=${buildNumber}&status=${status}&token=${token}`;
  let config = {headers:{
      "authorizer":  sessionStorage.getItem('idToken')
    }};
  response = await ApiInstance.get(reqUrl, config);
  console.log("firmwareThingsOfJob response : "+JSON.stringify(response))
  return response.data;
}

export async function firmwareGetJobFile(jobId) {
  var response = undefined;
  console.log("call firmwareThingsOfJob");
  let reqUrl = `/naviwatch/api/v1/monitoring/firmware/get-job-doc?`+
    `jobId=${jobId}`;
  let config = {headers:{
      "authorizer":  sessionStorage.getItem('idToken')
    }};
  response = await ApiInstance.get(reqUrl, config);

  console.log("response: "+JSON.stringify(response));
  return response.data;
}

export async function firmwareSearchThingGroups(groupName, token) {
  var response = undefined;
  console.log("call firmwareThingsOfJob");
  let reqUrl = `/naviwatch/api/v1/monitoring/firmware/search-thinggroups?`+
    `groupName=${groupName}&token=${token}`;
  let config = {headers:{
      "authorizer":  sessionStorage.getItem('idToken')
    }};
  response = await ApiInstance.get(reqUrl, config);

  console.log("response: "+JSON.stringify(response));
  return response.data;
}

export async function firmwareSearchThings(attributeName, attributeValue, token) {
  var response = undefined;
  console.log("call firmwareThingsOfJob");
  let reqUrl = `/naviwatch/api/v1/monitoring/firmware/search-things?`+
    `attributeName=${attributeName}&attributeValue=${attributeValue}&token=${token}`;
  let config = {headers:{
      "authorizer":  sessionStorage.getItem('idToken')
    }};
  response = await ApiInstance.get(reqUrl, config);

  console.log("response: "+JSON.stringify(response));
  return response.data;
}

export async function firmwareGetGroupUsers(majorVersion, minorVersion,
                                            modelCode, buildNumber) {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/monitoring/firmware/get-group-users?`+
    `majorVersion=${majorVersion}&minorVersion=${minorVersion}`+
    `&modelCode=${modelCode}&buildNumber=${buildNumber}`;

  let config = {headers:{
      "authorizer":  sessionStorage.getItem('idToken')
    }};
  response = await ApiInstance.get(reqUrl, config);
  console.log("response.data: "+JSON.stringify(response.data));
  return response.data;
}

export async function firmwareSetGroupUsers(naviwatchUser, ip, majorVersion, minorVersion, modelCode,
                                            buildNumber, fwType, userlist, s3Path) {
  var response = undefined;
  let data = JSON.stringify({
    "naviwatchUser": naviwatchUser,
    "ip": ip,
    "majorVersion": majorVersion,
    "minorVersion": minorVersion,
    "modelCode": modelCode,
    "buildNumber": buildNumber,
    "fwType": fwType,
    "userList": userlist,
    "deviceFwPath": OTA_DEVICE_FW_PATH,
    "deviceCertPath": OTA_DEVICE_CERT_PATH,
    "certificateArn": OTA_CERTIFICATE_ARN,
    "s3Bucket": OTA_S3_BUCKET,
    "s3Path": s3Path,
    "s3SigningPrefix": OTA_S3_SIGNING_PREFIX  });
  console.log("Data : "+data);
  let reqUrl = `/naviwatch/api/v1/monitoring/firmware/set-group-users`;

  let config = {headers:{
      "authorizer":  sessionStorage.getItem('idToken')
    }};
  console.log("userlist: "+userlist);
  response = await ApiInstance.post(reqUrl, data, config);
  return response.data;
}


//export async function firmwareModify(majorVersion, minorVersion, modelCode, buildNumber,
export async function firmwareModify(naviwatchUser, ip, majorVersion, minorVersion, modelCode, buildNumber,
                                     fwType, activeFlag, forcedFlag, groupOtaFlag,
                                     groupList, description, s3Path) {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/monitoring/firmware/modify-firmware`;
  let data = JSON.stringify({
    "naviwatchUser": naviwatchUser,
    "ip": ip,
    "majorVersion": majorVersion,
    "minorVersion": minorVersion,
    "modelCode": modelCode,
    "buildNumber": buildNumber,
    "fwType": fwType,
    "activeFlag": activeFlag,
    "forcedFlag": forcedFlag,
    "groupOtaFlag": groupOtaFlag,
    "groupList": groupList,
    "fwDescription": description,
    "deviceFwPath": OTA_DEVICE_FW_PATH,
    "deviceCertPath": OTA_DEVICE_CERT_PATH,
    "certificateArn": OTA_CERTIFICATE_ARN,
    "s3Bucket": OTA_S3_BUCKET,
    "s3Path": s3Path,
    "s3SigningPrefix": OTA_S3_SIGNING_PREFIX
  });
  let config = {headers:{
      "authorizer":  sessionStorage.getItem('idToken')
    }};
  
  response = await ApiInstance.post(reqUrl, data, config);
  
  return response.data;
}

export async function firmwareDelete(naviwatchUser, ip, majorVersion, minorVersion,  modelCode,
                                     buildNumber, s3Path, endCallback) {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/monitoring/firmware/delete-firmware?`+
    `naviwatchUser=${naviwatchUser}&ip=${ip}&majorVersion=${majorVersion}&minorVersion=${minorVersion}`+
    `&modelCode=${modelCode}&s3Bucket=${OTA_S3_BUCKET}&s3Path=${s3Path}`+
    `&buildNumber=${buildNumber}`;

  let config = {headers:{
      "authorizer":  sessionStorage.getItem('idToken')
    }};
  response = await ApiInstance.get(reqUrl, config);
  endCallback();
  return response.data;
}

export function formatter(n) {
  if(n!==undefined) {
    var tempval = new Date((n + 60 * 60 * 9) * 1000);
    var timeStr = tempval.toISOString();
    timeStr = timeStr.replaceAll("T", " ")
    timeStr = timeStr.slice(0, 19)
    return timeStr;
  }else{
    return undefined;
  }
}

const MODEL_CODE_EME_500 = 257;

export function getS3Path(modelCode){
  if ( parseInt(modelCode)===MODEL_CODE_EME_500 ){
    return "em/eme-500"
  } else{
    return "em/suchoi-test"; // Not yet defined.
  }
}

export function getModelName(modelCode){
  var modelName = 'Not defined';
  if (modelCode === '1')
    modelName = 'EQM-572';
  else if (modelCode === '2')
    modelName = 'EQM-580';
  else if (modelCode === '3')
    modelName = 'EQM-581';
  else if (modelCode === '4')
    modelName = 'EQM-582';
  else if (modelCode === '257')
    modelName = 'EME-501';
  else if (modelCode === '513')
    modelName = 'EMF-500';

  return modelName;
}


export function getFWType(type){
  switch (type) {
    case 1 :
      return "MCU";
    case 2 :
      return "Wifi";
    case 3:
      return "Certificate";
    default :
      return "Not Defined";
  }
}
