import React, { useReducer, createContext, useEffect } from 'react';

import { Router, BrowserRouter } from 'react-router-dom';
// import { createBrowserHistory } from 'history';
import { Chart } from 'react-chartjs-2';
import { ThemeProvider } from '@material-ui/core/styles';
import validate from 'validate.js';

import { chartjs } from './helpers';
import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import validators from './common/validators';
import Routes from './Routes';
import AppContainer from './AppContainer';
import { RecoilRoot } from 'recoil';

// const browserHistory = createBrowserHistory();

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw
});

validate.validators = {
  ...validate.validators,
  ...validators
};

const initialState = {
  isSignin: false,
  name: '',
  email: '',
  team: '',
  group: '',
  idToken: '',
  accessToken: '',
  account: undefined,
  fullPolicies: undefined,
  fullRoles: undefined,
  pages: undefined
};

function reducer(state, action) {
  switch (action.type) {
    case 'LOGIN':
      sessionStorage.setItem('naviwatch_permission', 'true');
      sessionStorage.setItem('name', action.name);
      sessionStorage.setItem('email', action.email);
      sessionStorage.setItem('team', action.team);
      sessionStorage.setItem('group', action.group);
      sessionStorage.setItem('idToken', action.idToken);
      sessionStorage.setItem('accessToken', action.accessToken);
      //sessionStorage.setItem("account", JSON.stringify(action.account));
      sessionStorage.setItem('fullRoles', undefined);
      sessionStorage.setItem('fullPolicies', undefined);
      sessionStorage.setItem('pages', undefined);
      return {
        ...state,
        isSignin: true,
        name: action.name,
        email: action.email,
        team: action.team,
        group: action.group,
        idToken: action.idToken,
        accessToken: action.accessToken,
        account: JSON.stringify(action.account)
      };
    case 'LOGOUT':
      sessionStorage.setItem('naviwatch_permission', 'false');
      sessionStorage.setItem('name', '');
      sessionStorage.setItem('email', '');
      sessionStorage.setItem('team', '');
      sessionStorage.setItem('group', '');
      sessionStorage.setItem('idToken', '');
      sessionStorage.setItem('accessToken', '');
      //sessionStorage.setItem("account", undefined);
      sessionStorage.setItem('fullPolicies', undefined);
      sessionStorage.setItem('fullRoles', undefined);
      sessionStorage.setItem('pages', undefined);
      return {
        isSignin: false,
        name: '',
        email: '',
        team: '',
        group: '',
        idToken: '',
        accessToken: '',
        account: undefined,
        fullPolicies: undefined,
        fullRoles: undefined,
        pages: undefined
      };
    case 'POLICY_UPDATE':
      sessionStorage.setItem('fullPolicies', JSON.stringify(action.policies));
      return {
        ...state,
        fullPolicies: JSON.stringify(action.policies)
      };
    case 'ROLE_UPDATE':
      sessionStorage.setItem('fullRoles', JSON.stringify(action.roles));
      return {
        ...state,
        fullRoles: JSON.stringify(action.roles)
      };
    case 'PAGE_UPDATE':
      sessionStorage.setItem('pages', JSON.stringify(action.pages));
      return {
        ...state,
        pages: JSON.stringify(action.pages)
      };
    default:
      return state;
  }
}

export const dispatchContext = createContext(null);
export const userInfoContext = createContext(null);

function App() {
  const [authInfo, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    console.log('App component re-rendering autoInfo:' + authInfo.isSignin);
  }, []);

  return (
    <RecoilRoot>
      <userInfoContext.Provider value={authInfo}>
        <dispatchContext.Provider value={dispatch}>
          <ThemeProvider theme={theme}>
            {/* <Router history={browserHistory}> */}
            <BrowserRouter>
              {/* <Routes /> */}
              <AppContainer />
            </BrowserRouter>
          </ThemeProvider>
        </dispatchContext.Provider>
      </userInfoContext.Provider>
    </RecoilRoot>
  );
}

export default App;
