import React,{useContext} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Divider, Drawer } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import CameraRearIcon from '@material-ui/icons/CameraRear';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import TapAndPlayIcon from '@material-ui/icons/TapAndPlay';
import TimelineIcon from '@material-ui/icons/Timeline';
import DeveloperBoardIcon from '@material-ui/icons/DeveloperBoard';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import { Profile, SidebarNav } from './components';
import {userInfoContext} from '../../../../../App';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import EqualizerIcon from '@material-ui/icons/Equalizer';
const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  }
}));
const NAME_DASHBOARD = '대시보드';
const NAME_DEVICES = '디바이스';
const NAME_USERS = '사용자';
const NAME_FIRMWARES = '펌웨어';
const NAME_PUSH='푸시';
const NAME_DORMANCY='휴면 사용자';
const NAME_QUERY_RECORD='조회 기록';
const NAME_SERVER = '서버';
const NAME_STATISTICS = '통계';
const NAME_REPORT = '보고';

const Sidebar = props => {
  const { open, variant, onClose, prodid, className, ...rest } = props;
  const authInfo =  useContext(userInfoContext);

  const classes = useStyles();

  const pages = [
    {
      title: `${NAME_DASHBOARD}`,
      href: `/dashboard/${localStorage.productName}`,
      icon: <DashboardIcon />,
      secret: false
    },
    {
      title: `${NAME_SERVER}`,
      href: `/server/${localStorage.productName}`,
      icon: <TimelineIcon/>,
      secret: false
    },
    {
      title: `${NAME_STATISTICS}`,
      href: `/statistics/${localStorage.productName}`,
      icon: <EqualizerIcon/>,
      secret: false
    },
    {
      title: `${NAME_DEVICES}`,
      href: `/device/${localStorage.productName}`,
      icon: <DeveloperBoardIcon />,
      secret: false
    },
    {
      title: `${NAME_USERS}`,
      href: `/users/${localStorage.productName}`,
      icon: <PeopleIcon />,
      secret: false
    },
    {
      title: `${NAME_DORMANCY}`,
      href: `/dormancy/${localStorage.productName}`,
      icon: <SentimentVeryDissatisfiedIcon/>,
      secret: true
    },
    {
      title: `${NAME_PUSH}`,
      href: `/push/${localStorage.productName}`,
      icon: <TapAndPlayIcon/>,
      secret: true
    },
    {
      title: `${NAME_QUERY_RECORD}`,
      href: `/record/${localStorage.productName}`,
      icon: <BorderColorIcon/>,
      secret: true
    },
    {
      title: `${NAME_FIRMWARES}`,
      href: `/product/${localStorage.productName}`,
      icon: <CameraRearIcon/>,
      secret: true
    },
    {
      title: `${NAME_REPORT}`,
      href: '/Planning/reports',
      icon: <ReportProblemIcon />,
      secret: false
    }
    /*
    ,
    {
      title: `${NAME_SETTINGS}`,
      href: '/settings',
      icon: <SettingsIcon />,
      secret: false
    }
    */
  ];

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div
        {...rest}
        className={clsx(classes.root, className)}
      >
        <Profile />
        <Divider className={classes.divider} />
        <SidebarNav
          className={classes.nav}
          pages={pages}
          userInfo={authInfo}
        />
        {/*<UpgradePlan />*/}
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;
