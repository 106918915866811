import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Backdrop, CircularProgress, Grid, Typography } from '@material-ui/core';

import { NaviUserToolbar } from './components';
import useAsync from '../../../asyncNet';
import { NaviUsersTable, NaviUsersDeletedTable } from './components/NaviUsersTable';
import publicIp from 'public-ip';
import {
  naviwatchCheckWhiteListValidation,
  naviwatchDeletedUserSearch,
  naviwatchUserSearch
} from '../../Common/APIs/naviwatchApis';
import { userInfoContext } from '../../../App';
import {
  NO_PERMISSION,
  NO_PERMISSION_WARNING,
  NO_WHITELIST_INSIDE_WARNING,
  READ_PERMISSION
} from '../../Common/Config/naviwatchConfig';
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));


const NaviUserSearch = ({ history }) => {
  const classes = useStyles();
  const [query, setQuery] = useState({
    activeUser: true,
    useremail: 'none',
    username: 'none',
    roleId: 'none'
  });
  const [SearchState, tryNaviSearch] = useAsync(getNaviUserSearch, [], true);
  const [ipCheckState, tryCheckWhitelist] = useAsync(checkIpValidation, [], true);
  const [deletedUserState, tryGetDeleteUser] = useAsync(getDeletedNaviUserSearch, [], true);
  const [ipAddress, setIpAddress] = useState(undefined);
  const { loading: activeLoading, data: activeUsers } = SearchState;
  const { loading: deletedUserLoading, data: deletedUser } = deletedUserState;
  const { loading: ipCheckLoading, data: ipValid } = ipCheckState;
  const authInfo = useContext(userInfoContext);
  const [noPermissionMsg, setNoPermissionMsg] = useState("");
  const [permission, setPermission] = useState(NO_PERMISSION);
  let pageInfo = sessionStorage.getItem('pages');

  useEffect(() => {
    if (pageInfo !== "undefined") {
      var pageInfoObj = JSON.parse(pageInfo);
      var pageArray = pageInfoObj.filter(page => page.name.toLowerCase().includes("role"));
      if (pageArray !== undefined && pageArray.length > 0) {
        var page = pageArray[0];
        if (page.permission === undefined) {
          setNoPermissionMsg(NO_PERMISSION_WARNING);
        }
        setPermission(page.permission);
      }
    }
  }, [authInfo.pages]);

  async function getNaviUserSearch() {
    return naviwatchUserSearch(localStorage.getItem('productID'),
      query.useremail, query.username, query.roleId);
  }

  async function getDeletedNaviUserSearch() {
    return naviwatchDeletedUserSearch(query.useremail);
  }

  async function checkIpValidation() {
    return naviwatchCheckWhiteListValidation(ipAddress);
  }

  const searchUsers = (searchdata, searchActive) => {
    setQuery(
      {
        activeUser: searchActive,
        useremail: searchdata.useremail,
        username: searchdata.username,
        roleId: searchdata.roleId
      });
  };

  useEffect(() => {
    if (query.useremail !== 'none' && query.username !== 'none' && query.roleId !== 'none') {
      if (query.activeUser === true) {
        tryNaviSearch();
      } else {
        tryGetDeleteUser();
      }
    }

  }, [query]);

  useEffect(() => {
    if (ipCheckState.data === false) {
      setNoPermissionMsg(NO_WHITELIST_INSIDE_WARNING + `(${ipAddress})`);
    }
  }, [ipCheckState]);

  useEffect(() => {
    console.log(`ipAddress: ${ipAddress}`);
    if (ipAddress !== undefined) {
      tryCheckWhitelist();
    }
  }, [ipAddress]);

  useEffect(() => {
    if (sessionStorage.getItem('naviwatch_permission') !== "true")
      history.push('/');
    (async () => {
      setIpAddress(await publicIp.v4());
    })();
  }, []);

  return (
    <div>
      { permission >= READ_PERMISSION && ipValid === true ?
        <div className={classes.root}>
          <Backdrop className={classes.backdrop} open={activeLoading || ipCheckLoading || deletedUserLoading}>
            <CircularProgress color={'primary'} />
          </Backdrop>
          <NaviUserToolbar callbackFunc={searchUsers} loading={activeLoading} />
          <div className={classes.content}>
            {
              query.activeUser === true ?
                activeLoading === false && activeUsers !== null && activeUsers !== undefined && <NaviUsersTable users={activeUsers.data} /> :
                deletedUserLoading === false && deletedUser !== null && deletedUser !== undefined && <NaviUsersDeletedTable users={deletedUser.data} />
            }
          </div>
        </div> :
        <Typography className={classes.root}>{noPermissionMsg}</Typography>
      }
    </div>
  );
};

export default NaviUserSearch;
