import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core/styles';
import palette from 'theme/palette';

import {
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Divider,
} from '@material-ui/core';

import useAsync from '../../../../../asyncNet';
import {
  statisticsWMGetReservOnUser,
  statisticsWMGetReservOffUser,
  statisticsWMGetSleepModeUser
} from '../../../APIs/mateApis';

const STRING_USER_USE = '사용';
const STRING_USER_NO_USE = '미사용';
const STRING_RESERVATION_ON = '예약 (On)';
const STRING_RESERVATION_OFF = '예약 (Off)';
const STRING_SLEEP = '수면모드';


const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
  card: {
    backgroundColor: '#fff9db'
  },
  chartContainer: {
    height: '100%',
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));


async function getMetric(metricName) {
  var url = '';
  if (metricName === 'Reservation-On') {
    return statisticsWMGetReservOnUser();
  } else if (metricName === 'Reservation-Off') {
    return statisticsWMGetReservOffUser();
  } else if (metricName === 'Sleep'){
    return statisticsWMGetSleepModeUser();
  }
}

const getTitle = metricName =>{
  var title = '';
  if(metricName==='Reservation-On'){
    title = STRING_RESERVATION_ON;
  }else if (metricName === 'Reservation-Off') {
    title = STRING_RESERVATION_OFF;
  }else if (metricName === 'Sleep'){
    title = STRING_SLEEP
  }
  return title;
};

const ReservationCharts = props => {
  const { className, metricName, ...rest } = props;
  const [state, tryRefetch] = useAsync(() => getMetric(metricName));
  const { data: metric} = state;
  const classes = useStyles();
  const [chartdata, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: '',
        fill: false,
        backgroundColor: palette.primary.main,
        data: []
      }
    ]
  });
  const options = {
    legend: {
      display: true,
      position: "right",
      align: "center",
      fontFamily: "Allianz-Neo",
      textDirection: 'ltr',
      labels: {
        usePointStyle: true,
        fontColor: "#006192",
      }
    }
  };

  useEffect(() => {
    try {
      if (metric !== null) {
        var userNotSet = 0;
        var userSet = 0;
        var label;
        var color;
        let rate;
        if(metricName==="Reservation-On"){
          rate = Math.round(metric['onResvCnt']/(metric['total'])*100);
          userNotSet = metric['total'] - metric['onResvCnt'];
          userSet = metric['onResvCnt'];
          label = [STRING_USER_USE +' '+rate+'%', STRING_USER_NO_USE+' '+(100-rate)+'%']
          color = palette.primary.main;
        }else if(metricName==="Reservation-Off"){
          userNotSet = metric['total'] - metric['offResvCnt'];
          userSet = metric['offResvCnt'];
          rate = Math.round(metric['offResvCnt']/(metric['total'])*100);
          label = [STRING_USER_USE+' '+rate+'%', STRING_USER_NO_USE+' '+(100-rate)+'%']
          color = palette.error.main;
        }else if(metricName === "Sleep"){
          userNotSet = metric['total'] - metric['sleepCnt'];
          userSet = metric['sleepCnt'];
          rate = Math.round(metric['sleepCnt']/(metric['total'])*100);
          label = [STRING_USER_USE+' '+rate+'%', STRING_USER_NO_USE+' '+(100-rate)+'%']
          color = palette.success.main;
        }
        console.log('userNotSet:' + userNotSet + ', userSet:' + userSet);
        setChartData(chardata => ({
            ...chartdata,
            labels: label,
            datasets: [
              {
                label: "",
                borderWidth: 1,
                backgroundColor: [color, palette.grey],
                data: [userSet, userNotSet]
              }]
          })
        );
      }
    } catch (e) {
      console.log('metric update failed :' + JSON.stringify(e));
    }
  }, [metric]);

  useEffect(() => {
    tryRefetch();
  }, []);

  return (
    <div>
      <Card
        {...rest}
        className={classes.root}>
        <CardHeader
          action={
            <IconButton size="small" style={{ color: 'white' }}>
            </IconButton>
          }
          title={getTitle(metricName)}
        />

        <Divider/>
        <CardContent>
          <div className={classes.chartContainer}>
            <Doughnut
              data={chartdata}
              options={options}
            />
          </div>
        </CardContent>
        <Divider/>
      </Card>
    </div>
  );
};

ReservationCharts.propTypes = {
  className: PropTypes.string
};

export default React.memo(ReservationCharts);
