import React, { useContext, useEffect, useState, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Backdrop,
  CircularProgress,
  Grid
} from '@material-ui/core';
import { userInfoContext } from '../../../App';
import useAsync from '../../../asyncNet';
import {
  NO_PERMISSION,
  NO_PERMISSION_WARNING,
  READ_PERMISSION
} from '../../Common/Config/naviwatchConfig';

import { getSleepSessionList, getSleepSessionDetail } from '../APIs/smartApis';

import UpSleepSensorSearch from './UpSleepSensorSearch/UpSleepSensorSearch';
import UpSleepDialog from './UpSleepDialog';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));

const UpSleepSensorInfo = ({ history }) => {
  const classes = useStyles();

  const authInfo = useContext(userInfoContext);

  const [noPermissionMsg, setNoPermissionMsg] = useState('');
  const [permission, setPermission] = useState(NO_PERMISSION);
  const [userSeq, setUserSeq] = useState(0);
  const [sessionIdList, setSessionIdList] = useState([]);
  const [sessionReportData, setSessionReportData] = useState({
    deviceReport: [],
    asleepSessionRealtimeReport: [],
    asleepSessionReport: {}
  });
  const [loadingAnimation, setLoadingAnimation] = useState(false);

  const [sleepListQuery, setSleepListQuery] = useState({});
  const [sleepDetailQuery, setSleepDetailQuery] = useState({});
  const [sleepSessions, fetchSleepSessions] = useState(false);
  const [sleepSession, fetchSleepSession] = useState(false);

  const [openStatus, setOpenStatus] = useState(false);

  async function getSleepList() {
    return await getSleepSessionList(sleepListQuery);
  }
  async function getSleepDetail() {
    return await getSleepSessionDetail(sleepDetailQuery);
  }

  const [sleepSessionListState, trySearchSleepList] = useAsync(
    getSleepList,
    [],
    true
  );
  const [sleepSessionDetailState, trySearchSleepDetail] = useAsync(
    getSleepDetail,
    [],
    true
  );

  const {
    loading: sleepSessionListLoading,
    data: sleepSessionList
  } = sleepSessionListState;
  const {
    loading: sleepSessionDetailLoading,
    data: sleepSessionDetail
  } = sleepSessionDetailState;

  useEffect(() => {
    var pageInfo = sessionStorage.getItem('pages');
    if (pageInfo !== 'undefined') {
      var pageInfoObj = JSON.parse(pageInfo);
      var pageArray = pageInfoObj.filter(page =>
        page.name.toLowerCase().includes('device')
      );
      if (pageArray !== undefined && pageArray.length > 0) {
        var page = pageArray[0];
        if (page.permission === undefined) {
          setNoPermissionMsg(NO_PERMISSION_WARNING);
        }
        setPermission(page.permission);
      }
    }
  }, [authInfo.pages]);

  useEffect(() => {
    if (sessionStorage.getItem('naviwatch_permission') !== 'true') {
      history.push('/');
    }
  }, []);

  useEffect(() => {
    if (!sleepSessionListLoading) {
      if (sleepSessionList?.data?.data?.length > 0)
        setSessionIdList(sleepSessionList.data.data);
      if (sleepSessionList?.data?.errorMessage)
        alert('해당 기간에 세션이 없습니다.');
      setLoadingAnimation(false);
      fetchSleepSessions(false);
    }
  }, [sleepSessionList, sleepSessionListLoading]);

  useEffect(() => {
    if (sleepSessions) {
      trySearchSleepList();
    }
  }, [sleepSessions]);

  useEffect(() => {
    if (!sleepSessionDetailLoading) {
      if (sleepSessionDetail?.data?.data) {
        setSessionReportData({
          deviceReport: [...sleepSessionDetail.data.data.deviceReport],
          asleepSessionRealtimeReport: [
            ...sleepSessionDetail.data.data.asleepSessionRealtimeReport
          ],
          asleepSessionReport: {
            ...sleepSessionDetail.data.data.asleepSessionReport
          }
        });
      }
      if (sleepSessionDetail?.data?.errorMessage)
        alert('해당 세션에 문제가 있습니다.');
      setLoadingAnimation(false);
      fetchSleepSession(false);
    }
  }, [sleepSessionDetail, sleepSessionDetailLoading]);

  useEffect(() => {
    if (sleepSession) {
      trySearchSleepDetail();
    }
  }, [sleepSession]);

  useEffect(() => {
    if (sessionReportData?.asleepSessionReport?.sleepStageList?.length) {
      setOpenStatus(true);
    }
  }, [
    sessionReportData,
    sessionReportData?.asleepSessionReport,
    sessionReportData?.asleepSessionReport?.sleepStageList?.length
  ]);

  const getSessionIdList = async values => {
    try {
      setLoadingAnimation(true);
      setUserSeq(values.userSeq);
      setSleepListQuery({
        ...sleepListQuery,
        values
      });
      setSessionReportData({
        deviceReport: [],
        asleepSessionReport: []
      });
      fetchSleepSessions(true);
    } catch (e) {
      console.log(e);
    }
  };

  const getSensorData = async sessionId => {
    try {
      setLoadingAnimation(true);
      setSessionReportData({
        deviceReport: [],
        asleepSessionRealTimeReport: [],
        asleepSessionReport: {}
      });
      setSleepDetailQuery({
        sessionId,
        userSeq
      });
      fetchSleepSession(true);
    } catch (e) {
      console.log(e);
    }
  };

  const closeFunc = () => {
    setOpenStatus(false);
  };

  return (
    <div>
      {/* {permission >= READ_PERMISSION ? ( */}
      <div className={classes.root}>
        <Grid>
          <UpSleepSensorSearch
            sessionIdList={sessionIdList}
            getSessionIdList={getSessionIdList}
            getSensorData={getSensorData}
            setLoadingAnimation={setLoadingAnimation}
          />
          <Backdrop className={classes.backdrop} open={loadingAnimation}>
            <CircularProgress color={'primary'} />
          </Backdrop>
          <Grid container>
            <UpSleepDialog
              openStatus={openStatus}
              closeFunc={closeFunc}
              sessionReportData={sessionReportData}
            />
          </Grid>
        </Grid>
      </div>
      {/* ) : (
        <Typography className={classes.root}>{noPermissionMsg}</Typography>
      )} */}
    </div>
  );
};

export default UpSleepSensorInfo;
