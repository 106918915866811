import PropTypes from 'prop-types';
import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';
import { Auth } from "aws-amplify";
import { dispatchContext } from '../../../../App';
import useAsync from '../../../../asyncNet';
import {
  Backdrop,
  CircularProgress,
  Button,
  TextField
} from '@material-ui/core';

const STRING_PASSWORD_CHANGE_TITLE = 'Chanage Password';
const STRING_WARING_MESSAGE = 'If password change successed, then your account is logout. Please re-signin.';
const STRING_CURRENT_PASSWORD = 'Current Password';
const STRING_NEW_PASSWORD = 'New Password';
const STRING_NEW_PASSWORD_CONFIRM = 'Confirm new Password';
const STRING_CLEAN = 'Init';

const useStyles = makeStyles(theme => ({
  root: {},
  actions: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  button:{
    width:'50%',
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));

const ProfileDialog = props => {
  const dispatch = useContext(dispatchContext);
  const { open, handleClose, accessToken, email } = props;
  const classes = useStyles();
  const [values, setValues] = useState({
    currentPassword: '',
    newPassword: '',
    newPasswordConfirm: ''
  });

  const PopupClose = () => {
    handleClose();
    cleanInput();
  };

  const changePassword = () => {
    if (values.newPassword === values.newPasswordConfirm) {
      Auth.currentAuthenticatedUser()
      .then(user => {
        return Auth.changePassword(user, values.currentPassword, values.newPassword);
      })
      .then(data => {
        console.log(data);
        alert('Password change success. Your account is logout. Please re-signin.');
        signout();
      })
      .catch(err => {
        console.log(err);
        alert(err.message);
        return false;
      });
    }
    else {
      alert('Confirm-new password doesnot match to new password. Please check again.');
      return false;
    }
  };

  async function awsSignout() {
    console.log("try signout");
    await Auth.signOut({ global : true });
  }

  const signout = () => {
    awsSignout().then(() => {
      console.log("success sign-out");
      dispatch({type: 'LOGOUT'});
    }).catch((e) => {
      console.log("error sign-out : " + JSON.stringify(e))
    })
  };

  const [state, tryRefetch] = useAsync(changePassword, [], true);

  const { loading } = state;

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const cleanInput = () => {
    setValues({
      currentPassword: '',
      newPassword: '',
      newPasswordConfirm: ''
    });
  };

  return (
    <div>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color={'primary'}/>
      </Backdrop>
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">{STRING_PASSWORD_CHANGE_TITLE}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Alert severity={'warning'}><span style={{ color: 'red' }}>{STRING_WARING_MESSAGE}</span></Alert>
          </DialogContentText>
          <TextField
              fullWidth
              label={STRING_CURRENT_PASSWORD}
              margin="dense"
              name="currentPassword"
              onChange={handleChange}
              value={values.currentPassword}
              type="password"
              variant="outlined"
            />
          <TextField
              fullWidth
              label={STRING_NEW_PASSWORD}
              margin="dense"
              name="newPassword"
              onChange={handleChange}
              value={values.newPassword}
              type="password"
              variant="outlined"
            />
          <TextField
              fullWidth
              label={STRING_NEW_PASSWORD_CONFIRM}
              margin="dense"
              name="newPasswordConfirm"
              onChange={handleChange}
              value={values.newPasswordConfirm}
              type="password"
              variant="outlined"
            />
            <div className={classes.actions}>
            <Button
              className={classes.button}
              color="primary"
              variant="contained"
              onClick={cleanInput}
              disabled={values.currentPassword === '' && values.newPassword === '' && values.newPasswordConfirm === ''}
            >
              {STRING_CLEAN}
            </Button>
              <Button
                className={classes.button}
                color="primary"
                variant="contained"
                onClick={tryRefetch}
                disabled={values.currentPassword === '' || values.newPassword === '' || values.newPasswordConfirm === ''}
              >
                {STRING_PASSWORD_CHANGE_TITLE}
              </Button>
            </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={PopupClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

ProfileDialog.propTypes = {
  className: PropTypes.string
};

export default ProfileDialog;
