import React, { useEffect, useReducer, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';
import { requestSearchUserByUserId } from '../../../APIs/tokapis';
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination
} from '@material-ui/core';
import { getInitials } from 'helpers';
import Divider from '@material-ui/core/Divider';
import useAsync from '../../../../../asyncNet';
import {
  STRING_LOGIN_ID,
  STRING_NAME,
  STRING_PHONE,
  STRING_EMAIL,
  STRING_DEVICE_ID
} from '../../../StringTable/StringTable';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
}));

const UsersTable = props => {
  const { className, users, ...rest } = props;

  const classes = useStyles();

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [choicedUser, setUserChoice] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');
  const [userDetailInfo, requestUserDetailInfo] = useAsync(() => requestSearchUserByUserId(choicedUser.userID), [choicedUser], true);

  const handleClickOpen = (user) => {
    setUserChoice(user);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMaxWidthChange = (event) => {
    setMaxWidth(event.target.value);
  };

  const handleFullWidthChange = (event) => {
    setFullWidth(event.target.checked);
  };

  const handleSelectAll = event => {
    const { users } = props;

    let selectedUsers;

    if (event.target.checked) {
      selectedUsers = users.map(user => user.userID);
    } else {
      selectedUsers = [];
    }

    setSelectedUsers(selectedUsers);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedUsers.indexOf(id);
    let newSelectedUsers = [];

    if (selectedIndex === -1) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers, id);
    } else if (selectedIndex === 0) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers.slice(1));
    } else if (selectedIndex === selectedUsers.length - 1) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedUsers = newSelectedUsers.concat(
        selectedUsers.slice(0, selectedIndex),
        selectedUsers.slice(selectedIndex + 1)
      );
    }

    setSelectedUsers(newSelectedUsers);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  useEffect(() => {
    setPage(0);
  }, [users]);

  useEffect(() => {
    if (choicedUser != undefined && choicedUser.userID != undefined) {
      /* request detail information of selected user */
      requestUserDetailInfo(choicedUser.userID);
    }
  }, [choicedUser]);

  useEffect(() => {
    const { loading, data: userInfo, error } = userDetailInfo;

    if (loading == false && error == null && userInfo != undefined && userInfo.data.length > 0) {

      let retUserInfo = userInfo.data.find((item) => item.userID === choicedUser.userID);

      console.log(retUserInfo);

      if (retUserInfo !== undefined && choicedUser.connected !== retUserInfo.connected) {
        /* refresh connected information */
        setUserChoice(userInfo.data[0]);
      }
    }
  }, [userDetailInfo]);

  return (
    <div>
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedUsers.length === users.length}
                        color="primary"
                        indeterminate={
                          selectedUsers.length > 0 &&
                          selectedUsers.length < users.length
                        }
                        onChange={handleSelectAll}
                      />
                    </TableCell>
                    <TableCell>{STRING_LOGIN_ID}</TableCell>
                    <TableCell>{STRING_NAME}</TableCell>
                    <TableCell>{STRING_EMAIL}</TableCell>
                    <TableCell>{STRING_PHONE}</TableCell>
                    <TableCell>{STRING_DEVICE_ID}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.slice((page * rowsPerPage), ((page + 1) * rowsPerPage)).map(user => (
                    <TableRow
                      className={classes.tableRow}
                      hover
                      key={user.userID}
                      selected={selectedUsers.indexOf(user.userID) !== -1}
                      onClick={() => { handleClickOpen(user) }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedUsers.indexOf(user.userID) !== -1}
                          color="primary"
                          onChange={event => handleSelectOne(event, user.userID)}
                          value="true"
                        />
                      </TableCell>
                      <TableCell>
                        <div className={classes.nameContainer}>
                          <Avatar
                            className={classes.avatar}
                            src={user.avatarUrl}
                          >
                            {getInitials(user.nickName)}
                          </Avatar>
                          <Typography variant="body1">{user.userID}</Typography>
                        </div>
                      </TableCell>
                      <TableCell>
                        {user.nickName/*moment(user.createdAt).format('DD/MM/YYYY')*/}
                      </TableCell>
                      <TableCell>
                        {user.eMail}
                      </TableCell>
                      <TableCell>
                        {user.phoneNumber}
                      </TableCell>
                      <TableCell>{user.deviceID}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={users.length}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </CardActions>
      </Card>

      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">User Information</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Alert severity={"info"}>This is user's private information. So this personal information cannot be leaked freely.</Alert>
            <Divider className={classes.divider} />
            <Typography variant={'h6'}>UserId:</Typography> <Typography> {choicedUser.userID}</Typography>
            <Divider className={classes.divider} />
            <Typography variant={'h6'}>Name:</Typography> <Typography> {choicedUser.nickName}</Typography>
            <Divider className={classes.divider} />
            <Typography variant={'h6'}>Email:</Typography> <Typography> {choicedUser.eMail}</Typography>
            <Divider className={classes.divider} />
            <Typography variant={'h6'}> Phone Number:</Typography> <Typography> {choicedUser.phoneNumber}</Typography>
            <Divider className={classes.divider} />
            <Typography variant={'h6'}>Device Connection:</Typography> <Typography> {choicedUser.connected == "O" ? "Connected" : "Disconnected"}</Typography>
            <Divider className={classes.divider} />
            <Typography variant={'h6'}>DeviceId 1:</Typography> <Typography> {choicedUser.deviceID}</Typography>
            <Divider className={classes.divider} />
            <Typography variant={'h6'}>DeviceId 2:</Typography> <Typography> {choicedUser.deviceID_1}</Typography>
            <Divider className={classes.divider} />
            <Typography variant={'h6'}>DeviceId 3:</Typography> <Typography> {choicedUser.deviceID_2}</Typography>
          </DialogContentText>
          <form className={classes.form} noValidate>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

UsersTable.propTypes = {
  className: PropTypes.string,
  users: PropTypes.array.isRequired
};

export default UsersTable;
